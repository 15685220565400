import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** Email custom scalar */
  Email: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](https://ecma-international.org/wp-content/uploads/ECMA-404.pdf). */
  JSONObject: any;
  Metadata: any;
  /** UUID custom scalar */
  UUID: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AiChatAssistantModel = {
  __typename?: "AIChatAssistantModel";
  id?: Maybe<Scalars["String"]>;
  modelId?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isFavorite?: Maybe<Scalars["Boolean"]>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type AiChatBlockTemplate = {
  __typename?: "AIChatBlockTemplate";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  blockType?: Maybe<AiChatBlockTemplateEnum>;
  templates?: Maybe<Array<Maybe<AiChatTemplate>>>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type AiChatParameter = {
  __typename?: "AIChatParameter";
  id?: Maybe<Scalars["String"]>;
  question?: Maybe<Scalars["String"]>;
  inputPlaceholderText?: Maybe<Scalars["String"]>;
  placeHolderName?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  template?: Maybe<AiChatTemplate>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type AiChatTemplate = {
  __typename?: "AIChatTemplate";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  lessonType?: Maybe<AiChatTemplateLessonTypeEnum>;
  prompt?: Maybe<Scalars["String"]>;
  parameters?: Maybe<Array<Maybe<AiChatParameter>>>;
  blockTemplate?: Maybe<AiChatBlockTemplate>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type AiPromptTemplate = {
  __typename?: "AIPromptTemplate";
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<AiPromptType>;
  category?: Maybe<AiPromptCategory>;
  description?: Maybe<Scalars["String"]>;
  template?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ArBookUsersCountLight = {
  __typename?: "ARBookUsersCountLight";
  role?: Maybe<UserRoleEnum>;
  count?: Maybe<Scalars["Int"]>;
};

export type Achieve = {
  __typename?: "Achieve";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  descriptionDone?: Maybe<Scalars["String"]>;
  descriptionProgress?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["Date"]>;
  type?: Maybe<AchieveTypeEnum>;
  isLocked?: Maybe<Scalars["Boolean"]>;
  completeAchieves?: Maybe<Array<Maybe<CompleteAchieve>>>;
  isForPUPIL?: Maybe<Scalars["Boolean"]>;
  isForTEACHER?: Maybe<Scalars["Boolean"]>;
  isForPARENT?: Maybe<Scalars["Boolean"]>;
  isForGUEST?: Maybe<Scalars["Boolean"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum AchieveTypeEnum {
  IsRegister = "IS_REGISTER",
  FullUserData = "FULL_USER_DATA",
  Groups3 = "GROUPS3",
  Groups5 = "GROUPS5",
  Groups7 = "GROUPS7",
  ExperimentAr1 = "EXPERIMENT_AR1",
  ExperimentAr10 = "EXPERIMENT_AR10",
  ExperimentAr50 = "EXPERIMENT_AR50",
  ExperimentAr100 = "EXPERIMENT_AR100",
  Experiment_3D1 = "EXPERIMENT_3D1",
  Experiment_3D10 = "EXPERIMENT_3D10",
  Experiment_3D50 = "EXPERIMENT_3D50",
  Experiment_3D100 = "EXPERIMENT_3D100",
  LessonFeedback1 = "LESSON_FEEDBACK1",
  LessonFeedback10 = "LESSON_FEEDBACK10",
  LessonFeedback50 = "LESSON_FEEDBACK50",
  LessonFeedback100 = "LESSON_FEEDBACK100",
  Training = "TRAINING",
  Assessment = "ASSESSMENT",
  VideoCritic = "VIDEO_CRITIC",
  TrainersEnglish5 = "TRAINERS_ENGLISH5",
  TrainersEnglish10 = "TRAINERS_ENGLISH10",
  TrainersEnglish25 = "TRAINERS_ENGLISH25",
}

export type Ad = {
  __typename?: "Ad";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  expireAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type AddParticipantsToEducationPlanInput = {
  userIds?: InputMaybe<Array<Scalars["UUID"]>>;
  classroomIds?: InputMaybe<Array<Scalars["UUID"]>>;
  schoolClassroomIds?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type AddedTasksByEpic = {
  __typename?: "AddedTasksByEpic";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  assigneeName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  timeSpent?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
};

export type AddedTasksEpic = {
  __typename?: "AddedTasksEpic";
  epicKey?: Maybe<Scalars["String"]>;
  epicName?: Maybe<Scalars["String"]>;
  totalTimeSpent?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
};

export type AddedTasksReport = {
  __typename?: "AddedTasksReport";
  projectId?: Maybe<Scalars["String"]>;
  projectName?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
  timeSpent?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
};

export type AdditionalData = {
  __typename?: "AdditionalData";
  reports?: Maybe<Array<Maybe<Report>>>;
  reportsByProjects?: Maybe<Array<Maybe<ReportByProjects>>>;
  mainPart?: Maybe<Scalars["Int"]>;
  bonusesValue?: Maybe<Scalars["Int"]>;
  kpiValue?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  hoursFact?: Maybe<Scalars["Int"]>;
};

export enum AdditionalMarkTypeEnum {
  Na = "NA",
  Passed = "PASSED",
}

export type AdminPanelChartData = {
  __typename?: "AdminPanelChartData";
  tag?: Maybe<Array<Maybe<Scalars["Date"]>>>;
  value?: Maybe<Scalars["Float"]>;
};

export type AdminReport = {
  __typename?: "AdminReport";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<AdminReportType>;
  link?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["Date"]>;
  endDate?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type AdminReportChartType = {
  __typename?: "AdminReportChartType";
  tag?: Maybe<Array<Maybe<Scalars["Date"]>>>;
  value?: Maybe<Scalars["Int"]>;
};

export type AdminReportPaginated = {
  __typename?: "AdminReportPaginated";
  reports?: Maybe<Array<Maybe<AdminReport>>>;
  total?: Maybe<Scalars["Int"]>;
};

export enum AdminReportType {
  TeacherGroupsClassroomsPupils = "TEACHER_GROUPS_CLASSROOMS_PUPILS",
}

export enum AiChatBlockTemplateEnum {
  LessonPlaning = "LESSON_PLANING",
  TheoreticalHelp = "THEORETICAL_HELP",
  ImprovingPracticalPart = "IMPROVING_PRACTICAL_PART",
  ImageGeneration = "IMAGE_GENERATION",
}

export enum AiChatTemplateLessonTypeEnum {
  General = "GENERAL",
  SimpleLesson = "SIMPLE_LESSON",
  ForeignLanguage = "FOREIGN_LANGUAGE",
  Mathematics = "MATHEMATICS",
  Informatics = "INFORMATICS",
  Physics = "PHYSICS",
  Chemistry = "CHEMISTRY",
}

export enum AiPromptCategory {
  System = "SYSTEM",
  User = "USER",
}

export enum AiPromptType {
  AiChat = "AI_CHAT",
  AiImageGeneration = "AI_IMAGE_GENERATION",
}

export enum AllowedAdminPanelPaymentType {
  Manual = "MANUAL",
  Promotion = "PROMOTION",
  Technical = "TECHNICAL",
}

export type Analytics = {
  __typename?: "Analytics";
  id?: Maybe<Scalars["String"]>;
  trainingId?: Maybe<Scalars["String"]>;
  training?: Maybe<Traning>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type AndroidBundle = {
  __typename?: "AndroidBundle";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  fileLink?: Maybe<Scalars["String"]>;
  animation?: Maybe<AnimationBundle>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type AnimationBundle = {
  __typename?: "AnimationBundle";
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<AnimationBundleType>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  nameEN?: Maybe<Scalars["String"]>;
  nameUKR?: Maybe<Scalars["String"]>;
  descriptionEN?: Maybe<Scalars["String"]>;
  descriptionUKR?: Maybe<Scalars["String"]>;
  lat?: Maybe<Scalars["String"]>;
  long?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  iosBundle?: Maybe<IosBundle>;
  androidBundle?: Maybe<AndroidBundle>;
  participants?: Maybe<Array<Maybe<User>>>;
  availableForEveryone?: Maybe<Scalars["Boolean"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type AnimationBundleType = {
  __typename?: "AnimationBundleType";
  id?: Maybe<Scalars["Int"]>;
  type?: Maybe<AnimationBundleTypeEnum>;
  displayName?: Maybe<Scalars["String"]>;
};

export enum AnimationBundleTypeEnum {
  Mural = "MURAL",
  AntonovMuseum = "ANTONOV_MUSEUM",
  Demo = "DEMO",
  Portfolio = "PORTFOLIO",
}

export type Answer = {
  __typename?: "Answer";
  id?: Maybe<Scalars["String"]>;
  isRight?: Maybe<Scalars["Boolean"]>;
  questionId?: Maybe<Scalars["String"]>;
  question?: Maybe<Question>;
  variantId?: Maybe<Scalars["String"]>;
  variant?: Maybe<Variant>;
  testAnswerId?: Maybe<Scalars["String"]>;
  testAnswer?: Maybe<TestAnswer>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type AppMainThemeBackground = {
  __typename?: "AppMainThemeBackground";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  manifestIOS?: Maybe<Scalars["String"]>;
  manifestAndroid?: Maybe<Scalars["String"]>;
  manifestWebGl?: Maybe<Scalars["String"]>;
  fileIOS?: Maybe<Scalars["String"]>;
  fileAndroid?: Maybe<Scalars["String"]>;
  fileWebGl?: Maybe<Scalars["String"]>;
  themes?: Maybe<Array<Maybe<ApplicationMainTheme>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum AppMainThemeBackgroundFileTypeEnum {
  Manifest = "MANIFEST",
  Bundle = "BUNDLE",
}

export type AppType = {
  __typename?: "AppType";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<AppTypeNameEnum>;
};

export enum AppTypeNameEnum {
  Mobile = "MOBILE",
  Web = "WEB",
  Market = "MARKET",
  Promo = "PROMO",
}

export type Application = {
  __typename?: "Application";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  privacyPolicy?: Maybe<Scalars["String"]>;
  userAgreement?: Maybe<Scalars["String"]>;
  appStoreLink?: Maybe<Scalars["String"]>;
  playMarketLink?: Maybe<Scalars["String"]>;
  mainPhoto?: Maybe<Scalars["String"]>;
  modelPhoto?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["Date"]>;
  links?: Maybe<Array<Maybe<ApplicationLink>>>;
  mains?: Maybe<Array<Maybe<ApplicationMain>>>;
  notifications?: Maybe<Array<Maybe<MobileNotification>>>;
  versions?: Maybe<Array<Maybe<ApplicationVersion>>>;
  smartNotifications?: Maybe<Array<Maybe<SmartNotification>>>;
  users?: Maybe<Array<Maybe<User>>>;
  frontLink?: Maybe<Scalars["String"]>;
  variableName?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum ApplicationBundleTypeEnum {
  Bundle = "BUNDLE",
  BundleLink = "BUNDLE_LINK",
  Photo = "PHOTO",
}

export enum ApplicationFileTypeEnum {
  Photo = "PHOTO",
  PrivacyPolicy = "PRIVACY_POLICY",
  UserAgreement = "USER_AGREEMENT",
}

export type ApplicationLink = {
  __typename?: "ApplicationLink";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  applicationId?: Maybe<Scalars["String"]>;
  application?: Maybe<Application>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ApplicationMain = {
  __typename?: "ApplicationMain";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  locale?: Maybe<LocaleEnum>;
  photo?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  background1?: Maybe<Scalars["String"]>;
  background2?: Maybe<Scalars["String"]>;
  background3?: Maybe<Scalars["String"]>;
  background4?: Maybe<Scalars["String"]>;
  background5?: Maybe<Scalars["String"]>;
  isMap?: Maybe<Scalars["Boolean"]>;
  availableFor?: Maybe<ApplicationMainAvailableForEnum>;
  participants?: Maybe<Array<Maybe<User>>>;
  qr?: Maybe<Scalars["String"]>;
  qrCustom?: Maybe<Scalars["String"]>;
  applicationId?: Maybe<Scalars["String"]>;
  application?: Maybe<Application>;
  models?: Maybe<Array<Maybe<ApplicationMainModel>>>;
  marketContentCategories?: Maybe<Array<Maybe<MarketContent>>>;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["Date"]>;
  city?: Maybe<ApplicationMainCityEnum>;
  priority?: Maybe<Scalars["Int"]>;
  display?: Maybe<Scalars["Boolean"]>;
  themes?: Maybe<Array<Maybe<ApplicationMainTheme>>>;
  tests?: Maybe<Array<Maybe<Test>>>;
  teachers?: Maybe<Array<Maybe<User>>>;
  showInSubjects?: Maybe<Scalars["Boolean"]>;
  showInArBook?: Maybe<Scalars["Boolean"]>;
  isDemo?: Maybe<Scalars["Boolean"]>;
  analyticsMark?: Maybe<Scalars["String"]>;
  subscriptionPlan?: Maybe<SubscriptionPlanStatusEnum>;
  isSelected?: Maybe<Scalars["Boolean"]>;
  planCount?: Maybe<Scalars["Int"]>;
  pupilPlansCount?: Maybe<Scalars["Int"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum ApplicationMainAvailableForEnum {
  All = "ALL",
  Clients = "CLIENTS",
  Qr = "QR",
}

export enum ApplicationMainCityEnum {
  Kharkiv = "Kharkiv",
  NotKharkiv = "NotKharkiv",
}

export type ApplicationMainModel = {
  __typename?: "ApplicationMainModel";
  id?: Maybe<Scalars["String"]>;
  parentId?: Maybe<Scalars["String"]>;
  childId?: Maybe<Scalars["String"]>;
  marketId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  experimentType?: Maybe<ExperimentTypeEnum>;
  mains?: Maybe<Array<Maybe<ApplicationMain>>>;
  schoolGrades?: Maybe<Array<Maybe<SchoolGrade>>>;
  educationAreas?: Maybe<Array<Maybe<EducationArea>>>;
  locale?: Maybe<LocaleEnum>;
  lang?: Maybe<LanguagesEnum>;
  lat?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["String"]>;
  address?: Maybe<Scalars["String"]>;
  isContour?: Maybe<Scalars["Boolean"]>;
  mapMarkerPhoto?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  isBundle?: Maybe<Scalars["Boolean"]>;
  bundleType?: Maybe<ApplicationBundleTypeEnum>;
  bundlePhoto?: Maybe<Scalars["String"]>;
  bundleLink?: Maybe<Scalars["String"]>;
  bundles?: Maybe<Array<Maybe<ApplicationMainModelFile>>>;
  answers?: Maybe<Array<Maybe<ApplicationMainModelFile>>>;
  tests?: Maybe<Array<Maybe<ApplicationMainModelFile>>>;
  variants?: Maybe<Array<Maybe<ApplicationMainModelFile>>>;
  iosBundle?: Maybe<Scalars["String"]>;
  androidBundle?: Maybe<Scalars["String"]>;
  vrBundle?: Maybe<Scalars["String"]>;
  isMarker?: Maybe<Scalars["Boolean"]>;
  markerPhoto?: Maybe<Scalars["String"]>;
  mainId?: Maybe<Scalars["String"]>;
  main?: Maybe<ApplicationMain>;
  schoolId?: Maybe<Scalars["String"]>;
  school?: Maybe<School>;
  isMap?: Maybe<Scalars["Boolean"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  isUploadedToMarket?: Maybe<Scalars["Boolean"]>;
  isUploadedToArBookPlus?: Maybe<Scalars["Boolean"]>;
  isFromMarket?: Maybe<Scalars["Boolean"]>;
  markerWidth?: Maybe<Scalars["Float"]>;
  publishedAt?: Maybe<Scalars["Date"]>;
  lessonId?: Maybe<Scalars["String"]>;
  lesson?: Maybe<Lesson>;
  themeId?: Maybe<Scalars["String"]>;
  theme?: Maybe<ApplicationMainTheme>;
  showSmartNotification?: Maybe<Scalars["Boolean"]>;
  smartNotificationId?: Maybe<Scalars["String"]>;
  smartNotification?: Maybe<SmartNotification>;
  clicks?: Maybe<Scalars["Int"]>;
  userProgresses?: Maybe<Array<Maybe<UserModelProgress>>>;
  subscriptionPlan?: Maybe<SubscriptionPlanStatusEnum>;
  contentAuthor?: Maybe<Scalars["String"]>;
  teachingFormat?: Maybe<TeachingFormatEnum>;
  libraryContentId?: Maybe<Scalars["UUID"]>;
  libraryContent?: Maybe<UserLibraryContent>;
  tags?: Maybe<Array<Maybe<ContentTag>>>;
  inGradeOrder?: Maybe<Scalars["Int"]>;
  countOfImport?: Maybe<Scalars["Int"]>;
  schoolGradeId?: Maybe<Scalars["String"]>;
  schoolGrade?: Maybe<SchoolGrade>;
  coloringPageFile?: Maybe<Scalars["String"]>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  contentPreviews?: Maybe<Array<Maybe<ContentPreview>>>;
  contentType?: Maybe<ContentType>;
};

export type ApplicationMainModelFile = {
  __typename?: "ApplicationMainModelFile";
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<ApplicationMainModelFileTypeEnum>;
  language?: Maybe<ApplicationMainModelFileLangEnum>;
  file?: Maybe<Scalars["String"]>;
  manifest?: Maybe<Scalars["String"]>;
  modelId?: Maybe<Scalars["String"]>;
  model?: Maybe<ApplicationMainModel>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum ApplicationMainModelFileLangEnum {
  En = "EN",
  Ukr = "UKR",
}

export enum ApplicationMainModelFileTypeEnum {
  Ios = "IOS",
  Android = "ANDROID",
  WebGl = "WEB_GL",
  Vr = "VR",
  Room = "ROOM",
}

export type ApplicationMainTheme = {
  __typename?: "ApplicationMainTheme";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nameEN?: Maybe<Scalars["String"]>;
  nameUKR?: Maybe<Scalars["String"]>;
  locale?: Maybe<LocaleEnum>;
  applicationMainId?: Maybe<Scalars["String"]>;
  applicationMain?: Maybe<ApplicationMain>;
  applicationMainModels?: Maybe<Array<Maybe<ApplicationMainModel>>>;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  tests?: Maybe<Array<Maybe<ApplicationMainModelFile>>>;
  photo?: Maybe<Scalars["String"]>;
  photoName?: Maybe<Scalars["String"]>;
  background?: Maybe<AppMainThemeBackground>;
  backgroundId?: Maybe<Scalars["String"]>;
  analyticsMark?: Maybe<Scalars["String"]>;
  subscriptionPlan?: Maybe<SubscriptionPlanStatusEnum>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ApplicationVersion = {
  __typename?: "ApplicationVersion";
  id?: Maybe<Scalars["String"]>;
  os?: Maybe<Os>;
  version?: Maybe<Scalars["String"]>;
  appId?: Maybe<Scalars["String"]>;
  app?: Maybe<Application>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ApplySettingsToAllSlidesInput = {
  testTime?: InputMaybe<SlideTestTimeInput>;
  shuffleAnswers?: InputMaybe<Scalars["Boolean"]>;
  testScore?: InputMaybe<Scalars["Int"]>;
  showCorrectAnswer?: InputMaybe<Scalars["Boolean"]>;
};

export type ArBookPlusMainPageData = {
  __typename?: "ArBookPlusMainPageData";
  contentTypes?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  subjects?: Maybe<Array<Maybe<ApplicationMain>>>;
  grades?: Maybe<Array<Maybe<SchoolGrade>>>;
};

export enum ArtifactRankEnum {
  Bronze = "bronze",
  Silver = "silver",
  Gold = "gold",
}

export enum ArtifactTypeEnum {
  Cloth = "cloth",
  Good = "good",
  Animation = "animation",
}

export type AssignPupil = {
  __typename?: "AssignPupil";
  pupilsExists?: Maybe<Array<Maybe<User>>>;
  pupilsAssign?: Maybe<Array<Maybe<User>>>;
};

export type AssigneeProblems = {
  __typename?: "AssigneeProblems";
  key?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  assigneeJiraId?: Maybe<Scalars["String"]>;
};

export type Auth = {
  __typename?: "Auth";
  token?: Maybe<Scalars["String"]>;
};

export type AvailableAndPersonalPlanCount = {
  __typename?: "AvailableAndPersonalPlanCount";
  availablePlanCount?: Maybe<Scalars["Int"]>;
  personalPlanCount?: Maybe<Scalars["Int"]>;
};

export enum AvailableForEnum {
  All = "ALL",
  Clients = "CLIENTS",
  Staff = "STAFF",
  Teachers = "TEACHERS",
}

export enum AvailableForMobileNotificationEnum {
  Teacher = "TEACHER",
  Pupil = "PUPIL",
  Parent = "PARENT",
  Manager = "MANAGER",
  Guest = "GUEST",
  Everyone = "EVERYONE",
}

export enum AvailableForSmartNotificationEnum {
  Teacher = "TEACHER",
  Pupil = "PUPIL",
  Parent = "PARENT",
  Manager = "MANAGER",
  Guest = "GUEST",
  Everyone = "EVERYONE",
}

export type Award = {
  __typename?: "Award";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  day?: Maybe<Scalars["Int"]>;
  background?: Maybe<Scalars["String"]>;
  animation?: Maybe<Scalars["String"]>;
  energyQuantity?: Maybe<Scalars["Int"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<AwardTypeEnum>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["UUID"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type AwardReorderInput = {
  id: Scalars["UUID"];
  newDay: Scalars["Int"];
};

export enum AwardTypeEnum {
  Day = "DAY",
  Bonus = "BONUS",
}

export enum BaseTaskLabelsEnum {
  Bug = "bug",
  AdditionalTask = "additionalTask",
}

export enum BaseTaskStatusesEnum {
  New = "new",
  InProgress = "inProgress",
  Done = "done",
}

export enum BaseWorkflowStepsEnum {
  New = "new",
  InProgress = "inProgress",
  Done = "done",
}

export enum BlockedReasonEnum {
  NoContent = "NO_CONTENT",
  TestNotValid = "TEST_NOT_VALID",
  NoSubscription = "NO_SUBSCRIPTION",
}

export type BugsAdditionalTasksReport = {
  __typename?: "BugsAdditionalTasksReport";
  name?: Maybe<Scalars["String"]>;
  tasks?: Maybe<Scalars["Int"]>;
  percents?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
};

export enum BundleFileTypeEnum {
  IosBundle = "IOS_BUNDLE",
  AndroidBundle = "ANDROID_BUNDLE",
  VrBundle = "VR_BUNDLE",
}

export type CsvValidationErrors = {
  __typename?: "CSVValidationErrors";
  line: Scalars["Int"];
  errors: Array<Scalars["String"]>;
};

export type ChartData = {
  __typename?: "ChartData";
  minStart?: Maybe<Scalars["String"]>;
  maxFinish?: Maybe<Scalars["String"]>;
  data?: Maybe<Array<Maybe<ChartTaskData>>>;
};

export type ChartData2 = {
  __typename?: "ChartData2";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  progress?: Maybe<Scalars["Int"]>;
  type?: Maybe<ChartData2Types>;
  project?: Maybe<Scalars["String"]>;
  isClient?: Maybe<Scalars["Boolean"]>;
  isAdditionalTask?: Maybe<Scalars["Boolean"]>;
};

export enum ChartData2Types {
  Project = "project",
  Task = "task",
}

export type ChartDataWithTotal = {
  __typename?: "ChartDataWithTotal";
  dataChart?: Maybe<Array<Maybe<AdminPanelChartData>>>;
  dataTotal?: Maybe<Scalars["Float"]>;
};

export type ChartTaskData = {
  __typename?: "ChartTaskData";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  startDateEstimate?: Maybe<Scalars["String"]>;
  startDateFact?: Maybe<Scalars["String"]>;
  finishDateEstimate?: Maybe<Scalars["String"]>;
  finishDateFact?: Maybe<Scalars["String"]>;
};

export type ChatBlockTemplateInput = {
  title?: InputMaybe<Scalars["String"]>;
  blockType?: InputMaybe<AiChatBlockTemplateEnum>;
};

export type CheckIfCanStartQuizResponse = {
  __typename?: "CheckIfCanStartQuizResponse";
  status?: Maybe<Scalars["Boolean"]>;
  reason?: Maybe<BlockedReasonEnum>;
};

export type CheckIfUserRegisteredResponse = {
  __typename?: "CheckIfUserRegisteredResponse";
  isRegistered?: Maybe<Scalars["Boolean"]>;
};

export type City = {
  __typename?: "City";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Classroom = {
  __typename?: "Classroom";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  teacherId?: Maybe<Scalars["String"]>;
  teacher?: Maybe<User>;
  tests?: Maybe<Array<Maybe<Test>>>;
  pupils?: Maybe<Array<Maybe<User>>>;
  educationPlans?: Maybe<Array<Maybe<EducationPlan>>>;
  isArchived?: Maybe<Scalars["Boolean"]>;
  archivedDate?: Maybe<Scalars["Date"]>;
  school?: Maybe<School>;
  schoolId?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum ClassroomAssigneeMode {
  AllStudents = "ALL_STUDENTS",
  IndividualStudents = "INDIVIDUAL_STUDENTS",
}

export type ClassroomAssignment = {
  __typename?: "ClassroomAssignment";
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  dueDate?: Maybe<ClassroomDate>;
};

export type ClassroomCourse = {
  __typename?: "ClassroomCourse";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  section?: Maybe<Scalars["String"]>;
  descriptionHeading?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  room?: Maybe<Scalars["String"]>;
  ownerId?: Maybe<Scalars["String"]>;
  creationTime?: Maybe<Scalars["String"]>;
  updateTime?: Maybe<Scalars["String"]>;
  enrollmentCode?: Maybe<Scalars["String"]>;
  courseState?: Maybe<Scalars["String"]>;
  alternateLink?: Maybe<Scalars["String"]>;
  teacherGroupEmail?: Maybe<Scalars["String"]>;
  courseGroupEmail?: Maybe<Scalars["String"]>;
  teacherFolder?: Maybe<ClassroomDriveFolder>;
  courseMaterialSets?: Maybe<Array<Maybe<ClassroomCourseMaterialSet>>>;
  guardiansEnabled?: Maybe<Scalars["Boolean"]>;
  calendarId?: Maybe<Scalars["String"]>;
  gradebookSettings?: Maybe<ClassroomGradebookSettings>;
};

export type ClassroomCourseMaterial = {
  __typename?: "ClassroomCourseMaterial";
  title?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  form?: Maybe<ClassroomForm>;
  driveFile?: Maybe<ClassroomDriveFile>;
  youtubeVideo?: Maybe<ClassroomYouTubeVideo>;
  media?: Maybe<ClassroomMedia>;
};

export type ClassroomCourseMaterialSet = {
  __typename?: "ClassroomCourseMaterialSet";
  title?: Maybe<Scalars["String"]>;
  materials?: Maybe<Array<Maybe<ClassroomCourseMaterial>>>;
};

export type ClassroomCourseWork = {
  __typename?: "ClassroomCourseWork";
  courseId?: Maybe<Scalars["ID"]>;
  id?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  materials?: Maybe<Array<Maybe<ClassroomMaterial>>>;
  state?: Maybe<ClassroomCourseWorkState>;
  alternateLink?: Maybe<Scalars["String"]>;
  creationTime?: Maybe<Scalars["String"]>;
  updateTime?: Maybe<Scalars["String"]>;
  dueDate?: Maybe<ClassroomDate>;
  dueTime?: Maybe<ClassroomTime>;
  scheduledTime?: Maybe<Scalars["String"]>;
  maxPoints?: Maybe<Scalars["Float"]>;
  workType?: Maybe<ClassroomCourseWorkType>;
  associatedWithDeveloper?: Maybe<Scalars["Boolean"]>;
  assigneeMode?: Maybe<ClassroomAssigneeMode>;
  individualStudentsOptions?: Maybe<ClassroomIndividualStudentsOptions>;
  submissionModificationMode?: Maybe<ClassroomSubmissionModificationMode>;
  creatorUserId?: Maybe<Scalars["String"]>;
  topicId?: Maybe<Scalars["String"]>;
  gradeCategory?: Maybe<ClassroomGradeCategory>;
  assignment?: Maybe<ClassroomAssignment>;
  multipleChoiceQuestion?: Maybe<ClassroomMultipleChoiceQuestion>;
};

export type ClassroomCourseWorkMaterial = {
  __typename?: "ClassroomCourseWorkMaterial";
  courseId?: Maybe<Scalars["ID"]>;
  id?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  materials?: Maybe<Array<Maybe<ClassroomMaterial>>>;
  state?: Maybe<ClassroomCourseWorkState>;
  alternateLink?: Maybe<Scalars["String"]>;
  creationTime?: Maybe<Scalars["String"]>;
  updateTime?: Maybe<Scalars["String"]>;
  scheduledTime?: Maybe<Scalars["String"]>;
  assigneeMode?: Maybe<ClassroomAssigneeMode>;
  individualStudentsOptions?: Maybe<ClassroomIndividualStudentsOptions>;
  creatorUserId?: Maybe<Scalars["String"]>;
  topicId?: Maybe<Scalars["String"]>;
};

export enum ClassroomCourseWorkState {
  CourseWorkStateUnspecified = "COURSE_WORK_STATE_UNSPECIFIED",
  Published = "PUBLISHED",
  Draft = "DRAFT",
  Deleted = "DELETED",
}

export enum ClassroomCourseWorkType {
  Assignment = "ASSIGNMENT",
  ShortAnswerQuestion = "SHORT_ANSWER_QUESTION",
  MultipleChoiceQuestion = "MULTIPLE_CHOICE_QUESTION",
}

export type ClassroomCreateEducationPlanInput = {
  mainId: Scalars["String"];
  classroomCourseId: Scalars["ID"];
  gradeId?: InputMaybe<Scalars["String"]>;
  difficultyLevel?: InputMaybe<DifficultyLevelEnum>;
  description?: InputMaybe<Scalars["String"]>;
  classroomId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

export type ClassroomDate = {
  __typename?: "ClassroomDate";
  year?: Maybe<Scalars["Int"]>;
  month?: Maybe<Scalars["Int"]>;
  day?: Maybe<Scalars["Int"]>;
};

export type ClassroomDriveFile = {
  __typename?: "ClassroomDriveFile";
  id?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  alternateLink?: Maybe<Scalars["String"]>;
};

export type ClassroomDriveFolder = {
  __typename?: "ClassroomDriveFolder";
  id?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  alternateLink?: Maybe<Scalars["String"]>;
};

export type ClassroomForm = {
  __typename?: "ClassroomForm";
  formUrl?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  responseUrl?: Maybe<Scalars["String"]>;
  thumbnailUrl?: Maybe<Scalars["String"]>;
};

export type ClassroomGradeCategory = {
  __typename?: "ClassroomGradeCategory";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ClassroomGradebookSettings = {
  __typename?: "ClassroomGradebookSettings";
  calculationType?: Maybe<Scalars["String"]>;
  gradeCategories?: Maybe<Array<Maybe<ClassroomGradeCategory>>>;
};

export type ClassroomIndividualStudentsOptions = {
  __typename?: "ClassroomIndividualStudentsOptions";
  studentIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type ClassroomLink = {
  __typename?: "ClassroomLink";
  url?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  thumbnailUrl?: Maybe<Scalars["String"]>;
};

export type ClassroomMaterial = {
  __typename?: "ClassroomMaterial";
  driveFile?: Maybe<ClassroomDriveFile>;
  youtubeVideo?: Maybe<ClassroomYouTubeVideo>;
  link?: Maybe<ClassroomLink>;
  form?: Maybe<ClassroomForm>;
};

export type ClassroomMedia = {
  __typename?: "ClassroomMedia";
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type ClassroomMultipleChoiceQuestion = {
  __typename?: "ClassroomMultipleChoiceQuestion";
  question?: Maybe<Scalars["String"]>;
  choices?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type ClassroomName = {
  __typename?: "ClassroomName";
  givenName?: Maybe<Scalars["String"]>;
  familyName?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
};

export type ClassroomStudent = {
  __typename?: "ClassroomStudent";
  courseId?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  profile?: Maybe<ClassroomUserProfile>;
};

export enum ClassroomSubmissionModificationMode {
  SubmissionModificationModeUnspecified = "SUBMISSION_MODIFICATION_MODE_UNSPECIFIED",
  ModifiableUntilTurnedIn = "MODIFIABLE_UNTIL_TURNED_IN",
  Modifiable = "MODIFIABLE",
}

export type ClassroomTeacher = {
  __typename?: "ClassroomTeacher";
  courseId?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  profile?: Maybe<ClassroomUserProfile>;
};

export type ClassroomTime = {
  __typename?: "ClassroomTime";
  hours?: Maybe<Scalars["Int"]>;
  minutes?: Maybe<Scalars["Int"]>;
  seconds?: Maybe<Scalars["Int"]>;
  nanos?: Maybe<Scalars["Int"]>;
};

export type ClassroomTopic = {
  __typename?: "ClassroomTopic";
  courseId?: Maybe<Scalars["String"]>;
  topicId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  updateTime?: Maybe<Scalars["String"]>;
};

export type ClassroomUserProfile = {
  __typename?: "ClassroomUserProfile";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<ClassroomName>;
  emailAddress?: Maybe<Scalars["String"]>;
  photoUrl?: Maybe<Scalars["String"]>;
  verifiedTeacher?: Maybe<Scalars["Boolean"]>;
};

export type ClassroomYouTubeVideo = {
  __typename?: "ClassroomYouTubeVideo";
  id?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  alternateLink?: Maybe<Scalars["String"]>;
};

export enum ClientEnvironmentEnum {
  AdminPanel = "adminPanel",
  Arteacher = "arteacher",
  Arbook = "arbook",
}

export type ClientSite = {
  __typename?: "ClientSite";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  iframe?: Maybe<Scalars["String"]>;
  models?: Maybe<Array<Maybe<ClientSiteModel>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ClientSiteModel = {
  __typename?: "ClientSiteModel";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  itemId?: Maybe<Scalars["String"]>;
  items?: Maybe<Array<Maybe<ClientSiteModelItem>>>;
  glbFile?: Maybe<Scalars["String"]>;
  usdzFile?: Maybe<Scalars["String"]>;
  gltfFile?: Maybe<Scalars["String"]>;
  clientSiteId?: Maybe<Scalars["String"]>;
  clientSite?: Maybe<ClientSite>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum ClientSiteModelFilesEnum {
  GlbFile = "glbFile",
  UsdzFile = "usdzFile",
  GltfFile = "gltfFile",
}

export type ClientSiteModelItem = {
  __typename?: "ClientSiteModelItem";
  id?: Maybe<Scalars["String"]>;
  itemId?: Maybe<Scalars["String"]>;
  clientSiteModelId?: Maybe<Scalars["String"]>;
  clientSiteModel?: Maybe<ClientSiteModel>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type CompleteAchieve = {
  __typename?: "CompleteAchieve";
  id?: Maybe<Scalars["String"]>;
  isWatch?: Maybe<Scalars["Boolean"]>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]>;
  achieve?: Maybe<Achieve>;
  achieveId?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Date"]>;
  watchedDate?: Maybe<Scalars["Date"]>;
};

export enum CompletedLessonContentType {
  Lesson = "LESSON",
  Template = "TEMPLATE",
  ControlWork = "CONTROL_WORK",
  Lab = "LAB",
  PracticalWork = "PRACTICAL_WORK",
  EducationalProject = "EDUCATIONAL_PROJECT",
  Homework = "HOMEWORK",
  CompletedTest = "COMPLETED_TEST",
  TestZno = "TEST_ZNO",
}

export type CompletionPctReport = {
  __typename?: "CompletionPctReport";
  projectId?: Maybe<Scalars["String"]>;
  projectName?: Maybe<Scalars["String"]>;
  prc?: Maybe<Array<Maybe<Prc>>>;
};

export type ConfirmUserV3Response = {
  __typename?: "ConfirmUserV3Response";
  role?: Maybe<UserRoleEnum>;
  isUserVerified?: Maybe<Scalars["Boolean"]>;
  isEmailAlreadyConfirmed?: Maybe<Scalars["Boolean"]>;
};

export enum ContentAction {
  Move = "MOVE",
  Remove = "REMOVE",
}

export type ContentCategory = {
  __typename?: "ContentCategory";
  id?: Maybe<Scalars["String"]>;
  category?: Maybe<ContentCategoryEnum>;
  displayName?: Maybe<Scalars["String"]>;
};

export enum ContentCategoryEnum {
  LessonComponents = "LESSON_COMPONENTS",
  Trainers = "TRAINERS",
  Courses = "COURSES",
  Classes = "CLASSES",
  ClassesSupplement = "CLASSES_SUPPLEMENT",
  MethodicalMaterials = "METHODICAL_MATERIALS",
  Unrecognized = "UNRECOGNIZED",
  InteractiveTextbooks = "INTERACTIVE_TEXTBOOKS",
}

export type ContentFile = {
  __typename?: "ContentFile";
  id?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  downloadUrl?: Maybe<Scalars["String"]>;
  isInternalUrl?: Maybe<Scalars["Boolean"]>;
  content?: Maybe<UserLibraryContent>;
  contentId?: Maybe<Scalars["UUID"]>;
  fileSize?: Maybe<Scalars["Int"]>;
};

export type ContentFileDuplicateInput = {
  contentId: Scalars["UUID"];
  subjectId: Scalars["UUID"];
  educationPlanId: Scalars["UUID"];
  educationPlanChapterId: Scalars["UUID"];
  connectedToLessonId: Scalars["UUID"];
};

export type ContentFileUpdateInput = {
  contentId: Scalars["UUID"];
  name?: InputMaybe<Scalars["String"]>;
  folderId?: InputMaybe<Scalars["UUID"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  inAppPurchases?: InputMaybe<InAppPurchasesEnum>;
  equalId?: InputMaybe<Scalars["UUID"]>;
};

export type ContentLinkInput = {
  contentId?: InputMaybe<Scalars["String"]>;
  lessonId?: InputMaybe<Scalars["String"]>;
  experimentId?: InputMaybe<Scalars["String"]>;
  planId?: InputMaybe<Scalars["String"]>;
  fileId?: InputMaybe<Scalars["String"]>;
};

export type ContentPreview = {
  __typename?: "ContentPreview";
  id?: Maybe<Scalars["String"]>;
  contentId?: Maybe<Scalars["String"]>;
  content?: Maybe<UserLibraryContent>;
  contentType?: Maybe<UserLibraryContentType>;
  lessonId?: Maybe<Scalars["String"]>;
  lesson?: Maybe<NewLesson>;
  experimentId?: Maybe<Scalars["String"]>;
  experiment?: Maybe<ApplicationMainModel>;
  planId?: Maybe<Scalars["String"]>;
  plan?: Maybe<EducationPlan>;
  url?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  type?: Maybe<ContentPreviewTypeEnum>;
  images?: Maybe<Image>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  updatedById?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum ContentPreviewTypeEnum {
  Image = "IMAGE",
  Video = "VIDEO",
}

export enum ContentSortTypeEnum {
  All = "ALL",
  Popular = "POPULAR",
  Last = "LAST",
  ContentType = "CONTENT_TYPE",
  Best = "BEST",
}

export type ContentTag = {
  __typename?: "ContentTag";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  pluralName?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  tagType?: Maybe<Array<Maybe<TagType>>>;
  category?: Maybe<Array<Maybe<ContentCategory>>>;
  contentTypes?: Maybe<Array<Maybe<ContentType>>>;
  userRoles?: Maybe<Array<Maybe<UserRole>>>;
  isNew?: Maybe<Scalars["Boolean"]>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  updatedById?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ContentType = {
  __typename?: "ContentType";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<ContentTypeEnum>;
  lessonLevel?: Maybe<ContentTypeLessonLevel>;
};

export enum ContentTypeEnum {
  Slide = "SLIDE",
  SlideEditor = "SLIDE_EDITOR",
  Image = "IMAGE",
  Text = "TEXT",
  Video = "VIDEO",
  CompletedTest = "COMPLETED_TEST",
  Test = "TEST",
  TestZno = "TEST_ZNO",
  Experiment = "EXPERIMENT",
  Experiment360 = "EXPERIMENT360",
  ExperimentVr = "EXPERIMENT_VR",
  ExperimentPhet = "EXPERIMENT_PHET",
  Lesson = "LESSON",
  Template = "TEMPLATE",
  ControlWork = "CONTROL_WORK",
  Lab = "LAB",
  PracticalWork = "PRACTICAL_WORK",
  EducationalProject = "EDUCATIONAL_PROJECT",
  Homework = "HOMEWORK",
  Course = "COURSE",
  EducationPlan = "EDUCATION_PLAN",
  LessonFlow = "LESSON_FLOW",
  ColoringPageAr = "COLORING_PAGE_AR",
  Trainers = "TRAINERS",
  InteractiveExercise = "INTERACTIVE_EXERCISE",
  Vr = "VR",
  ArWebgl = "AR_WEBGL",
  File = "FILE",
  ThematicLesson = "THEMATIC_LESSON",
  Internal = "INTERNAL",
  Webinar = "WEBINAR",
  InteractiveTextbook = "INTERACTIVE_TEXTBOOK",
}

export enum ContentTypeGroupName {
  CompletedLesson = "COMPLETED_LESSON",
  LessonPart = "LESSON_PART",
  SecondLevelCompletedLesson = "SECOND_LEVEL_COMPLETED_LESSON",
  ThirdLevelCompletedLesson = "THIRD_LEVEL_COMPLETED_LESSON",
  PupilInLibraryLessons = "PUPIL_IN_LIBRARY_LESSONS",
  PupilInLibraryInteractiveContent = "PUPIL_IN_LIBRARY_INTERACTIVE_CONTENT",
  PupilInLibraryTrainers = "PUPIL_IN_LIBRARY_TRAINERS",
  TeacherAndParentInLibraryInteractiveContent = "TEACHER_AND_PARENT_IN_LIBRARY_INTERACTIVE_CONTENT",
}

export type ContentTypeInput = {
  testId?: InputMaybe<Scalars["String"]>;
  applicationMainModelId?: InputMaybe<Scalars["String"]>;
};

export enum ContentTypeLessonLevel {
  Second = "SECOND",
  Third = "THIRD",
}

export type Contract = {
  __typename?: "Contract";
  id?: Maybe<Scalars["String"]>;
  trackerContractId?: Maybe<Scalars["Int"]>;
  projectId?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
  userId?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type CorrectionComment = {
  __typename?: "CorrectionComment";
  id?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  correction?: Maybe<ProjectCorrection>;
  files?: Maybe<Array<Maybe<UploadedFile>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type CorrectionsLight = {
  __typename?: "CorrectionsLight";
  status?: Maybe<ProjectCorrectionStatusEnum>;
  count?: Maybe<Scalars["Int"]>;
};

export type CostReport = {
  __typename?: "CostReport";
  projectId?: Maybe<Scalars["String"]>;
  projectName?: Maybe<Scalars["String"]>;
  totalEstimate?: Maybe<Scalars["Int"]>;
  costEstimate?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
  difference?: Maybe<Scalars["Int"]>;
};

export type Course = {
  __typename?: "Course";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  grade?: Maybe<SchoolGrade>;
  mainId?: Maybe<Scalars["String"]>;
  main?: Maybe<ApplicationMain>;
  photo?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Maybe<ContentTag>>>;
  contentPreviews?: Maybe<Array<Maybe<ContentPreview>>>;
  lang?: Maybe<LanguagesEnum>;
  courseIsAvailable?: Maybe<Scalars["Boolean"]>;
  courseData?: Maybe<Scalars["String"]>;
  courseProductType?: Maybe<CourseProductTypeEnum>;
  courseTypes?: Maybe<Array<Maybe<CourseType>>>;
  isUpdated?: Maybe<Scalars["Boolean"]>;
  progress?: Maybe<Scalars["Int"]>;
  educationPlanChapters?: Maybe<Array<Maybe<EducationPlanChapter>>>;
  layoutId?: Maybe<Scalars["UUID"]>;
  layout?: Maybe<EducationPlanLayout>;
  themeId?: Maybe<Scalars["UUID"]>;
  theme?: Maybe<EducationPlanTheme>;
  inAppPurchases?: Maybe<InAppPurchasesEnum>;
  randomQuote?: Maybe<Scalars["String"]>;
};

export type CourseCertificate = {
  __typename?: "CourseCertificate";
  id?: Maybe<Scalars["UUID"]>;
  userId?: Maybe<Scalars["UUID"]>;
  user?: Maybe<User>;
  fullName?: Maybe<Scalars["String"]>;
  courseId?: Maybe<Scalars["String"]>;
  course?: Maybe<EducationPlan>;
  pdfLink?: Maybe<Scalars["String"]>;
  imgLink?: Maybe<Scalars["String"]>;
  imgPrevLink?: Maybe<Scalars["String"]>;
  certId?: Maybe<Scalars["String"]>;
};

export type CourseCertificateTemplate = {
  __typename?: "CourseCertificateTemplate";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  templateUrl?: Maybe<Scalars["String"]>;
  previewUrl?: Maybe<Scalars["String"]>;
  educationPlanLayoutId?: Maybe<Scalars["UUID"]>;
  courseProductType?: Maybe<CourseProductTypeEnum>;
};

export enum CourseLessonReflectionSortType {
  All = "ALL",
  Completed = "COMPLETED",
}

export enum CourseProductTypeEnum {
  Uiro = "UIRO",
  Arbook = "ARBOOK",
}

export type CourseRequest = {
  __typename?: "CourseRequest";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  region?: Maybe<SchoolRegion>;
  city?: Maybe<SchoolCity>;
  school?: Maybe<School>;
  subjects?: Maybe<Array<Maybe<ApplicationMain>>>;
  course?: Maybe<EducationPlan>;
  createdBy?: Maybe<User>;
};

export type CourseType = {
  __typename?: "CourseType";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<CourseTypeEnum>;
  displayName?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  isSelected?: Maybe<Scalars["Boolean"]>;
  userRoles?: Maybe<Array<Maybe<UserRole>>>;
};

export enum CourseTypeEnum {
  ForPupil = "FOR_PUPIL",
  ForParent = "FOR_PARENT",
  ForTeacher = "FOR_TEACHER",
}

export type CreateAchieveInput = {
  name: Scalars["String"];
  descriptionDone: Scalars["String"];
  descriptionProgress: Scalars["String"];
  type: AchieveTypeEnum;
  isLocked?: InputMaybe<Scalars["Boolean"]>;
  isForPUPIL?: InputMaybe<Scalars["Boolean"]>;
  isForTEACHER?: InputMaybe<Scalars["Boolean"]>;
  isForPARENT?: InputMaybe<Scalars["Boolean"]>;
  isForGUEST?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateAiChatTemplateParameterInput = {
  question: Scalars["String"];
  inputPlaceholderText: Scalars["String"];
  placeHolderName: Scalars["String"];
  order: Scalars["Int"];
};

export type CreateAiPromptTemplateInput = {
  type: AiPromptType;
  category: AiPromptCategory;
  description?: InputMaybe<Scalars["String"]>;
  template: Scalars["String"];
  active?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateAnimationBundleInput = {
  typeId?: InputMaybe<Scalars["Int"]>;
  nameEN?: InputMaybe<Scalars["String"]>;
  nameUKR?: InputMaybe<Scalars["String"]>;
  descriptionEN?: InputMaybe<Scalars["String"]>;
  descriptionUKR?: InputMaybe<Scalars["String"]>;
  lat?: InputMaybe<Scalars["String"]>;
  long?: InputMaybe<Scalars["String"]>;
};

export type CreateApplicationInput = {
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  appStoreLink?: InputMaybe<Scalars["String"]>;
  playMarketLink?: InputMaybe<Scalars["String"]>;
  mainPhoto?: InputMaybe<Scalars["String"]>;
  modelPhoto?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  comments?: InputMaybe<Scalars["String"]>;
  frontLink?: InputMaybe<Scalars["String"]>;
  variableName?: InputMaybe<Scalars["String"]>;
};

export type CreateApplicationLinkInput = {
  name?: InputMaybe<Scalars["String"]>;
  link?: InputMaybe<Scalars["String"]>;
  applicationId?: InputMaybe<Scalars["String"]>;
};

export type CreateApplicationMainInput = {
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<LocaleEnum>;
  background1?: InputMaybe<Scalars["String"]>;
  background2?: InputMaybe<Scalars["String"]>;
  background3?: InputMaybe<Scalars["String"]>;
  background4?: InputMaybe<Scalars["String"]>;
  background5?: InputMaybe<Scalars["String"]>;
  isMap?: InputMaybe<Scalars["Boolean"]>;
  applicationId: Scalars["String"];
  display?: InputMaybe<Scalars["Boolean"]>;
  city?: InputMaybe<ApplicationMainCityEnum>;
  priority?: InputMaybe<Scalars["Int"]>;
  showInSubjects?: InputMaybe<Scalars["Boolean"]>;
  showInArBook?: InputMaybe<Scalars["Boolean"]>;
  analyticsMark?: InputMaybe<Scalars["String"]>;
};

export type CreateApplicationMainModelFileInput = {
  modelId: Scalars["String"];
  type: ApplicationMainModelFileTypeEnum;
  language: ApplicationMainModelFileLangEnum;
  file: Scalars["Upload"];
  manifest?: InputMaybe<Scalars["Upload"]>;
};

export type CreateApplicationMainModelInput = {
  mainId: Scalars["String"];
  name: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<LocaleEnum>;
  lat?: InputMaybe<Scalars["String"]>;
  longitude?: InputMaybe<Scalars["String"]>;
  address?: InputMaybe<Scalars["String"]>;
  isContour?: InputMaybe<Scalars["Boolean"]>;
  themeId?: InputMaybe<Scalars["String"]>;
  showSmartNotification?: InputMaybe<Scalars["Boolean"]>;
  clicks?: InputMaybe<Scalars["Int"]>;
  isMarker?: InputMaybe<Scalars["Boolean"]>;
  markerWidth?: InputMaybe<Scalars["Float"]>;
  schoolGradeId?: InputMaybe<Scalars["String"]>;
  inGradeOrder?: InputMaybe<Scalars["Int"]>;
  contentAuthor?: InputMaybe<Scalars["String"]>;
};

export type CreateAwardInput = {
  name: Scalars["String"];
  photo: Scalars["Upload"];
  day: Scalars["Int"];
  background: Scalars["String"];
  animation: Scalars["Upload"];
  energyQuantity: Scalars["Int"];
  type: AwardTypeEnum;
};

export type CreateContentTagInput = {
  contentTypeIds: Array<Scalars["UUID"]>;
  displayName: Scalars["String"];
  categoryId: Scalars["UUID"];
  type: UserLibraryContentType;
  userRoleIds: Array<Scalars["Int"]>;
  pluralName?: InputMaybe<Scalars["String"]>;
  imageUrl?: InputMaybe<Scalars["String"]>;
};

export type CreateCorrectionCommentInput = {
  text?: InputMaybe<Scalars["String"]>;
  correctionId?: InputMaybe<Scalars["String"]>;
  files?: InputMaybe<Array<InputMaybe<Scalars["Upload"]>>>;
};

export type CreateCourseCertificateTemplateData = {
  name?: InputMaybe<Scalars["String"]>;
  template: Scalars["Upload"];
  preview: Scalars["Upload"];
  educationPlanLayoutId: Scalars["UUID"];
  courseProductType: CourseProductTypeEnum;
};

export type CreateCourseRequest = {
  name: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
  phone: Scalars["String"];
  regionId: Scalars["UUID"];
  cityId?: InputMaybe<Scalars["UUID"]>;
  schoolId?: InputMaybe<Scalars["UUID"]>;
  subjectIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  courseId?: InputMaybe<Scalars["UUID"]>;
};

export type CreateEducationPlanInput = {
  mainId: Scalars["String"];
  name: Scalars["String"];
  gradeId?: InputMaybe<Scalars["String"]>;
  difficultyLevel?: InputMaybe<DifficultyLevelEnum>;
  description?: InputMaybe<Scalars["String"]>;
  classroomId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

export type CreateEmployeesDistribution = {
  name: Scalars["String"];
  projectId: Scalars["String"];
  employeesNumber?: InputMaybe<Scalars["Int"]>;
};

export type CreateEventUserInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone: Scalars["String"];
  email: Scalars["String"];
  eventDateId: Scalars["String"];
  applicationId: Scalars["String"];
};

export type CreateExerciseInput = {
  contentEN?: InputMaybe<Scalars["String"]>;
  contentUKR?: InputMaybe<Scalars["String"]>;
  video?: InputMaybe<Scalars["Upload"]>;
  pauseTime?: InputMaybe<Scalars["Int"]>;
  type: ExerciseTypeEnum;
  traningId: Scalars["String"];
};

export type CreateFeatureInput = {
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  subscriptionPlanId: Scalars["String"];
};

export type CreateManualPromotionInput = {
  planIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  allPlans?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  discount: Scalars["Float"];
  startDate: Scalars["Date"];
  expiredDate: Scalars["Date"];
};

export type CreateMobileNotificationInput = {
  applicationId: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
};

export type CreateNewsInput = {
  name: Scalars["String"];
  isFor: NewsIsForEnum;
  description?: InputMaybe<Scalars["String"]>;
  youTubeLink?: InputMaybe<Scalars["String"]>;
};

export type CreateNotificationInput = {
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
};

export type CreatePayrollKpiInput = {
  payrollId: Scalars["String"];
  name: Scalars["String"];
  unit?: InputMaybe<Scalars["String"]>;
  weigh?: InputMaybe<Scalars["Float"]>;
  plan?: InputMaybe<Scalars["Int"]>;
  fact?: InputMaybe<Scalars["Int"]>;
};

export type CreateProductInput = {
  name: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  features?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  typeId: Scalars["Int"];
  flex: Scalars["Int"];
  requiredKpi?: InputMaybe<Scalars["Int"]>;
  requiredLevel?: InputMaybe<Scalars["Int"]>;
  image?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
};

export type CreateProjectAdditionalContactInput = {
  name: Scalars["String"];
  phone: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  projectId: Scalars["String"];
};

export type CreateProjectCorrectionInput = {
  text: Scalars["String"];
  projectId: Scalars["String"];
  files?: InputMaybe<Array<InputMaybe<Scalars["Upload"]>>>;
};

export type CreateProjectCustomFieldsMapInput = {
  projectId: Scalars["String"];
  startDateEstimate: Scalars["String"];
  startDateFact: Scalars["String"];
  finishDateEstimate: Scalars["String"];
  finishDateFact: Scalars["String"];
};

export type CreateProjectLinkInput = {
  title: Scalars["String"];
  link: Scalars["String"];
  projectId: Scalars["String"];
  login?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  isFor?: InputMaybe<IsForEnum>;
};

export type CreateProjectStepInput = {
  name: Scalars["String"];
  projectId: Scalars["String"];
  startDateEstimate?: InputMaybe<Scalars["String"]>;
  startDateFact?: InputMaybe<Scalars["String"]>;
  finishDateEstimate?: InputMaybe<Scalars["String"]>;
  finishDateFact?: InputMaybe<Scalars["String"]>;
  statusId?: InputMaybe<Scalars["String"]>;
};

export type CreateProjectTaskInput = {
  name: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  epicId: Scalars["String"];
  assigneeId?: InputMaybe<Scalars["String"]>;
  responsibleId?: InputMaybe<Scalars["String"]>;
  startDateEstimate?: InputMaybe<Scalars["String"]>;
  startDateFact?: InputMaybe<Scalars["String"]>;
  finishDateEstimate?: InputMaybe<Scalars["String"]>;
  finishDateFact?: InputMaybe<Scalars["String"]>;
  timeEstimate?: InputMaybe<Scalars["Int"]>;
  timeSpent?: InputMaybe<Scalars["Int"]>;
  taskStatusId?: InputMaybe<Scalars["String"]>;
  workflowStepOrder?: InputMaybe<Scalars["Int"]>;
  kanbanWorkflowStepId?: InputMaybe<Scalars["String"]>;
};

export type CreateRobotsHistoryInput = {
  link: Scalars["String"];
  order: Scalars["Int"];
};

export type CreateSchoolEventInput = {
  id?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<SchoolEventTypeEnum>;
  title?: InputMaybe<Scalars["String"]>;
  dateFrom?: InputMaybe<Scalars["Date"]>;
  dateTo?: InputMaybe<Scalars["Date"]>;
  appointment?: InputMaybe<Scalars["String"]>;
  format?: InputMaybe<SchoolEventFormatEnum>;
  schoolId?: InputMaybe<Scalars["String"]>;
  applicationMainId?: InputMaybe<Scalars["String"]>;
  educationPlanId?: InputMaybe<Scalars["String"]>;
  lessonId?: InputMaybe<Scalars["String"]>;
  groups?: InputMaybe<Array<InputMaybe<SchoolEventClassroomInput>>>;
};

export type CreateSchoolInput = {
  idShort?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  fundingType?: InputMaybe<SchoolFundingType>;
  cityId?: InputMaybe<Scalars["String"]>;
  countryId?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["String"]>;
  districtId?: InputMaybe<Scalars["String"]>;
  communityId?: InputMaybe<Scalars["UUID"]>;
  isInternalUse?: InputMaybe<Scalars["Boolean"]>;
  isForTest?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<Scalars["String"]>;
  edrpou?: InputMaybe<Scalars["String"]>;
  shortName?: InputMaybe<Scalars["String"]>;
  isVerified?: InputMaybe<Scalars["Boolean"]>;
  addressIndex?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  house?: InputMaybe<Scalars["String"]>;
  managerId?: InputMaybe<Scalars["String"]>;
  whiteLabelCollectionId?: InputMaybe<Scalars["UUID"]>;
};

export type CreateSlideReflectionInput = {
  slideId: Scalars["String"];
  time: Scalars["Int"];
  isItClear?: InputMaybe<Scalars["Boolean"]>;
  progressPercent?: InputMaybe<Scalars["Int"]>;
  testChoiceIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  openAnswer?: InputMaybe<Scalars["String"]>;
  stars?: InputMaybe<Scalars["Int"]>;
};

export type CreateSmartNotificationInput = {
  description?: InputMaybe<Scalars["String"]>;
  applicationId: Scalars["String"];
  type: SmartNotificationTypeEnum;
  link?: InputMaybe<Scalars["String"]>;
  testId?: InputMaybe<Scalars["String"]>;
  applicationMainModelId?: InputMaybe<Scalars["String"]>;
};

export type CreateTeacherInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  password?: InputMaybe<Scalars["String"]>;
  applicationId: Scalars["String"];
  schoolId?: InputMaybe<Scalars["String"]>;
  isEmailConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isSchoolAdmin?: InputMaybe<Scalars["Boolean"]>;
  planData?: InputMaybe<PlanDataInput>;
  agreement: Scalars["Boolean"];
  privatePolicy: Scalars["Boolean"];
  userRole?: InputMaybe<UserRoleEnum>;
  registeredCode?: InputMaybe<Scalars["String"]>;
  schoolGrades?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  teachingFormat?: InputMaybe<TeachingFormatEnum>;
  middleName?: InputMaybe<Scalars["String"]>;
  roleId?: InputMaybe<Scalars["Int"]>;
};

export type CreateTestInput = {
  name: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  modelId?: InputMaybe<Scalars["String"]>;
  mainId?: InputMaybe<Scalars["String"]>;
  themeId?: InputMaybe<Scalars["String"]>;
  classroomId?: InputMaybe<Scalars["String"]>;
  isOpenTest?: InputMaybe<Scalars["Boolean"]>;
  attempts?: InputMaybe<Scalars["Int"]>;
  time?: InputMaybe<Scalars["Int"]>;
  class?: InputMaybe<TestClassEnum>;
  repeatAttemptTime?: InputMaybe<Scalars["Int"]>;
  showSmartNotification?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateTrainerInput = {
  name?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["String"]>;
  subjectId?: InputMaybe<Scalars["String"]>;
  trainersType?: InputMaybe<TrainersType>;
  trainersMechanicsType?: InputMaybe<TrainersMechanicsType>;
  photo?: InputMaybe<Scalars["Upload"]>;
  inAppPurchases?: InputMaybe<InAppPurchasesEnum>;
};

export type CreateTraningInput = {
  nameEN?: InputMaybe<Scalars["String"]>;
  nameUKR?: InputMaybe<Scalars["String"]>;
  contentEN?: InputMaybe<Scalars["String"]>;
  contentUKR?: InputMaybe<Scalars["String"]>;
  startTitleEN?: InputMaybe<Scalars["String"]>;
  startTitleUKR?: InputMaybe<Scalars["String"]>;
  endTitleEN?: InputMaybe<Scalars["String"]>;
  endTitleUKR?: InputMaybe<Scalars["String"]>;
  photo?: InputMaybe<Scalars["Upload"]>;
  startVideo?: InputMaybe<Scalars["Upload"]>;
  endVideo?: InputMaybe<Scalars["Upload"]>;
};

export type CreateUserGroupInput = {
  name?: InputMaybe<Scalars["String"]>;
};

export type CreateUserInput = {
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<GenderEnum>;
  applicationId?: InputMaybe<Scalars["String"]>;
  userRole?: InputMaybe<UserRoleEnum>;
  countryId?: InputMaybe<Scalars["String"]>;
  birthday?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  schoolRegionId?: InputMaybe<Scalars["String"]>;
  schoolDistrictId?: InputMaybe<Scalars["String"]>;
  schoolCityId?: InputMaybe<Scalars["String"]>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  isEmailConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isSchoolAdmin?: InputMaybe<Scalars["Boolean"]>;
  planData?: InputMaybe<PlanDataInput>;
  agreement?: InputMaybe<Scalars["Boolean"]>;
  privatePolicy?: InputMaybe<Scalars["Boolean"]>;
  registeredCode?: InputMaybe<Scalars["String"]>;
  schoolGrades?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  teachingFormat?: InputMaybe<TeachingFormatEnum>;
  isInvitedUser?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateUserInputV2 = {
  isFrom: IsFromEnum;
  applicationId: Scalars["String"];
  roleId: Scalars["Int"];
  email: Scalars["String"];
  parentEmail?: InputMaybe<Scalars["String"]>;
  birthday?: InputMaybe<Scalars["String"]>;
  agreement?: InputMaybe<Scalars["Boolean"]>;
  privatePolicy?: InputMaybe<Scalars["Boolean"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  pupilId?: InputMaybe<Scalars["String"]>;
  deviceToken?: InputMaybe<Scalars["String"]>;
  planData?: InputMaybe<PlanDataInput>;
  hash?: InputMaybe<Scalars["UUID"]>;
};

export type CreateUserInputV3 = {
  isFrom: IsFromEnum;
  applicationId: Scalars["String"];
  roleId: Scalars["Int"];
  email?: InputMaybe<Scalars["String"]>;
  parentEmail?: InputMaybe<Scalars["String"]>;
  birthday?: InputMaybe<Scalars["String"]>;
  agreement?: InputMaybe<Scalars["Boolean"]>;
  privatePolicy?: InputMaybe<Scalars["Boolean"]>;
  deviceToken?: InputMaybe<Scalars["String"]>;
  hash?: InputMaybe<Scalars["UUID"]>;
  userToken?: InputMaybe<Scalars["UUID"]>;
  referrerCode?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type CreateUserPaymentInput = {
  name: Scalars["String"];
  cost: Scalars["Int"];
  projectId: Scalars["String"];
  estimatedDate?: InputMaybe<Scalars["Date"]>;
};

export type CreateUserPayrollInput = {
  userId: Scalars["String"];
  name: Scalars["Date"];
  hoursPlan: Scalars["Int"];
  firstPartSalary?: InputMaybe<Scalars["Int"]>;
  secondPartSalary?: InputMaybe<Scalars["Int"]>;
  salaryDescription?: InputMaybe<Scalars["String"]>;
  kpiDescription?: InputMaybe<Scalars["String"]>;
  bonusesDescription?: InputMaybe<Scalars["String"]>;
  totalDescription?: InputMaybe<Scalars["String"]>;
  bonusFund?: InputMaybe<Scalars["Int"]>;
};

export type CreateUserV2Response = {
  __typename?: "CreateUserV2Response";
  user?: Maybe<User>;
  registrationToken?: Maybe<Scalars["UUID"]>;
};

export type CreateUserV3Response = {
  __typename?: "CreateUserV3Response";
  user?: Maybe<User>;
  hash?: Maybe<Scalars["UUID"]>;
};

export type CreateVacanciesPlanInput = {
  userId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  jobId: Scalars["String"];
  positionId: Scalars["Int"];
  experience: Scalars["Float"];
  comment?: InputMaybe<Scalars["String"]>;
};

export type CreatedTasksInTracker = {
  __typename?: "CreatedTasksInTracker";
  count?: Maybe<Scalars["Int"]>;
};

export type CropRegion = {
  left: Scalars["Float"];
  top: Scalars["Float"];
  width: Scalars["Float"];
  height: Scalars["Float"];
};

export type DashboardAddedTasksReport = {
  __typename?: "DashboardAddedTasksReport";
  projectId?: Maybe<Scalars["String"]>;
  projectName?: Maybe<Scalars["String"]>;
  tasksCount?: Maybe<Scalars["Int"]>;
};

export type DashboardCompletionPctReport = {
  __typename?: "DashboardCompletionPctReport";
  projectId?: Maybe<Scalars["String"]>;
  projectName?: Maybe<Scalars["String"]>;
  pct?: Maybe<Scalars["Int"]>;
};

export type DashboardInProgressTasksReport = {
  __typename?: "DashboardInProgressTasksReport";
  projectId?: Maybe<Scalars["String"]>;
  projectName?: Maybe<Scalars["String"]>;
  tasksCount?: Maybe<Scalars["Int"]>;
};

export type DashboardMasteringMoneyReport = {
  __typename?: "DashboardMasteringMoneyReport";
  projectId?: Maybe<Scalars["String"]>;
  projectName?: Maybe<Scalars["String"]>;
  costFact?: Maybe<Scalars["Int"]>;
  costEstimate?: Maybe<Scalars["Int"]>;
  pct?: Maybe<Scalars["Int"]>;
};

export type DashboardOverdueTasksReport = {
  __typename?: "DashboardOverdueTasksReport";
  projectId?: Maybe<Scalars["String"]>;
  projectName?: Maybe<Scalars["String"]>;
  tasksCount?: Maybe<Scalars["Int"]>;
};

export type DashboardUserWorkloadProjectsReport = {
  __typename?: "DashboardUserWorkloadProjectsReport";
  projectName?: Maybe<Scalars["String"]>;
  totalSeconds?: Maybe<Scalars["Int"]>;
};

export type DashboardUserWorkloadReport = {
  __typename?: "DashboardUserWorkloadReport";
  userId?: Maybe<Scalars["String"]>;
  assigneeName?: Maybe<Scalars["String"]>;
  pct?: Maybe<Scalars["Int"]>;
};

export type DateEstimateProblems = {
  __typename?: "DateEstimateProblems";
  key?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  startDateEstimate?: Maybe<Scalars["String"]>;
  finishDateEstimate?: Maybe<Scalars["String"]>;
};

export type DateReport = {
  __typename?: "DateReport";
  projectId?: Maybe<Scalars["String"]>;
  projectName?: Maybe<Scalars["String"]>;
  startDateEstimate?: Maybe<Scalars["Date"]>;
  startDate?: Maybe<Scalars["Date"]>;
  startDifference?: Maybe<Scalars["Int"]>;
  finishDateEstimate?: Maybe<Scalars["Date"]>;
  finishDate?: Maybe<Scalars["Date"]>;
  finishDifference?: Maybe<Scalars["Int"]>;
};

export type DaysRangeUntilLicenseExpiration = {
  from?: InputMaybe<Scalars["Int"]>;
  to?: InputMaybe<Scalars["Int"]>;
};

export type DeletionReason = {
  __typename?: "DeletionReason";
  id?: Maybe<Scalars["UUID"]>;
  reason?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  needAdditionalInfo?: Maybe<Scalars["Boolean"]>;
};

export enum DifficultyLevelEnum {
  Class_7 = "CLASS_7",
  Class_8 = "CLASS_8",
  Class_9 = "CLASS_9",
  Class_10 = "CLASS_10",
  Class_11 = "CLASS_11",
}

export type DistributionAdditionalData = {
  __typename?: "DistributionAdditionalData";
  employeesReport?: Maybe<Array<Maybe<EmployeesReport>>>;
  statusReport?: Maybe<Array<Maybe<StatusReport>>>;
};

export type DoneProblems = {
  __typename?: "DoneProblems";
  key?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  startDateEstimate?: Maybe<Scalars["String"]>;
  finishDateEstimate?: Maybe<Scalars["String"]>;
  startDateFact?: Maybe<Scalars["String"]>;
  finishDateFact?: Maybe<Scalars["String"]>;
  assigneeJiraId?: Maybe<Scalars["String"]>;
};

export type ESputnikSubscriptionCategory = {
  __typename?: "ESputnikSubscriptionCategory";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
};

export type EducationArea = {
  __typename?: "EducationArea";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type EducationPlan = {
  __typename?: "EducationPlan";
  id?: Maybe<Scalars["String"]>;
  parentId?: Maybe<Scalars["String"]>;
  childId?: Maybe<Scalars["String"]>;
  marketId?: Maybe<Scalars["String"]>;
  isUploadedToMarket?: Maybe<Scalars["Boolean"]>;
  isFromMarket?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  grade?: Maybe<SchoolGrade>;
  educationPlanChapters?: Maybe<Array<Maybe<EducationPlanChapter>>>;
  mainId?: Maybe<Scalars["String"]>;
  main?: Maybe<ApplicationMain>;
  schoolId?: Maybe<Scalars["String"]>;
  school?: Maybe<School>;
  classrooms?: Maybe<Array<Maybe<Classroom>>>;
  difficultyLevel?: Maybe<DifficultyLevelEnum>;
  libraryContentId?: Maybe<Scalars["UUID"]>;
  libraryContent?: Maybe<UserLibraryContent>;
  inAppPurchases?: Maybe<InAppPurchasesEnum>;
  photo?: Maybe<Scalars["String"]>;
  isDemo?: Maybe<Scalars["Boolean"]>;
  countOfImport?: Maybe<Scalars["Int"]>;
  tags?: Maybe<Array<Maybe<ContentTag>>>;
  contentPreviews?: Maybe<Array<Maybe<ContentPreview>>>;
  teacherParticipants?: Maybe<Array<Maybe<User>>>;
  type?: Maybe<EducationPlanTypeEnum>;
  lang?: Maybe<LanguagesEnum>;
  markType?: Maybe<LessonMarkTypeEnum>;
  courseIsAvailable?: Maybe<Scalars["Boolean"]>;
  courseData?: Maybe<Scalars["String"]>;
  courseProductType?: Maybe<CourseProductTypeEnum>;
  courseTypes?: Maybe<Array<Maybe<CourseType>>>;
  currentUserAverageScore?: Maybe<Scalars["Int"]>;
  results?: Maybe<EducationPlanResults>;
  layoutId?: Maybe<Scalars["UUID"]>;
  layout?: Maybe<EducationPlanLayout>;
  themeId?: Maybe<Scalars["UUID"]>;
  theme?: Maybe<EducationPlanTheme>;
  courseCertificateTemplateId?: Maybe<Scalars["UUID"]>;
  canPublish?: Maybe<Scalars["Boolean"]>;
  isPublic?: Maybe<Scalars["Boolean"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  quoteCollectionId?: Maybe<Scalars["UUID"]>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type EducationPlanChapter = {
  __typename?: "EducationPlanChapter";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  educationPlanId?: Maybe<Scalars["String"]>;
  educationPlan?: Maybe<EducationPlan>;
  newLessons?: Maybe<Array<Maybe<NewLesson>>>;
  position?: Maybe<Scalars["Int"]>;
  isDemo?: Maybe<Scalars["Boolean"]>;
  isCompletedByUser?: Maybe<Scalars["Boolean"]>;
  isNestedLessonsDisabled?: Maybe<Scalars["Boolean"]>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  chapterMark?: Maybe<LessonMark>;
};

export type EducationPlanLayout = {
  __typename?: "EducationPlanLayout";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<EducationPlanLayoutEnum>;
  displayName?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  courses?: Maybe<Array<Maybe<EducationPlan>>>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export enum EducationPlanLayoutEnum {
  ForChildren = "FOR_CHILDREN",
  Classic = "CLASSIC",
  Universal = "UNIVERSAL",
}

export type EducationPlanParticipant = {
  __typename?: "EducationPlanParticipant";
  id?: Maybe<Scalars["UUID"]>;
  userId?: Maybe<Scalars["UUID"]>;
  user?: Maybe<User>;
  status?: Maybe<EducationPlanParticipantStatusEnum>;
  educationPlanId?: Maybe<Scalars["UUID"]>;
  educationPlan?: Maybe<EducationPlan>;
  classrooms?: Maybe<Array<Maybe<Classroom>>>;
  schoolClassrooms?: Maybe<SchoolClassroom>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum EducationPlanParticipantStatusEnum {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type EducationPlanResults = {
  __typename?: "EducationPlanResults";
  mark?: Maybe<Scalars["Int"]>;
  progress?: Maybe<Scalars["Int"]>;
};

export type EducationPlanTheme = {
  __typename?: "EducationPlanTheme";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  backgroundMobile?: Maybe<Scalars["String"]>;
  backgroundWeb?: Maybe<Scalars["String"]>;
  backgroundGradientWeb?: Maybe<Scalars["String"]>;
  heroAvatar?: Maybe<Scalars["String"]>;
  hero?: Maybe<Scalars["String"]>;
  elements?: Maybe<Array<Maybe<Scalars["String"]>>>;
  certificate?: Maybe<Scalars["String"]>;
  chapterIcon?: Maybe<Scalars["String"]>;
  lessonPassedIcon?: Maybe<Scalars["String"]>;
  lessonLockedIcon?: Maybe<Scalars["String"]>;
  startIcon?: Maybe<Scalars["String"]>;
  courses?: Maybe<Array<Maybe<EducationPlan>>>;
};

export enum EducationPlanTypeEnum {
  EducationPlan = "EDUCATION_PLAN",
  Course = "COURSE",
}

export enum EmailTypeEnum {
  Registration = "REGISTRATION",
  Resetpassword = "RESETPASSWORD",
}

export type EmployeeWorkload = {
  __typename?: "EmployeeWorkload";
  date?: Maybe<Scalars["String"]>;
  seconds?: Maybe<Scalars["Int"]>;
};

export type EmployeeWorkloadDayReport = {
  __typename?: "EmployeeWorkloadDayReport";
  projectName?: Maybe<Scalars["String"]>;
  epicKey?: Maybe<Scalars["String"]>;
  taskName?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  seconds?: Maybe<Scalars["Int"]>;
};

export type EmployeeWorkloadReport = {
  __typename?: "EmployeeWorkloadReport";
  projectName?: Maybe<Scalars["String"]>;
  days?: Maybe<Scalars["Int"]>;
  secondsPerDay?: Maybe<Scalars["Int"]>;
  startDate?: Maybe<Scalars["String"]>;
};

export type EmployeeWorkloadReportByJobs = {
  __typename?: "EmployeeWorkloadReportByJobs";
  jobName?: Maybe<Scalars["String"]>;
  totalHours?: Maybe<Scalars["Int"]>;
  totalDays?: Maybe<Scalars["Float"]>;
  totalWeeks?: Maybe<Scalars["Float"]>;
  totalMonth?: Maybe<Scalars["Float"]>;
};

export type EmployeeWorkloadReportByProject = {
  __typename?: "EmployeeWorkloadReportByProject";
  projectName?: Maybe<Scalars["String"]>;
  report?: Maybe<Array<Maybe<EmployeeWorkload>>>;
};

export type EmployeeWorkloadReportByUser = {
  __typename?: "EmployeeWorkloadReportByUser";
  userId?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
  projectCount?: Maybe<Scalars["Int"]>;
  totalHours?: Maybe<Scalars["Int"]>;
  totalDays?: Maybe<Scalars["Float"]>;
  totalWeeks?: Maybe<Scalars["Float"]>;
  totalMonth?: Maybe<Scalars["Float"]>;
};

export type EmployeesDistribution = {
  __typename?: "EmployeesDistribution";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  projectName?: Maybe<Scalars["String"]>;
  employeesNumber?: Maybe<Scalars["Int"]>;
  projectType?: Maybe<ProjectType>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type EmployeesDistributionLight = {
  __typename?: "EmployeesDistributionLight";
  name?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
};

export type EmployeesReport = {
  __typename?: "EmployeesReport";
  projectName?: Maybe<Scalars["String"]>;
  employeesPercent?: Maybe<Scalars["Float"]>;
};

export enum EnvironmentEnum {
  ArbLkt = "ARB_LKT",
  ApLks = "AP_LKS",
  Everywhere = "EVERYWHERE",
}

export type Event = {
  __typename?: "Event";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  dates?: Maybe<Array<Maybe<EventDate>>>;
  description?: Maybe<Scalars["String"]>;
  speaker?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type EventDate = {
  __typename?: "EventDate";
  id?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  userLimit?: Maybe<Scalars["Int"]>;
  eventId?: Maybe<Scalars["String"]>;
  event?: Maybe<Event>;
  users?: Maybe<Array<Maybe<User>>>;
  werePresent?: Maybe<Array<Maybe<User>>>;
  wereNotPresent?: Maybe<Array<Maybe<User>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type Exercise = {
  __typename?: "Exercise";
  id?: Maybe<Scalars["String"]>;
  content?: Maybe<Scalars["String"]>;
  contentEN?: Maybe<Scalars["String"]>;
  contentUKR?: Maybe<Scalars["String"]>;
  video?: Maybe<Scalars["String"]>;
  pauseTime?: Maybe<Scalars["Int"]>;
  type?: Maybe<ExerciseTypeEnum>;
  traningId?: Maybe<Scalars["String"]>;
  traning?: Maybe<Traning>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum ExerciseTypeEnum {
  Exercise = "EXERCISE",
  Pause = "PAUSE",
}

export enum ExperimentTypeEnum {
  Vr = "VR",
  ArWebgl = "AR_WEBGL",
  ColoringPageAr = "COLORING_PAGE_AR",
  InteractiveExercise = "INTERACTIVE_EXERCISE",
  Quiz = "QUIZ",
}

export type ExportContentInput = {
  contentId: Scalars["String"];
  name: Scalars["String"];
  contentAuthor: Scalars["String"];
  mainIds: Array<Scalars["String"]>;
  type: UserLibraryContentType;
  schoolGradeIds: Array<Scalars["String"]>;
  inAppPurchases: InAppPurchasesEnum;
  teachingFormat?: InputMaybe<TeachingFormatEnum>;
  educationAreasIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description: Scalars["String"];
  tagIds: Array<Scalars["UUID"]>;
  contentType?: InputMaybe<ContentTypeEnum>;
  price?: InputMaybe<Scalars["Float"]>;
};

export type Faq = {
  __typename?: "Faq";
  id?: Maybe<Scalars["String"]>;
  answer?: Maybe<Scalars["String"]>;
  question?: Maybe<Scalars["String"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["Date"]>;
  isFrom?: Maybe<IsFromEnum>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type FeatureFlag = {
  __typename?: "FeatureFlag";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  flag?: Maybe<Scalars["String"]>;
  isGranted?: Maybe<Scalars["Boolean"]>;
};

export type Feedback = {
  __typename?: "Feedback";
  id?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  status?: Maybe<FeedbackStatusEnum>;
  isFrom?: Maybe<FeedbackIsFromEnum>;
  comments?: Maybe<Array<Maybe<FeedbackComment>>>;
  files?: Maybe<Array<Maybe<UploadedFile>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type FeedbackComment = {
  __typename?: "FeedbackComment";
  id?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  feedback?: Maybe<Feedback>;
  files?: Maybe<Array<Maybe<UploadedFile>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type FeedbackDataInput = {
  rating: Scalars["Int"];
  feedbackQuestionId: Scalars["String"];
  newLessonId: Scalars["String"];
};

export enum FeedbackIsFromEnum {
  LkStaff = "LK_STAFF",
  LkTeacher = "LK_TEACHER",
}

export enum FeedbackStatusEnum {
  New = "NEW",
  Review = "REVIEW",
  Discussion = "DISCUSSION",
  Accepted = "ACCEPTED",
  Done = "DONE",
  Rejected = "REJECTED",
}

export type FeedbacksLight = {
  __typename?: "FeedbacksLight";
  status?: Maybe<FeedbackStatusEnum>;
  count?: Maybe<Scalars["Int"]>;
};

export enum FileContentTypeEnum {
  LessonFlow = "LESSON_FLOW",
  ColoringPageAr = "COLORING_PAGE_AR",
}

export type FileLinkCount = {
  __typename?: "FileLinkCount";
  id?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type FilterAdminReportCreatedPaymentsInput = {
  periodType?: InputMaybe<PeriodAdminReportEnum>;
  paymentTypes?: InputMaybe<Array<InputMaybe<PaymentType>>>;
  paymentTypeIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

export type FilterAdminReportRegisteredSchoolsInput = {
  periodType?: InputMaybe<PeriodAdminReportEnum>;
};

export type FilterAdminReportRegisteredUsersInput = {
  userType?: InputMaybe<UserTypeAdminReportEnum>;
  periodType?: InputMaybe<PeriodAdminReportEnum>;
};

export type FilterInput = {
  role?: InputMaybe<UserRoleEnum>;
  status?: InputMaybe<EducationPlanParticipantStatusEnum>;
  classroomOrSchoolClassroomId?: InputMaybe<Scalars["UUID"]>;
};

export type Forum = {
  __typename?: "Forum";
  id?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  likes?: Maybe<Array<Maybe<User>>>;
  files?: Maybe<Array<Maybe<UploadedFile>>>;
  likesCount?: Maybe<Scalars["Int"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type GameModel = {
  __typename?: "GameModel";
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<GameModelTypeEnum>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  background?: Maybe<Scalars["String"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  isCanBePublished?: Maybe<Scalars["Boolean"]>;
  statesCount?: Maybe<Scalars["Int"]>;
  modelStates?: Maybe<Array<Maybe<GameModelState>>>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  updatedById?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type GameModelArtifact = {
  __typename?: "GameModelArtifact";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  isCanBePublished?: Maybe<Scalars["Boolean"]>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  energyPrice?: Maybe<Scalars["Int"]>;
  type?: Maybe<ArtifactTypeEnum>;
  clothTypeId?: Maybe<Scalars["String"]>;
  clothType?: Maybe<GameModelArtifactClothType>;
  animationTypeId?: Maybe<Scalars["String"]>;
  animationType?: Maybe<GameModelArtifactAnimationType>;
  rank?: Maybe<ArtifactRankEnum>;
  image?: Maybe<Scalars["String"]>;
  iosBundle?: Maybe<Scalars["String"]>;
  iosManifest?: Maybe<Scalars["String"]>;
  androidBundle?: Maybe<Scalars["String"]>;
  androidManifest?: Maybe<Scalars["String"]>;
  animation?: Maybe<Scalars["String"]>;
  gameModelStateId?: Maybe<Scalars["String"]>;
  gameModelState?: Maybe<GameModelState>;
  experiencePrice?: Maybe<Scalars["Int"]>;
  animationBundle?: Maybe<ApplicationMainModel>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  updatedById?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type GameModelArtifactAnimationType = {
  __typename?: "GameModelArtifactAnimationType";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type GameModelArtifactAnimationTypeInput = {
  name: Scalars["String"];
  displayName: Scalars["String"];
};

export type GameModelArtifactClothType = {
  __typename?: "GameModelArtifactClothType";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type GameModelArtifactClothTypeInput = {
  name: Scalars["String"];
  displayName: Scalars["String"];
};

export enum GameModelArtifactFileTypeEnum {
  Image = "image",
  IosBundle = "iosBundle",
  IosManifest = "iosManifest",
  AndroidBundle = "androidBundle",
  AndroidManifest = "androidManifest",
  Animation = "animation",
}

export type GameModelArtifactInput = {
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<ArtifactTypeEnum>;
  gameModelStateId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  clothTypeId?: InputMaybe<Scalars["String"]>;
  animationTypeId?: InputMaybe<Scalars["String"]>;
  rank?: InputMaybe<ArtifactRankEnum>;
  energyPrice?: InputMaybe<Scalars["Int"]>;
};

export type GameModelInput = {
  type?: InputMaybe<GameModelTypeEnum>;
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  background?: InputMaybe<Scalars["String"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type GameModelState = {
  __typename?: "GameModelState";
  id?: Maybe<Scalars["String"]>;
  stageId?: Maybe<Scalars["String"]>;
  stage?: Maybe<GameModelStateStage>;
  description?: Maybe<Scalars["String"]>;
  energyPrice?: Maybe<Scalars["Int"]>;
  experiencePrice?: Maybe<Scalars["Int"]>;
  image?: Maybe<Scalars["String"]>;
  iosBundle?: Maybe<Scalars["String"]>;
  iosManifest?: Maybe<Scalars["String"]>;
  androidBundle?: Maybe<Scalars["String"]>;
  androidManifest?: Maybe<Scalars["String"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  isCanBePublished?: Maybe<Scalars["Boolean"]>;
  gameModelId?: Maybe<Scalars["String"]>;
  gameModel?: Maybe<GameModel>;
  animationBundle?: Maybe<ApplicationMainModel>;
  nextState?: Maybe<GameModelState>;
  name?: Maybe<Scalars["String"]>;
  backgroundColor?: Maybe<Scalars["String"]>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  updatedById?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum GameModelStateFileTypeEnum {
  Image = "image",
  IosBundle = "iosBundle",
  IosManifest = "iosManifest",
  AndroidBundle = "androidBundle",
  AndroidManifest = "androidManifest",
}

export type GameModelStateInput = {
  stageId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  energyPrice?: InputMaybe<Scalars["Int"]>;
  experiencePrice?: InputMaybe<Scalars["Int"]>;
  gameModelId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  backgroundColor?: InputMaybe<Scalars["String"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type GameModelStateStage = {
  __typename?: "GameModelStateStage";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["Int"]>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum GameModelTypeEnum {
  Robot = "robot",
}

export enum GenderEnum {
  Male = "MALE",
  Female = "FEMALE",
}

export type GetAchieves = {
  __typename?: "GetAchieves";
  achieves?: Maybe<Array<Maybe<Achieve>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetAdminReportsType = {
  __typename?: "GetAdminReportsType";
  dataChart?: Maybe<Array<Maybe<AdminReportChartType>>>;
  dataTotal?: Maybe<Scalars["Int"]>;
};

export type GetAnalytics = {
  __typename?: "GetAnalytics";
  total?: Maybe<Scalars["Int"]>;
  totalUnique?: Maybe<Scalars["Int"]>;
  analytics?: Maybe<Array<Maybe<GetAnalyticsByUser>>>;
};

export type GetAnalyticsByUser = {
  __typename?: "GetAnalyticsByUser";
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDates?: Maybe<Array<Maybe<Scalars["Date"]>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type GetAnimationBundles = {
  __typename?: "GetAnimationBundles";
  animationBundles?: Maybe<Array<Maybe<AnimationBundle>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetAnimationBundlesInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  types?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GetApplicationMainModels = {
  __typename?: "GetApplicationMainModels";
  models?: Maybe<Array<Maybe<ApplicationMainModel>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetApplications = {
  __typename?: "GetApplications";
  applications?: Maybe<Array<Maybe<Application>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetApplicationsMain = {
  __typename?: "GetApplicationsMain";
  applicationsMain?: Maybe<Array<Maybe<ApplicationMain>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetClassrooms = {
  __typename?: "GetClassrooms";
  classrooms?: Maybe<Array<Maybe<Classroom>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetClientSiteModels = {
  __typename?: "GetClientSiteModels";
  models?: Maybe<Array<Maybe<ClientSiteModel>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetClientSites = {
  __typename?: "GetClientSites";
  clientSites?: Maybe<Array<Maybe<ClientSite>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetCompletionPctReportByEpic = {
  __typename?: "GetCompletionPctReportByEpic";
  tasks?: Maybe<Array<Maybe<TaskWithCompletionPct>>>;
  epicCompletionPct?: Maybe<Scalars["Float"]>;
  projectCompletionPct?: Maybe<Scalars["Float"]>;
};

export type GetContentPublic = {
  __typename?: "GetContentPublic";
  lesson?: Maybe<NewLesson>;
  plan?: Maybe<EducationPlan>;
  experiment?: Maybe<ApplicationMainModel>;
};

export type GetCostReportByEpic = {
  __typename?: "GetCostReportByEpic";
  tasks?: Maybe<Array<Maybe<TaskWithCost>>>;
  totalCostEstimate?: Maybe<Scalars["Int"]>;
  totalCost?: Maybe<Scalars["Int"]>;
  totalDifference?: Maybe<Scalars["Int"]>;
};

export type GetDateReportByEpic = {
  __typename?: "GetDateReportByEpic";
  tasks?: Maybe<Array<Maybe<TaskWithDate>>>;
  totalStartDifference?: Maybe<Scalars["Int"]>;
  totalFinishDifference?: Maybe<Scalars["Int"]>;
};

export type GetEducationPlanChapters = {
  __typename?: "GetEducationPlanChapters";
  educationPlanChapters?: Maybe<Array<Maybe<EducationPlanChapter>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetEducationPlanChaptersHeader = {
  __typename?: "GetEducationPlanChaptersHeader";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  lessons?: Maybe<Array<Maybe<GetEducationPlanLessonsHeader>>>;
};

export type GetEducationPlanLessonsHeader = {
  __typename?: "GetEducationPlanLessonsHeader";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  contentType?: Maybe<ContentTypeEnum>;
  lessons?: Maybe<Array<Maybe<GetEducationPlanLessonsHeader>>>;
};

export type GetEducationPlanParticipants = {
  __typename?: "GetEducationPlanParticipants";
  educationPlanParticipants?: Maybe<Array<Maybe<EducationPlanParticipant>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetEducationPlanParticipantsInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<SortInput>;
  filter?: InputMaybe<FilterInput>;
  educationPlanId: Scalars["UUID"];
  searchKey?: InputMaybe<Scalars["String"]>;
};

export type GetEducationPlanResultBody = {
  __typename?: "GetEducationPlanResultBody";
  rows?: Maybe<Array<Maybe<GetEducationPlanResultRow>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetEducationPlanResultChapter = {
  __typename?: "GetEducationPlanResultChapter";
  id?: Maybe<Scalars["UUID"]>;
  lessons?: Maybe<Array<Maybe<GetEducationPlanResultLesson>>>;
  chapterMark?: Maybe<LessonMark>;
};

export type GetEducationPlanResultLesson = {
  __typename?: "GetEducationPlanResultLesson";
  id?: Maybe<Scalars["UUID"]>;
  lessons?: Maybe<Array<Maybe<GetEducationPlanResultLesson>>>;
  lessonMark?: Maybe<LessonMark>;
  slidesCount?: Maybe<Scalars["Int"]>;
};

export type GetEducationPlanResultRow = {
  __typename?: "GetEducationPlanResultRow";
  participant?: Maybe<EducationPlanParticipant>;
  numberOfMissedLessons?: Maybe<Scalars["Int"]>;
  chapters?: Maybe<Array<Maybe<GetEducationPlanResultChapter>>>;
};

export type GetEducationPlanResults = {
  __typename?: "GetEducationPlanResults";
  header?: Maybe<Array<Maybe<GetEducationPlanChaptersHeader>>>;
  body?: Maybe<GetEducationPlanResultBody>;
};

export type GetEducationPlanResultsFilter = {
  status?: InputMaybe<EducationPlanParticipantStatusEnum>;
  classroomOrSchoolClassroomId?: InputMaybe<Scalars["UUID"]>;
};

export type GetEducationPlanResultsInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  educationPlanId: Scalars["UUID"];
  searchKey?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<GetEducationPlanResultsFilter>;
  sort?: InputMaybe<SortInput>;
};

export type GetEducationPlans = {
  __typename?: "GetEducationPlans";
  educationPlans?: Maybe<Array<Maybe<EducationPlan>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetEducationPlansInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  classroomId?: InputMaybe<Scalars["String"]>;
  mainId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  difficultyLevel?: InputMaybe<DifficultyLevelEnum>;
  searchKey?: InputMaybe<Scalars["String"]>;
  teacherId?: InputMaybe<Scalars["String"]>;
  available?: InputMaybe<Scalars["Boolean"]>;
};

export type GetEmployeesDistributionsByName = {
  __typename?: "GetEmployeesDistributionsByName";
  distributions?: Maybe<Array<Maybe<EmployeesDistribution>>>;
  additionalData?: Maybe<DistributionAdditionalData>;
};

export type GetEpicsProgressReport = {
  __typename?: "GetEpicsProgressReport";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["Date"]>;
  finishDate?: Maybe<Scalars["Date"]>;
  lateStart?: Maybe<Scalars["Int"]>;
  progress?: Maybe<Scalars["Int"]>;
  lateEnd?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetEvents = {
  __typename?: "GetEvents";
  events?: Maybe<Array<Maybe<Event>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetExercises = {
  __typename?: "GetExercises";
  exercises?: Maybe<Array<Maybe<Exercise>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetFaqs = {
  __typename?: "GetFaqs";
  faqs?: Maybe<Array<Maybe<Faq>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetForums = {
  __typename?: "GetForums";
  forums?: Maybe<Array<Maybe<Forum>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetGameModelArtifacts = {
  __typename?: "GetGameModelArtifacts";
  artifacts?: Maybe<Array<Maybe<GameModelArtifact>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetGameModelArtifactsInput = {
  gameModelStateId: Scalars["UUID"];
  type?: InputMaybe<ArtifactTypeEnum>;
  rank?: InputMaybe<ArtifactRankEnum>;
  clothTypeId?: InputMaybe<Scalars["String"]>;
  animationTypeId?: InputMaybe<Scalars["String"]>;
};

export type GetGuestTestResult = {
  __typename?: "GetGuestTestResult";
  test?: Maybe<Slide>;
  pupil?: Maybe<GuestUser>;
  correctAnswer?: Maybe<Scalars["String"]>;
  userAnswer?: Maybe<UserAnswer>;
  openMark?: Maybe<Scalars["Int"]>;
};

export type GetGuestsSlideTestAnswers = {
  __typename?: "GetGuestsSlideTestAnswers";
  answers?: Maybe<Array<Maybe<GetSlideTestAnswers>>>;
  totalMark?: Maybe<Scalars["Float"]>;
  userMark?: Maybe<Scalars["Float"]>;
  user?: Maybe<User>;
  lessonMark?: Maybe<LessonMark>;
  wasAbsent?: Maybe<Scalars["Boolean"]>;
};

export type GetGuestsTestResult = {
  __typename?: "GetGuestsTestResult";
  test?: Maybe<Slide>;
  correctAnswer?: Maybe<Scalars["String"]>;
  pupils?: Maybe<Array<Maybe<GuestUser>>>;
  pupilsResults?: Maybe<Array<Maybe<UserAnswer>>>;
  openMarks?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type GetKnowledgeBaseCategories = {
  __typename?: "GetKnowledgeBaseCategories";
  categories?: Maybe<Array<Maybe<KnowledgeBaseCategory>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetKnowledgeBaseCategoriesByParticipant = {
  __typename?: "GetKnowledgeBaseCategoriesByParticipant";
  categories?: Maybe<Array<Maybe<KnowledgeBaseCategoryByParticipant>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetKnowledgeBaseTestHistory = {
  __typename?: "GetKnowledgeBaseTestHistory";
  user?: Maybe<User>;
  answers?: Maybe<Array<Maybe<KnowledgeBaseBlockTestAnswer>>>;
};

export type GetLessonPages = {
  __typename?: "GetLessonPages";
  lessonPages?: Maybe<Array<Maybe<LessonPage>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetLessons = {
  __typename?: "GetLessons";
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetLessonsParams = {
  mainId?: InputMaybe<Scalars["String"]>;
  themeId?: InputMaybe<Scalars["String"]>;
  modelId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type GetLibraryContent = {
  __typename?: "GetLibraryContent";
  content?: Maybe<Array<Maybe<UserLibraryContent>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetLibraryContentParams = {
  folderId?: InputMaybe<Scalars["UUID"]>;
  isEntireLibrary?: InputMaybe<Scalars["Boolean"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
  sortKey?: InputMaybe<GetLibraryLessonsSortKeyEnum>;
  sortOrder?: InputMaybe<GetLibraryLessonsSortOrderEnum>;
  onlyFromLibrary?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  lessonType?: InputMaybe<NewLessonType>;
  type?: InputMaybe<UserLibraryContentType>;
  orderLessonContentType?: InputMaybe<ContentTypeEnum>;
};

export type GetLibraryLessonsParams = {
  teacherId: Scalars["String"];
  searchKey?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SortTypeEnum>;
  order?: InputMaybe<OrderTypeEnum>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum GetLibraryLessonsSortKeyEnum {
  Name = "name",
  CreatedDate = "createdDate",
  UpdatedDate = "updatedDate",
  LessonContentType = "lessonContentType",
}

export enum GetLibraryLessonsSortOrderEnum {
  Asc = "ASC",
  Desc = "DESC",
}

export type GetLibraryNewLessons = {
  __typename?: "GetLibraryNewLessons";
  lessons?: Maybe<Array<Maybe<NewLesson>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetManualPromotionsResponse = {
  __typename?: "GetManualPromotionsResponse";
  promotions?: Maybe<Array<Maybe<Promotion>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetMarketCollectionResponse = {
  __typename?: "GetMarketCollectionResponse";
  collection?: Maybe<MarketCollection>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetMarketCollectionsResponse = {
  __typename?: "GetMarketCollectionsResponse";
  collections?: Maybe<Array<Maybe<MarketCollection>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetMobileNotifications = {
  __typename?: "GetMobileNotifications";
  notifications?: Maybe<Array<Maybe<MobileNotification>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetMobileNotificationsByParticipant = {
  __typename?: "GetMobileNotificationsByParticipant";
  notifications?: Maybe<Array<Maybe<MobileNotificationByParticipant>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetNews = {
  __typename?: "GetNews";
  news?: Maybe<Array<Maybe<News>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetNewsByParticipant = {
  __typename?: "GetNewsByParticipant";
  news?: Maybe<Array<Maybe<NewsByParticipant>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetNotifications = {
  __typename?: "GetNotifications";
  notifications?: Maybe<Array<Maybe<Notification>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetNotificationsByParticipant = {
  __typename?: "GetNotificationsByParticipant";
  notifications?: Maybe<Array<Maybe<NotificationByParticipant>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetPaymentsHistory = {
  __typename?: "GetPaymentsHistory";
  payments?: Maybe<Array<Maybe<Payment>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetProjectTasksByWorkflowStep = {
  __typename?: "GetProjectTasksByWorkflowStep";
  tasks?: Maybe<Array<Maybe<ProjectTask>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetProjects = {
  __typename?: "GetProjects";
  projects?: Maybe<Array<Maybe<Project>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetProjectsInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  searchKey?: InputMaybe<Scalars["String"]>;
};

export type GetPupilLibraryContent = {
  __typename?: "GetPupilLibraryContent";
  content?: Maybe<Array<Maybe<UserLibraryContent>>>;
  total?: Maybe<Scalars["Int"]>;
  isLibraryEmpty?: Maybe<Scalars["Boolean"]>;
};

export type GetPupilTestResult = {
  __typename?: "GetPupilTestResult";
  test?: Maybe<Slide>;
  pupil?: Maybe<User>;
  correctAnswer?: Maybe<Scalars["String"]>;
  userAnswer?: Maybe<UserAnswer>;
  openMark?: Maybe<Scalars["Int"]>;
};

export type GetPupilsAnswersByClassroom = {
  __typename?: "GetPupilsAnswersByClassroom";
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  pupilId?: Maybe<Scalars["String"]>;
  attempts?: Maybe<Scalars["Int"]>;
  createdDate?: Maybe<Scalars["Date"]>;
  rightQuestions?: Maybe<Scalars["Int"]>;
  questions?: Maybe<Scalars["Int"]>;
  testAnswerId?: Maybe<Scalars["String"]>;
};

export type GetPupilsTestResult = {
  __typename?: "GetPupilsTestResult";
  test?: Maybe<Slide>;
  correctAnswer?: Maybe<Scalars["String"]>;
  pupils?: Maybe<Array<Maybe<User>>>;
  pupilsResults?: Maybe<Array<Maybe<UserAnswer>>>;
  openMarks?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type GetPurchases = {
  __typename?: "GetPurchases";
  purchases?: Maybe<Array<Maybe<Purchase>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetPurchasesByUserInput = {
  userId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GetPurchasesInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GetQuestions = {
  __typename?: "GetQuestions";
  questions?: Maybe<Array<Maybe<Question>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetSchoolClassrooms = {
  __typename?: "GetSchoolClassrooms";
  schoolClassrooms?: Maybe<Array<Maybe<SchoolClassroom>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetSchools = {
  __typename?: "GetSchools";
  schools?: Maybe<Array<Maybe<School>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetSchoolsForAdminPanelParams = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<SchoolsSortType>;
  order?: InputMaybe<OrderTypeEnum>;
  searchKey?: InputMaybe<Scalars["String"]>;
  isPersonal?: InputMaybe<Scalars["Boolean"]>;
  daysRangeUntilLicenseExpiration?: InputMaybe<DaysRangeUntilLicenseExpiration>;
  subscriptionStatus?: InputMaybe<SchoolsSubscriptionStatus>;
  minTeachersCount?: InputMaybe<Scalars["Int"]>;
  maxTeachersCount?: InputMaybe<Scalars["Int"]>;
  minPupilsCount?: InputMaybe<Scalars["Int"]>;
  maxPupilsCount?: InputMaybe<Scalars["Int"]>;
  excludeTestSchools?: InputMaybe<Scalars["Boolean"]>;
};

export type GetSchoolsParams = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  cityId?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["String"]>;
  isPersonal?: InputMaybe<Scalars["Boolean"]>;
};

export type GetSessionHistories = {
  __typename?: "GetSessionHistories";
  sessionHistories?: Maybe<Array<Maybe<SessionHistory>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetSlideTestAnswers = {
  __typename?: "GetSlideTestAnswers";
  slide?: Maybe<Slide>;
  totalMark?: Maybe<Scalars["Float"]>;
  userMark?: Maybe<Scalars["Float"]>;
  openAnswer?: Maybe<Scalars["String"]>;
  status?: Maybe<TestSlideResultStatusEnum>;
};

export type GetSlidesAndPreviews = {
  __typename?: "GetSlidesAndPreviews";
  previews?: Maybe<Array<Maybe<ContentPreview>>>;
  slides?: Maybe<Array<Maybe<Slide>>>;
};

export type GetSmartNotificationAnswers = {
  __typename?: "GetSmartNotificationAnswers";
  total?: Maybe<Scalars["Int"]>;
  answers?: Maybe<Array<Maybe<SmartNotificationAnswersWithPercents>>>;
};

export type GetSmartNotifications = {
  __typename?: "GetSmartNotifications";
  notifications?: Maybe<Array<Maybe<SmartNotification>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetSmartNotificationsByParticipant = {
  __typename?: "GetSmartNotificationsByParticipant";
  notifications?: Maybe<Array<Maybe<SmartNotificationByParticipant>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetSubjects = {
  __typename?: "GetSubjects";
  subjects?: Maybe<Array<Maybe<ApplicationMain>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetTerritorialCommunities = {
  __typename?: "GetTerritorialCommunities";
  communities?: Maybe<Array<Maybe<TerritorialCommunity>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetTests = {
  __typename?: "GetTests";
  tests?: Maybe<Array<Maybe<Test>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetTestsAnswersByPupil = {
  __typename?: "GetTestsAnswersByPupil";
  createdDate?: Maybe<Scalars["Date"]>;
  answers?: Maybe<Array<Maybe<Answer>>>;
};

export type GetTimeReportByEpic = {
  __typename?: "GetTimeReportByEpic";
  tasks?: Maybe<Array<Maybe<TaskWithTime>>>;
  totalTimeEstimate?: Maybe<Scalars["Int"]>;
  totalTimeSpent?: Maybe<Scalars["Int"]>;
};

export type GetTotalSlideTestAnswers = {
  __typename?: "GetTotalSlideTestAnswers";
  answers?: Maybe<Array<Maybe<GetSlideTestAnswers>>>;
  totalMark?: Maybe<Scalars["Float"]>;
  userMark?: Maybe<Scalars["Float"]>;
  user?: Maybe<User>;
  lessonMark?: Maybe<LessonMark>;
  wasAbsent?: Maybe<Scalars["Boolean"]>;
};

export type GetTrainers = {
  __typename?: "GetTrainers";
  trainers?: Maybe<Array<Maybe<UserLibraryContent>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetTranings = {
  __typename?: "GetTranings";
  tranings?: Maybe<Array<Maybe<Traning>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetUserExpHistoryByUser = {
  __typename?: "GetUserExpHistoryByUser";
  userExpHistory?: Maybe<Array<Maybe<UserExpHistory>>>;
  total?: Maybe<Scalars["Int"]>;
};

export enum GetUserFolderSortKeyEnum {
  Name = "name",
  CreatedDate = "createdDate",
  UpdatedDate = "updatedDate",
}

export type GetUserInvitationsResponse = {
  __typename?: "GetUserInvitationsResponse";
  count?: Maybe<Scalars["Int"]>;
  invitations?: Maybe<Array<Maybe<UserInvitation>>>;
};

export type GetUserPayments = {
  __typename?: "GetUserPayments";
  payments?: Maybe<Array<Maybe<UserPayment>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetUsers = {
  __typename?: "GetUsers";
  users?: Maybe<Array<Maybe<User>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetUsersInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  roles?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  searchKey?: InputMaybe<Scalars["String"]>;
  applicationId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  environments?: InputMaybe<Array<InputMaybe<EnvironmentEnum>>>;
};

export type GetWorkplaces = {
  __typename?: "GetWorkplaces";
  workplaces?: Maybe<Array<Maybe<WorkPlace>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type GuestUser = {
  __typename?: "GuestUser";
  id?: Maybe<Scalars["UUID"]>;
  fullName?: Maybe<Scalars["String"]>;
};

export type HandleAccessInput = {
  applicationMainId: Scalars["String"];
  availableFor: ApplicationMainAvailableForEnum;
  participantsIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  qrOptions?: InputMaybe<QrOptions>;
};

export type Image = {
  __typename?: "Image";
  id?: Maybe<Scalars["ID"]>;
  original?: Maybe<Scalars["String"]>;
  w1920?: Maybe<Scalars["String"]>;
  w1080?: Maybe<Scalars["String"]>;
  w512?: Maybe<Scalars["String"]>;
};

export type ImportTasksFromGSheetResult = {
  __typename?: "ImportTasksFromGSheetResult";
  epicsCount?: Maybe<Scalars["Int"]>;
  tasksCount?: Maybe<Scalars["Int"]>;
};

export enum InAppPurchasesEnum {
  Free = "FREE",
  Subscribe = "SUBSCRIBE",
  Purchases = "PURCHASES",
}

export type Invitation = {
  __typename?: "Invitation";
  id?: Maybe<Scalars["String"]>;
  invitedUserId?: Maybe<Scalars["String"]>;
  invitedUser?: Maybe<User>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type IosBundle = {
  __typename?: "IosBundle";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  fileLink?: Maybe<Scalars["String"]>;
  animation?: Maybe<AnimationBundle>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum IsForEnum {
  Client = "CLIENT",
  Staff = "STAFF",
  ClientAndStaff = "CLIENT_AND_STAFF",
}

export type Issue = {
  __typename?: "Issue";
  expand?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  self?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  fields?: Maybe<IssueFields>;
};

export type IssueFields = {
  __typename?: "IssueFields";
  summary?: Maybe<Scalars["String"]>;
  duedate?: Maybe<Scalars["String"]>;
  customfield_10015?: Maybe<Scalars["String"]>;
};

export type JiraProject = {
  __typename?: "JiraProject";
  id?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  avatar?: Maybe<Scalars["String"]>;
};

export type KanbanWorkflowStep = {
  __typename?: "KanbanWorkflowStep";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  taskStatusId?: Maybe<Scalars["String"]>;
  taskStatus?: Maybe<ProjectTaskStatus>;
  isBase?: Maybe<Scalars["Boolean"]>;
  isMutable?: Maybe<Scalars["Boolean"]>;
  projectId?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type KnowledgeBaseBlock = {
  __typename?: "KnowledgeBaseBlock";
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<KnowledgeBaseBlockTypeEnum>;
  theme?: Maybe<KnowledgeBaseTheme>;
  themeId?: Maybe<Scalars["String"]>;
  content?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  video?: Maybe<Scalars["String"]>;
  lists?: Maybe<Array<Maybe<KnowledgeBaseBlockList>>>;
  testQuestion?: Maybe<Scalars["String"]>;
  testAnswers?: Maybe<Array<Maybe<KnowledgeBaseBlockTestAnswer>>>;
  testsHistory?: Maybe<Array<Maybe<KnowledgeBaseBlockTestHistory>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type KnowledgeBaseBlockList = {
  __typename?: "KnowledgeBaseBlockList";
  id?: Maybe<Scalars["String"]>;
  content?: Maybe<Scalars["String"]>;
  isNumbered?: Maybe<Scalars["Boolean"]>;
  blockId?: Maybe<Scalars["String"]>;
  block?: Maybe<KnowledgeBaseBlock>;
  photo?: Maybe<Scalars["String"]>;
  video?: Maybe<Scalars["String"]>;
  photoTitle?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type KnowledgeBaseBlockTestAnswer = {
  __typename?: "KnowledgeBaseBlockTestAnswer";
  id?: Maybe<Scalars["String"]>;
  content?: Maybe<Scalars["String"]>;
  isAnswerTrue?: Maybe<Scalars["Boolean"]>;
  blockId?: Maybe<Scalars["String"]>;
  block?: Maybe<KnowledgeBaseBlock>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type KnowledgeBaseBlockTestAttempt = {
  __typename?: "KnowledgeBaseBlockTestAttempt";
  id?: Maybe<Scalars["String"]>;
  themeId?: Maybe<Scalars["String"]>;
  theme?: Maybe<KnowledgeBaseTheme>;
  testHistory?: Maybe<Array<Maybe<KnowledgeBaseBlockTestHistory>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type KnowledgeBaseBlockTestHistory = {
  __typename?: "KnowledgeBaseBlockTestHistory";
  id?: Maybe<Scalars["String"]>;
  answer?: Maybe<KnowledgeBaseBlockTestAnswer>;
  answerId?: Maybe<Scalars["String"]>;
  block?: Maybe<KnowledgeBaseBlock>;
  blockId?: Maybe<Scalars["String"]>;
  attemptId?: Maybe<Scalars["String"]>;
  attempt?: Maybe<KnowledgeBaseBlockTestAttempt>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum KnowledgeBaseBlockTypeEnum {
  Subtitle = "SUBTITLE",
  Header = "HEADER",
  List = "LIST",
  Text = "TEXT",
  Photo = "PHOTO",
  Video = "VIDEO",
  Test = "TEST",
  Question = "QUESTION",
  MultiList = "MULTI_LIST",
  Formula = "FORMULA",
  Editor = "EDITOR",
}

export type KnowledgeBaseCategory = {
  __typename?: "KnowledgeBaseCategory";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  isFor?: Maybe<KnowledgeBaseCategoryIsForEnum>;
  themes?: Maybe<Array<Maybe<KnowledgeBaseTheme>>>;
  availableFor?: Maybe<AvailableForEnum>;
  participants?: Maybe<Array<Maybe<KnowledgeBaseCategoryParticipant>>>;
  publishedAt?: Maybe<Scalars["Date"]>;
  photo?: Maybe<Scalars["String"]>;
  backgroundColor?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type KnowledgeBaseCategoryByParticipant = {
  __typename?: "KnowledgeBaseCategoryByParticipant";
  category?: Maybe<KnowledgeBaseCategory>;
  isViewed?: Maybe<Scalars["Boolean"]>;
};

export enum KnowledgeBaseCategoryIsForEnum {
  AdminPanel = "ADMIN_PANEL",
  LkTeacherGuide = "LK_TEACHER_GUIDE",
}

export type KnowledgeBaseCategoryParticipant = {
  __typename?: "KnowledgeBaseCategoryParticipant";
  id?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  knowledgeBaseCategory?: Maybe<KnowledgeBaseCategory>;
  isWatched?: Maybe<Scalars["Boolean"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type KnowledgeBaseTheme = {
  __typename?: "KnowledgeBaseTheme";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  blocks?: Maybe<Array<Maybe<KnowledgeBaseBlock>>>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  isTestPublished?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["Date"]>;
  testPublishedAt?: Maybe<Scalars["Date"]>;
  categoryId?: Maybe<Scalars["String"]>;
  category?: Maybe<KnowledgeBaseCategory>;
  testAttempts?: Maybe<Array<Maybe<KnowledgeBaseBlockTestAttempt>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export enum LangEnum {
  En = "EN",
  Ukr = "UKR",
}

export enum LanguageEnum {
  English = "English",
  Ukrainian = "Ukrainian",
}

export enum LanguagesEnum {
  En = "EN",
  Ukr = "UKR",
}

export type LateTasks = {
  __typename?: "LateTasks";
  id?: Maybe<Scalars["String"]>;
  epicName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  assigneeName?: Maybe<Scalars["String"]>;
  startDateEstimate?: Maybe<Scalars["String"]>;
  startDateFact?: Maybe<Scalars["String"]>;
  finishDateEstimate?: Maybe<Scalars["String"]>;
  finishDateFact?: Maybe<Scalars["String"]>;
  lateStart?: Maybe<Scalars["Int"]>;
  lateFinish?: Maybe<Scalars["Int"]>;
  costEstimate?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
  lateReason?: Maybe<Scalars["String"]>;
};

export type Lesson = {
  __typename?: "Lesson";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  pages?: Maybe<Array<Maybe<LessonPage>>>;
  main?: Maybe<ApplicationMain>;
  mainId?: Maybe<Scalars["String"]>;
  theme?: Maybe<ApplicationMainTheme>;
  themeId?: Maybe<Scalars["String"]>;
  model?: Maybe<ApplicationMainModel>;
  modelId?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type LessonBlock = {
  __typename?: "LessonBlock";
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<LessonBlockTypeEnum>;
  page?: Maybe<LessonPage>;
  pageId?: Maybe<Scalars["String"]>;
  content?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  video?: Maybe<Scalars["String"]>;
  videoPreview?: Maybe<Scalars["String"]>;
  lists?: Maybe<Array<Maybe<LessonListItem>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type LessonBlockListInput = {
  content?: InputMaybe<Scalars["String"]>;
  isNumbered?: InputMaybe<Scalars["Boolean"]>;
};

export enum LessonBlockTypeEnum {
  Subtitle = "SUBTITLE",
  Header = "HEADER",
  List = "LIST",
  Text = "TEXT",
  Photo = "PHOTO",
  Video = "VIDEO",
}

export type LessonContentInput = {
  lessonId: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  contentAuthor?: InputMaybe<Scalars["String"]>;
  mainIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  educationAreasIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentType?: InputMaybe<ContentTypeEnum>;
  teachingFormat?: InputMaybe<TeachingFormatEnum>;
  schoolGradeIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  inAppPurchases?: InputMaybe<InAppPurchasesEnum>;
  description?: InputMaybe<Scalars["String"]>;
  lang?: InputMaybe<LanguagesEnum>;
  testTimeSec?: InputMaybe<Scalars["Int"]>;
  tagIds: Array<Scalars["UUID"]>;
  price?: InputMaybe<Scalars["Float"]>;
};

export enum LessonContentType {
  Slide = "SLIDE",
  Image = "IMAGE",
  Text = "TEXT",
  Video = "VIDEO",
  Test = "TEST",
  TestZno = "TEST_ZNO",
  Experiment = "EXPERIMENT",
  Experiment360 = "EXPERIMENT360",
  ExperimentVr = "EXPERIMENT_VR",
  ExperimentPhet = "EXPERIMENT_PHET",
  Lesson = "LESSON",
  SlideEditor = "SLIDE_EDITOR",
  Template = "TEMPLATE",
  ControlWork = "CONTROL_WORK",
  Lab = "LAB",
  PracticalWork = "PRACTICAL_WORK",
  EducationalProject = "EDUCATIONAL_PROJECT",
  Homework = "HOMEWORK",
  CompletedTest = "COMPLETED_TEST",
  InteractiveExercise = "INTERACTIVE_EXERCISE",
}

export type LessonListItem = {
  __typename?: "LessonListItem";
  id?: Maybe<Scalars["String"]>;
  content?: Maybe<Scalars["String"]>;
  isNumbered?: Maybe<Scalars["Boolean"]>;
  blockId?: Maybe<Scalars["String"]>;
  block?: Maybe<LessonBlock>;
  questionId?: Maybe<Scalars["String"]>;
  question?: Maybe<LessonQuestion>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type LessonMark = {
  __typename?: "LessonMark";
  id?: Maybe<Scalars["String"]>;
  lessonId?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  fullName?: Maybe<Scalars["String"]>;
  type?: Maybe<LessonMarkTypeEnum>;
  markDigital?: Maybe<Scalars["Int"]>;
  markLevelId?: Maybe<Scalars["String"]>;
  markLevel?: Maybe<MarkLevel>;
  specialLessonMarkId?: Maybe<Scalars["String"]>;
  specialLessonMark?: Maybe<SpecialLessonMark>;
  progress?: Maybe<Scalars["Int"]>;
  additionalMarkType?: Maybe<AdditionalMarkTypeEnum>;
  isAbsent?: Maybe<Scalars["Boolean"]>;
  isUnmarked?: Maybe<Scalars["Boolean"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum LessonMarkTypeEnum {
  Level = "LEVEL",
  Digital = "DIGITAL",
}

export type LessonPage = {
  __typename?: "LessonPage";
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<LessonPageTypeEnum>;
  lesson?: Maybe<Lesson>;
  lessonId?: Maybe<Scalars["String"]>;
  blocks?: Maybe<Array<Maybe<LessonBlock>>>;
  question?: Maybe<LessonQuestion>;
  questionId?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum LessonPageTypeEnum {
  Information = "INFORMATION",
  Question = "QUESTION",
}

export enum LessonPresentationTypeEnum {
  Lesson = "LESSON",
  Quiz = "QUIZ",
}

export type LessonQuestion = {
  __typename?: "LessonQuestion";
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<LessonQuestionTypeEnum>;
  page?: Maybe<LessonPage>;
  pageId?: Maybe<Scalars["String"]>;
  question?: Maybe<Scalars["String"]>;
  enterWord?: Maybe<Scalars["String"]>;
  booleanVariant?: Maybe<Scalars["Boolean"]>;
  rightVariant?: Maybe<LessonListItem>;
  rightVariantId?: Maybe<Scalars["String"]>;
  variants?: Maybe<Array<Maybe<LessonListItem>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum LessonQuestionTypeEnum {
  EnterWord = "ENTER_WORD",
  Test = "TEST",
  Boolean = "BOOLEAN",
}

export type LessonResult = {
  __typename?: "LessonResult";
  isPassed?: Maybe<Scalars["Boolean"]>;
  percent?: Maybe<Scalars["Int"]>;
};

export enum LessonSortTypeEnum {
  All = "ALL",
  Popular = "POPULAR",
  Last = "LAST",
}

export type LessonVideoBlockInput = {
  video?: InputMaybe<Scalars["String"]>;
  videoPreview?: InputMaybe<Scalars["Upload"]>;
};

export type LibraryContentMarketInput = {
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  contentTypes?: InputMaybe<Array<InputMaybe<UserLibraryContentType>>>;
  gradeIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  subjectIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  inAppPurchases?: InputMaybe<Array<InputMaybe<InAppPurchasesEnum>>>;
  teachingFormats?: InputMaybe<Array<InputMaybe<TeachingFormatEnum>>>;
  sortType?: InputMaybe<ContentSortTypeEnum>;
  searchKey?: InputMaybe<Scalars["String"]>;
  excludeTemplates?: InputMaybe<Scalars["Boolean"]>;
  orderByTagId?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type LiqpayDataSignature = {
  __typename?: "LiqpayDataSignature";
  data?: Maybe<Scalars["String"]>;
  signature?: Maybe<Scalars["String"]>;
};

export type LiqpayPayload = {
  amount: Scalars["Float"];
  currency: Scalars["String"];
  description: Scalars["String"];
  order_id: Scalars["String"];
  server_url?: InputMaybe<Scalars["String"]>;
  result_url?: InputMaybe<Scalars["String"]>;
  dae?: InputMaybe<Scalars["String"]>;
};

export enum LocaleEnum {
  En = "EN",
  Ukr = "UKR",
}

export enum LockedLessonReason {
  PrevLessonNotCompleted = "PREV_LESSON_NOT_COMPLETED",
  NotPurchased = "NOT_PURCHASED",
  NoSubscription = "NO_SUBSCRIPTION",
}

export type LoginPupilGuest = {
  __typename?: "LoginPupilGuest";
  token?: Maybe<Scalars["String"]>;
  lesson?: Maybe<NewLesson>;
  user?: Maybe<User>;
};

export enum LoginStatusEnum {
  Closed = "CLOSED",
  Open = "OPEN",
}

export type LoginTeacherGuest = {
  __typename?: "LoginTeacherGuest";
  token?: Maybe<Scalars["String"]>;
  lesson?: Maybe<NewLesson>;
  user?: Maybe<User>;
};

export type MarkLevel = {
  __typename?: "MarkLevel";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
};

export type MarketCollection = {
  __typename?: "MarketCollection";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  imageWeb?: Maybe<Scalars["String"]>;
  imageWebSmall?: Maybe<Scalars["String"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  content?: Maybe<Array<Maybe<UserLibraryContent>>>;
};

export enum MarketCollectionSortTypeEnum {
  Popular = "POPULAR",
  Last = "LAST",
  Best = "BEST",
}

export type MarketContent = {
  __typename?: "MarketContent";
  id?: Maybe<Scalars["String"]>;
  applicationMainId?: Maybe<Scalars["String"]>;
  applicationModelId?: Maybe<Scalars["String"]>;
  categoryId?: Maybe<Scalars["Int"]>;
  productId?: Maybe<Scalars["Int"]>;
  lang?: Maybe<LanguagesEnum>;
  countOfImport?: Maybe<Scalars["Int"]>;
  libraryContentId?: Maybe<Scalars["UUID"]>;
  libraryContent?: Maybe<UserLibraryContent>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type MarketContentCategory = {
  __typename?: "MarketContentCategory";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  lang?: Maybe<Scalars["String"]>;
};

export type MarketingInfo = {
  __typename?: "MarketingInfo";
  showRequestPresentationButton?: Maybe<Scalars["Boolean"]>;
  showRequestDemoModal?: Maybe<Scalars["Boolean"]>;
  showSubscriptionBenefitsModal?: Maybe<Scalars["Boolean"]>;
};

export type MarketingModalInfo = {
  __typename?: "MarketingModalInfo";
  id?: Maybe<Scalars["UUID"]>;
  type?: Maybe<MarketingModalInfoType>;
  appTypeId?: Maybe<Scalars["UUID"]>;
  appType?: Maybe<AppType>;
  title?: Maybe<Scalars["String"]>;
  subTitle?: Maybe<Scalars["String"]>;
  features?: Maybe<Array<Maybe<MarketingModalInfoFeatureEntity>>>;
  note?: Maybe<Scalars["String"]>;
  fileLink?: Maybe<Scalars["String"]>;
};

export type MarketingModalInfoFeatureEntity = {
  __typename?: "MarketingModalInfoFeatureEntity";
  id?: Maybe<Scalars["UUID"]>;
  description?: Maybe<Scalars["String"]>;
};

export type MarketingModalInfoInput = {
  type?: InputMaybe<MarketingModalInfoType>;
  appType?: InputMaybe<AppTypeNameEnum>;
  title?: InputMaybe<Scalars["String"]>;
  subTitle?: InputMaybe<Scalars["String"]>;
  features?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  note?: InputMaybe<Scalars["String"]>;
  fileLink?: InputMaybe<Scalars["String"]>;
  file?: InputMaybe<Scalars["Upload"]>;
};

export enum MarketingModalInfoType {
  Demo = "DEMO",
  AdvantagesOfSubscriptions = "ADVANTAGES_OF_SUBSCRIPTIONS",
}

export type MasteringMoneyReport = {
  __typename?: "MasteringMoneyReport";
  projectId?: Maybe<Scalars["String"]>;
  projectName?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  costEstimate?: Maybe<Scalars["Int"]>;
  costFact?: Maybe<Scalars["Int"]>;
};

export type Meeting = {
  __typename?: "Meeting";
  join_url?: Maybe<Scalars["String"]>;
  registration_url?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Float"]>;
  password?: Maybe<Scalars["String"]>;
};

export type MobileNotification = {
  __typename?: "MobileNotification";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  availableFor?: Maybe<AvailableForMobileNotificationEnum>;
  successParticipantsCount?: Maybe<Scalars["Int"]>;
  watchParticipantsCount?: Maybe<Scalars["Int"]>;
  participants?: Maybe<Array<Maybe<MobileNotificationParticipant>>>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["Date"]>;
  application?: Maybe<Application>;
  type?: Maybe<PushNotificationTypeEnum>;
  showInNotifications?: Maybe<Scalars["Boolean"]>;
  sendPush?: Maybe<Scalars["Boolean"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type MobileNotificationByParticipant = {
  __typename?: "MobileNotificationByParticipant";
  notification?: Maybe<MobileNotification>;
  isViewed?: Maybe<Scalars["Boolean"]>;
};

export type MobileNotificationParticipant = {
  __typename?: "MobileNotificationParticipant";
  id?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  notification?: Maybe<MobileNotification>;
  isWatched?: Maybe<Scalars["Boolean"]>;
  data?: Maybe<Scalars["String"]>;
  body?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum ModelFileTypeEnum {
  Photo = "PHOTO",
  MapMarkerPhoto = "MAP_MARKER_PHOTO",
  MarkerPhoto = "MARKER_PHOTO",
  IosBundle = "IOS_BUNDLE",
  AndroidBundle = "ANDROID_BUNDLE",
  VrBundle = "VR_BUNDLE",
  BundlePhoto = "BUNDLE_PHOTO",
  ColoringPageArFile = "COLORING_PAGE_AR_FILE",
}

export type MonthHourPlan = {
  __typename?: "MonthHourPlan";
  id?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["Date"]>;
  hoursPlan?: Maybe<Scalars["Int"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  createAchieve?: Maybe<Achieve>;
  updateAchieve?: Maybe<Achieve>;
  deleteAchievePhoto?: Maybe<Scalars["Boolean"]>;
  deleteAchieve?: Maybe<Scalars["Boolean"]>;
  publishAchieve?: Maybe<Scalars["Boolean"]>;
  createAd?: Maybe<Ad>;
  updateAd?: Maybe<Ad>;
  deleteAd?: Maybe<Scalars["Boolean"]>;
  createTeacherGroupsClassroomsPupilsReport?: Maybe<AdminReport>;
  deleteTeacherGroupsClassroomsPupilsReport?: Maybe<Scalars["Boolean"]>;
  updateAIChatAssistantModel?: Maybe<Scalars["Boolean"]>;
  createAiChatBlockTemplate?: Maybe<Scalars["Boolean"]>;
  updateAiChatBlockTemplate?: Maybe<Scalars["Boolean"]>;
  deleteAiChatBlockTemplate?: Maybe<Scalars["Boolean"]>;
  createAiChatTemplate?: Maybe<Scalars["Boolean"]>;
  updateAiChatTemplate?: Maybe<Scalars["Boolean"]>;
  deleteAiChatTemplate?: Maybe<Scalars["Boolean"]>;
  createAiPromptTemplate?: Maybe<Scalars["Boolean"]>;
  updateAiPromptTemplate?: Maybe<Scalars["Boolean"]>;
  deleteAiPromptTemplate?: Maybe<Scalars["Boolean"]>;
  createAnimationBundle?: Maybe<AnimationBundle>;
  updateAnimationBundle?: Maybe<AnimationBundle>;
  deleteAnimationBundle?: Maybe<Scalars["Boolean"]>;
  setIosBundle?: Maybe<Scalars["Boolean"]>;
  setAndroidBundle?: Maybe<Scalars["Boolean"]>;
  createAnswer?: Maybe<Answer>;
  deleteAnswer?: Maybe<Scalars["Boolean"]>;
  createApplication?: Maybe<Application>;
  updateApplication?: Maybe<Application>;
  deleteApplication?: Maybe<Scalars["Boolean"]>;
  updateApplicationFiles?: Maybe<Scalars["Boolean"]>;
  deleteApplicationFile?: Maybe<Scalars["Boolean"]>;
  publishApplication?: Maybe<Scalars["Boolean"]>;
  createApplicationLink?: Maybe<ApplicationLink>;
  updateApplicationLink?: Maybe<ApplicationLink>;
  deleteApplicationLink?: Maybe<Scalars["Boolean"]>;
  createApplicationMain?: Maybe<ApplicationMain>;
  updateApplicationMain?: Maybe<ApplicationMain>;
  deleteApplicationMain?: Maybe<Scalars["Boolean"]>;
  updateApplicationMainPhoto?: Maybe<Scalars["Boolean"]>;
  deleteApplicationMainPhoto?: Maybe<Scalars["Boolean"]>;
  deleteApplicationMainIcon?: Maybe<Scalars["Boolean"]>;
  handleAccess?: Maybe<Scalars["Boolean"]>;
  setQrCustom?: Maybe<Scalars["Boolean"]>;
  deleteQrCustom?: Maybe<Scalars["Boolean"]>;
  publishApplicationMain?: Maybe<Scalars["Boolean"]>;
  assignSubjectsToTeacher?: Maybe<Scalars["Boolean"]>;
  createApplicationModel?: Maybe<ApplicationMainModel>;
  updateApplicationModel?: Maybe<ApplicationMainModel>;
  deleteApplicationModel?: Maybe<Scalars["Boolean"]>;
  updateApplicationModelPhoto?: Maybe<Scalars["Boolean"]>;
  updateApplicationModelMarkerPhoto?: Maybe<Scalars["Boolean"]>;
  updateApplicationModelMapMarkerPhoto?: Maybe<Scalars["Boolean"]>;
  updateApplicationModelColoringPageFile?: Maybe<Scalars["Boolean"]>;
  updateApplicationModelBundle?: Maybe<Scalars["Boolean"]>;
  deleteApplicationModelFiles?: Maybe<Scalars["Boolean"]>;
  publishApplicationMainModel?: Maybe<Scalars["Boolean"]>;
  setApplicationMainModelExperimentType?: Maybe<Scalars["Boolean"]>;
  createApplicationMainModelFile?: Maybe<ApplicationMainModelFile>;
  updateApplicationMainModelFile?: Maybe<ApplicationMainModelFile>;
  deleteApplicationMainModelFile?: Maybe<Scalars["Boolean"]>;
  createApplicationMainTheme?: Maybe<ApplicationMainTheme>;
  updateApplicationMainTheme?: Maybe<ApplicationMainTheme>;
  addApplicationMainThemePhoto?: Maybe<Scalars["Boolean"]>;
  deleteApplicationMainThemePhoto?: Maybe<Scalars["Boolean"]>;
  deleteApplicationMainTheme?: Maybe<Scalars["Boolean"]>;
  createApplicationVersion?: Maybe<ApplicationVersion>;
  updateApplicationVersion?: Maybe<ApplicationVersion>;
  deleteApplicationVersion?: Maybe<Scalars["Boolean"]>;
  createAppMainThemeBackground?: Maybe<AppMainThemeBackground>;
  updateAppMainThemeBackground?: Maybe<AppMainThemeBackground>;
  deleteAppMainThemeBackground?: Maybe<Scalars["Boolean"]>;
  createAward?: Maybe<Award>;
  updateAward?: Maybe<Award>;
  deleteAward?: Maybe<Scalars["Boolean"]>;
  publishAwards?: Maybe<Scalars["Boolean"]>;
  unpublishAwards?: Maybe<Scalars["Boolean"]>;
  reorderAwards?: Maybe<Scalars["Boolean"]>;
  createClassroom?: Maybe<Classroom>;
  updateClassroom?: Maybe<Classroom>;
  deleteClassroom?: Maybe<Scalars["Boolean"]>;
  archiveClassroom?: Maybe<Scalars["Boolean"]>;
  assignPupilToClassroom?: Maybe<AssignPupil>;
  removePupilFromClassroom?: Maybe<Scalars["Boolean"]>;
  sendPupilClassroomConnectionEmail?: Maybe<Scalars["Boolean"]>;
  sendGroupConnectionEmail?: Maybe<Array<Maybe<Scalars["String"]>>>;
  addPupilToGroup?: Maybe<Scalars["Boolean"]>;
  createClientSite?: Maybe<ClientSite>;
  updateClientSite?: Maybe<ClientSite>;
  deleteClientSite?: Maybe<Scalars["Boolean"]>;
  createClientSiteModel?: Maybe<ClientSiteModel>;
  updateClientSiteModel?: Maybe<ClientSiteModel>;
  deleteClientSiteModel?: Maybe<Scalars["Boolean"]>;
  uploadClientSiteModelFile?: Maybe<Scalars["Boolean"]>;
  deleteClientSiteModelFile?: Maybe<Scalars["Boolean"]>;
  createClientSiteModelItem?: Maybe<ClientSiteModelItem>;
  updateClientSiteModelItem?: Maybe<ClientSiteModelItem>;
  deleteClientSiteModelItem?: Maybe<Scalars["Boolean"]>;
  createContentCategory?: Maybe<ContentCategory>;
  updateContentCategory?: Maybe<ContentCategory>;
  addContentFile?: Maybe<Scalars["Boolean"]>;
  addContentFileFromLibrary?: Maybe<Scalars["Boolean"]>;
  addContentFileFromArBookPlus?: Maybe<Scalars["Boolean"]>;
  deleteContentFile?: Maybe<Scalars["Boolean"]>;
  updateContentFile?: Maybe<UserLibraryContent>;
  duplicateContentFile?: Maybe<UserLibraryContent>;
  createContentPreview?: Maybe<ContentPreview>;
  updateContentPreviewOrder?: Maybe<Scalars["Boolean"]>;
  deleteContentPreview?: Maybe<Scalars["Boolean"]>;
  createContentTag?: Maybe<ContentTag>;
  updateContentTag?: Maybe<ContentTag>;
  deleteContentTag?: Maybe<Scalars["Boolean"]>;
  createCorrectionComment?: Maybe<CorrectionComment>;
  updateCorrectionComment?: Maybe<CorrectionComment>;
  deleteCorrectionComment?: Maybe<Scalars["Boolean"]>;
  createCourseCertificateTemplate?: Maybe<CourseCertificateTemplate>;
  uploadCourseCertificateTemplate?: Maybe<Scalars["String"]>;
  uploadCourseCertificateTemplatePreview?: Maybe<Scalars["String"]>;
  createCourseRequest?: Maybe<Scalars["Boolean"]>;
  deleteCourseRequest?: Maybe<Scalars["Boolean"]>;
  updateCourseRequest?: Maybe<Scalars["Boolean"]>;
  addCourseToSchool?: Maybe<Scalars["Boolean"]>;
  addCourseToSchoolV3?: Maybe<Scalars["Boolean"]>;
  removeCourseFromSchool?: Maybe<Scalars["Boolean"]>;
  createCourseCertificate?: Maybe<CourseCertificate>;
  publishCourse?: Maybe<Scalars["Boolean"]>;
  createEducationArea?: Maybe<EducationArea>;
  updateEducationArea?: Maybe<EducationArea>;
  deleteEducationArea?: Maybe<Scalars["Boolean"]>;
  createEducationContent?: Maybe<Scalars["Boolean"]>;
  createLessonAttachment?: Maybe<Scalars["Boolean"]>;
  createEducationPlan?: Maybe<EducationPlan>;
  importEducationPlan?: Maybe<Scalars["String"]>;
  updateEducationPlan?: Maybe<EducationPlan>;
  addEducationPlanToSchool?: Maybe<Scalars["Boolean"]>;
  addEducationPlanToClassroom?: Maybe<Scalars["Boolean"]>;
  removeEducationPlanFromSchool?: Maybe<Scalars["Boolean"]>;
  removeEducationPlanFromClassroom?: Maybe<Scalars["Boolean"]>;
  deleteEducationPlan?: Maybe<Scalars["Boolean"]>;
  uploadEducationPlanToMarket?: Maybe<Scalars["Boolean"]>;
  updateEducationPlanInMarket?: Maybe<Scalars["Boolean"]>;
  setLessonMarkType?: Maybe<Scalars["Boolean"]>;
  createEducationPlanChapter?: Maybe<EducationPlanChapter>;
  updateEducationPlanChapter?: Maybe<EducationPlanChapter>;
  deleteEducationPlanChapter?: Maybe<Scalars["Boolean"]>;
  addParticipantsToEducationPlan?: Maybe<Scalars["Boolean"]>;
  updateEducationPlanParticipantsStatus?: Maybe<Scalars["Boolean"]>;
  createEmployeesDistribution?: Maybe<EmployeesDistribution>;
  createEmployeesDistributions?: Maybe<Array<Maybe<EmployeesDistribution>>>;
  updateEmployeesDistribution?: Maybe<EmployeesDistribution>;
  deleteEmployeesDistribution?: Maybe<Scalars["Boolean"]>;
  updateESputnikSubscriptionCategories?: Maybe<Scalars["Boolean"]>;
  createEvent?: Maybe<Event>;
  updateEvent?: Maybe<Event>;
  deleteEvent?: Maybe<Scalars["Boolean"]>;
  createEventDate?: Maybe<EventDate>;
  updateEventDate?: Maybe<EventDate>;
  deleteEventDate?: Maybe<Scalars["Boolean"]>;
  addUserToEventDate?: Maybe<Scalars["Boolean"]>;
  eventDatePresentUser?: Maybe<Scalars["Boolean"]>;
  eventDateAbsentUser?: Maybe<Scalars["Boolean"]>;
  createExercise?: Maybe<Exercise>;
  updateExercise?: Maybe<Exercise>;
  deleteExercise?: Maybe<Scalars["Boolean"]>;
  createFaq?: Maybe<Faq>;
  updateFaq?: Maybe<Faq>;
  deleteFaq?: Maybe<Scalars["Boolean"]>;
  publishFaq?: Maybe<Scalars["Boolean"]>;
  createFeatureFlag?: Maybe<FeatureFlag>;
  updateFeatureFlag?: Maybe<Scalars["Boolean"]>;
  deleteFeatureFlag?: Maybe<Scalars["Boolean"]>;
  createFeedback?: Maybe<Feedback>;
  updateFeedback?: Maybe<Feedback>;
  addFeedbackFiles?: Maybe<Scalars["Boolean"]>;
  deleteFeedback?: Maybe<Scalars["Boolean"]>;
  createFeedbackComment?: Maybe<FeedbackComment>;
  updateFeedbackComment?: Maybe<FeedbackComment>;
  addFeedbackCommentFiles?: Maybe<Scalars["Boolean"]>;
  deleteFeedbackComment?: Maybe<Scalars["Boolean"]>;
  createForum?: Maybe<Forum>;
  updateForum?: Maybe<Forum>;
  addForumFiles?: Maybe<Scalars["Boolean"]>;
  deleteForum?: Maybe<Scalars["Boolean"]>;
  likeForum?: Maybe<Scalars["Boolean"]>;
  dislikeForum?: Maybe<Scalars["Boolean"]>;
  createGameModel?: Maybe<GameModel>;
  updateGameModel?: Maybe<GameModel>;
  deleteGameModel?: Maybe<Scalars["Boolean"]>;
  createGameModelArtifact?: Maybe<GameModelArtifact>;
  updateGameModelArtifact?: Maybe<GameModelArtifact>;
  deleteGameModelArtifact?: Maybe<Scalars["Boolean"]>;
  updateGameModelArtifactFile?: Maybe<Scalars["Boolean"]>;
  deleteGameModelArtifactFile?: Maybe<Scalars["Boolean"]>;
  createGameModelArtifactAnimationType?: Maybe<GameModelArtifactAnimationType>;
  createGameModelArtifactClothType?: Maybe<GameModelArtifactClothType>;
  createGameModelState?: Maybe<GameModelState>;
  updateGameModelState?: Maybe<GameModelState>;
  deleteGameModelState?: Maybe<Scalars["Boolean"]>;
  updateGameModelStateFile?: Maybe<Scalars["Boolean"]>;
  deleteGameModelStateFile?: Maybe<Scalars["Boolean"]>;
  unlinkGameModelStateStage?: Maybe<Scalars["Boolean"]>;
  createGameModelStateStage?: Maybe<GameModelStateStage>;
  createEducationPlanFromGoogleClassroom?: Maybe<EducationPlan>;
  sendInvitationEmail?: Maybe<Scalars["Boolean"]>;
  createInvitedUser?: Maybe<Scalars["Boolean"]>;
  createKnowledgeBaseBlock?: Maybe<KnowledgeBaseBlock>;
  createKnowledgeBaseBlockTestAnswer?: Maybe<KnowledgeBaseBlockTestAnswer>;
  createKnowledgeBaseBlockListItem?: Maybe<KnowledgeBaseBlockList>;
  uploudKnowledgeBaseBlockPhoto?: Maybe<KnowledgeBaseBlock>;
  updateKnowledgeBaseBlock?: Maybe<KnowledgeBaseBlock>;
  updateKnowledgeBaseBlockTestAnswer?: Maybe<KnowledgeBaseBlockTestAnswer>;
  updateKnowledgeBaseBlockListItem?: Maybe<KnowledgeBaseBlockList>;
  handleKnowledgeBaseBlockEditorPhoto?: Maybe<KnowledgeBaseBlock>;
  deleteKnowledgeBaseBlockListItem?: Maybe<Scalars["Boolean"]>;
  deleteKnowledgeBaseBlockTestAnswer?: Maybe<Scalars["Boolean"]>;
  deleteKnowledgeBaseBlock?: Maybe<Scalars["Boolean"]>;
  answerKnowledgeBaseBlockQuestion?: Maybe<KnowledgeBaseBlockTestHistory>;
  createKnowledgeBaseTestAttempt?: Maybe<KnowledgeBaseBlockTestAttempt>;
  answerKnowledgeBaseBlockTest?: Maybe<KnowledgeBaseBlockTestHistory>;
  changeKnowledgeBaseBlockTestAnswer?: Maybe<KnowledgeBaseBlockTestHistory>;
  publishKnowledgeBaseTestBlocksByThemeId?: Maybe<Scalars["Boolean"]>;
  createKnowledgeBaseCategory?: Maybe<KnowledgeBaseCategory>;
  updateKnowledgeBaseCategory?: Maybe<KnowledgeBaseCategory>;
  deleteKnowledgeBaseCategory?: Maybe<Scalars["Boolean"]>;
  setKnowledgeBaseCategoryParticipants?: Maybe<Scalars["Boolean"]>;
  knowledgeBaseCategoryParticipantStatusChange?: Maybe<Scalars["Boolean"]>;
  createKnowledgeBaseTheme?: Maybe<KnowledgeBaseTheme>;
  updateKnowledgeBaseTheme?: Maybe<KnowledgeBaseTheme>;
  deleteKnowledgeBaseTheme?: Maybe<Scalars["Boolean"]>;
  publishKnowledgeBaseTheme?: Maybe<Scalars["Boolean"]>;
  createLesson?: Maybe<Lesson>;
  updateLesson?: Maybe<Lesson>;
  deleteLesson?: Maybe<Scalars["Boolean"]>;
  publishLesson?: Maybe<Scalars["Boolean"]>;
  unpublishLesson?: Maybe<Scalars["Boolean"]>;
  copyLessonToTargetServer?: Maybe<Scalars["Boolean"]>;
  createLessonBlock?: Maybe<LessonBlock>;
  updateLessonBlock?: Maybe<LessonBlock>;
  deleteLessonBlock?: Maybe<Scalars["Boolean"]>;
  createLessonListItem?: Maybe<LessonListItem>;
  updateLessonListItem?: Maybe<LessonListItem>;
  deleteLessonListItem?: Maybe<Scalars["Boolean"]>;
  createLessonMark?: Maybe<Scalars["Boolean"]>;
  updateLessonMark?: Maybe<Scalars["Boolean"]>;
  setSpecialLessonMark?: Maybe<Scalars["Boolean"]>;
  setLessonMark?: Maybe<LessonMark>;
  deleteLessonMark?: Maybe<Scalars["Boolean"]>;
  deleteLessonMarks?: Maybe<Scalars["Boolean"]>;
  createLessonPage?: Maybe<LessonPage>;
  deleteLessonPage?: Maybe<Scalars["Boolean"]>;
  createLessonQuestion?: Maybe<LessonQuestion>;
  updateLessonQuestion?: Maybe<LessonQuestion>;
  deleteLessonQuestion?: Maybe<Scalars["Boolean"]>;
  createQuestionVariant?: Maybe<LessonListItem>;
  updateQuestionVariant?: Maybe<LessonListItem>;
  deleteQuestionVariant?: Maybe<Scalars["Boolean"]>;
  createApplicationModelInMarketContent?: Maybe<Scalars["Boolean"]>;
  updateApplicationModelInMarketContent?: Maybe<Scalars["Boolean"]>;
  deleteApplicationModelInMarketContent?: Maybe<Scalars["Boolean"]>;
  setShowRequestDemoModalDate?: Maybe<Scalars["Boolean"]>;
  setShowSubscriptionBenefitsModal?: Maybe<Scalars["Boolean"]>;
  updateMarketingModalInfo?: Maybe<MarketingModalInfo>;
  createMobileNotification?: Maybe<MobileNotification>;
  updateMobileNotification?: Maybe<MobileNotification>;
  removeMobileNotificationPhoto?: Maybe<Scalars["Boolean"]>;
  setMobileNotificationParticipants?: Maybe<Scalars["Boolean"]>;
  publishMobileNotification?: Maybe<Scalars["Boolean"]>;
  mobileNotificationParticipantStatusChange?: Maybe<Scalars["Boolean"]>;
  deleteMobileNotification?: Maybe<Scalars["Boolean"]>;
  createMonthHourPlan?: Maybe<MonthHourPlan>;
  updateMonthHourPlan?: Maybe<MonthHourPlan>;
  deleteMonthHourPlan?: Maybe<Scalars["Boolean"]>;
  createNewLesson?: Maybe<NewLesson>;
  updateNewLesson?: Maybe<NewLesson>;
  deleteNewLesson?: Maybe<Scalars["Boolean"]>;
  restoreNewLesson?: Maybe<Scalars["Boolean"]>;
  uploadNewLessonToMarket?: Maybe<Scalars["Boolean"]>;
  updateNewLessonInMarket?: Maybe<Scalars["Boolean"]>;
  importNewLessonFromMarket?: Maybe<NewLesson>;
  importNewLessonFromParentRecommendation?: Maybe<NewLesson>;
  importLessonContentFromMarket?: Maybe<Array<Maybe<Slide>>>;
  duplicateNewLesson?: Maybe<NewLesson>;
  moveNewLesson?: Maybe<NewLesson>;
  importLessonContentIntoConstructor?: Maybe<Scalars["Boolean"]>;
  importNewLessonAndChangeContentType?: Maybe<NewLesson>;
  setNewLessonPupilLoginCode?: Maybe<Scalars["String"]>;
  generateTeacherLoginCode?: Maybe<Scalars["String"]>;
  importRecommendedLessonIntoCurrentLesson?: Maybe<Scalars["Boolean"]>;
  setIsLector?: Maybe<Scalars["Boolean"]>;
  createLessonResults?: Maybe<Scalars["Boolean"]>;
  createTranslatedLesson?: Maybe<NewLesson>;
  sendNewLessonFeedbackPush?: Maybe<Scalars["Boolean"]>;
  answerNewLessonFeedbackQuestions?: Maybe<Scalars["Boolean"]>;
  createNewLessonFeedbackQuestion?: Maybe<NewLessonFeedbackQuestion>;
  updateNewLessonFeedbackQuestion?: Maybe<NewLessonFeedbackQuestion>;
  deleteNewLessonFeedbackQuestion?: Maybe<Scalars["Boolean"]>;
  createNewLessonPreview?: Maybe<NewLessonPreview>;
  updateNewLessonPreviewOrder?: Maybe<NewLessonPreview>;
  deleteNewLessonPreview?: Maybe<Scalars["Boolean"]>;
  createNews?: Maybe<News>;
  updateNews?: Maybe<News>;
  setNewsParticipants?: Maybe<Scalars["Boolean"]>;
  deleteNews?: Maybe<Scalars["Boolean"]>;
  publishNews?: Maybe<Scalars["Boolean"]>;
  newsParticipantStatusChange?: Maybe<Scalars["Boolean"]>;
  deleteNewsPhoto?: Maybe<Scalars["Boolean"]>;
  createNotification?: Maybe<Notification>;
  updateNotification?: Maybe<Notification>;
  setNotificationParticipants?: Maybe<Scalars["Boolean"]>;
  deleteNotification?: Maybe<Scalars["Boolean"]>;
  publishNotification?: Maybe<Scalars["Boolean"]>;
  viewNotification?: Maybe<Scalars["Boolean"]>;
  notificationParticipantStatusChange?: Maybe<Scalars["Boolean"]>;
  onboardingThemeFirst?: Maybe<Scalars["Boolean"]>;
  onboardingThemeFinished?: Maybe<Scalars["Boolean"]>;
  createOnboardingTheme?: Maybe<OnboardingTheme>;
  updateOnboardingTheme?: Maybe<OnboardingTheme>;
  deleteOnboardingTheme?: Maybe<OnboardingTheme>;
  reorderOnboardingTheme?: Maybe<Array<Maybe<OnboardingTheme>>>;
  publishOnboardingTheme?: Maybe<OnboardingTheme>;
  unpublishOnboardingTheme?: Maybe<OnboardingTheme>;
  createPaymentFormAdminPanel?: Maybe<Payment>;
  createPaymentFormLkTeacher?: Maybe<Payment>;
  createSubscriptionPlanPaymentFromAdminPanel?: Maybe<Payment>;
  createSubscriptionPlanPaymentFromLkTeacher?: Maybe<Payment>;
  generateWayForPaySignature?: Maybe<Array<Maybe<Scalars["String"]>>>;
  generateLiqpayUrl?: Maybe<PaymentCheckoutUrl>;
  createStripePaymentSession?: Maybe<PaymentCheckoutUrl>;
  generateLiqpayWidgetParams?: Maybe<LiqpayDataSignature>;
  handlePaymentActivation?: Maybe<Scalars["Boolean"]>;
  deletePayment?: Maybe<Scalars["Boolean"]>;
  updatePaymentTeachersCount?: Maybe<Payment>;
  createPurchasePaymentFromAdminPanel?: Maybe<Scalars["Boolean"]>;
  createPurchasePaymentFromLkTeacher?: Maybe<PurchasePaymentResponse>;
  deactivatePurchasePayment?: Maybe<Scalars["Boolean"]>;
  unsubscribeSubscription?: Maybe<Scalars["Boolean"]>;
  resumeSubscription?: Maybe<ResumeSubscriptionResponse>;
  createPersonalSubscriptionV3FromAdminPanel?: Maybe<Scalars["Boolean"]>;
  createPaymentSession?: Maybe<PaymentCheckoutUrl>;
  clearPaymentSession?: Maybe<Scalars["Boolean"]>;
  createPayrollBonus?: Maybe<PayrollBonus>;
  updatePayrollBonus?: Maybe<PayrollBonus>;
  deletePayrollBonus?: Maybe<Scalars["Boolean"]>;
  createPayrollKpi?: Maybe<PayrollKpi>;
  updatePayrollKpi?: Maybe<PayrollKpi>;
  deletePayrollKpi?: Maybe<Scalars["Boolean"]>;
  createPayrollSalaryPart?: Maybe<PayrollSalaryPart>;
  updatePayrollSalaryPart?: Maybe<PayrollSalaryPart>;
  deletePayrollSalaryPart?: Maybe<Scalars["Boolean"]>;
  addByUserIdPermission?: Maybe<Scalars["Boolean"]>;
  removeByUserIdPermission?: Maybe<Scalars["Boolean"]>;
  addPermissionByUserId?: Maybe<Scalars["Boolean"]>;
  removePermissionByUserId?: Maybe<Scalars["Boolean"]>;
  addByPersonalSchoolIdPermission?: Maybe<Scalars["Boolean"]>;
  removeByPersonalSchoolIdPermission?: Maybe<Scalars["Boolean"]>;
  addByUserIdsPermission?: Maybe<Scalars["Boolean"]>;
  removeByUserIdsPermission?: Maybe<Scalars["Boolean"]>;
  addByJobIdsPermission?: Maybe<Scalars["Boolean"]>;
  removeByJobIdsPermission?: Maybe<Scalars["Boolean"]>;
  addByEmailsPermission?: Maybe<Scalars["Boolean"]>;
  removeByEmailsPermission?: Maybe<Scalars["Boolean"]>;
  addByRolesPermission?: Maybe<Scalars["Boolean"]>;
  removeByRolesPermission?: Maybe<Scalars["Boolean"]>;
  addByAllPermission?: Maybe<Scalars["Boolean"]>;
  removeByAllPermission?: Maybe<Scalars["Boolean"]>;
  createProduct?: Maybe<Product>;
  updateProduct?: Maybe<Product>;
  deleteProduct?: Maybe<Scalars["Boolean"]>;
  createProject?: Maybe<Project>;
  createProjectByJiraData?: Maybe<Project>;
  updateProject?: Maybe<Project>;
  deleteProject?: Maybe<Scalars["Boolean"]>;
  syncProject?: Maybe<SyncProject>;
  assignProjectTrackerId?: Maybe<Scalars["Boolean"]>;
  updateProjectFiles?: Maybe<Array<Maybe<ProjectFile>>>;
  createProjectAdditionalContact?: Maybe<ProjectAdditionalContact>;
  updateProjectAdditionalContact?: Maybe<ProjectAdditionalContact>;
  deleteProjectAdditionalContact?: Maybe<Scalars["Boolean"]>;
  createProjectCorrection?: Maybe<ProjectCorrection>;
  updateProjectCorrection?: Maybe<ProjectCorrection>;
  deleteProjectCorrection?: Maybe<Scalars["Boolean"]>;
  addCorrectionsToProject?: Maybe<Scalars["Boolean"]>;
  removeCorrectionsFromProject?: Maybe<Scalars["Boolean"]>;
  createProjectCustomFieldsMap?: Maybe<ProjectCustomFieldsMap>;
  updateProjectCustomFieldsMap?: Maybe<ProjectCustomFieldsMap>;
  updateProjectCustomFieldsMapByProject?: Maybe<ProjectCustomFieldsMap>;
  deleteProjectCustomFieldsMap?: Maybe<Scalars["Boolean"]>;
  createProjectKanban?: Maybe<ProjectKanban>;
  restartSprint?: Maybe<ProjectKanban>;
  deleteProjectKanban?: Maybe<Scalars["Boolean"]>;
  clearProjectKanban?: Maybe<Scalars["Boolean"]>;
  activateProjectKanban?: Maybe<Scalars["Boolean"]>;
  createBaseKanbanWorkflowStep?: Maybe<KanbanWorkflowStep>;
  createKanbanWorkflowStepForProject?: Maybe<KanbanWorkflowStep>;
  updateKanbanWorkflowStep?: Maybe<KanbanWorkflowStep>;
  deleteKanbanWorkflowStep?: Maybe<Scalars["Boolean"]>;
  createProjectLink?: Maybe<ProjectLink>;
  updateProjectLink?: Maybe<ProjectLink>;
  deleteProjectLink?: Maybe<Scalars["Boolean"]>;
  addLinksToProject?: Maybe<Scalars["Boolean"]>;
  removeLinksFromProject?: Maybe<Scalars["Boolean"]>;
  createProjectPresentation?: Maybe<ProjectPresentation>;
  updateProjectPresentation?: Maybe<ProjectPresentation>;
  deleteProjectPresentation?: Maybe<Scalars["Boolean"]>;
  createProjectStep?: Maybe<ProjectStep>;
  updateProjectStep?: Maybe<ProjectStep>;
  deleteProjectStep?: Maybe<Scalars["Boolean"]>;
  createProjectTask?: Maybe<ProjectTask>;
  createProjectTaskInKanban?: Maybe<ProjectTask>;
  updateProjectTask?: Maybe<ProjectTask>;
  deleteProjectTask?: Maybe<Scalars["Boolean"]>;
  createProjectTasksInTracker?: Maybe<CreatedTasksInTracker>;
  syncProjectTaskWithTracker?: Maybe<Scalars["Boolean"]>;
  syncProjectTasksWithTracker?: Maybe<Scalars["Boolean"]>;
  importProjectTasksFromGSheets?: Maybe<ImportTasksFromGSheetResult>;
  addProjectTasksToKanban?: Maybe<Scalars["Boolean"]>;
  changeProjectTaskWorkflowStep?: Maybe<Scalars["Boolean"]>;
  removeProjectTaskFromKanban?: Maybe<Scalars["Boolean"]>;
  setProjectTaskLateReason?: Maybe<Scalars["Boolean"]>;
  setTaskBoundaries?: Maybe<Scalars["Boolean"]>;
  createPTCheckListItem?: Maybe<ProjectTaskCheckListItem>;
  updatePTCheckListItem?: Maybe<ProjectTaskCheckListItem>;
  deletePTCheckListItem?: Maybe<Scalars["Boolean"]>;
  changePTCheckListItemStatus?: Maybe<ProjectTaskCheckListItem>;
  createProjectTaskComment?: Maybe<ProjectTaskComment>;
  updateProjectTaskComment?: Maybe<ProjectTaskComment>;
  deleteProjectTaskComment?: Maybe<Scalars["Boolean"]>;
  deleteProjectTaskCommentFile?: Maybe<Scalars["Boolean"]>;
  createBaseProjectTaskLabel?: Maybe<ProjectTaskLabel>;
  createProjectTaskLabelForProject?: Maybe<ProjectTaskLabel>;
  updateProjectTaskLabel?: Maybe<ProjectTaskLabel>;
  deleteProjectTaskLabel?: Maybe<Scalars["Boolean"]>;
  assignProjectTaskLabel?: Maybe<Scalars["Boolean"]>;
  removeProjectTaskLabel?: Maybe<Scalars["Boolean"]>;
  createBaseProjectTaskStatus?: Maybe<ProjectTaskStatus>;
  createProjectTaskStatusForProject?: Maybe<ProjectTaskStatus>;
  updateProjectTaskStatus?: Maybe<ProjectTaskStatus>;
  deleteProjectTaskStatus?: Maybe<Scalars["Boolean"]>;
  createProjectType?: Maybe<ProjectType>;
  updateProjectType?: Maybe<ProjectType>;
  deleteProjectType?: Maybe<Scalars["Boolean"]>;
  applyPromoCode?: Maybe<PromoCode>;
  createManualPromotion?: Maybe<Scalars["Boolean"]>;
  updateManualPromotion?: Maybe<Scalars["Boolean"]>;
  deleteManualPromotion?: Maybe<Scalars["Boolean"]>;
  createPurchase?: Maybe<Purchase>;
  updatePurchase?: Maybe<Purchase>;
  deletePurchase?: Maybe<Scalars["Boolean"]>;
  confirmPurchase?: Maybe<Scalars["Boolean"]>;
  createQuestion?: Maybe<Question>;
  updateQuestion?: Maybe<Question>;
  setQuestionRightVariant?: Maybe<Question>;
  deleteQuestion?: Maybe<Scalars["Boolean"]>;
  createQuoteCollection?: Maybe<QuoteCollection>;
  updateQuoteCollection?: Maybe<Scalars["Boolean"]>;
  deleteQuoteCollection?: Maybe<Scalars["Boolean"]>;
  createReward?: Maybe<Reward>;
  updateReward?: Maybe<Reward>;
  deleteReward?: Maybe<Scalars["Boolean"]>;
  createRewardGroup?: Maybe<RewardGroup>;
  createRobotsHistoryLink?: Maybe<RobotsHistory>;
  deleteRobotsHistory?: Maybe<Scalars["Boolean"]>;
  createSchool?: Maybe<School>;
  updateSchool?: Maybe<School>;
  deleteSchool?: Maybe<Scalars["Boolean"]>;
  deleteSchoolPhoto?: Maybe<Scalars["Boolean"]>;
  sendUsersSchoolConnectionEmail?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sendTeacherConnectionEmail?: Maybe<Scalars["Boolean"]>;
  sendPupilSchoolConnectionEmail?: Maybe<Scalars["Boolean"]>;
  checkIsConnectionByEmailTeacherExist?: Maybe<Scalars["Boolean"]>;
  removePupilFromSchool?: Maybe<Scalars["Boolean"]>;
  setTeacherWorkplace?: Maybe<Scalars["Boolean"]>;
  inviteUsersByList: Array<Maybe<CsvValidationErrors>>;
  inviteUsersByListV3: Array<Maybe<CsvValidationErrors>>;
  updateUserInvitation?: Maybe<Scalars["Boolean"]>;
  createSchoolCity?: Maybe<SchoolCity>;
  updateSchoolCity?: Maybe<SchoolCity>;
  deleteSchoolCity?: Maybe<Scalars["Boolean"]>;
  createCustomCity?: Maybe<SchoolCity>;
  createSchoolClassroom?: Maybe<SchoolClassroom>;
  updateSchoolClassroom?: Maybe<SchoolClassroom>;
  removeSchoolClassroomTeacher?: Maybe<Scalars["Boolean"]>;
  addPupilToSchoolClassroom?: Maybe<Scalars["Boolean"]>;
  addPupilToSchoolClassroomV3?: Maybe<Scalars["Boolean"]>;
  removePupilFromSchoolClassroom?: Maybe<Scalars["Boolean"]>;
  deleteSchoolClassroom?: Maybe<Scalars["Boolean"]>;
  archiveSchoolClassroom?: Maybe<Scalars["Boolean"]>;
  transferPupilsToNextGrade?: Maybe<Scalars["Boolean"]>;
  sendPupilSchoolClassroomConnectionEmail?: Maybe<Scalars["Boolean"]>;
  sendClassroomConnectionEmail?: Maybe<Array<Maybe<Scalars["String"]>>>;
  createSchoolDistrict?: Maybe<SchoolDistrict>;
  updateSchoolDistrict?: Maybe<SchoolDistrict>;
  deleteSchoolDistrict?: Maybe<Scalars["Boolean"]>;
  createSchoolEvent?: Maybe<SchoolEvent>;
  updateSchoolEvent?: Maybe<SchoolEvent>;
  deleteSchoolEvent?: Maybe<Scalars["Boolean"]>;
  restoreSchoolEvent?: Maybe<Scalars["Boolean"]>;
  createSchoolPayer?: Maybe<Scalars["Boolean"]>;
  updateSchoolPayer?: Maybe<Scalars["Boolean"]>;
  deleteSchoolPayer?: Maybe<Scalars["Boolean"]>;
  recoverSchoolPayer?: Maybe<Scalars["Boolean"]>;
  createSchoolRegion?: Maybe<SchoolRegion>;
  updateSchoolRegion?: Maybe<SchoolRegion>;
  deleteSchoolRegion?: Maybe<Scalars["Boolean"]>;
  addTeacherToSchool?: Maybe<Scalars["Boolean"]>;
  addUserToSchool?: Maybe<Scalars["Boolean"]>;
  removeTeacherFromSchool?: Maybe<Scalars["Boolean"]>;
  addSchoolAdmin?: Maybe<Scalars["Boolean"]>;
  removeAdminFromPosition?: Maybe<Scalars["Boolean"]>;
  changeTeacherActiveSchool?: Maybe<Scalars["Boolean"]>;
  changeActiveSchool?: Maybe<School>;
  updateTeacherPosition?: Maybe<Scalars["Boolean"]>;
  updateUserSubjectGradesFormat?: Maybe<Scalars["Boolean"]>;
  updateTeacherSchool?: Maybe<Scalars["Boolean"]>;
  createRequestForPayment?: Maybe<Scalars["Boolean"]>;
  createSessionHistory?: Maybe<SessionHistory>;
  deleteSessionHistory?: Maybe<Scalars["Boolean"]>;
  createSlide?: Maybe<Slide>;
  createGoogleSlides?: Maybe<Array<Maybe<Slide>>>;
  updateSlide?: Maybe<Slide>;
  deleteSlide?: Maybe<Scalars["Boolean"]>;
  restoreSlide?: Maybe<Scalars["Boolean"]>;
  updateSlideFile?: Maybe<Slide>;
  deleteSlideFile?: Maybe<Scalars["Boolean"]>;
  uploadPresentation?: Maybe<UploadPresentationResponse>;
  completeUploadPresentation?: Maybe<Array<Maybe<Slide>>>;
  duplicateSlide?: Maybe<Slide>;
  inAppPurchases?: Maybe<InAppPurchasesEnum>;
  price?: Maybe<Scalars["Int"]>;
  updateSlideMetadata?: Maybe<Array<Maybe<Slide>>>;
  deleteSlideMetadata?: Maybe<Scalars["Boolean"]>;
  uploadFileToSlideEditor?: Maybe<UploadFileToSlideEditorResponse>;
  applySettingsToAllSlides?: Maybe<Scalars["Boolean"]>;
  createSlideReflection?: Maybe<SlideReflection>;
  updateOpenQuestionMark?: Maybe<Scalars["Boolean"]>;
  createSlideTestChoice?: Maybe<SlideTestChoice>;
  updateSlideTestChoice?: Maybe<SlideTestChoice>;
  deleteSlideTestChoice?: Maybe<Scalars["Boolean"]>;
  createSlideTestChoiceConformity?: Maybe<SlideTestChoiceConformity>;
  updateSlideTestChoiceConformity?: Maybe<SlideTestChoiceConformity>;
  deleteSlideTestChoiceConformity?: Maybe<Scalars["Boolean"]>;
  createSmartNotification?: Maybe<SmartNotification>;
  updateSmartNotification?: Maybe<SmartNotification>;
  deleteSmartNotification?: Maybe<Scalars["Boolean"]>;
  publishSmartNotification?: Maybe<Scalars["Boolean"]>;
  unpublishSmartNotification?: Maybe<Scalars["Boolean"]>;
  deleteSmartNotificationPhoto?: Maybe<Scalars["Boolean"]>;
  setSmartNotificationParticipants?: Maybe<Scalars["Boolean"]>;
  smartNotificationParticipantStatusChange?: Maybe<Scalars["Boolean"]>;
  createSmartNotificationPollList?: Maybe<SmartNotificationPollList>;
  updateSmartNotificationPollList?: Maybe<SmartNotificationPollList>;
  deleteSmartNotificationPollList?: Maybe<Scalars["Boolean"]>;
  createSubscriptionPlan?: Maybe<SubscriptionPlan>;
  updateSubscriptionPlan?: Maybe<SubscriptionPlan>;
  deleteSubscriptionPlan?: Maybe<Scalars["Boolean"]>;
  createSubscriptionPlanFeature?: Maybe<SubscriptionPlanFeature>;
  updateSubscriptionPlanFeature?: Maybe<SubscriptionPlanFeature>;
  deleteSubscriptionPlanFeature?: Maybe<Scalars["Boolean"]>;
  createSubscriptionSales?: Maybe<Scalars["Boolean"]>;
  deleteTeacherPlanData?: Maybe<Scalars["Boolean"]>;
  createTeacherSchoolReport?: Maybe<Scalars["Boolean"]>;
  createTerritorialCommunity?: Maybe<Scalars["Boolean"]>;
  updateTerritorialCommunity?: Maybe<Scalars["Boolean"]>;
  deleteTerritorialCommunity?: Maybe<Scalars["Boolean"]>;
  recoverTerritorialCommunity?: Maybe<Scalars["Boolean"]>;
  createTest?: Maybe<Test>;
  updateTest?: Maybe<Test>;
  publishTest?: Maybe<Scalars["Boolean"]>;
  deleteTest?: Maybe<Scalars["Boolean"]>;
  createTrainer?: Maybe<UserLibraryContent>;
  updateTrainer?: Maybe<UserLibraryContent>;
  deleteTrainer?: Maybe<Scalars["Boolean"]>;
  publishTrainer?: Maybe<Scalars["Boolean"]>;
  unpublishTrainer?: Maybe<Scalars["Boolean"]>;
  createTraning?: Maybe<Traning>;
  updateTraning?: Maybe<Traning>;
  deleteTraning?: Maybe<Scalars["Boolean"]>;
  publishTraning?: Maybe<Scalars["Boolean"]>;
  unpublishTraning?: Maybe<Scalars["Boolean"]>;
  uploadFile?: Maybe<Scalars["String"]>;
  deleteUploadedFile?: Maybe<Scalars["Boolean"]>;
  createUserV2?: Maybe<CreateUserV2Response>;
  createUserV3?: Maybe<CreateUserV3Response>;
  createUser?: Maybe<User>;
  confirmUser?: Maybe<Scalars["Boolean"]>;
  confirmUserV3?: Maybe<ConfirmUserV3Response>;
  confirmUserFromART?: Maybe<Auth>;
  confirmChildByParentV3?: Maybe<Scalars["Boolean"]>;
  login?: Maybe<Auth>;
  loginPupilGuest?: Maybe<LoginPupilGuest>;
  loginTeacherGuest?: Maybe<LoginTeacherGuest>;
  loginMobile?: Maybe<Auth>;
  loginOAuth?: Maybe<Auth>;
  loginApple?: Maybe<Auth>;
  logout: Scalars["Boolean"];
  forgotPassword?: Maybe<Scalars["Boolean"]>;
  forgotPasswordV3?: Maybe<Scalars["Boolean"]>;
  deleteOldTokens?: Maybe<Scalars["Boolean"]>;
  sendConfirmEmail?: Maybe<Scalars["Boolean"]>;
  sendConfirmEmailV2?: Maybe<Scalars["Boolean"]>;
  sendConfirmEmailV3?: Maybe<Scalars["Boolean"]>;
  resetPassword?: Maybe<Auth>;
  resetPasswordV3?: Maybe<Auth>;
  changeUserPassword?: Maybe<Scalars["Boolean"]>;
  updateUser?: Maybe<User>;
  updateUserInAdminPanel?: Maybe<User>;
  updateCurrentUser?: Maybe<User>;
  updateSchoolParticipant?: Maybe<User>;
  deleteUserFromClient: Scalars["Boolean"];
  deleteUser?: Maybe<Scalars["Boolean"]>;
  assignUsersToGroup?: Maybe<Scalars["Boolean"]>;
  removeUsersFromGroup?: Maybe<Scalars["Boolean"]>;
  assignUsersToProject?: Maybe<Scalars["Boolean"]>;
  removeUsersFromProject?: Maybe<Scalars["Boolean"]>;
  assignProjectsToUser?: Maybe<Scalars["Boolean"]>;
  removeProjectsFromUser?: Maybe<Scalars["Boolean"]>;
  assignAnimationBundleParticipants?: Maybe<Scalars["Boolean"]>;
  assignUserTrackerId?: Maybe<Scalars["Boolean"]>;
  inviteUserToTracker?: Maybe<Scalars["Boolean"]>;
  createTrackerContract?: Maybe<Contract>;
  deleteTrackerContract?: Maybe<Scalars["Boolean"]>;
  cleanUserDataForClientRole?: Maybe<Scalars["Boolean"]>;
  createUserPassword?: Maybe<Scalars["Boolean"]>;
  createEventUser?: Maybe<Scalars["Boolean"]>;
  registerEventUser?: Maybe<User>;
  blockUser?: Maybe<Scalars["Boolean"]>;
  addSubjectToTeacher?: Maybe<Scalars["Boolean"]>;
  removeSubjectFromTeacher?: Maybe<Scalars["Boolean"]>;
  addParentChildCommunication?: Maybe<Scalars["Boolean"]>;
  removeParentChildCommunication?: Maybe<Scalars["Boolean"]>;
  addPresentationUserToGSheet?: Maybe<Scalars["Boolean"]>;
  createTeacherAndSchool?: Maybe<User>;
  createTeacherAndSchoolWithApple?: Maybe<Auth>;
  setSelectedSubjects?: Maybe<Scalars["Boolean"]>;
  finishRegistration?: Maybe<Auth>;
  finishRegistrationV3?: Maybe<Auth>;
  inviteParentsByEmails?: Maybe<Scalars["Boolean"]>;
  sendConnectParentPupilEmails?: Maybe<Scalars["Boolean"]>;
  connectParentPupil?: Maybe<Scalars["Boolean"]>;
  resetAuthFlowData?: Maybe<Scalars["Boolean"]>;
  createUserAgreement?: Maybe<UserAgreement>;
  updateUserAgreement?: Maybe<UserAgreement>;
  deleteUserAgreement?: Maybe<Scalars["Boolean"]>;
  createUserDepartment?: Maybe<UserDepartment>;
  updateUserDepartment?: Maybe<UserDepartment>;
  deleteUserDepartment?: Maybe<Scalars["Boolean"]>;
  createUserEvent?: Maybe<Scalars["Boolean"]>;
  processUserEvent?: Maybe<Scalars["Boolean"]>;
  deleteUserEvent?: Maybe<Scalars["Boolean"]>;
  createUserExpHistory?: Maybe<UserExpHistory>;
  createUserFolder?: Maybe<UserFolder>;
  updateUserFolder?: Maybe<Scalars["Boolean"]>;
  deleteUserFolder?: Maybe<Scalars["Boolean"]>;
  restoreUserFolder?: Maybe<Scalars["Boolean"]>;
  updateUserGdpr?: Maybe<Scalars["Boolean"]>;
  createUserGroup?: Maybe<UserGroup>;
  updateUserGroup?: Maybe<UserGroup>;
  deleteUserGroup?: Maybe<Scalars["Boolean"]>;
  exportConnectedUsersToSchool?: Maybe<Scalars["Boolean"]>;
  createUserJob?: Maybe<UserJob>;
  updateUserJob?: Maybe<UserJob>;
  deleteUserJob?: Maybe<Scalars["Boolean"]>;
  addContentToLibrary?: Maybe<Scalars["Boolean"]>;
  renameContent?: Maybe<Scalars["Boolean"]>;
  moveContent?: Maybe<Scalars["Boolean"]>;
  removeContent?: Maybe<Scalars["Boolean"]>;
  restoreContent?: Maybe<Scalars["Boolean"]>;
  copyContentLesson?: Maybe<Scalars["Boolean"]>;
  assignContentLessonToPlan?: Maybe<Scalars["Boolean"]>;
  assignContentPlanToSubject?: Maybe<Scalars["Boolean"]>;
  exportContent?: Maybe<Scalars["Boolean"]>;
  updateThirdLevelContentOrder?: Maybe<Scalars["Boolean"]>;
  importContentFromParentRecommendation?: Maybe<Scalars["Boolean"]>;
  replaceCurrentContentWithRecommendedContent?: Maybe<Scalars["Boolean"]>;
  updateCountOfView?: Maybe<Scalars["Boolean"]>;
  createUserPayment?: Maybe<UserPayment>;
  updateUserPayment?: Maybe<UserPayment>;
  setUserPaymentDocuments?: Maybe<Scalars["Boolean"]>;
  deleteUserPayment?: Maybe<Scalars["Boolean"]>;
  payUserPayment?: Maybe<Scalars["Boolean"]>;
  publishUserPayment?: Maybe<Scalars["Boolean"]>;
  createUserPayroll?: Maybe<UserPayroll>;
  updateUserPayroll?: Maybe<UserPayroll>;
  deleteUserPayroll?: Maybe<Scalars["Boolean"]>;
  createMonthUserPayrolls?: Maybe<Scalars["Boolean"]>;
  createRateHistory?: Maybe<UserRateHistory>;
  updateRateHistory?: Maybe<UserRateHistory>;
  deleteSRateHistory?: Maybe<Scalars["Boolean"]>;
  createMonthlyStaffRate?: Maybe<Scalars["Boolean"]>;
  createSalaryHistory?: Maybe<UserSalaryHistory>;
  updateSalaryHistory?: Maybe<UserSalaryHistory>;
  deleteSalaryHistory?: Maybe<Scalars["Boolean"]>;
  updateUserStats?: Maybe<UserStats>;
  createVacanciesPlan?: Maybe<VacanciesPlan>;
  updateVacanciesPlan?: Maybe<VacanciesPlan>;
  deleteVacanciesPlan?: Maybe<Scalars["Boolean"]>;
  createVariant?: Maybe<Variant>;
  updateVariant?: Maybe<Variant>;
  deleteVariant?: Maybe<Scalars["Boolean"]>;
  createVRHeadset?: Maybe<VrHeadset>;
  updateVRHeadset?: Maybe<VrHeadset>;
  deleteVRHeadset?: Maybe<Scalars["Boolean"]>;
  recoverVRHeadset?: Maybe<Scalars["Boolean"]>;
  linkVRHeadsetsToLesson?: Maybe<Scalars["Boolean"]>;
  unlinkVRHeadsetsFromLesson?: Maybe<Scalars["Boolean"]>;
  createWhiteLabelCollection?: Maybe<Scalars["Boolean"]>;
  updateWhiteLabelCollection?: Maybe<Scalars["Boolean"]>;
  deleteWhiteLabelCollection?: Maybe<Scalars["Boolean"]>;
  checkIfWhiteLabelCollectionIsInUse?: Maybe<Scalars["Boolean"]>;
  connectZoom?: Maybe<Scalars["Boolean"]>;
  createMeeting: Meeting;
};

export type MutationCreateAchieveArgs = {
  achieveData: CreateAchieveInput;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationUpdateAchieveArgs = {
  achieveData: UpdateAchieveInput;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationDeleteAchievePhotoArgs = {
  id: Scalars["String"];
};

export type MutationDeleteAchieveArgs = {
  id: Scalars["String"];
};

export type MutationPublishAchieveArgs = {
  id: Scalars["String"];
};

export type MutationCreateAdArgs = {
  name: Scalars["String"];
  description: Scalars["String"];
};

export type MutationUpdateAdArgs = {
  updateAdData: UpdateAdInput;
};

export type MutationDeleteAdArgs = {
  adId: Scalars["String"];
};

export type MutationCreateTeacherGroupsClassroomsPupilsReportArgs = {
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type MutationDeleteTeacherGroupsClassroomsPupilsReportArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateAiChatAssistantModelArgs = {
  id: Scalars["String"];
  data: UpdateAiChatAssistantModelInput;
};

export type MutationCreateAiChatBlockTemplateArgs = {
  data: ChatBlockTemplateInput;
};

export type MutationUpdateAiChatBlockTemplateArgs = {
  id: Scalars["String"];
  data: ChatBlockTemplateInput;
};

export type MutationDeleteAiChatBlockTemplateArgs = {
  id: Scalars["String"];
};

export type MutationCreateAiChatTemplateArgs = {
  aiChatBlockTemplateId: Scalars["String"];
  title: Scalars["String"];
  prompt?: InputMaybe<Scalars["String"]>;
  lessonType: AiChatTemplateLessonTypeEnum;
  parameters: Array<InputMaybe<CreateAiChatTemplateParameterInput>>;
};

export type MutationUpdateAiChatTemplateArgs = {
  id: Scalars["String"];
  data?: InputMaybe<UpdateChatTemplateInput>;
};

export type MutationDeleteAiChatTemplateArgs = {
  id: Scalars["String"];
};

export type MutationCreateAiPromptTemplateArgs = {
  data: CreateAiPromptTemplateInput;
};

export type MutationUpdateAiPromptTemplateArgs = {
  id: Scalars["String"];
  data: UpdateAiPromptTemplateInput;
};

export type MutationDeleteAiPromptTemplateArgs = {
  id: Scalars["String"];
};

export type MutationCreateAnimationBundleArgs = {
  animationData: CreateAnimationBundleInput;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationUpdateAnimationBundleArgs = {
  animationData: UpdateAnimationBundleInput;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationDeleteAnimationBundleArgs = {
  animationId: Scalars["String"];
};

export type MutationSetIosBundleArgs = {
  animationId: Scalars["String"];
  deletedIosBundleId?: InputMaybe<Scalars["String"]>;
  newIosBundle?: InputMaybe<Scalars["Upload"]>;
};

export type MutationSetAndroidBundleArgs = {
  animationId: Scalars["String"];
  deletedAndroidBundleId?: InputMaybe<Scalars["String"]>;
  newAndroidBundle?: InputMaybe<Scalars["Upload"]>;
};

export type MutationCreateAnswerArgs = {
  questionId: Scalars["String"];
  variantId: Scalars["String"];
};

export type MutationDeleteAnswerArgs = {
  id: Scalars["String"];
};

export type MutationCreateApplicationArgs = {
  applicationData: CreateApplicationInput;
};

export type MutationUpdateApplicationArgs = {
  applicationData: UpdateApplicationInput;
};

export type MutationDeleteApplicationArgs = {
  id: Scalars["String"];
};

export type MutationUpdateApplicationFilesArgs = {
  applicationId: Scalars["String"];
  file: Scalars["Upload"];
  ApplicationFileType: ApplicationFileTypeEnum;
};

export type MutationDeleteApplicationFileArgs = {
  applicationId: Scalars["String"];
  ApplicationFileType: ApplicationFileTypeEnum;
};

export type MutationPublishApplicationArgs = {
  id: Scalars["String"];
};

export type MutationCreateApplicationLinkArgs = {
  name: Scalars["String"];
  link: Scalars["String"];
  applicationId: Scalars["String"];
};

export type MutationUpdateApplicationLinkArgs = {
  applicationLinkId: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  link?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteApplicationLinkArgs = {
  id: Scalars["String"];
};

export type MutationCreateApplicationMainArgs = {
  applicationMainData: CreateApplicationMainInput;
};

export type MutationUpdateApplicationMainArgs = {
  applicationMainData: UpdateApplicationMainInput;
};

export type MutationDeleteApplicationMainArgs = {
  id: Scalars["String"];
};

export type MutationUpdateApplicationMainPhotoArgs = {
  applicationMainId: Scalars["String"];
  photo?: InputMaybe<Scalars["Upload"]>;
  icon?: InputMaybe<Scalars["Upload"]>;
};

export type MutationDeleteApplicationMainPhotoArgs = {
  applicationMainId: Scalars["String"];
};

export type MutationDeleteApplicationMainIconArgs = {
  applicationMainId: Scalars["String"];
};

export type MutationHandleAccessArgs = {
  applicationMainData: HandleAccessInput;
};

export type MutationSetQrCustomArgs = {
  appMainId: Scalars["String"];
  qrData: SetQrCustomInput;
};

export type MutationDeleteQrCustomArgs = {
  appMainId: Scalars["String"];
};

export type MutationPublishApplicationMainArgs = {
  id: Scalars["String"];
};

export type MutationAssignSubjectsToTeacherArgs = {
  mainPageIds: Array<InputMaybe<Scalars["String"]>>;
  teacherId: Scalars["String"];
  schoolId: Scalars["String"];
};

export type MutationCreateApplicationModelArgs = {
  modelData: CreateApplicationMainModelInput;
};

export type MutationUpdateApplicationModelArgs = {
  modelData: UpdateApplicationMainModelInput;
};

export type MutationDeleteApplicationModelArgs = {
  id: Scalars["String"];
};

export type MutationUpdateApplicationModelPhotoArgs = {
  modelId: Scalars["String"];
  file: Scalars["Upload"];
};

export type MutationUpdateApplicationModelMarkerPhotoArgs = {
  modelId: Scalars["String"];
  file?: InputMaybe<Scalars["Upload"]>;
  isMarker?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateApplicationModelMapMarkerPhotoArgs = {
  modelId: Scalars["String"];
  file: Scalars["Upload"];
};

export type MutationUpdateApplicationModelColoringPageFileArgs = {
  modelId: Scalars["String"];
  coloringPageFile: Scalars["Upload"];
};

export type MutationUpdateApplicationModelBundleArgs = {
  modelId: Scalars["String"];
  bundleType: ApplicationBundleTypeEnum;
  bundleFileType?: InputMaybe<BundleFileTypeEnum>;
  file?: InputMaybe<Scalars["Upload"]>;
  bundleLink?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteApplicationModelFilesArgs = {
  modelId: Scalars["String"];
  modelFileType: ModelFileTypeEnum;
};

export type MutationPublishApplicationMainModelArgs = {
  id: Scalars["String"];
};

export type MutationSetApplicationMainModelExperimentTypeArgs = {
  id: Scalars["String"];
  experimentType?: InputMaybe<ExperimentTypeEnum>;
  contentType?: InputMaybe<ContentTypeEnum>;
};

export type MutationCreateApplicationMainModelFileArgs = {
  fileData: CreateApplicationMainModelFileInput;
};

export type MutationUpdateApplicationMainModelFileArgs = {
  fileData: UpdateApplicationMainModelFileInput;
};

export type MutationDeleteApplicationMainModelFileArgs = {
  fileId: Scalars["String"];
};

export type MutationCreateApplicationMainThemeArgs = {
  applicationMainId: Scalars["String"];
  nameEN?: InputMaybe<Scalars["String"]>;
  nameUKR?: InputMaybe<Scalars["String"]>;
  backgroundId?: InputMaybe<Scalars["String"]>;
  analyticsMark?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateApplicationMainThemeArgs = {
  id: Scalars["String"];
  nameEN?: InputMaybe<Scalars["String"]>;
  nameUKR?: InputMaybe<Scalars["String"]>;
  backgroundId?: InputMaybe<Scalars["String"]>;
  analyticsMark?: InputMaybe<Scalars["String"]>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanStatusEnum>;
};

export type MutationAddApplicationMainThemePhotoArgs = {
  id: Scalars["String"];
  photo: Scalars["Upload"];
};

export type MutationDeleteApplicationMainThemePhotoArgs = {
  id: Scalars["String"];
};

export type MutationDeleteApplicationMainThemeArgs = {
  id: Scalars["String"];
};

export type MutationCreateApplicationVersionArgs = {
  appId: Scalars["String"];
  os: Os;
  version: Scalars["String"];
};

export type MutationUpdateApplicationVersionArgs = {
  id: Scalars["String"];
  os: Os;
  version: Scalars["String"];
};

export type MutationDeleteApplicationVersionArgs = {
  id: Scalars["String"];
};

export type MutationCreateAppMainThemeBackgroundArgs = {
  name: Scalars["String"];
  fileIOS: Scalars["Upload"];
  fileAndroid: Scalars["Upload"];
  fileWebGl: Scalars["Upload"];
  manifestIOS?: InputMaybe<Scalars["Upload"]>;
  manifestAndroid?: InputMaybe<Scalars["Upload"]>;
  manifestWebGl?: InputMaybe<Scalars["Upload"]>;
};

export type MutationUpdateAppMainThemeBackgroundArgs = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  fileIOS?: InputMaybe<Scalars["Upload"]>;
  fileAndroid?: InputMaybe<Scalars["Upload"]>;
  fileWebGl?: InputMaybe<Scalars["Upload"]>;
  manifestIOS?: InputMaybe<Scalars["Upload"]>;
  manifestAndroid?: InputMaybe<Scalars["Upload"]>;
  manifestWebGl?: InputMaybe<Scalars["Upload"]>;
};

export type MutationDeleteAppMainThemeBackgroundArgs = {
  id: Scalars["String"];
};

export type MutationCreateAwardArgs = {
  input: CreateAwardInput;
};

export type MutationUpdateAwardArgs = {
  input: UpdateAwardInput;
};

export type MutationDeleteAwardArgs = {
  id: Scalars["UUID"];
};

export type MutationPublishAwardsArgs = {
  ids: Array<Scalars["UUID"]>;
};

export type MutationUnpublishAwardsArgs = {
  ids: Array<Scalars["UUID"]>;
};

export type MutationReorderAwardsArgs = {
  input: Array<AwardReorderInput>;
};

export type MutationCreateClassroomArgs = {
  name: Scalars["String"];
  password: Scalars["String"];
  teacherId: Scalars["String"];
  schoolId: Scalars["String"];
};

export type MutationUpdateClassroomArgs = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  teacherId?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteClassroomArgs = {
  id: Scalars["String"];
};

export type MutationArchiveClassroomArgs = {
  id: Scalars["String"];
};

export type MutationAssignPupilToClassroomArgs = {
  classroomId: Scalars["String"];
  pupilIds: Array<InputMaybe<Scalars["String"]>>;
};

export type MutationRemovePupilFromClassroomArgs = {
  classroomId: Scalars["String"];
  pupilId: Scalars["String"];
};

export type MutationSendPupilClassroomConnectionEmailArgs = {
  classroomId: Scalars["String"];
  emails: Array<InputMaybe<Scalars["String"]>>;
  lang?: InputMaybe<LanguagesEnum>;
};

export type MutationSendGroupConnectionEmailArgs = {
  groupId: Scalars["String"];
  emails: Array<InputMaybe<Scalars["String"]>>;
};

export type MutationAddPupilToGroupArgs = {
  hash: Scalars["UUID"];
};

export type MutationCreateClientSiteArgs = {
  name: Scalars["String"];
  link?: InputMaybe<Scalars["String"]>;
  key: Scalars["String"];
  iframe: Scalars["String"];
};

export type MutationUpdateClientSiteArgs = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  link?: InputMaybe<Scalars["String"]>;
  key: Scalars["String"];
  iframe: Scalars["String"];
};

export type MutationDeleteClientSiteArgs = {
  id: Scalars["String"];
};

export type MutationCreateClientSiteModelArgs = {
  name: Scalars["String"];
  clientSiteId: Scalars["String"];
  itemIds: Array<InputMaybe<Scalars["String"]>>;
};

export type MutationUpdateClientSiteModelArgs = {
  id: Scalars["String"];
  name: Scalars["String"];
};

export type MutationDeleteClientSiteModelArgs = {
  id: Scalars["String"];
};

export type MutationUploadClientSiteModelFileArgs = {
  clientSiteModelId: Scalars["String"];
  file: Scalars["Upload"];
  fileType: ClientSiteModelFilesEnum;
};

export type MutationDeleteClientSiteModelFileArgs = {
  clientSiteModelId: Scalars["String"];
  fileType: ClientSiteModelFilesEnum;
};

export type MutationCreateClientSiteModelItemArgs = {
  clientSiteModelId: Scalars["String"];
  itemId: Scalars["String"];
};

export type MutationUpdateClientSiteModelItemArgs = {
  id: Scalars["String"];
  itemId: Scalars["String"];
};

export type MutationDeleteClientSiteModelItemArgs = {
  id: Scalars["String"];
};

export type MutationCreateContentCategoryArgs = {
  category: ContentCategoryEnum;
  displayName: Scalars["String"];
};

export type MutationUpdateContentCategoryArgs = {
  id: Scalars["UUID"];
  displayName: Scalars["String"];
};

export type MutationAddContentFileArgs = {
  connectedToLessonId: Scalars["UUID"];
  file?: InputMaybe<Scalars["Upload"]>;
  name?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<FileContentTypeEnum>;
  contentType?: InputMaybe<ContentTypeEnum>;
};

export type MutationAddContentFileFromLibraryArgs = {
  contentId: Scalars["UUID"];
  connectedToLessonId: Scalars["UUID"];
};

export type MutationAddContentFileFromArBookPlusArgs = {
  contentId: Scalars["UUID"];
  connectedToLessonId?: InputMaybe<Scalars["UUID"]>;
  folderId?: InputMaybe<Scalars["UUID"]>;
};

export type MutationDeleteContentFileArgs = {
  contentId: Scalars["UUID"];
  fromMarket?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateContentFileArgs = {
  updateContentFileInput: ContentFileUpdateInput;
};

export type MutationDuplicateContentFileArgs = {
  duplicateContentFileInput: ContentFileDuplicateInput;
};

export type MutationCreateContentPreviewArgs = {
  contentLink: ContentLinkInput;
  file?: InputMaybe<Scalars["Upload"]>;
  videoUrl?: InputMaybe<Scalars["String"]>;
  cropRegion?: InputMaybe<CropRegion>;
};

export type MutationUpdateContentPreviewOrderArgs = {
  id: Scalars["String"];
  order: Scalars["Int"];
};

export type MutationDeleteContentPreviewArgs = {
  id: Scalars["String"];
};

export type MutationCreateContentTagArgs = {
  data?: InputMaybe<CreateContentTagInput>;
};

export type MutationUpdateContentTagArgs = {
  data?: InputMaybe<UpdateContentTagInput>;
};

export type MutationDeleteContentTagArgs = {
  id: Scalars["UUID"];
};

export type MutationCreateCorrectionCommentArgs = {
  correctionCommentData: CreateCorrectionCommentInput;
};

export type MutationUpdateCorrectionCommentArgs = {
  correctionCommentData: UpdateCorrectionCommentInput;
};

export type MutationDeleteCorrectionCommentArgs = {
  correctionCommentId: Scalars["String"];
};

export type MutationCreateCourseCertificateTemplateArgs = {
  data: CreateCourseCertificateTemplateData;
};

export type MutationUploadCourseCertificateTemplateArgs = {
  id: Scalars["UUID"];
  file?: InputMaybe<Scalars["Upload"]>;
};

export type MutationUploadCourseCertificateTemplatePreviewArgs = {
  id: Scalars["UUID"];
  file: Scalars["Upload"];
};

export type MutationCreateCourseRequestArgs = {
  requestData: CreateCourseRequest;
};

export type MutationDeleteCourseRequestArgs = {
  id: Scalars["UUID"];
};

export type MutationUpdateCourseRequestArgs = {
  id: Scalars["UUID"];
  requestData: CreateCourseRequest;
};

export type MutationAddCourseToSchoolArgs = {
  schoolId: Scalars["UUID"];
  courseId: Scalars["UUID"];
};

export type MutationAddCourseToSchoolV3Args = {
  schoolId: Scalars["UUID"];
  courseId: Scalars["UUID"];
};

export type MutationRemoveCourseFromSchoolArgs = {
  schoolId: Scalars["UUID"];
  courseId: Scalars["UUID"];
};

export type MutationCreateCourseCertificateArgs = {
  fullName: Scalars["String"];
  courseId: Scalars["UUID"];
};

export type MutationPublishCourseArgs = {
  id: Scalars["UUID"];
  isPublish?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateEducationAreaArgs = {
  name: Scalars["String"];
};

export type MutationUpdateEducationAreaArgs = {
  id?: InputMaybe<Scalars["UUID"]>;
  name: Scalars["String"];
};

export type MutationDeleteEducationAreaArgs = {
  id: Scalars["UUID"];
};

export type MutationCreateEducationContentArgs = {
  planId: Scalars["UUID"];
  table?: InputMaybe<Scalars["Upload"]>;
};

export type MutationCreateLessonAttachmentArgs = {
  lessonId: Scalars["UUID"];
  table?: InputMaybe<Scalars["Upload"]>;
};

export type MutationCreateEducationPlanArgs = {
  data: CreateEducationPlanInput;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationImportEducationPlanArgs = {
  importedPlanId: Scalars["String"];
  mainId?: InputMaybe<Scalars["String"]>;
  gradeId?: InputMaybe<Scalars["String"]>;
  currentPlanId?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateEducationPlanArgs = {
  id: Scalars["String"];
  data: UpdateEducationPlanInput;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationAddEducationPlanToSchoolArgs = {
  educationPlanId: Scalars["String"];
  schoolId: Scalars["String"];
};

export type MutationAddEducationPlanToClassroomArgs = {
  educationPlanId: Scalars["String"];
  classroomId: Scalars["String"];
};

export type MutationRemoveEducationPlanFromSchoolArgs = {
  educationPlanId: Scalars["String"];
  schoolId: Scalars["String"];
};

export type MutationRemoveEducationPlanFromClassroomArgs = {
  educationPlanId: Scalars["String"];
  classroomId: Scalars["String"];
};

export type MutationDeleteEducationPlanArgs = {
  id: Scalars["String"];
};

export type MutationUploadEducationPlanToMarketArgs = {
  id: Scalars["String"];
};

export type MutationUpdateEducationPlanInMarketArgs = {
  id: Scalars["String"];
};

export type MutationSetLessonMarkTypeArgs = {
  educationPlanId: Scalars["String"];
  type: LessonMarkTypeEnum;
};

export type MutationCreateEducationPlanChapterArgs = {
  educationPlanId: Scalars["String"];
  name: Scalars["String"];
};

export type MutationUpdateEducationPlanChapterArgs = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["Int"]>;
};

export type MutationDeleteEducationPlanChapterArgs = {
  id: Scalars["String"];
};

export type MutationAddParticipantsToEducationPlanArgs = {
  educationPlanId: Scalars["UUID"];
  input?: InputMaybe<AddParticipantsToEducationPlanInput>;
  isSendEmail?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateEducationPlanParticipantsStatusArgs = {
  userIds: Array<InputMaybe<Scalars["UUID"]>>;
  educationPlanId: Scalars["UUID"];
  newStatus: EducationPlanParticipantStatusEnum;
};

export type MutationCreateEmployeesDistributionArgs = {
  distributionData: CreateEmployeesDistribution;
};

export type MutationCreateEmployeesDistributionsArgs = {
  name: Scalars["String"];
};

export type MutationUpdateEmployeesDistributionArgs = {
  distributionData: UpdateEmployeesDistribution;
};

export type MutationDeleteEmployeesDistributionArgs = {
  id: Scalars["String"];
};

export type MutationUpdateESputnikSubscriptionCategoriesArgs = {
  subscriptionsKeys: Array<Scalars["String"]>;
};

export type MutationCreateEventArgs = {
  name: Scalars["String"];
  photo?: InputMaybe<Scalars["Upload"]>;
  description?: InputMaybe<Scalars["String"]>;
  speaker?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateEventArgs = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  photo?: InputMaybe<Scalars["Upload"]>;
  speaker?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteEventArgs = {
  id: Scalars["String"];
};

export type MutationCreateEventDateArgs = {
  date: Scalars["String"];
  link?: InputMaybe<Scalars["String"]>;
  userLimit?: InputMaybe<Scalars["Int"]>;
  eventId: Scalars["String"];
};

export type MutationUpdateEventDateArgs = {
  id: Scalars["String"];
  date?: InputMaybe<Scalars["String"]>;
  link?: InputMaybe<Scalars["String"]>;
  userLimit?: InputMaybe<Scalars["Int"]>;
  eventId?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteEventDateArgs = {
  id: Scalars["String"];
};

export type MutationAddUserToEventDateArgs = {
  eventDateId: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationEventDatePresentUserArgs = {
  eventDateId: Scalars["String"];
  userIds: Array<InputMaybe<Scalars["String"]>>;
};

export type MutationEventDateAbsentUserArgs = {
  eventDateId: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationCreateExerciseArgs = {
  exerciseData: CreateExerciseInput;
};

export type MutationUpdateExerciseArgs = {
  id: Scalars["String"];
  exerciseData: UpdateExerciseInput;
};

export type MutationDeleteExerciseArgs = {
  id: Scalars["String"];
};

export type MutationCreateFaqArgs = {
  answer: Scalars["String"];
  question: Scalars["String"];
  isFrom: IsFromEnum;
};

export type MutationUpdateFaqArgs = {
  id: Scalars["String"];
  answer?: InputMaybe<Scalars["String"]>;
  question?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteFaqArgs = {
  id: Scalars["String"];
};

export type MutationPublishFaqArgs = {
  id: Scalars["String"];
};

export type MutationCreateFeatureFlagArgs = {
  name: Scalars["String"];
  flag: Scalars["String"];
};

export type MutationUpdateFeatureFlagArgs = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  flag?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteFeatureFlagArgs = {
  id: Scalars["String"];
};

export type MutationCreateFeedbackArgs = {
  text: Scalars["String"];
  files?: InputMaybe<Array<InputMaybe<Scalars["Upload"]>>>;
  isFrom?: InputMaybe<FeedbackIsFromEnum>;
};

export type MutationUpdateFeedbackArgs = {
  feedbackData: UpdateFeedbackInput;
};

export type MutationAddFeedbackFilesArgs = {
  feedbackId: Scalars["String"];
  files: Array<InputMaybe<Scalars["Upload"]>>;
};

export type MutationDeleteFeedbackArgs = {
  feedbackId: Scalars["String"];
};

export type MutationCreateFeedbackCommentArgs = {
  text: Scalars["String"];
  feedbackId: Scalars["String"];
  files?: InputMaybe<Array<InputMaybe<Scalars["Upload"]>>>;
};

export type MutationUpdateFeedbackCommentArgs = {
  id: Scalars["String"];
  text?: InputMaybe<Scalars["String"]>;
};

export type MutationAddFeedbackCommentFilesArgs = {
  feedbackCommentId: Scalars["String"];
  files: Array<InputMaybe<Scalars["Upload"]>>;
};

export type MutationDeleteFeedbackCommentArgs = {
  feedbackCommentId: Scalars["String"];
};

export type MutationCreateForumArgs = {
  text: Scalars["String"];
  files?: InputMaybe<Array<InputMaybe<Scalars["Upload"]>>>;
};

export type MutationUpdateForumArgs = {
  id: Scalars["String"];
  text: Scalars["String"];
};

export type MutationAddForumFilesArgs = {
  forumId: Scalars["String"];
  files: Array<InputMaybe<Scalars["Upload"]>>;
};

export type MutationDeleteForumArgs = {
  forumId: Scalars["String"];
};

export type MutationLikeForumArgs = {
  forumId: Scalars["String"];
};

export type MutationDislikeForumArgs = {
  forumId: Scalars["String"];
};

export type MutationCreateGameModelArgs = {
  input: GameModelInput;
};

export type MutationUpdateGameModelArgs = {
  id: Scalars["UUID"];
  input: GameModelInput;
};

export type MutationDeleteGameModelArgs = {
  id: Scalars["UUID"];
};

export type MutationCreateGameModelArtifactArgs = {
  input: GameModelArtifactInput;
};

export type MutationUpdateGameModelArtifactArgs = {
  id: Scalars["UUID"];
  input: GameModelArtifactInput;
};

export type MutationDeleteGameModelArtifactArgs = {
  id: Scalars["UUID"];
};

export type MutationUpdateGameModelArtifactFileArgs = {
  id: Scalars["UUID"];
  file: Scalars["Upload"];
  fileType: GameModelArtifactFileTypeEnum;
};

export type MutationDeleteGameModelArtifactFileArgs = {
  id: Scalars["UUID"];
  fileType: GameModelArtifactFileTypeEnum;
};

export type MutationCreateGameModelArtifactAnimationTypeArgs = {
  input: GameModelArtifactAnimationTypeInput;
};

export type MutationCreateGameModelArtifactClothTypeArgs = {
  input: GameModelArtifactClothTypeInput;
};

export type MutationCreateGameModelStateArgs = {
  input: GameModelStateInput;
};

export type MutationUpdateGameModelStateArgs = {
  id: Scalars["UUID"];
  input: GameModelStateInput;
};

export type MutationDeleteGameModelStateArgs = {
  id: Scalars["UUID"];
};

export type MutationUpdateGameModelStateFileArgs = {
  id: Scalars["UUID"];
  file: Scalars["Upload"];
  fileType: GameModelStateFileTypeEnum;
};

export type MutationDeleteGameModelStateFileArgs = {
  id: Scalars["UUID"];
  fileType: GameModelStateFileTypeEnum;
};

export type MutationUnlinkGameModelStateStageArgs = {
  id: Scalars["UUID"];
};

export type MutationCreateGameModelStateStageArgs = {
  name: Scalars["Int"];
};

export type MutationCreateEducationPlanFromGoogleClassroomArgs = {
  data: ClassroomCreateEducationPlanInput;
};

export type MutationSendInvitationEmailArgs = {
  email: Scalars["String"];
};

export type MutationCreateInvitedUserArgs = {
  invitedUserId: Scalars["String"];
  invitingUserId: Scalars["String"];
};

export type MutationCreateKnowledgeBaseBlockArgs = {
  type: KnowledgeBaseBlockTypeEnum;
  themeId: Scalars["String"];
  blockTypeData?: InputMaybe<BlockTypeInput>;
};

export type MutationCreateKnowledgeBaseBlockTestAnswerArgs = {
  blockId: Scalars["String"];
  content: Scalars["String"];
  isAnswerTrue: Scalars["Boolean"];
};

export type MutationCreateKnowledgeBaseBlockListItemArgs = {
  blockId: Scalars["String"];
  content: Scalars["String"];
  isNumbered?: InputMaybe<Scalars["Boolean"]>;
  photo?: InputMaybe<Scalars["Upload"]>;
  video?: InputMaybe<Scalars["String"]>;
  photoTitle?: InputMaybe<Scalars["String"]>;
};

export type MutationUploudKnowledgeBaseBlockPhotoArgs = {
  blockId: Scalars["String"];
  photo: Scalars["Upload"];
};

export type MutationUpdateKnowledgeBaseBlockArgs = {
  id: Scalars["String"];
  blockTypeData?: InputMaybe<BlockTypeInput>;
};

export type MutationUpdateKnowledgeBaseBlockTestAnswerArgs = {
  answerId: Scalars["String"];
  content: Scalars["String"];
  isAnswerTrue: Scalars["Boolean"];
};

export type MutationUpdateKnowledgeBaseBlockListItemArgs = {
  listItemId: Scalars["String"];
  content: Scalars["String"];
  isNumbered?: InputMaybe<Scalars["Boolean"]>;
  photo?: InputMaybe<Scalars["Upload"]>;
  video?: InputMaybe<Scalars["String"]>;
  photoTitle?: InputMaybe<Scalars["String"]>;
};

export type MutationHandleKnowledgeBaseBlockEditorPhotoArgs = {
  blockId: Scalars["String"];
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationDeleteKnowledgeBaseBlockListItemArgs = {
  id: Scalars["String"];
};

export type MutationDeleteKnowledgeBaseBlockTestAnswerArgs = {
  id: Scalars["String"];
};

export type MutationDeleteKnowledgeBaseBlockArgs = {
  id: Scalars["String"];
};

export type MutationAnswerKnowledgeBaseBlockQuestionArgs = {
  blockId: Scalars["String"];
  answerId: Scalars["String"];
};

export type MutationCreateKnowledgeBaseTestAttemptArgs = {
  themeId: Scalars["String"];
};

export type MutationAnswerKnowledgeBaseBlockTestArgs = {
  blockId: Scalars["String"];
  answerId: Scalars["String"];
  attemptId: Scalars["String"];
};

export type MutationChangeKnowledgeBaseBlockTestAnswerArgs = {
  id: Scalars["String"];
  answerId: Scalars["String"];
};

export type MutationPublishKnowledgeBaseTestBlocksByThemeIdArgs = {
  themeId: Scalars["String"];
};

export type MutationCreateKnowledgeBaseCategoryArgs = {
  name: Scalars["String"];
  isFor: KnowledgeBaseCategoryIsForEnum;
  photo?: InputMaybe<Scalars["Upload"]>;
  backgroundColor?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateKnowledgeBaseCategoryArgs = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  isFor?: InputMaybe<KnowledgeBaseCategoryIsForEnum>;
  photo?: InputMaybe<Scalars["Upload"]>;
  backgroundColor?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteKnowledgeBaseCategoryArgs = {
  id: Scalars["String"];
};

export type MutationSetKnowledgeBaseCategoryParticipantsArgs = {
  knowledgeBaseCategoryData: SetKnowledgeBaseCategoryParticipantsInput;
};

export type MutationKnowledgeBaseCategoryParticipantStatusChangeArgs = {
  knowledgeBaseCategoryIds: Array<InputMaybe<Scalars["String"]>>;
  userId: Scalars["String"];
};

export type MutationCreateKnowledgeBaseThemeArgs = {
  name: Scalars["String"];
  categoryId: Scalars["String"];
};

export type MutationUpdateKnowledgeBaseThemeArgs = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  categoryId?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteKnowledgeBaseThemeArgs = {
  id: Scalars["String"];
};

export type MutationPublishKnowledgeBaseThemeArgs = {
  id: Scalars["String"];
};

export type MutationCreateLessonArgs = {
  mainId: Scalars["String"];
  themeId?: InputMaybe<Scalars["String"]>;
  modelId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type MutationUpdateLessonArgs = {
  lessonId: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteLessonArgs = {
  lessonId: Scalars["String"];
};

export type MutationPublishLessonArgs = {
  lessonId: Scalars["String"];
};

export type MutationUnpublishLessonArgs = {
  lessonId: Scalars["String"];
};

export type MutationCopyLessonToTargetServerArgs = {
  lessonId: Scalars["String"];
};

export type MutationCreateLessonBlockArgs = {
  type: LessonBlockTypeEnum;
  pageId: Scalars["String"];
};

export type MutationUpdateLessonBlockArgs = {
  lessonBlockData: UpdateLessonBlockInput;
};

export type MutationDeleteLessonBlockArgs = {
  lessonBlockId: Scalars["String"];
};

export type MutationCreateLessonListItemArgs = {
  lessonBlockId: Scalars["String"];
  lessonListItemData: LessonBlockListInput;
};

export type MutationUpdateLessonListItemArgs = {
  listItemId: Scalars["String"];
  lessonListItemData: LessonBlockListInput;
};

export type MutationDeleteLessonListItemArgs = {
  listItemId: Scalars["String"];
};

export type MutationCreateLessonMarkArgs = {
  lessonId: Scalars["UUID"];
  type: LessonMarkTypeEnum;
  userId?: InputMaybe<Scalars["UUID"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  markDigital?: InputMaybe<Scalars["Int"]>;
  markLevelId?: InputMaybe<Scalars["UUID"]>;
};

export type MutationUpdateLessonMarkArgs = {
  lessonMarkId: Scalars["UUID"];
  type: LessonMarkTypeEnum;
  markDigital?: InputMaybe<Scalars["Int"]>;
  markLevelId?: InputMaybe<Scalars["UUID"]>;
};

export type MutationSetSpecialLessonMarkArgs = {
  lessonId: Scalars["UUID"];
  specialMarkId?: InputMaybe<Scalars["UUID"]>;
  userId?: InputMaybe<Scalars["UUID"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  isAbsent?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationSetLessonMarkArgs = {
  lessonId: Scalars["UUID"];
  userId?: InputMaybe<Scalars["UUID"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  markDigital?: InputMaybe<Scalars["Int"]>;
  markLevelId?: InputMaybe<Scalars["UUID"]>;
  isAbsent?: InputMaybe<Scalars["Boolean"]>;
  additionalMarkType?: InputMaybe<AdditionalMarkTypeEnum>;
};

export type MutationDeleteLessonMarkArgs = {
  lessonMarkId: Scalars["UUID"];
};

export type MutationDeleteLessonMarksArgs = {
  lessonId: Scalars["UUID"];
};

export type MutationCreateLessonPageArgs = {
  type: LessonPageTypeEnum;
  lessonId: Scalars["String"];
};

export type MutationDeleteLessonPageArgs = {
  id: Scalars["String"];
};

export type MutationCreateLessonQuestionArgs = {
  type: LessonQuestionTypeEnum;
  pageId: Scalars["String"];
};

export type MutationUpdateLessonQuestionArgs = {
  data: UpdateLessonQuestionInput;
};

export type MutationDeleteLessonQuestionArgs = {
  id: Scalars["String"];
};

export type MutationCreateQuestionVariantArgs = {
  questionId: Scalars["String"];
  questionVariantData: QuestionVariantInput;
};

export type MutationUpdateQuestionVariantArgs = {
  variantId: Scalars["String"];
  questionVariantData: QuestionVariantInput;
};

export type MutationDeleteQuestionVariantArgs = {
  variantId: Scalars["String"];
};

export type MutationCreateApplicationModelInMarketContentArgs = {
  id: Scalars["String"];
  lang?: InputMaybe<LanguagesEnum>;
};

export type MutationUpdateApplicationModelInMarketContentArgs = {
  id: Scalars["String"];
  lang?: InputMaybe<LanguagesEnum>;
};

export type MutationDeleteApplicationModelInMarketContentArgs = {
  id: Scalars["String"];
  lang?: InputMaybe<LanguagesEnum>;
};

export type MutationUpdateMarketingModalInfoArgs = {
  data?: InputMaybe<MarketingModalInfoInput>;
};

export type MutationCreateMobileNotificationArgs = {
  notificationData: CreateMobileNotificationInput;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationUpdateMobileNotificationArgs = {
  notificationData: UpdateMobileNotificationInput;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationRemoveMobileNotificationPhotoArgs = {
  id: Scalars["String"];
};

export type MutationSetMobileNotificationParticipantsArgs = {
  notificationData: SetMobileNotificationParticipantsInput;
};

export type MutationPublishMobileNotificationArgs = {
  id: Scalars["String"];
};

export type MutationMobileNotificationParticipantStatusChangeArgs = {
  notificationIds: Array<InputMaybe<Scalars["String"]>>;
  userId: Scalars["String"];
};

export type MutationDeleteMobileNotificationArgs = {
  id: Scalars["String"];
};

export type MutationCreateMonthHourPlanArgs = {
  date: Scalars["Date"];
  hoursPlan: Scalars["Int"];
};

export type MutationUpdateMonthHourPlanArgs = {
  id: Scalars["String"];
  hoursPlan: Scalars["Int"];
};

export type MutationDeleteMonthHourPlanArgs = {
  id: Scalars["String"];
};

export type MutationCreateNewLessonArgs = {
  name: Scalars["String"];
  educationPlanChapterId?: InputMaybe<Scalars["String"]>;
  connectedToLessonId?: InputMaybe<Scalars["String"]>;
  inAppPurchases?: InputMaybe<InAppPurchasesEnum>;
  educationPlanId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  contentType?: InputMaybe<ContentTypeEnum>;
};

export type MutationUpdateNewLessonArgs = {
  id: Scalars["String"];
  lessonData: NewLessonInput;
};

export type MutationDeleteNewLessonArgs = {
  id: Scalars["String"];
};

export type MutationRestoreNewLessonArgs = {
  id: Scalars["String"];
};

export type MutationUploadNewLessonToMarketArgs = {
  data: LessonContentInput;
};

export type MutationUpdateNewLessonInMarketArgs = {
  data: LessonContentInput;
};

export type MutationImportNewLessonFromMarketArgs = {
  lessonId: Scalars["String"];
  educationPlanChapterId: Scalars["String"];
};

export type MutationImportNewLessonFromParentRecommendationArgs = {
  lessonId: Scalars["String"];
  educationPlanChapterId: Scalars["String"];
  parentId: Scalars["String"];
};

export type MutationImportLessonContentFromMarketArgs = {
  lessonId: Scalars["String"];
  slideId: Scalars["String"];
};

export type MutationDuplicateNewLessonArgs = {
  educationPlanChapterId?: InputMaybe<Scalars["String"]>;
  educationPlanId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  subjectId?: InputMaybe<Scalars["String"]>;
  connectedToLessonId?: InputMaybe<Scalars["String"]>;
  lessonId: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationMoveNewLessonArgs = {
  educationPlanChapterId?: InputMaybe<Scalars["String"]>;
  educationPlanId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  subjectId?: InputMaybe<Scalars["String"]>;
  connectedToLessonId?: InputMaybe<Scalars["String"]>;
  lessonId: Scalars["String"];
};

export type MutationImportLessonContentIntoConstructorArgs = {
  lessonId?: InputMaybe<Scalars["String"]>;
  experimentId?: InputMaybe<Scalars["String"]>;
  slideId?: InputMaybe<Scalars["String"]>;
  currentLessonId: Scalars["String"];
  specificSlideIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fromMarket?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationImportNewLessonAndChangeContentTypeArgs = {
  contentType: ContentTypeEnum;
  contentId: Scalars["String"];
  isFromLibrary: Scalars["Boolean"];
  educationPlanChapterId?: InputMaybe<Scalars["String"]>;
  connectedToLessonId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationSetNewLessonPupilLoginCodeArgs = {
  id: Scalars["UUID"];
};

export type MutationGenerateTeacherLoginCodeArgs = {
  id: Scalars["UUID"];
};

export type MutationImportRecommendedLessonIntoCurrentLessonArgs = {
  recommendedContentId: Scalars["UUID"];
  lessonId: Scalars["UUID"];
};

export type MutationSetIsLectorArgs = {
  contentId: Scalars["UUID"];
  isLector: Scalars["Boolean"];
};

export type MutationCreateLessonResultsArgs = {
  lessonId: Scalars["UUID"];
};

export type MutationCreateTranslatedLessonArgs = {
  lessonId: Scalars["UUID"];
  originalLanguage: LanguageEnum;
  targetLanguage: LanguageEnum;
};

export type MutationAnswerNewLessonFeedbackQuestionsArgs = {
  feedbackData: Array<InputMaybe<FeedbackDataInput>>;
};

export type MutationCreateNewLessonFeedbackQuestionArgs = {
  content: Scalars["String"];
};

export type MutationUpdateNewLessonFeedbackQuestionArgs = {
  id: Scalars["String"];
  content: Scalars["String"];
};

export type MutationDeleteNewLessonFeedbackQuestionArgs = {
  id: Scalars["String"];
};

export type MutationCreateNewLessonPreviewArgs = {
  lessonId: Scalars["String"];
  file?: InputMaybe<Scalars["Upload"]>;
  videoUrl?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateNewLessonPreviewOrderArgs = {
  previewId: Scalars["String"];
  order: Scalars["Int"];
};

export type MutationDeleteNewLessonPreviewArgs = {
  previewId: Scalars["String"];
};

export type MutationCreateNewsArgs = {
  newsData: CreateNewsInput;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationUpdateNewsArgs = {
  newsData: UpdateNewsInput;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationSetNewsParticipantsArgs = {
  newsData: SetNewsParticipantsInput;
};

export type MutationDeleteNewsArgs = {
  id: Scalars["String"];
};

export type MutationPublishNewsArgs = {
  id: Scalars["String"];
};

export type MutationNewsParticipantStatusChangeArgs = {
  newsId: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationDeleteNewsPhotoArgs = {
  id: Scalars["String"];
};

export type MutationCreateNotificationArgs = {
  notificationData: CreateNotificationInput;
};

export type MutationUpdateNotificationArgs = {
  notificationData: UpdateNotificationInput;
};

export type MutationSetNotificationParticipantsArgs = {
  notificationData: SetNotificationParticipantsInput;
};

export type MutationDeleteNotificationArgs = {
  id: Scalars["String"];
};

export type MutationPublishNotificationArgs = {
  id: Scalars["String"];
};

export type MutationViewNotificationArgs = {
  notificationId: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationNotificationParticipantStatusChangeArgs = {
  notificationIds: Array<InputMaybe<Scalars["String"]>>;
  userId: Scalars["String"];
};

export type MutationOnboardingThemeFirstArgs = {
  themeId: Scalars["String"];
};

export type MutationOnboardingThemeFinishedArgs = {
  themeId: Scalars["String"];
};

export type MutationCreateOnboardingThemeArgs = {
  title: Scalars["String"];
  description: Scalars["String"];
  video: Scalars["String"];
  routeId?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["Int"]>;
};

export type MutationUpdateOnboardingThemeArgs = {
  themeId: Scalars["String"];
  isFinishedReset: Scalars["Boolean"];
  title: Scalars["String"];
  description: Scalars["String"];
  video: Scalars["String"];
  routeId?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["Int"]>;
};

export type MutationDeleteOnboardingThemeArgs = {
  themeId: Scalars["String"];
};

export type MutationReorderOnboardingThemeArgs = {
  themeId: Scalars["String"];
  position: Scalars["Int"];
};

export type MutationPublishOnboardingThemeArgs = {
  themeId: Scalars["String"];
};

export type MutationUnpublishOnboardingThemeArgs = {
  themeId: Scalars["String"];
};

export type MutationCreatePaymentFormAdminPanelArgs = {
  data: PaymentData;
};

export type MutationCreatePaymentFormLkTeacherArgs = {
  data: PaymentData;
};

export type MutationCreateSubscriptionPlanPaymentFromAdminPanelArgs = {
  data: SubscriptionPlanPaymentData;
};

export type MutationCreateSubscriptionPlanPaymentFromLkTeacherArgs = {
  data: SubscriptionPlanPaymentData;
};

export type MutationGenerateWayForPaySignatureArgs = {
  data: WayForPayPayload;
};

export type MutationGenerateLiqpayUrlArgs = {
  data: LiqpayPayload;
};

export type MutationCreateStripePaymentSessionArgs = {
  data: StripePayload;
};

export type MutationGenerateLiqpayWidgetParamsArgs = {
  data: LiqpayPayload;
};

export type MutationHandlePaymentActivationArgs = {
  id: Scalars["String"];
  isActivated?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationDeletePaymentArgs = {
  id: Scalars["String"];
};

export type MutationUpdatePaymentTeachersCountArgs = {
  schoolId: Scalars["String"];
  teachersCount?: InputMaybe<Scalars["Int"]>;
};

export type MutationCreatePurchasePaymentFromAdminPanelArgs = {
  schoolId: Scalars["UUID"];
  paymentDate: Scalars["Date"];
  paymentType?: InputMaybe<AllowedAdminPanelPaymentType>;
  paymentTypeId?: InputMaybe<Scalars["UUID"]>;
  productId: Scalars["Int"];
  price: Scalars["Float"];
};

export type MutationCreatePurchasePaymentFromLkTeacherArgs = {
  contentId: Scalars["UUID"];
};

export type MutationDeactivatePurchasePaymentArgs = {
  paymentId: Scalars["UUID"];
};

export type MutationUnsubscribeSubscriptionArgs = {
  subscriptionPlanId: Scalars["UUID"];
  reason: Scalars["String"];
};

export type MutationResumeSubscriptionArgs = {
  data: Scalars["String"];
};

export type MutationCreatePersonalSubscriptionV3FromAdminPanelArgs = {
  data: PaymentDataV3;
};

export type MutationCreatePaymentSessionArgs = {
  data: CreatePaymentSessionInput;
};

export type MutationClearPaymentSessionArgs = {
  id?: InputMaybe<Scalars["UUID"]>;
};

export type MutationCreatePayrollBonusArgs = {
  payrollId: Scalars["String"];
  name: Scalars["String"];
  value: Scalars["Int"];
};

export type MutationUpdatePayrollBonusArgs = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["Int"]>;
};

export type MutationDeletePayrollBonusArgs = {
  bonusId: Scalars["String"];
};

export type MutationCreatePayrollKpiArgs = {
  kpiData: CreatePayrollKpiInput;
};

export type MutationUpdatePayrollKpiArgs = {
  kpiData: UpdatePayrollKpiInput;
};

export type MutationDeletePayrollKpiArgs = {
  kpiId: Scalars["String"];
};

export type MutationCreatePayrollSalaryPartArgs = {
  payrollId: Scalars["String"];
  name: Scalars["String"];
  date: Scalars["Date"];
  value: Scalars["Int"];
};

export type MutationUpdatePayrollSalaryPartArgs = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  date?: InputMaybe<Scalars["Date"]>;
  value?: InputMaybe<Scalars["Int"]>;
};

export type MutationDeletePayrollSalaryPartArgs = {
  partId: Scalars["String"];
};

export type MutationAddByUserIdPermissionArgs = {
  userId: Scalars["String"];
  flag: Scalars["String"];
};

export type MutationRemoveByUserIdPermissionArgs = {
  userId: Scalars["String"];
  flag: Scalars["String"];
};

export type MutationAddPermissionByUserIdArgs = {
  userId: Scalars["UUID"];
  flagId: Scalars["UUID"];
};

export type MutationRemovePermissionByUserIdArgs = {
  userId: Scalars["UUID"];
  flagId: Scalars["UUID"];
};

export type MutationAddByPersonalSchoolIdPermissionArgs = {
  schoolId: Scalars["UUID"];
  flagId: Scalars["UUID"];
};

export type MutationRemoveByPersonalSchoolIdPermissionArgs = {
  schoolId: Scalars["UUID"];
  flagId: Scalars["UUID"];
};

export type MutationAddByUserIdsPermissionArgs = {
  userIds: Array<InputMaybe<Scalars["String"]>>;
  flag: Scalars["String"];
};

export type MutationRemoveByUserIdsPermissionArgs = {
  userIds: Array<InputMaybe<Scalars["String"]>>;
  flag: Scalars["String"];
};

export type MutationAddByJobIdsPermissionArgs = {
  jobIds: Array<InputMaybe<Scalars["String"]>>;
  flag: Scalars["String"];
};

export type MutationRemoveByJobIdsPermissionArgs = {
  jobIds: Array<InputMaybe<Scalars["String"]>>;
  flag: Scalars["String"];
};

export type MutationAddByEmailsPermissionArgs = {
  emails: Array<InputMaybe<Scalars["String"]>>;
  flag: Scalars["String"];
};

export type MutationRemoveByEmailsPermissionArgs = {
  emails: Array<InputMaybe<Scalars["String"]>>;
  flag: Scalars["String"];
};

export type MutationAddByRolesPermissionArgs = {
  roles: Array<InputMaybe<Scalars["String"]>>;
  flag: Scalars["String"];
};

export type MutationRemoveByRolesPermissionArgs = {
  roles: Array<InputMaybe<Scalars["String"]>>;
  flag: Scalars["String"];
};

export type MutationAddByAllPermissionArgs = {
  flag: Scalars["String"];
};

export type MutationRemoveByAllPermissionArgs = {
  flag: Scalars["String"];
};

export type MutationCreateProductArgs = {
  productData: CreateProductInput;
};

export type MutationUpdateProductArgs = {
  productData: UpdateProductInput;
};

export type MutationDeleteProductArgs = {
  productId: Scalars["String"];
};

export type MutationCreateProjectArgs = {
  jiraKey: Scalars["String"];
  name: Scalars["String"];
};

export type MutationCreateProjectByJiraDataArgs = {
  jira: Scalars["String"];
  jiraKey: Scalars["String"];
};

export type MutationUpdateProjectArgs = {
  projectData: UpdateProjectInput;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationDeleteProjectArgs = {
  projectId: Scalars["String"];
};

export type MutationSyncProjectArgs = {
  projectId: Scalars["String"];
};

export type MutationAssignProjectTrackerIdArgs = {
  projectId: Scalars["String"];
};

export type MutationUpdateProjectFilesArgs = {
  projectId: Scalars["String"];
  deleteFileId?: InputMaybe<Scalars["String"]>;
  uploadFile?: InputMaybe<UploadFileInput>;
};

export type MutationCreateProjectAdditionalContactArgs = {
  projectAdditionalContactData: CreateProjectAdditionalContactInput;
};

export type MutationUpdateProjectAdditionalContactArgs = {
  projectAdditionalContactData: UpdateProjectAdditionalContactInput;
};

export type MutationDeleteProjectAdditionalContactArgs = {
  projectAdditionalContactId: Scalars["String"];
};

export type MutationCreateProjectCorrectionArgs = {
  projectCorrectionData: CreateProjectCorrectionInput;
};

export type MutationUpdateProjectCorrectionArgs = {
  projectCorrectionData: UpdateProjectCorrectionInput;
};

export type MutationDeleteProjectCorrectionArgs = {
  projectCorrectionId: Scalars["String"];
};

export type MutationAddCorrectionsToProjectArgs = {
  projectCorrectionIds: Array<InputMaybe<Scalars["String"]>>;
  projectId: Scalars["String"];
};

export type MutationRemoveCorrectionsFromProjectArgs = {
  projectCorrectionIds: Array<InputMaybe<Scalars["String"]>>;
  projectId: Scalars["String"];
};

export type MutationCreateProjectCustomFieldsMapArgs = {
  customFieldsData: CreateProjectCustomFieldsMapInput;
};

export type MutationUpdateProjectCustomFieldsMapArgs = {
  customFieldsData: UpdateProjectCustomFieldsMapInput;
};

export type MutationUpdateProjectCustomFieldsMapByProjectArgs = {
  customFieldsData: UpdateProjectCustomFieldsMapByProjectInput;
};

export type MutationDeleteProjectCustomFieldsMapArgs = {
  id: Scalars["String"];
};

export type MutationCreateProjectKanbanArgs = {
  projectId: Scalars["String"];
  name: Scalars["String"];
};

export type MutationRestartSprintArgs = {
  id: Scalars["String"];
  name: Scalars["String"];
};

export type MutationDeleteProjectKanbanArgs = {
  id: Scalars["String"];
};

export type MutationClearProjectKanbanArgs = {
  id: Scalars["String"];
};

export type MutationActivateProjectKanbanArgs = {
  id: Scalars["String"];
};

export type MutationCreateBaseKanbanWorkflowStepArgs = {
  name: Scalars["String"];
  taskStatusId: Scalars["String"];
  order: Scalars["Int"];
};

export type MutationCreateKanbanWorkflowStepForProjectArgs = {
  name: Scalars["String"];
  taskStatusId: Scalars["String"];
  projectId: Scalars["String"];
  order: Scalars["Int"];
};

export type MutationUpdateKanbanWorkflowStepArgs = {
  id: Scalars["String"];
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type MutationDeleteKanbanWorkflowStepArgs = {
  id: Scalars["String"];
};

export type MutationCreateProjectLinkArgs = {
  projectLinkData: CreateProjectLinkInput;
};

export type MutationUpdateProjectLinkArgs = {
  projectLinkData: UpdateProjectLinkInput;
};

export type MutationDeleteProjectLinkArgs = {
  projectLinkId: Scalars["String"];
};

export type MutationAddLinksToProjectArgs = {
  projectLinkIds: Array<InputMaybe<Scalars["String"]>>;
  projectId: Scalars["String"];
};

export type MutationRemoveLinksFromProjectArgs = {
  projectLinkIds: Array<InputMaybe<Scalars["String"]>>;
  projectId: Scalars["String"];
};

export type MutationCreateProjectPresentationArgs = {
  projectId: Scalars["String"];
  link: Scalars["String"];
  title?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateProjectPresentationArgs = {
  id: Scalars["String"];
  link?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteProjectPresentationArgs = {
  projectPresentationId: Scalars["String"];
};

export type MutationCreateProjectStepArgs = {
  projectStepData: CreateProjectStepInput;
};

export type MutationUpdateProjectStepArgs = {
  projectStepData: UpdateProjectStepInput;
};

export type MutationDeleteProjectStepArgs = {
  projectStepId: Scalars["String"];
};

export type MutationCreateProjectTaskArgs = {
  taskData: CreateProjectTaskInput;
};

export type MutationCreateProjectTaskInKanbanArgs = {
  taskData: CreateProjectTaskInput;
  workflowStepId: Scalars["String"];
  workflowStepOrder: Scalars["Int"];
};

export type MutationUpdateProjectTaskArgs = {
  taskData: UpdateProjectTaskInput;
};

export type MutationDeleteProjectTaskArgs = {
  id: Scalars["String"];
};

export type MutationCreateProjectTasksInTrackerArgs = {
  projectId: Scalars["String"];
};

export type MutationSyncProjectTaskWithTrackerArgs = {
  id: Scalars["String"];
};

export type MutationSyncProjectTasksWithTrackerArgs = {
  projectId: Scalars["String"];
};

export type MutationImportProjectTasksFromGSheetsArgs = {
  projectId: Scalars["String"];
  gSheetId: Scalars["String"];
  gSheetTitle: Scalars["String"];
};

export type MutationAddProjectTasksToKanbanArgs = {
  tasksIds: Array<InputMaybe<Scalars["String"]>>;
  workflowStepId: Scalars["String"];
  workflowStepStartOrder: Scalars["Int"];
};

export type MutationChangeProjectTaskWorkflowStepArgs = {
  tasksId: Scalars["String"];
  workflowStepId: Scalars["String"];
  workflowStepOrder: Scalars["Int"];
};

export type MutationRemoveProjectTaskFromKanbanArgs = {
  tasksId: Scalars["String"];
};

export type MutationSetProjectTaskLateReasonArgs = {
  tasksId: Scalars["String"];
  lateReason: Scalars["String"];
};

export type MutationSetTaskBoundariesArgs = {
  id: Scalars["String"];
  startDateEstimate: Scalars["String"];
  finishDateEstimate: Scalars["String"];
  isEpic?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreatePtCheckListItemArgs = {
  projectTaskId: Scalars["String"];
  name: Scalars["String"];
};

export type MutationUpdatePtCheckListItemArgs = {
  id: Scalars["String"];
  name: Scalars["String"];
};

export type MutationDeletePtCheckListItemArgs = {
  id: Scalars["String"];
};

export type MutationChangePtCheckListItemStatusArgs = {
  id: Scalars["String"];
};

export type MutationCreateProjectTaskCommentArgs = {
  projectTaskId: Scalars["String"];
  text: Scalars["String"];
  file?: InputMaybe<Scalars["Upload"]>;
};

export type MutationUpdateProjectTaskCommentArgs = {
  id: Scalars["String"];
  text: Scalars["String"];
  file?: InputMaybe<Scalars["Upload"]>;
};

export type MutationDeleteProjectTaskCommentArgs = {
  id: Scalars["String"];
};

export type MutationDeleteProjectTaskCommentFileArgs = {
  id: Scalars["String"];
};

export type MutationCreateBaseProjectTaskLabelArgs = {
  name: Scalars["String"];
  color: Scalars["String"];
};

export type MutationCreateProjectTaskLabelForProjectArgs = {
  name: Scalars["String"];
  color: Scalars["String"];
  projectId: Scalars["String"];
};

export type MutationUpdateProjectTaskLabelArgs = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  color?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteProjectTaskLabelArgs = {
  id: Scalars["String"];
};

export type MutationAssignProjectTaskLabelArgs = {
  projectTaskId: Scalars["String"];
  labelId: Scalars["String"];
};

export type MutationRemoveProjectTaskLabelArgs = {
  projectTaskId: Scalars["String"];
  labelId: Scalars["String"];
};

export type MutationCreateBaseProjectTaskStatusArgs = {
  name: Scalars["String"];
};

export type MutationCreateProjectTaskStatusForProjectArgs = {
  name: Scalars["String"];
  projectId: Scalars["String"];
};

export type MutationUpdateProjectTaskStatusArgs = {
  id: Scalars["String"];
  name: Scalars["String"];
};

export type MutationDeleteProjectTaskStatusArgs = {
  id: Scalars["String"];
};

export type MutationCreateProjectTypeArgs = {
  name: Scalars["String"];
};

export type MutationUpdateProjectTypeArgs = {
  typeId: Scalars["String"];
  name: Scalars["String"];
};

export type MutationDeleteProjectTypeArgs = {
  typeId: Scalars["String"];
};

export type MutationApplyPromoCodeArgs = {
  code: Scalars["String"];
};

export type MutationCreateManualPromotionArgs = {
  data: CreateManualPromotionInput;
};

export type MutationUpdateManualPromotionArgs = {
  id: Scalars["UUID"];
  data: UpdateManualPromotionInput;
};

export type MutationDeleteManualPromotionArgs = {
  id: Scalars["UUID"];
};

export type MutationCreatePurchaseArgs = {
  productId: Scalars["String"];
};

export type MutationUpdatePurchaseArgs = {
  purchaseId: Scalars["String"];
  status: PurchaseStatusEnum;
};

export type MutationDeletePurchaseArgs = {
  purchaseId: Scalars["String"];
};

export type MutationCreateQuestionArgs = {
  testId: Scalars["String"];
  question: Scalars["String"];
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationUpdateQuestionArgs = {
  id: Scalars["String"];
  question: Scalars["String"];
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationSetQuestionRightVariantArgs = {
  questionId: Scalars["String"];
  rightVariantId: Scalars["String"];
};

export type MutationDeleteQuestionArgs = {
  id: Scalars["String"];
};

export type MutationCreateQuoteCollectionArgs = {
  name: Scalars["String"];
  file: Scalars["Upload"];
};

export type MutationUpdateQuoteCollectionArgs = {
  id: Scalars["UUID"];
  name?: InputMaybe<Scalars["String"]>;
  file?: InputMaybe<Scalars["Upload"]>;
};

export type MutationDeleteQuoteCollectionArgs = {
  id: Scalars["UUID"];
};

export type MutationCreateRewardArgs = {
  input: RewardInput;
};

export type MutationUpdateRewardArgs = {
  id: Scalars["UUID"];
  input: RewardInput;
};

export type MutationDeleteRewardArgs = {
  id: Scalars["UUID"];
};

export type MutationCreateRewardGroupArgs = {
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type MutationCreateRobotsHistoryLinkArgs = {
  input: CreateRobotsHistoryInput;
};

export type MutationDeleteRobotsHistoryArgs = {
  id: Scalars["UUID"];
};

export type MutationCreateSchoolArgs = {
  schoolData: CreateSchoolInput;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationUpdateSchoolArgs = {
  schoolData: CreateSchoolInput;
  photo?: InputMaybe<Scalars["Upload"]>;
  id: Scalars["String"];
};

export type MutationDeleteSchoolArgs = {
  id: Scalars["String"];
};

export type MutationDeleteSchoolPhotoArgs = {
  id: Scalars["String"];
};

export type MutationSendUsersSchoolConnectionEmailArgs = {
  data: UsersSchoolConnectionEmailData;
};

export type MutationSendTeacherConnectionEmailArgs = {
  email: Scalars["String"];
  schoolId: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  middleName?: InputMaybe<Scalars["String"]>;
};

export type MutationSendPupilSchoolConnectionEmailArgs = {
  schoolId: Scalars["String"];
  emails: Array<InputMaybe<Scalars["String"]>>;
  lang?: InputMaybe<LanguagesEnum>;
};

export type MutationCheckIsConnectionByEmailTeacherExistArgs = {
  schoolId: Scalars["String"];
  email: Scalars["String"];
};

export type MutationRemovePupilFromSchoolArgs = {
  pupilId: Scalars["String"];
  schoolId: Scalars["String"];
};

export type MutationSetTeacherWorkplaceArgs = {
  schoolName: Scalars["String"];
  cityId: Scalars["UUID"];
  districtId?: InputMaybe<Scalars["UUID"]>;
  regionId: Scalars["UUID"];
  countryId?: InputMaybe<Scalars["UUID"]>;
  addressIndex?: InputMaybe<Scalars["String"]>;
};

export type MutationInviteUsersByListArgs = {
  list: Scalars["Upload"];
  schoolId: Scalars["UUID"];
};

export type MutationInviteUsersByListV3Args = {
  list: Scalars["Upload"];
  schoolId: Scalars["UUID"];
};

export type MutationUpdateUserInvitationArgs = {
  id: Scalars["UUID"];
  accept?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateSchoolCityArgs = {
  name: Scalars["String"];
  regionId: Scalars["String"];
  districtId?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateSchoolCityArgs = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["String"]>;
  districtId?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteSchoolCityArgs = {
  id: Scalars["String"];
};

export type MutationCreateCustomCityArgs = {
  cityName: Scalars["String"];
  districtId?: InputMaybe<Scalars["UUID"]>;
  districtName?: InputMaybe<Scalars["String"]>;
  regionId: Scalars["UUID"];
};

export type MutationCreateSchoolClassroomArgs = {
  grade: Scalars["Int"];
  letter?: InputMaybe<Scalars["String"]>;
  schoolId: Scalars["String"];
  classTeacherId?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateSchoolClassroomArgs = {
  id: Scalars["String"];
  grade?: InputMaybe<Scalars["Int"]>;
  letter?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  classTeacherId?: InputMaybe<Scalars["String"]>;
};

export type MutationRemoveSchoolClassroomTeacherArgs = {
  schoolClassroomId: Scalars["String"];
};

export type MutationAddPupilToSchoolClassroomArgs = {
  classroomId: Scalars["String"];
  pupilId: Scalars["String"];
};

export type MutationAddPupilToSchoolClassroomV3Args = {
  hash: Scalars["UUID"];
};

export type MutationRemovePupilFromSchoolClassroomArgs = {
  pupilId: Scalars["String"];
  classroomId: Scalars["String"];
};

export type MutationDeleteSchoolClassroomArgs = {
  id: Scalars["String"];
};

export type MutationArchiveSchoolClassroomArgs = {
  id: Scalars["String"];
};

export type MutationSendPupilSchoolClassroomConnectionEmailArgs = {
  schoolClassroomId: Scalars["String"];
  emails: Array<InputMaybe<Scalars["String"]>>;
  lang?: InputMaybe<LanguagesEnum>;
};

export type MutationSendClassroomConnectionEmailArgs = {
  classroomId: Scalars["String"];
  emails: Array<InputMaybe<Scalars["String"]>>;
};

export type MutationCreateSchoolDistrictArgs = {
  name: Scalars["String"];
  regionId: Scalars["String"];
};

export type MutationUpdateSchoolDistrictArgs = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteSchoolDistrictArgs = {
  id: Scalars["String"];
};

export type MutationCreateSchoolEventArgs = {
  schoolEventData: CreateSchoolEventInput;
};

export type MutationUpdateSchoolEventArgs = {
  schoolEventData: CreateSchoolEventInput;
};

export type MutationDeleteSchoolEventArgs = {
  id: Scalars["String"];
};

export type MutationRestoreSchoolEventArgs = {
  id: Scalars["String"];
};

export type MutationCreateSchoolPayerArgs = {
  data: SchoolPayerInput;
};

export type MutationUpdateSchoolPayerArgs = {
  id: Scalars["UUID"];
  data: SchoolPayerInput;
};

export type MutationDeleteSchoolPayerArgs = {
  id: Scalars["UUID"];
};

export type MutationRecoverSchoolPayerArgs = {
  id: Scalars["UUID"];
};

export type MutationCreateSchoolRegionArgs = {
  name: Scalars["String"];
  countryId: Scalars["String"];
};

export type MutationUpdateSchoolRegionArgs = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  countryId?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteSchoolRegionArgs = {
  id: Scalars["String"];
};

export type MutationAddTeacherToSchoolArgs = {
  schoolId: Scalars["String"];
  teacherId: Scalars["String"];
  teacherStatus?: InputMaybe<SchoolUserStatusEnum>;
  teacherPosition?: InputMaybe<Scalars["String"]>;
};

export type MutationAddUserToSchoolArgs = {
  hash: Scalars["UUID"];
};

export type MutationRemoveTeacherFromSchoolArgs = {
  schoolId: Scalars["String"];
  teacherId: Scalars["String"];
};

export type MutationAddSchoolAdminArgs = {
  schoolId: Scalars["String"];
  teacherId: Scalars["String"];
  teacherPosition?: InputMaybe<Scalars["String"]>;
};

export type MutationRemoveAdminFromPositionArgs = {
  schoolId: Scalars["String"];
  teacherId: Scalars["String"];
};

export type MutationChangeTeacherActiveSchoolArgs = {
  schoolId: Scalars["String"];
};

export type MutationChangeActiveSchoolArgs = {
  schoolId: Scalars["String"];
};

export type MutationUpdateTeacherPositionArgs = {
  schoolId: Scalars["String"];
  teacherId: Scalars["String"];
  teacherPosition: Scalars["String"];
};

export type MutationUpdateUserSubjectGradesFormatArgs = {
  subjects?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  grades?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  teachingFormat?: InputMaybe<TeachingFormatEnum>;
  schoolId?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateTeacherSchoolArgs = {
  schoolId: Scalars["UUID"];
  data: UpdateTeacherSchoolInput;
};

export type MutationCreateRequestForPaymentArgs = {
  email: Scalars["String"];
};

export type MutationCreateSessionHistoryArgs = {
  isFrom: IsFromEnum;
};

export type MutationDeleteSessionHistoryArgs = {
  id: Scalars["String"];
};

export type MutationCreateSlideArgs = {
  lessonId: Scalars["String"];
  startPosition: Scalars["Int"];
  slideData?: InputMaybe<SlideInput>;
};

export type MutationCreateGoogleSlidesArgs = {
  lessonId: Scalars["String"];
  startPosition: Scalars["Int"];
  slides: Array<InputMaybe<Scalars["Upload"]>>;
};

export type MutationUpdateSlideArgs = {
  id: Scalars["String"];
  slideData?: InputMaybe<SlideInput>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  order?: InputMaybe<Scalars["Int"]>;
  validateData?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationDeleteSlideArgs = {
  id: Scalars["String"];
};

export type MutationRestoreSlideArgs = {
  id: Scalars["String"];
};

export type MutationUpdateSlideFileArgs = {
  id: Scalars["String"];
  file: Scalars["Upload"];
};

export type MutationDeleteSlideFileArgs = {
  id: Scalars["String"];
};

export type MutationUploadPresentationArgs = {
  id: Scalars["String"];
  file: Scalars["Upload"];
};

export type MutationCompleteUploadPresentationArgs = {
  lessonId: Scalars["String"];
};

export type MutationDuplicateSlideArgs = {
  slideId: Scalars["String"];
};

export type MutationUpdateSlideMetadataArgs = {
  slideId?: InputMaybe<Scalars["String"]>;
  lessonId?: InputMaybe<Scalars["String"]>;
  metadata: Scalars["String"];
};

export type MutationDeleteSlideMetadataArgs = {
  slideId?: InputMaybe<Scalars["String"]>;
  lessonId?: InputMaybe<Scalars["String"]>;
};

export type MutationUploadFileToSlideEditorArgs = {
  slideId: Scalars["String"];
  file: Scalars["Upload"];
  cropRegion?: InputMaybe<CropRegion>;
};

export type MutationApplySettingsToAllSlidesArgs = {
  lessonId: Scalars["String"];
  input: ApplySettingsToAllSlidesInput;
};

export type MutationCreateSlideReflectionArgs = {
  data: CreateSlideReflectionInput;
};

export type MutationUpdateOpenQuestionMarkArgs = {
  slideId: Scalars["UUID"];
  userId?: InputMaybe<Scalars["UUID"]>;
  mark?: InputMaybe<Scalars["Int"]>;
  fullName?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateSlideTestChoiceArgs = {
  slideId: Scalars["String"];
  choiceData: SlideTestChoiceInput;
};

export type MutationUpdateSlideTestChoiceArgs = {
  id: Scalars["String"];
  choiceData?: InputMaybe<SlideTestChoiceInput>;
  order?: InputMaybe<Scalars["Int"]>;
};

export type MutationDeleteSlideTestChoiceArgs = {
  id: Scalars["String"];
};

export type MutationCreateSlideTestChoiceConformityArgs = {
  text?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<Scalars["String"]>;
  type: SlideTestConformityType;
  testChoiceId: Scalars["String"];
  questionId?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateSlideTestChoiceConformityArgs = {
  id: Scalars["String"];
  text?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<Scalars["String"]>;
  type: SlideTestConformityType;
  testChoiceId: Scalars["String"];
  questionId?: InputMaybe<Scalars["String"]>;
  file?: InputMaybe<Scalars["Upload"]>;
};

export type MutationDeleteSlideTestChoiceConformityArgs = {
  id: Scalars["String"];
};

export type MutationCreateSmartNotificationArgs = {
  smartNotificationData: CreateSmartNotificationInput;
  photo?: InputMaybe<Scalars["Upload"]>;
  video?: InputMaybe<Scalars["Upload"]>;
};

export type MutationUpdateSmartNotificationArgs = {
  smartNotificationData: UpdateSmartNotificationInput;
  photo?: InputMaybe<Scalars["Upload"]>;
  video?: InputMaybe<Scalars["Upload"]>;
};

export type MutationDeleteSmartNotificationArgs = {
  id: Scalars["String"];
};

export type MutationPublishSmartNotificationArgs = {
  id: Scalars["String"];
};

export type MutationUnpublishSmartNotificationArgs = {
  id: Scalars["String"];
};

export type MutationDeleteSmartNotificationPhotoArgs = {
  id: Scalars["String"];
};

export type MutationSetSmartNotificationParticipantsArgs = {
  notificationData: SetSmartNotificationParticipantsInput;
};

export type MutationSmartNotificationParticipantStatusChangeArgs = {
  notificationIds: Array<InputMaybe<Scalars["String"]>>;
  userId: Scalars["String"];
};

export type MutationCreateSmartNotificationPollListArgs = {
  name: Scalars["String"];
  notificationId: Scalars["String"];
};

export type MutationUpdateSmartNotificationPollListArgs = {
  id: Scalars["String"];
  name: Scalars["String"];
};

export type MutationDeleteSmartNotificationPollListArgs = {
  id: Scalars["String"];
};

export type MutationCreateSubscriptionPlanArgs = {
  planData: PlanInput;
};

export type MutationUpdateSubscriptionPlanArgs = {
  id: Scalars["String"];
  planData: PlanInput;
};

export type MutationDeleteSubscriptionPlanArgs = {
  id: Scalars["String"];
};

export type MutationCreateSubscriptionPlanFeatureArgs = {
  featureData: CreateFeatureInput;
};

export type MutationUpdateSubscriptionPlanFeatureArgs = {
  id: Scalars["String"];
  featureData: UpdateFeatureInput;
};

export type MutationDeleteSubscriptionPlanFeatureArgs = {
  id: Scalars["String"];
};

export type MutationCreateSubscriptionSalesArgs = {
  data: SubscriptionSalesInput;
};

export type MutationDeleteTeacherPlanDataArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateTeacherSchoolReportArgs = {
  reportToId: Scalars["String"];
};

export type MutationCreateTerritorialCommunityArgs = {
  data: TerritorialCommunityInput;
};

export type MutationUpdateTerritorialCommunityArgs = {
  id: Scalars["UUID"];
  data: TerritorialCommunityInput;
};

export type MutationDeleteTerritorialCommunityArgs = {
  id: Scalars["UUID"];
};

export type MutationRecoverTerritorialCommunityArgs = {
  id: Scalars["UUID"];
};

export type MutationCreateTestArgs = {
  testData: CreateTestInput;
};

export type MutationUpdateTestArgs = {
  testData: UpdateTestInput;
};

export type MutationPublishTestArgs = {
  id: Scalars["String"];
};

export type MutationDeleteTestArgs = {
  id: Scalars["String"];
};

export type MutationCreateTrainerArgs = {
  trainerData: CreateTrainerInput;
};

export type MutationUpdateTrainerArgs = {
  id: Scalars["String"];
  trainerData: CreateTrainerInput;
};

export type MutationDeleteTrainerArgs = {
  id: Scalars["String"];
};

export type MutationPublishTrainerArgs = {
  id: Scalars["String"];
};

export type MutationUnpublishTrainerArgs = {
  id: Scalars["String"];
};

export type MutationCreateTraningArgs = {
  traningData: CreateTraningInput;
};

export type MutationUpdateTraningArgs = {
  id: Scalars["String"];
  traningData: CreateTraningInput;
};

export type MutationDeleteTraningArgs = {
  id: Scalars["String"];
};

export type MutationPublishTraningArgs = {
  id: Scalars["String"];
};

export type MutationUnpublishTraningArgs = {
  id: Scalars["String"];
};

export type MutationUploadFileArgs = {
  file: Scalars["Upload"];
};

export type MutationDeleteUploadedFileArgs = {
  uploadedFileId: Scalars["String"];
};

export type MutationCreateUserV2Args = {
  userData: CreateUserInputV2;
};

export type MutationCreateUserV3Args = {
  userData: CreateUserInputV3;
};

export type MutationCreateUserArgs = {
  userData: CreateUserInput;
  isFrom: IsFromEnum;
  lang?: InputMaybe<LangEnum>;
};

export type MutationConfirmUserArgs = {
  token: Scalars["String"];
};

export type MutationConfirmUserV3Args = {
  hash: Scalars["UUID"];
};

export type MutationConfirmUserFromArtArgs = {
  token: Scalars["String"];
  isFrom: IsFromEnum;
};

export type MutationConfirmChildByParentV3Args = {
  hash: Scalars["UUID"];
};

export type MutationLoginArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
  isFrom: IsFromEnum;
  hash?: InputMaybe<Scalars["UUID"]>;
  isPersonalSpace?: InputMaybe<Scalars["Boolean"]>;
  referrerCode?: InputMaybe<Scalars["String"]>;
};

export type MutationLoginPupilGuestArgs = {
  pupilLoginCode: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type MutationLoginTeacherGuestArgs = {
  teacherLoginCode: Scalars["String"];
};

export type MutationLoginMobileArgs = {
  phone: Scalars["String"];
  password: Scalars["String"];
  token: Scalars["String"];
};

export type MutationLoginOAuthArgs = {
  email: Scalars["String"];
  isFrom: IsFromEnum;
};

export type MutationLoginAppleArgs = {
  registeredCode: Scalars["String"];
  isFrom: IsFromEnum;
};

export type MutationForgotPasswordArgs = {
  email: Scalars["String"];
  isFrom: IsFromEnum;
  lang?: InputMaybe<LangEnum>;
};

export type MutationForgotPasswordV3Args = {
  email: Scalars["String"];
  isFrom: IsFromEnum;
  slug?: InputMaybe<Scalars["String"]>;
  referrerCode?: InputMaybe<Scalars["String"]>;
};

export type MutationSendConfirmEmailArgs = {
  userId?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  isFrom: IsFromEnum;
  lang?: InputMaybe<LangEnum>;
};

export type MutationSendConfirmEmailV2Args = {
  registrationToken: Scalars["UUID"];
  parentEmail?: InputMaybe<Scalars["String"]>;
};

export type MutationSendConfirmEmailV3Args = {
  hash: Scalars["UUID"];
  referrerCode?: InputMaybe<Scalars["String"]>;
};

export type MutationResetPasswordArgs = {
  password: Scalars["String"];
  token: Scalars["String"];
  isFrom: IsFromEnum;
};

export type MutationResetPasswordV3Args = {
  password: Scalars["String"];
  hash?: InputMaybe<Scalars["UUID"]>;
};

export type MutationChangeUserPasswordArgs = {
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type MutationUpdateUserArgs = {
  userData: UpdateUserInput;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationUpdateUserInAdminPanelArgs = {
  id: Scalars["UUID"];
  userData: UpdateUserInAdminPanelInput;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type MutationUpdateCurrentUserArgs = {
  userData: UpdateCurrentUserInput;
};

export type MutationUpdateSchoolParticipantArgs = {
  id: Scalars["UUID"];
  userData: UpdateSchoolParticipantInput;
};

export type MutationDeleteUserFromClientArgs = {
  reasonId: Scalars["UUID"];
  otherReason?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteUserArgs = {
  userId: Scalars["String"];
};

export type MutationAssignUsersToGroupArgs = {
  userIds: Array<InputMaybe<Scalars["String"]>>;
  userGroupId: Scalars["String"];
};

export type MutationRemoveUsersFromGroupArgs = {
  userIds: Array<InputMaybe<Scalars["String"]>>;
  userGroupId: Scalars["String"];
};

export type MutationAssignUsersToProjectArgs = {
  userIds: Array<InputMaybe<Scalars["String"]>>;
  projectId: Scalars["String"];
};

export type MutationRemoveUsersFromProjectArgs = {
  userIds: Array<InputMaybe<Scalars["String"]>>;
  projectId: Scalars["String"];
};

export type MutationAssignProjectsToUserArgs = {
  userId: Scalars["String"];
  projectIds: Array<InputMaybe<Scalars["String"]>>;
};

export type MutationRemoveProjectsFromUserArgs = {
  userId: Scalars["String"];
  projectIds: Array<InputMaybe<Scalars["String"]>>;
};

export type MutationAssignAnimationBundleParticipantsArgs = {
  animationId: Scalars["String"];
  userIds: Array<InputMaybe<Scalars["String"]>>;
  availableForEveryone: Scalars["Boolean"];
};

export type MutationAssignUserTrackerIdArgs = {
  userId?: InputMaybe<Scalars["String"]>;
};

export type MutationInviteUserToTrackerArgs = {
  userId?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateTrackerContractArgs = {
  userId?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteTrackerContractArgs = {
  contractId?: InputMaybe<Scalars["String"]>;
};

export type MutationCleanUserDataForClientRoleArgs = {
  userId?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateUserPasswordArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
  isFrom: IsFromEnum;
};

export type MutationCreateEventUserArgs = {
  userData: CreateEventUserInput;
};

export type MutationRegisterEventUserArgs = {
  userData: RegisterEventUserInput;
  lang?: InputMaybe<LangEnum>;
};

export type MutationBlockUserArgs = {
  userId?: InputMaybe<Scalars["String"]>;
};

export type MutationAddSubjectToTeacherArgs = {
  appMainId: Scalars["String"];
  userId: Scalars["String"];
  schoolId: Scalars["String"];
};

export type MutationRemoveSubjectFromTeacherArgs = {
  appMainId: Scalars["String"];
  userId: Scalars["String"];
  schoolId: Scalars["String"];
};

export type MutationAddParentChildCommunicationArgs = {
  parentId: Scalars["String"];
  pupilId: Scalars["String"];
};

export type MutationRemoveParentChildCommunicationArgs = {
  communicationId: Scalars["String"];
};

export type MutationAddPresentationUserToGSheetArgs = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone: Scalars["String"];
  email: Scalars["String"];
};

export type MutationCreateTeacherAndSchoolArgs = {
  teacherData: CreateTeacherInput;
  schoolData?: InputMaybe<CreateSchoolInput>;
  lang?: InputMaybe<LangEnum>;
};

export type MutationCreateTeacherAndSchoolWithAppleArgs = {
  teacherData: CreateTeacherInput;
  schoolData?: InputMaybe<CreateSchoolInput>;
  lang?: InputMaybe<LangEnum>;
};

export type MutationSetSelectedSubjectsArgs = {
  subjectIds: Array<Scalars["UUID"]>;
  applicationId: Scalars["UUID"];
  schoolId?: InputMaybe<Scalars["UUID"]>;
};

export type MutationFinishRegistrationArgs = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  password: Scalars["String"];
  userId: Scalars["UUID"];
  schoolId?: InputMaybe<Scalars["UUID"]>;
};

export type MutationFinishRegistrationV3Args = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  password?: InputMaybe<Scalars["String"]>;
  hash: Scalars["UUID"];
  marketingEmailsConsent?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationInviteParentsByEmailsArgs = {
  pupilId: Scalars["UUID"];
  parentEmails: Array<InputMaybe<Scalars["String"]>>;
};

export type MutationSendConnectParentPupilEmailsArgs = {
  emails: Array<Scalars["String"]>;
};

export type MutationConnectParentPupilArgs = {
  hash: Scalars["UUID"];
};

export type MutationCreateUserAgreementArgs = {
  name: Scalars["String"];
};

export type MutationUpdateUserAgreementArgs = {
  userAgreementId: Scalars["String"];
  name: Scalars["String"];
};

export type MutationDeleteUserAgreementArgs = {
  userAgreementId: Scalars["String"];
};

export type MutationCreateUserDepartmentArgs = {
  name: Scalars["String"];
};

export type MutationUpdateUserDepartmentArgs = {
  departmentId: Scalars["String"];
  name: Scalars["String"];
};

export type MutationDeleteUserDepartmentArgs = {
  departmentId: Scalars["String"];
};

export type MutationCreateUserEventArgs = {
  data: UserEventInput;
};

export type MutationProcessUserEventArgs = {
  id: Scalars["UUID"];
  action?: InputMaybe<UserEventActionType>;
};

export type MutationDeleteUserEventArgs = {
  id: Scalars["UUID"];
};

export type MutationCreateUserExpHistoryArgs = {
  difference: Scalars["Int"];
  userId?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateUserFolderArgs = {
  name: Scalars["String"];
};

export type MutationUpdateUserFolderArgs = {
  id: Scalars["UUID"];
  name: Scalars["String"];
};

export type MutationDeleteUserFolderArgs = {
  id: Scalars["UUID"];
  contentAction: ContentAction;
};

export type MutationRestoreUserFolderArgs = {
  id: Scalars["UUID"];
};

export type MutationUpdateUserGdprArgs = {
  userId: Scalars["String"];
  agreement?: InputMaybe<Scalars["Boolean"]>;
  privatePolicy?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateUserGroupArgs = {
  userGroupData: CreateUserGroupInput;
};

export type MutationUpdateUserGroupArgs = {
  userGroupData: UpdateUserGroupInput;
};

export type MutationDeleteUserGroupArgs = {
  userGroupId: Scalars["String"];
};

export type MutationExportConnectedUsersToSchoolArgs = {
  schoolId: Scalars["String"];
};

export type MutationCreateUserJobArgs = {
  name: Scalars["String"];
};

export type MutationUpdateUserJobArgs = {
  userJobId: Scalars["String"];
  name: Scalars["String"];
};

export type MutationDeleteUserJobArgs = {
  userJobId: Scalars["String"];
};

export type MutationAddContentToLibraryArgs = {
  contentId: Scalars["UUID"];
  folderId?: InputMaybe<Scalars["UUID"]>;
};

export type MutationRenameContentArgs = {
  id: Scalars["UUID"];
  name: Scalars["String"];
};

export type MutationMoveContentArgs = {
  id: Scalars["UUID"];
  folderId: Scalars["UUID"];
};

export type MutationRemoveContentArgs = {
  id: Scalars["UUID"];
};

export type MutationRestoreContentArgs = {
  id: Scalars["UUID"];
};

export type MutationCopyContentLessonArgs = {
  id: Scalars["UUID"];
  subjectId?: InputMaybe<Scalars["String"]>;
  educationPlanId?: InputMaybe<Scalars["String"]>;
  educationPlanChapterId?: InputMaybe<Scalars["String"]>;
  connectedToLessonId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationAssignContentLessonToPlanArgs = {
  id: Scalars["UUID"];
  subjectId: Scalars["UUID"];
  educationPlanId: Scalars["UUID"];
  educationPlanChapterId?: InputMaybe<Scalars["UUID"]>;
  connectedToLessonId?: InputMaybe<Scalars["UUID"]>;
};

export type MutationAssignContentPlanToSubjectArgs = {
  id: Scalars["UUID"];
  subjectId: Scalars["UUID"];
  gradeId?: InputMaybe<Scalars["UUID"]>;
  classroomId?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
};

export type MutationExportContentArgs = {
  data: ExportContentInput;
};

export type MutationUpdateThirdLevelContentOrderArgs = {
  id: Scalars["UUID"];
  position: Scalars["Int"];
};

export type MutationImportContentFromParentRecommendationArgs = {
  id: Scalars["UUID"];
  educationPlanChapterId: Scalars["UUID"];
  parentId: Scalars["UUID"];
};

export type MutationReplaceCurrentContentWithRecommendedContentArgs = {
  currentContentId: Scalars["UUID"];
  recommendedContentId: Scalars["UUID"];
};

export type MutationUpdateCountOfViewArgs = {
  id: Scalars["UUID"];
};

export type MutationCreateUserPaymentArgs = {
  paymentData: CreateUserPaymentInput;
};

export type MutationUpdateUserPaymentArgs = {
  paymentData: UpdateUserPaymentInput;
};

export type MutationSetUserPaymentDocumentsArgs = {
  paymentId: Scalars["String"];
  deletedDocuments?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  newDocuments?: InputMaybe<Array<InputMaybe<Scalars["Upload"]>>>;
};

export type MutationDeleteUserPaymentArgs = {
  paymentId: Scalars["String"];
};

export type MutationPayUserPaymentArgs = {
  paymentId: Scalars["String"];
};

export type MutationPublishUserPaymentArgs = {
  paymentId: Scalars["String"];
};

export type MutationCreateUserPayrollArgs = {
  payrollData: CreateUserPayrollInput;
};

export type MutationUpdateUserPayrollArgs = {
  payrollData: UpdateUserPayrollInput;
};

export type MutationDeleteUserPayrollArgs = {
  payrollId: Scalars["String"];
};

export type MutationCreateRateHistoryArgs = {
  userId: Scalars["String"];
  rate: Scalars["Int"];
  changeDate: Scalars["Date"];
};

export type MutationUpdateRateHistoryArgs = {
  id: Scalars["String"];
  rate?: InputMaybe<Scalars["Int"]>;
  changeDate?: InputMaybe<Scalars["Date"]>;
};

export type MutationDeleteSRateHistoryArgs = {
  id: Scalars["String"];
};

export type MutationCreateSalaryHistoryArgs = {
  userId: Scalars["String"];
  newSalary: Scalars["Int"];
  changeDate: Scalars["Date"];
  description?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateSalaryHistoryArgs = {
  salaryHistoryId: Scalars["String"];
  newSalary?: InputMaybe<Scalars["Int"]>;
  changeDate?: InputMaybe<Scalars["Date"]>;
  description?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteSalaryHistoryArgs = {
  salaryHistoryId: Scalars["String"];
};

export type MutationUpdateUserStatsArgs = {
  userStatsData: UpdateUserStatsInput;
};

export type MutationCreateVacanciesPlanArgs = {
  planData: CreateVacanciesPlanInput;
};

export type MutationUpdateVacanciesPlanArgs = {
  planData: UpdateVacanciesPlanInput;
};

export type MutationDeleteVacanciesPlanArgs = {
  planId: Scalars["String"];
};

export type MutationCreateVariantArgs = {
  questionId: Scalars["String"];
  content: Scalars["String"];
};

export type MutationUpdateVariantArgs = {
  variantId: Scalars["String"];
  content: Scalars["String"];
};

export type MutationDeleteVariantArgs = {
  id: Scalars["String"];
};

export type MutationCreateVrHeadsetArgs = {
  connectCode: Scalars["String"];
  name: Scalars["String"];
};

export type MutationUpdateVrHeadsetArgs = {
  id: Scalars["UUID"];
  data: UpdateVrHeadsetInput;
};

export type MutationDeleteVrHeadsetArgs = {
  id: Scalars["UUID"];
};

export type MutationRecoverVrHeadsetArgs = {
  id: Scalars["UUID"];
};

export type MutationLinkVrHeadsetsToLessonArgs = {
  ids: Array<InputMaybe<Scalars["UUID"]>>;
  lessonId: Scalars["String"];
};

export type MutationUnlinkVrHeadsetsFromLessonArgs = {
  ids: Array<InputMaybe<Scalars["UUID"]>>;
};

export type MutationCreateWhiteLabelCollectionArgs = {
  name: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<Scalars["Upload"]>;
  schoolLogo: Scalars["Upload"];
  systemLogo: Scalars["Upload"];
  systemLogoLarge: Scalars["Upload"];
  banner: Scalars["Upload"];
  textColor?: InputMaybe<Scalars["String"]>;
  backgroundColor: Scalars["String"];
  slug?: InputMaybe<Scalars["String"]>;
  welcomeHtml?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateWhiteLabelCollectionArgs = {
  id: Scalars["UUID"];
  name?: InputMaybe<Scalars["String"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<Scalars["Upload"]>;
  schoolLogo?: InputMaybe<Scalars["Upload"]>;
  systemLogo?: InputMaybe<Scalars["Upload"]>;
  systemLogoLarge?: InputMaybe<Scalars["Upload"]>;
  banner?: InputMaybe<Scalars["Upload"]>;
  textColor?: InputMaybe<Scalars["String"]>;
  backgroundColor?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  welcomeHtml?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteWhiteLabelCollectionArgs = {
  id: Scalars["UUID"];
};

export type MutationCheckIfWhiteLabelCollectionIsInUseArgs = {
  id: Scalars["UUID"];
};

export type MutationConnectZoomArgs = {
  code: Scalars["String"];
};

export type NewLesson = {
  __typename?: "NewLesson";
  id?: Maybe<Scalars["String"]>;
  equalId?: Maybe<Scalars["String"]>;
  parentId?: Maybe<Scalars["String"]>;
  childId?: Maybe<Scalars["String"]>;
  marketId?: Maybe<Scalars["String"]>;
  isUploadedToMarket?: Maybe<Scalars["Boolean"]>;
  isFromMarket?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<NewLessonType>;
  educationPlanChapterId?: Maybe<Scalars["String"]>;
  educationPlanChapter?: Maybe<EducationPlanChapter>;
  educationPlanId?: Maybe<Scalars["String"]>;
  educationPlan?: Maybe<EducationPlan>;
  schoolId?: Maybe<Scalars["String"]>;
  school?: Maybe<School>;
  subjectId?: Maybe<Scalars["String"]>;
  subject?: Maybe<ApplicationMain>;
  isValid?: Maybe<Scalars["Boolean"]>;
  position?: Maybe<Scalars["Int"]>;
  slides?: Maybe<Array<Maybe<Slide>>>;
  firstSlide?: Maybe<Slide>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["Date"]>;
  isCompleted?: Maybe<Scalars["Boolean"]>;
  isDemo?: Maybe<Scalars["Boolean"]>;
  completedDate?: Maybe<Scalars["Date"]>;
  status?: Maybe<NewLessonStatus>;
  feedbacks?: Maybe<Array<Maybe<NewLessonFeedback>>>;
  faceTrackings?: Maybe<Array<Maybe<NewLessonFaceTracking>>>;
  reflectionPoint?: Maybe<Scalars["Float"]>;
  recommendedLessonsCount?: Maybe<Scalars["Int"]>;
  inAppPurchases?: Maybe<InAppPurchasesEnum>;
  description?: Maybe<Scalars["String"]>;
  mains?: Maybe<Array<Maybe<ApplicationMain>>>;
  schoolGrades?: Maybe<Array<Maybe<SchoolGrade>>>;
  educationAreas?: Maybe<Array<Maybe<EducationArea>>>;
  contentTypeName?: Maybe<ContentTypeEnum>;
  teachingFormat?: Maybe<TeachingFormatEnum>;
  lang?: Maybe<LanguagesEnum>;
  contentAuthor?: Maybe<Scalars["String"]>;
  countOfImport?: Maybe<Scalars["Int"]>;
  libraryContentId?: Maybe<Scalars["UUID"]>;
  libraryContent?: Maybe<UserLibraryContent>;
  classroom?: Maybe<Classroom>;
  slidesCount?: Maybe<Scalars["Int"]>;
  connectedToLessonId?: Maybe<Scalars["String"]>;
  connectedToLesson?: Maybe<NewLesson>;
  connectedLessons?: Maybe<Array<Maybe<NewLesson>>>;
  connectedContent?: Maybe<Array<Maybe<UserLibraryContent>>>;
  pupilLoginCode?: Maybe<Scalars["String"]>;
  previews?: Maybe<Array<Maybe<ContentPreview>>>;
  contentPreviews?: Maybe<Array<Maybe<ContentPreview>>>;
  testTimeSec?: Maybe<Scalars["Int"]>;
  tags?: Maybe<Array<Maybe<ContentTag>>>;
  upcomingEvent?: Maybe<SchoolEvent>;
  eventsCount?: Maybe<Scalars["Int"]>;
  isDisabledByDate?: Maybe<Scalars["Boolean"]>;
  isCompletedByUser?: Maybe<Scalars["Boolean"]>;
  isFinalTestInCourse?: Maybe<Scalars["Boolean"]>;
  isNestedLessonsDisabled?: Maybe<Scalars["Boolean"]>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
  lessonMark?: Maybe<LessonMark>;
  lockedReason?: Maybe<LockedLessonReason>;
};

export type NewLessonFaceTracking = {
  __typename?: "NewLessonFaceTracking";
  id?: Maybe<Scalars["String"]>;
  newLessonId?: Maybe<Scalars["String"]>;
  newLesson?: Maybe<NewLesson>;
  presentPercent?: Maybe<Scalars["Int"]>;
  status?: Maybe<NewLessonFaceTrackingStatusEnum>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export enum NewLessonFaceTrackingStatusEnum {
  Manual = "MANUAL",
  Error = "ERROR",
  Success = "SUCCESS",
}

export type NewLessonFeedback = {
  __typename?: "NewLessonFeedback";
  id?: Maybe<Scalars["String"]>;
  feedbackQuestionId?: Maybe<Scalars["String"]>;
  feedbackQuestion?: Maybe<NewLessonFeedbackQuestion>;
  newLessonId?: Maybe<Scalars["String"]>;
  newLesson?: Maybe<NewLesson>;
  rating?: Maybe<Scalars["Int"]>;
  fullName?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type NewLessonFeedbackQuestion = {
  __typename?: "NewLessonFeedbackQuestion";
  id?: Maybe<Scalars["String"]>;
  content?: Maybe<Scalars["String"]>;
  feedbacks?: Maybe<Array<Maybe<NewLessonFeedback>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type NewLessonInput = {
  equalId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  isCompleted?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<NewLessonStatus>;
  inAppPurchases?: InputMaybe<InAppPurchasesEnum>;
  sendPush?: InputMaybe<Scalars["Boolean"]>;
  educationPlanChapterId?: InputMaybe<Scalars["String"]>;
  educationPlanId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  contentType?: InputMaybe<ContentTypeEnum>;
  contentTypeId?: InputMaybe<Scalars["String"]>;
  testTimeSec?: InputMaybe<Scalars["Int"]>;
};

export type NewLessonPreview = {
  __typename?: "NewLessonPreview";
  id?: Maybe<Scalars["String"]>;
  lessonId?: Maybe<Scalars["String"]>;
  lesson?: Maybe<NewLesson>;
  url?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  type?: Maybe<NewLessonPreviewTypeEnum>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum NewLessonPreviewTypeEnum {
  Image = "IMAGE",
  Video = "VIDEO",
}

export type NewLessonReport = {
  __typename?: "NewLessonReport";
  schoolId?: Maybe<Scalars["String"]>;
  schoolName?: Maybe<Scalars["String"]>;
  subjectName?: Maybe<Scalars["String"]>;
  educationPlanName?: Maybe<Scalars["String"]>;
  educationPlanChapterName?: Maybe<Scalars["String"]>;
  lessonName?: Maybe<Scalars["String"]>;
  teacherEmail?: Maybe<Scalars["String"]>;
  teacherFirstName?: Maybe<Scalars["String"]>;
  teacherLastName?: Maybe<Scalars["String"]>;
  efficiencyPercent?: Maybe<Scalars["Int"]>;
};

export enum NewLessonStatus {
  Locked = "LOCKED",
  Unlocked = "UNLOCKED",
  InProgress = "IN_PROGRESS",
  Completed = "COMPLETED",
}

export enum NewLessonType {
  CompletedLesson = "COMPLETED_LESSON",
  LessonPart = "LESSON_PART",
  InteractiveTextbook = "INTERACTIVE_TEXTBOOK",
}

export type NewLessonsPaginated = {
  __typename?: "NewLessonsPaginated";
  lessons?: Maybe<Array<Maybe<NewLesson>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type News = {
  __typename?: "News";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  isFor?: Maybe<NewsIsForEnum>;
  description?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  youTubeLink?: Maybe<Scalars["String"]>;
  availableFor?: Maybe<AvailableForEnum>;
  participants?: Maybe<Array<Maybe<NewsParticipant>>>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type NewsByParticipant = {
  __typename?: "NewsByParticipant";
  news?: Maybe<News>;
  isViewed?: Maybe<Scalars["Boolean"]>;
};

export enum NewsIsForEnum {
  AdminPanel = "ADMIN_PANEL",
  LkTeacher = "LK_TEACHER",
  AdminPanelAndLkTeacher = "ADMIN_PANEL_AND_LK_TEACHER",
}

export type NewsParticipant = {
  __typename?: "NewsParticipant";
  id?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  news?: Maybe<News>;
  isWatched?: Maybe<Scalars["Boolean"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type NoEpicProblems = {
  __typename?: "NoEpicProblems";
  key?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  epicKey?: Maybe<Scalars["String"]>;
};

export type Notification = {
  __typename?: "Notification";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  availableFor?: Maybe<AvailableForEnum>;
  participants?: Maybe<Array<Maybe<NotificationParticipant>>>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type NotificationByParticipant = {
  __typename?: "NotificationByParticipant";
  notification?: Maybe<Notification>;
  isViewed?: Maybe<Scalars["Boolean"]>;
};

export type NotificationParticipant = {
  __typename?: "NotificationParticipant";
  id?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  notification?: Maybe<Notification>;
  isWatched?: Maybe<Scalars["Boolean"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum Os {
  IOs = "iOS",
  Android = "android",
  WebGl = "WebGl",
}

export type OnboardingRoute = {
  __typename?: "OnboardingRoute";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  route?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type OnboardingTheme = {
  __typename?: "OnboardingTheme";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  video?: Maybe<Scalars["String"]>;
  route?: Maybe<OnboardingRoute>;
  routeId?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  isFirst?: Maybe<Scalars["Boolean"]>;
  isFinished?: Maybe<Scalars["Boolean"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["Date"]>;
  unpublishedAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum OrderTypeEnum {
  Asc = "ASC",
  Desc = "DESC",
}

export type PaginatedCourse = {
  __typename?: "PaginatedCourse";
  courses?: Maybe<Array<Maybe<Course>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type PaginatedCourseCertificate = {
  __typename?: "PaginatedCourseCertificate";
  certificates?: Maybe<Array<Maybe<CourseCertificate>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type PaginatedEducationPlanTheme = {
  __typename?: "PaginatedEducationPlanTheme";
  themes?: Maybe<Array<Maybe<EducationPlanTheme>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type PaginatedGetTotalSlideTestAnswers = {
  __typename?: "PaginatedGetTotalSlideTestAnswers";
  data?: Maybe<Array<Maybe<GetTotalSlideTestAnswers>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type PaginatedSubjectWithNewLessons = {
  __typename?: "PaginatedSubjectWithNewLessons";
  data?: Maybe<Array<Maybe<SubjectWithNewLessons>>>;
  totalSubjects?: Maybe<Scalars["Int"]>;
};

export type Payment = {
  __typename?: "Payment";
  id?: Maybe<Scalars["String"]>;
  paymentDate?: Maybe<Scalars["Date"]>;
  expiredDate?: Maybe<Scalars["Date"]>;
  planId?: Maybe<Scalars["String"]>;
  plan?: Maybe<SubscriptionPlan>;
  contentId?: Maybe<Scalars["String"]>;
  content?: Maybe<UserLibraryContent>;
  contentName?: Maybe<Scalars["String"]>;
  type?: Maybe<PaymentContentType>;
  paymentType?: Maybe<PaymentType>;
  amount?: Maybe<Scalars["Int"]>;
  validity?: Maybe<Scalars["Int"]>;
  pupilsCount?: Maybe<Scalars["Int"]>;
  teachersCount?: Maybe<Scalars["Int"]>;
  price?: Maybe<Scalars["Float"]>;
  total?: Maybe<Scalars["Float"]>;
  isManual?: Maybe<Scalars["Boolean"]>;
  isActivated?: Maybe<Scalars["Boolean"]>;
  isTrial?: Maybe<Scalars["Boolean"]>;
  space?: Maybe<PaymentSpaceType>;
  schoolId?: Maybe<Scalars["String"]>;
  school?: Maybe<School>;
  subscriptionPeriod?: Maybe<PaymentPeriod>;
  paymentFrom?: Maybe<PaymentFromType>;
  payerId?: Maybe<Scalars["UUID"]>;
  payer?: Maybe<SchoolPayer>;
  currency?: Maybe<Scalars["String"]>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedById?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type PaymentCheckoutUrl = {
  __typename?: "PaymentCheckoutUrl";
  redirect_url?: Maybe<Scalars["String"]>;
  error?: Maybe<Scalars["String"]>;
};

export enum PaymentContentType {
  Subscription = "SUBSCRIPTION",
  Content = "CONTENT",
}

export type PaymentData = {
  paymentDate: Scalars["Date"];
  schoolId: Scalars["String"];
  amount: Scalars["Int"];
  price?: InputMaybe<Scalars["Float"]>;
  expiredDate?: InputMaybe<Scalars["Date"]>;
  validity?: InputMaybe<Scalars["Int"]>;
  planId?: InputMaybe<Scalars["String"]>;
  contentId?: InputMaybe<Scalars["String"]>;
  isTrial?: InputMaybe<Scalars["Boolean"]>;
  isActivated?: InputMaybe<Scalars["Boolean"]>;
  paymentFrom?: InputMaybe<PaymentFromType>;
  subscriptionPeriod?: InputMaybe<PaymentPeriod>;
};

export type PaymentDataV3 = {
  subscriptionPlanId: Scalars["UUID"];
  schoolId: Scalars["UUID"];
  total: Scalars["Float"];
  paymentDate: Scalars["Date"];
  paymentTypeId?: InputMaybe<Scalars["UUID"]>;
  payerId?: InputMaybe<Scalars["UUID"]>;
};

export enum PaymentFromType {
  ArTeacher = "AR_TEACHER",
  ArBook = "AR_BOOK",
  AdminPanel = "ADMIN_PANEL",
}

export enum PaymentPeriod {
  Monthly = "MONTHLY",
  Yearly = "YEARLY",
  SixMonths = "SIX_MONTHS",
}

export type PaymentProvider = {
  __typename?: "PaymentProvider";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<PaymentProviderEnum>;
  isActive?: Maybe<Scalars["Boolean"]>;
  logo?: Maybe<Scalars["String"]>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  order?: Maybe<Scalars["Int"]>;
};

export enum PaymentProviderEnum {
  Liqpay = "LIQPAY",
  Stripe = "STRIPE",
}

export type PaymentSession = {
  __typename?: "PaymentSession";
  id?: Maybe<Scalars["String"]>;
  data?: Maybe<Scalars["Metadata"]>;
};

export enum PaymentSessionStatus {
  Pending = "PENDING",
  Failed = "FAILED",
  Succeeded = "SUCCEEDED",
}

export enum PaymentSpaceType {
  Personal = "PERSONAL",
  School = "SCHOOL",
}

export enum PaymentType {
  Fondy = "FONDY",
  Liqpay = "LIQPAY",
  Stripe = "STRIPE",
  Manual = "MANUAL",
  Promotion = "PROMOTION",
  Technical = "TECHNICAL",
  Application = "APPLICATION",
  AppStore = "APP_STORE",
  GoogleStore = "GOOGLE_STORE",
  Promocode = "PROMOCODE",
}

export enum PaymentTypeGroupEnum {
  PaymentSystem = "PAYMENT_SYSTEM",
  Application = "APPLICATION",
  Paid = "PAID",
  Free = "FREE",
  AdminPanel = "ADMIN_PANEL",
}

export type PaymentTypeGroupResponse = {
  __typename?: "PaymentTypeGroupResponse";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<PaymentTypeGroupEnum>;
};

export type PaymentTypeResponse = {
  __typename?: "PaymentTypeResponse";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<PaymentType>;
};

export type PayrollBonus = {
  __typename?: "PayrollBonus";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Int"]>;
  payroll?: Maybe<UserPayroll>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type PayrollKpi = {
  __typename?: "PayrollKpi";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
  weigh?: Maybe<Scalars["Float"]>;
  plan?: Maybe<Scalars["Int"]>;
  fact?: Maybe<Scalars["Int"]>;
  payroll?: Maybe<UserPayroll>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type PayrollSalaryPart = {
  __typename?: "PayrollSalaryPart";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["Date"]>;
  value?: Maybe<Scalars["Int"]>;
  payroll?: Maybe<UserPayroll>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum PeriodAdminReportEnum {
  Day = "DAY",
  Week = "WEEK",
  Month = "MONTH",
}

export type Perks = {
  __typename?: "Perks";
  id?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  planId?: Maybe<Scalars["String"]>;
  plan?: Maybe<SubscriptionPlan>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type Permission = {
  __typename?: "Permission";
  id?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  flagId?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum PersonalForEnum {
  Teacher = "TEACHER",
  Pupil = "PUPIL",
  Parent = "PARENT",
}

export type PlanDataInput = {
  planName?: InputMaybe<SubscriptionPlanNameEnum>;
  subscriptions?: InputMaybe<Scalars["Int"]>;
  subscriptionPeriod?: InputMaybe<PaymentPeriod>;
  price?: InputMaybe<Scalars["Float"]>;
  validity?: InputMaybe<Scalars["Int"]>;
};

export type PlanInput = {
  name?: InputMaybe<SubscriptionPlanNameEnum>;
  description?: InputMaybe<Scalars["String"]>;
  trialPeriod?: InputMaybe<Scalars["Int"]>;
  price?: InputMaybe<Scalars["Float"]>;
  order?: InputMaybe<Scalars["Int"]>;
  pupilsCount?: InputMaybe<Scalars["Int"]>;
  lessonsCount?: InputMaybe<Scalars["Int"]>;
  storageSpace?: InputMaybe<Scalars["Int"]>;
  version?: InputMaybe<SubscriptionPlanVersionEnum>;
  allowedContentStatus?: InputMaybe<SubscriptionPlanStatusEnum>;
};

export type PlanParticipantForSelector = {
  __typename?: "PlanParticipantForSelector";
  id?: Maybe<Scalars["UUID"]>;
  fullName?: Maybe<Scalars["String"]>;
  isAdded?: Maybe<Scalars["Boolean"]>;
  usersCount?: Maybe<Scalars["Int"]>;
  type?: Maybe<PlanParticipantTypeEnum>;
  photo?: Maybe<Scalars["String"]>;
  userRole?: Maybe<Scalars["String"]>;
};

export type PlanParticipantForSelectorFilter = {
  searchKey?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum PlanParticipantTypeEnum {
  Users = "USERS",
  Classrooms = "CLASSROOMS",
  SchoolClassrooms = "SCHOOL_CLASSROOMS",
}

export type Prc = {
  __typename?: "Prc";
  plan?: Maybe<Scalars["Float"]>;
  fact?: Maybe<Scalars["Float"]>;
};

export type Product = {
  __typename?: "Product";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  features?: Maybe<Array<Maybe<Scalars["String"]>>>;
  type?: Maybe<ProductType>;
  flex?: Maybe<Scalars["Int"]>;
  requiredKpi?: Maybe<Scalars["Int"]>;
  requiredLevel?: Maybe<Scalars["Int"]>;
  image?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ProductType = {
  __typename?: "ProductType";
  id?: Maybe<Scalars["Int"]>;
  type?: Maybe<ProductTypeEnum>;
  displayName?: Maybe<Scalars["String"]>;
};

export enum ProductTypeAdminReportEnum {
  Arteacher = "ARTEACHER",
  Arbook = "ARBOOK",
}

export enum ProductTypeEnum {
  Impression = "IMPRESSION",
  Merch = "MERCH",
  Dance = "DANCE",
  Item = "ITEM",
  Clothes = "CLOTHES",
  Other = "OTHER",
}

export type Project = {
  __typename?: "Project";
  id?: Maybe<Scalars["String"]>;
  trackerId?: Maybe<Scalars["Int"]>;
  isLocal?: Maybe<Scalars["Boolean"]>;
  jiraId?: Maybe<Scalars["String"]>;
  jira?: Maybe<Scalars["String"]>;
  jiraKey?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<ProjectStatus>;
  projectType?: Maybe<ProjectType>;
  stages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  startDateEstimate?: Maybe<Scalars["Date"]>;
  finishDateEstimate?: Maybe<Scalars["Date"]>;
  startDate?: Maybe<Scalars["Date"]>;
  finishDate?: Maybe<Scalars["Date"]>;
  budget?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
  client?: Maybe<User>;
  projectManager?: Maybe<User>;
  additionalContacts?: Maybe<Array<Maybe<ProjectAdditionalContact>>>;
  employees?: Maybe<Array<Maybe<User>>>;
  contactPersons?: Maybe<Array<Maybe<User>>>;
  links?: Maybe<Array<Maybe<ProjectLink>>>;
  corrections?: Maybe<Array<Maybe<ProjectCorrection>>>;
  diagram?: Maybe<Array<Maybe<ProjectStep>>>;
  tasks?: Maybe<Array<Maybe<ProjectTask>>>;
  isDeleted?: Maybe<Scalars["Boolean"]>;
  stack?: Maybe<Array<Maybe<Scalars["String"]>>>;
  kanbanId?: Maybe<Scalars["String"]>;
  kanban?: Maybe<ProjectKanban>;
  blockDiagrams?: Maybe<Scalars["String"]>;
  contractTemplates?: Maybe<Scalars["String"]>;
  finalAgreement?: Maybe<Scalars["String"]>;
  brief?: Maybe<Scalars["String"]>;
  testTaskFile?: Maybe<Scalars["String"]>;
  renderingFile?: Maybe<Scalars["String"]>;
  presentations?: Maybe<Array<Maybe<ProjectPresentation>>>;
  designFigma?: Maybe<Scalars["String"]>;
  risks?: Maybe<Scalars["String"]>;
  riskScore?: Maybe<Scalars["Int"]>;
  trackerContracts?: Maybe<Array<Maybe<Contract>>>;
  files?: Maybe<Array<Maybe<ProjectFile>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type ProjectAdditionalContact = {
  __typename?: "ProjectAdditionalContact";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ProjectCorrection = {
  __typename?: "ProjectCorrection";
  id?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  status?: Maybe<ProjectCorrectionStatus>;
  files?: Maybe<Array<Maybe<UploadedFile>>>;
  project?: Maybe<Project>;
  comments?: Maybe<Array<Maybe<CorrectionComment>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ProjectCorrectionStatus = {
  __typename?: "ProjectCorrectionStatus";
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<ProjectCorrectionStatusEnum>;
  displayName?: Maybe<Scalars["String"]>;
};

export enum ProjectCorrectionStatusEnum {
  New = "NEW",
  Review = "REVIEW",
  Discussion = "DISCUSSION",
  Accepted = "ACCEPTED",
  Done = "DONE",
  Rejected = "REJECTED",
}

export type ProjectCustomFieldsMap = {
  __typename?: "ProjectCustomFieldsMap";
  id?: Maybe<Scalars["String"]>;
  startDateEstimate?: Maybe<Scalars["String"]>;
  startDateFact?: Maybe<Scalars["String"]>;
  finishDateEstimate?: Maybe<Scalars["String"]>;
  finishDateFact?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ProjectEpic = {
  __typename?: "ProjectEpic";
  epicKey?: Maybe<Scalars["String"]>;
  epicName?: Maybe<Scalars["String"]>;
};

export type ProjectFile = {
  __typename?: "ProjectFile";
  id?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  isFor?: Maybe<IsForEnum>;
};

export type ProjectJiraTask = {
  __typename?: "ProjectJiraTask";
  status?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  assignee?: Maybe<Scalars["String"]>;
  costPerHour?: Maybe<Scalars["Int"]>;
  costEstimate?: Maybe<Scalars["Int"]>;
  costActual?: Maybe<Scalars["Int"]>;
  timeEstimate?: Maybe<Scalars["Int"]>;
  timeSpent?: Maybe<Scalars["Int"]>;
};

export type ProjectKanban = {
  __typename?: "ProjectKanban";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
  isCompleted?: Maybe<Scalars["Boolean"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type ProjectLight = {
  __typename?: "ProjectLight";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ProjectLink = {
  __typename?: "ProjectLink";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  login?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  isFor?: Maybe<IsForEnum>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ProjectPresentation = {
  __typename?: "ProjectPresentation";
  id?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ProjectStatus = {
  __typename?: "ProjectStatus";
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<ProjectStatusEnum>;
  displayName?: Maybe<Scalars["String"]>;
};

export enum ProjectStatusEnum {
  New = "NEW",
  InProgress = "IN_PROGRESS",
  Done = "DONE",
}

export type ProjectStep = {
  __typename?: "ProjectStep";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  startDateEstimate?: Maybe<Scalars["String"]>;
  startDateFact?: Maybe<Scalars["String"]>;
  finishDateEstimate?: Maybe<Scalars["String"]>;
  finishDateFact?: Maybe<Scalars["String"]>;
  statusId?: Maybe<Scalars["String"]>;
  status?: Maybe<ProjectTaskStatus>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type ProjectTask = {
  __typename?: "ProjectTask";
  id?: Maybe<Scalars["String"]>;
  trackerId?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  comments?: Maybe<Array<Maybe<ProjectTaskComment>>>;
  items?: Maybe<Array<Maybe<ProjectTaskCheckListItem>>>;
  key?: Maybe<Scalars["String"]>;
  epicKey?: Maybe<Scalars["String"]>;
  epicName?: Maybe<Scalars["String"]>;
  assignee?: Maybe<User>;
  assigneeId?: Maybe<Scalars["String"]>;
  assigneeJiraId?: Maybe<Scalars["String"]>;
  assigneeName?: Maybe<Scalars["String"]>;
  responsibleId?: Maybe<Scalars["String"]>;
  responsible?: Maybe<User>;
  projectKey?: Maybe<Scalars["String"]>;
  startDateEstimate?: Maybe<Scalars["String"]>;
  startDateFact?: Maybe<Scalars["String"]>;
  finishDateEstimate?: Maybe<Scalars["String"]>;
  finishDateFact?: Maybe<Scalars["String"]>;
  timeEstimate?: Maybe<Scalars["Int"]>;
  timeSpent?: Maybe<Scalars["Int"]>;
  taskStatusId?: Maybe<Scalars["String"]>;
  taskStatus?: Maybe<ProjectTaskStatus>;
  workflowStepOrder?: Maybe<Scalars["Int"]>;
  kanbanWorkflowStepId?: Maybe<Scalars["String"]>;
  kanbanWorkflowStep?: Maybe<KanbanWorkflowStep>;
  labels?: Maybe<Array<Maybe<ProjectTaskLabel>>>;
  projectId?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
  history?: Maybe<Array<Maybe<ProjectTaskHistory>>>;
  lateReason?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type ProjectTaskCheckListItem = {
  __typename?: "ProjectTaskCheckListItem";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  isCompleted?: Maybe<Scalars["Boolean"]>;
  projectTaskId?: Maybe<Scalars["String"]>;
  projectTask?: Maybe<ProjectTask>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ProjectTaskComment = {
  __typename?: "ProjectTaskComment";
  id?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  projectTaskId?: Maybe<Scalars["String"]>;
  projectTask?: Maybe<ProjectTask>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ProjectTaskHistory = {
  __typename?: "ProjectTaskHistory";
  id?: Maybe<Scalars["String"]>;
  previousStatusId?: Maybe<Scalars["String"]>;
  previousStatus?: Maybe<ProjectTaskStatus>;
  currentStatusId?: Maybe<Scalars["String"]>;
  currentStatus?: Maybe<ProjectTaskStatus>;
  projectTaskId?: Maybe<Scalars["String"]>;
  projectTask?: Maybe<ProjectTask>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type ProjectTaskLabel = {
  __typename?: "ProjectTaskLabel";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  color?: Maybe<Scalars["String"]>;
  isBase?: Maybe<Scalars["Boolean"]>;
  isMutable?: Maybe<Scalars["Boolean"]>;
  projectId?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type ProjectTaskStatus = {
  __typename?: "ProjectTaskStatus";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  isBase?: Maybe<Scalars["Boolean"]>;
  isMutable?: Maybe<Scalars["Boolean"]>;
  projectId?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type ProjectType = {
  __typename?: "ProjectType";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type ProjectsCostReport = {
  __typename?: "ProjectsCostReport";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
};

export type PromoCode = {
  __typename?: "PromoCode";
  id?: Maybe<Scalars["String"]>;
  promoCodeTypeId?: Maybe<Scalars["String"]>;
  content?: Maybe<Array<Maybe<UserLibraryContent>>>;
  plans?: Maybe<Array<Maybe<SubscriptionPlan>>>;
  code?: Maybe<Scalars["String"]>;
  promoter?: Maybe<Scalars["String"]>;
  activatedDate?: Maybe<Scalars["Date"]>;
  activatedBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<User>;
  promoCodeType?: Maybe<PromoCodeType>;
  expiredDate?: Maybe<Scalars["Date"]>;
  subscriptionValidity?: Maybe<Scalars["Int"]>;
};

export type PromoCodeType = {
  __typename?: "PromoCodeType";
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<PromoCodeTypeEnum>;
};

export enum PromoCodeTypeEnum {
  Content = "CONTENT",
  Subscription = "SUBSCRIPTION",
  SubscriptionDiscount = "SUBSCRIPTION_DISCOUNT",
}

export type Promotion = {
  __typename?: "Promotion";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<PromotionTypeEnum>;
  code?: Maybe<Scalars["String"]>;
  discount?: Maybe<Scalars["Float"]>;
  startDate?: Maybe<Scalars["Date"]>;
  expiredDate?: Maybe<Scalars["Date"]>;
  allowedPlans?: Maybe<Array<Maybe<SubscriptionPlan>>>;
};

export enum PromotionTypeEnum {
  AfterRegistration = "AFTER_REGISTRATION",
  ReferrerNoviZnanya = "REFERRER_NOVI_ZNANYA",
  ManualPromotion = "MANUAL_PROMOTION",
  PartnerTeacherTabolina = "PARTNER_TEACHER_TABOLINA",
}

export enum PupilLibraryContentGroup {
  Lessons = "LESSONS",
  InteractiveContent = "INTERACTIVE_CONTENT",
}

export type PupilSlideTestResults = {
  __typename?: "PupilSlideTestResults";
  answers?: Maybe<Array<Maybe<TestSlideAnswers>>>;
  totalMark?: Maybe<Scalars["Int"]>;
  userMark?: Maybe<Scalars["Float"]>;
  percents?: Maybe<Scalars["Int"]>;
  user?: Maybe<User>;
  lessonMark?: Maybe<LessonMark>;
  wasAbsent?: Maybe<Scalars["Boolean"]>;
  totalSteps?: Maybe<Scalars["Int"]>;
  totalTime?: Maybe<Scalars["String"]>;
};

export type Purchase = {
  __typename?: "Purchase";
  id?: Maybe<Scalars["String"]>;
  product?: Maybe<Product>;
  status?: Maybe<PurchaseStatus>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type PurchasePaymentResponse = {
  __typename?: "PurchasePaymentResponse";
  paymentId: Scalars["UUID"];
  total: Scalars["Float"];
};

export type PurchaseStatus = {
  __typename?: "PurchaseStatus";
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<PurchaseStatusEnum>;
  displayName?: Maybe<Scalars["String"]>;
};

export enum PurchaseStatusEnum {
  Pending = "PENDING",
  Confirmed = "CONFIRMED",
  Sent = "SENT",
  Completed = "COMPLETED",
}

export enum PushNotificationTypeEnum {
  Training = "TRAINING",
  MobileNotification = "MOBILE_NOTIFICATION",
  NewLessonFeedback = "NEW_LESSON_FEEDBACK",
  Achieve = "ACHIEVE",
  StartLesson = "START_LESSON",
  AddToGroup = "ADD_TO_GROUP",
  AssignToGroup = "ASSIGN_TO_GROUP",
  AddToSchoolClass = "ADD_TO_SCHOOL_CLASS",
  AddPupilToSchool = "ADD_PUPIL_TO_SCHOOL",
  AddTeacherToSchool = "ADD_TEACHER_TO_SCHOOL",
  RemoveFromGroup = "REMOVE_FROM_GROUP",
  RemoveFromSchoolClass = "REMOVE_FROM_SCHOOL_CLASS",
  RemovePupilFromSchool = "REMOVE_PUPIL_FROM_SCHOOL",
  RemoveTeacherFromSchool = "REMOVE_TEACHER_FROM_SCHOOL",
  ChangeTariff = "CHANGE_TARIFF",
  SubscriptionSuccess = "SUBSCRIPTION_SUCCESS",
  SubscriptionRenewal = "SUBSCRIPTION_RENEWAL",
  SubscriptionEnd_3Days = "SUBSCRIPTION_END_3_DAYS",
  SubscriptionEnd = "SUBSCRIPTION_END",
  Birthday = "BIRTHDAY",
  InactiveUser = "INACTIVE_USER",
  PreAchieve = "PRE_ACHIEVE",
  AddedUserToEducationPlan = "ADDED_USER_TO_EDUCATION_PLAN",
  ActivatedUserInEducationPlan = "ACTIVATED_USER_IN_EDUCATION_PLAN",
  AddedPupilToEducationPlan = "ADDED_PUPIL_TO_EDUCATION_PLAN",
  AddedTeacherToEducationPlan = "ADDED_TEACHER_TO_EDUCATION_PLAN",
  ActivatedTeacherInEducationPlan = "ACTIVATED_TEACHER_IN_EDUCATION_PLAN",
  ActivatedPupilInEducationPlan = "ACTIVATED_PUPIL_IN_EDUCATION_PLAN",
  LessonMark = "LESSON_MARK",
  SpecialLessonMark = "SPECIAL_LESSON_MARK",
  SubscriptionAutoRenewDisabled = "SUBSCRIPTION_AUTO_RENEW_DISABLED",
  SubscriptionAutoRenewEnabled = "SUBSCRIPTION_AUTO_RENEW_ENABLED",
  SubscriptionUpgradedOrDowngraded = "SUBSCRIPTION_UPGRADED_OR_DOWNGRADED",
  MaterialsAdvantage = "MATERIALS_ADVANTAGE",
  BookDemoOffer = "BOOK_DEMO_OFFER",
}

export type QrLoginData = {
  __typename?: "QRLoginData";
  qr?: Maybe<Scalars["String"]>;
  sessionId?: Maybe<Scalars["String"]>;
};

export type QrOptions = {
  dotColor?: InputMaybe<Scalars["String"]>;
  backgroundColor?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
  isPersonalSpace?: InputMaybe<Scalars["Boolean"]>;
};

export type Query = {
  __typename?: "Query";
  getAchieve?: Maybe<Achieve>;
  getAchieves?: Maybe<GetAchieves>;
  getAds?: Maybe<Array<Maybe<Ad>>>;
  getAd?: Maybe<Ad>;
  getAdsByUser?: Maybe<Array<Maybe<Ad>>>;
  getReports?: Maybe<AdminReportPaginated>;
  getCreatedPaymentsAdminReports?: Maybe<GetAdminReportsType>;
  getRegisteredUsersAdminReports?: Maybe<GetAdminReportsType>;
  getRegisteredSchoolsAdminReports?: Maybe<GetAdminReportsType>;
  getAIChatAssistantModels?: Maybe<Array<Maybe<AiChatAssistantModel>>>;
  getAIChatBlocksTemplate?: Maybe<Array<Maybe<AiChatBlockTemplate>>>;
  getAiPromptTemplate?: Maybe<Array<Maybe<AiPromptTemplate>>>;
  getAnalyticsByTraining?: Maybe<GetAnalytics>;
  getAnimationBundles?: Maybe<GetAnimationBundles>;
  getAnimationBundle?: Maybe<AnimationBundle>;
  getAnimationBundleTypes?: Maybe<Array<Maybe<AnimationBundleType>>>;
  getTestsAnswersByPupil?: Maybe<Array<Maybe<Answer>>>;
  getTestsAnswer?: Maybe<Answer>;
  getPupilsAnswersByClassroom?: Maybe<
    Array<Maybe<GetPupilsAnswersByClassroom>>
  >;
  getPupilsAnswersByTestId?: Maybe<Array<Maybe<GetPupilsAnswersByClassroom>>>;
  getApplication?: Maybe<Application>;
  getApplications?: Maybe<GetApplications>;
  getApplicationMain?: Maybe<ApplicationMain>;
  getApplicationsMain?: Maybe<GetApplicationsMain>;
  getApplicationsMainForFilters?: Maybe<GetApplicationsMain>;
  getApplicationMainsByTeacherId?: Maybe<GetApplicationsMain>;
  getSubjectsForSelectOptions?: Maybe<GetApplicationsMain>;
  getSelectedSubjectsByTeacherId?: Maybe<GetApplicationsMain>;
  getSelectedSubjectsAndProfileSubjects?: Maybe<GetApplicationsMain>;
  getSubjectsWithContentPaginated?: Maybe<GetApplicationsMain>;
  getTutorsSubjects?: Maybe<Array<Maybe<TutorsSubjects>>>;
  getSchoolSubjectsForStudent?: Maybe<GetSubjects>;
  getApplicationMainModel?: Maybe<ApplicationMainModel>;
  getApplicationMainModels?: Maybe<GetApplicationMainModels>;
  getApplicationMainModelFiles?: Maybe<Array<Maybe<ApplicationMainModelFile>>>;
  getApplicationMainModelFilesByApplicationId?: Maybe<
    Array<Maybe<ApplicationMainModelFile>>
  >;
  getApplicationMainTheme?: Maybe<ApplicationMainTheme>;
  getApplicationMainThemes?: Maybe<Array<Maybe<ApplicationMainTheme>>>;
  getApplicationMainThemesByMainId?: Maybe<Array<Maybe<ApplicationMainTheme>>>;
  getApplicationVersion?: Maybe<ApplicationVersion>;
  getApplicationVersions?: Maybe<Array<Maybe<ApplicationVersion>>>;
  getCurrentApplicationVersion?: Maybe<Scalars["String"]>;
  getAppMainThemeBackground?: Maybe<AppMainThemeBackground>;
  getAppMainThemeBackgrounds?: Maybe<Array<Maybe<AppMainThemeBackground>>>;
  getAwards?: Maybe<Array<Maybe<Award>>>;
  getAward?: Maybe<Award>;
  getCities?: Maybe<Array<Maybe<City>>>;
  getClassroom?: Maybe<Classroom>;
  getClassrooms?: Maybe<GetClassrooms>;
  getClassroomsByTeacher?: Maybe<GetClassrooms>;
  getClassroomsByPupil?: Maybe<GetClassrooms>;
  getClientSites?: Maybe<GetClientSites>;
  getClientSiteModel?: Maybe<ClientSiteModel>;
  getModelsByClientSiteId?: Maybe<GetClientSiteModels>;
  getClientSiteModelByItemId?: Maybe<ClientSiteModel>;
  getClientSiteModelItem?: Maybe<ClientSiteModelItem>;
  getClientSiteModelItemsByModelId?: Maybe<Array<Maybe<ClientSiteModelItem>>>;
  getContentCategories?: Maybe<Array<Maybe<ContentCategory>>>;
  getContentCategory?: Maybe<ContentCategory>;
  getContentTypeByCategory?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  getCategoryByContentType?: Maybe<Array<Maybe<ContentCategory>>>;
  getContentPreviewById?: Maybe<ContentPreview>;
  getContentPreviews?: Maybe<Array<Maybe<ContentPreview>>>;
  getContentTags?: Maybe<Array<Maybe<ContentTag>>>;
  getContentTagByCategory?: Maybe<Array<Maybe<ContentTag>>>;
  getContentTagByContentType?: Maybe<Array<Maybe<ContentTag>>>;
  getCategoryByContentTag?: Maybe<Array<Maybe<ContentCategory>>>;
  getContentTagsForArbook?: Maybe<Array<Maybe<ContentTag>>>;
  getCorrectionComments?: Maybe<Array<Maybe<CorrectionComment>>>;
  getCorrectionComment?: Maybe<CorrectionComment>;
  getCommentsByCorrectionId?: Maybe<Array<Maybe<CorrectionComment>>>;
  getCourseCertificateTemplates?: Maybe<
    Array<Maybe<CourseCertificateTemplate>>
  >;
  getCoursesRequests?: Maybe<Array<Maybe<CourseRequest>>>;
  getUserCoursesRequests?: Maybe<Array<Maybe<CourseRequest>>>;
  getCourses?: Maybe<PaginatedCourse>;
  getCourse?: Maybe<Course>;
  getCourseInfo?: Maybe<Course>;
  getCoursesForAdminPanel?: Maybe<PaginatedCourse>;
  getCertificateByCourseId?: Maybe<CourseCertificate>;
  getUserCoursesCertificates?: Maybe<PaginatedCourseCertificate>;
  getCourseTypes?: Maybe<Array<Maybe<CourseType>>>;
  getCourseLessons?: Maybe<Array<Maybe<NewLesson>>>;
  getCourseLesson?: Maybe<NewLesson>;
  getEducationArea?: Maybe<EducationArea>;
  getEducationAreas?: Maybe<Array<Maybe<EducationArea>>>;
  getEducationPlan?: Maybe<EducationPlan>;
  getEducationPlans?: Maybe<GetEducationPlans>;
  getPupilEducationPlans?: Maybe<GetEducationPlans>;
  getAvailableAndPersonalPlanCount?: Maybe<AvailableAndPersonalPlanCount>;
  getEducationPlanResults?: Maybe<GetEducationPlanResults>;
  checkIfPlanHasContentUploadedToArBookPlus?: Maybe<Scalars["Boolean"]>;
  getEducationPlanChapter?: Maybe<EducationPlanChapter>;
  getEducationPlanChapters?: Maybe<GetEducationPlanChapters>;
  checkIfChapterHasContentUploadedToArBookPlus?: Maybe<Scalars["Boolean"]>;
  getEducationPlanLayouts?: Maybe<Array<Maybe<EducationPlanLayout>>>;
  getEducationPlanParticipants?: Maybe<GetEducationPlanParticipants>;
  getPlanParticipantsForSelector?: Maybe<
    Array<Maybe<PlanParticipantForSelector>>
  >;
  getSchoolClassroomsAndClassroomsForFilter?: Maybe<
    Array<Maybe<SchoolClassroomsAndClassroomsForFilter>>
  >;
  getActivePupils?: Maybe<Array<Maybe<User>>>;
  checkIfParticipantsExistInPlan?: Maybe<Scalars["Boolean"]>;
  getEducationPlanThemes?: Maybe<PaginatedEducationPlanTheme>;
  getEmployeesDistributionsByName?: Maybe<GetEmployeesDistributionsByName>;
  getEmployeesDistribution?: Maybe<EmployeesDistribution>;
  getEmployeesDistributionLight?: Maybe<
    Array<Maybe<EmployeesDistributionLight>>
  >;
  getESputnikSubscriptionCategories?: Maybe<
    Array<Maybe<ESputnikSubscriptionCategory>>
  >;
  getEvent?: Maybe<Event>;
  getEvents?: Maybe<GetEvents>;
  getEventDate?: Maybe<EventDate>;
  getEventDates?: Maybe<Array<Maybe<EventDate>>>;
  getExercise?: Maybe<Exercise>;
  getExercises?: Maybe<GetExercises>;
  getFaq?: Maybe<Faq>;
  getFaqs?: Maybe<GetFaqs>;
  getFeatureFlags?: Maybe<Array<Maybe<FeatureFlag>>>;
  getUserFeatureFlagsByUserId?: Maybe<Array<Maybe<FeatureFlag>>>;
  getUserFeatureFlagsBySchoolId?: Maybe<Array<Maybe<FeatureFlag>>>;
  getFeedbacks?: Maybe<Array<Maybe<Feedback>>>;
  getFeedbacksLight?: Maybe<Array<Maybe<FeedbacksLight>>>;
  getFeedback?: Maybe<Feedback>;
  getFeedbacksForCurrentUser?: Maybe<Array<Maybe<Feedback>>>;
  getFeedbackComments?: Maybe<Array<Maybe<FeedbackComment>>>;
  getFeedbackComment?: Maybe<FeedbackComment>;
  getCommentsByFeedbackId?: Maybe<Array<Maybe<FeedbackComment>>>;
  getForums?: Maybe<GetForums>;
  getForum?: Maybe<Forum>;
  getForumsForCurrentUser?: Maybe<GetForums>;
  getGameModel?: Maybe<GameModel>;
  getGameModels?: Maybe<Array<Maybe<GameModel>>>;
  getGameModelArtifact?: Maybe<GameModelArtifact>;
  getGameModelArtifacts?: Maybe<GetGameModelArtifacts>;
  getGameModelArtifactAnimationTypes?: Maybe<
    Array<Maybe<GameModelArtifactAnimationType>>
  >;
  getGameModelArtifactClothTypes?: Maybe<
    Array<Maybe<GameModelArtifactClothType>>
  >;
  getGameModelState?: Maybe<GameModelState>;
  getGameModelStates?: Maybe<Array<Maybe<GameModelState>>>;
  getGameModelStateStages?: Maybe<Array<Maybe<GameModelStateStage>>>;
  isClassroomEnabled?: Maybe<Scalars["Boolean"]>;
  getClassroomAuthUrl?: Maybe<Scalars["String"]>;
  classRoomCourses?: Maybe<Array<Maybe<ClassroomCourse>>>;
  classRoomTopics?: Maybe<Array<Maybe<ClassroomTopic>>>;
  classRoomStudents?: Maybe<Array<Maybe<ClassroomStudent>>>;
  classRoomTeachers?: Maybe<Array<Maybe<ClassroomTeacher>>>;
  classroomCourseWorks?: Maybe<Array<Maybe<ClassroomCourseWork>>>;
  getKnowledgeBaseBlocksByThemeId?: Maybe<Array<Maybe<KnowledgeBaseBlock>>>;
  getKnowledgeBaseBlocksTestByThemeId?: Maybe<Array<Maybe<KnowledgeBaseBlock>>>;
  getKnowledgeBaseBlocksTestHistory?: Maybe<
    Array<Maybe<GetKnowledgeBaseTestHistory>>
  >;
  getKnowledgeBaseBlocksTestByManager?: Maybe<Array<Maybe<KnowledgeBaseBlock>>>;
  getKnowledgeBaseBlocksTestAnswersByTestId?: Maybe<
    Array<Maybe<KnowledgeBaseBlockTestAnswer>>
  >;
  getBlockTestHistoryByBlockId?: Maybe<
    Array<Maybe<KnowledgeBaseBlockTestHistory>>
  >;
  getKnowledgeBaseTestAttempt?: Maybe<KnowledgeBaseBlockTestAttempt>;
  getKnowledgeBaseTestAttempts?: Maybe<
    Array<Maybe<KnowledgeBaseBlockTestAttempt>>
  >;
  getBaseKnowledgeBaseCategories?: Maybe<GetKnowledgeBaseCategories>;
  getKnowledgeBaseCategoriesByManager?: Maybe<GetKnowledgeBaseCategories>;
  getKnowledgeBaseCategoriesByParticipant?: Maybe<GetKnowledgeBaseCategoriesByParticipant>;
  doesHaveEveryThemeTestAttemptByCategoryId?: Maybe<Scalars["Boolean"]>;
  getKnowledgeBaseTheme?: Maybe<KnowledgeBaseTheme>;
  getKnowledgeBaseThemes?: Maybe<Array<Maybe<KnowledgeBaseTheme>>>;
  getKnowledgeBaseThemesByCategoryId?: Maybe<Array<Maybe<KnowledgeBaseTheme>>>;
  getKnowledgeBaseThemesByManager?: Maybe<Array<Maybe<KnowledgeBaseTheme>>>;
  getPercentageCompleteThemeTests?: Maybe<Scalars["Int"]>;
  getPercentageCompleteCategory?: Maybe<Scalars["Int"]>;
  getLesson?: Maybe<Lesson>;
  getLessonsByMasterId?: Maybe<GetLessons>;
  getLessonBlock?: Maybe<LessonBlock>;
  getLessonBlocksByPageId?: Maybe<Array<Maybe<LessonBlock>>>;
  getMarksForLesson?: Maybe<Array<Maybe<LessonMark>>>;
  getLessonPage?: Maybe<LessonPage>;
  getLessonPages?: Maybe<GetLessonPages>;
  getLessonQuestion?: Maybe<LessonQuestion>;
  getLessonQuestionsByPageId?: Maybe<Array<Maybe<LessonQuestion>>>;
  getMarketCollections?: Maybe<GetMarketCollectionsResponse>;
  getMarketCollection?: Maybe<GetMarketCollectionResponse>;
  getMarketingModalInfo?: Maybe<MarketingModalInfo>;
  getMarketingModals?: Maybe<Array<Maybe<MarketingModalInfo>>>;
  getMarkLevels?: Maybe<Array<Maybe<MarkLevel>>>;
  getMobileNotification?: Maybe<MobileNotification>;
  getMobileNotifications?: Maybe<GetMobileNotifications>;
  getMobileNotificationsByApp?: Maybe<GetMobileNotifications>;
  getMobileNotificationsByManager?: Maybe<GetMobileNotifications>;
  getMobileNotificationsByParticipant?: Maybe<GetMobileNotificationsByParticipant>;
  getIsUnreadNotifications?: Maybe<Scalars["Boolean"]>;
  getParticipantsByMobileNotification?: Maybe<
    Array<Maybe<MobileNotificationParticipant>>
  >;
  getMonthHoursPlan?: Maybe<Array<Maybe<MonthHourPlan>>>;
  getMonthHourPlan?: Maybe<MonthHourPlan>;
  getNewLesson?: Maybe<NewLesson>;
  getNewLessonMaterial?: Maybe<NewLesson>;
  getNewLessons?: Maybe<NewLessonsPaginated>;
  getNewLessonsByMainId?: Maybe<Array<Maybe<NewLesson>>>;
  getNewLessonsByEducationPlanId?: Maybe<NewLessonsPaginated>;
  getNewLessonsWithTemplateType?: Maybe<NewLessonsPaginated>;
  getNewLessonsArbookPlus?: Maybe<NewLessonsPaginated>;
  getNewLessonContentTypes?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  getPupilGuestLesson?: Maybe<NewLesson>;
  getLessonByCode?: Maybe<NewLesson>;
  getNewLessonResultsOnPresentation?: Maybe<
    Array<Maybe<PupilSlideTestResults>>
  >;
  getNewLessonGuestsResultsOnPresentation?: Maybe<
    Array<Maybe<PupilSlideTestResults>>
  >;
  getTeachersSubjectsNewLessonTemplates?: Maybe<PaginatedSubjectWithNewLessons>;
  getLessonTestsResults?: Maybe<Array<Maybe<LessonResult>>>;
  getNewLessonForParticipant?: Maybe<NewLesson>;
  getRecommendedLessons?: Maybe<Array<Maybe<NewLesson>>>;
  getLessonByPupilLoginCode?: Maybe<NewLesson>;
  checkIfLessonHasContentUploadedToArBookPlus?: Maybe<Scalars["Boolean"]>;
  checkIfCanStartQuiz?: Maybe<CheckIfCanStartQuizResponse>;
  getNewLessonFaceTracking?: Maybe<NewLessonFaceTracking>;
  getNewLessonFeedbacks?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  getNewLessonFeedbackQuestions?: Maybe<
    Array<Maybe<NewLessonFeedbackQuestion>>
  >;
  getNewLessonPreviews?: Maybe<Array<Maybe<NewLessonPreview>>>;
  getNew?: Maybe<News>;
  getNews?: Maybe<GetNews>;
  getNewsByManager?: Maybe<GetNews>;
  getNewsByParticipant?: Maybe<GetNewsByParticipant>;
  getNotifications?: Maybe<GetNotifications>;
  getNotificationsByManager?: Maybe<GetNotifications>;
  getNotificationsByParticipant?: Maybe<GetNotificationsByParticipant>;
  getIsNewNotification?: Maybe<Scalars["Boolean"]>;
  getOnboardingTheme?: Maybe<OnboardingTheme>;
  getOnboardingThemes?: Maybe<Array<Maybe<OnboardingTheme>>>;
  getOnboardingThemesEdit?: Maybe<Array<Maybe<OnboardingTheme>>>;
  getOnboardingRoutesEdit?: Maybe<Array<Maybe<OnboardingRoute>>>;
  getPaymentHistory?: Maybe<Payment>;
  getPaymentsHistoryBySchool?: Maybe<GetPaymentsHistory>;
  getPurchasePaymentsBySchool?: Maybe<GetPaymentsHistory>;
  getSchoolPaymentHistory?: Maybe<GetPaymentsHistory>;
  getPaymentById?: Maybe<Payment>;
  getPaymentTypes?: Maybe<Array<Maybe<PaymentTypeResponse>>>;
  getPaymentTypeGroups?: Maybe<Array<Maybe<PaymentTypeGroupResponse>>>;
  getPaymentProviders?: Maybe<Array<Maybe<PaymentProvider>>>;
  getSummary?: Maybe<SummaryResponse>;
  getAdminPanelSubscriptionsPaymentType?: Maybe<
    Array<Maybe<PaymentTypeResponse>>
  >;
  getPayrollBonuses?: Maybe<Array<Maybe<PayrollBonus>>>;
  getPayrollBonus?: Maybe<PayrollBonus>;
  getPayrollKpis?: Maybe<Array<Maybe<PayrollKpi>>>;
  getPayrollSalaryPart?: Maybe<Array<Maybe<PayrollSalaryPart>>>;
  getPermissions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  getProducts?: Maybe<Array<Maybe<Product>>>;
  getProduct?: Maybe<Product>;
  getProductsByType?: Maybe<Array<Maybe<Product>>>;
  getProductTypes?: Maybe<Array<Maybe<ProductType>>>;
  getProjects?: Maybe<GetProjects>;
  getProjectsLight?: Maybe<Array<Maybe<ProjectLight>>>;
  getPMProjectsLight?: Maybe<Array<Maybe<ProjectLight>>>;
  getProject?: Maybe<Project>;
  getProjectsByUser?: Maybe<Array<Maybe<Project>>>;
  getProjectsByClient?: Maybe<Array<Maybe<Project>>>;
  getEarliestProjectDate?: Maybe<Scalars["Date"]>;
  getProjectFilesByProject?: Maybe<Array<Maybe<ProjectLink>>>;
  getProjectAdditionalContacts?: Maybe<Array<Maybe<ProjectAdditionalContact>>>;
  getProjectAdditionalContact?: Maybe<ProjectAdditionalContact>;
  getProjectAdditionalContactsByProject?: Maybe<
    Array<Maybe<ProjectAdditionalContact>>
  >;
  getProjectCorrections?: Maybe<Array<Maybe<ProjectCorrection>>>;
  getProjectCorrectionsLight?: Maybe<Array<Maybe<CorrectionsLight>>>;
  getProjectCorrection?: Maybe<ProjectCorrection>;
  getProjectCorrectionStatuses?: Maybe<Array<Maybe<ProjectCorrectionStatus>>>;
  getProjectCustomFieldsMap?: Maybe<ProjectCustomFieldsMap>;
  getProjectCustomFieldsMapByProject?: Maybe<ProjectCustomFieldsMap>;
  getProjectKanban?: Maybe<ProjectKanban>;
  getKanbanWorkflowStep?: Maybe<KanbanWorkflowStep>;
  getBaseKanbanWorkflowSteps?: Maybe<Array<Maybe<KanbanWorkflowStep>>>;
  getKanbanWorkflowStepsByProject?: Maybe<Array<Maybe<KanbanWorkflowStep>>>;
  getProjectLinks?: Maybe<Array<Maybe<ProjectLink>>>;
  getProjectLink?: Maybe<ProjectLink>;
  getProjectLinksByProject?: Maybe<Array<Maybe<ProjectLink>>>;
  getProjectStatuses?: Maybe<Array<Maybe<ProjectStatus>>>;
  getProjectStep?: Maybe<ProjectStep>;
  getProjectStepsByProject?: Maybe<Array<Maybe<ProjectStep>>>;
  getProjectEpics?: Maybe<Array<Maybe<ProjectEpic>>>;
  getProjectStepsFromJira?: Maybe<Array<Maybe<Issue>>>;
  getEpicsProgressReport?: Maybe<Array<Maybe<GetEpicsProgressReport>>>;
  getChartData?: Maybe<ChartData>;
  getChartData2?: Maybe<Array<Maybe<ChartData2>>>;
  getProjectStepsInProgress?: Maybe<Array<Maybe<ProjectStep>>>;
  getProjectTask?: Maybe<ProjectTask>;
  getProjectTasks?: Maybe<Array<Maybe<ProjectTask>>>;
  getProjectTasksByProject?: Maybe<Array<Maybe<ProjectTask>>>;
  getProjectTasksByEpic?: Maybe<Array<Maybe<ProjectTask>>>;
  getProjectTasksByWorkflowStep?: Maybe<GetProjectTasksByWorkflowStep>;
  getTasksWithProblems?: Maybe<TasksWithProblems>;
  getOldestTaskDate?: Maybe<Scalars["String"]>;
  getLateTasks?: Maybe<Array<Maybe<LateTasks>>>;
  getBugsAdditionalTasksReport?: Maybe<Array<Maybe<BugsAdditionalTasksReport>>>;
  getProjectTaskHistory?: Maybe<Array<Maybe<ProjectTaskHistory>>>;
  getProjectTaskLabel?: Maybe<ProjectTaskLabel>;
  getBaseProjectTaskLabels?: Maybe<Array<Maybe<ProjectTaskLabel>>>;
  getProjectTaskLabelsByProject?: Maybe<Array<Maybe<ProjectTaskLabel>>>;
  getAvailableProjectTaskLabels?: Maybe<Array<Maybe<ProjectTaskLabel>>>;
  getProjectTaskStatus?: Maybe<ProjectTaskStatus>;
  getBaseProjectTaskStatuses?: Maybe<Array<Maybe<ProjectTaskStatus>>>;
  getProjectTaskStatusesByProject?: Maybe<Array<Maybe<ProjectTaskStatus>>>;
  getProjectTypes?: Maybe<Array<Maybe<ProjectType>>>;
  getProjectType?: Maybe<ProjectType>;
  checkUserPromotionStatus?: Maybe<UserPromotionStatus>;
  checkUserPromotionStatusOld?: Maybe<UserPromotionStatus>;
  getSubscriptionPlansForManualPromotion?: Maybe<
    Array<Maybe<SubscriptionPlan>>
  >;
  getManualPromotions?: Maybe<GetManualPromotionsResponse>;
  getPurchase?: Maybe<Purchase>;
  getPurchases?: Maybe<GetPurchases>;
  getPurchasesByUser?: Maybe<GetPurchases>;
  getPurchasesInCart?: Maybe<GetPurchases>;
  getPurchaseHistory?: Maybe<GetPurchases>;
  getPurchaseStatuses?: Maybe<Array<Maybe<PurchaseStatus>>>;
  getQuestionsByTestId?: Maybe<GetQuestions>;
  getQuizThemes?: Maybe<QuizThemesPaginated>;
  getQuoteCollections?: Maybe<Array<Maybe<QuoteCollection>>>;
  getTimeReport?: Maybe<Array<Maybe<TimeReport>>>;
  getTimeReportByEpic?: Maybe<GetTimeReportByEpic>;
  getCostReport?: Maybe<Array<Maybe<CostReport>>>;
  getCostReportByEpic?: Maybe<GetCostReportByEpic>;
  getDateReport?: Maybe<Array<Maybe<DateReport>>>;
  getDateReportByEpic?: Maybe<GetDateReportByEpic>;
  getCompletionPctReport?: Maybe<Array<Maybe<CompletionPctReport>>>;
  getCompletionPctReportByEpic?: Maybe<GetCompletionPctReportByEpic>;
  getAddedTasksReport?: Maybe<Array<Maybe<AddedTasksReport>>>;
  getAddedTasksEpics?: Maybe<Array<Maybe<AddedTasksEpic>>>;
  getAddedTasksReportByEpic?: Maybe<Array<Maybe<AddedTasksByEpic>>>;
  getMasteringMoneyReport?: Maybe<Array<Maybe<MasteringMoneyReport>>>;
  getEmployeeWorkloadReportByJobs?: Maybe<
    Array<Maybe<EmployeeWorkloadReportByJobs>>
  >;
  getEmployeeWorkloadReportByUser?: Maybe<
    Array<Maybe<EmployeeWorkloadReportByUser>>
  >;
  getEmployeeWorkloadReport?: Maybe<
    Array<Maybe<EmployeeWorkloadReportByProject>>
  >;
  getEmployeeWorkloadDayReport?: Maybe<Array<Maybe<EmployeeWorkloadDayReport>>>;
  getDashboardMasteringMoneyReport?: Maybe<
    Array<Maybe<DashboardMasteringMoneyReport>>
  >;
  getDashboardInProgressTasksReport?: Maybe<
    Array<Maybe<DashboardInProgressTasksReport>>
  >;
  getDashboardCompletionPctReport?: Maybe<
    Array<Maybe<DashboardCompletionPctReport>>
  >;
  getDashboardAddedTasksReport?: Maybe<Array<Maybe<DashboardAddedTasksReport>>>;
  getDashboardAddedTasks?: Maybe<Array<Maybe<TaskName>>>;
  getDashboardUserWorkloadReport?: Maybe<
    Array<Maybe<DashboardUserWorkloadReport>>
  >;
  getDashboardUserWorkloadProjects?: Maybe<
    Array<Maybe<DashboardUserWorkloadProjectsReport>>
  >;
  getDashboardOverdueTasksReport?: Maybe<
    Array<Maybe<DashboardOverdueTasksReport>>
  >;
  getDashboardOverdueTasks?: Maybe<Array<Maybe<TaskName>>>;
  getProjectsCostReport?: Maybe<Array<Maybe<ProjectsCostReport>>>;
  getReward?: Maybe<Reward>;
  getRewards?: Maybe<Array<Maybe<Reward>>>;
  getRewardGroups?: Maybe<Array<Maybe<RewardGroup>>>;
  getRobotsHistory?: Maybe<Array<Maybe<RobotsHistory>>>;
  getSchools?: Maybe<GetSchools>;
  getSchoolsForAdminPanel?: Maybe<GetSchools>;
  getSchoolsByCity?: Maybe<Array<Maybe<School>>>;
  getSchool?: Maybe<School>;
  getPupilsBySchool?: Maybe<GetUsers>;
  getPupils?: Maybe<GetUsers>;
  getTeachersBySchool?: Maybe<GetUsers>;
  getTeachers?: Maybe<GetUsers>;
  getGiveawaySchools?: Maybe<GetSchools>;
  validateUserInvitationsList?: Maybe<ValidationInvitationListResponse>;
  getSchoolUsersCSV?: Maybe<Scalars["String"]>;
  getSchoolParticipants?: Maybe<SchoolParticipantsPaginated>;
  getMySchools?: Maybe<Array<Maybe<School>>>;
  getSchoolNamesBySearch?: Maybe<Array<Maybe<Scalars["String"]>>>;
  getCreatedLessonsBySchoolTeachers?: Maybe<ChartDataWithTotal>;
  getSchoolConnectionStatistic?: Maybe<ChartDataWithTotal>;
  getSchoolCities?: Maybe<Array<Maybe<SchoolCity>>>;
  getSchoolClassrooms?: Maybe<GetSchoolClassrooms>;
  getSchoolClassroom?: Maybe<SchoolClassroom>;
  getPupilsBySchoolClassroom?: Maybe<GetUsers>;
  getSchoolClassroomByYear?: Maybe<SchoolClassroom>;
  getPupilClassroom?: Maybe<SchoolClassroom>;
  getSchoolCountries?: Maybe<Array<Maybe<SchoolCountry>>>;
  getSchoolDistrictsByRegion?: Maybe<Array<Maybe<SchoolDistrict>>>;
  getSchoolEvents?: Maybe<Array<Maybe<SchoolEvent>>>;
  getLessonEvents?: Maybe<Array<Maybe<SchoolEvent>>>;
  getSchoolGrades?: Maybe<Array<Maybe<SchoolGrade>>>;
  getSchoolGradesPaginated?: Maybe<SchoolGradesPaginated>;
  getSchoolPayers?: Maybe<Array<Maybe<SchoolPayer>>>;
  getSchoolPayer?: Maybe<SchoolPayer>;
  getSchoolRegions?: Maybe<Array<Maybe<SchoolRegion>>>;
  getTeacherSchools?: Maybe<Array<Maybe<SchoolSettings>>>;
  getSessionHistories?: Maybe<GetSessionHistories>;
  getSessionHistory?: Maybe<SessionHistory>;
  getSlide?: Maybe<Slide>;
  getSlides?: Maybe<SlidesPaginated>;
  getTheMostEffectiveSlides?: Maybe<Array<Maybe<Slide>>>;
  getSlidesBackgrounds?: Maybe<SlideBackgroundsPaginated>;
  getSlidesStickers?: Maybe<SlideStickersPaginated>;
  getSlidesStickersCollections?: Maybe<Array<Maybe<StickerCollection>>>;
  getSlidesStickersCollection?: Maybe<StickerCollectionPaginated>;
  getSuggestedSlideTests?: Maybe<Array<Maybe<SuggestedSlideTests>>>;
  getSlideBackgroundCollections?: Maybe<SlideBackgroundCollectionPaginated>;
  getSlideBackgrounds?: Maybe<Array<Maybe<SlideBackground>>>;
  getSlideReflections?: Maybe<Array<Maybe<SlideReflection>>>;
  getNewLessonEfficiencyPercent?: Maybe<Scalars["Int"]>;
  getNewLessonInvolvementPercent?: Maybe<Scalars["Int"]>;
  getNewLessonAssimilationPercent?: Maybe<Scalars["Int"]>;
  getNewLessonFeedbackPercent?: Maybe<Scalars["Int"]>;
  getSlideReflectionPercent?: Maybe<Scalars["Int"]>;
  getNewLessonReflectionReports?: Maybe<Array<Maybe<NewLessonReport>>>;
  getLessonSlidesAnswers?: Maybe<Array<Maybe<GetTotalSlideTestAnswers>>>;
  getLessonSlidesGuestsAnswers?: Maybe<Array<Maybe<GetGuestsSlideTestAnswers>>>;
  getPupilSlideResultOnPresentation?: Maybe<GetPupilTestResult>;
  getGuestSlideResultOnPresentation?: Maybe<GetGuestTestResult>;
  getPupilsSlideResultOnPresentation?: Maybe<GetPupilsTestResult>;
  getGuestsSlideResultOnPresentation?: Maybe<GetGuestsTestResult>;
  getCourseLessonResults?: Maybe<PaginatedGetTotalSlideTestAnswers>;
  getSlideTestChoice?: Maybe<SlideTestChoice>;
  getSlideTestChoices?: Maybe<Array<Maybe<SlideTestChoice>>>;
  getSlideTestChoiceConformity?: Maybe<SlideTestChoiceConformity>;
  getSmartNotification?: Maybe<SmartNotification>;
  getSmartNotifications?: Maybe<GetSmartNotifications>;
  getSmartNotificationsByManager?: Maybe<GetSmartNotifications>;
  getSmartNotificationsByParticipant?: Maybe<GetSmartNotificationsByParticipant>;
  getSmartNotificationAnswer?: Maybe<SmartNotificationAnswer>;
  getAnswersBySmartNotificationId?: Maybe<
    Array<Maybe<SmartNotificationAnswer>>
  >;
  getAnswersBySmartNotificationPollListId?: Maybe<
    Array<Maybe<SmartNotificationAnswer>>
  >;
  getAnswersWithPercentsBySmartNotificationId?: Maybe<GetSmartNotificationAnswers>;
  getRatingAnswersCountBySmartNotificationId?: Maybe<Scalars["Float"]>;
  getUsersBySmartNotificationAnswer?: Maybe<GetUsers>;
  getSmartNotificationPollList?: Maybe<SmartNotificationPollList>;
  getPollListsBySmartNotificationId?: Maybe<
    Array<Maybe<SmartNotificationPollList>>
  >;
  getSpecialLessonMarks?: Maybe<Array<Maybe<SpecialLessonMark>>>;
  getSubscriptionPlan?: Maybe<SubscriptionPlan>;
  getSubscriptionPlans?: Maybe<Array<Maybe<SubscriptionPlan>>>;
  getSubscriptionPlansV2?: Maybe<Array<Maybe<SubscriptionPlan>>>;
  getSubscriptionPlansV3ForAdminPanel?: Maybe<Array<Maybe<SubscriptionPlan>>>;
  getSubscriptionPlanFeature?: Maybe<SubscriptionPlanFeature>;
  getSubscriptionPlanFeatures?: Maybe<Array<Maybe<SubscriptionPlanFeature>>>;
  getSubscriptionPlanGroups?: Maybe<Array<Maybe<SubscriptionPlanGroup>>>;
  getTerritorialCommunities?: Maybe<GetTerritorialCommunities>;
  getTerritorialCommunity?: Maybe<TerritorialCommunity>;
  getTest?: Maybe<Test>;
  getTestsByModelId?: Maybe<GetTests>;
  getTestsByClassroomId?: Maybe<GetTests>;
  getTestsByThemeId?: Maybe<GetTests>;
  getTestsByTeacherIdAndSchoolId?: Maybe<GetTests>;
  getTestsByThemeIdAndSchoolId?: Maybe<GetTests>;
  getTestsByTeacherId?: Maybe<GetTests>;
  getTestsByMainlId?: Maybe<GetTests>;
  getTestsByMultipleParams?: Maybe<GetTests>;
  getTestAnswersByPupilAndTest?: Maybe<Array<Maybe<TestAnswer>>>;
  getTestAnswer?: Maybe<TestAnswer>;
  getTrainer?: Maybe<UserLibraryContent>;
  getTrainers?: Maybe<GetTrainers>;
  getTraning?: Maybe<Traning>;
  getTranings?: Maybe<GetTranings>;
  getUser?: Maybe<User>;
  getUserProfile?: Maybe<UserProfile>;
  getUserInfo?: Maybe<User>;
  getUserOptionFields?: Maybe<UserOptionFields>;
  getUsers?: Maybe<GetUsers>;
  getUsersByGroup?: Maybe<Array<Maybe<User>>>;
  getUsersCountLight?: Maybe<Array<Maybe<UsersCountLight>>>;
  getUsersWithoutAnimationBundle?: Maybe<GetUsers>;
  getUsersWithoutGroups?: Maybe<Array<Maybe<User>>>;
  getUsersWithoutProject?: Maybe<GetUsers>;
  getUsersWithoutApplicationMain?: Maybe<GetUsers>;
  getTrackerUsers?: Maybe<Array<Maybe<TrackerUser>>>;
  getProjectAssignees?: Maybe<GetUsers>;
  getUsersWithProjectContract?: Maybe<GetUsers>;
  getUsersByApplicationId?: Maybe<GetUsers>;
  getGiveawayTeachers?: Maybe<GetUsers>;
  getGiveawayTeachersTwoConditions?: Maybe<GetUsers>;
  getARBookUsersCountLight?: Maybe<Array<Maybe<ArBookUsersCountLight>>>;
  doesTeacherHaveGiveawayPupils?: Maybe<Scalars["Int"]>;
  doesTeacherHaveGiveawayInvitation?: Maybe<Scalars["Int"]>;
  getLoginStatus?: Maybe<Scalars["Boolean"]>;
  checkIfUserRegistered?: Maybe<CheckIfUserRegisteredResponse>;
  getQRLoginData?: Maybe<QrLoginData>;
  getIsTokenExpired?: Maybe<Scalars["Boolean"]>;
  getRedirectUrl?: Maybe<Scalars["String"]>;
  checkIfLinkExpired?: Maybe<Scalars["Boolean"]>;
  getDeletionReasons?: Maybe<Array<Maybe<DeletionReason>>>;
  getUserAgreements?: Maybe<Array<Maybe<UserAgreement>>>;
  getUserAgreement?: Maybe<UserAgreement>;
  getUserAgreementTypes?: Maybe<Array<Maybe<UserAgreementType>>>;
  getUserDepartments?: Maybe<Array<Maybe<UserDepartment>>>;
  getUserDepartment?: Maybe<UserDepartment>;
  getUserEvent?: Maybe<Array<Maybe<UserEvent>>>;
  getUserExpHistoryByUser?: Maybe<GetUserExpHistoryByUser>;
  getUserExpByDate?: Maybe<Scalars["Int"]>;
  getUserFolder?: Maybe<UserFolder>;
  getUserFolders?: Maybe<Array<Maybe<UserFolder>>>;
  getPaginatedUserFolders?: Maybe<UserFolders>;
  getUserGroups?: Maybe<Array<Maybe<UserGroup>>>;
  getUserGroup?: Maybe<UserGroup>;
  getGroupsByUserId?: Maybe<Array<Maybe<UserGroup>>>;
  getUserInvitations?: Maybe<GetUserInvitationsResponse>;
  getUserJobs?: Maybe<Array<Maybe<UserJob>>>;
  getUserJob?: Maybe<UserJob>;
  getLibraryNewLessons?: Maybe<GetLibraryNewLessons>;
  getLibraryContent?: Maybe<GetLibraryContent>;
  getLibraryContentMarket?: Maybe<GetLibraryContent>;
  getRecentlyUsedContent?: Maybe<GetLibraryContent>;
  getLibraryContentForConstructor?: Maybe<GetLibraryContent>;
  getMarketContentForConstructor?: Maybe<GetLibraryContent>;
  getContentPublic?: Maybe<GetContentPublic>;
  getContentByMarketId?: Maybe<UserLibraryContent>;
  getArBookPlusMainPageData?: Maybe<ArBookPlusMainPageData>;
  getLessonContent?: Maybe<GetLibraryContent>;
  getContentSlidesAndPreviews?: Maybe<GetSlidesAndPreviews>;
  getContentById?: Maybe<UserLibraryContent>;
  getArbookContentForExportModalByParentId?: Maybe<UserLibraryContent>;
  checkContentByProductId?: Maybe<Scalars["Boolean"]>;
  getContentTemplates?: Maybe<GetLibraryContent>;
  getRecommendedContent?: Maybe<Array<Maybe<UserLibraryContent>>>;
  getPupilLibraryContent?: Maybe<GetPupilLibraryContent>;
  getLibraryContentMarketForMainPage?: Maybe<Array<Maybe<UserLibraryContent>>>;
  getUserPayments?: Maybe<GetUserPayments>;
  getUserPaymentsByProject?: Maybe<GetUserPayments>;
  getUserPayment?: Maybe<UserPayment>;
  getUserPaymentStatuses?: Maybe<Array<Maybe<UserPaymentStatus>>>;
  getUserPayrolls?: Maybe<Array<Maybe<UserPayroll>>>;
  getUserPayroll?: Maybe<UserPayroll>;
  getDayReport?: Maybe<Array<Maybe<ReportByTasks>>>;
  getUserPositions?: Maybe<Array<Maybe<UserPosition>>>;
  getRateHistory?: Maybe<Array<Maybe<UserRateHistory>>>;
  getOneRateHistory?: Maybe<UserRateHistory>;
  getUserRoles?: Maybe<Array<Maybe<UserRole>>>;
  getRolesForRegistration?: Maybe<Array<Maybe<UserRole>>>;
  getSalaryHistory?: Maybe<Array<Maybe<UserSalaryHistory>>>;
  getOneSalaryHistory?: Maybe<UserSalaryHistory>;
  getUserStatsByUser?: Maybe<UserStats>;
  getUserStatuses?: Maybe<Array<Maybe<UserStatus>>>;
  getVacanciesPlans?: Maybe<Array<Maybe<VacanciesPlan>>>;
  getVacanciesPlansByName?: Maybe<VacanciesPlansByName>;
  getVacanciesPlan?: Maybe<VacanciesPlan>;
  getVacanciesPlanOptionsFields?: Maybe<VacanciesPlanOptionFields>;
  getVacanciesPlanLight?: Maybe<Array<Maybe<VacanciesPlanLight>>>;
  getVacanciesPlanStatuses?: Maybe<Array<Maybe<VacanciesPlanStatus>>>;
  getVariantByQuestionId?: Maybe<Array<Maybe<Variant>>>;
  getVRHeadset?: Maybe<VrHeadset>;
  getVRHeadsets?: Maybe<Array<Maybe<VrHeadset>>>;
  getWhiteLabelCollections?: Maybe<Array<Maybe<WhiteLabelCollection>>>;
  getWhiteLabelCollection?: Maybe<WhiteLabelCollection>;
  getWorkPlaceNames?: Maybe<Array<Maybe<Scalars["String"]>>>;
  getWorkPlaces?: Maybe<GetWorkplaces>;
};

export type QueryGetAchieveArgs = {
  id: Scalars["String"];
};

export type QueryGetAchievesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetAdArgs = {
  adId: Scalars["String"];
};

export type QueryGetAdsByUserArgs = {
  userId: Scalars["String"];
};

export type QueryGetReportsArgs = {
  type?: InputMaybe<AdminReportType>;
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCreatedPaymentsAdminReportsArgs = {
  topFilter?: InputMaybe<TopFilterAdminReportCreatedPaymentsInput>;
  filter?: InputMaybe<FilterAdminReportCreatedPaymentsInput>;
};

export type QueryGetRegisteredUsersAdminReportsArgs = {
  topFilter?: InputMaybe<TopFilterAdminReportRegisteredUsersInput>;
  filter?: InputMaybe<FilterAdminReportRegisteredUsersInput>;
};

export type QueryGetRegisteredSchoolsAdminReportsArgs = {
  topFilter?: InputMaybe<TopFilterAdminReportRegisteredSchoolsInput>;
  filter?: InputMaybe<FilterAdminReportRegisteredSchoolsInput>;
};

export type QueryGetAiChatBlocksTemplateArgs = {
  blockTypes?: InputMaybe<Array<InputMaybe<AiChatBlockTemplateEnum>>>;
  applicationMainId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetAiPromptTemplateArgs = {
  type: AiPromptType;
  category: AiPromptCategory;
  active?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetAnalyticsByTrainingArgs = {
  trainingId: Scalars["String"];
};

export type QueryGetAnimationBundlesArgs = {
  animationData: GetAnimationBundlesInput;
};

export type QueryGetAnimationBundleArgs = {
  animationId: Scalars["String"];
};

export type QueryGetTestsAnswersByPupilArgs = {
  testId: Scalars["String"];
  pupilId: Scalars["String"];
};

export type QueryGetTestsAnswerArgs = {
  id: Scalars["String"];
};

export type QueryGetPupilsAnswersByClassroomArgs = {
  classroomId: Scalars["String"];
};

export type QueryGetPupilsAnswersByTestIdArgs = {
  testId: Scalars["String"];
  searchKey?: InputMaybe<Scalars["String"]>;
};

export type QueryGetApplicationArgs = {
  id: Scalars["String"];
};

export type QueryGetApplicationsArgs = {
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetApplicationMainArgs = {
  id: Scalars["UUID"];
};

export type QueryGetApplicationsMainArgs = {
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  applicationId: Scalars["String"];
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetApplicationsMainForFiltersArgs = {
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  applicationId: Scalars["String"];
};

export type QueryGetApplicationMainsByTeacherIdArgs = {
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  teacherId: Scalars["String"];
  withSelected?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetSubjectsForSelectOptionsArgs = {
  applicationId: Scalars["UUID"];
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  teacherId?: InputMaybe<Scalars["UUID"]>;
};

export type QueryGetSelectedSubjectsByTeacherIdArgs = {
  teacherId: Scalars["UUID"];
  applicationId: Scalars["UUID"];
  schoolId: Scalars["UUID"];
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetSelectedSubjectsAndProfileSubjectsArgs = {
  teacherId: Scalars["UUID"];
  schoolId: Scalars["UUID"];
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetSubjectsWithContentPaginatedArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetSchoolSubjectsForStudentArgs = {
  take?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetApplicationMainModelArgs = {
  id: Scalars["String"];
};

export type QueryGetApplicationMainModelsArgs = {
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  applicationMainId?: InputMaybe<Scalars["String"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  themeId?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanPaymentTypeEnum>;
  sort?: InputMaybe<SortTypeEnum>;
  order?: InputMaybe<OrderTypeEnum>;
  schoolGradeId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetApplicationMainModelFilesByApplicationIdArgs = {
  modelId: Scalars["String"];
};

export type QueryGetApplicationMainThemeArgs = {
  id: Scalars["String"];
};

export type QueryGetApplicationMainThemesByMainIdArgs = {
  applicationMainId: Scalars["String"];
};

export type QueryGetApplicationVersionArgs = {
  id: Scalars["String"];
};

export type QueryGetApplicationVersionsArgs = {
  appId: Scalars["String"];
};

export type QueryGetCurrentApplicationVersionArgs = {
  appId: Scalars["String"];
  os: Os;
};

export type QueryGetAppMainThemeBackgroundArgs = {
  id: Scalars["String"];
};

export type QueryGetAwardArgs = {
  id: Scalars["UUID"];
};

export type QueryGetClassroomArgs = {
  id: Scalars["String"];
};

export type QueryGetClassroomsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
  isPersonal?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetClassroomsByTeacherArgs = {
  teacherId: Scalars["String"];
  schoolId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetClassroomsByPupilArgs = {
  pupilId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetClientSitesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetClientSiteModelArgs = {
  id: Scalars["String"];
};

export type QueryGetModelsByClientSiteIdArgs = {
  clientSiteId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetClientSiteModelByItemIdArgs = {
  itemId: Scalars["String"];
  siteKey: Scalars["String"];
};

export type QueryGetClientSiteModelItemArgs = {
  id: Scalars["String"];
};

export type QueryGetClientSiteModelItemsByModelIdArgs = {
  clientSiteModelId: Scalars["String"];
};

export type QueryGetContentCategoryArgs = {
  id: Scalars["UUID"];
};

export type QueryGetContentTypeByCategoryArgs = {
  id?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  category?: InputMaybe<Array<InputMaybe<ContentCategoryEnum>>>;
};

export type QueryGetCategoryByContentTypeArgs = {
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
};

export type QueryGetContentPreviewByIdArgs = {
  id: Scalars["String"];
};

export type QueryGetContentPreviewsArgs = {
  contentLink: ContentLinkInput;
};

export type QueryGetContentTagsArgs = {
  ids: Array<InputMaybe<Scalars["UUID"]>>;
};

export type QueryGetContentTagByCategoryArgs = {
  ids: Array<InputMaybe<Scalars["UUID"]>>;
  onlyWithContent?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetContentTagByContentTypeArgs = {
  contentType: Array<ContentTypeEnum>;
  tagType?: InputMaybe<Array<InputMaybe<UserLibraryContentType>>>;
  onlyWithContent?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetCategoryByContentTagArgs = {
  contentTagIds: Array<InputMaybe<Scalars["UUID"]>>;
};

export type QueryGetContentTagsForArbookArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCorrectionCommentArgs = {
  correctionCommentId: Scalars["String"];
};

export type QueryGetCommentsByCorrectionIdArgs = {
  correctionId: Scalars["String"];
};

export type QueryGetCoursesRequestsArgs = {
  id?: InputMaybe<Scalars["UUID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetUserCoursesRequestsArgs = {
  courseId?: InputMaybe<Scalars["UUID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCoursesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  courseTypes?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

export type QueryGetCourseArgs = {
  id: Scalars["UUID"];
};

export type QueryGetCourseInfoArgs = {
  id: Scalars["UUID"];
};

export type QueryGetCoursesForAdminPanelArgs = {
  schoolId: Scalars["UUID"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCertificateByCourseIdArgs = {
  courseId: Scalars["UUID"];
};

export type QueryGetUserCoursesCertificatesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCourseTypesArgs = {
  fromEditor?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetCourseLessonsArgs = {
  courseId: Scalars["UUID"];
  searchKey?: InputMaybe<Scalars["String"]>;
};

export type QueryGetCourseLessonArgs = {
  id: Scalars["UUID"];
};

export type QueryGetEducationAreaArgs = {
  id: Scalars["String"];
};

export type QueryGetEducationPlanArgs = {
  id: Scalars["String"];
};

export type QueryGetEducationPlansArgs = {
  data: GetEducationPlansInput;
};

export type QueryGetPupilEducationPlansArgs = {
  subjectId: Scalars["UUID"];
  tutorId?: InputMaybe<Scalars["UUID"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
  take?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetAvailableAndPersonalPlanCountArgs = {
  subjectId?: InputMaybe<Scalars["UUID"]>;
  teacherId?: InputMaybe<Scalars["UUID"]>;
};

export type QueryGetEducationPlanResultsArgs = {
  data?: InputMaybe<GetEducationPlanResultsInput>;
};

export type QueryCheckIfPlanHasContentUploadedToArBookPlusArgs = {
  educationPlanId?: InputMaybe<Scalars["UUID"]>;
};

export type QueryGetEducationPlanChapterArgs = {
  id: Scalars["String"];
};

export type QueryGetEducationPlanChaptersArgs = {
  educationPlanId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryCheckIfChapterHasContentUploadedToArBookPlusArgs = {
  educationPlanChapterId?: InputMaybe<Scalars["UUID"]>;
};

export type QueryGetEducationPlanParticipantsArgs = {
  data: GetEducationPlanParticipantsInput;
};

export type QueryGetPlanParticipantsForSelectorArgs = {
  educationPlanId: Scalars["UUID"];
  type: PlanParticipantTypeEnum;
  filter?: InputMaybe<PlanParticipantForSelectorFilter>;
};

export type QueryGetSchoolClassroomsAndClassroomsForFilterArgs = {
  educationPlanId: Scalars["UUID"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetActivePupilsArgs = {
  educationPlanId: Scalars["UUID"];
};

export type QueryCheckIfParticipantsExistInPlanArgs = {
  educationPlanId: Scalars["UUID"];
};

export type QueryGetEmployeesDistributionsByNameArgs = {
  name: Scalars["String"];
};

export type QueryGetEmployeesDistributionArgs = {
  id: Scalars["String"];
};

export type QueryGetEventArgs = {
  id: Scalars["String"];
};

export type QueryGetEventsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetEventDateArgs = {
  id: Scalars["String"];
};

export type QueryGetEventDatesArgs = {
  eventId: Scalars["String"];
};

export type QueryGetExerciseArgs = {
  id: Scalars["String"];
};

export type QueryGetExercisesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetFaqArgs = {
  id: Scalars["String"];
};

export type QueryGetFaqsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  isFrom?: InputMaybe<IsFromEnum>;
};

export type QueryGetUserFeatureFlagsByUserIdArgs = {
  userId: Scalars["UUID"];
};

export type QueryGetUserFeatureFlagsBySchoolIdArgs = {
  schoolId: Scalars["UUID"];
};

export type QueryGetFeedbacksArgs = {
  statuses?: InputMaybe<Array<InputMaybe<FeedbackStatusEnum>>>;
  isFrom?: InputMaybe<FeedbackIsFromEnum>;
};

export type QueryGetFeedbacksLightArgs = {
  isFrom?: InputMaybe<FeedbackIsFromEnum>;
};

export type QueryGetFeedbackArgs = {
  feedbackId: Scalars["String"];
};

export type QueryGetFeedbacksForCurrentUserArgs = {
  statuses?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryGetFeedbackCommentArgs = {
  feedbackCommentId: Scalars["String"];
};

export type QueryGetCommentsByFeedbackIdArgs = {
  feedbackId: Scalars["String"];
};

export type QueryGetForumsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetForumArgs = {
  forumId: Scalars["String"];
};

export type QueryGetForumsForCurrentUserArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetGameModelArgs = {
  id: Scalars["UUID"];
};

export type QueryGetGameModelArtifactArgs = {
  id: Scalars["UUID"];
};

export type QueryGetGameModelArtifactsArgs = {
  input: GetGameModelArtifactsInput;
  take?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetGameModelStateArgs = {
  id: Scalars["UUID"];
};

export type QueryGetGameModelStatesArgs = {
  gameModelId: Scalars["UUID"];
};

export type QueryGetClassroomAuthUrlArgs = {
  webAppUrl?: InputMaybe<Scalars["String"]>;
};

export type QueryClassRoomTopicsArgs = {
  courseId: Scalars["ID"];
};

export type QueryClassRoomStudentsArgs = {
  courseId: Scalars["ID"];
};

export type QueryClassRoomTeachersArgs = {
  courseId: Scalars["ID"];
};

export type QueryClassroomCourseWorksArgs = {
  courseId: Scalars["ID"];
};

export type QueryGetKnowledgeBaseBlocksByThemeIdArgs = {
  themeId: Scalars["String"];
};

export type QueryGetKnowledgeBaseBlocksTestByThemeIdArgs = {
  themeId: Scalars["String"];
  isTestPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetKnowledgeBaseBlocksTestHistoryArgs = {
  themeId: Scalars["String"];
};

export type QueryGetKnowledgeBaseBlocksTestByManagerArgs = {
  userId?: InputMaybe<Scalars["String"]>;
  isTestPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetKnowledgeBaseBlocksTestAnswersByTestIdArgs = {
  testBlockId: Scalars["String"];
};

export type QueryGetBlockTestHistoryByBlockIdArgs = {
  blockId: Scalars["String"];
};

export type QueryGetKnowledgeBaseTestAttemptArgs = {
  id: Scalars["String"];
};

export type QueryGetKnowledgeBaseTestAttemptsArgs = {
  themeId: Scalars["String"];
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetBaseKnowledgeBaseCategoriesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isFor?: InputMaybe<KnowledgeBaseCategoryIsForEnum>;
};

export type QueryGetKnowledgeBaseCategoriesByManagerArgs = {
  userId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetKnowledgeBaseCategoriesByParticipantArgs = {
  userId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryDoesHaveEveryThemeTestAttemptByCategoryIdArgs = {
  categoryId: Scalars["String"];
  userId: Scalars["String"];
};

export type QueryGetKnowledgeBaseThemeArgs = {
  id: Scalars["String"];
};

export type QueryGetKnowledgeBaseThemesArgs = {
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetKnowledgeBaseThemesByCategoryIdArgs = {
  categoryId: Scalars["String"];
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetKnowledgeBaseThemesByManagerArgs = {
  userId?: InputMaybe<Scalars["String"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetPercentageCompleteThemeTestsArgs = {
  userId: Scalars["String"];
  themeId: Scalars["String"];
};

export type QueryGetPercentageCompleteCategoryArgs = {
  userId: Scalars["String"];
  categoryId: Scalars["String"];
};

export type QueryGetLessonArgs = {
  lessonId: Scalars["String"];
};

export type QueryGetLessonsByMasterIdArgs = {
  getLessonsParams: GetLessonsParams;
};

export type QueryGetLessonBlockArgs = {
  blockId: Scalars["String"];
};

export type QueryGetLessonBlocksByPageIdArgs = {
  pageId: Scalars["String"];
};

export type QueryGetMarksForLessonArgs = {
  lessonId: Scalars["UUID"];
};

export type QueryGetLessonPageArgs = {
  id: Scalars["String"];
};

export type QueryGetLessonPagesArgs = {
  lessonId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetLessonQuestionArgs = {
  id: Scalars["String"];
};

export type QueryGetLessonQuestionsByPageIdArgs = {
  pageId: Scalars["String"];
};

export type QueryGetMarketCollectionsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  onlyPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetMarketCollectionArgs = {
  id: Scalars["UUID"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortType?: InputMaybe<MarketCollectionSortTypeEnum>;
};

export type QueryGetMarketingModalInfoArgs = {
  type?: InputMaybe<MarketingModalInfoType>;
  appType?: InputMaybe<AppTypeNameEnum>;
};

export type QueryGetMarketingModalsArgs = {
  type?: InputMaybe<MarketingModalInfoType>;
  appType?: InputMaybe<AppTypeNameEnum>;
};

export type QueryGetMobileNotificationArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type QueryGetMobileNotificationsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetMobileNotificationsByAppArgs = {
  appId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetMobileNotificationsByManagerArgs = {
  userId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetMobileNotificationsByParticipantArgs = {
  userId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetIsUnreadNotificationsArgs = {
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetParticipantsByMobileNotificationArgs = {
  notificationId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetMonthHourPlanArgs = {
  id: Scalars["String"];
};

export type QueryGetNewLessonArgs = {
  id: Scalars["String"];
  subjectId?: InputMaybe<Scalars["String"]>;
  fromMarket?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetNewLessonMaterialArgs = {
  id: Scalars["String"];
};

export type QueryGetNewLessonsArgs = {
  educationPlanChapterId?: InputMaybe<Scalars["String"]>;
  educationPlanId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  subjectId?: InputMaybe<Scalars["String"]>;
  connectedToLessonId?: InputMaybe<Scalars["String"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetNewLessonsByMainIdArgs = {
  mainId: Scalars["String"];
};

export type QueryGetNewLessonsByEducationPlanIdArgs = {
  educationPlanId: Scalars["String"];
  teacherId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  available?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetNewLessonsWithTemplateTypeArgs = {
  sortType?: InputMaybe<LessonSortTypeEnum>;
  searchKey?: InputMaybe<Scalars["String"]>;
  subjectIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetNewLessonsArbookPlusArgs = {
  sortType?: InputMaybe<LessonSortTypeEnum>;
  searchKey?: InputMaybe<Scalars["String"]>;
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  subjectIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  teachingFormats?: InputMaybe<Array<InputMaybe<TeachingFormatEnum>>>;
  inAppPurchases?: InputMaybe<Array<InputMaybe<InAppPurchasesEnum>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetNewLessonContentTypesArgs = {
  type: NewLessonType;
  isThirdLevel?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetLessonByCodeArgs = {
  pupilLoginCode: Scalars["String"];
};

export type QueryGetNewLessonResultsOnPresentationArgs = {
  newLessonId: Scalars["String"];
};

export type QueryGetNewLessonGuestsResultsOnPresentationArgs = {
  newLessonId: Scalars["String"];
};

export type QueryGetTeachersSubjectsNewLessonTemplatesArgs = {
  searchKey?: InputMaybe<Scalars["String"]>;
  subjectLimit?: InputMaybe<Scalars["Int"]>;
  subjectSkip?: InputMaybe<Scalars["Int"]>;
  lessonLimit?: InputMaybe<Scalars["Int"]>;
  lessonSkip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetLessonTestsResultsArgs = {
  lessonId: Scalars["UUID"];
  onlyLast?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetNewLessonForParticipantArgs = {
  id: Scalars["UUID"];
};

export type QueryGetRecommendedLessonsArgs = {
  lessonId: Scalars["UUID"];
};

export type QueryGetLessonByPupilLoginCodeArgs = {
  pupilLoginCode: Scalars["String"];
};

export type QueryCheckIfLessonHasContentUploadedToArBookPlusArgs = {
  lessonId?: InputMaybe<Scalars["UUID"]>;
};

export type QueryCheckIfCanStartQuizArgs = {
  lessonId?: InputMaybe<Scalars["UUID"]>;
};

export type QueryGetNewLessonFaceTrackingArgs = {
  newLessonId: Scalars["String"];
  userId: Scalars["String"];
};

export type QueryGetNewLessonFeedbacksArgs = {
  newLessonId: Scalars["String"];
  feedbackQuestionId: Scalars["String"];
};

export type QueryGetNewLessonPreviewsArgs = {
  lessonId: Scalars["String"];
};

export type QueryGetNewArgs = {
  id: Scalars["String"];
};

export type QueryGetNewsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  isFor?: InputMaybe<Array<InputMaybe<NewsIsForEnum>>>;
};

export type QueryGetNewsByManagerArgs = {
  userId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetNewsByParticipantArgs = {
  userId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetNotificationsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetNotificationsByManagerArgs = {
  userId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetNotificationsByParticipantArgs = {
  userId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetIsNewNotificationArgs = {
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetOnboardingThemeArgs = {
  themeId: Scalars["String"];
};

export type QueryGetPaymentHistoryArgs = {
  id: Scalars["String"];
};

export type QueryGetPaymentsHistoryBySchoolArgs = {
  schoolId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  withTrial?: InputMaybe<Scalars["Boolean"]>;
  isActivated?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetPurchasePaymentsBySchoolArgs = {
  schoolId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetSchoolPaymentHistoryArgs = {
  searchKey?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  onlyActivated?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetPaymentByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryGetPaymentTypesArgs = {
  names?: InputMaybe<Array<InputMaybe<PaymentType>>>;
  groups?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

export type QueryGetPaymentProvidersArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  planId?: InputMaybe<Scalars["UUID"]>;
};

export type QueryGetSummaryArgs = {
  contentId?: InputMaybe<Scalars["UUID"]>;
  planId?: InputMaybe<Scalars["UUID"]>;
};

export type QueryGetPayrollBonusesArgs = {
  payrollId: Scalars["String"];
};

export type QueryGetPayrollBonusArgs = {
  bonusId: Scalars["String"];
};

export type QueryGetPayrollKpisArgs = {
  payrollId: Scalars["String"];
};

export type QueryGetPayrollSalaryPartArgs = {
  payrollId: Scalars["String"];
};

export type QueryGetPermissionsArgs = {
  userId: Scalars["String"];
};

export type QueryGetProductArgs = {
  productId: Scalars["String"];
};

export type QueryGetProductsByTypeArgs = {
  productTypeId: Scalars["Int"];
};

export type QueryGetProjectsArgs = {
  getProjectsInput: GetProjectsInput;
};

export type QueryGetProjectArgs = {
  projectId: Scalars["String"];
};

export type QueryGetProjectsByUserArgs = {
  userId: Scalars["String"];
};

export type QueryGetProjectsByClientArgs = {
  userId: Scalars["String"];
};

export type QueryGetProjectFilesByProjectArgs = {
  projectId: Scalars["String"];
  isFor?: InputMaybe<IsForEnum>;
};

export type QueryGetProjectAdditionalContactArgs = {
  projectAdditionalContactId: Scalars["String"];
};

export type QueryGetProjectAdditionalContactsByProjectArgs = {
  projectId: Scalars["String"];
};

export type QueryGetProjectCorrectionsArgs = {
  projectId: Scalars["String"];
  statuses?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryGetProjectCorrectionsLightArgs = {
  projectId: Scalars["String"];
};

export type QueryGetProjectCorrectionArgs = {
  projectCorrectionId: Scalars["String"];
};

export type QueryGetProjectCustomFieldsMapArgs = {
  id: Scalars["String"];
};

export type QueryGetProjectCustomFieldsMapByProjectArgs = {
  projectId: Scalars["String"];
};

export type QueryGetProjectKanbanArgs = {
  projectId: Scalars["String"];
};

export type QueryGetKanbanWorkflowStepArgs = {
  id: Scalars["String"];
};

export type QueryGetKanbanWorkflowStepsByProjectArgs = {
  projectId: Scalars["String"];
};

export type QueryGetProjectLinksArgs = {
  isFor?: InputMaybe<IsForEnum>;
};

export type QueryGetProjectLinkArgs = {
  projectLinkId: Scalars["String"];
};

export type QueryGetProjectLinksByProjectArgs = {
  projectId: Scalars["String"];
  isFor?: InputMaybe<IsForEnum>;
};

export type QueryGetProjectStepArgs = {
  id: Scalars["String"];
};

export type QueryGetProjectStepsByProjectArgs = {
  projectId: Scalars["String"];
};

export type QueryGetProjectEpicsArgs = {
  projectId: Scalars["String"];
};

export type QueryGetProjectStepsFromJiraArgs = {
  projectId: Scalars["String"];
};

export type QueryGetEpicsProgressReportArgs = {
  projectId: Scalars["String"];
};

export type QueryGetChartDataArgs = {
  projectId: Scalars["String"];
  isEpic?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetChartData2Args = {
  projectId: Scalars["String"];
  isEpic?: InputMaybe<Scalars["Boolean"]>;
  epicId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetProjectStepsInProgressArgs = {
  projectId: Scalars["String"];
};

export type QueryGetProjectTaskArgs = {
  id: Scalars["String"];
};

export type QueryGetProjectTasksArgs = {
  projectId?: InputMaybe<Scalars["String"]>;
  taskStatusId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetProjectTasksByProjectArgs = {
  projectId: Scalars["String"];
};

export type QueryGetProjectTasksByEpicArgs = {
  epicKey: Scalars["String"];
};

export type QueryGetProjectTasksByWorkflowStepArgs = {
  projectId: Scalars["String"];
  workflowStepId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetTasksWithProblemsArgs = {
  projectId: Scalars["String"];
};

export type QueryGetLateTasksArgs = {
  projectId: Scalars["String"];
  taskStatusId?: InputMaybe<Scalars["String"]>;
  isClient?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetBugsAdditionalTasksReportArgs = {
  projectId: Scalars["String"];
};

export type QueryGetProjectTaskHistoryArgs = {
  projectTaskId: Scalars["String"];
};

export type QueryGetProjectTaskLabelArgs = {
  id: Scalars["String"];
};

export type QueryGetProjectTaskLabelsByProjectArgs = {
  projectId: Scalars["String"];
};

export type QueryGetAvailableProjectTaskLabelsArgs = {
  projectId: Scalars["String"];
  projectTaskId: Scalars["String"];
};

export type QueryGetProjectTaskStatusArgs = {
  id: Scalars["String"];
};

export type QueryGetProjectTaskStatusesByProjectArgs = {
  projectId: Scalars["String"];
};

export type QueryGetProjectTypeArgs = {
  typeId: Scalars["String"];
};

export type QueryCheckUserPromotionStatusArgs = {
  code?: InputMaybe<Scalars["String"]>;
};

export type QueryCheckUserPromotionStatusOldArgs = {
  type?: InputMaybe<PromotionTypeEnum>;
};

export type QueryGetManualPromotionsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetPurchaseArgs = {
  purchaseId: Scalars["String"];
};

export type QueryGetPurchasesArgs = {
  getPurchasesData: GetPurchasesInput;
};

export type QueryGetPurchasesByUserArgs = {
  getPurchasesData: GetPurchasesByUserInput;
};

export type QueryGetPurchasesInCartArgs = {
  getPurchasesData: GetPurchasesByUserInput;
};

export type QueryGetPurchaseHistoryArgs = {
  getPurchasesData: GetPurchasesByUserInput;
};

export type QueryGetQuestionsByTestIdArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  testId: Scalars["String"];
};

export type QueryGetQuizThemesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetTimeReportArgs = {
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetTimeReportByEpicArgs = {
  epicKey: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetCostReportArgs = {
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetCostReportByEpicArgs = {
  epicKey: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetDateReportByEpicArgs = {
  epicKey: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetCompletionPctReportArgs = {
  dates?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryGetCompletionPctReportByEpicArgs = {
  epicKey: Scalars["String"];
};

export type QueryGetAddedTasksReportArgs = {
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetAddedTasksEpicsArgs = {
  projectId: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetAddedTasksReportByEpicArgs = {
  epicKey: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetMasteringMoneyReportArgs = {
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetEmployeeWorkloadReportByUserArgs = {
  jobName: Scalars["String"];
};

export type QueryGetEmployeeWorkloadReportArgs = {
  userId: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetEmployeeWorkloadDayReportArgs = {
  userId: Scalars["String"];
  date: Scalars["Date"];
};

export type QueryGetDashboardMasteringMoneyReportArgs = {
  projectId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetDashboardInProgressTasksReportArgs = {
  projectId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetDashboardCompletionPctReportArgs = {
  projectId?: InputMaybe<Scalars["String"]>;
  date?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetDashboardAddedTasksReportArgs = {
  projectId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetDashboardAddedTasksArgs = {
  projectId: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetDashboardUserWorkloadReportArgs = {
  projectId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetDashboardUserWorkloadProjectsArgs = {
  assigneeId: Scalars["String"];
  projectId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetDashboardOverdueTasksReportArgs = {
  projectId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetDashboardOverdueTasksArgs = {
  projectId: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetRewardArgs = {
  id: Scalars["UUID"];
};

export type QueryGetRewardsArgs = {
  groupId: Scalars["UUID"];
};

export type QueryGetSchoolsArgs = {
  params: GetSchoolsParams;
};

export type QueryGetSchoolsForAdminPanelArgs = {
  params: GetSchoolsForAdminPanelParams;
};

export type QueryGetSchoolsByCityArgs = {
  cityId: Scalars["String"];
};

export type QueryGetSchoolArgs = {
  id: Scalars["String"];
};

export type QueryGetPupilsBySchoolArgs = {
  schoolId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
};

export type QueryGetPupilsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
};

export type QueryGetTeachersBySchoolArgs = {
  schoolId: Scalars["String"];
  teacherStatuses?: InputMaybe<Array<InputMaybe<SchoolUserStatusEnum>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
};

export type QueryGetTeachersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
};

export type QueryGetGiveawaySchoolsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryValidateUserInvitationsListArgs = {
  list: Scalars["Upload"];
};

export type QueryGetSchoolUsersCsvArgs = {
  schoolId: Scalars["UUID"];
};

export type QueryGetSchoolParticipantsArgs = {
  schoolId: Scalars["UUID"];
  searchKey?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SchoolParticipantSortKey>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetSchoolNamesBySearchArgs = {
  searchKey?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  region: Scalars["String"];
  district?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
};

export type QueryGetCreatedLessonsBySchoolTeachersArgs = {
  schoolId: Scalars["UUID"];
  from: Scalars["Date"];
  to: Scalars["Date"];
};

export type QueryGetSchoolConnectionStatisticArgs = {
  schoolId: Scalars["UUID"];
  from: Scalars["Date"];
  to: Scalars["Date"];
  roles: TeacherPupilRoleEnum;
};

export type QueryGetSchoolCitiesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["String"]>;
  districtId?: InputMaybe<Scalars["String"]>;
  countryId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetSchoolClassroomsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
};

export type QueryGetSchoolClassroomArgs = {
  id: Scalars["String"];
};

export type QueryGetPupilsBySchoolClassroomArgs = {
  schoolClassroomId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetSchoolClassroomByYearArgs = {
  schoolId: Scalars["String"];
  grade: Scalars["Int"];
  year: Scalars["Int"];
  letter: Scalars["String"];
};

export type QueryGetSchoolCountriesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
};

export type QueryGetSchoolDistrictsByRegionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetSchoolEventsArgs = {
  schoolIds: Array<InputMaybe<Scalars["String"]>>;
  from?: InputMaybe<Scalars["Date"]>;
  to?: InputMaybe<Scalars["Date"]>;
  isUpcoming?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetLessonEventsArgs = {
  lessonId: Scalars["UUID"];
  from?: InputMaybe<Scalars["Date"]>;
  to?: InputMaybe<Scalars["Date"]>;
};

export type QueryGetSchoolGradesPaginatedArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetSchoolPayerArgs = {
  id: Scalars["UUID"];
};

export type QueryGetSchoolRegionsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
  countryId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetTeacherSchoolsArgs = {
  teacherId: Scalars["String"];
};

export type QueryGetSessionHistoriesArgs = {
  userId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isFrom?: InputMaybe<IsFromEnum>;
};

export type QueryGetSessionHistoryArgs = {
  id: Scalars["String"];
};

export type QueryGetSlideArgs = {
  id: Scalars["String"];
};

export type QueryGetSlidesArgs = {
  lessonId: Scalars["String"];
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isEffective?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetTheMostEffectiveSlidesArgs = {
  lessonId: Scalars["String"];
};

export type QueryGetSlidesBackgroundsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  continueToken?: InputMaybe<Scalars["String"]>;
};

export type QueryGetSlidesStickersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  continueToken?: InputMaybe<Scalars["String"]>;
};

export type QueryGetSlidesStickersCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  continueToken?: InputMaybe<Scalars["String"]>;
  collectionName: Scalars["String"];
};

export type QueryGetSuggestedSlideTestsArgs = {
  slideId: Scalars["String"];
};

export type QueryGetSlideBackgroundCollectionsArgs = {
  limit: Scalars["Int"];
  skip: Scalars["Int"];
};

export type QueryGetSlideBackgroundsArgs = {
  collectionId: Scalars["String"];
};

export type QueryGetSlideReflectionsArgs = {
  userId?: InputMaybe<Scalars["String"]>;
  slideId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetNewLessonEfficiencyPercentArgs = {
  newLessonId: Scalars["String"];
};

export type QueryGetNewLessonInvolvementPercentArgs = {
  newLessonId: Scalars["String"];
};

export type QueryGetNewLessonAssimilationPercentArgs = {
  newLessonId: Scalars["String"];
};

export type QueryGetNewLessonFeedbackPercentArgs = {
  newLessonId: Scalars["String"];
};

export type QueryGetSlideReflectionPercentArgs = {
  slideId: Scalars["String"];
};

export type QueryGetNewLessonReflectionReportsArgs = {
  from: Scalars["Date"];
  to: Scalars["Date"];
};

export type QueryGetLessonSlidesAnswersArgs = {
  newLessonId: Scalars["String"];
};

export type QueryGetLessonSlidesGuestsAnswersArgs = {
  newLessonId: Scalars["String"];
};

export type QueryGetPupilSlideResultOnPresentationArgs = {
  slideId: Scalars["String"];
  userId: Scalars["String"];
};

export type QueryGetGuestSlideResultOnPresentationArgs = {
  slideId: Scalars["String"];
  fullName?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetPupilsSlideResultOnPresentationArgs = {
  slideId: Scalars["String"];
};

export type QueryGetGuestsSlideResultOnPresentationArgs = {
  slideId: Scalars["String"];
};

export type QueryGetCourseLessonResultsArgs = {
  newLessonId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortType?: InputMaybe<CourseLessonReflectionSortType>;
};

export type QueryGetSlideTestChoiceArgs = {
  id: Scalars["String"];
};

export type QueryGetSlideTestChoicesArgs = {
  slideId: Scalars["String"];
};

export type QueryGetSlideTestChoiceConformityArgs = {
  id: Scalars["String"];
};

export type QueryGetSmartNotificationArgs = {
  id?: InputMaybe<Scalars["String"]>;
  contentType?: InputMaybe<ContentTypeInput>;
};

export type QueryGetSmartNotificationsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<SmartNotificationTypeEnum>;
};

export type QueryGetSmartNotificationsByManagerArgs = {
  userId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetSmartNotificationsByParticipantArgs = {
  userId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetSmartNotificationAnswerArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type QueryGetAnswersBySmartNotificationIdArgs = {
  smartNotificationId: Scalars["String"];
};

export type QueryGetAnswersBySmartNotificationPollListIdArgs = {
  pollListId: Scalars["String"];
};

export type QueryGetAnswersWithPercentsBySmartNotificationIdArgs = {
  smartNotificationId: Scalars["String"];
};

export type QueryGetRatingAnswersCountBySmartNotificationIdArgs = {
  smartNotificationId: Scalars["String"];
};

export type QueryGetUsersBySmartNotificationAnswerArgs = {
  smartNotificationId: Scalars["String"];
  ratting?: InputMaybe<Scalars["Int"]>;
  pollListId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetSmartNotificationPollListArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type QueryGetPollListsBySmartNotificationIdArgs = {
  smartNotificationId: Scalars["String"];
};

export type QueryGetSubscriptionPlanArgs = {
  id: Scalars["String"];
};

export type QueryGetSubscriptionPlansV2Args = {
  type?: InputMaybe<SubscriptionPlanTypeEnum>;
};

export type QueryGetSubscriptionPlansV3ForAdminPanelArgs = {
  schoolId?: InputMaybe<Scalars["UUID"]>;
};

export type QueryGetSubscriptionPlanFeatureArgs = {
  id: Scalars["String"];
};

export type QueryGetSubscriptionPlanFeaturesArgs = {
  id: Scalars["String"];
};

export type QueryGetSubscriptionPlanGroupsArgs = {
  appType: AppTypeNameEnum;
  excludeGroups?: InputMaybe<Array<InputMaybe<SubscriptionPlanGroupNameEnum>>>;
};

export type QueryGetTerritorialCommunitiesArgs = {
  data?: InputMaybe<TerritorialCommunityInput>;
  take?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetTerritorialCommunityArgs = {
  id: Scalars["UUID"];
};

export type QueryGetTestArgs = {
  id: Scalars["String"];
};

export type QueryGetTestsByModelIdArgs = {
  modelId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetTestsByClassroomIdArgs = {
  classroomId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetTestsByThemeIdArgs = {
  themeId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetTestsByTeacherIdAndSchoolIdArgs = {
  schoolId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetTestsByThemeIdAndSchoolIdArgs = {
  themeId: Scalars["String"];
  schoolId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetTestsByTeacherIdArgs = {
  teacherId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetTestsByMainlIdArgs = {
  mainId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetTestsByMultipleParamsArgs = {
  schoolId: Scalars["String"];
  teacherId?: InputMaybe<Scalars["String"]>;
  applicationMainId?: InputMaybe<Scalars["String"]>;
  applicationMainModelId?: InputMaybe<Scalars["String"]>;
  applicationMainThemeId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetTestAnswersByPupilAndTestArgs = {
  testId: Scalars["String"];
  pupilId: Scalars["String"];
};

export type QueryGetTestAnswerArgs = {
  id: Scalars["String"];
};

export type QueryGetTrainerArgs = {
  id: Scalars["String"];
};

export type QueryGetTrainersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetTraningArgs = {
  id: Scalars["String"];
};

export type QueryGetTraningsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetUserArgs = {
  userId: Scalars["String"];
};

export type QueryGetUserProfileArgs = {
  userId: Scalars["UUID"];
};

export type QueryGetUsersArgs = {
  getUsersData: GetUsersInput;
};

export type QueryGetUsersByGroupArgs = {
  userGroupId: Scalars["String"];
};

export type QueryGetUsersWithoutAnimationBundleArgs = {
  animationId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetUsersWithoutProjectArgs = {
  projectId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetUsersWithoutApplicationMainArgs = {
  applicationMainId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetProjectAssigneesArgs = {
  projectId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetUsersWithProjectContractArgs = {
  projectId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  withOutContract?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetUsersByApplicationIdArgs = {
  applicationId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetGiveawayTeachersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetGiveawayTeachersTwoConditionsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryDoesTeacherHaveGiveawayPupilsArgs = {
  teacherId: Scalars["String"];
};

export type QueryDoesTeacherHaveGiveawayInvitationArgs = {
  teacherId: Scalars["String"];
};

export type QueryCheckIfUserRegisteredArgs = {
  email: Scalars["String"];
};

export type QueryGetQrLoginDataArgs = {
  options?: InputMaybe<QrOptions>;
};

export type QueryGetIsTokenExpiredArgs = {
  token: Scalars["String"];
  type: TokenType;
};

export type QueryGetRedirectUrlArgs = {
  data?: InputMaybe<RedirectUrlData>;
};

export type QueryCheckIfLinkExpiredArgs = {
  hash: Scalars["UUID"];
};

export type QueryGetUserAgreementArgs = {
  userAgreementId: Scalars["String"];
};

export type QueryGetUserDepartmentArgs = {
  departmentId: Scalars["String"];
};

export type QueryGetUserExpHistoryByUserArgs = {
  userId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetUserExpByDateArgs = {
  userId?: InputMaybe<Scalars["String"]>;
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
};

export type QueryGetUserFolderArgs = {
  id: Scalars["UUID"];
};

export type QueryGetUserFoldersArgs = {
  sortKey?: InputMaybe<GetUserFolderSortKeyEnum>;
  sortOrder?: InputMaybe<GetLibraryLessonsSortOrderEnum>;
};

export type QueryGetPaginatedUserFoldersArgs = {
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sortKey?: InputMaybe<GetUserFolderSortKeyEnum>;
  sortOrder?: InputMaybe<GetLibraryLessonsSortOrderEnum>;
};

export type QueryGetUserGroupArgs = {
  userGroupId: Scalars["String"];
};

export type QueryGetGroupsByUserIdArgs = {
  userId: Scalars["String"];
};

export type QueryGetUserInvitationsArgs = {
  onlyActive?: InputMaybe<Scalars["Boolean"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetUserJobArgs = {
  userJobId: Scalars["String"];
};

export type QueryGetLibraryNewLessonsArgs = {
  getLibraryLessonsParams: GetLibraryLessonsParams;
};

export type QueryGetLibraryContentArgs = {
  params?: InputMaybe<GetLibraryContentParams>;
};

export type QueryGetLibraryContentMarketArgs = {
  params?: InputMaybe<LibraryContentMarketInput>;
};

export type QueryGetRecentlyUsedContentArgs = {
  id: Scalars["UUID"];
  lessonType?: InputMaybe<NewLessonType>;
  contentType?: InputMaybe<ContentTypeEnum>;
  onlyCount?: InputMaybe<Scalars["Boolean"]>;
  fromMarket?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetLibraryContentForConstructorArgs = {
  id: Scalars["UUID"];
  folderId?: InputMaybe<Scalars["UUID"]>;
  lessonType?: InputMaybe<NewLessonType>;
  contentType?: InputMaybe<ContentTypeEnum>;
  gradeId?: InputMaybe<Scalars["UUID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetMarketContentForConstructorArgs = {
  id: Scalars["UUID"];
  lessonType?: InputMaybe<NewLessonType>;
  contentType?: InputMaybe<ContentTypeEnum>;
  filterBySubjects?: InputMaybe<Scalars["Boolean"]>;
  filterByGrades?: InputMaybe<Scalars["Boolean"]>;
  onlyCount?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetContentPublicArgs = {
  contentId: Scalars["UUID"];
  type: UserLibraryContentType;
};

export type QueryGetContentByMarketIdArgs = {
  marketId: Scalars["String"];
};

export type QueryGetArBookPlusMainPageDataArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetLessonContentArgs = {
  connectedToLessonId?: InputMaybe<Scalars["UUID"]>;
  chapterId?: InputMaybe<Scalars["UUID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetContentSlidesAndPreviewsArgs = {
  contentId: Scalars["UUID"];
};

export type QueryGetContentByIdArgs = {
  contentId: Scalars["UUID"];
  eSputnikTrack?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetArbookContentForExportModalByParentIdArgs = {
  parentId: Scalars["UUID"];
};

export type QueryCheckContentByProductIdArgs = {
  productId: Scalars["Int"];
};

export type QueryGetContentTemplatesArgs = {
  sortType?: InputMaybe<LessonSortTypeEnum>;
  searchKey?: InputMaybe<Scalars["String"]>;
  subjectIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetRecommendedContentArgs = {
  contentId: Scalars["UUID"];
};

export type QueryGetPupilLibraryContentArgs = {
  contentGroup: PupilLibraryContentGroup;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  searchKey?: InputMaybe<Scalars["String"]>;
};

export type QueryGetLibraryContentMarketForMainPageArgs = {
  sortType?: InputMaybe<ContentSortTypeEnum>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetUserPaymentsArgs = {
  userId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetUserPaymentsByProjectArgs = {
  projectId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetUserPaymentArgs = {
  paymentId: Scalars["String"];
};

export type QueryGetUserPayrollsArgs = {
  userId: Scalars["String"];
};

export type QueryGetUserPayrollArgs = {
  payrollId: Scalars["String"];
};

export type QueryGetDayReportArgs = {
  payrollId: Scalars["String"];
  date: Scalars["String"];
};

export type QueryGetRateHistoryArgs = {
  userId: Scalars["String"];
};

export type QueryGetOneRateHistoryArgs = {
  id: Scalars["String"];
};

export type QueryGetUserRolesArgs = {
  roles?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
};

export type QueryGetSalaryHistoryArgs = {
  userId: Scalars["String"];
};

export type QueryGetOneSalaryHistoryArgs = {
  salaryHistoryId: Scalars["String"];
};

export type QueryGetUserStatsByUserArgs = {
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetVacanciesPlansArgs = {
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetVacanciesPlansByNameArgs = {
  userId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type QueryGetVacanciesPlanArgs = {
  planId: Scalars["String"];
};

export type QueryGetVariantByQuestionIdArgs = {
  questionId: Scalars["String"];
};

export type QueryGetVrHeadsetArgs = {
  id: Scalars["UUID"];
};

export type QueryGetVrHeadsetsArgs = {
  searchKey?: InputMaybe<Scalars["String"]>;
};

export type QueryGetWhiteLabelCollectionsArgs = {
  filters?: InputMaybe<GetWhiteLabelCollectionsFilters>;
};

export type QueryGetWhiteLabelCollectionArgs = {
  id: Scalars["UUID"];
};

export type QueryGetWorkPlaceNamesArgs = {
  regionId: Scalars["UUID"];
  cityId: Scalars["UUID"];
  countryId?: InputMaybe<Scalars["UUID"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetWorkPlacesArgs = {
  regionId: Scalars["UUID"];
  cityId: Scalars["UUID"];
  countryId?: InputMaybe<Scalars["UUID"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type Question = {
  __typename?: "Question";
  id?: Maybe<Scalars["String"]>;
  question?: Maybe<Scalars["String"]>;
  testId?: Maybe<Scalars["String"]>;
  test?: Maybe<Test>;
  variants?: Maybe<Array<Maybe<Variant>>>;
  answers?: Maybe<Array<Maybe<Answer>>>;
  rightVariantId?: Maybe<Scalars["String"]>;
  rightVariant?: Maybe<Variant>;
  photo?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type QuestionVariantInput = {
  content?: InputMaybe<Scalars["String"]>;
};

export type QuizTheme = {
  __typename?: "QuizTheme";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  previewUrl?: Maybe<Scalars["String"]>;
  musicUrl?: Maybe<Scalars["String"]>;
  backgroundUrl?: Maybe<Scalars["String"]>;
  scene?: Maybe<ApplicationMainModel>;
  questionTime?: Maybe<SlideTestTime>;
  userSettings?: Maybe<QuizUserSettings>;
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type QuizThemesPaginated = {
  __typename?: "QuizThemesPaginated";
  themes?: Maybe<Array<Maybe<QuizTheme>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type QuizUserSettings = {
  __typename?: "QuizUserSettings";
  id?: Maybe<Scalars["UUID"]>;
  userId?: Maybe<Scalars["UUID"]>;
  quizId?: Maybe<Scalars["UUID"]>;
  questionTime?: Maybe<SlideTestTime>;
};

export type QuoteCollection = {
  __typename?: "QuoteCollection";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  quotes?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export enum RedirectType {
  InviteUserToSchool = "INVITE_USER_TO_SCHOOL",
  InviteUserToCourse = "INVITE_USER_TO_COURSE",
  ParticipantAddedToPlan = "PARTICIPANT_ADDED_TO_PLAN",
  ConnectParentPupil = "CONNECT_PARENT_PUPIL",
  ConnectPupilToClassroom = "CONNECT_PUPIL_TO_CLASSROOM",
  ConnectPupilToGroup = "CONNECT_PUPIL_TO_GROUP",
  ConfirmPupilEmailByParent = "CONFIRM_PUPIL_EMAIL_BY_PARENT",
  ConfirmUserEmail = "CONFIRM_USER_EMAIL",
  ResetPassword = "RESET_PASSWORD",
  ShareProduct = "SHARE_PRODUCT",
  Registration = "REGISTRATION",
  Login = "LOGIN",
}

export type RedirectUrlData = {
  type: RedirectType;
  schoolId?: InputMaybe<Scalars["UUID"]>;
  role?: InputMaybe<UserRoleEnum>;
  classroomId?: InputMaybe<Scalars["UUID"]>;
  groupId?: InputMaybe<Scalars["UUID"]>;
};

export type RegisterEventUserInput = {
  email: Scalars["String"];
  password: Scalars["String"];
  countryId?: InputMaybe<Scalars["String"]>;
  birthday?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type Report = {
  __typename?: "Report";
  salary?: Maybe<Scalars["Int"]>;
  mainPart?: Maybe<Scalars["Int"]>;
  totalTime?: Maybe<Scalars["Int"]>;
  times?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type ReportByProjects = {
  __typename?: "ReportByProjects";
  projectName?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["Int"]>;
};

export type ReportByTasks = {
  __typename?: "ReportByTasks";
  taskName?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["Int"]>;
};

export enum ResourcesTypeEnum {
  Energy = "Energy",
  Experience = "Experience",
  EnergyAndExperience = "EnergyAndExperience",
}

export type ResumeSubscriptionResponse = {
  __typename?: "ResumeSubscriptionResponse";
  summary?: Maybe<Summary>;
  paymentProviders?: Maybe<Array<Maybe<PaymentProvider>>>;
  redirect_url?: Maybe<Scalars["String"]>;
  error?: Maybe<Scalars["String"]>;
};

export type Reward = {
  __typename?: "Reward";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Int"]>;
  order?: Maybe<Scalars["Int"]>;
  type?: Maybe<RewardTypeEnum>;
  resourcesType?: Maybe<ResourcesTypeEnum>;
  groupId?: Maybe<Scalars["String"]>;
  group?: Maybe<RewardGroup>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  updatedById?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type RewardGroup = {
  __typename?: "RewardGroup";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  rewards?: Maybe<Array<Maybe<Reward>>>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type RewardInput = {
  name?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<RewardTypeEnum>;
  resourcesType?: InputMaybe<ResourcesTypeEnum>;
  groupId?: InputMaybe<Scalars["String"]>;
};

export enum RewardTypeEnum {
  Attendance = "ATTENDANCE",
  Attention = "ATTENTION",
  AwardReceived = "AWARD_RECEIVED",
  ExperimentViewing = "EXPERIMENT_VIEWING",
  Joining = "JOINING",
  LessonRating = "LESSON_RATING",
  LessonViewing = "LESSON_VIEWING",
  LibraryLessonViewing = "LIBRARY_LESSON_VIEWING",
  LibraryMaterialViewing = "LIBRARY_MATERIAL_VIEWING",
  MaterialAdding = "MATERIAL_ADDING",
  ProfilePhotoUpload = "PROFILE_PHOTO_UPLOAD",
  SessionCompletion = "SESSION_COMPLETION",
  Subscription = "SUBSCRIPTION",
  TestCompletion = "TEST_COMPLETION",
}

export type RobotsHistory = {
  __typename?: "RobotsHistory";
  id?: Maybe<Scalars["UUID"]>;
  link?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type School = {
  __typename?: "School";
  id?: Maybe<Scalars["String"]>;
  idShort?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  workplaceName?: Maybe<Scalars["String"]>;
  shortName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  fundingType?: Maybe<SchoolFundingType>;
  isInternalUse?: Maybe<Scalars["Boolean"]>;
  isForTest?: Maybe<Scalars["Boolean"]>;
  street?: Maybe<Scalars["String"]>;
  house?: Maybe<Scalars["String"]>;
  addressIndex?: Maybe<Scalars["String"]>;
  country?: Maybe<SchoolCountry>;
  countryId?: Maybe<Scalars["String"]>;
  region?: Maybe<SchoolRegion>;
  regionId?: Maybe<Scalars["String"]>;
  district?: Maybe<SchoolDistrict>;
  districtId?: Maybe<Scalars["String"]>;
  city?: Maybe<SchoolCity>;
  cityId?: Maybe<Scalars["String"]>;
  communityId?: Maybe<Scalars["UUID"]>;
  community?: Maybe<TerritorialCommunity>;
  photo?: Maybe<Scalars["String"]>;
  schoolSettings?: Maybe<Array<Maybe<SchoolSettings>>>;
  teachers?: Maybe<Array<Maybe<User>>>;
  admins?: Maybe<Array<Maybe<User>>>;
  edrpou?: Maybe<Scalars["String"]>;
  isVerified?: Maybe<Scalars["Boolean"]>;
  isPersonal?: Maybe<Scalars["Boolean"]>;
  personalFor?: Maybe<PersonalForEnum>;
  classrooms?: Maybe<Array<Maybe<SchoolClassroom>>>;
  educationPlans?: Maybe<Array<Maybe<EducationPlan>>>;
  manager?: Maybe<User>;
  managerId?: Maybe<Scalars["String"]>;
  subscriptionInfo?: Maybe<SubscriptionInfo>;
  groups?: Maybe<Array<Maybe<Classroom>>>;
  planUsers?: Maybe<SchoolPlanUsers>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  teachersCount?: Maybe<Scalars["Int"]>;
  pupilsCount?: Maybe<Scalars["Int"]>;
  subscriptionStatus?: Maybe<SchoolsSubscriptionStatus>;
  subscriptionExpiredDate?: Maybe<Scalars["Date"]>;
  isTrialSubscription?: Maybe<Scalars["Boolean"]>;
  whiteLabelCollectionId?: Maybe<Scalars["UUID"]>;
  whiteLabelCollection?: Maybe<WhiteLabelCollection>;
};

export type SchoolCity = {
  __typename?: "SchoolCity";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  schools?: Maybe<Array<Maybe<School>>>;
  district?: Maybe<SchoolDistrict>;
  districtId?: Maybe<Scalars["String"]>;
  region?: Maybe<SchoolRegion>;
  regionId?: Maybe<Scalars["String"]>;
  users?: Maybe<Array<Maybe<User>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type SchoolClassroom = {
  __typename?: "SchoolClassroom";
  id?: Maybe<Scalars["String"]>;
  grade?: Maybe<Scalars["Int"]>;
  letter?: Maybe<Scalars["String"]>;
  school?: Maybe<School>;
  schoolId?: Maybe<Scalars["String"]>;
  pupils?: Maybe<Array<Maybe<User>>>;
  graduatedAt?: Maybe<Scalars["Int"]>;
  isArchived?: Maybe<Scalars["Boolean"]>;
  classTeacher?: Maybe<User>;
  classTeacherId?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type SchoolClassroomsAndClassroomsForFilter = {
  __typename?: "SchoolClassroomsAndClassroomsForFilter";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type SchoolCountry = {
  __typename?: "SchoolCountry";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nameEN?: Maybe<Scalars["String"]>;
  nameUKR?: Maybe<Scalars["String"]>;
  alpha2?: Maybe<Scalars["String"]>;
  alpha3?: Maybe<Scalars["String"]>;
  dialCode?: Maybe<Scalars["String"]>;
  regions?: Maybe<Array<Maybe<SchoolRegion>>>;
  users?: Maybe<Array<Maybe<User>>>;
  schools?: Maybe<Array<Maybe<School>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type SchoolDistrict = {
  __typename?: "SchoolDistrict";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  cities?: Maybe<Array<Maybe<SchoolCity>>>;
  region?: Maybe<SchoolRegion>;
  regionId?: Maybe<Scalars["String"]>;
  schools?: Maybe<Array<Maybe<School>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type SchoolEvent = {
  __typename?: "SchoolEvent";
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<SchoolEventTypeEnum>;
  title?: Maybe<Scalars["String"]>;
  dateFrom?: Maybe<Scalars["Date"]>;
  dateTo?: Maybe<Scalars["Date"]>;
  appointment?: Maybe<Scalars["String"]>;
  format?: Maybe<SchoolEventFormatEnum>;
  schoolId?: Maybe<Scalars["String"]>;
  school?: Maybe<School>;
  applicationMainId?: Maybe<Scalars["String"]>;
  applicationMain?: Maybe<ApplicationMain>;
  educationPlanId?: Maybe<Scalars["String"]>;
  educationPlan?: Maybe<EducationPlan>;
  lessonId?: Maybe<Scalars["String"]>;
  lesson?: Maybe<NewLesson>;
  groups?: Maybe<Array<Maybe<Classroom>>>;
  canDelete?: Maybe<Scalars["Boolean"]>;
  canEdit?: Maybe<Scalars["Boolean"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type SchoolEventClassroomInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export enum SchoolEventFormatEnum {
  Offline = "OFFLINE",
  Online = "ONLINE",
}

export enum SchoolEventTypeEnum {
  Lesson = "LESSON",
  Other = "OTHER",
}

export enum SchoolFundingType {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type SchoolGrade = {
  __typename?: "SchoolGrade";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  backgroundColor?: Maybe<Scalars["String"]>;
};

export type SchoolGradesPaginated = {
  __typename?: "SchoolGradesPaginated";
  schoolGrades?: Maybe<Array<Maybe<SchoolGrade>>>;
  total?: Maybe<Scalars["Int"]>;
};

export enum SchoolParticipantSortKey {
  All = "ALL",
  Teachers = "TEACHERS",
  Pupils = "PUPILS",
}

export type SchoolParticipantsPaginated = {
  __typename?: "SchoolParticipantsPaginated";
  total?: Maybe<Scalars["Int"]>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type SchoolPayer = {
  __typename?: "SchoolPayer";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  createdById?: Maybe<Scalars["UUID"]>;
  createdBy?: Maybe<User>;
  updatedById?: Maybe<Scalars["UUID"]>;
  updatedBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type SchoolPayerInput = {
  name?: InputMaybe<Scalars["String"]>;
};

export type SchoolPlanUsers = {
  __typename?: "SchoolPlanUsers";
  teachers?: Maybe<SchoolPlanUsersCount>;
  pupils?: Maybe<SchoolPlanUsersCount>;
  parents?: Maybe<SchoolPlanUsersCount>;
  admins?: Maybe<SchoolPlanUsersCount>;
  all?: Maybe<SchoolPlanUsersCount>;
};

export type SchoolPlanUsersCount = {
  __typename?: "SchoolPlanUsersCount";
  plan?: Maybe<Scalars["Int"]>;
  current?: Maybe<Scalars["Int"]>;
  currentPercent?: Maybe<Scalars["Float"]>;
};

export type SchoolRegion = {
  __typename?: "SchoolRegion";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  cities?: Maybe<Array<Maybe<SchoolCity>>>;
  districts?: Maybe<Array<Maybe<SchoolDistrict>>>;
  countryId?: Maybe<Scalars["String"]>;
  country?: Maybe<SchoolCountry>;
  schools?: Maybe<Array<Maybe<School>>>;
  users?: Maybe<Array<Maybe<User>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type SchoolSettings = {
  __typename?: "SchoolSettings";
  id?: Maybe<Scalars["String"]>;
  school?: Maybe<School>;
  schoolId?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]>;
  role?: Maybe<UserRoleEnum>;
  status?: Maybe<SchoolUserStatusEnum>;
  teacherPosition?: Maybe<Scalars["String"]>;
  teachingFormat?: Maybe<TeachingFormatEnum>;
  subjects?: Maybe<Array<Maybe<ApplicationMain>>>;
  selectedSubjects?: Maybe<Array<Maybe<ApplicationMain>>>;
  schoolGrades?: Maybe<Array<Maybe<SchoolGrade>>>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export enum SchoolUserStatusEnum {
  Teacher = "TEACHER",
  Admin = "ADMIN",
  Pupil = "PUPIL",
}

export enum SchoolsSortType {
  Subscription = "SUBSCRIPTION",
}

export enum SchoolsSubscriptionStatus {
  Active = "ACTIVE",
  Finished = "FINISHED",
  NoSubscriptionHistory = "NO_SUBSCRIPTION_HISTORY",
}

export type SessionHistory = {
  __typename?: "SessionHistory";
  id?: Maybe<Scalars["String"]>;
  isFrom?: Maybe<IsFromEnum>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type SetKnowledgeBaseCategoryParticipantsInput = {
  id: Scalars["String"];
  availableFor?: InputMaybe<AvailableForEnum>;
  participants?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type SetMobileNotificationParticipantsInput = {
  notificationId: Scalars["String"];
  availableFor?: InputMaybe<AvailableForMobileNotificationEnum>;
  participants?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type SetNewsParticipantsInput = {
  id: Scalars["String"];
  availableFor?: InputMaybe<AvailableForEnum>;
  participants?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type SetNotificationParticipantsInput = {
  id: Scalars["String"];
  availableFor?: InputMaybe<AvailableForEnum>;
  participants?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type SetQrCustomInput = {
  string?: InputMaybe<Scalars["String"]>;
  dotColor?: InputMaybe<Scalars["String"]>;
  backgroundColor?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type SetSmartNotificationParticipantsInput = {
  notificationId: Scalars["String"];
  availableFor?: InputMaybe<AvailableForSmartNotificationEnum>;
  participants?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type Slide = {
  __typename?: "Slide";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  contentType?: Maybe<SlideContentType>;
  player?: Maybe<SlidePlayer>;
  image?: Maybe<Scalars["String"]>;
  imageName?: Maybe<Scalars["String"]>;
  images?: Maybe<Image>;
  videoUrl?: Maybe<Scalars["String"]>;
  pageUrl?: Maybe<Scalars["String"]>;
  slideElementsData?: Maybe<Scalars["String"]>;
  experimentUrl?: Maybe<Scalars["String"]>;
  experimentHtml?: Maybe<Scalars["String"]>;
  experimentId?: Maybe<Scalars["String"]>;
  experiment?: Maybe<ApplicationMainModel>;
  text?: Maybe<Scalars["String"]>;
  testType?: Maybe<SlideTestType>;
  testChoices?: Maybe<Array<Maybe<SlideTestChoice>>>;
  testScore?: Maybe<Scalars["Int"]>;
  testTime?: Maybe<SlideTestTime>;
  showCorrectAnswer?: Maybe<Scalars["Boolean"]>;
  testTimeSec?: Maybe<Scalars["Int"]>;
  lessonId?: Maybe<Scalars["String"]>;
  lesson?: Maybe<NewLesson>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  slideReflections?: Maybe<Array<Maybe<SlideReflection>>>;
  slideTestAnswers?: Maybe<Array<Maybe<SlideTestAnswer>>>;
  correctAnswer?: Maybe<Scalars["String"]>;
  slideReflectionPercent?: Maybe<Scalars["Int"]>;
  parentId?: Maybe<Scalars["String"]>;
  isUploadedToMarket?: Maybe<Scalars["Boolean"]>;
  isFromMarket?: Maybe<Scalars["Boolean"]>;
  userFavorites?: Maybe<Array<Maybe<User>>>;
  shuffleAnswers?: Maybe<Scalars["Boolean"]>;
  metadata?: Maybe<Scalars["Metadata"]>;
  webGl?: Maybe<WebGlBundleData>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
  isOnlyImages?: Maybe<Scalars["Boolean"]>;
};

export type SlideBackground = {
  __typename?: "SlideBackground";
  id?: Maybe<Scalars["UUID"]>;
  collectionId?: Maybe<Scalars["UUID"]>;
  previewUrl?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type SlideBackgroundCollection = {
  __typename?: "SlideBackgroundCollection";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  previewUrl?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
};

export type SlideBackgroundCollectionPaginated = {
  __typename?: "SlideBackgroundCollectionPaginated";
  collections?: Maybe<Array<Maybe<SlideBackgroundCollection>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type SlideBackgroundsPaginated = {
  __typename?: "SlideBackgroundsPaginated";
  urls?: Maybe<Array<Maybe<Scalars["String"]>>>;
  continueToken?: Maybe<Scalars["String"]>;
};

export enum SlideContentType {
  Slide = "SLIDE",
  SlideEditor = "SLIDE_EDITOR",
  Image = "IMAGE",
  Text = "TEXT",
  Video = "VIDEO",
  Test = "TEST",
  Experiment = "EXPERIMENT",
  Vr = "VR",
  Link = "LINK",
  Experiment360 = "EXPERIMENT360",
  ExperimentVr = "EXPERIMENT_VR",
  ExperimentPhet = "EXPERIMENT_PHET",
  InteractiveExercise = "INTERACTIVE_EXERCISE",
  InteractiveTextbook = "INTERACTIVE_TEXTBOOK",
}

export type SlideInput = {
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  slideElementsData?: InputMaybe<Scalars["String"]>;
  contentType?: InputMaybe<SlideContentType>;
  player?: InputMaybe<SlidePlayer>;
  image?: InputMaybe<Scalars["String"]>;
  imageName?: InputMaybe<Scalars["String"]>;
  videoUrl?: InputMaybe<Scalars["String"]>;
  pageUrl?: InputMaybe<Scalars["String"]>;
  experimentUrl?: InputMaybe<Scalars["String"]>;
  experimentHtml?: InputMaybe<Scalars["String"]>;
  experimentId?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  testChoices?: InputMaybe<Array<InputMaybe<SlideTestChoiceInput>>>;
  testType?: InputMaybe<SlideTestType>;
  showCorrectAnswer?: InputMaybe<Scalars["Boolean"]>;
  testScore?: InputMaybe<Scalars["Int"]>;
  testTime?: InputMaybe<SlideTestTimeInput>;
  testTimeSec?: InputMaybe<Scalars["Int"]>;
  shuffleAnswers?: InputMaybe<Scalars["Boolean"]>;
};

export enum SlidePlayer {
  Youtube = "YOUTUBE",
  Kyivstar = "KYIVSTAR",
}

export type SlideReflection = {
  __typename?: "SlideReflection";
  id?: Maybe<Scalars["String"]>;
  slide?: Maybe<Slide>;
  slideId?: Maybe<Scalars["String"]>;
  testChoice?: Maybe<SlideTestChoice>;
  testChoiceId?: Maybe<Scalars["String"]>;
  isItClear?: Maybe<Scalars["Boolean"]>;
  clicks?: Maybe<Scalars["Int"]>;
  progressPercent?: Maybe<Scalars["Int"]>;
  time?: Maybe<Scalars["Int"]>;
  slideTestAnswers?: Maybe<Array<Maybe<SlideTestAnswer>>>;
  fullName?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  openMark?: Maybe<Scalars["Int"]>;
  openAnswer?: Maybe<Scalars["String"]>;
  stars?: Maybe<Scalars["Int"]>;
  experimentResult?: Maybe<Scalars["Float"]>;
};

export type SlideStickersPaginated = {
  __typename?: "SlideStickersPaginated";
  urls?: Maybe<Array<Maybe<Scalars["String"]>>>;
  continueToken?: Maybe<Scalars["String"]>;
};

export type SlideTestAnswer = {
  __typename?: "SlideTestAnswer";
  id?: Maybe<Scalars["String"]>;
  slide?: Maybe<Slide>;
  slideId?: Maybe<Scalars["String"]>;
  testChoice?: Maybe<SlideTestChoice>;
  testChoiceId?: Maybe<Scalars["String"]>;
  slideReflection?: Maybe<SlideReflection>;
  slideReflectionId?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type SlideTestChoice = {
  __typename?: "SlideTestChoice";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  isCorrect?: Maybe<Scalars["Boolean"]>;
  order?: Maybe<Scalars["Int"]>;
  slideId?: Maybe<Scalars["String"]>;
  slide?: Maybe<Slide>;
  slideReflections?: Maybe<Array<Maybe<SlideReflection>>>;
  slideTestAnswers?: Maybe<Array<Maybe<SlideTestAnswer>>>;
  questionId?: Maybe<Scalars["String"]>;
  question?: Maybe<SlideTestChoiceConformity>;
  answerId?: Maybe<Scalars["String"]>;
  answer?: Maybe<SlideTestChoiceConformity>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type SlideTestChoiceConformity = {
  __typename?: "SlideTestChoiceConformity";
  id?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  type?: Maybe<SlideTestConformityType>;
  testChoiceId?: Maybe<Scalars["String"]>;
  testChoice?: Maybe<SlideTestChoice>;
  questionId?: Maybe<Scalars["String"]>;
  question?: Maybe<SlideTestChoiceConformity>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type SlideTestChoiceConformityInput = {
  id?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<SlideTestConformityType>;
  testChoice?: InputMaybe<SlideTestChoiceInput>;
  questionId?: InputMaybe<Scalars["String"]>;
  file?: InputMaybe<Scalars["Upload"]>;
};

export type SlideTestChoiceInput = {
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  file?: InputMaybe<Scalars["Upload"]>;
  image?: InputMaybe<Scalars["String"]>;
  isCorrect?: InputMaybe<Scalars["Boolean"]>;
  order?: InputMaybe<Scalars["Int"]>;
  slideId?: InputMaybe<Scalars["String"]>;
  question?: InputMaybe<SlideTestChoiceConformityInput>;
  answer?: InputMaybe<SlideTestChoiceConformityInput>;
};

export enum SlideTestConformityType {
  Question = "QUESTION",
  Answer = "ANSWER",
}

export type SlideTestTime = {
  __typename?: "SlideTestTime";
  min?: Maybe<Scalars["Int"]>;
  sec?: Maybe<Scalars["Int"]>;
};

export type SlideTestTimeInput = {
  min?: InputMaybe<Scalars["Int"]>;
  sec?: InputMaybe<Scalars["Int"]>;
};

export enum SlideTestType {
  Solo = "SOLO",
  Multi = "MULTI",
  Conformity = "CONFORMITY",
  OpenQuestion = "OPEN_QUESTION",
}

export type SlidesPaginated = {
  __typename?: "SlidesPaginated";
  slides?: Maybe<Array<Maybe<Slide>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type SmartNotification = {
  __typename?: "SmartNotification";
  id?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  type?: Maybe<SmartNotificationTypeEnum>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["Date"]>;
  unpublishedAt?: Maybe<Scalars["Date"]>;
  photo?: Maybe<Scalars["String"]>;
  photoName?: Maybe<Scalars["String"]>;
  pollLists?: Maybe<Array<Maybe<SmartNotificationPollList>>>;
  answers?: Maybe<Array<Maybe<SmartNotificationAnswer>>>;
  application?: Maybe<Application>;
  applicationId?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  video?: Maybe<Scalars["String"]>;
  participants?: Maybe<Array<Maybe<SmartNotificationParticipant>>>;
  availableFor?: Maybe<AvailableForSmartNotificationEnum>;
  successParticipantsCount?: Maybe<Scalars["Int"]>;
  watchParticipantsCount?: Maybe<Scalars["Int"]>;
  testId?: Maybe<Scalars["String"]>;
  test?: Maybe<Test>;
  applicationMainModelId?: Maybe<Scalars["String"]>;
  applicationMainModel?: Maybe<ApplicationMainModel>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type SmartNotificationAnswer = {
  __typename?: "SmartNotificationAnswer";
  id?: Maybe<Scalars["String"]>;
  ratting?: Maybe<Scalars["Int"]>;
  notificationId?: Maybe<Scalars["String"]>;
  notification?: Maybe<SmartNotification>;
  pollListId?: Maybe<Scalars["String"]>;
  pollList?: Maybe<SmartNotificationPollList>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type SmartNotificationAnswersWithPercents = {
  __typename?: "SmartNotificationAnswersWithPercents";
  ratting?: Maybe<Scalars["Int"]>;
  pollListId?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
  percents?: Maybe<Scalars["Int"]>;
};

export type SmartNotificationByParticipant = {
  __typename?: "SmartNotificationByParticipant";
  notification?: Maybe<SmartNotification>;
  isViewed?: Maybe<Scalars["Boolean"]>;
};

export type SmartNotificationParticipant = {
  __typename?: "SmartNotificationParticipant";
  id?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  notification?: Maybe<SmartNotification>;
  isWatched?: Maybe<Scalars["Boolean"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type SmartNotificationPollList = {
  __typename?: "SmartNotificationPollList";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  notificationId?: Maybe<Scalars["String"]>;
  notification?: Maybe<SmartNotification>;
  answers?: Maybe<Array<Maybe<SmartNotificationAnswer>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export enum SmartNotificationTypeEnum {
  Rating = "RATING",
  Poll = "POLL",
  Link = "LINK",
  ContentRating = "CONTENT_RATING",
}

export enum SortCommandEnum {
  Desc = "DESC",
  Asc = "ASC",
}

export type SortInput = {
  name?: InputMaybe<SortCommandEnum>;
  createdDate?: InputMaybe<SortCommandEnum>;
};

export enum SortTypeEnum {
  Name = "NAME",
  Date = "DATE",
}

export enum SpaceTypeAdminReportEnum {
  Personal = "PERSONAL",
  School = "SCHOOL",
}

export type SpecialLessonMark = {
  __typename?: "SpecialLessonMark";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<SpecialLessonMarkEnum>;
  order?: Maybe<Scalars["Int"]>;
};

export enum SpecialLessonMarkEnum {
  Elementary = "ELEMENTARY",
  Average = "AVERAGE",
  Sufficient = "SUFFICIENT",
  High = "HIGH",
}

export type StatusReport = {
  __typename?: "StatusReport";
  status?: Maybe<Scalars["String"]>;
  projectsTypesPercent?: Maybe<Scalars["Float"]>;
};

export type StickerCollection = {
  __typename?: "StickerCollection";
  name: Scalars["String"];
  preview: Scalars["String"];
};

export type StickerCollectionPaginated = {
  __typename?: "StickerCollectionPaginated";
  name: Scalars["String"];
  preview: Scalars["String"];
  continueToken?: Maybe<Scalars["String"]>;
  urls: Array<Maybe<Scalars["String"]>>;
};

export type StripePayload = {
  amount: Scalars["Float"];
  productName: Scalars["String"];
  successUrl: Scalars["String"];
  cancelUrl: Scalars["String"];
  declineUrl: Scalars["String"];
  paymentId: Scalars["String"];
  lessonId?: InputMaybe<Scalars["String"]>;
  slideId?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type SubjectWithNewLessons = {
  __typename?: "SubjectWithNewLessons";
  subject?: Maybe<ApplicationMain>;
  lessons?: Maybe<Array<Maybe<UserLibraryContent>>>;
  totalLessons?: Maybe<Scalars["Int"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  activePupilsChanged?: Maybe<Scalars["String"]>;
  workflowStepChanged?: Maybe<Scalars["String"]>;
  qrCodeExpired?: Maybe<Scalars["Boolean"]>;
  userEventChanged?: Maybe<Array<Maybe<UserEvent>>>;
};

export type SubscriptionActivePupilsChangedArgs = {
  educationPlanId: Scalars["String"];
};

export type SubscriptionWorkflowStepChangedArgs = {
  projectId: Scalars["String"];
};

export type SubscriptionQrCodeExpiredArgs = {
  sessionId: Scalars["UUID"];
};

export type SubscriptionInfo = {
  __typename?: "SubscriptionInfo";
  plan?: Maybe<SubscriptionPlan>;
  payment?: Maybe<Payment>;
  expiredDate?: Maybe<Scalars["Date"]>;
  trialPeriodExpiredDate?: Maybe<Scalars["Date"]>;
  subscriptionPeriodExpiredDate?: Maybe<Scalars["Date"]>;
  isTrial?: Maybe<Scalars["Boolean"]>;
  subscriptions?: Maybe<Scalars["Int"]>;
  price?: Maybe<Scalars["Float"]>;
  nextPayment?: Maybe<Scalars["Float"]>;
  subscriptionPeriod?: Maybe<PaymentPeriod>;
  isShowSubscriptionBanner?: Maybe<Scalars["Boolean"]>;
  isBasePlanAfterSubscription?: Maybe<Scalars["Boolean"]>;
  isBasePlanAfterTrial?: Maybe<Scalars["Boolean"]>;
  isSpecialPeriodBeforeEndTrial?: Maybe<Scalars["Boolean"]>;
  isSpecialPeriodBeforeEndSubscription?: Maybe<Scalars["Boolean"]>;
  daysLeft?: Maybe<Scalars["Int"]>;
  planData?: Maybe<TeacherPlanData>;
  pupilsCount?: Maybe<Scalars["Int"]>;
  pupilsAllowedByPlan?: Maybe<Scalars["Int"]>;
  canAddPupil?: Maybe<Scalars["Boolean"]>;
  /** @deprecated No longer supported */
  isCanceled?: Maybe<Scalars["Boolean"]>;
  canChange?: Maybe<Scalars["Boolean"]>;
  canCancel?: Maybe<Scalars["Boolean"]>;
  canResume?: Maybe<Scalars["Boolean"]>;
  canCreate?: Maybe<Scalars["Boolean"]>;
  promotionCode?: Maybe<Scalars["String"]>;
  nextPlanedSubscription?: Maybe<Payment>;
};

export type SubscriptionPlan = {
  __typename?: "SubscriptionPlan";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<SubscriptionPlanNameEnum>;
  description?: Maybe<Scalars["String"]>;
  additionalDescription?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  trialPeriod?: Maybe<Scalars["Int"]>;
  price?: Maybe<Scalars["Float"]>;
  fakePrice?: Maybe<Scalars["Float"]>;
  isBestseller?: Maybe<Scalars["Boolean"]>;
  mainColor?: Maybe<Scalars["String"]>;
  subColor?: Maybe<Scalars["String"]>;
  monthsCount?: Maybe<Scalars["Int"]>;
  features?: Maybe<Array<Maybe<SubscriptionPlanFeature>>>;
  order?: Maybe<Scalars["Int"]>;
  pupilsCount?: Maybe<Scalars["Int"]>;
  lessonsCount?: Maybe<Scalars["Int"]>;
  storageSpace?: Maybe<Scalars["Int"]>;
  allowedContentStatus?: Maybe<SubscriptionPlanStatusEnum>;
  version?: Maybe<SubscriptionPlanVersionEnum>;
  type?: Maybe<SubscriptionPlanTypeEnum>;
  discount?: Maybe<Scalars["Int"]>;
  planPriceForOneMonth?: Maybe<Scalars["Int"]>;
  isCurrentUserPlan?: Maybe<Scalars["Boolean"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export enum SubscriptionPlanCoursesEnum {
  Visible = "VISIBLE",
  Hidden = "HIDDEN",
  Disabled = "DISABLED",
}

export type SubscriptionPlanFeature = {
  __typename?: "SubscriptionPlanFeature";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  subscriptionPlans?: Maybe<Array<Maybe<SubscriptionPlan>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type SubscriptionPlanGroup = {
  __typename?: "SubscriptionPlanGroup";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<SubscriptionPlanGroupNameEnum>;
  subscriptionPlans?: Maybe<Array<Maybe<TransformSubscriptionPlan>>>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  userRoleId?: Maybe<Scalars["Int"]>;
  backGroundColor?: Maybe<Scalars["String"]>;
  iconUrl?: Maybe<Scalars["String"]>;
  level?: Maybe<Scalars["Int"]>;
  features?: Maybe<Array<Maybe<SubscriptionPlanGroupFeature>>>;
  isFocused?: Maybe<Scalars["Boolean"]>;
};

export type SubscriptionPlanGroupFeature = {
  __typename?: "SubscriptionPlanGroupFeature";
  id?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export enum SubscriptionPlanGroupNameEnum {
  Teacher = "TEACHER",
  TeacherPlus = "TEACHER_PLUS",
  Pupil = "PUPIL",
  Parent = "PARENT",
  School = "SCHOOL",
}

export enum SubscriptionPlanNameEnum {
  BaseV2 = "BASE_V2",
  Teacher = "TEACHER",
  TeacherPlus = "TEACHER_PLUS",
  School = "SCHOOL",
  SchoolPlus = "SCHOOL_PLUS",
  PremiumPlus = "PREMIUM_PLUS",
  Base = "BASE",
  Standard = "STANDARD",
  Individual = "INDIVIDUAL",
  SchoolMini = "SCHOOL_MINI",
  Community = "COMMUNITY",
  City = "CITY",
  Country = "COUNTRY",
  Parent = "PARENT",
  Pupil = "PUPIL",
  TeacherPlusOne = "TEACHER_PLUS_ONE",
  TeacherPlusSix = "TEACHER_PLUS_SIX",
  TeacherPlusTwelve = "TEACHER_PLUS_TWELVE",
  PupilOne = "PUPIL_ONE",
  PupilSix = "PUPIL_SIX",
  PupilTwelve = "PUPIL_TWELVE",
  TeacherOne = "TEACHER_ONE",
  TeacherSix = "TEACHER_SIX",
  TeacherTwelve = "TEACHER_TWELVE",
  ParentOne = "PARENT_ONE",
  ParentSix = "PARENT_SIX",
  ParentTwelve = "PARENT_TWELVE",
}

export type SubscriptionPlanPaymentData = {
  planId: Scalars["String"];
  schoolId: Scalars["String"];
  paymentDate: Scalars["Date"];
  validity: Scalars["Int"];
  paymentType?: InputMaybe<PaymentType>;
  paymentTypeId?: InputMaybe<Scalars["UUID"]>;
  pupilsCount?: InputMaybe<Scalars["Int"]>;
  teachersCount?: InputMaybe<Scalars["Int"]>;
  total?: InputMaybe<Scalars["Float"]>;
  isTrial?: InputMaybe<Scalars["Boolean"]>;
  isActivated?: InputMaybe<Scalars["Boolean"]>;
  paymentFrom?: InputMaybe<PaymentFromType>;
  payerId?: InputMaybe<Scalars["UUID"]>;
};

export enum SubscriptionPlanPaymentTypeEnum {
  Paid = "PAID",
  Free = "FREE",
}

export enum SubscriptionPlanStatusEnum {
  Unauthorized = "UNAUTHORIZED",
  Authorized = "AUTHORIZED",
  Paid = "PAID",
  Purchases = "PURCHASES",
}

export enum SubscriptionPlanTypeEnum {
  ForTeacher = "FOR_TEACHER",
  ForPupil = "FOR_PUPIL",
  ForParent = "FOR_PARENT",
  ForSchool = "FOR_SCHOOL",
}

export enum SubscriptionPlanVersionEnum {
  V1 = "V1",
  V2 = "V2",
  V3 = "V3",
}

export type SubscriptionSalesInput = {
  title: Scalars["String"];
  fullName?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  phone: Scalars["String"];
  schoolName?: InputMaybe<Scalars["String"]>;
};

export type SuggestedSlideTestChoice = {
  __typename?: "SuggestedSlideTestChoice";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  isCorrect?: Maybe<Scalars["Boolean"]>;
  question?: Maybe<SuggestedSlideTestConformity>;
  answer?: Maybe<SuggestedSlideTestConformity>;
};

export type SuggestedSlideTestConformity = {
  __typename?: "SuggestedSlideTestConformity";
  id: Scalars["String"];
  type?: Maybe<SlideTestConformityType>;
  text?: Maybe<Scalars["String"]>;
};

export type SuggestedSlideTests = {
  __typename?: "SuggestedSlideTests";
  name?: Maybe<Scalars["String"]>;
  testType?: Maybe<SlideTestType>;
  testChoices?: Maybe<Array<Maybe<SuggestedSlideTestChoice>>>;
};

export type Summary = {
  __typename?: "Summary";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  monthsCount?: Maybe<Scalars["Int"]>;
  price?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  discountPromotionType?: Maybe<PromotionTypeEnum>;
  discountPercents?: Maybe<Scalars["Float"]>;
  total?: Maybe<Scalars["Float"]>;
  showUpgradeInfo?: Maybe<Scalars["Boolean"]>;
};

export type SummaryResponse = {
  __typename?: "SummaryResponse";
  summary?: Maybe<Summary>;
  paymentProviders?: Maybe<Array<Maybe<PaymentProvider>>>;
};

export type SyncProject = {
  __typename?: "SyncProject";
  tasksToAdd?: Maybe<Scalars["Int"]>;
  tasksToDelete?: Maybe<Scalars["Int"]>;
  tasksToUpdate?: Maybe<Scalars["Int"]>;
};

export type TagType = {
  __typename?: "TagType";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  contentTag?: Maybe<Array<Maybe<ContentTag>>>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type TaskName = {
  __typename?: "TaskName";
  name?: Maybe<Scalars["String"]>;
};

export type TaskWithCompletionPct = {
  __typename?: "TaskWithCompletionPct";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  assigneeName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  epicPrc?: Maybe<Scalars["Float"]>;
  projectPrc?: Maybe<Scalars["Float"]>;
};

export type TaskWithCost = {
  __typename?: "TaskWithCost";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  assigneeName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  costEstimate?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
  difference?: Maybe<Scalars["Int"]>;
};

export type TaskWithDate = {
  __typename?: "TaskWithDate";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  assigneeName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  startDateEstimate?: Maybe<Scalars["String"]>;
  startDateFact?: Maybe<Scalars["String"]>;
  startDifference?: Maybe<Scalars["Int"]>;
  finishDateEstimate?: Maybe<Scalars["String"]>;
  finishDateFact?: Maybe<Scalars["String"]>;
  finishDifference?: Maybe<Scalars["Int"]>;
};

export type TaskWithTime = {
  __typename?: "TaskWithTime";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  assigneeName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  timeEstimate?: Maybe<Scalars["Int"]>;
  timeSpent?: Maybe<Scalars["Int"]>;
};

export type TasksWithProblems = {
  __typename?: "TasksWithProblems";
  done?: Maybe<Array<Maybe<DoneProblems>>>;
  dateEstimate?: Maybe<Array<Maybe<DateEstimateProblems>>>;
  assignee?: Maybe<Array<Maybe<AssigneeProblems>>>;
  noEpic?: Maybe<Array<Maybe<NoEpicProblems>>>;
};

export type TeacherPlanData = {
  __typename?: "TeacherPlanData";
  id?: Maybe<Scalars["String"]>;
  teacherId?: Maybe<Scalars["String"]>;
  teacher?: Maybe<User>;
  planName?: Maybe<SubscriptionPlanNameEnum>;
  subscriptionPeriod?: Maybe<PaymentPeriod>;
  subscriptions?: Maybe<Scalars["Int"]>;
  validity?: Maybe<Scalars["Int"]>;
  price?: Maybe<Scalars["Float"]>;
};

export enum TeacherPupilRoleEnum {
  All = "ALL",
  Teacher = "TEACHER",
  Pupil = "PUPIL",
}

export type TeacherSchoolReport = {
  __typename?: "TeacherSchoolReport";
  id?: Maybe<Scalars["String"]>;
  reportToId?: Maybe<Scalars["String"]>;
  reportTo?: Maybe<User>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export enum TeacherStatusEnum {
  Teacher = "TEACHER",
  Admin = "ADMIN",
}

export enum TeachingFormatEnum {
  Online = "ONLINE",
  Offline = "OFFLINE",
  Mixed = "MIXED",
}

export type TerritorialCommunity = {
  __typename?: "TerritorialCommunity";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  cityId?: Maybe<Scalars["UUID"]>;
  districtId?: Maybe<Scalars["UUID"]>;
  regionId?: Maybe<Scalars["UUID"]>;
  countryId?: Maybe<Scalars["UUID"]>;
  createdById?: Maybe<Scalars["UUID"]>;
  createdBy?: Maybe<User>;
  updatedById?: Maybe<Scalars["UUID"]>;
  updatedBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
  communityType?: Maybe<Scalars["String"]>;
};

export type TerritorialCommunityInput = {
  name?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["UUID"]>;
};

export type Test = {
  __typename?: "Test";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  questions?: Maybe<Array<Maybe<Question>>>;
  modelId?: Maybe<Scalars["String"]>;
  model?: Maybe<ApplicationMainModel>;
  mainId?: Maybe<Scalars["String"]>;
  main?: Maybe<ApplicationMain>;
  themeId?: Maybe<Scalars["String"]>;
  theme?: Maybe<ApplicationMainTheme>;
  classroomId?: Maybe<Scalars["String"]>;
  classroom?: Maybe<Classroom>;
  isOpenTest?: Maybe<Scalars["Boolean"]>;
  attempts?: Maybe<Scalars["Int"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["Date"]>;
  time?: Maybe<Scalars["Int"]>;
  class?: Maybe<TestClassEnum>;
  testAnswers?: Maybe<Array<Maybe<TestAnswer>>>;
  repeatAttemptTime?: Maybe<Scalars["Int"]>;
  showSmartNotification?: Maybe<Scalars["Boolean"]>;
  smartNotificationId?: Maybe<Scalars["String"]>;
  smartNotification?: Maybe<SmartNotification>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type TestAnswer = {
  __typename?: "TestAnswer";
  id?: Maybe<Scalars["String"]>;
  testId?: Maybe<Scalars["String"]>;
  test?: Maybe<Test>;
  answers?: Maybe<Array<Maybe<Answer>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type TestAnswersForApp = {
  __typename?: "TestAnswersForApp";
  questions?: Maybe<Scalars["Int"]>;
  answers?: Maybe<Scalars["Int"]>;
  attempt?: Maybe<Scalars["Int"]>;
  rightAnswers?: Maybe<Scalars["Int"]>;
};

export enum TestClassEnum {
  Class_7 = "CLASS_7",
  Class_8 = "CLASS_8",
  Class_9 = "CLASS_9",
  Class_10 = "CLASS_10",
  Class_11 = "CLASS_11",
}

export type TestForApp = {
  __typename?: "TestForApp";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  questions?: Maybe<Array<Maybe<Question>>>;
  modelId?: Maybe<Scalars["String"]>;
  mainId?: Maybe<Scalars["String"]>;
  themeId?: Maybe<Scalars["String"]>;
  classroomId?: Maybe<Scalars["String"]>;
  isOpenTest?: Maybe<Scalars["Boolean"]>;
  attempts?: Maybe<Scalars["Int"]>;
  time?: Maybe<Scalars["Int"]>;
  createdBy?: Maybe<User>;
  rightQuestions?: Maybe<Scalars["Int"]>;
  questionsLength?: Maybe<Scalars["Int"]>;
  class?: Maybe<Scalars["Int"]>;
  lastAttemptDate?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
};

export type TestSlideAnswers = {
  __typename?: "TestSlideAnswers";
  slide?: Maybe<Slide>;
  totalMark?: Maybe<Scalars["Int"]>;
  userMark?: Maybe<Scalars["Float"]>;
  percents?: Maybe<Scalars["Int"]>;
  openAnswer?: Maybe<Scalars["String"]>;
  status?: Maybe<TestSlideResultStatusEnum>;
};

export enum TestSlideResultStatusEnum {
  Ignored = "IGNORED",
  Failed = "FAILED",
  Completed = "COMPLETED",
  Broken = "BROKEN",
}

export type TimeReport = {
  __typename?: "TimeReport";
  projectId?: Maybe<Scalars["String"]>;
  projectName?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
  totalTimeEstimate?: Maybe<Scalars["Int"]>;
  totalTimeSpent?: Maybe<Scalars["Int"]>;
  difference?: Maybe<Scalars["Int"]>;
};

export enum TokenType {
  ResetPassword = "RESET_PASSWORD",
  ConfirmEmail = "CONFIRM_EMAIL",
}

export type TopFilterAdminReportCreatedPaymentsInput = {
  from: Scalars["Date"];
  to: Scalars["Date"];
  spaceType?: InputMaybe<PaymentSpaceType>;
  productType?: InputMaybe<PaymentFromType>;
};

export type TopFilterAdminReportRegisteredSchoolsInput = {
  from: Scalars["Date"];
  to: Scalars["Date"];
};

export type TopFilterAdminReportRegisteredUsersInput = {
  from: Scalars["Date"];
  to: Scalars["Date"];
  spaceType?: InputMaybe<SpaceTypeAdminReportEnum>;
  productType?: InputMaybe<ProductTypeAdminReportEnum>;
};

export type TrackerUser = {
  __typename?: "TrackerUser";
  id?: Maybe<Scalars["String"]>;
  fullname?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export enum TrainersMechanicsType {
  OneOfTwo = "ONE_OF_TWO",
  OneOfFour = "ONE_OF_FOUR",
}

export enum TrainersType {
  ThirtySeconds = "THIRTY_SECONDS",
  SixtySeconds = "SIXTY_SECONDS",
  TwentyAnswers = "TWENTY_ANSWERS",
  FiveHundredPoints = "FIVE_HUNDRED_POINTS",
  ZnoMain = "ZNO_MAIN",
}

export type Traning = {
  __typename?: "Traning";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  content?: Maybe<Scalars["String"]>;
  startTitle?: Maybe<Scalars["String"]>;
  endTitle?: Maybe<Scalars["String"]>;
  nameEN?: Maybe<Scalars["String"]>;
  nameUKR?: Maybe<Scalars["String"]>;
  contentEN?: Maybe<Scalars["String"]>;
  contentUKR?: Maybe<Scalars["String"]>;
  startTitleEN?: Maybe<Scalars["String"]>;
  startTitleUKR?: Maybe<Scalars["String"]>;
  endTitleEN?: Maybe<Scalars["String"]>;
  endTitleUKR?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  startVideo?: Maybe<Scalars["String"]>;
  endVideo?: Maybe<Scalars["String"]>;
  exercises?: Maybe<Array<Maybe<Exercise>>>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  publishedAt?: Maybe<Scalars["Date"]>;
  unpublishedAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type TransformSubscriptionPlan = {
  __typename?: "TransformSubscriptionPlan";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<SubscriptionPlanNameEnum>;
  description?: Maybe<Scalars["String"]>;
  additionalDescription?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  trialPeriod?: Maybe<Scalars["Int"]>;
  price?: Maybe<Scalars["Float"]>;
  fakePrice?: Maybe<Scalars["Float"]>;
  isBestseller?: Maybe<Scalars["Boolean"]>;
  mainColor?: Maybe<Scalars["String"]>;
  subColor?: Maybe<Scalars["String"]>;
  monthsCount?: Maybe<Scalars["Int"]>;
  order?: Maybe<Scalars["Int"]>;
  pupilsCount?: Maybe<Scalars["Int"]>;
  lessonsCount?: Maybe<Scalars["Int"]>;
  storageSpace?: Maybe<Scalars["Int"]>;
  allowedContentStatus?: Maybe<SubscriptionPlanStatusEnum>;
  version?: Maybe<SubscriptionPlanVersionEnum>;
  type?: Maybe<SubscriptionPlanTypeEnum>;
  discount?: Maybe<Scalars["Float"]>;
  discountPercents?: Maybe<Scalars["Float"]>;
  planPriceForOneMonth?: Maybe<Scalars["Float"]>;
  isCurrentUserPlan?: Maybe<Scalars["Boolean"]>;
  isFocused?: Maybe<Scalars["Boolean"]>;
};

export type TutorsSubjects = {
  __typename?: "TutorsSubjects";
  id?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  middleName?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  subjects?: Maybe<Array<Maybe<ApplicationMain>>>;
};

export type UpdateAiChatAssistantModelInput = {
  modelId?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isFavorite?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateAchieveInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  descriptionDone?: InputMaybe<Scalars["String"]>;
  descriptionProgress?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<AchieveTypeEnum>;
  isLocked?: InputMaybe<Scalars["Boolean"]>;
  isForPUPIL?: InputMaybe<Scalars["Boolean"]>;
  isForTEACHER?: InputMaybe<Scalars["Boolean"]>;
  isForPARENT?: InputMaybe<Scalars["Boolean"]>;
  isForGUEST?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateAdInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
};

export type UpdateAiChatTemplateParameterInput = {
  question: Scalars["String"];
  inputPlaceholderText: Scalars["String"];
  placeHolderName: Scalars["String"];
  order: Scalars["Int"];
};

export type UpdateAiPromptTemplateInput = {
  type?: InputMaybe<AiPromptType>;
  category?: InputMaybe<AiPromptCategory>;
  description?: InputMaybe<Scalars["String"]>;
  template?: InputMaybe<Scalars["String"]>;
  active?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateAnimationBundleInput = {
  id: Scalars["String"];
  typeId?: InputMaybe<Scalars["Int"]>;
  nameEN?: InputMaybe<Scalars["String"]>;
  nameUKR?: InputMaybe<Scalars["String"]>;
  descriptionEN?: InputMaybe<Scalars["String"]>;
  descriptionUKR?: InputMaybe<Scalars["String"]>;
  lat?: InputMaybe<Scalars["String"]>;
  long?: InputMaybe<Scalars["String"]>;
};

export type UpdateApplicationInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  appStoreLink?: InputMaybe<Scalars["String"]>;
  playMarketLink?: InputMaybe<Scalars["String"]>;
  mainPhoto?: InputMaybe<Scalars["String"]>;
  modelPhoto?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  comments?: InputMaybe<Scalars["String"]>;
  frontLink?: InputMaybe<Scalars["String"]>;
};

export type UpdateApplicationLinkInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  link?: InputMaybe<Scalars["String"]>;
};

export type UpdateApplicationMainInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<LocaleEnum>;
  background1?: InputMaybe<Scalars["String"]>;
  background2?: InputMaybe<Scalars["String"]>;
  background3?: InputMaybe<Scalars["String"]>;
  background4?: InputMaybe<Scalars["String"]>;
  background5?: InputMaybe<Scalars["String"]>;
  isMap?: InputMaybe<Scalars["Boolean"]>;
  display?: InputMaybe<Scalars["Boolean"]>;
  city?: InputMaybe<ApplicationMainCityEnum>;
  priority?: InputMaybe<Scalars["Int"]>;
  showInSubjects?: InputMaybe<Scalars["Boolean"]>;
  showInArBook?: InputMaybe<Scalars["Boolean"]>;
  analyticsMark?: InputMaybe<Scalars["String"]>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanStatusEnum>;
};

export type UpdateApplicationMainModelFileInput = {
  id: Scalars["String"];
  file?: InputMaybe<Scalars["Upload"]>;
  manifest?: InputMaybe<Scalars["Upload"]>;
};

export type UpdateApplicationMainModelInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  mainIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  schoolGradeIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  educationAreasIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  tagIds: Array<Scalars["UUID"]>;
  locale?: InputMaybe<LocaleEnum>;
  lang?: InputMaybe<LanguagesEnum>;
  lat?: InputMaybe<Scalars["String"]>;
  longitude?: InputMaybe<Scalars["String"]>;
  address?: InputMaybe<Scalars["String"]>;
  isContour?: InputMaybe<Scalars["Boolean"]>;
  themeId?: InputMaybe<Scalars["String"]>;
  showSmartNotification?: InputMaybe<Scalars["Boolean"]>;
  clicks?: InputMaybe<Scalars["Int"]>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanStatusEnum>;
  isMarker?: InputMaybe<Scalars["Boolean"]>;
  markerWidth?: InputMaybe<Scalars["Float"]>;
  schoolGradeId?: InputMaybe<Scalars["String"]>;
  inGradeOrder?: InputMaybe<Scalars["Int"]>;
  contentAuthor?: InputMaybe<Scalars["String"]>;
  equalId?: InputMaybe<Scalars["UUID"]>;
  price?: InputMaybe<Scalars["Int"]>;
};

export type UpdateAwardInput = {
  id: Scalars["UUID"];
  name: Scalars["String"];
  photo?: InputMaybe<Scalars["Upload"]>;
  day: Scalars["Int"];
  background: Scalars["String"];
  animation?: InputMaybe<Scalars["Upload"]>;
  energyQuantity: Scalars["Int"];
  isPublished: Scalars["Boolean"];
  type: AwardTypeEnum;
};

export type UpdateChatTemplateInput = {
  title?: InputMaybe<Scalars["String"]>;
  prompt?: InputMaybe<Scalars["String"]>;
  lessonType?: InputMaybe<AiChatTemplateLessonTypeEnum>;
  parameters?: InputMaybe<
    Array<InputMaybe<UpdateAiChatTemplateParameterInput>>
  >;
};

export type UpdateContentTagInput = {
  id: Scalars["UUID"];
  displayName?: InputMaybe<Scalars["String"]>;
  userRoleIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  contentTypeIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  pluralName?: InputMaybe<Scalars["String"]>;
  imageUrl?: InputMaybe<Scalars["String"]>;
  categoryId?: InputMaybe<Scalars["UUID"]>;
  type?: InputMaybe<UserLibraryContentType>;
};

export type UpdateCorrectionCommentInput = {
  id: Scalars["String"];
  text?: InputMaybe<Scalars["String"]>;
  files?: InputMaybe<Array<InputMaybe<Scalars["Upload"]>>>;
};

export type UpdateCourseRequest = {
  name?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["UUID"]>;
  cityId?: InputMaybe<Scalars["UUID"]>;
  schoolId?: InputMaybe<Scalars["UUID"]>;
  subjectIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  courseId?: InputMaybe<Scalars["UUID"]>;
};

export type UpdateCurrentUserInput = {
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  middleName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  birthday?: InputMaybe<Scalars["String"]>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  isLKTTrainingCompleted?: InputMaybe<Scalars["Boolean"]>;
  teacherCertificate?: InputMaybe<Scalars["Boolean"]>;
  isShowTariffModal?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateEducationPlanInput = {
  name?: InputMaybe<Scalars["String"]>;
  gradeId?: InputMaybe<Scalars["String"]>;
  difficultyLevel?: InputMaybe<DifficultyLevelEnum>;
  description?: InputMaybe<Scalars["String"]>;
  classroomId?: InputMaybe<Scalars["String"]>;
  lang?: InputMaybe<LanguagesEnum>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  type?: InputMaybe<EducationPlanTypeEnum>;
  courseTypes?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  themeId?: InputMaybe<Scalars["UUID"]>;
  layoutId?: InputMaybe<Scalars["UUID"]>;
  inAppPurchases?: InputMaybe<InAppPurchasesEnum>;
  isPublic?: InputMaybe<Scalars["Boolean"]>;
  finalTestInCourse?: InputMaybe<Scalars["UUID"]>;
  courseProductType?: InputMaybe<CourseProductTypeEnum>;
  quoteCollectionId?: InputMaybe<Scalars["UUID"]>;
  courseCertificateTemplateId?: InputMaybe<Scalars["UUID"]>;
};

export type UpdateEmployeesDistribution = {
  id: Scalars["String"];
  projectName?: InputMaybe<Scalars["String"]>;
  employeesNumber?: InputMaybe<Scalars["Int"]>;
  projectTypeId?: InputMaybe<Scalars["String"]>;
};

export type UpdateExerciseInput = {
  contentEN?: InputMaybe<Scalars["String"]>;
  contentUKR?: InputMaybe<Scalars["String"]>;
  video?: InputMaybe<Scalars["Upload"]>;
  pauseTime?: InputMaybe<Scalars["Int"]>;
};

export type UpdateFeatureInput = {
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
};

export type UpdateFeedbackInput = {
  id: Scalars["String"];
  text?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<FeedbackStatusEnum>;
};

export type UpdateLessonBlockInput = {
  lessonBlockId: Scalars["String"];
  content?: InputMaybe<Scalars["String"]>;
  videoData?: InputMaybe<LessonVideoBlockInput>;
  photo?: InputMaybe<Scalars["Upload"]>;
};

export type UpdateLessonQuestionInput = {
  id: Scalars["String"];
  question?: InputMaybe<Scalars["String"]>;
  enterWord?: InputMaybe<Scalars["String"]>;
  booleanVariant?: InputMaybe<Scalars["Boolean"]>;
  rightVariantId?: InputMaybe<Scalars["String"]>;
};

export type UpdateManualPromotionInput = {
  planIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  allPlans?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  discount?: InputMaybe<Scalars["Float"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  expiredDate?: InputMaybe<Scalars["Date"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateMobileNotificationInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
};

export type UpdateNewsInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  isFor?: InputMaybe<NewsIsForEnum>;
  description?: InputMaybe<Scalars["String"]>;
  youTubeLink?: InputMaybe<Scalars["String"]>;
};

export type UpdateNotificationInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
};

export type UpdatePayrollKpiInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  unit?: InputMaybe<Scalars["String"]>;
  weigh?: InputMaybe<Scalars["Float"]>;
  plan?: InputMaybe<Scalars["Int"]>;
  fact?: InputMaybe<Scalars["Int"]>;
};

export type UpdateProductInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  features?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  typeId?: InputMaybe<Scalars["Int"]>;
  flex?: InputMaybe<Scalars["Int"]>;
  requiredKpi?: InputMaybe<Scalars["Int"]>;
  requiredLevel?: InputMaybe<Scalars["Int"]>;
  image?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
};

export type UpdateProjectAdditionalContactInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
};

export type UpdateProjectCorrectionInput = {
  id: Scalars["String"];
  text?: InputMaybe<Scalars["String"]>;
  statusId?: InputMaybe<Scalars["Int"]>;
  files?: InputMaybe<Array<InputMaybe<Scalars["Upload"]>>>;
};

export type UpdateProjectCustomFieldsMapByProjectInput = {
  projectId: Scalars["String"];
  startDateEstimate?: InputMaybe<Scalars["String"]>;
  startDateFact?: InputMaybe<Scalars["String"]>;
  finishDateEstimate?: InputMaybe<Scalars["String"]>;
  finishDateFact?: InputMaybe<Scalars["String"]>;
};

export type UpdateProjectCustomFieldsMapInput = {
  id: Scalars["String"];
  startDateEstimate?: InputMaybe<Scalars["String"]>;
  startDateFact?: InputMaybe<Scalars["String"]>;
  finishDateEstimate?: InputMaybe<Scalars["String"]>;
  finishDateFact?: InputMaybe<Scalars["String"]>;
};

export type UpdateProjectInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  statusId?: InputMaybe<Scalars["Int"]>;
  projectTypeId?: InputMaybe<Scalars["String"]>;
  startDateEstimate?: InputMaybe<Scalars["Date"]>;
  finishDateEstimate?: InputMaybe<Scalars["Date"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  finishDate?: InputMaybe<Scalars["Date"]>;
  budget?: InputMaybe<Scalars["Int"]>;
  cost?: InputMaybe<Scalars["Int"]>;
  clientId?: InputMaybe<Scalars["String"]>;
  projectManagerId?: InputMaybe<Scalars["String"]>;
  stack?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  blockDiagrams?: InputMaybe<Scalars["String"]>;
  contractTemplates?: InputMaybe<Scalars["String"]>;
  finalAgreement?: InputMaybe<Scalars["String"]>;
  brief?: InputMaybe<Scalars["String"]>;
  testTaskFile?: InputMaybe<Scalars["String"]>;
  renderingFile?: InputMaybe<Scalars["String"]>;
  designFigma?: InputMaybe<Scalars["String"]>;
  risks?: InputMaybe<Scalars["String"]>;
  riskScore?: InputMaybe<Scalars["Int"]>;
};

export type UpdateProjectLinkInput = {
  id: Scalars["String"];
  title?: InputMaybe<Scalars["String"]>;
  link?: InputMaybe<Scalars["String"]>;
  login?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  isFor?: InputMaybe<IsForEnum>;
};

export type UpdateProjectStepInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  startDateEstimate?: InputMaybe<Scalars["String"]>;
  startDateFact?: InputMaybe<Scalars["String"]>;
  finishDateEstimate?: InputMaybe<Scalars["String"]>;
  finishDateFact?: InputMaybe<Scalars["String"]>;
  statusId?: InputMaybe<Scalars["String"]>;
};

export type UpdateProjectTaskInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  assigneeId?: InputMaybe<Scalars["String"]>;
  responsibleId?: InputMaybe<Scalars["String"]>;
  startDateEstimate?: InputMaybe<Scalars["String"]>;
  startDateFact?: InputMaybe<Scalars["String"]>;
  finishDateEstimate?: InputMaybe<Scalars["String"]>;
  finishDateFact?: InputMaybe<Scalars["String"]>;
  timeEstimate?: InputMaybe<Scalars["Int"]>;
  timeSpent?: InputMaybe<Scalars["Int"]>;
  taskStatusId?: InputMaybe<Scalars["String"]>;
};

export type UpdateSchoolParticipantInput = {
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  schoolRegionId?: InputMaybe<Scalars["UUID"]>;
  schoolCityId?: InputMaybe<Scalars["UUID"]>;
  street?: InputMaybe<Scalars["String"]>;
  houseNumber?: InputMaybe<Scalars["String"]>;
};

export type UpdateSmartNotificationInput = {
  id: Scalars["String"];
  applicationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  link?: InputMaybe<Scalars["String"]>;
};

export type UpdateTeacherSchoolInput = {
  id: Scalars["UUID"];
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  phone?: InputMaybe<Scalars["String"]>;
  birthday?: InputMaybe<Scalars["String"]>;
  schoolCityId?: InputMaybe<Scalars["String"]>;
  schoolRegionId?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  houseNumber?: InputMaybe<Scalars["String"]>;
};

export type UpdateTestInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  modelId?: InputMaybe<Scalars["String"]>;
  mainId?: InputMaybe<Scalars["String"]>;
  themeId?: InputMaybe<Scalars["String"]>;
  classroomId?: InputMaybe<Scalars["String"]>;
  isOpenTest?: InputMaybe<Scalars["Boolean"]>;
  attempts?: InputMaybe<Scalars["Int"]>;
  time?: InputMaybe<Scalars["Int"]>;
  class?: InputMaybe<TestClassEnum>;
  repeatAttemptTime?: InputMaybe<Scalars["Int"]>;
  showSmartNotification?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateUserGroupInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateUserInAdminPanelInput = {
  phone?: InputMaybe<Scalars["String"]>;
  jiraId?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  middleName?: InputMaybe<Scalars["String"]>;
  birthday?: InputMaybe<Scalars["String"]>;
  address?: InputMaybe<Scalars["String"]>;
  cityId?: InputMaybe<Scalars["String"]>;
  addressReal?: InputMaybe<Scalars["String"]>;
  instagram?: InputMaybe<Scalars["String"]>;
  telegram?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<Scalars["Boolean"]>;
  drfo?: InputMaybe<Scalars["Boolean"]>;
  nda?: InputMaybe<Scalars["Boolean"]>;
  inn?: InputMaybe<Scalars["Boolean"]>;
  creditCard?: InputMaybe<Scalars["String"]>;
  roleId?: InputMaybe<Scalars["Int"]>;
  statusId?: InputMaybe<Scalars["Int"]>;
  jobId?: InputMaybe<Scalars["UUID"]>;
  positionId?: InputMaybe<Scalars["Int"]>;
  hireDate?: InputMaybe<Scalars["Date"]>;
  agreementTypeId?: InputMaybe<Scalars["Int"]>;
  agreementId?: InputMaybe<Scalars["UUID"]>;
  departmentId?: InputMaybe<Scalars["UUID"]>;
  loadPercent?: InputMaybe<Scalars["Int"]>;
  promotionDate?: InputMaybe<Scalars["Date"]>;
  promotionReason?: InputMaybe<Scalars["String"]>;
  bonuses?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  diploma?: InputMaybe<Scalars["Boolean"]>;
  passport?: InputMaybe<Scalars["Boolean"]>;
  contractType?: InputMaybe<Scalars["String"]>;
  excerpt?: InputMaybe<Scalars["Boolean"]>;
  idCard?: InputMaybe<Scalars["Boolean"]>;
  countryId?: InputMaybe<Scalars["UUID"]>;
  schoolRegionId?: InputMaybe<Scalars["UUID"]>;
  schoolClassroomId?: InputMaybe<Scalars["UUID"]>;
  schoolGrades?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

export type UpdateUserInput = {
  id: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  jiraId?: InputMaybe<Scalars["String"]>;
  oneCId?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  middleName?: InputMaybe<Scalars["String"]>;
  birthday?: InputMaybe<Scalars["String"]>;
  address?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  houseNumber?: InputMaybe<Scalars["String"]>;
  cityId?: InputMaybe<Scalars["String"]>;
  addressReal?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<GenderEnum>;
  photo?: InputMaybe<Scalars["String"]>;
  instagram?: InputMaybe<Scalars["String"]>;
  telegram?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<Scalars["Boolean"]>;
  isRemote?: InputMaybe<Scalars["Boolean"]>;
  drfo?: InputMaybe<Scalars["Boolean"]>;
  nda?: InputMaybe<Scalars["Boolean"]>;
  inn?: InputMaybe<Scalars["Boolean"]>;
  creditCard?: InputMaybe<Scalars["String"]>;
  roleId?: InputMaybe<Scalars["Int"]>;
  statusId?: InputMaybe<Scalars["Int"]>;
  jobId?: InputMaybe<Scalars["String"]>;
  positionId?: InputMaybe<Scalars["Int"]>;
  hireDate?: InputMaybe<Scalars["Date"]>;
  agreementTypeId?: InputMaybe<Scalars["Int"]>;
  agreementId?: InputMaybe<Scalars["String"]>;
  departmentId?: InputMaybe<Scalars["String"]>;
  loadPercent?: InputMaybe<Scalars["Int"]>;
  promotionDate?: InputMaybe<Scalars["Date"]>;
  promotionReason?: InputMaybe<Scalars["String"]>;
  bonuses?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  diploma?: InputMaybe<Scalars["Boolean"]>;
  passport?: InputMaybe<Scalars["Boolean"]>;
  contractType?: InputMaybe<Scalars["String"]>;
  excerpt?: InputMaybe<Scalars["Boolean"]>;
  idCard?: InputMaybe<Scalars["Boolean"]>;
  applicationId?: InputMaybe<Scalars["String"]>;
  countryId?: InputMaybe<Scalars["String"]>;
  schoolRegionId?: InputMaybe<Scalars["String"]>;
  schoolCityId?: InputMaybe<Scalars["String"]>;
  sendTrainingPush?: InputMaybe<Scalars["Boolean"]>;
  showSmartNotifications?: InputMaybe<Scalars["Boolean"]>;
  showContentNotifications?: InputMaybe<Scalars["Boolean"]>;
  sendNotificationsPush?: InputMaybe<Scalars["Boolean"]>;
  teacherCertificate?: InputMaybe<Scalars["Boolean"]>;
  schoolClassroomId?: InputMaybe<Scalars["String"]>;
  noSuchSchoolExist?: InputMaybe<Scalars["Boolean"]>;
  noSuchCityExist?: InputMaybe<Scalars["Boolean"]>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  isLKTTrainingCompleted?: InputMaybe<Scalars["Boolean"]>;
  schoolGrades?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  teachingFormat?: InputMaybe<TeachingFormatEnum>;
  aiWizardPassed?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateUserPaymentInput = {
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Int"]>;
  estimatedDate?: InputMaybe<Scalars["Date"]>;
};

export type UpdateUserPayrollInput = {
  id: Scalars["String"];
  hoursPlan?: InputMaybe<Scalars["Int"]>;
  firstPartSalary?: InputMaybe<Scalars["Int"]>;
  secondPartSalary?: InputMaybe<Scalars["Int"]>;
  salaryDescription?: InputMaybe<Scalars["String"]>;
  kpiDescription?: InputMaybe<Scalars["String"]>;
  bonusesDescription?: InputMaybe<Scalars["String"]>;
  totalDescription?: InputMaybe<Scalars["String"]>;
  bonusFund?: InputMaybe<Scalars["Int"]>;
};

export type UpdateUserStatsInput = {
  levelValue?: InputMaybe<Scalars["Int"]>;
  exp?: InputMaybe<Scalars["Int"]>;
  nextLevelExp?: InputMaybe<Scalars["Int"]>;
  cof?: InputMaybe<Scalars["Float"]>;
  baseExp?: InputMaybe<Scalars["Int"]>;
  flex?: InputMaybe<Scalars["Int"]>;
  kpi?: InputMaybe<Scalars["Int"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type UpdateVrHeadsetInput = {
  name?: InputMaybe<Scalars["String"]>;
  model?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  lessonId?: InputMaybe<Scalars["String"]>;
};

export type UpdateVacanciesPlanInput = {
  id: Scalars["String"];
  userId?: InputMaybe<Scalars["String"]>;
  jobId?: InputMaybe<Scalars["String"]>;
  positionId?: InputMaybe<Scalars["Int"]>;
  experience?: InputMaybe<Scalars["Float"]>;
  statusId?: InputMaybe<Scalars["Int"]>;
  comment?: InputMaybe<Scalars["String"]>;
};

export type UploadFileInput = {
  file: Scalars["Upload"];
  name: Scalars["String"];
  isFor?: InputMaybe<IsForEnum>;
};

export type UploadFileToSlideEditorResponse = {
  __typename?: "UploadFileToSlideEditorResponse";
  roomId?: Maybe<Scalars["UUID"]>;
  slide?: Maybe<Slide>;
};

export type UploadedFile = {
  __typename?: "UploadedFile";
  id?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  feedbackId?: Maybe<Scalars["String"]>;
  feedbackCommentId?: Maybe<Scalars["String"]>;
  correctionCommentId?: Maybe<Scalars["String"]>;
  projectCorrectionId?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type User = {
  __typename?: "User";
  id?: Maybe<Scalars["String"]>;
  jiraId?: Maybe<Scalars["String"]>;
  trackerId?: Maybe<Scalars["Int"]>;
  oneCId?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  middleName?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  birthday?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  address?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  houseNumber?: Maybe<Scalars["String"]>;
  city?: Maybe<City>;
  gender?: Maybe<GenderEnum>;
  addressReal?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  instagram?: Maybe<Scalars["String"]>;
  telegram?: Maybe<Scalars["String"]>;
  contract?: Maybe<Scalars["Boolean"]>;
  isRemote?: Maybe<Scalars["Boolean"]>;
  drfo?: Maybe<Scalars["Boolean"]>;
  nda?: Maybe<Scalars["Boolean"]>;
  inn?: Maybe<Scalars["Boolean"]>;
  salary?: Maybe<Scalars["Int"]>;
  userSalaryHistory?: Maybe<Array<Maybe<UserSalaryHistory>>>;
  userRateHistory?: Maybe<Array<Maybe<UserRateHistory>>>;
  creditCard?: Maybe<Scalars["String"]>;
  role?: Maybe<UserRole>;
  status?: Maybe<UserStatus>;
  job?: Maybe<UserJob>;
  position?: Maybe<UserPosition>;
  stats?: Maybe<UserStats>;
  userExpHistory?: Maybe<Array<Maybe<UserExpHistory>>>;
  purchases?: Maybe<Array<Maybe<Purchase>>>;
  cart?: Maybe<Array<Maybe<Purchase>>>;
  purchaseHistory?: Maybe<Array<Maybe<Purchase>>>;
  projects?: Maybe<Array<Maybe<Project>>>;
  groups?: Maybe<Array<Maybe<UserGroup>>>;
  friends?: Maybe<Array<Maybe<User>>>;
  rate?: Maybe<Scalars["Int"]>;
  hireDate?: Maybe<Scalars["Date"]>;
  userPermissions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  agreementType?: Maybe<UserAgreementType>;
  agreement?: Maybe<UserAgreement>;
  department?: Maybe<UserDepartment>;
  loadPercent?: Maybe<Scalars["Int"]>;
  promotionDate?: Maybe<Scalars["Date"]>;
  promotionReason?: Maybe<Scalars["String"]>;
  previousSalary?: Maybe<Scalars["Int"]>;
  payrolls?: Maybe<Array<Maybe<UserPayroll>>>;
  payments?: Maybe<Array<Maybe<UserPayment>>>;
  bonuses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  diploma?: Maybe<Scalars["Boolean"]>;
  passport?: Maybe<Scalars["Boolean"]>;
  contractType?: Maybe<Scalars["String"]>;
  excerpt?: Maybe<Scalars["Boolean"]>;
  cvs?: Maybe<Array<Maybe<UserCv>>>;
  idCard?: Maybe<Scalars["Boolean"]>;
  trackerContracts?: Maybe<Array<Maybe<Contract>>>;
  vacanciesPlans?: Maybe<Array<Maybe<VacanciesPlan>>>;
  applicationId?: Maybe<Scalars["String"]>;
  application?: Maybe<Application>;
  classrooms?: Maybe<Array<Maybe<Classroom>>>;
  countryId?: Maybe<Scalars["String"]>;
  country?: Maybe<SchoolCountry>;
  testAnswers?: Maybe<Array<Maybe<TestAnswer>>>;
  schoolId?: Maybe<Scalars["String"]>;
  school?: Maybe<School>;
  personalSchoolId?: Maybe<Scalars["String"]>;
  personalSchool?: Maybe<School>;
  schools?: Maybe<Array<Maybe<School>>>;
  subjects?: Maybe<Array<Maybe<ApplicationMain>>>;
  schoolGrades?: Maybe<Array<Maybe<SchoolGrade>>>;
  eventDates?: Maybe<Array<Maybe<EventDate>>>;
  sendTrainingPush?: Maybe<Scalars["Boolean"]>;
  showSmartNotifications?: Maybe<Scalars["Boolean"]>;
  showContentNotifications?: Maybe<Scalars["Boolean"]>;
  sendNotificationsPush?: Maybe<Scalars["Boolean"]>;
  teacherCertificate?: Maybe<Scalars["Boolean"]>;
  schoolRegionId?: Maybe<Scalars["String"]>;
  schoolRegion?: Maybe<SchoolRegion>;
  schoolCityId?: Maybe<Scalars["String"]>;
  schoolCity?: Maybe<SchoolCity>;
  schoolStatus?: Maybe<SchoolUserStatusEnum>;
  teacherPosition?: Maybe<Scalars["String"]>;
  schoolClassroom?: Maybe<SchoolClassroom>;
  schoolClassroomId?: Maybe<Scalars["String"]>;
  pupilSchoolClassrooms?: Maybe<Array<Maybe<SchoolClassroom>>>;
  parents?: Maybe<Array<Maybe<UserParentChild>>>;
  children?: Maybe<Array<Maybe<UserParentChild>>>;
  registeredFrom?: Maybe<IsFromEnum>;
  environment?: Maybe<EnvironmentEnum>;
  teacherSchoolClassrooms?: Maybe<Array<Maybe<SchoolClassroom>>>;
  isFirstLogin?: Maybe<Scalars["Boolean"]>;
  isLKTTrainingCompleted?: Maybe<Scalars["Boolean"]>;
  modelProgresses?: Maybe<Array<Maybe<UserModelProgress>>>;
  appInterfaceLanguage?: Maybe<LangEnum>;
  subscriptionInfo?: Maybe<SubscriptionInfo>;
  havePassword?: Maybe<Scalars["Boolean"]>;
  isChild?: Maybe<Scalars["Boolean"]>;
  isZoomConnected?: Maybe<Scalars["Boolean"]>;
  parentConfirmed?: Maybe<Scalars["Boolean"]>;
  userGdpr?: Maybe<UserGdpr>;
  countPupilsActiveSchool?: Maybe<Scalars["Int"]>;
  favoriteSlides?: Maybe<Array<Maybe<Slide>>>;
  schoolTeachingFormat?: Maybe<TeachingFormatEnum>;
  folders?: Maybe<Array<Maybe<UserFolder>>>;
  pupilLoginCode?: Maybe<Scalars["String"]>;
  activeInvitations?: Maybe<Array<Maybe<UserInvitation>>>;
  selectedSubjects?: Maybe<Array<Maybe<Scalars["String"]>>>;
  educationPlanParticipantStatus?: Maybe<EducationPlanParticipantStatusEnum>;
  paymentSession?: Maybe<Scalars["Metadata"]>;
  events?: Maybe<Array<Maybe<UserEvent>>>;
  authFlowData?: Maybe<Scalars["String"]>;
  eSputnikContactId?: Maybe<Scalars["Int"]>;
  isShowTariffModal?: Maybe<Scalars["Boolean"]>;
  aiWizardPassed?: Maybe<Scalars["Boolean"]>;
  marketing?: Maybe<MarketingInfo>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export enum UserArtLoginRoleEnum {
  Pupil = "PUPIL",
  Teacher = "TEACHER",
  Parent = "PARENT",
}

export type UserAgreement = {
  __typename?: "UserAgreement";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type UserAgreementType = {
  __typename?: "UserAgreementType";
  id?: Maybe<Scalars["Int"]>;
  type?: Maybe<UserAgreementTypesEnum>;
  displayName?: Maybe<Scalars["String"]>;
};

export enum UserAgreementTypesEnum {
  Outsourcing = "OUTSOURCING",
  Staff = "STAFF",
}

export type UserAnswer = {
  __typename?: "UserAnswer";
  value?: Maybe<Scalars["String"]>;
  isCorrectAnswer?: Maybe<Scalars["Boolean"]>;
};

export type UserCv = {
  __typename?: "UserCV";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type UserDepartment = {
  __typename?: "UserDepartment";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type UserDevice = {
  __typename?: "UserDevice";
  id?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type UserEvent = {
  __typename?: "UserEvent";
  id?: Maybe<Scalars["UUID"]>;
  userId?: Maybe<Scalars["UUID"]>;
  data?: Maybe<Scalars["JSONObject"]>;
  type?: Maybe<UserEventType>;
  processedDate?: Maybe<Scalars["Date"]>;
  actionType?: Maybe<UserEventActionType>;
  createdById?: Maybe<Scalars["UUID"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedById?: Maybe<Scalars["UUID"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export enum UserEventActionType {
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  Viewed = "VIEWED",
}

export type UserEventInput = {
  userId: Scalars["UUID"];
  data?: InputMaybe<Scalars["JSONObject"]>;
  type: UserEventType;
};

export enum UserEventType {
  InviteToSchool = "INVITE_TO_SCHOOL",
  InviteToSchoolClassroom = "INVITE_TO_SCHOOL_CLASSROOM",
  InviteToClassroom = "INVITE_TO_CLASSROOM",
  InviteToCourse = "INVITE_TO_COURSE",
  InviteToEducationPlan = "INVITE_TO_EDUCATION_PLAN",
}

export type UserExpHistory = {
  __typename?: "UserExpHistory";
  id?: Maybe<Scalars["String"]>;
  difference?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type UserFolder = {
  __typename?: "UserFolder";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  ownerId?: Maybe<Scalars["String"]>;
  owner?: Maybe<User>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type UserFolders = {
  __typename?: "UserFolders";
  folders?: Maybe<Array<Maybe<UserFolder>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type UserGdpr = {
  __typename?: "UserGdpr";
  id?: Maybe<Scalars["String"]>;
  agreement?: Maybe<Scalars["Boolean"]>;
  agreementDate?: Maybe<Scalars["Date"]>;
  privatePolicy?: Maybe<Scalars["Boolean"]>;
  privatePolicyDate?: Maybe<Scalars["Date"]>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type UserGroup = {
  __typename?: "UserGroup";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  members?: Maybe<Array<Maybe<User>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type UserInvitation = {
  __typename?: "UserInvitation";
  id?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  schoolClassroomId?: Maybe<Scalars["String"]>;
  schoolClassroom?: Maybe<School>;
  schoolId?: Maybe<Scalars["String"]>;
  school?: Maybe<School>;
  acceptedDate?: Maybe<Scalars["Date"]>;
  declinedDate?: Maybe<Scalars["Date"]>;
  invitedUserId?: Maybe<Scalars["String"]>;
  invitedUser?: Maybe<User>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type UserJob = {
  __typename?: "UserJob";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type UserLibraryContent = {
  __typename?: "UserLibraryContent";
  id?: Maybe<Scalars["UUID"]>;
  equalId?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  reflectionPoint?: Maybe<Scalars["Float"]>;
  type?: Maybe<UserLibraryContentType>;
  contentTypeName?: Maybe<ContentTypeEnum>;
  isCompletedByUser?: Maybe<Scalars["Boolean"]>;
  isNestedLessonsDisabled?: Maybe<Scalars["Boolean"]>;
  isDisabledByDate?: Maybe<Scalars["Boolean"]>;
  upcomingEvent?: Maybe<SchoolEvent>;
  mains?: Maybe<Array<Maybe<ApplicationMain>>>;
  schoolGrades?: Maybe<Array<Maybe<SchoolGrade>>>;
  educationAreas?: Maybe<Array<Maybe<EducationArea>>>;
  photo?: Maybe<Scalars["String"]>;
  firstSlide?: Maybe<Slide>;
  contentAuthor?: Maybe<Scalars["String"]>;
  countOfImport?: Maybe<Scalars["Int"]>;
  countOfView?: Maybe<Scalars["Int"]>;
  parentId?: Maybe<Scalars["UUID"]>;
  childId?: Maybe<Scalars["UUID"]>;
  marketId?: Maybe<Scalars["String"]>;
  isUploadedToMarket?: Maybe<Scalars["Boolean"]>;
  isFromMarket?: Maybe<Scalars["Boolean"]>;
  educationPlanChapterId?: Maybe<Scalars["UUID"]>;
  educationPlanChapter?: Maybe<EducationPlanChapter>;
  educationPlanId?: Maybe<Scalars["UUID"]>;
  educationPlan?: Maybe<EducationPlan>;
  subjectId?: Maybe<Scalars["UUID"]>;
  subject?: Maybe<ApplicationMain>;
  schoolId?: Maybe<Scalars["UUID"]>;
  school?: Maybe<School>;
  planId?: Maybe<Scalars["UUID"]>;
  plan?: Maybe<EducationPlan>;
  applicationMainModelId?: Maybe<Scalars["UUID"]>;
  applicationMainModel?: Maybe<ApplicationMainModel>;
  experimentType?: Maybe<ExperimentTypeEnum>;
  description?: Maybe<Scalars["String"]>;
  lessonId?: Maybe<Scalars["UUID"]>;
  lesson?: Maybe<NewLesson>;
  contentFileId?: Maybe<Scalars["UUID"]>;
  contentFile?: Maybe<ContentFile>;
  lessonType?: Maybe<NewLessonType>;
  inAppPurchases?: Maybe<InAppPurchasesEnum>;
  teachingFormat?: Maybe<TeachingFormatEnum>;
  lang?: Maybe<LanguagesEnum>;
  folderId?: Maybe<Scalars["UUID"]>;
  folder?: Maybe<UserFolder>;
  libraryOwnerId?: Maybe<Scalars["UUID"]>;
  libraryOwner?: Maybe<User>;
  connectedToLessonId?: Maybe<Scalars["String"]>;
  connectedToLesson?: Maybe<NewLesson>;
  category?: Maybe<Array<Maybe<ContentCategory>>>;
  webGl?: Maybe<WebGlBundleData>;
  previews?: Maybe<Array<Maybe<ContentPreview>>>;
  recommendationsCount?: Maybe<Scalars["Int"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  slidesCount?: Maybe<Scalars["Int"]>;
  slides?: Maybe<Array<Maybe<Slide>>>;
  testTimeSec?: Maybe<Scalars["Int"]>;
  tags?: Maybe<Array<Maybe<ContentTag>>>;
  planType?: Maybe<EducationPlanTypeEnum>;
  fileContentType?: Maybe<FileContentTypeEnum>;
  price?: Maybe<Scalars["Float"]>;
  availableForSchool?: Maybe<Scalars["Boolean"]>;
  productId?: Maybe<Scalars["Int"]>;
  isLector?: Maybe<Scalars["Boolean"]>;
  createdById?: Maybe<Scalars["UUID"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
  contentTypePreview?: Maybe<Scalars["String"]>;
};

export enum UserLibraryContentType {
  Lesson = "LESSON",
  EducationPlan = "EDUCATION_PLAN",
  Experiment = "EXPERIMENT",
  ExperimentVr = "EXPERIMENT_VR",
  Trainers = "TRAINERS",
  File = "FILE",
}

export type UserModelProgress = {
  __typename?: "UserModelProgress";
  id?: Maybe<Scalars["String"]>;
  model?: Maybe<ApplicationMainModel>;
  modelId?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]>;
  clicks?: Maybe<Scalars["Int"]>;
  progressPercent?: Maybe<Scalars["Int"]>;
  type?: Maybe<UserModelTypeEnum>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export enum UserModelTypeEnum {
  ThreeD = "THREE_D",
  Ar = "AR",
}

export type UserOptionFields = {
  __typename?: "UserOptionFields";
  cities?: Maybe<Array<Maybe<City>>>;
  agreements?: Maybe<Array<Maybe<UserAgreement>>>;
  departments?: Maybe<Array<Maybe<UserDepartment>>>;
  roles?: Maybe<Array<Maybe<UserRole>>>;
  statuses?: Maybe<Array<Maybe<UserStatus>>>;
  jobs?: Maybe<Array<Maybe<UserJob>>>;
  positions?: Maybe<Array<Maybe<UserPosition>>>;
  agreementType?: Maybe<Array<Maybe<UserAgreementType>>>;
};

export type UserParentChild = {
  __typename?: "UserParentChild";
  id?: Maybe<Scalars["String"]>;
  parent?: Maybe<User>;
  parentId?: Maybe<Scalars["String"]>;
  child?: Maybe<User>;
  childId?: Maybe<Scalars["String"]>;
  isWatchedByPupil?: Maybe<Scalars["Boolean"]>;
  isWatchedByParent?: Maybe<Scalars["Boolean"]>;
};

export type UserPayment = {
  __typename?: "UserPayment";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  user?: Maybe<User>;
  project?: Maybe<Project>;
  isPaid?: Maybe<Scalars["Boolean"]>;
  paidDate?: Maybe<Scalars["Date"]>;
  status?: Maybe<UserPaymentStatus>;
  estimatedDate?: Maybe<Scalars["Date"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  documents?: Maybe<Array<Maybe<UserPaymentDocument>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type UserPaymentDocument = {
  __typename?: "UserPaymentDocument";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  fileLink?: Maybe<Scalars["String"]>;
  userPayment?: Maybe<UserPayment>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
};

export type UserPaymentStatus = {
  __typename?: "UserPaymentStatus";
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<UserPaymentStatusEnum>;
  displayName?: Maybe<Scalars["String"]>;
};

export enum UserPaymentStatusEnum {
  Pending = "PENDING",
  Paid = "PAID",
  Late = "LATE",
}

export type UserPayroll = {
  __typename?: "UserPayroll";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["Date"]>;
  user?: Maybe<User>;
  bonuses?: Maybe<Array<Maybe<PayrollBonus>>>;
  kpi?: Maybe<Array<Maybe<PayrollKpi>>>;
  parts?: Maybe<Array<Maybe<PayrollSalaryPart>>>;
  salaryDescription?: Maybe<Scalars["String"]>;
  kpiDescription?: Maybe<Scalars["String"]>;
  bonusesDescription?: Maybe<Scalars["String"]>;
  totalDescription?: Maybe<Scalars["String"]>;
  hoursPlan?: Maybe<Scalars["Int"]>;
  bonusFund?: Maybe<Scalars["Int"]>;
  additionalData?: Maybe<AdditionalData>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type UserPosition = {
  __typename?: "UserPosition";
  id?: Maybe<Scalars["Int"]>;
  position?: Maybe<UserPositionEnum>;
  displayName?: Maybe<Scalars["String"]>;
};

export enum UserPositionEnum {
  Candidate = "CANDIDATE",
  Trainee = "TRAINEE",
  Junior = "JUNIOR",
  Middle = "MIDDLE",
  Senior = "SENIOR",
}

export type UserProfile = {
  __typename?: "UserProfile";
  id?: Maybe<Scalars["UUID"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  middleName?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  schoolStatus?: Maybe<SchoolUserStatusEnum>;
  subjects?: Maybe<Array<Maybe<ApplicationMain>>>;
};

export enum UserPromotionStatus {
  Active = "ACTIVE",
  Expired = "EXPIRED",
  AlreadyHasSubscription = "ALREADY_HAS_SUBSCRIPTION",
  AlreadyApplied = "ALREADY_APPLIED",
  NotActive = "NOT_ACTIVE",
  NotExist = "NOT_EXIST",
  NotAvailable = "NOT_AVAILABLE",
  NotStarted = "NOT_STARTED",
}

export type UserRateHistory = {
  __typename?: "UserRateHistory";
  id?: Maybe<Scalars["String"]>;
  rate?: Maybe<Scalars["Int"]>;
  user?: Maybe<User>;
  changeDate?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type UserRole = {
  __typename?: "UserRole";
  id?: Maybe<Scalars["Int"]>;
  role?: Maybe<UserRoleEnum>;
  displayName?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  iconUrl?: Maybe<Scalars["String"]>;
};

export enum UserRoleEnum {
  Super = "SUPER",
  Manager = "MANAGER",
  Client = "CLIENT",
  Staff = "STAFF",
  Pupil = "PUPIL",
  Subclient = "SUBCLIENT",
  Teacher = "TEACHER",
  Parent = "PARENT",
  Guest = "GUEST",
}

export type UserSalaryHistory = {
  __typename?: "UserSalaryHistory";
  id?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  difference?: Maybe<Scalars["Int"]>;
  previous?: Maybe<Scalars["Int"]>;
  current?: Maybe<Scalars["Int"]>;
  user?: Maybe<User>;
  changeDate?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type UserSelectParams = {
  __typename?: "UserSelectParams";
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type UserStats = {
  __typename?: "UserStats";
  id?: Maybe<Scalars["String"]>;
  levelValue?: Maybe<Scalars["Int"]>;
  exp?: Maybe<Scalars["Int"]>;
  nextLevelExp?: Maybe<Scalars["Int"]>;
  cof?: Maybe<Scalars["Float"]>;
  baseExp?: Maybe<Scalars["Int"]>;
  flex?: Maybe<Scalars["Int"]>;
  kpi?: Maybe<Scalars["Int"]>;
  user?: Maybe<User>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type UserStatus = {
  __typename?: "UserStatus";
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<UserStatusEnum>;
  displayName?: Maybe<Scalars["String"]>;
};

export enum UserStatusEnum {
  Pending = "PENDING",
  Verified = "VERIFIED",
  NotVerified = "NOT_VERIFIED",
  Fired = "FIRED",
  Unregistered = "UNREGISTERED",
  Blocked = "BLOCKED",
}

export enum UserTypeAdminReportEnum {
  Pupil = "PUPIL",
  Teacher = "TEACHER",
  Parent = "PARENT",
}

export type UsersCountLight = {
  __typename?: "UsersCountLight";
  status?: Maybe<UsersCountStatusesEnum>;
  count?: Maybe<Scalars["Int"]>;
};

export enum UsersCountStatusesEnum {
  Client = "CLIENT",
  Staff = "STAFF",
  New = "NEW",
}

export type UsersSchoolConnectionEmailData = {
  role: UserRoleEnum;
  emails: Array<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  middleName?: InputMaybe<Scalars["String"]>;
};

export type VrHeadset = {
  __typename?: "VRHeadset";
  id?: Maybe<Scalars["UUID"]>;
  deviceId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  schoolId?: Maybe<Scalars["String"]>;
  school?: Maybe<School>;
  lessonId?: Maybe<Scalars["String"]>;
  lesson?: Maybe<NewLesson>;
  createdById?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  updatedById?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
  deletedDate?: Maybe<Scalars["Date"]>;
};

export type VacanciesPlan = {
  __typename?: "VacanciesPlan";
  id?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  name?: Maybe<Scalars["String"]>;
  job?: Maybe<UserJob>;
  position?: Maybe<UserPosition>;
  experience?: Maybe<Scalars["Float"]>;
  status?: Maybe<VacanciesPlanStatus>;
  comment?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type VacanciesPlanLight = {
  __typename?: "VacanciesPlanLight";
  name?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
};

export type VacanciesPlanOptionFields = {
  __typename?: "VacanciesPlanOptionFields";
  statuses?: Maybe<Array<Maybe<VacanciesPlanStatus>>>;
  jobs?: Maybe<Array<Maybe<UserJob>>>;
  positions?: Maybe<Array<Maybe<UserPosition>>>;
};

export type VacanciesPlanStatus = {
  __typename?: "VacanciesPlanStatus";
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<VacanciesPlanStatusEnum>;
  displayName?: Maybe<Scalars["String"]>;
};

export enum VacanciesPlanStatusEnum {
  New = "NEW",
  Pending = "PENDING",
  Success = "SUCCESS",
  Failed = "FAILED",
}

export type VacanciesPlansByName = {
  __typename?: "VacanciesPlansByName";
  plans?: Maybe<Array<Maybe<VacanciesPlan>>>;
  progress?: Maybe<Scalars["Float"]>;
};

export type ValidationInvitationListResponse = {
  __typename?: "ValidationInvitationListResponse";
  success: Scalars["Boolean"];
  errors: Array<Maybe<CsvValidationErrors>>;
};

export type Variant = {
  __typename?: "Variant";
  id?: Maybe<Scalars["String"]>;
  content?: Maybe<Scalars["String"]>;
  questionId?: Maybe<Scalars["String"]>;
  question?: Maybe<Question>;
  answers?: Maybe<Array<Maybe<Answer>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type WayForPayPayload = {
  merchantDomainName: Scalars["String"];
  orderReference: Scalars["String"];
  orderDate: Scalars["String"];
  amount: Scalars["String"];
  currency: Scalars["String"];
  productName?: InputMaybe<Array<Scalars["String"]>>;
  productCount?: InputMaybe<Array<Scalars["String"]>>;
  productPrice?: InputMaybe<Array<Scalars["String"]>>;
};

export type WebGlBundleData = {
  __typename?: "WebGlBundleData";
  file?: Maybe<Scalars["String"]>;
  manifest?: Maybe<Scalars["String"]>;
};

export type WhiteLabelCollection = {
  __typename?: "WhiteLabelCollection";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  isPublished?: Maybe<Scalars["Boolean"]>;
  image?: Maybe<Scalars["String"]>;
  schoolLogo?: Maybe<Scalars["String"]>;
  systemLogo?: Maybe<Scalars["String"]>;
  systemLogoLarge?: Maybe<Scalars["String"]>;
  banner?: Maybe<Scalars["String"]>;
  textColor?: Maybe<Scalars["String"]>;
  backgroundColor?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  welcomeHtml?: Maybe<Scalars["String"]>;
};

export type WorkPlace = {
  __typename?: "WorkPlace";
  id?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  cityId?: Maybe<Scalars["UUID"]>;
  city?: Maybe<SchoolCity>;
  districtId?: Maybe<Scalars["UUID"]>;
  district?: Maybe<SchoolDistrict>;
  regionId?: Maybe<Scalars["UUID"]>;
  region?: Maybe<SchoolRegion>;
  countryId?: Maybe<Scalars["UUID"]>;
  country?: Maybe<SchoolCountry>;
  createdDate?: Maybe<Scalars["Date"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type BlockTypeInput = {
  content?: InputMaybe<Scalars["String"]>;
  testQuestion?: InputMaybe<Scalars["String"]>;
  video?: InputMaybe<Scalars["String"]>;
};

export type CreatePaymentSessionInput = {
  contentId?: InputMaybe<Scalars["UUID"]>;
  planId?: InputMaybe<Scalars["UUID"]>;
  paymentProviderId: Scalars["UUID"];
  data: Scalars["String"];
};

export type GetWhiteLabelCollectionsFilters = {
  isPublished?: InputMaybe<Scalars["Boolean"]>;
};

export enum IsFromEnum {
  AdminPanel = "ADMIN_PANEL",
  LkStaff = "LK_STAFF",
  LkTeacher = "LK_TEACHER",
  Arbook = "ARBOOK",
}

export type UploadPresentationResponse = {
  __typename?: "uploadPresentationResponse";
  roomId?: Maybe<Scalars["String"]>;
};

export type CreateAchieveMutationVariables = Exact<{
  achieveData: CreateAchieveInput;
  photo?: InputMaybe<Scalars["Upload"]>;
}>;

export type CreateAchieveMutation = {
  __typename?: "Mutation";
  createAchieve?: { __typename?: "Achieve"; id?: string | null } | null;
};

export type UpdateAchieveMutationVariables = Exact<{
  achieveData: UpdateAchieveInput;
  photo?: InputMaybe<Scalars["Upload"]>;
}>;

export type UpdateAchieveMutation = {
  __typename?: "Mutation";
  updateAchieve?: { __typename?: "Achieve"; id?: string | null } | null;
};

export type PublishAchieveMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type PublishAchieveMutation = {
  __typename?: "Mutation";
  publishAchieve?: boolean | null;
};

export type DeleteAchieveMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteAchieveMutation = {
  __typename?: "Mutation";
  deleteAchieve?: boolean | null;
};

export type CreateUserAgreementMutationVariables = Exact<{
  name: Scalars["String"];
}>;

export type CreateUserAgreementMutation = {
  __typename?: "Mutation";
  createUserAgreement?: {
    __typename?: "UserAgreement";
    id?: string | null;
  } | null;
};

export type UpdateUserAgreementMutationVariables = Exact<{
  userAgreementId: Scalars["String"];
  name: Scalars["String"];
}>;

export type UpdateUserAgreementMutation = {
  __typename?: "Mutation";
  updateUserAgreement?: {
    __typename?: "UserAgreement";
    id?: string | null;
  } | null;
};

export type DeleteUserAgreementMutationVariables = Exact<{
  userAgreementId: Scalars["String"];
}>;

export type DeleteUserAgreementMutation = {
  __typename?: "Mutation";
  deleteUserAgreement?: boolean | null;
};

export type CreateAnimationBundleMutationVariables = Exact<{
  animationData: CreateAnimationBundleInput;
  photo?: InputMaybe<Scalars["Upload"]>;
}>;

export type CreateAnimationBundleMutation = {
  __typename?: "Mutation";
  createAnimationBundle?: {
    __typename?: "AnimationBundle";
    id?: string | null;
    type?: {
      __typename?: "AnimationBundleType";
      type?: AnimationBundleTypeEnum | null;
    } | null;
    participants?: Array<{
      __typename?: "User";
      id?: string | null;
    } | null> | null;
    iosBundle?: {
      __typename?: "IosBundle";
      id?: string | null;
      name?: string | null;
      fileLink?: string | null;
    } | null;
    androidBundle?: {
      __typename?: "AndroidBundle";
      id?: string | null;
      name?: string | null;
      fileLink?: string | null;
    } | null;
  } | null;
};

export type UpdateAnimationBundleMutationVariables = Exact<{
  animationData: UpdateAnimationBundleInput;
  photo?: InputMaybe<Scalars["Upload"]>;
}>;

export type UpdateAnimationBundleMutation = {
  __typename?: "Mutation";
  updateAnimationBundle?: {
    __typename?: "AnimationBundle";
    id?: string | null;
  } | null;
};

export type DeleteAnimationBundleMutationVariables = Exact<{
  animationId: Scalars["String"];
}>;

export type DeleteAnimationBundleMutation = {
  __typename?: "Mutation";
  deleteAnimationBundle?: boolean | null;
};

export type AssignAnimationBundleParticipantsMutationVariables = Exact<{
  animationId: Scalars["String"];
  userIds: Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>;
  availableForEveryone: Scalars["Boolean"];
}>;

export type AssignAnimationBundleParticipantsMutation = {
  __typename?: "Mutation";
  assignAnimationBundleParticipants?: boolean | null;
};

export type SetIosBundleMutationVariables = Exact<{
  animationId: Scalars["String"];
  deletedIosBundleId?: InputMaybe<Scalars["String"]>;
  newIosBundle?: InputMaybe<Scalars["Upload"]>;
}>;

export type SetIosBundleMutation = {
  __typename?: "Mutation";
  setIosBundle?: boolean | null;
};

export type SetAndroidBundleMutationVariables = Exact<{
  animationId: Scalars["String"];
  deletedAndroidBundleId?: InputMaybe<Scalars["String"]>;
  newAndroidBundle?: InputMaybe<Scalars["Upload"]>;
}>;

export type SetAndroidBundleMutation = {
  __typename?: "Mutation";
  setAndroidBundle?: boolean | null;
};

export type CreateAppMainThemeBackgroundMutationVariables = Exact<{
  name: Scalars["String"];
  fileIOS: Scalars["Upload"];
  fileAndroid: Scalars["Upload"];
  fileWebGl: Scalars["Upload"];
  manifestIOS?: InputMaybe<Scalars["Upload"]>;
  manifestAndroid?: InputMaybe<Scalars["Upload"]>;
  manifestWebGl?: InputMaybe<Scalars["Upload"]>;
}>;

export type CreateAppMainThemeBackgroundMutation = {
  __typename?: "Mutation";
  createAppMainThemeBackground?: {
    __typename?: "AppMainThemeBackground";
    id?: string | null;
  } | null;
};

export type UpdateAppMainThemeBackgroundMutationVariables = Exact<{
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  fileIOS?: InputMaybe<Scalars["Upload"]>;
  fileAndroid?: InputMaybe<Scalars["Upload"]>;
  fileWebGl?: InputMaybe<Scalars["Upload"]>;
  manifestIOS?: InputMaybe<Scalars["Upload"]>;
  manifestAndroid?: InputMaybe<Scalars["Upload"]>;
  manifestWebGl?: InputMaybe<Scalars["Upload"]>;
}>;

export type UpdateAppMainThemeBackgroundMutation = {
  __typename?: "Mutation";
  updateAppMainThemeBackground?: {
    __typename?: "AppMainThemeBackground";
    id?: string | null;
  } | null;
};

export type DeleteAppMainThemeBackgroundMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteAppMainThemeBackgroundMutation = {
  __typename?: "Mutation";
  deleteAppMainThemeBackground?: boolean | null;
};

export type UpdateApplicationFilesMutationVariables = Exact<{
  applicationId: Scalars["String"];
  file: Scalars["Upload"];
  ApplicationFileType: ApplicationFileTypeEnum;
}>;

export type UpdateApplicationFilesMutation = {
  __typename?: "Mutation";
  updateApplicationFiles?: boolean | null;
};

export type DeleteApplicationFileMutationVariables = Exact<{
  applicationId: Scalars["String"];
  ApplicationFileType: ApplicationFileTypeEnum;
}>;

export type DeleteApplicationFileMutation = {
  __typename?: "Mutation";
  deleteApplicationFile?: boolean | null;
};

export type CreateApplicationLinkMutationVariables = Exact<{
  name: Scalars["String"];
  link: Scalars["String"];
  applicationId: Scalars["String"];
}>;

export type CreateApplicationLinkMutation = {
  __typename?: "Mutation";
  createApplicationLink?: {
    __typename?: "ApplicationLink";
    id?: string | null;
  } | null;
};

export type UpdateApplicationLinkMutationVariables = Exact<{
  name?: InputMaybe<Scalars["String"]>;
  link?: InputMaybe<Scalars["String"]>;
  applicationLinkId: Scalars["String"];
}>;

export type UpdateApplicationLinkMutation = {
  __typename?: "Mutation";
  updateApplicationLink?: {
    __typename?: "ApplicationLink";
    id?: string | null;
  } | null;
};

export type DeleteApplicationLinkMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteApplicationLinkMutation = {
  __typename?: "Mutation";
  deleteApplicationLink?: boolean | null;
};

export type CreateApplicationMainMutationVariables = Exact<{
  applicationMainData: CreateApplicationMainInput;
}>;

export type CreateApplicationMainMutation = {
  __typename?: "Mutation";
  createApplicationMain?: {
    __typename?: "ApplicationMain";
    id?: string | null;
  } | null;
};

export type UpdateApplicationMainMutationVariables = Exact<{
  applicationMainData: UpdateApplicationMainInput;
}>;

export type UpdateApplicationMainMutation = {
  __typename?: "Mutation";
  updateApplicationMain?: {
    __typename?: "ApplicationMain";
    id?: string | null;
  } | null;
};

export type UpdateApplicationMainPhotoMutationVariables = Exact<{
  applicationMainId: Scalars["String"];
  photo?: InputMaybe<Scalars["Upload"]>;
  icon?: InputMaybe<Scalars["Upload"]>;
}>;

export type UpdateApplicationMainPhotoMutation = {
  __typename?: "Mutation";
  updateApplicationMainPhoto?: boolean | null;
};

export type DeleteApplicationMainMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteApplicationMainMutation = {
  __typename?: "Mutation";
  deleteApplicationMain?: boolean | null;
};

export type DeleteApplicationMainIconMutationVariables = Exact<{
  applicationMainId: Scalars["String"];
}>;

export type DeleteApplicationMainIconMutation = {
  __typename?: "Mutation";
  deleteApplicationMainIcon?: boolean | null;
};

export type PublishApplicationMainMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type PublishApplicationMainMutation = {
  __typename?: "Mutation";
  publishApplicationMain?: boolean | null;
};

export type SetAccessToApplicationMainMutationVariables = Exact<{
  applicationMainData: HandleAccessInput;
}>;

export type SetAccessToApplicationMainMutation = {
  __typename?: "Mutation";
  handleAccess?: boolean | null;
};

export type CreateApplicationMainModelMutationVariables = Exact<{
  modelData: CreateApplicationMainModelInput;
}>;

export type CreateApplicationMainModelMutation = {
  __typename?: "Mutation";
  createApplicationModel?: {
    __typename?: "ApplicationMainModel";
    id?: string | null;
  } | null;
};

export type UpdateApplicationMainModelMutationVariables = Exact<{
  modelData: UpdateApplicationMainModelInput;
}>;

export type UpdateApplicationMainModelMutation = {
  __typename?: "Mutation";
  updateApplicationModel?: {
    __typename?: "ApplicationMainModel";
    id?: string | null;
  } | null;
};

export type DeleteApplicationMainModelMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteApplicationMainModelMutation = {
  __typename?: "Mutation";
  deleteApplicationModel?: boolean | null;
};

export type PublishApplicationMainModelMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type PublishApplicationMainModelMutation = {
  __typename?: "Mutation";
  publishApplicationMainModel?: boolean | null;
};

export type CreateContentPreviewMutationVariables = Exact<{
  contentLink: ContentLinkInput;
  file?: InputMaybe<Scalars["Upload"]>;
  videoUrl?: InputMaybe<Scalars["String"]>;
}>;

export type CreateContentPreviewMutation = {
  __typename?: "Mutation";
  createContentPreview?: {
    __typename?: "ContentPreview";
    id?: string | null;
  } | null;
};

export type UpdateContentPreviewOrderMutationVariables = Exact<{
  updateContentPreviewOrderId: Scalars["String"];
  order: Scalars["Int"];
}>;

export type UpdateContentPreviewOrderMutation = {
  __typename?: "Mutation";
  updateContentPreviewOrder?: boolean | null;
};

export type DeleteContentPreviewMutationVariables = Exact<{
  deleteContentPreviewId: Scalars["String"];
}>;

export type DeleteContentPreviewMutation = {
  __typename?: "Mutation";
  deleteContentPreview?: boolean | null;
};

export type SetApplicationMainModelExperimentTypeMutationVariables = Exact<{
  setApplicationMainModelExperimentTypeId: Scalars["String"];
  contentType?: InputMaybe<ContentTypeEnum>;
}>;

export type SetApplicationMainModelExperimentTypeMutation = {
  __typename?: "Mutation";
  setApplicationMainModelExperimentType?: boolean | null;
};

export type CreateApplicationMainThemeMutationVariables = Exact<{
  applicationMainId: Scalars["String"];
  nameEN?: InputMaybe<Scalars["String"]>;
  nameUKR?: InputMaybe<Scalars["String"]>;
}>;

export type CreateApplicationMainThemeMutation = {
  __typename?: "Mutation";
  createApplicationMainTheme?: {
    __typename?: "ApplicationMainTheme";
    id?: string | null;
  } | null;
};

export type UpdateApplicationMainThemeMutationVariables = Exact<{
  id: Scalars["String"];
  nameEN?: InputMaybe<Scalars["String"]>;
  nameUKR?: InputMaybe<Scalars["String"]>;
  backgroundId?: InputMaybe<Scalars["String"]>;
  analyticsMark?: InputMaybe<Scalars["String"]>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanStatusEnum>;
}>;

export type UpdateApplicationMainThemeMutation = {
  __typename?: "Mutation";
  updateApplicationMainTheme?: {
    __typename?: "ApplicationMainTheme";
    id?: string | null;
  } | null;
};

export type DeleteApplicationMainThemeMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteApplicationMainThemeMutation = {
  __typename?: "Mutation";
  deleteApplicationMainTheme?: boolean | null;
};

export type AddApplicationMainThemePhotoMutationVariables = Exact<{
  id: Scalars["String"];
  photo: Scalars["Upload"];
}>;

export type AddApplicationMainThemePhotoMutation = {
  __typename?: "Mutation";
  addApplicationMainThemePhoto?: boolean | null;
};

export type DeleteApplicationMainThemePhotoMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteApplicationMainThemePhotoMutation = {
  __typename?: "Mutation";
  deleteApplicationMainThemePhoto?: boolean | null;
};

export type UpdateApplicationModelPhotoMutationVariables = Exact<{
  modelId: Scalars["String"];
  file: Scalars["Upload"];
}>;

export type UpdateApplicationModelPhotoMutation = {
  __typename?: "Mutation";
  updateApplicationModelPhoto?: boolean | null;
};

export type UpdateApplicationModelMarkerPhotoMutationVariables = Exact<{
  modelId: Scalars["String"];
  file?: InputMaybe<Scalars["Upload"]>;
  isMarker: Scalars["Boolean"];
}>;

export type UpdateApplicationModelMarkerPhotoMutation = {
  __typename?: "Mutation";
  updateApplicationModelMarkerPhoto?: boolean | null;
};

export type UpdateApplicationModelMapMarkerPhotoMutationVariables = Exact<{
  modelId: Scalars["String"];
  file: Scalars["Upload"];
}>;

export type UpdateApplicationModelMapMarkerPhotoMutation = {
  __typename?: "Mutation";
  updateApplicationModelMapMarkerPhoto?: boolean | null;
};

export type CreateApplicationMainModelFileMutationVariables = Exact<{
  fileData: CreateApplicationMainModelFileInput;
}>;

export type CreateApplicationMainModelFileMutation = {
  __typename?: "Mutation";
  createApplicationMainModelFile?: {
    __typename?: "ApplicationMainModelFile";
    id?: string | null;
  } | null;
};

export type UpdateApplicationModelBundleMutationVariables = Exact<{
  modelId: Scalars["String"];
  bundleType: ApplicationBundleTypeEnum;
  bundleFileType?: InputMaybe<BundleFileTypeEnum>;
  file?: InputMaybe<Scalars["Upload"]>;
  bundleLink?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateApplicationModelBundleMutation = {
  __typename?: "Mutation";
  updateApplicationModelBundle?: boolean | null;
};

export type DeleteApplicationMainModelFilesMutationVariables = Exact<{
  modelId: Scalars["String"];
  modelFileType: ModelFileTypeEnum;
}>;

export type DeleteApplicationMainModelFilesMutation = {
  __typename?: "Mutation";
  deleteApplicationModelFiles?: boolean | null;
};

export type DeleteApplicationMainModelFileMutationVariables = Exact<{
  fileId: Scalars["String"];
}>;

export type DeleteApplicationMainModelFileMutation = {
  __typename?: "Mutation";
  deleteApplicationMainModelFile?: boolean | null;
};

export type UpdateApplicationMainModelFileMutationVariables = Exact<{
  fileData: UpdateApplicationMainModelFileInput;
}>;

export type UpdateApplicationMainModelFileMutation = {
  __typename?: "Mutation";
  updateApplicationMainModelFile?: {
    __typename?: "ApplicationMainModelFile";
    id?: string | null;
  } | null;
};

export type UpdateApplicationModelColoringPageFileMutationVariables = Exact<{
  modelId: Scalars["String"];
  coloringPageFile: Scalars["Upload"];
}>;

export type UpdateApplicationModelColoringPageFileMutation = {
  __typename?: "Mutation";
  updateApplicationModelColoringPageFile?: boolean | null;
};

export type CreateApplicationVersionMutationVariables = Exact<{
  appId: Scalars["String"];
  os: Os;
  version: Scalars["String"];
}>;

export type CreateApplicationVersionMutation = {
  __typename?: "Mutation";
  createApplicationVersion?: {
    __typename?: "ApplicationVersion";
    appId?: string | null;
    os?: Os | null;
    createdDate?: any | null;
  } | null;
};

export type CreateApplicationMutationVariables = Exact<{
  applicationData: CreateApplicationInput;
}>;

export type CreateApplicationMutation = {
  __typename?: "Mutation";
  createApplication?: { __typename?: "Application"; id?: string | null } | null;
};

export type UpdateApplicationMutationVariables = Exact<{
  applicationData: UpdateApplicationInput;
}>;

export type UpdateApplicationMutation = {
  __typename?: "Mutation";
  updateApplication?: { __typename?: "Application"; id?: string | null } | null;
};

export type DeleteApplicationMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteApplicationMutation = {
  __typename?: "Mutation";
  deleteApplication?: boolean | null;
};

export type RemoveUsersFromGroupMutationVariables = Exact<{
  userIds: Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>;
  userGroupId: Scalars["String"];
}>;

export type RemoveUsersFromGroupMutation = {
  __typename?: "Mutation";
  removeUsersFromGroup?: boolean | null;
};

export type AssignUsersToGroupMutationVariables = Exact<{
  userIds: Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>;
  userGroupId: Scalars["String"];
}>;

export type AssignUsersToGroupMutation = {
  __typename?: "Mutation";
  assignUsersToGroup?: boolean | null;
};

export type AssignUsersToProjectMutationVariables = Exact<{
  userIds: Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>;
  projectId: Scalars["String"];
}>;

export type AssignUsersToProjectMutation = {
  __typename?: "Mutation";
  assignUsersToProject?: boolean | null;
};

export type RemoveUsersFromProjectMutationVariables = Exact<{
  userIds: Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>;
  projectId: Scalars["String"];
}>;

export type RemoveUsersFromProjectMutation = {
  __typename?: "Mutation";
  removeUsersFromProject?: boolean | null;
};

export type LoginMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
  isFrom: IsFromEnum;
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login?: { __typename?: "Auth"; token?: string | null } | null;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: "Mutation"; logout: boolean };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars["String"];
  isFrom: IsFromEnum;
}>;

export type ForgotPasswordMutation = {
  __typename?: "Mutation";
  forgotPassword?: boolean | null;
};

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars["String"];
  token: Scalars["String"];
  isFrom: IsFromEnum;
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword?: { __typename?: "Auth"; token?: string | null } | null;
};

export type ConfirmUserMutationVariables = Exact<{
  token: Scalars["String"];
}>;

export type ConfirmUserMutation = {
  __typename?: "Mutation";
  confirmUser?: boolean | null;
};

export type CreatePayrollBonusMutationVariables = Exact<{
  payrollId: Scalars["String"];
  name: Scalars["String"];
  value: Scalars["Int"];
}>;

export type CreatePayrollBonusMutation = {
  __typename?: "Mutation";
  createPayrollBonus?: {
    __typename?: "PayrollBonus";
    id?: string | null;
    name?: string | null;
    value?: number | null;
  } | null;
};

export type UpdatePayrollBonusMutationVariables = Exact<{
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["Int"]>;
}>;

export type UpdatePayrollBonusMutation = {
  __typename?: "Mutation";
  updatePayrollBonus?: {
    __typename?: "PayrollBonus";
    id?: string | null;
    name?: string | null;
    value?: number | null;
  } | null;
};

export type DeletePayrollBonusMutationVariables = Exact<{
  bonusId: Scalars["String"];
}>;

export type DeletePayrollBonusMutation = {
  __typename?: "Mutation";
  deletePayrollBonus?: boolean | null;
};

export type CreatePtCheckListItemMutationVariables = Exact<{
  projectTaskId: Scalars["String"];
  name: Scalars["String"];
}>;

export type CreatePtCheckListItemMutation = {
  __typename?: "Mutation";
  createPTCheckListItem?: {
    __typename?: "ProjectTaskCheckListItem";
    id?: string | null;
  } | null;
};

export type UpdatePtCheckListItemMutationVariables = Exact<{
  id: Scalars["String"];
  name: Scalars["String"];
}>;

export type UpdatePtCheckListItemMutation = {
  __typename?: "Mutation";
  updatePTCheckListItem?: {
    __typename?: "ProjectTaskCheckListItem";
    id?: string | null;
  } | null;
};

export type DeletePtCheckListItemMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeletePtCheckListItemMutation = {
  __typename?: "Mutation";
  deletePTCheckListItem?: boolean | null;
};

export type ChangePtCheckListItemStatusMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type ChangePtCheckListItemStatusMutation = {
  __typename?: "Mutation";
  changePTCheckListItemStatus?: {
    __typename?: "ProjectTaskCheckListItem";
    id?: string | null;
  } | null;
};

export type DeleteClassroomMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteClassroomMutation = {
  __typename?: "Mutation";
  deleteClassroom?: boolean | null;
};

export type CreateClientSiteModelMutationVariables = Exact<{
  name: Scalars["String"];
  clientSiteId: Scalars["String"];
  itemIds: Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>;
}>;

export type CreateClientSiteModelMutation = {
  __typename?: "Mutation";
  createClientSiteModel?: {
    __typename?: "ClientSiteModel";
    id?: string | null;
    name?: string | null;
    itemId?: string | null;
    items?: Array<{
      __typename?: "ClientSiteModelItem";
      id?: string | null;
      itemId?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateClientSiteModelMutationVariables = Exact<{
  id: Scalars["String"];
  name: Scalars["String"];
}>;

export type UpdateClientSiteModelMutation = {
  __typename?: "Mutation";
  updateClientSiteModel?: {
    __typename?: "ClientSiteModel";
    id?: string | null;
  } | null;
};

export type DeleteClientSiteModelMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteClientSiteModelMutation = {
  __typename?: "Mutation";
  deleteClientSiteModel?: boolean | null;
};

export type UploadClientSiteModelFileMutationVariables = Exact<{
  clientSiteModelId: Scalars["String"];
  file: Scalars["Upload"];
  fileType: ClientSiteModelFilesEnum;
}>;

export type UploadClientSiteModelFileMutation = {
  __typename?: "Mutation";
  uploadClientSiteModelFile?: boolean | null;
};

export type DeleteClientSiteModelFileMutationVariables = Exact<{
  clientSiteModelId: Scalars["String"];
  fileType: ClientSiteModelFilesEnum;
}>;

export type DeleteClientSiteModelFileMutation = {
  __typename?: "Mutation";
  deleteClientSiteModelFile?: boolean | null;
};

export type CreateClientSiteModelItemMutationVariables = Exact<{
  clientSiteModelId: Scalars["String"];
  itemId: Scalars["String"];
}>;

export type CreateClientSiteModelItemMutation = {
  __typename?: "Mutation";
  createClientSiteModelItem?: {
    __typename?: "ClientSiteModelItem";
    id?: string | null;
  } | null;
};

export type UpdateClientSiteModelItemMutationVariables = Exact<{
  id: Scalars["String"];
  itemId: Scalars["String"];
}>;

export type UpdateClientSiteModelItemMutation = {
  __typename?: "Mutation";
  updateClientSiteModelItem?: {
    __typename?: "ClientSiteModelItem";
    id?: string | null;
  } | null;
};

export type DeleteClientSiteModelItemMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteClientSiteModelItemMutation = {
  __typename?: "Mutation";
  deleteClientSiteModelItem?: boolean | null;
};

export type CreateClientSiteMutationVariables = Exact<{
  name: Scalars["String"];
  link?: InputMaybe<Scalars["String"]>;
  iframe: Scalars["String"];
  key: Scalars["String"];
}>;

export type CreateClientSiteMutation = {
  __typename?: "Mutation";
  createClientSite?: {
    __typename?: "ClientSite";
    id?: string | null;
    name?: string | null;
    link?: string | null;
    iframe?: string | null;
    models?: Array<{
      __typename?: "ClientSiteModel";
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateClientSiteMutationVariables = Exact<{
  id: Scalars["String"];
  name: Scalars["String"];
  link?: InputMaybe<Scalars["String"]>;
  iframe: Scalars["String"];
  key: Scalars["String"];
}>;

export type UpdateClientSiteMutation = {
  __typename?: "Mutation";
  updateClientSite?: {
    __typename?: "ClientSite";
    id?: string | null;
    name?: string | null;
    link?: string | null;
    iframe?: string | null;
    models?: Array<{
      __typename?: "ClientSiteModel";
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type DeleteClientSiteMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteClientSiteMutation = {
  __typename?: "Mutation";
  deleteClientSite?: boolean | null;
};

export type UpdateProjectCorrectionMutationVariables = Exact<{
  projectCorrectionData: UpdateProjectCorrectionInput;
}>;

export type UpdateProjectCorrectionMutation = {
  __typename?: "Mutation";
  updateProjectCorrection?: {
    __typename?: "ProjectCorrection";
    id?: string | null;
  } | null;
};

export type CreateCorrectionCommentMutationVariables = Exact<{
  correctionCommentData: CreateCorrectionCommentInput;
}>;

export type CreateCorrectionCommentMutation = {
  __typename?: "Mutation";
  createCorrectionComment?: {
    __typename?: "CorrectionComment";
    id?: string | null;
  } | null;
};

export type UpdateCorrectionCommentMutationVariables = Exact<{
  correctionCommentData: UpdateCorrectionCommentInput;
}>;

export type UpdateCorrectionCommentMutation = {
  __typename?: "Mutation";
  updateCorrectionComment?: {
    __typename?: "CorrectionComment";
    id?: string | null;
  } | null;
};

export type DeleteCorrectionCommentMutationVariables = Exact<{
  correctionCommentId: Scalars["String"];
}>;

export type DeleteCorrectionCommentMutation = {
  __typename?: "Mutation";
  deleteCorrectionComment?: boolean | null;
};

export type CreateUserDepartmentMutationVariables = Exact<{
  name: Scalars["String"];
}>;

export type CreateUserDepartmentMutation = {
  __typename?: "Mutation";
  createUserDepartment?: {
    __typename?: "UserDepartment";
    id?: string | null;
  } | null;
};

export type UpdateUserDepartmentMutationVariables = Exact<{
  departmentId: Scalars["String"];
  name: Scalars["String"];
}>;

export type UpdateUserDepartmentMutation = {
  __typename?: "Mutation";
  updateUserDepartment?: {
    __typename?: "UserDepartment";
    id?: string | null;
  } | null;
};

export type DeleteUserDepartmentMutationVariables = Exact<{
  departmentId: Scalars["String"];
}>;

export type DeleteUserDepartmentMutation = {
  __typename?: "Mutation";
  deleteUserDepartment?: boolean | null;
};

export type CreateEmployeesDistributionMutationVariables = Exact<{
  distributionData: CreateEmployeesDistribution;
}>;

export type CreateEmployeesDistributionMutation = {
  __typename?: "Mutation";
  createEmployeesDistribution?: {
    __typename?: "EmployeesDistribution";
    id?: string | null;
  } | null;
};

export type CreateEmployeesDistributionsMutationVariables = Exact<{
  name: Scalars["String"];
}>;

export type CreateEmployeesDistributionsMutation = {
  __typename?: "Mutation";
  createEmployeesDistributions?: Array<{
    __typename?: "EmployeesDistribution";
    id?: string | null;
  } | null> | null;
};

export type UpdateEmployeesDistributionMutationVariables = Exact<{
  distributionData: UpdateEmployeesDistribution;
}>;

export type UpdateEmployeesDistributionMutation = {
  __typename?: "Mutation";
  updateEmployeesDistribution?: {
    __typename?: "EmployeesDistribution";
    id?: string | null;
  } | null;
};

export type DeleteEmployeesDistributionMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteEmployeesDistributionMutation = {
  __typename?: "Mutation";
  deleteEmployeesDistribution?: boolean | null;
};

export type CreateEventMutationVariables = Exact<{
  name: Scalars["String"];
  photo?: InputMaybe<Scalars["Upload"]>;
  description?: InputMaybe<Scalars["String"]>;
  speaker?: InputMaybe<Scalars["String"]>;
}>;

export type CreateEventMutation = {
  __typename?: "Mutation";
  createEvent?: {
    __typename?: "Event";
    id?: string | null;
    name?: string | null;
    photo?: string | null;
    description?: string | null;
    speaker?: string | null;
  } | null;
};

export type DeleteEventMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteEventMutation = {
  __typename?: "Mutation";
  deleteEvent?: boolean | null;
};

export type UpdateEventMutationVariables = Exact<{
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  photo?: InputMaybe<Scalars["Upload"]>;
  speaker?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateEventMutation = {
  __typename?: "Mutation";
  updateEvent?: {
    __typename?: "Event";
    id?: string | null;
    name?: string | null;
    photo?: string | null;
    description?: string | null;
    speaker?: string | null;
  } | null;
};

export type CreateEventDateMutationVariables = Exact<{
  date: Scalars["String"];
  link?: InputMaybe<Scalars["String"]>;
  userLimit?: InputMaybe<Scalars["Int"]>;
  eventId: Scalars["String"];
}>;

export type CreateEventDateMutation = {
  __typename?: "Mutation";
  createEventDate?: {
    __typename?: "EventDate";
    date?: string | null;
    link?: string | null;
    userLimit?: number | null;
  } | null;
};

export type UpdateEventDateMutationVariables = Exact<{
  id: Scalars["String"];
  date?: InputMaybe<Scalars["String"]>;
  link?: InputMaybe<Scalars["String"]>;
  userLimit?: InputMaybe<Scalars["Int"]>;
  eventId?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateEventDateMutation = {
  __typename?: "Mutation";
  updateEventDate?: {
    __typename?: "EventDate";
    id?: string | null;
    date?: string | null;
    link?: string | null;
    userLimit?: number | null;
  } | null;
};

export type DeleteEventDateMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteEventDateMutation = {
  __typename?: "Mutation";
  deleteEventDate?: boolean | null;
};

export type EventDatePresentUserMutationVariables = Exact<{
  eventDateId: Scalars["String"];
  userIds: Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>;
}>;

export type EventDatePresentUserMutation = {
  __typename?: "Mutation";
  eventDatePresentUser?: boolean | null;
};

export type EventDateAbsentUserMutationVariables = Exact<{
  eventDateId: Scalars["String"];
  userId: Scalars["String"];
}>;

export type EventDateAbsentUserMutation = {
  __typename?: "Mutation";
  eventDateAbsentUser?: boolean | null;
};

export type CreateExerciseMutationVariables = Exact<{
  exerciseData: CreateExerciseInput;
}>;

export type CreateExerciseMutation = {
  __typename?: "Mutation";
  createExercise?: {
    __typename?: "Exercise";
    id?: string | null;
    type?: ExerciseTypeEnum | null;
  } | null;
};

export type UpdateExerciseMutationVariables = Exact<{
  id: Scalars["String"];
  exerciseData: UpdateExerciseInput;
}>;

export type UpdateExerciseMutation = {
  __typename?: "Mutation";
  updateExercise?: {
    __typename?: "Exercise";
    contentEN?: string | null;
    contentUKR?: string | null;
    video?: string | null;
    pauseTime?: number | null;
    type?: ExerciseTypeEnum | null;
  } | null;
};

export type DeleteExerciseMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteExerciseMutation = {
  __typename?: "Mutation";
  deleteExercise?: boolean | null;
};

export type UpdateFeedbackMutationVariables = Exact<{
  feedbackData: UpdateFeedbackInput;
}>;

export type UpdateFeedbackMutation = {
  __typename?: "Mutation";
  updateFeedback?: { __typename?: "Feedback"; id?: string | null } | null;
};

export type CreateFeedbackMutationVariables = Exact<{
  text: Scalars["String"];
  files?: InputMaybe<
    Array<InputMaybe<Scalars["Upload"]>> | InputMaybe<Scalars["Upload"]>
  >;
}>;

export type CreateFeedbackMutation = {
  __typename?: "Mutation";
  createFeedback?: {
    __typename?: "Feedback";
    text?: string | null;
    files?: Array<{
      __typename?: "UploadedFile";
      file?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type CreateFeedbackCommentMutationVariables = Exact<{
  text: Scalars["String"];
  feedbackId: Scalars["String"];
  files?: InputMaybe<
    Array<InputMaybe<Scalars["Upload"]>> | InputMaybe<Scalars["Upload"]>
  >;
}>;

export type CreateFeedbackCommentMutation = {
  __typename?: "Mutation";
  createFeedbackComment?: {
    __typename?: "FeedbackComment";
    text?: string | null;
    id?: string | null;
    files?: Array<{
      __typename?: "UploadedFile";
      id?: string | null;
      file?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateFeedbackCommentMutationVariables = Exact<{
  id: Scalars["String"];
  text?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateFeedbackCommentMutation = {
  __typename?: "Mutation";
  updateFeedbackComment?: {
    __typename?: "FeedbackComment";
    id?: string | null;
    text?: string | null;
    files?: Array<{
      __typename?: "UploadedFile";
      file?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type DeleteFeedbackCommentMutationVariables = Exact<{
  feedbackCommentId: Scalars["String"];
}>;

export type DeleteFeedbackCommentMutation = {
  __typename?: "Mutation";
  deleteFeedbackComment?: boolean | null;
};

export type DeleteFeedbackMutationVariables = Exact<{
  feedbackId: Scalars["String"];
}>;

export type DeleteFeedbackMutation = {
  __typename?: "Mutation";
  deleteFeedback?: boolean | null;
};

export type CreateRobotsHistoryLinkMutationVariables = Exact<{
  input: CreateRobotsHistoryInput;
}>;

export type CreateRobotsHistoryLinkMutation = {
  __typename?: "Mutation";
  createRobotsHistoryLink?: {
    __typename?: "RobotsHistory";
    id?: any | null;
    link?: string | null;
    order?: number | null;
  } | null;
};

export type DeleteRobotsHistoryMutationVariables = Exact<{
  deleteRobotsHistoryId: Scalars["UUID"];
}>;

export type DeleteRobotsHistoryMutation = {
  __typename?: "Mutation";
  deleteRobotsHistory?: boolean | null;
};

export type CreateAwardMutationVariables = Exact<{
  input: CreateAwardInput;
}>;

export type CreateAwardMutation = {
  __typename?: "Mutation";
  createAward?: { __typename?: "Award"; id?: any | null } | null;
};

export type UpdateAwardMutationVariables = Exact<{
  input: UpdateAwardInput;
}>;

export type UpdateAwardMutation = {
  __typename?: "Mutation";
  updateAward?: { __typename?: "Award"; id?: any | null } | null;
};

export type DeleteAwardMutationVariables = Exact<{
  deleteAwardId: Scalars["UUID"];
}>;

export type DeleteAwardMutation = {
  __typename?: "Mutation";
  deleteAward?: boolean | null;
};

export type PublishAwardsMutationVariables = Exact<{
  ids: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type PublishAwardsMutation = {
  __typename?: "Mutation";
  publishAwards?: boolean | null;
};

export type UnpublishAwardsMutationVariables = Exact<{
  ids: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type UnpublishAwardsMutation = {
  __typename?: "Mutation";
  unpublishAwards?: boolean | null;
};

export type ReorderAwardsMutationVariables = Exact<{
  input: Array<AwardReorderInput> | AwardReorderInput;
}>;

export type ReorderAwardsMutation = {
  __typename?: "Mutation";
  reorderAwards?: boolean | null;
};

export type CreateGameModelMutationVariables = Exact<{
  input: GameModelInput;
}>;

export type CreateGameModelMutation = {
  __typename?: "Mutation";
  createGameModel?: { __typename?: "GameModel"; id?: string | null } | null;
};

export type DeleteGameModelMutationVariables = Exact<{
  deleteGameModelId: Scalars["UUID"];
}>;

export type DeleteGameModelMutation = {
  __typename?: "Mutation";
  deleteGameModel?: boolean | null;
};

export type UpdateGameModelMutationVariables = Exact<{
  updateGameModelId: Scalars["UUID"];
  input: GameModelInput;
}>;

export type UpdateGameModelMutation = {
  __typename?: "Mutation";
  updateGameModel?: { __typename?: "GameModel"; id?: string | null } | null;
};

export type CreateGameModelStateMutationVariables = Exact<{
  input: GameModelStateInput;
}>;

export type CreateGameModelStateMutation = {
  __typename?: "Mutation";
  createGameModelState?: {
    __typename?: "GameModelState";
    id?: string | null;
  } | null;
};

export type DeleteGameModelStateMutationVariables = Exact<{
  deleteGameModelStateId: Scalars["UUID"];
}>;

export type DeleteGameModelStateMutation = {
  __typename?: "Mutation";
  deleteGameModelState?: boolean | null;
};

export type UpdateGameModelStateMutationVariables = Exact<{
  updateGameModelStateId: Scalars["UUID"];
  input: GameModelStateInput;
}>;

export type UpdateGameModelStateMutation = {
  __typename?: "Mutation";
  updateGameModelState?: {
    __typename?: "GameModelState";
    id?: string | null;
  } | null;
};

export type UpdateGameModelStateFileMutationVariables = Exact<{
  updateGameModelStateFileId: Scalars["UUID"];
  file: Scalars["Upload"];
  fileType: GameModelStateFileTypeEnum;
}>;

export type UpdateGameModelStateFileMutation = {
  __typename?: "Mutation";
  updateGameModelStateFile?: boolean | null;
};

export type DeleteGameModelStateFileMutationVariables = Exact<{
  deleteGameModelStateFileId: Scalars["UUID"];
  fileType: GameModelStateFileTypeEnum;
}>;

export type DeleteGameModelStateFileMutation = {
  __typename?: "Mutation";
  deleteGameModelStateFile?: boolean | null;
};

export type UpdateRewardMutationVariables = Exact<{
  updateRewardId: Scalars["UUID"];
  input: RewardInput;
}>;

export type UpdateRewardMutation = {
  __typename?: "Mutation";
  updateReward?: {
    __typename?: "Reward";
    id?: string | null;
    name?: string | null;
    order?: number | null;
    type?: RewardTypeEnum | null;
  } | null;
};

export type CreateGameModelArtifactMutationVariables = Exact<{
  input: GameModelArtifactInput;
}>;

export type CreateGameModelArtifactMutation = {
  __typename?: "Mutation";
  createGameModelArtifact?: {
    __typename?: "GameModelArtifact";
    id?: string | null;
  } | null;
};

export type DeleteGameModelArtifactMutationVariables = Exact<{
  deleteGameModelArtifactId: Scalars["UUID"];
}>;

export type DeleteGameModelArtifactMutation = {
  __typename?: "Mutation";
  deleteGameModelArtifact?: boolean | null;
};

export type UpdateGameModelArtifactMutationVariables = Exact<{
  updateGameModelArtifactId: Scalars["UUID"];
  input: GameModelArtifactInput;
}>;

export type UpdateGameModelArtifactMutation = {
  __typename?: "Mutation";
  updateGameModelArtifact?: {
    __typename?: "GameModelArtifact";
    id?: string | null;
  } | null;
};

export type UpdateGameModelArtifactFileMutationVariables = Exact<{
  updateGameModelArtifactFileId: Scalars["UUID"];
  file: Scalars["Upload"];
  fileType: GameModelArtifactFileTypeEnum;
}>;

export type UpdateGameModelArtifactFileMutation = {
  __typename?: "Mutation";
  updateGameModelArtifactFile?: boolean | null;
};

export type DeleteGameModelArtifactFileMutationVariables = Exact<{
  deleteGameModelArtifactFileId: Scalars["UUID"];
  fileType: GameModelArtifactFileTypeEnum;
}>;

export type DeleteGameModelArtifactFileMutation = {
  __typename?: "Mutation";
  deleteGameModelArtifactFile?: boolean | null;
};

export type CreateUserJobMutationVariables = Exact<{
  name: Scalars["String"];
}>;

export type CreateUserJobMutation = {
  __typename?: "Mutation";
  createUserJob?: {
    __typename?: "UserJob";
    id?: string | null;
    name?: string | null;
  } | null;
};

export type UpdateUserJobMutationVariables = Exact<{
  userJobId: Scalars["String"];
  name: Scalars["String"];
}>;

export type UpdateUserJobMutation = {
  __typename?: "Mutation";
  updateUserJob?: {
    __typename?: "UserJob";
    id?: string | null;
    name?: string | null;
  } | null;
};

export type DeleteUserJobMutationVariables = Exact<{
  userJobId: Scalars["String"];
}>;

export type DeleteUserJobMutation = {
  __typename?: "Mutation";
  deleteUserJob?: boolean | null;
};

export type CreateProjectKanbanMutationVariables = Exact<{
  projectId: Scalars["String"];
  name: Scalars["String"];
}>;

export type CreateProjectKanbanMutation = {
  __typename?: "Mutation";
  createProjectKanban?: {
    __typename?: "ProjectKanban";
    id?: string | null;
  } | null;
};

export type RestartSprintMutationVariables = Exact<{
  id: Scalars["String"];
  name: Scalars["String"];
}>;

export type RestartSprintMutation = {
  __typename?: "Mutation";
  restartSprint?: {
    __typename?: "ProjectKanban";
    name?: string | null;
    projectId?: string | null;
  } | null;
};

export type DeleteProjectKanbanMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteProjectKanbanMutation = {
  __typename?: "Mutation";
  deleteProjectKanban?: boolean | null;
};

export type ClearProjectKanbanMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type ClearProjectKanbanMutation = {
  __typename?: "Mutation";
  clearProjectKanban?: boolean | null;
};

export type CreateKanbanWorkflowStepForProjectMutationVariables = Exact<{
  name: Scalars["String"];
  taskStatusId: Scalars["String"];
  projectId: Scalars["String"];
  order: Scalars["Int"];
}>;

export type CreateKanbanWorkflowStepForProjectMutation = {
  __typename?: "Mutation";
  createKanbanWorkflowStepForProject?: {
    __typename?: "KanbanWorkflowStep";
    id?: string | null;
  } | null;
};

export type UpdateKanbanWorkflowStepMutationVariables = Exact<{
  id: Scalars["String"];
  name: Scalars["String"];
  order: Scalars["Int"];
}>;

export type UpdateKanbanWorkflowStepMutation = {
  __typename?: "Mutation";
  updateKanbanWorkflowStep?: {
    __typename?: "KanbanWorkflowStep";
    id?: string | null;
  } | null;
};

export type DeleteKanbanWorkflowStepMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteKanbanWorkflowStepMutation = {
  __typename?: "Mutation";
  deleteKanbanWorkflowStep?: boolean | null;
};

export type CreateKnowledgeBaseBlockMutationVariables = Exact<{
  type: KnowledgeBaseBlockTypeEnum;
  themeId: Scalars["String"];
  blockTypeData?: InputMaybe<BlockTypeInput>;
}>;

export type CreateKnowledgeBaseBlockMutation = {
  __typename?: "Mutation";
  createKnowledgeBaseBlock?: {
    __typename?: "KnowledgeBaseBlock";
    id?: string | null;
  } | null;
};

export type UpdateKnowledgeBaseBlockMutationVariables = Exact<{
  id: Scalars["String"];
  blockTypeData?: InputMaybe<BlockTypeInput>;
}>;

export type UpdateKnowledgeBaseBlockMutation = {
  __typename?: "Mutation";
  updateKnowledgeBaseBlock?: {
    __typename?: "KnowledgeBaseBlock";
    id?: string | null;
  } | null;
};

export type DeleteKnowledgeBaseBlockMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteKnowledgeBaseBlockMutation = {
  __typename?: "Mutation";
  deleteKnowledgeBaseBlock?: boolean | null;
};

export type UploudKnowledgeBaseBlockPhotoMutationVariables = Exact<{
  blockId: Scalars["String"];
  photo: Scalars["Upload"];
}>;

export type UploudKnowledgeBaseBlockPhotoMutation = {
  __typename?: "Mutation";
  uploudKnowledgeBaseBlockPhoto?: {
    __typename?: "KnowledgeBaseBlock";
    id?: string | null;
  } | null;
};

export type HandleKnowledgeBaseBlockEditorPhotoMutationVariables = Exact<{
  blockId: Scalars["String"];
  photo?: InputMaybe<Scalars["Upload"]>;
}>;

export type HandleKnowledgeBaseBlockEditorPhotoMutation = {
  __typename?: "Mutation";
  handleKnowledgeBaseBlockEditorPhoto?: {
    __typename?: "KnowledgeBaseBlock";
    id?: string | null;
    photo?: string | null;
  } | null;
};

export type CreateKnowledgeBaseBlockListItemMutationVariables = Exact<{
  blockId: Scalars["String"];
  content: Scalars["String"];
  isNumbered?: InputMaybe<Scalars["Boolean"]>;
  photo?: InputMaybe<Scalars["Upload"]>;
  video?: InputMaybe<Scalars["String"]>;
  photoTitle?: InputMaybe<Scalars["String"]>;
}>;

export type CreateKnowledgeBaseBlockListItemMutation = {
  __typename?: "Mutation";
  createKnowledgeBaseBlockListItem?: {
    __typename?: "KnowledgeBaseBlockList";
    id?: string | null;
  } | null;
};

export type UpdateKnowledgeBaseBlockListItemMutationVariables = Exact<{
  listItemId: Scalars["String"];
  content: Scalars["String"];
  isNumbered?: InputMaybe<Scalars["Boolean"]>;
  photo?: InputMaybe<Scalars["Upload"]>;
  video?: InputMaybe<Scalars["String"]>;
  photoTitle?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateKnowledgeBaseBlockListItemMutation = {
  __typename?: "Mutation";
  updateKnowledgeBaseBlockListItem?: {
    __typename?: "KnowledgeBaseBlockList";
    id?: string | null;
  } | null;
};

export type DeleteKnowledgeBaseBlockListItemMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteKnowledgeBaseBlockListItemMutation = {
  __typename?: "Mutation";
  deleteKnowledgeBaseBlockListItem?: boolean | null;
};

export type CreateKnowledgeBaseCategoryMutationVariables = Exact<{
  name: Scalars["String"];
  isFor: KnowledgeBaseCategoryIsForEnum;
  photo?: InputMaybe<Scalars["Upload"]>;
  backgroundColor?: InputMaybe<Scalars["String"]>;
}>;

export type CreateKnowledgeBaseCategoryMutation = {
  __typename?: "Mutation";
  createKnowledgeBaseCategory?: {
    __typename?: "KnowledgeBaseCategory";
    id?: string | null;
  } | null;
};

export type UpdateKnowledgeBaseCategoryMutationVariables = Exact<{
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  isFor: KnowledgeBaseCategoryIsForEnum;
  photo?: InputMaybe<Scalars["Upload"]>;
  backgroundColor?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateKnowledgeBaseCategoryMutation = {
  __typename?: "Mutation";
  updateKnowledgeBaseCategory?: {
    __typename?: "KnowledgeBaseCategory";
    id?: string | null;
  } | null;
};

export type DeleteKnowledgeBaseCategoryMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteKnowledgeBaseCategoryMutation = {
  __typename?: "Mutation";
  deleteKnowledgeBaseCategory?: boolean | null;
};

export type SetKnowledgeBaseCategoryParticipantsMutationVariables = Exact<{
  knowledgeBaseCategoryData: SetKnowledgeBaseCategoryParticipantsInput;
}>;

export type SetKnowledgeBaseCategoryParticipantsMutation = {
  __typename?: "Mutation";
  setKnowledgeBaseCategoryParticipants?: boolean | null;
};

export type CreateKnowledgeBaseBlockTestAnswerMutationVariables = Exact<{
  blockId: Scalars["String"];
  content: Scalars["String"];
  isAnswerTrue: Scalars["Boolean"];
}>;

export type CreateKnowledgeBaseBlockTestAnswerMutation = {
  __typename?: "Mutation";
  createKnowledgeBaseBlockTestAnswer?: {
    __typename?: "KnowledgeBaseBlockTestAnswer";
    id?: string | null;
  } | null;
};

export type UpdateKnowledgeBaseBlockTestAnswerMutationVariables = Exact<{
  answerId: Scalars["String"];
  content: Scalars["String"];
  isAnswerTrue: Scalars["Boolean"];
}>;

export type UpdateKnowledgeBaseBlockTestAnswerMutation = {
  __typename?: "Mutation";
  updateKnowledgeBaseBlockTestAnswer?: {
    __typename?: "KnowledgeBaseBlockTestAnswer";
    id?: string | null;
  } | null;
};

export type DeleteKnowledgeBaseBlockTestAnswerMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteKnowledgeBaseBlockTestAnswerMutation = {
  __typename?: "Mutation";
  deleteKnowledgeBaseBlockTestAnswer?: boolean | null;
};

export type CreateKnowledgeBaseThemeMutationVariables = Exact<{
  name: Scalars["String"];
  categoryId: Scalars["String"];
}>;

export type CreateKnowledgeBaseThemeMutation = {
  __typename?: "Mutation";
  createKnowledgeBaseTheme?: {
    __typename?: "KnowledgeBaseTheme";
    id?: string | null;
  } | null;
};

export type UpdateKnowledgeBaseThemeMutationVariables = Exact<{
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  categoryId: Scalars["String"];
}>;

export type UpdateKnowledgeBaseThemeMutation = {
  __typename?: "Mutation";
  updateKnowledgeBaseTheme?: {
    __typename?: "KnowledgeBaseTheme";
    id?: string | null;
  } | null;
};

export type DeleteKnowledgeBaseThemeMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteKnowledgeBaseThemeMutation = {
  __typename?: "Mutation";
  deleteKnowledgeBaseTheme?: boolean | null;
};

export type PublishKnowledgeBaseThemeMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type PublishKnowledgeBaseThemeMutation = {
  __typename?: "Mutation";
  publishKnowledgeBaseTheme?: boolean | null;
};

export type PublishKnowledgeBaseTestMutationVariables = Exact<{
  themeId: Scalars["String"];
}>;

export type PublishKnowledgeBaseTestMutation = {
  __typename?: "Mutation";
  publishKnowledgeBaseTestBlocksByThemeId?: boolean | null;
};

export type CreatePayrollKpiMutationVariables = Exact<{
  kpiData: CreatePayrollKpiInput;
}>;

export type CreatePayrollKpiMutation = {
  __typename?: "Mutation";
  createPayrollKpi?: {
    __typename?: "PayrollKpi";
    id?: string | null;
    name?: string | null;
  } | null;
};

export type UpdatePayrollKpiMutationVariables = Exact<{
  kpiData: UpdatePayrollKpiInput;
}>;

export type UpdatePayrollKpiMutation = {
  __typename?: "Mutation";
  updatePayrollKpi?: {
    __typename?: "PayrollKpi";
    id?: string | null;
    name?: string | null;
  } | null;
};

export type DeletePayrollKpiMutationVariables = Exact<{
  kpiId: Scalars["String"];
}>;

export type DeletePayrollKpiMutation = {
  __typename?: "Mutation";
  deletePayrollKpi?: boolean | null;
};

export type CreateLessonMutationVariables = Exact<{
  mainId: Scalars["String"];
  themeId?: InputMaybe<Scalars["String"]>;
  modelId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
}>;

export type CreateLessonMutation = {
  __typename?: "Mutation";
  createLesson?: { __typename?: "Lesson"; id?: string | null } | null;
};

export type CreateLessonBlockMutationVariables = Exact<{
  type: LessonBlockTypeEnum;
  pageId: Scalars["String"];
}>;

export type CreateLessonBlockMutation = {
  __typename?: "Mutation";
  createLessonBlock?: { __typename?: "LessonBlock"; id?: string | null } | null;
};

export type PublishLessonMutationVariables = Exact<{
  lessonId: Scalars["String"];
}>;

export type PublishLessonMutation = {
  __typename?: "Mutation";
  publishLesson?: boolean | null;
};

export type DeleteLessonMutationVariables = Exact<{
  lessonId: Scalars["String"];
}>;

export type DeleteLessonMutation = {
  __typename?: "Mutation";
  deleteLesson?: boolean | null;
};

export type UpdateLessonBlockMutationVariables = Exact<{
  lessonBlockData: UpdateLessonBlockInput;
}>;

export type UpdateLessonBlockMutation = {
  __typename?: "Mutation";
  updateLessonBlock?: { __typename?: "LessonBlock"; id?: string | null } | null;
};

export type DeleteLessonBlockMutationVariables = Exact<{
  lessonBlockId: Scalars["String"];
}>;

export type DeleteLessonBlockMutation = {
  __typename?: "Mutation";
  deleteLessonBlock?: boolean | null;
};

export type CreateLessonListItemMutationVariables = Exact<{
  lessonBlockId: Scalars["String"];
  lessonListItemData: LessonBlockListInput;
}>;

export type CreateLessonListItemMutation = {
  __typename?: "Mutation";
  createLessonListItem?: {
    __typename?: "LessonListItem";
    id?: string | null;
  } | null;
};

export type UpdateLessonListItemMutationVariables = Exact<{
  listItemId: Scalars["String"];
  lessonListItemData: LessonBlockListInput;
}>;

export type UpdateLessonListItemMutation = {
  __typename?: "Mutation";
  updateLessonListItem?: {
    __typename?: "LessonListItem";
    id?: string | null;
  } | null;
};

export type DeleteLessonListItemMutationVariables = Exact<{
  listItemId: Scalars["String"];
}>;

export type DeleteLessonListItemMutation = {
  __typename?: "Mutation";
  deleteLessonListItem?: boolean | null;
};

export type CreateManualPromotionMutationVariables = Exact<{
  data: CreateManualPromotionInput;
}>;

export type CreateManualPromotionMutation = {
  __typename?: "Mutation";
  createManualPromotion?: boolean | null;
};

export type UpdateManualPromotionMutationVariables = Exact<{
  updateManualPromotionId: Scalars["UUID"];
  data: UpdateManualPromotionInput;
}>;

export type UpdateManualPromotionMutation = {
  __typename?: "Mutation";
  updateManualPromotion?: boolean | null;
};

export type DeleteManualPromotionMutationVariables = Exact<{
  deleteManualPromotionId: Scalars["UUID"];
}>;

export type DeleteManualPromotionMutation = {
  __typename?: "Mutation";
  deleteManualPromotion?: boolean | null;
};

export type CreateApplicationModelInMarketContentMutationVariables = Exact<{
  id: Scalars["String"];
  lang: LanguagesEnum;
}>;

export type CreateApplicationModelInMarketContentMutation = {
  __typename?: "Mutation";
  createApplicationModelInMarketContent?: boolean | null;
};

export type UpdateApplicationModelInMarketContentMutationVariables = Exact<{
  id: Scalars["String"];
  lang: LanguagesEnum;
}>;

export type UpdateApplicationModelInMarketContentMutation = {
  __typename?: "Mutation";
  updateApplicationModelInMarketContent?: boolean | null;
};

export type UpdateMarketingMediaFileMutationVariables = Exact<{
  data?: InputMaybe<MarketingModalInfoInput>;
}>;

export type UpdateMarketingMediaFileMutation = {
  __typename?: "Mutation";
  updateMarketingModalInfo?: {
    __typename?: "MarketingModalInfo";
    id?: any | null;
    fileLink?: string | null;
  } | null;
};

export type UpdateMarketingModalInfoMutationVariables = Exact<{
  data?: InputMaybe<MarketingModalInfoInput>;
}>;

export type UpdateMarketingModalInfoMutation = {
  __typename?: "Mutation";
  updateMarketingModalInfo?: {
    __typename?: "MarketingModalInfo";
    id?: any | null;
  } | null;
};

export type CreateMobileNotificationMutationVariables = Exact<{
  notificationData: CreateMobileNotificationInput;
}>;

export type CreateMobileNotificationMutation = {
  __typename?: "Mutation";
  createMobileNotification?: {
    __typename?: "MobileNotification";
    id?: string | null;
    participants?: Array<{
      __typename?: "MobileNotificationParticipant";
      id?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateMobileNotificationMutationVariables = Exact<{
  notificationData: UpdateMobileNotificationInput;
  photo?: InputMaybe<Scalars["Upload"]>;
}>;

export type UpdateMobileNotificationMutation = {
  __typename?: "Mutation";
  updateMobileNotification?: {
    __typename?: "MobileNotification";
    id?: string | null;
  } | null;
};

export type DeleteMobileNotificationMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteMobileNotificationMutation = {
  __typename?: "Mutation";
  deleteMobileNotification?: boolean | null;
};

export type PublishMobileNotificationMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type PublishMobileNotificationMutation = {
  __typename?: "Mutation";
  publishMobileNotification?: boolean | null;
};

export type SetMobileNotificationParticipantsMutationVariables = Exact<{
  notificationData: SetMobileNotificationParticipantsInput;
}>;

export type SetMobileNotificationParticipantsMutation = {
  __typename?: "Mutation";
  setMobileNotificationParticipants?: boolean | null;
};

export type RemoveMobileNotificationPhotoMutationVariables = Exact<{
  removeMobileNotificationPhotoId: Scalars["String"];
}>;

export type RemoveMobileNotificationPhotoMutation = {
  __typename?: "Mutation";
  removeMobileNotificationPhoto?: boolean | null;
};

export type CreateNewsMutationVariables = Exact<{
  newsData: CreateNewsInput;
  photo?: InputMaybe<Scalars["Upload"]>;
}>;

export type CreateNewsMutation = {
  __typename?: "Mutation";
  createNews?: {
    __typename?: "News";
    id?: string | null;
    name?: string | null;
    isFor?: NewsIsForEnum | null;
    description?: string | null;
    availableFor?: AvailableForEnum | null;
    participants?: Array<{
      __typename?: "NewsParticipant";
      id?: string | null;
      isWatched?: boolean | null;
      user?: { __typename?: "User"; id?: string | null } | null;
    } | null> | null;
  } | null;
};

export type UpdateNewsMutationVariables = Exact<{
  newsData: UpdateNewsInput;
  photo?: InputMaybe<Scalars["Upload"]>;
}>;

export type UpdateNewsMutation = {
  __typename?: "Mutation";
  updateNews?: { __typename?: "News"; id?: string | null } | null;
};

export type DeleteNewsMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteNewsMutation = {
  __typename?: "Mutation";
  deleteNews?: boolean | null;
};

export type PublishNewsMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type PublishNewsMutation = {
  __typename?: "Mutation";
  publishNews?: boolean | null;
};

export type SetNewsParticipantsMutationVariables = Exact<{
  newsData: SetNewsParticipantsInput;
}>;

export type SetNewsParticipantsMutation = {
  __typename?: "Mutation";
  setNewsParticipants?: boolean | null;
};

export type CreateNotificationMutationVariables = Exact<{
  notificationData: CreateNotificationInput;
}>;

export type CreateNotificationMutation = {
  __typename?: "Mutation";
  createNotification?: {
    __typename?: "Notification";
    id?: string | null;
    name?: string | null;
    description?: string | null;
    availableFor?: AvailableForEnum | null;
    participants?: Array<{
      __typename?: "NotificationParticipant";
      id?: string | null;
      isWatched?: boolean | null;
      user?: { __typename?: "User"; id?: string | null } | null;
    } | null> | null;
  } | null;
};

export type UpdateNotificationMutationVariables = Exact<{
  notificationData: UpdateNotificationInput;
}>;

export type UpdateNotificationMutation = {
  __typename?: "Mutation";
  updateNotification?: {
    __typename?: "Notification";
    id?: string | null;
  } | null;
};

export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteNotificationMutation = {
  __typename?: "Mutation";
  deleteNotification?: boolean | null;
};

export type PublishNotificationMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type PublishNotificationMutation = {
  __typename?: "Mutation";
  publishNotification?: boolean | null;
};

export type SetNotificationParticipantsMutationVariables = Exact<{
  notificationData: SetNotificationParticipantsInput;
}>;

export type SetNotificationParticipantsMutation = {
  __typename?: "Mutation";
  setNotificationParticipants?: boolean | null;
};

export type CreateOnboardingThemeMutationVariables = Exact<{
  title: Scalars["String"];
  description: Scalars["String"];
  video: Scalars["String"];
  routeId?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["Int"]>;
}>;

export type CreateOnboardingThemeMutation = {
  __typename?: "Mutation";
  createOnboardingTheme?: {
    __typename?: "OnboardingTheme";
    id?: string | null;
  } | null;
};

export type UpdateOnboardingThemeMutationVariables = Exact<{
  themeId: Scalars["String"];
  isFinishedReset: Scalars["Boolean"];
  title: Scalars["String"];
  description: Scalars["String"];
  video: Scalars["String"];
  routeId?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["Int"]>;
}>;

export type UpdateOnboardingThemeMutation = {
  __typename?: "Mutation";
  updateOnboardingTheme?: {
    __typename?: "OnboardingTheme";
    id?: string | null;
  } | null;
};

export type DeleteOnboardingThemeMutationVariables = Exact<{
  themeId: Scalars["String"];
}>;

export type DeleteOnboardingThemeMutation = {
  __typename?: "Mutation";
  deleteOnboardingTheme?: {
    __typename?: "OnboardingTheme";
    id?: string | null;
  } | null;
};

export type PublishOnboardingThemeMutationVariables = Exact<{
  themeId: Scalars["String"];
}>;

export type PublishOnboardingThemeMutation = {
  __typename?: "Mutation";
  publishOnboardingTheme?: {
    __typename?: "OnboardingTheme";
    id?: string | null;
  } | null;
};

export type UnpublishOnboardingThemeMutationVariables = Exact<{
  themeId: Scalars["String"];
}>;

export type UnpublishOnboardingThemeMutation = {
  __typename?: "Mutation";
  unpublishOnboardingTheme?: {
    __typename?: "OnboardingTheme";
    id?: string | null;
  } | null;
};

export type ReorderOnboardingThemeMutationVariables = Exact<{
  themeId: Scalars["String"];
  position: Scalars["Int"];
}>;

export type ReorderOnboardingThemeMutation = {
  __typename?: "Mutation";
  reorderOnboardingTheme?: Array<{
    __typename?: "OnboardingTheme";
    id?: string | null;
  } | null> | null;
};

export type UpdateUserPaymentMutationVariables = Exact<{
  paymentData: UpdateUserPaymentInput;
}>;

export type UpdateUserPaymentMutation = {
  __typename?: "Mutation";
  updateUserPayment?: { __typename?: "UserPayment"; id?: string | null } | null;
};

export type CreateUserPaymentMutationVariables = Exact<{
  paymentData: CreateUserPaymentInput;
}>;

export type CreateUserPaymentMutation = {
  __typename?: "Mutation";
  createUserPayment?: { __typename?: "UserPayment"; id?: string | null } | null;
};

export type PayUserPaymentMutationVariables = Exact<{
  paymentId: Scalars["String"];
}>;

export type PayUserPaymentMutation = {
  __typename?: "Mutation";
  payUserPayment?: boolean | null;
};

export type PublishUserPaymentMutationVariables = Exact<{
  paymentId: Scalars["String"];
}>;

export type PublishUserPaymentMutation = {
  __typename?: "Mutation";
  publishUserPayment?: boolean | null;
};

export type SetUserPaymentDocumentsMutationVariables = Exact<{
  paymentId: Scalars["String"];
  deletedDocuments?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
  newDocuments?: InputMaybe<
    Array<InputMaybe<Scalars["Upload"]>> | InputMaybe<Scalars["Upload"]>
  >;
}>;

export type SetUserPaymentDocumentsMutation = {
  __typename?: "Mutation";
  setUserPaymentDocuments?: boolean | null;
};

export type CreateUserPayrollMutationVariables = Exact<{
  payrollData: CreateUserPayrollInput;
}>;

export type CreateUserPayrollMutation = {
  __typename?: "Mutation";
  createUserPayroll?: {
    __typename?: "UserPayroll";
    id?: string | null;
    name?: any | null;
  } | null;
};

export type UpdateUserPayrollMutationVariables = Exact<{
  payrollData: UpdateUserPayrollInput;
}>;

export type UpdateUserPayrollMutation = {
  __typename?: "Mutation";
  updateUserPayroll?: {
    __typename?: "UserPayroll";
    id?: string | null;
    hoursPlan?: number | null;
    bonuses?: Array<{
      __typename?: "PayrollBonus";
      name?: string | null;
      value?: number | null;
    } | null> | null;
    kpi?: Array<{
      __typename?: "PayrollKpi";
      id?: string | null;
      name?: string | null;
      unit?: string | null;
      weigh?: number | null;
      plan?: number | null;
      fact?: number | null;
    } | null> | null;
    additionalData?: {
      __typename?: "AdditionalData";
      kpiValue?: number | null;
    } | null;
  } | null;
};

export type DeleteUserPayrollMutationVariables = Exact<{
  payrollId: Scalars["String"];
}>;

export type DeleteUserPayrollMutation = {
  __typename?: "Mutation";
  deleteUserPayroll?: boolean | null;
};

export type CreateProjectMutationVariables = Exact<{
  jiraKey: Scalars["String"];
  name: Scalars["String"];
}>;

export type CreateProjectMutation = {
  __typename?: "Mutation";
  createProject?: { __typename?: "Project"; id?: string | null } | null;
};

export type CreateProjectByJiraDataMutationVariables = Exact<{
  jira: Scalars["String"];
  jiraKey: Scalars["String"];
}>;

export type CreateProjectByJiraDataMutation = {
  __typename?: "Mutation";
  createProjectByJiraData?: {
    __typename?: "Project";
    id?: string | null;
    jira?: string | null;
  } | null;
};

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type DeleteProjectMutation = {
  __typename?: "Mutation";
  deleteProject?: boolean | null;
};

export type UpdateProjectMutationVariables = Exact<{
  projectData: UpdateProjectInput;
  photo?: InputMaybe<Scalars["Upload"]>;
}>;

export type UpdateProjectMutation = {
  __typename?: "Mutation";
  updateProject?: {
    __typename?: "Project";
    cost?: number | null;
    name?: string | null;
  } | null;
};

export type SyncProjectMutationVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type SyncProjectMutation = {
  __typename?: "Mutation";
  syncProject?: {
    __typename?: "SyncProject";
    tasksToAdd?: number | null;
    tasksToDelete?: number | null;
    tasksToUpdate?: number | null;
  } | null;
};

export type AssignProjectsToUserMutationVariables = Exact<{
  userId: Scalars["String"];
  projectIds:
    | Array<InputMaybe<Scalars["String"]>>
    | InputMaybe<Scalars["String"]>;
}>;

export type AssignProjectsToUserMutation = {
  __typename?: "Mutation";
  assignProjectsToUser?: boolean | null;
};

export type RemoveProjectsFromUserMutationVariables = Exact<{
  userId: Scalars["String"];
  projectIds:
    | Array<InputMaybe<Scalars["String"]>>
    | InputMaybe<Scalars["String"]>;
}>;

export type RemoveProjectsFromUserMutation = {
  __typename?: "Mutation";
  removeProjectsFromUser?: boolean | null;
};

export type UpdateProjectFilesMutationVariables = Exact<{
  projectId: Scalars["String"];
  deleteFileId?: InputMaybe<Scalars["String"]>;
  uploadFile?: InputMaybe<UploadFileInput>;
}>;

export type UpdateProjectFilesMutation = {
  __typename?: "Mutation";
  updateProjectFiles?: Array<{
    __typename?: "ProjectFile";
    id?: string | null;
  } | null> | null;
};

export type CreateProjectAdditionalContactMutationVariables = Exact<{
  projectAdditionalContactData: CreateProjectAdditionalContactInput;
}>;

export type CreateProjectAdditionalContactMutation = {
  __typename?: "Mutation";
  createProjectAdditionalContact?: {
    __typename?: "ProjectAdditionalContact";
    name?: string | null;
    phone?: string | null;
    description?: string | null;
  } | null;
};

export type UpdateProjectAdditionalContactMutationVariables = Exact<{
  projectAdditionalContactData: UpdateProjectAdditionalContactInput;
}>;

export type UpdateProjectAdditionalContactMutation = {
  __typename?: "Mutation";
  updateProjectAdditionalContact?: {
    __typename?: "ProjectAdditionalContact";
    id?: string | null;
    name?: string | null;
    phone?: string | null;
    description?: string | null;
  } | null;
};

export type DeleteProjectAdditionalContactMutationVariables = Exact<{
  projectAdditionalContactId: Scalars["String"];
}>;

export type DeleteProjectAdditionalContactMutation = {
  __typename?: "Mutation";
  deleteProjectAdditionalContact?: boolean | null;
};

export type CreateProjectLinkMutationVariables = Exact<{
  projectLinkData: CreateProjectLinkInput;
}>;

export type CreateProjectLinkMutation = {
  __typename?: "Mutation";
  createProjectLink?: {
    __typename?: "ProjectLink";
    title?: string | null;
  } | null;
};

export type UpdateProjectLinkMutationVariables = Exact<{
  projectLinkData: UpdateProjectLinkInput;
}>;

export type UpdateProjectLinkMutation = {
  __typename?: "Mutation";
  updateProjectLink?: { __typename?: "ProjectLink"; id?: string | null } | null;
};

export type DeleteProjectLinkMutationVariables = Exact<{
  projectLinkId: Scalars["String"];
}>;

export type DeleteProjectLinkMutation = {
  __typename?: "Mutation";
  deleteProjectLink?: boolean | null;
};

export type CreateProjectPresentationMutationVariables = Exact<{
  projectId: Scalars["String"];
  link: Scalars["String"];
  title?: InputMaybe<Scalars["String"]>;
}>;

export type CreateProjectPresentationMutation = {
  __typename?: "Mutation";
  createProjectPresentation?: {
    __typename?: "ProjectPresentation";
    id?: string | null;
  } | null;
};

export type UpdateProjectPresentationMutationVariables = Exact<{
  id: Scalars["String"];
  link?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateProjectPresentationMutation = {
  __typename?: "Mutation";
  updateProjectPresentation?: {
    __typename?: "ProjectPresentation";
    id?: string | null;
  } | null;
};

export type DeleteProjectPresentationMutationVariables = Exact<{
  projectPresentationId: Scalars["String"];
}>;

export type DeleteProjectPresentationMutation = {
  __typename?: "Mutation";
  deleteProjectPresentation?: boolean | null;
};

export type CreateProjectStepMutationVariables = Exact<{
  projectStepData: CreateProjectStepInput;
}>;

export type CreateProjectStepMutation = {
  __typename?: "Mutation";
  createProjectStep?: { __typename?: "ProjectStep"; id?: string | null } | null;
};

export type UpdateProjectStepMutationVariables = Exact<{
  projectStepData: UpdateProjectStepInput;
}>;

export type UpdateProjectStepMutation = {
  __typename?: "Mutation";
  updateProjectStep?: { __typename?: "ProjectStep"; id?: string | null } | null;
};

export type DeleteProjectStepMutationVariables = Exact<{
  projectStepId: Scalars["String"];
}>;

export type DeleteProjectStepMutation = {
  __typename?: "Mutation";
  deleteProjectStep?: boolean | null;
};

export type CreateProjectTaskInKanbanMutationVariables = Exact<{
  taskData: CreateProjectTaskInput;
  workflowStepId: Scalars["String"];
  workflowStepOrder: Scalars["Int"];
}>;

export type CreateProjectTaskInKanbanMutation = {
  __typename?: "Mutation";
  createProjectTaskInKanban?: {
    __typename?: "ProjectTask";
    id?: string | null;
  } | null;
};

export type AddProjectTasksToKanbanMutationVariables = Exact<{
  tasksIds:
    | Array<InputMaybe<Scalars["String"]>>
    | InputMaybe<Scalars["String"]>;
  workflowStepId: Scalars["String"];
  workflowStepStartOrder: Scalars["Int"];
}>;

export type AddProjectTasksToKanbanMutation = {
  __typename?: "Mutation";
  addProjectTasksToKanban?: boolean | null;
};

export type ChangeProjectTaskWorkflowStepMutationVariables = Exact<{
  tasksId: Scalars["String"];
  workflowStepId: Scalars["String"];
  workflowStepOrder: Scalars["Int"];
}>;

export type ChangeProjectTaskWorkflowStepMutation = {
  __typename?: "Mutation";
  changeProjectTaskWorkflowStep?: boolean | null;
};

export type CreateProjectTypeMutationVariables = Exact<{
  name: Scalars["String"];
}>;

export type CreateProjectTypeMutation = {
  __typename?: "Mutation";
  createProjectType?: { __typename?: "ProjectType"; id?: string | null } | null;
};

export type UpdateProjectTypeMutationVariables = Exact<{
  typeId: Scalars["String"];
  name: Scalars["String"];
}>;

export type UpdateProjectTypeMutation = {
  __typename?: "Mutation";
  updateProjectType?: { __typename?: "ProjectType"; id?: string | null } | null;
};

export type DeleteProjectTypeMutationVariables = Exact<{
  typeId: Scalars["String"];
}>;

export type DeleteProjectTypeMutation = {
  __typename?: "Mutation";
  deleteProjectType?: boolean | null;
};

export type CreateQuoteCollectionMutationVariables = Exact<{
  name: Scalars["String"];
  file: Scalars["Upload"];
}>;

export type CreateQuoteCollectionMutation = {
  __typename?: "Mutation";
  createQuoteCollection?: {
    __typename?: "QuoteCollection";
    id?: string | null;
  } | null;
};

export type UpdateQuoteCollectionMutationVariables = Exact<{
  updateQuoteCollectionId: Scalars["UUID"];
  name?: InputMaybe<Scalars["String"]>;
  file?: InputMaybe<Scalars["Upload"]>;
}>;

export type UpdateQuoteCollectionMutation = {
  __typename?: "Mutation";
  updateQuoteCollection?: boolean | null;
};

export type DeleteQuoteCollectionMutationVariables = Exact<{
  deleteQuoteCollectionId: Scalars["UUID"];
}>;

export type DeleteQuoteCollectionMutation = {
  __typename?: "Mutation";
  deleteQuoteCollection?: boolean | null;
};

export type CreateRateHistoryMutationVariables = Exact<{
  userId: Scalars["String"];
  rate: Scalars["Int"];
  changeDate: Scalars["Date"];
}>;

export type CreateRateHistoryMutation = {
  __typename?: "Mutation";
  createRateHistory?: {
    __typename?: "UserRateHistory";
    id?: string | null;
  } | null;
};

export type UpdateRateHistoryMutationVariables = Exact<{
  id: Scalars["String"];
  rate?: InputMaybe<Scalars["Int"]>;
  changeDate?: InputMaybe<Scalars["Date"]>;
}>;

export type UpdateRateHistoryMutation = {
  __typename?: "Mutation";
  updateRateHistory?: {
    __typename?: "UserRateHistory";
    id?: string | null;
  } | null;
};

export type DeleteSRateHistoryMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteSRateHistoryMutation = {
  __typename?: "Mutation";
  deleteSRateHistory?: boolean | null;
};

export type CreateUserMutationVariables = Exact<{
  userData: CreateUserInput;
  isFrom: IsFromEnum;
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser?: { __typename?: "User"; id?: string | null } | null;
};

export type CreateVacanciesPlanMutationVariables = Exact<{
  planData: CreateVacanciesPlanInput;
}>;

export type CreateVacanciesPlanMutation = {
  __typename?: "Mutation";
  createVacanciesPlan?: {
    __typename?: "VacanciesPlan";
    name?: string | null;
    id?: string | null;
  } | null;
};

export type UpdateVacanciesPlanMutationVariables = Exact<{
  planData: UpdateVacanciesPlanInput;
}>;

export type UpdateVacanciesPlanMutation = {
  __typename?: "Mutation";
  updateVacanciesPlan?: {
    __typename?: "VacanciesPlan";
    name?: string | null;
    id?: string | null;
    status?: {
      __typename?: "VacanciesPlanStatus";
      id?: number | null;
      displayName?: string | null;
    } | null;
  } | null;
};

export type DeleteVacanciesPlanMutationVariables = Exact<{
  planId: Scalars["String"];
}>;

export type DeleteVacanciesPlanMutation = {
  __typename?: "Mutation";
  deleteVacanciesPlan?: boolean | null;
};

export type CreateTeacherGroupsClassroomsPupilsReportMutationVariables = Exact<{
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type CreateTeacherGroupsClassroomsPupilsReportMutation = {
  __typename?: "Mutation";
  createTeacherGroupsClassroomsPupilsReport?: {
    __typename?: "AdminReport";
    id?: string | null;
    name?: string | null;
    type?: AdminReportType | null;
    link?: string | null;
  } | null;
};

export type DeleteTeacherGroupsClassroomsPupilsReportMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteTeacherGroupsClassroomsPupilsReportMutation = {
  __typename?: "Mutation";
  deleteTeacherGroupsClassroomsPupilsReport?: boolean | null;
};

export type CreateSalaryHistoryMutationVariables = Exact<{
  userId: Scalars["String"];
  newSalary: Scalars["Int"];
  changeDate: Scalars["Date"];
  description?: InputMaybe<Scalars["String"]>;
}>;

export type CreateSalaryHistoryMutation = {
  __typename?: "Mutation";
  createSalaryHistory?: {
    __typename?: "UserSalaryHistory";
    id?: string | null;
  } | null;
};

export type UpdateSalaryHistoryMutationVariables = Exact<{
  salaryHistoryId: Scalars["String"];
  newSalary?: InputMaybe<Scalars["Int"]>;
  changeDate?: InputMaybe<Scalars["Date"]>;
  description?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateSalaryHistoryMutation = {
  __typename?: "Mutation";
  updateSalaryHistory?: {
    __typename?: "UserSalaryHistory";
    id?: string | null;
  } | null;
};

export type DeleteSalaryHistoryMutationVariables = Exact<{
  salaryHistoryId: Scalars["String"];
}>;

export type DeleteSalaryHistoryMutation = {
  __typename?: "Mutation";
  deleteSalaryHistory?: boolean | null;
};

export type CreatePayrollSalaryPartMutationVariables = Exact<{
  payrollId: Scalars["String"];
  name: Scalars["String"];
  value: Scalars["Int"];
  date: Scalars["Date"];
}>;

export type CreatePayrollSalaryPartMutation = {
  __typename?: "Mutation";
  createPayrollSalaryPart?: {
    __typename?: "PayrollSalaryPart";
    id?: string | null;
  } | null;
};

export type UpdatePayrollSalaryPartMutationVariables = Exact<{
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["Int"]>;
  date?: InputMaybe<Scalars["Date"]>;
}>;

export type UpdatePayrollSalaryPartMutation = {
  __typename?: "Mutation";
  updatePayrollSalaryPart?: {
    __typename?: "PayrollSalaryPart";
    id?: string | null;
  } | null;
};

export type CreateSchoolMutationVariables = Exact<{
  schoolData: CreateSchoolInput;
  photo?: InputMaybe<Scalars["Upload"]>;
}>;

export type CreateSchoolMutation = {
  __typename?: "Mutation";
  createSchool?: { __typename?: "School"; id?: string | null } | null;
};

export type UpdateSchoolMutationVariables = Exact<{
  schoolData: CreateSchoolInput;
  photo?: InputMaybe<Scalars["Upload"]>;
  id: Scalars["String"];
}>;

export type UpdateSchoolMutation = {
  __typename?: "Mutation";
  updateSchool?: { __typename?: "School"; id?: string | null } | null;
};

export type DeleteSchoolMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteSchoolMutation = {
  __typename?: "Mutation";
  deleteSchool?: boolean | null;
};

export type AddSchoolAdminMutationVariables = Exact<{
  schoolId: Scalars["String"];
  teacherId: Scalars["String"];
  teacherPosition?: InputMaybe<Scalars["String"]>;
}>;

export type AddSchoolAdminMutation = {
  __typename?: "Mutation";
  addSchoolAdmin?: boolean | null;
};

export type AddTeacherToSchoolMutationVariables = Exact<{
  schoolId: Scalars["String"];
  teacherPosition?: InputMaybe<Scalars["String"]>;
  teacherStatus?: InputMaybe<SchoolUserStatusEnum>;
  teacherId: Scalars["String"];
}>;

export type AddTeacherToSchoolMutation = {
  __typename?: "Mutation";
  addTeacherToSchool?: boolean | null;
};

export type RemoveTeacherFromSchoolMutationVariables = Exact<{
  schoolId: Scalars["String"];
  teacherId: Scalars["String"];
}>;

export type RemoveTeacherFromSchoolMutation = {
  __typename?: "Mutation";
  removeTeacherFromSchool?: boolean | null;
};

export type InviteUsersByListMutationVariables = Exact<{
  list: Scalars["Upload"];
  schoolId: Scalars["UUID"];
}>;

export type InviteUsersByListMutation = {
  __typename?: "Mutation";
  inviteUsersByListV3: Array<{
    __typename?: "CSVValidationErrors";
    line: number;
    errors: Array<string>;
  } | null>;
};

export type InviteUsersByListV3MutationVariables = Exact<{
  list: Scalars["Upload"];
  schoolId: Scalars["UUID"];
}>;

export type InviteUsersByListV3Mutation = {
  __typename?: "Mutation";
  inviteUsersByListV3: Array<{
    __typename?: "CSVValidationErrors";
    line: number;
    errors: Array<string>;
  } | null>;
};

export type AddCourseToSchoolMutationVariables = Exact<{
  schoolId: Scalars["UUID"];
  courseId: Scalars["UUID"];
}>;

export type AddCourseToSchoolMutation = {
  __typename?: "Mutation";
  addCourseToSchool?: boolean | null;
};

export type RemoveCourseFromSchoolMutationVariables = Exact<{
  schoolId: Scalars["UUID"];
  courseId: Scalars["UUID"];
}>;

export type RemoveCourseFromSchoolMutation = {
  __typename?: "Mutation";
  removeCourseFromSchool?: boolean | null;
};

export type AddCourseToSchoolV3MutationVariables = Exact<{
  schoolId: Scalars["UUID"];
  courseId: Scalars["UUID"];
}>;

export type AddCourseToSchoolV3Mutation = {
  __typename?: "Mutation";
  addCourseToSchoolV3?: boolean | null;
};

export type CreateSubscriptionPlanPaymentFromAdminPanelMutationVariables =
  Exact<{
    data: SubscriptionPlanPaymentData;
  }>;

export type CreateSubscriptionPlanPaymentFromAdminPanelMutation = {
  __typename?: "Mutation";
  createSubscriptionPlanPaymentFromAdminPanel?: {
    __typename?: "Payment";
    id?: string | null;
  } | null;
};

export type CreatePaymentFormAdminPanelMutationVariables = Exact<{
  data: PaymentData;
}>;

export type CreatePaymentFormAdminPanelMutation = {
  __typename?: "Mutation";
  createPaymentFormAdminPanel?: {
    __typename?: "Payment";
    id?: string | null;
  } | null;
};

export type HandlePaymentActivationMutationVariables = Exact<{
  id: Scalars["String"];
  isActivated?: InputMaybe<Scalars["Boolean"]>;
}>;

export type HandlePaymentActivationMutation = {
  __typename?: "Mutation";
  handlePaymentActivation?: boolean | null;
};

export type DeletePaymentMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeletePaymentMutation = {
  __typename?: "Mutation";
  deletePayment?: boolean | null;
};

export type CreatePurchasePaymentFromAdminPanelMutationVariables = Exact<{
  schoolId: Scalars["UUID"];
  paymentDate: Scalars["Date"];
  paymentType: AllowedAdminPanelPaymentType;
  productId: Scalars["Int"];
  price: Scalars["Float"];
}>;

export type CreatePurchasePaymentFromAdminPanelMutation = {
  __typename?: "Mutation";
  createPurchasePaymentFromAdminPanel?: boolean | null;
};

export type DeactivatePurchasePaymentMutationVariables = Exact<{
  paymentId: Scalars["UUID"];
}>;

export type DeactivatePurchasePaymentMutation = {
  __typename?: "Mutation";
  deactivatePurchasePayment?: boolean | null;
};

export type CreatePersonalSubscriptionV3FromAdminPanelMutationVariables =
  Exact<{
    data: PaymentDataV3;
  }>;

export type CreatePersonalSubscriptionV3FromAdminPanelMutation = {
  __typename?: "Mutation";
  createPersonalSubscriptionV3FromAdminPanel?: boolean | null;
};

export type SetTaskBoundariesMutationVariables = Exact<{
  id: Scalars["String"];
  startDateEstimate: Scalars["String"];
  finishDateEstimate: Scalars["String"];
  isEpic?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SetTaskBoundariesMutation = {
  __typename?: "Mutation";
  setTaskBoundaries?: boolean | null;
};

export type CreateSmartNotificationMutationVariables = Exact<{
  smartNotificationData: CreateSmartNotificationInput;
  photo?: InputMaybe<Scalars["Upload"]>;
  video?: InputMaybe<Scalars["Upload"]>;
}>;

export type CreateSmartNotificationMutation = {
  __typename?: "Mutation";
  createSmartNotification?: {
    __typename?: "SmartNotification";
    id?: string | null;
  } | null;
};

export type UpdateSmartNotificationMutationVariables = Exact<{
  smartNotificationData: UpdateSmartNotificationInput;
  photo?: InputMaybe<Scalars["Upload"]>;
}>;

export type UpdateSmartNotificationMutation = {
  __typename?: "Mutation";
  updateSmartNotification?: {
    __typename?: "SmartNotification";
    id?: string | null;
  } | null;
};

export type DeleteSmartNotificationMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteSmartNotificationMutation = {
  __typename?: "Mutation";
  deleteSmartNotification?: boolean | null;
};

export type PublishSmartNotificationMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type PublishSmartNotificationMutation = {
  __typename?: "Mutation";
  publishSmartNotification?: boolean | null;
};

export type UnpublishSmartNotificationMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type UnpublishSmartNotificationMutation = {
  __typename?: "Mutation";
  unpublishSmartNotification?: boolean | null;
};

export type DeleteSmartNotificationPhotoMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteSmartNotificationPhotoMutation = {
  __typename?: "Mutation";
  deleteSmartNotificationPhoto?: boolean | null;
};

export type SetSmartNotificationParticipantsMutationVariables = Exact<{
  notificationData: SetSmartNotificationParticipantsInput;
}>;

export type SetSmartNotificationParticipantsMutation = {
  __typename?: "Mutation";
  setSmartNotificationParticipants?: boolean | null;
};

export type CreateSmartNotificationPollListMutationVariables = Exact<{
  name: Scalars["String"];
  notificationId: Scalars["String"];
}>;

export type CreateSmartNotificationPollListMutation = {
  __typename?: "Mutation";
  createSmartNotificationPollList?: {
    __typename?: "SmartNotificationPollList";
    id?: string | null;
  } | null;
};

export type UpdateSmartNotificationPollListMutationVariables = Exact<{
  id: Scalars["String"];
  name: Scalars["String"];
}>;

export type UpdateSmartNotificationPollListMutation = {
  __typename?: "Mutation";
  updateSmartNotificationPollList?: {
    __typename?: "SmartNotificationPollList";
    id?: string | null;
  } | null;
};

export type DeleteSmartNotificationPollListMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteSmartNotificationPollListMutation = {
  __typename?: "Mutation";
  deleteSmartNotificationPollList?: boolean | null;
};

export type AddSubjectToTeacherMutationVariables = Exact<{
  appMainId: Scalars["String"];
  userId: Scalars["String"];
  schoolId: Scalars["String"];
}>;

export type AddSubjectToTeacherMutation = {
  __typename?: "Mutation";
  addSubjectToTeacher?: boolean | null;
};

export type RemoveSubjectFromTeacherMutationVariables = Exact<{
  appMainId: Scalars["String"];
  userId: Scalars["String"];
  schoolId: Scalars["String"];
}>;

export type RemoveSubjectFromTeacherMutation = {
  __typename?: "Mutation";
  removeSubjectFromTeacher?: boolean | null;
};

export type SyncProjectTaskWithTrackerMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type SyncProjectTaskWithTrackerMutation = {
  __typename?: "Mutation";
  syncProjectTaskWithTracker?: boolean | null;
};

export type CreateProjectTaskMutationVariables = Exact<{
  taskData: CreateProjectTaskInput;
}>;

export type CreateProjectTaskMutation = {
  __typename?: "Mutation";
  createProjectTask?: { __typename?: "ProjectTask"; id?: string | null } | null;
};

export type UpdateProjectTaskMutationVariables = Exact<{
  taskData: UpdateProjectTaskInput;
}>;

export type UpdateProjectTaskMutation = {
  __typename?: "Mutation";
  updateProjectTask?: { __typename?: "ProjectTask"; id?: string | null } | null;
};

export type DeleteProjectTaskMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteProjectTaskMutation = {
  __typename?: "Mutation";
  deleteProjectTask?: boolean | null;
};

export type ImportProjectTasksFromGSheetsMutationVariables = Exact<{
  projectId: Scalars["String"];
  gSheetId: Scalars["String"];
  gSheetTitle: Scalars["String"];
}>;

export type ImportProjectTasksFromGSheetsMutation = {
  __typename?: "Mutation";
  importProjectTasksFromGSheets?: {
    __typename?: "ImportTasksFromGSheetResult";
    epicsCount?: number | null;
    tasksCount?: number | null;
  } | null;
};

export type SetProjectTaskLateReasonMutationVariables = Exact<{
  tasksId: Scalars["String"];
  lateReason: Scalars["String"];
}>;

export type SetProjectTaskLateReasonMutation = {
  __typename?: "Mutation";
  setProjectTaskLateReason?: boolean | null;
};

export type CreateProjectTaskCommentMutationVariables = Exact<{
  projectTaskId: Scalars["String"];
  text: Scalars["String"];
  file?: InputMaybe<Scalars["Upload"]>;
}>;

export type CreateProjectTaskCommentMutation = {
  __typename?: "Mutation";
  createProjectTaskComment?: {
    __typename?: "ProjectTaskComment";
    id?: string | null;
  } | null;
};

export type UpdateProjectTaskCommentMutationVariables = Exact<{
  id: Scalars["String"];
  text: Scalars["String"];
  file?: InputMaybe<Scalars["Upload"]>;
}>;

export type UpdateProjectTaskCommentMutation = {
  __typename?: "Mutation";
  updateProjectTaskComment?: {
    __typename?: "ProjectTaskComment";
    id?: string | null;
  } | null;
};

export type DeleteProjectTaskCommentMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteProjectTaskCommentMutation = {
  __typename?: "Mutation";
  deleteProjectTaskComment?: boolean | null;
};

export type DeleteProjectTaskCommentFileMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteProjectTaskCommentFileMutation = {
  __typename?: "Mutation";
  deleteProjectTaskCommentFile?: boolean | null;
};

export type CreateBaseProjectTaskLabelMutationVariables = Exact<{
  name: Scalars["String"];
  color: Scalars["String"];
}>;

export type CreateBaseProjectTaskLabelMutation = {
  __typename?: "Mutation";
  createBaseProjectTaskLabel?: {
    __typename?: "ProjectTaskLabel";
    id?: string | null;
  } | null;
};

export type CreateProjectTaskLabelForProjectMutationVariables = Exact<{
  name: Scalars["String"];
  color: Scalars["String"];
  projectId: Scalars["String"];
}>;

export type CreateProjectTaskLabelForProjectMutation = {
  __typename?: "Mutation";
  createProjectTaskLabelForProject?: {
    __typename?: "ProjectTaskLabel";
    id?: string | null;
  } | null;
};

export type UpdateProjectTaskLabelMutationVariables = Exact<{
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  color?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateProjectTaskLabelMutation = {
  __typename?: "Mutation";
  updateProjectTaskLabel?: {
    __typename?: "ProjectTaskLabel";
    id?: string | null;
  } | null;
};

export type DeleteProjectTaskLabelMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteProjectTaskLabelMutation = {
  __typename?: "Mutation";
  deleteProjectTaskLabel?: boolean | null;
};

export type AssignProjectTaskLabelMutationVariables = Exact<{
  projectTaskId: Scalars["String"];
  labelId: Scalars["String"];
}>;

export type AssignProjectTaskLabelMutation = {
  __typename?: "Mutation";
  assignProjectTaskLabel?: boolean | null;
};

export type RemoveProjectTaskLabelMutationVariables = Exact<{
  projectTaskId: Scalars["String"];
  labelId: Scalars["String"];
}>;

export type RemoveProjectTaskLabelMutation = {
  __typename?: "Mutation";
  removeProjectTaskLabel?: boolean | null;
};

export type CreateBaseProjectTaskStatusMutationVariables = Exact<{
  name: Scalars["String"];
}>;

export type CreateBaseProjectTaskStatusMutation = {
  __typename?: "Mutation";
  createBaseProjectTaskStatus?: {
    __typename?: "ProjectTaskStatus";
    id?: string | null;
  } | null;
};

export type CreateProjectTaskStatusForProjectMutationVariables = Exact<{
  name: Scalars["String"];
  projectId: Scalars["String"];
}>;

export type CreateProjectTaskStatusForProjectMutation = {
  __typename?: "Mutation";
  createProjectTaskStatusForProject?: {
    __typename?: "ProjectTaskStatus";
    id?: string | null;
  } | null;
};

export type UpdateProjectTaskStatusMutationVariables = Exact<{
  id: Scalars["String"];
  name: Scalars["String"];
}>;

export type UpdateProjectTaskStatusMutation = {
  __typename?: "Mutation";
  updateProjectTaskStatus?: {
    __typename?: "ProjectTaskStatus";
    id?: string | null;
  } | null;
};

export type DeleteProjectTaskStatusMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteProjectTaskStatusMutation = {
  __typename?: "Mutation";
  deleteProjectTaskStatus?: boolean | null;
};

export type CreateTrackerContractMutationVariables = Exact<{
  userId?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
}>;

export type CreateTrackerContractMutation = {
  __typename?: "Mutation";
  createTrackerContract?: {
    __typename?: "Contract";
    id?: string | null;
  } | null;
};

export type DeleteTrackerContractMutationVariables = Exact<{
  contractId: Scalars["String"];
}>;

export type DeleteTrackerContractMutation = {
  __typename?: "Mutation";
  deleteTrackerContract?: boolean | null;
};

export type CreateTraningMutationVariables = Exact<{
  traningData: CreateTraningInput;
}>;

export type CreateTraningMutation = {
  __typename?: "Mutation";
  createTraning?: { __typename?: "Traning"; id?: string | null } | null;
};

export type DeleteTraningMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteTraningMutation = {
  __typename?: "Mutation";
  deleteTraning?: boolean | null;
};

export type PublishTraningMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type PublishTraningMutation = {
  __typename?: "Mutation";
  publishTraning?: boolean | null;
};

export type UnpublishTraningMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type UnpublishTraningMutation = {
  __typename?: "Mutation";
  unpublishTraning?: boolean | null;
};

export type UpdateTraningMutationVariables = Exact<{
  id: Scalars["String"];
  traningData: CreateTraningInput;
}>;

export type UpdateTraningMutation = {
  __typename?: "Mutation";
  updateTraning?: {
    __typename?: "Traning";
    contentEN?: string | null;
    contentUKR?: string | null;
    startTitleEN?: string | null;
    startTitleUKR?: string | null;
    endTitleEN?: string | null;
    endTitleUKR?: string | null;
    photo?: string | null;
    startVideo?: string | null;
    endVideo?: string | null;
  } | null;
};

export type UpdateUserInAdminPanelMutationVariables = Exact<{
  updateUserInAdminPanelId: Scalars["UUID"];
  userData: UpdateUserInAdminPanelInput;
  photo?: InputMaybe<Scalars["Upload"]>;
}>;

export type UpdateUserInAdminPanelMutation = {
  __typename?: "Mutation";
  updateUserInAdminPanel?: {
    __typename?: "User";
    role?: {
      __typename?: "UserRole";
      id?: number | null;
      role?: UserRoleEnum | null;
      displayName?: string | null;
    } | null;
  } | null;
};

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars["String"];
}>;

export type DeleteUserMutation = {
  __typename?: "Mutation";
  deleteUser?: boolean | null;
};

export type AssignUserTrackerIdMutationVariables = Exact<{
  userId?: InputMaybe<Scalars["String"]>;
}>;

export type AssignUserTrackerIdMutation = {
  __typename?: "Mutation";
  assignUserTrackerId?: boolean | null;
};

export type InviteUserToTrackerMutationVariables = Exact<{
  userId?: InputMaybe<Scalars["String"]>;
}>;

export type InviteUserToTrackerMutation = {
  __typename?: "Mutation";
  inviteUserToTracker?: boolean | null;
};

export type BlockUserMutationVariables = Exact<{
  userId?: InputMaybe<Scalars["String"]>;
}>;

export type BlockUserMutation = {
  __typename?: "Mutation";
  blockUser?: boolean | null;
};

export type CreateUserGroupMutationVariables = Exact<{
  userGroupData: CreateUserGroupInput;
}>;

export type CreateUserGroupMutation = {
  __typename?: "Mutation";
  createUserGroup?: { __typename?: "UserGroup"; id?: string | null } | null;
};

export type UpdateUserGroupMutationVariables = Exact<{
  userGroupData: UpdateUserGroupInput;
}>;

export type UpdateUserGroupMutation = {
  __typename?: "Mutation";
  updateUserGroup?: { __typename?: "UserGroup"; id?: string | null } | null;
};

export type DeleteUserGroupMutationVariables = Exact<{
  userGroupId: Scalars["String"];
}>;

export type DeleteUserGroupMutation = {
  __typename?: "Mutation";
  deleteUserGroup?: boolean | null;
};

export type AddPermissionByUserIdMutationVariables = Exact<{
  userId: Scalars["UUID"];
  flagId: Scalars["UUID"];
}>;

export type AddPermissionByUserIdMutation = {
  __typename?: "Mutation";
  addPermissionByUserId?: boolean | null;
};

export type RemovePermissionByUserIdMutationVariables = Exact<{
  userId: Scalars["UUID"];
  flagId: Scalars["UUID"];
}>;

export type RemovePermissionByUserIdMutation = {
  __typename?: "Mutation";
  removePermissionByUserId?: boolean | null;
};

export type AddByPersonalSchoolIdPermissionMutationVariables = Exact<{
  schoolId: Scalars["UUID"];
  flagId: Scalars["UUID"];
}>;

export type AddByPersonalSchoolIdPermissionMutation = {
  __typename?: "Mutation";
  addByPersonalSchoolIdPermission?: boolean | null;
};

export type RemoveByPersonalSchoolIdPermissionMutationVariables = Exact<{
  schoolId: Scalars["UUID"];
  flagId: Scalars["UUID"];
}>;

export type RemoveByPersonalSchoolIdPermissionMutation = {
  __typename?: "Mutation";
  removeByPersonalSchoolIdPermission?: boolean | null;
};

export type PublishedWhiteLabelCollectionMutationVariables = Exact<{
  updateWhiteLabelCollectionId: Scalars["UUID"];
  isPublished?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PublishedWhiteLabelCollectionMutation = {
  __typename?: "Mutation";
  updateWhiteLabelCollection?: boolean | null;
};

export type DeleteWhiteLabelCollectionMutationVariables = Exact<{
  deleteWhiteLabelCollectionId: Scalars["UUID"];
}>;

export type DeleteWhiteLabelCollectionMutation = {
  __typename?: "Mutation";
  deleteWhiteLabelCollection?: boolean | null;
};

export type CreateWhiteLabelCollectionMutationVariables = Exact<{
  name: Scalars["String"];
  schoolLogo: Scalars["Upload"];
  systemLogo: Scalars["Upload"];
  systemLogoLarge: Scalars["Upload"];
  banner: Scalars["Upload"];
  backgroundColor: Scalars["String"];
  welcomeHtml?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  textColor?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<Scalars["Upload"]>;
  description?: InputMaybe<Scalars["String"]>;
}>;

export type CreateWhiteLabelCollectionMutation = {
  __typename?: "Mutation";
  createWhiteLabelCollection?: boolean | null;
};

export type UpdateWhiteLabelCollectionMutationVariables = Exact<{
  id: Scalars["UUID"];
  name?: InputMaybe<Scalars["String"]>;
  schoolLogo?: InputMaybe<Scalars["Upload"]>;
  systemLogo?: InputMaybe<Scalars["Upload"]>;
  systemLogoLarge?: InputMaybe<Scalars["Upload"]>;
  banner?: InputMaybe<Scalars["Upload"]>;
  backgroundColor?: InputMaybe<Scalars["String"]>;
  welcomeHtml?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  textColor?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<Scalars["Upload"]>;
  description?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateWhiteLabelCollectionMutation = {
  __typename?: "Mutation";
  updateWhiteLabelCollection?: boolean | null;
};

export type CheckIfWhiteLabelCollectionIsInUseMutationVariables = Exact<{
  checkIfWhiteLabelCollectionIsInUseId: Scalars["UUID"];
}>;

export type CheckIfWhiteLabelCollectionIsInUseMutation = {
  __typename?: "Mutation";
  checkIfWhiteLabelCollectionIsInUse?: boolean | null;
};

export type GetAchievesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetAchievesQuery = {
  __typename?: "Query";
  getAchieves?: {
    __typename?: "GetAchieves";
    total?: number | null;
    achieves?: Array<{
      __typename?: "Achieve";
      id?: string | null;
      name?: string | null;
      photo?: string | null;
      isPublished?: boolean | null;
      isForTEACHER?: boolean | null;
      isForPUPIL?: boolean | null;
      isForPARENT?: boolean | null;
      isForGUEST?: boolean | null;
      isLocked?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetAchieveQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetAchieveQuery = {
  __typename?: "Query";
  getAchieve?: {
    __typename?: "Achieve";
    id?: string | null;
    name?: string | null;
    descriptionDone?: string | null;
    descriptionProgress?: string | null;
    type?: AchieveTypeEnum | null;
    isLocked?: boolean | null;
    isPublished?: boolean | null;
    isForTEACHER?: boolean | null;
    isForPUPIL?: boolean | null;
    isForPARENT?: boolean | null;
    isForGUEST?: boolean | null;
    photo?: string | null;
  } | null;
};

export type GetAddedTasksReportQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetAddedTasksReportQuery = {
  __typename?: "Query";
  getAddedTasksReport?: Array<{
    __typename?: "AddedTasksReport";
    projectId?: string | null;
    projectName?: string | null;
    count?: number | null;
    timeSpent?: number | null;
    cost?: number | null;
  } | null> | null;
};

export type GetAddedTasksEpicsQueryVariables = Exact<{
  projectId: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetAddedTasksEpicsQuery = {
  __typename?: "Query";
  getAddedTasksEpics?: Array<{
    __typename?: "AddedTasksEpic";
    epicKey?: string | null;
    epicName?: string | null;
    totalTimeSpent?: number | null;
    cost?: number | null;
  } | null> | null;
};

export type GetAddedTasksReportByEpicQueryVariables = Exact<{
  epicKey: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetAddedTasksReportByEpicQuery = {
  __typename?: "Query";
  getAddedTasksReportByEpic?: Array<{
    __typename?: "AddedTasksByEpic";
    id?: string | null;
    name?: string | null;
    assigneeName?: string | null;
    status?: string | null;
    timeSpent?: number | null;
    cost?: number | null;
  } | null> | null;
};

export type GetUserAgreementsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserAgreementsQuery = {
  __typename?: "Query";
  getUserAgreements?: Array<{
    __typename?: "UserAgreement";
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetAnimationBundlesQueryVariables = Exact<{
  animationData: GetAnimationBundlesInput;
}>;

export type GetAnimationBundlesQuery = {
  __typename?: "Query";
  getAnimationBundles?: {
    __typename?: "GetAnimationBundles";
    animationBundles?: Array<{
      __typename?: "AnimationBundle";
      id?: string | null;
      nameEN?: string | null;
      nameUKR?: string | null;
      descriptionEN?: string | null;
      descriptionUKR?: string | null;
      photo?: string | null;
      lat?: string | null;
      long?: string | null;
      availableForEveryone?: boolean | null;
      createdDate?: any | null;
      type?: {
        __typename?: "AnimationBundleType";
        displayName?: string | null;
        type?: AnimationBundleTypeEnum | null;
      } | null;
      iosBundle?: {
        __typename?: "IosBundle";
        id?: string | null;
        name?: string | null;
        fileLink?: string | null;
      } | null;
      androidBundle?: {
        __typename?: "AndroidBundle";
        id?: string | null;
        name?: string | null;
        fileLink?: string | null;
      } | null;
      participants?: Array<{
        __typename?: "User";
        id?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetAnimationBundleTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAnimationBundleTypesQuery = {
  __typename?: "Query";
  getAnimationBundleTypes?: Array<{
    __typename?: "AnimationBundleType";
    id?: number | null;
    type?: AnimationBundleTypeEnum | null;
    displayName?: string | null;
  } | null> | null;
};

export type GetAppMainThemeBackgroundsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAppMainThemeBackgroundsQuery = {
  __typename?: "Query";
  getAppMainThemeBackgrounds?: Array<{
    __typename?: "AppMainThemeBackground";
    id?: string | null;
    name?: string | null;
    fileIOS?: string | null;
    fileAndroid?: string | null;
    fileWebGl?: string | null;
    manifestIOS?: string | null;
    manifestAndroid?: string | null;
    manifestWebGl?: string | null;
    themes?: Array<{
      __typename?: "ApplicationMainTheme";
      id?: string | null;
      analyticsMark?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetApplicationsMainQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  applicationId: Scalars["String"];
  isPublished?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetApplicationsMainQuery = {
  __typename?: "Query";
  getApplicationsMain?: {
    __typename?: "GetApplicationsMain";
    total?: number | null;
    applicationsMain?: Array<{
      __typename?: "ApplicationMain";
      id?: string | null;
      name?: string | null;
      description?: string | null;
      photo?: string | null;
      isMap?: boolean | null;
      availableFor?: ApplicationMainAvailableForEnum | null;
      isPublished?: boolean | null;
      applicationId?: string | null;
      showInSubjects?: boolean | null;
      application?: { __typename?: "Application"; id?: string | null } | null;
    } | null> | null;
  } | null;
};

export type GetApplicationMainQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetApplicationMainQuery = {
  __typename?: "Query";
  getApplicationMain?: {
    __typename?: "ApplicationMain";
    id?: string | null;
    name?: string | null;
    photo?: string | null;
    icon?: string | null;
    description?: string | null;
    background1?: string | null;
    background2?: string | null;
    background3?: string | null;
    background4?: string | null;
    background5?: string | null;
    isMap?: boolean | null;
    availableFor?: ApplicationMainAvailableForEnum | null;
    priority?: number | null;
    analyticsMark?: string | null;
    subscriptionPlan?: SubscriptionPlanStatusEnum | null;
    showInArBook?: boolean | null;
    qr?: string | null;
    isPublished?: boolean | null;
    display?: boolean | null;
    participants?: Array<{
      __typename?: "User";
      id?: string | null;
      photo?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null> | null;
    themes?: Array<{
      __typename?: "ApplicationMainTheme";
      id?: string | null;
      nameEN?: string | null;
      nameUKR?: string | null;
      photo?: string | null;
      photoName?: string | null;
      backgroundId?: string | null;
      analyticsMark?: string | null;
      subscriptionPlan?: SubscriptionPlanStatusEnum | null;
      background?: {
        __typename?: "AppMainThemeBackground";
        id?: string | null;
      } | null;
    } | null> | null;
    marketContentCategories?: Array<{
      __typename?: "MarketContent";
      id?: string | null;
      categoryId?: number | null;
      lang?: LanguagesEnum | null;
    } | null> | null;
  } | null;
};

export type GetApplicationMainModelsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  applicationMainId?: InputMaybe<Scalars["String"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  themeId?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SortTypeEnum>;
  order?: InputMaybe<OrderTypeEnum>;
}>;

export type GetApplicationMainModelsQuery = {
  __typename?: "Query";
  getApplicationMainModels?: {
    __typename?: "GetApplicationMainModels";
    total?: number | null;
    models?: Array<{
      __typename?: "ApplicationMainModel";
      id?: string | null;
      name?: string | null;
      description?: string | null;
      photo?: string | null;
      isPublished?: boolean | null;
      smartNotificationId?: string | null;
      marketId?: string | null;
      isUploadedToMarket?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetApplicationMainModelsSelectQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  applicationMainId: Scalars["String"];
  isPublished?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetApplicationMainModelsSelectQuery = {
  __typename?: "Query";
  getApplicationMainModels?: {
    __typename?: "GetApplicationMainModels";
    models?: Array<{
      __typename?: "ApplicationMainModel";
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type GetApplicationMainModelQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetApplicationMainModelQuery = {
  __typename?: "Query";
  getApplicationMainModel?: {
    __typename?: "ApplicationMainModel";
    id?: string | null;
    marketId?: string | null;
    name?: string | null;
    description?: string | null;
    isUploadedToMarket?: boolean | null;
    lat?: string | null;
    lang?: LanguagesEnum | null;
    longitude?: string | null;
    address?: string | null;
    mapMarkerPhoto?: string | null;
    photo?: string | null;
    isBundle?: boolean | null;
    childId?: string | null;
    schoolGradeId?: string | null;
    inGradeOrder?: number | null;
    subscriptionPlan?: SubscriptionPlanStatusEnum | null;
    bundleType?: ApplicationBundleTypeEnum | null;
    bundlePhoto?: string | null;
    bundleLink?: string | null;
    iosBundle?: string | null;
    vrBundle?: string | null;
    androidBundle?: string | null;
    isMarker?: boolean | null;
    markerWidth?: number | null;
    markerPhoto?: string | null;
    isMap?: boolean | null;
    isPublished?: boolean | null;
    isContour?: boolean | null;
    themeId?: string | null;
    smartNotificationId?: string | null;
    showSmartNotification?: boolean | null;
    clicks?: number | null;
    contentAuthor?: string | null;
    coloringPageFile?: string | null;
    contentType?: {
      __typename?: "ContentType";
      name?: ContentTypeEnum | null;
    } | null;
    bundles?: Array<{
      __typename?: "ApplicationMainModelFile";
      id?: string | null;
      type?: ApplicationMainModelFileTypeEnum | null;
      language?: ApplicationMainModelFileLangEnum | null;
      file?: string | null;
      manifest?: string | null;
    } | null> | null;
    schoolGrade?: {
      __typename?: "SchoolGrade";
      id?: string | null;
      name?: number | null;
    } | null;
    schoolGrades?: Array<{
      __typename?: "SchoolGrade";
      id?: string | null;
      name?: number | null;
    } | null> | null;
    educationAreas?: Array<{
      __typename?: "EducationArea";
      id?: any | null;
      name?: string | null;
      createdDate?: any | null;
    } | null> | null;
    main?: {
      __typename?: "ApplicationMain";
      id?: string | null;
      name?: string | null;
    } | null;
    mains?: Array<{
      __typename?: "ApplicationMain";
      id?: string | null;
      name?: string | null;
    } | null> | null;
    contentPreviews?: Array<{
      __typename?: "ContentPreview";
      id?: string | null;
      url?: string | null;
      type?: ContentPreviewTypeEnum | null;
      order?: number | null;
    } | null> | null;
    tags?: Array<{
      __typename?: "ContentTag";
      id?: string | null;
      name?: string | null;
      displayName?: string | null;
    } | null> | null;
    libraryContent?: {
      __typename?: "UserLibraryContent";
      equalId?: any | null;
      price?: number | null;
    } | null;
  } | null;
};

export type GetEducationAreasQueryVariables = Exact<{ [key: string]: never }>;

export type GetEducationAreasQuery = {
  __typename?: "Query";
  getEducationAreas?: Array<{
    __typename?: "EducationArea";
    id?: any | null;
    name?: string | null;
  } | null> | null;
};

export type GetApplicationsMainForSelectQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  applicationId: Scalars["String"];
  isPublished?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetApplicationsMainForSelectQuery = {
  __typename?: "Query";
  getApplicationsMain?: {
    __typename?: "GetApplicationsMain";
    applicationsMain?: Array<{
      __typename?: "ApplicationMain";
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type GetContentTagByContentTypeQueryVariables = Exact<{
  contentType: Array<ContentTypeEnum> | ContentTypeEnum;
}>;

export type GetContentTagByContentTypeQuery = {
  __typename?: "Query";
  getContentTagByContentType?: Array<{
    __typename?: "ContentTag";
    id?: string | null;
    name?: string | null;
    displayName?: string | null;
  } | null> | null;
};

export type GetApplicationMainThemesByMainIdQueryVariables = Exact<{
  applicationMainId: Scalars["String"];
}>;

export type GetApplicationMainThemesByMainIdQuery = {
  __typename?: "Query";
  getApplicationMainThemesByMainId?: Array<{
    __typename?: "ApplicationMainTheme";
    id?: string | null;
    nameEN?: string | null;
    nameUKR?: string | null;
    analyticsMark?: string | null;
    subscriptionPlan?: SubscriptionPlanStatusEnum | null;
  } | null> | null;
};

export type GetApplicationVersionsQueryVariables = Exact<{
  appId: Scalars["String"];
}>;

export type GetApplicationVersionsQuery = {
  __typename?: "Query";
  getApplicationVersions?: Array<{
    __typename?: "ApplicationVersion";
    id?: string | null;
    os?: Os | null;
    version?: string | null;
    createdDate?: any | null;
  } | null> | null;
};

export type GetCurrentApplicationVersionQueryVariables = Exact<{
  appId: Scalars["String"];
  os: Os;
}>;

export type GetCurrentApplicationVersionQuery = {
  __typename?: "Query";
  getCurrentApplicationVersion?: string | null;
};

export type GetApplicationsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type GetApplicationsQuery = {
  __typename?: "Query";
  getApplications?: {
    __typename?: "GetApplications";
    total?: number | null;
    applications?: Array<{
      __typename?: "Application";
      id?: string | null;
      name?: string | null;
      description?: string | null;
      photo?: string | null;
      privacyPolicy?: string | null;
      userAgreement?: string | null;
      appStoreLink?: string | null;
      playMarketLink?: string | null;
      mainPhoto?: string | null;
      modelPhoto?: string | null;
      email?: string | null;
      phone?: string | null;
      comments?: string | null;
      isPublished?: boolean | null;
      links?: Array<{
        __typename?: "ApplicationLink";
        id?: string | null;
        name?: string | null;
        link?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetApplicationQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetApplicationQuery = {
  __typename?: "Query";
  getApplication?: {
    __typename?: "Application";
    id?: string | null;
    name?: string | null;
    photo?: string | null;
    description?: string | null;
    privacyPolicy?: string | null;
    userAgreement?: string | null;
    appStoreLink?: string | null;
    playMarketLink?: string | null;
    mainPhoto?: string | null;
    modelPhoto?: string | null;
    email?: string | null;
    phone?: string | null;
    comments?: string | null;
    isPublished?: boolean | null;
    links?: Array<{
      __typename?: "ApplicationLink";
      id?: string | null;
      name?: string | null;
      link?: string | null;
    } | null> | null;
  } | null;
};

export type GetPayrollBonusesQueryVariables = Exact<{
  payrollId: Scalars["String"];
}>;

export type GetPayrollBonusesQuery = {
  __typename?: "Query";
  getPayrollBonuses?: Array<{
    __typename?: "PayrollBonus";
    id?: string | null;
    name?: string | null;
    value?: number | null;
  } | null> | null;
};

export type GetBugsAdditionalTasksReportQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetBugsAdditionalTasksReportQuery = {
  __typename?: "Query";
  getBugsAdditionalTasksReport?: Array<{
    __typename?: "BugsAdditionalTasksReport";
    name?: string | null;
    tasks?: number | null;
    percents?: number | null;
    cost?: number | null;
  } | null> | null;
};

export type GetChartData2QueryVariables = Exact<{
  projectId: Scalars["String"];
  isEpic?: InputMaybe<Scalars["Boolean"]>;
  epicId?: InputMaybe<Scalars["String"]>;
}>;

export type GetChartData2Query = {
  __typename?: "Query";
  getChartData2?: Array<{
    __typename?: "ChartData2";
    id?: string | null;
    name?: string | null;
    start?: string | null;
    end?: string | null;
    progress?: number | null;
    type?: ChartData2Types | null;
    project?: string | null;
    isClient?: boolean | null;
    isAdditionalTask?: boolean | null;
  } | null> | null;
};

export type GetModelsByClientSiteIdQueryVariables = Exact<{
  clientSiteId: Scalars["String"];
}>;

export type GetModelsByClientSiteIdQuery = {
  __typename?: "Query";
  getModelsByClientSiteId?: {
    __typename?: "GetClientSiteModels";
    total?: number | null;
    models?: Array<{
      __typename?: "ClientSiteModel";
      id?: string | null;
      name?: string | null;
      glbFile?: string | null;
      usdzFile?: string | null;
      gltfFile?: string | null;
      itemId?: string | null;
      clientSite?: {
        __typename?: "ClientSite";
        link?: string | null;
        iframe?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetClientSiteModelQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetClientSiteModelQuery = {
  __typename?: "Query";
  getClientSiteModel?: {
    __typename?: "ClientSiteModel";
    id?: string | null;
    name?: string | null;
    itemId?: string | null;
    glbFile?: string | null;
    gltfFile?: string | null;
    usdzFile?: string | null;
    items?: Array<{
      __typename?: "ClientSiteModelItem";
      id?: string | null;
      itemId?: string | null;
    } | null> | null;
    clientSite?: {
      __typename?: "ClientSite";
      name?: string | null;
      link?: string | null;
    } | null;
  } | null;
};

export type GetClientSiteModelByItemIdQueryVariables = Exact<{
  itemId: Scalars["String"];
  siteKey: Scalars["String"];
}>;

export type GetClientSiteModelByItemIdQuery = {
  __typename?: "Query";
  getClientSiteModelByItemId?: {
    __typename?: "ClientSiteModel";
    id?: string | null;
    name?: string | null;
    glbFile?: string | null;
    gltfFile?: string | null;
    usdzFile?: string | null;
  } | null;
};

export type GetClientSiteModelItemsByModelIdQueryVariables = Exact<{
  clientSiteModelId: Scalars["String"];
}>;

export type GetClientSiteModelItemsByModelIdQuery = {
  __typename?: "Query";
  getClientSiteModelItemsByModelId?: Array<{
    __typename?: "ClientSiteModelItem";
    id?: string | null;
    itemId?: string | null;
  } | null> | null;
};

export type GetClientSitesQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientSitesQuery = {
  __typename?: "Query";
  getClientSites?: {
    __typename?: "GetClientSites";
    total?: number | null;
    clientSites?: Array<{
      __typename?: "ClientSite";
      id?: string | null;
      name?: string | null;
      link?: string | null;
      iframe?: string | null;
      key?: string | null;
    } | null> | null;
  } | null;
};

export type GetCompletionPctReportQueryVariables = Exact<{
  dates?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
}>;

export type GetCompletionPctReportQuery = {
  __typename?: "Query";
  getCompletionPctReport?: Array<{
    __typename?: "CompletionPctReport";
    projectId?: string | null;
    projectName?: string | null;
    prc?: Array<{
      __typename?: "Prc";
      plan?: number | null;
      fact?: number | null;
    } | null> | null;
  } | null> | null;
};

export type GetCompletionPctReportByEpicQueryVariables = Exact<{
  epicKey: Scalars["String"];
}>;

export type GetCompletionPctReportByEpicQuery = {
  __typename?: "Query";
  getCompletionPctReportByEpic?: {
    __typename?: "GetCompletionPctReportByEpic";
    epicCompletionPct?: number | null;
    projectCompletionPct?: number | null;
    tasks?: Array<{
      __typename?: "TaskWithCompletionPct";
      id?: string | null;
      name?: string | null;
      assigneeName?: string | null;
      status?: string | null;
      epicPrc?: number | null;
      projectPrc?: number | null;
    } | null> | null;
  } | null;
};

export type GetOldestTaskDateQueryVariables = Exact<{ [key: string]: never }>;

export type GetOldestTaskDateQuery = {
  __typename?: "Query";
  getOldestTaskDate?: string | null;
};

export type GetProjectCorrectionsLightQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetProjectCorrectionsLightQuery = {
  __typename?: "Query";
  getProjectCorrectionsLight?: Array<{
    __typename?: "CorrectionsLight";
    status?: ProjectCorrectionStatusEnum | null;
    count?: number | null;
  } | null> | null;
};

export type GetProjectCorrectionsQueryVariables = Exact<{
  projectId: Scalars["String"];
  statuses?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
}>;

export type GetProjectCorrectionsQuery = {
  __typename?: "Query";
  getProjectCorrections?: Array<{
    __typename?: "ProjectCorrection";
    id?: string | null;
    text?: string | null;
    createdDate?: any | null;
    comments?: Array<{
      __typename?: "CorrectionComment";
      id?: string | null;
      text?: string | null;
      createdDate?: any | null;
      createdBy?: {
        __typename?: "User";
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        photo?: string | null;
        job?: { __typename?: "UserJob"; name?: string | null } | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type GetCostReportQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetCostReportQuery = {
  __typename?: "Query";
  getCostReport?: Array<{
    __typename?: "CostReport";
    projectId?: string | null;
    projectName?: string | null;
    totalEstimate?: number | null;
    costEstimate?: number | null;
    cost?: number | null;
    difference?: number | null;
  } | null> | null;
};

export type GetCostReportByEpicQueryVariables = Exact<{
  epicKey: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetCostReportByEpicQuery = {
  __typename?: "Query";
  getCostReportByEpic?: {
    __typename?: "GetCostReportByEpic";
    totalCostEstimate?: number | null;
    totalCost?: number | null;
    totalDifference?: number | null;
    tasks?: Array<{
      __typename?: "TaskWithCost";
      id?: string | null;
      name?: string | null;
      assigneeName?: string | null;
      status?: string | null;
      costEstimate?: number | null;
      cost?: number | null;
      difference?: number | null;
    } | null> | null;
  } | null;
};

export type GetDashboardMasteringMoneyReportQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetDashboardMasteringMoneyReportQuery = {
  __typename?: "Query";
  getDashboardMasteringMoneyReport?: Array<{
    __typename?: "DashboardMasteringMoneyReport";
    projectId?: string | null;
    costFact?: number | null;
    costEstimate?: number | null;
    pct?: number | null;
  } | null> | null;
};

export type GetDashboardInProgressTasksReportQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetDashboardInProgressTasksReportQuery = {
  __typename?: "Query";
  getDashboardInProgressTasksReport?: Array<{
    __typename?: "DashboardInProgressTasksReport";
    projectId?: string | null;
    projectName?: string | null;
    tasksCount?: number | null;
  } | null> | null;
};

export type GetDashboardInProgressTasksQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars["String"]>;
  taskStatusId?: InputMaybe<Scalars["String"]>;
}>;

export type GetDashboardInProgressTasksQuery = {
  __typename?: "Query";
  getProjectTasks?: Array<{
    __typename?: "ProjectTask";
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetDashboardCompletionPctReportQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars["String"]>;
  date?: InputMaybe<Scalars["Date"]>;
}>;

export type GetDashboardCompletionPctReportQuery = {
  __typename?: "Query";
  getDashboardCompletionPctReport?: Array<{
    __typename?: "DashboardCompletionPctReport";
    projectId?: string | null;
    projectName?: string | null;
    pct?: number | null;
  } | null> | null;
};

export type GetDashboardAddedTasksReportQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetDashboardAddedTasksReportQuery = {
  __typename?: "Query";
  getDashboardAddedTasksReport?: Array<{
    __typename?: "DashboardAddedTasksReport";
    projectId?: string | null;
    projectName?: string | null;
    tasksCount?: number | null;
  } | null> | null;
};

export type GetDashboardAddedTasksQueryVariables = Exact<{
  projectId: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetDashboardAddedTasksQuery = {
  __typename?: "Query";
  getDashboardAddedTasks?: Array<{
    __typename?: "TaskName";
    name?: string | null;
  } | null> | null;
};

export type GetDashboardUserWorkloadReportQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetDashboardUserWorkloadReportQuery = {
  __typename?: "Query";
  getDashboardUserWorkloadReport?: Array<{
    __typename?: "DashboardUserWorkloadReport";
    userId?: string | null;
    assigneeName?: string | null;
    pct?: number | null;
  } | null> | null;
};

export type GetDashboardUserWorkloadProjectsQueryVariables = Exact<{
  assigneeId: Scalars["String"];
  projectId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetDashboardUserWorkloadProjectsQuery = {
  __typename?: "Query";
  getDashboardUserWorkloadProjects?: Array<{
    __typename?: "DashboardUserWorkloadProjectsReport";
    projectName?: string | null;
    totalSeconds?: number | null;
  } | null> | null;
};

export type GetDashboardOverdueTasksReportQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetDashboardOverdueTasksReportQuery = {
  __typename?: "Query";
  getDashboardOverdueTasksReport?: Array<{
    __typename?: "DashboardOverdueTasksReport";
    projectId?: string | null;
    projectName?: string | null;
    tasksCount?: number | null;
  } | null> | null;
};

export type GetDashboardOverdueTasksQueryVariables = Exact<{
  projectId: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetDashboardOverdueTasksQuery = {
  __typename?: "Query";
  getDashboardOverdueTasks?: Array<{
    __typename?: "TaskName";
    name?: string | null;
  } | null> | null;
};

export type GetDateReportQueryVariables = Exact<{ [key: string]: never }>;

export type GetDateReportQuery = {
  __typename?: "Query";
  getDateReport?: Array<{
    __typename?: "DateReport";
    projectId?: string | null;
    projectName?: string | null;
    startDateEstimate?: any | null;
    startDate?: any | null;
    startDifference?: number | null;
    finishDateEstimate?: any | null;
    finishDate?: any | null;
    finishDifference?: number | null;
  } | null> | null;
};

export type GetDateReportByEpicQueryVariables = Exact<{
  epicKey: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetDateReportByEpicQuery = {
  __typename?: "Query";
  getDateReportByEpic?: {
    __typename?: "GetDateReportByEpic";
    totalStartDifference?: number | null;
    totalFinishDifference?: number | null;
    tasks?: Array<{
      __typename?: "TaskWithDate";
      id?: string | null;
      name?: string | null;
      assigneeName?: string | null;
      status?: string | null;
      startDateEstimate?: string | null;
      startDateFact?: string | null;
      startDifference?: number | null;
      finishDateEstimate?: string | null;
      finishDateFact?: string | null;
      finishDifference?: number | null;
    } | null> | null;
  } | null;
};

export type GetDayReportQueryVariables = Exact<{
  payrollId: Scalars["String"];
  date: Scalars["String"];
}>;

export type GetDayReportQuery = {
  __typename?: "Query";
  getDayReport?: Array<{
    __typename?: "ReportByTasks";
    taskName?: string | null;
    time?: number | null;
  } | null> | null;
};

export type GetUserDepartmentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserDepartmentsQuery = {
  __typename?: "Query";
  getUserDepartments?: Array<{
    __typename?: "UserDepartment";
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetEmployeeWorkloadDayReportQueryVariables = Exact<{
  userId: Scalars["String"];
  date: Scalars["Date"];
}>;

export type GetEmployeeWorkloadDayReportQuery = {
  __typename?: "Query";
  getEmployeeWorkloadDayReport?: Array<{
    __typename?: "EmployeeWorkloadDayReport";
    projectName?: string | null;
    epicKey?: string | null;
    taskName?: string | null;
    key?: string | null;
    seconds?: number | null;
  } | null> | null;
};

export type GetEmployeeWorkloadReportQueryVariables = Exact<{
  userId: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetEmployeeWorkloadReportQuery = {
  __typename?: "Query";
  getEmployeeWorkloadReport?: Array<{
    __typename?: "EmployeeWorkloadReportByProject";
    projectName?: string | null;
    report?: Array<{
      __typename?: "EmployeeWorkload";
      date?: string | null;
      seconds?: number | null;
    } | null> | null;
  } | null> | null;
};

export type GetEmployeeWorkloadReportByUserQueryVariables = Exact<{
  jobName: Scalars["String"];
}>;

export type GetEmployeeWorkloadReportByUserQuery = {
  __typename?: "Query";
  getEmployeeWorkloadReportByUser?: Array<{
    __typename?: "EmployeeWorkloadReportByUser";
    userId?: string | null;
    userName?: string | null;
    projectCount?: number | null;
    totalHours?: number | null;
    totalDays?: number | null;
    totalWeeks?: number | null;
    totalMonth?: number | null;
  } | null> | null;
};

export type GetEmployeeWorkloadReportByJobsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetEmployeeWorkloadReportByJobsQuery = {
  __typename?: "Query";
  getEmployeeWorkloadReportByJobs?: Array<{
    __typename?: "EmployeeWorkloadReportByJobs";
    jobName?: string | null;
    totalHours?: number | null;
    totalDays?: number | null;
    totalWeeks?: number | null;
    totalMonth?: number | null;
  } | null> | null;
};

export type GetEmployeesDistributionLightQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetEmployeesDistributionLightQuery = {
  __typename?: "Query";
  getEmployeesDistributionLight?: Array<{
    __typename?: "EmployeesDistributionLight";
    name?: string | null;
    count?: number | null;
  } | null> | null;
};

export type GetEmployeesDistributionQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetEmployeesDistributionQuery = {
  __typename?: "Query";
  getEmployeesDistribution?: {
    __typename?: "EmployeesDistribution";
    id?: string | null;
    name?: string | null;
    projectName?: string | null;
    employeesNumber?: number | null;
    projectType?: { __typename?: "ProjectType"; name?: string | null } | null;
  } | null;
};

export type GetEmployeesDistributionsByNameQueryVariables = Exact<{
  name: Scalars["String"];
}>;

export type GetEmployeesDistributionsByNameQuery = {
  __typename?: "Query";
  getEmployeesDistributionsByName?: {
    __typename?: "GetEmployeesDistributionsByName";
    distributions?: Array<{
      __typename?: "EmployeesDistribution";
      id?: string | null;
      projectName?: string | null;
      employeesNumber?: number | null;
      projectType?: { __typename?: "ProjectType"; name?: string | null } | null;
    } | null> | null;
    additionalData?: {
      __typename?: "DistributionAdditionalData";
      employeesReport?: Array<{
        __typename?: "EmployeesReport";
        projectName?: string | null;
        employeesPercent?: number | null;
      } | null> | null;
      statusReport?: Array<{
        __typename?: "StatusReport";
        status?: string | null;
        projectsTypesPercent?: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetEpicsProgressReportQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetEpicsProgressReportQuery = {
  __typename?: "Query";
  getEpicsProgressReport?: Array<{
    __typename?: "GetEpicsProgressReport";
    id?: string | null;
    name?: string | null;
    startDate?: any | null;
    finishDate?: any | null;
    lateEnd?: number | null;
    lateStart?: number | null;
    progress?: number | null;
    total?: number | null;
  } | null> | null;
};

export type GetEventDatesQueryVariables = Exact<{
  eventId: Scalars["String"];
}>;

export type GetEventDatesQuery = {
  __typename?: "Query";
  getEventDates?: Array<{
    __typename?: "EventDate";
    id?: string | null;
    date?: string | null;
    link?: string | null;
    userLimit?: number | null;
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetEventDateQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetEventDateQuery = {
  __typename?: "Query";
  getEventDate?: {
    __typename?: "EventDate";
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null> | null;
    werePresent?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      email?: string | null;
      phone?: string | null;
      birthday?: string | null;
      city?: { __typename?: "City"; name?: string | null } | null;
      role?: {
        __typename?: "UserRole";
        role?: UserRoleEnum | null;
        displayName?: string | null;
      } | null;
    } | null> | null;
    wereNotPresent?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      email?: string | null;
      phone?: string | null;
      birthday?: string | null;
      city?: { __typename?: "City"; name?: string | null } | null;
      role?: {
        __typename?: "UserRole";
        role?: UserRoleEnum | null;
        displayName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetEventsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetEventsQuery = {
  __typename?: "Query";
  getEvents?: {
    __typename?: "GetEvents";
    events?: Array<{
      __typename?: "Event";
      id?: string | null;
      name?: string | null;
      photo?: string | null;
      createdDate?: any | null;
      dates?: Array<{
        __typename?: "EventDate";
        id?: string | null;
        date?: string | null;
        link?: string | null;
        users?: Array<{
          __typename?: "User";
          firstName?: string | null;
          lastName?: string | null;
        } | null> | null;
      } | null> | null;
      createdBy?: {
        __typename?: "User";
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetEventQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetEventQuery = {
  __typename?: "Query";
  getEvent?: {
    __typename?: "Event";
    id?: string | null;
    name?: string | null;
    photo?: string | null;
    speaker?: string | null;
    description?: string | null;
    dates?: Array<{
      __typename?: "EventDate";
      id?: string | null;
      date?: string | null;
      link?: string | null;
      userLimit?: number | null;
      users?: Array<{
        __typename?: "User";
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null> | null;
      werePresent?: Array<{
        __typename?: "User";
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null> | null;
      wereNotPresent?: Array<{
        __typename?: "User";
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetFeedbacksQueryVariables = Exact<{
  statuses?: InputMaybe<
    Array<InputMaybe<FeedbackStatusEnum>> | InputMaybe<FeedbackStatusEnum>
  >;
}>;

export type GetFeedbacksQuery = {
  __typename?: "Query";
  getFeedbacks?: Array<{
    __typename?: "Feedback";
    status?: FeedbackStatusEnum | null;
    id?: string | null;
    text?: string | null;
    createdDate?: any | null;
    updatedDate?: any | null;
    comments?: Array<{
      __typename?: "FeedbackComment";
      id?: string | null;
    } | null> | null;
    createdBy?: {
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
    } | null;
  } | null> | null;
};

export type GetFeedbacksLightQueryVariables = Exact<{ [key: string]: never }>;

export type GetFeedbacksLightQuery = {
  __typename?: "Query";
  getFeedbacksLight?: Array<{
    __typename?: "FeedbacksLight";
    status?: FeedbackStatusEnum | null;
    count?: number | null;
  } | null> | null;
};

export type GetCommentsByFeedbackIdQueryVariables = Exact<{
  feedbackId: Scalars["String"];
}>;

export type GetCommentsByFeedbackIdQuery = {
  __typename?: "Query";
  getCommentsByFeedbackId?: Array<{
    __typename?: "FeedbackComment";
    id?: string | null;
    text?: string | null;
    createdDate?: any | null;
    updatedDate?: any | null;
    files?: Array<{
      __typename?: "UploadedFile";
      id?: string | null;
      file?: string | null;
      name?: string | null;
    } | null> | null;
    feedback?: { __typename?: "Feedback"; id?: string | null } | null;
    createdBy?: {
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
    } | null;
  } | null> | null;
};

export type GetFeedbackCommentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetFeedbackCommentsQuery = {
  __typename?: "Query";
  getFeedbackComments?: Array<{
    __typename?: "FeedbackComment";
    id?: string | null;
    text?: string | null;
    files?: Array<{
      __typename?: "UploadedFile";
      file?: string | null;
      name?: string | null;
    } | null> | null;
    feedback?: { __typename?: "Feedback"; id?: string | null } | null;
    createdBy?: {
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
    } | null;
  } | null> | null;
};

export type GetRobotsHistoryQueryVariables = Exact<{ [key: string]: never }>;

export type GetRobotsHistoryQuery = {
  __typename?: "Query";
  getRobotsHistory?: Array<{
    __typename?: "RobotsHistory";
    id?: any | null;
    link?: string | null;
    order?: number | null;
  } | null> | null;
};

export type GetAwardQueryVariables = Exact<{
  getAwardId: Scalars["UUID"];
}>;

export type GetAwardQuery = {
  __typename?: "Query";
  getAward?: {
    __typename?: "Award";
    id?: any | null;
    name?: string | null;
    photo?: string | null;
    day?: number | null;
    background?: string | null;
    animation?: string | null;
    energyQuantity?: number | null;
    isPublished?: boolean | null;
    type?: AwardTypeEnum | null;
  } | null;
};

export type GetAwardsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAwardsQuery = {
  __typename?: "Query";
  getAwards?: Array<{
    __typename?: "Award";
    id?: any | null;
    name?: string | null;
    photo?: string | null;
    day?: number | null;
    background?: string | null;
    animation?: string | null;
    energyQuantity?: number | null;
    isPublished?: boolean | null;
    type?: AwardTypeEnum | null;
  } | null> | null;
};

export type GetGameModelsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGameModelsQuery = {
  __typename?: "Query";
  getGameModels?: Array<{
    __typename?: "GameModel";
    id?: string | null;
    type?: GameModelTypeEnum | null;
    name?: string | null;
    description?: string | null;
    background?: string | null;
    isPublished?: boolean | null;
    isCanBePublished?: boolean | null;
    statesCount?: number | null;
    order?: number | null;
  } | null> | null;
};

export type GetGameModelQueryVariables = Exact<{
  getGameModelId: Scalars["UUID"];
}>;

export type GetGameModelQuery = {
  __typename?: "Query";
  getGameModel?: {
    __typename?: "GameModel";
    id?: string | null;
    name?: string | null;
    description?: string | null;
    background?: string | null;
    order?: number | null;
  } | null;
};

export type GetGameModelStatesQueryVariables = Exact<{
  gameModelId: Scalars["UUID"];
}>;

export type GetGameModelStatesQuery = {
  __typename?: "Query";
  getGameModelStates?: Array<{
    __typename?: "GameModelState";
    id?: string | null;
    stageId?: string | null;
    image?: string | null;
    name?: string | null;
    description?: string | null;
    isPublished?: boolean | null;
    isCanBePublished?: boolean | null;
  } | null> | null;
};

export type GetGameModelStatesForSelectQueryVariables = Exact<{
  gameModelId: Scalars["UUID"];
}>;

export type GetGameModelStatesForSelectQuery = {
  __typename?: "Query";
  getGameModelStates?: Array<{
    __typename?: "GameModelState";
    stageId?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetGameModelStateQueryVariables = Exact<{
  getGameModelStateId: Scalars["UUID"];
}>;

export type GetGameModelStateQuery = {
  __typename?: "Query";
  getGameModelState?: {
    __typename?: "GameModelState";
    id?: string | null;
    stageId?: string | null;
    image?: string | null;
    name?: string | null;
    description?: string | null;
    experiencePrice?: number | null;
    energyPrice?: number | null;
    iosBundle?: string | null;
    iosManifest?: string | null;
    androidBundle?: string | null;
    androidManifest?: string | null;
    gameModelId?: string | null;
  } | null;
};

export type GetGameModelArtifactsQueryVariables = Exact<{
  input: GetGameModelArtifactsInput;
  take?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetGameModelArtifactsQuery = {
  __typename?: "Query";
  getGameModelArtifacts?: {
    __typename?: "GetGameModelArtifacts";
    total?: number | null;
    artifacts?: Array<{
      __typename?: "GameModelArtifact";
      id?: string | null;
      name?: string | null;
      image?: string | null;
      type?: ArtifactTypeEnum | null;
      isPublished?: boolean | null;
      isDefault?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetGameModelArtifactClothTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetGameModelArtifactClothTypesQuery = {
  __typename?: "Query";
  getGameModelArtifactClothTypes?: Array<{
    __typename?: "GameModelArtifactClothType";
    id?: string | null;
    name?: string | null;
    displayName?: string | null;
  } | null> | null;
};

export type GetGameModelStateStagesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetGameModelStateStagesQuery = {
  __typename?: "Query";
  getGameModelStateStages?: Array<{
    __typename?: "GameModelStateStage";
    id?: string | null;
    name?: number | null;
  } | null> | null;
};

export type GetRewardGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRewardGroupsQuery = {
  __typename?: "Query";
  getRewardGroups?: Array<{
    __typename?: "RewardGroup";
    id?: string | null;
    name?: string | null;
    order?: number | null;
    rewards?: Array<{
      __typename?: "Reward";
      id?: string | null;
      name?: string | null;
      groupId?: string | null;
      order?: number | null;
      value?: number | null;
      type?: RewardTypeEnum | null;
      group?: {
        __typename?: "RewardGroup";
        id?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type GetGameModelArtifactAnimationTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetGameModelArtifactAnimationTypesQuery = {
  __typename?: "Query";
  getGameModelArtifactAnimationTypes?: Array<{
    __typename?: "GameModelArtifactAnimationType";
    id?: string | null;
    name?: string | null;
    displayName?: string | null;
  } | null> | null;
};

export type GetGameModelArtifactQueryVariables = Exact<{
  getGameModelArtifactId: Scalars["UUID"];
}>;

export type GetGameModelArtifactQuery = {
  __typename?: "Query";
  getGameModelArtifact?: {
    __typename?: "GameModelArtifact";
    id?: string | null;
    name?: string | null;
    energyPrice?: number | null;
    type?: ArtifactTypeEnum | null;
    clothTypeId?: string | null;
    animationTypeId?: string | null;
    rank?: ArtifactRankEnum | null;
    image?: string | null;
    iosBundle?: string | null;
    iosManifest?: string | null;
    androidBundle?: string | null;
    androidManifest?: string | null;
    animation?: string | null;
    gameModelStateId?: string | null;
    experiencePrice?: number | null;
    isPublished?: boolean | null;
    isCanBePublished?: boolean | null;
    isDefault?: boolean | null;
  } | null;
};

export type GetGiveawayTeachersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetGiveawayTeachersQuery = {
  __typename?: "Query";
  getGiveawayTeachers?: {
    __typename?: "GetUsers";
    total?: number | null;
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      email?: string | null;
      schoolCity?: { __typename?: "SchoolCity"; name?: string | null } | null;
      school?: { __typename?: "School"; shortName?: string | null } | null;
    } | null> | null;
  } | null;
};

export type GetGiveawayTeachersTwoConditionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetGiveawayTeachersTwoConditionsQuery = {
  __typename?: "Query";
  getGiveawayTeachersTwoConditions?: {
    __typename?: "GetUsers";
    total?: number | null;
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      email?: string | null;
      schoolCity?: { __typename?: "SchoolCity"; name?: string | null } | null;
      school?: { __typename?: "School"; shortName?: string | null } | null;
    } | null> | null;
  } | null;
};

export type GetUserJobsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserJobsQuery = {
  __typename?: "Query";
  getUserJobs?: Array<{
    __typename?: "UserJob";
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetProjectKanbanQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetProjectKanbanQuery = {
  __typename?: "Query";
  getProjectKanban?: {
    __typename?: "ProjectKanban";
    id?: string | null;
    name?: string | null;
    isCompleted?: boolean | null;
  } | null;
};

export type GetKnowledgeBaseBlocksByThemeIdQueryVariables = Exact<{
  themeId: Scalars["String"];
}>;

export type GetKnowledgeBaseBlocksByThemeIdQuery = {
  __typename?: "Query";
  getKnowledgeBaseBlocksByThemeId?: Array<{
    __typename?: "KnowledgeBaseBlock";
    id?: string | null;
    type?: KnowledgeBaseBlockTypeEnum | null;
    content?: string | null;
    photo?: string | null;
    video?: string | null;
    testQuestion?: string | null;
    lists?: Array<{
      __typename?: "KnowledgeBaseBlockList";
      id?: string | null;
      content?: string | null;
      photo?: string | null;
      photoTitle?: string | null;
      video?: string | null;
      blockId?: string | null;
      isNumbered?: boolean | null;
    } | null> | null;
    testAnswers?: Array<{
      __typename?: "KnowledgeBaseBlockTestAnswer";
      id?: string | null;
      content?: string | null;
      blockId?: string | null;
      isAnswerTrue?: boolean | null;
    } | null> | null;
  } | null> | null;
};

export type GetKnowledgeBaseBlocksTestByThemeIdQueryVariables = Exact<{
  themeId: Scalars["String"];
}>;

export type GetKnowledgeBaseBlocksTestByThemeIdQuery = {
  __typename?: "Query";
  getKnowledgeBaseBlocksTestByThemeId?: Array<{
    __typename?: "KnowledgeBaseBlock";
    id?: string | null;
    type?: KnowledgeBaseBlockTypeEnum | null;
    testQuestion?: string | null;
    testAnswers?: Array<{
      __typename?: "KnowledgeBaseBlockTestAnswer";
      id?: string | null;
      content?: string | null;
      blockId?: string | null;
      isAnswerTrue?: boolean | null;
    } | null> | null;
  } | null> | null;
};

export type GetKnowledgeBaseBlocksTestHistoryQueryVariables = Exact<{
  themeId: Scalars["String"];
}>;

export type GetKnowledgeBaseBlocksTestHistoryQuery = {
  __typename?: "Query";
  getKnowledgeBaseBlocksTestHistory?: Array<{
    __typename?: "GetKnowledgeBaseTestHistory";
    user?: {
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
      job?: { __typename?: "UserJob"; name?: string | null } | null;
    } | null;
    answers?: Array<{
      __typename?: "KnowledgeBaseBlockTestAnswer";
      id?: string | null;
      isAnswerTrue?: boolean | null;
    } | null> | null;
  } | null> | null;
};

export type GetBaseKnowledgeBaseCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBaseKnowledgeBaseCategoriesQuery = {
  __typename?: "Query";
  getBaseKnowledgeBaseCategories?: {
    __typename?: "GetKnowledgeBaseCategories";
    categories?: Array<{
      __typename?: "KnowledgeBaseCategory";
      id?: string | null;
      name?: string | null;
      isFor?: KnowledgeBaseCategoryIsForEnum | null;
      photo?: string | null;
      backgroundColor?: string | null;
      availableFor?: AvailableForEnum | null;
      participants?: Array<{
        __typename?: "KnowledgeBaseCategoryParticipant";
        id?: string | null;
        user?: { __typename?: "User"; id?: string | null } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetKnowledgeBaseThemeQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetKnowledgeBaseThemeQuery = {
  __typename?: "Query";
  getKnowledgeBaseTheme?: {
    __typename?: "KnowledgeBaseTheme";
    id?: string | null;
    name?: string | null;
    categoryId?: string | null;
  } | null;
};

export type GetKnowledgeBaseThemesByCategoryIdQueryVariables = Exact<{
  categoryId: Scalars["String"];
  isPublished?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetKnowledgeBaseThemesByCategoryIdQuery = {
  __typename?: "Query";
  getKnowledgeBaseThemesByCategoryId?: Array<{
    __typename?: "KnowledgeBaseTheme";
    id?: string | null;
    name?: string | null;
    categoryId?: string | null;
  } | null> | null;
};

export type GetLessonsByMasterIdQueryVariables = Exact<{
  getLessonsParams: GetLessonsParams;
}>;

export type GetLessonsByMasterIdQuery = {
  __typename?: "Query";
  getLessonsByMasterId?: {
    __typename?: "GetLessons";
    total?: number | null;
    lessons?: Array<{
      __typename?: "Lesson";
      id?: string | null;
      name?: string | null;
      isPublished?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetLessonQueryVariables = Exact<{
  lessonId: Scalars["String"];
}>;

export type GetLessonQuery = {
  __typename?: "Query";
  getLesson?: {
    __typename?: "Lesson";
    name?: string | null;
    pages?: Array<{
      __typename?: "LessonPage";
      blocks?: Array<{
        __typename?: "LessonBlock";
        id?: string | null;
        type?: LessonBlockTypeEnum | null;
        content?: string | null;
        photo?: string | null;
        video?: string | null;
        videoPreview?: string | null;
        lists?: Array<{
          __typename?: "LessonListItem";
          id?: string | null;
          content?: string | null;
          isNumbered?: boolean | null;
          blockId?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetCreatedLessonsBySchoolTeachersQueryVariables = Exact<{
  schoolId: Scalars["UUID"];
  from: Scalars["Date"];
  to: Scalars["Date"];
}>;

export type GetCreatedLessonsBySchoolTeachersQuery = {
  __typename?: "Query";
  getCreatedLessonsBySchoolTeachers?: {
    __typename?: "ChartDataWithTotal";
    dataTotal?: number | null;
    dataChart?: Array<{
      __typename?: "AdminPanelChartData";
      tag?: Array<any | null> | null;
      value?: number | null;
    } | null> | null;
  } | null;
};

export type GetMarketingModalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMarketingModalsQuery = {
  __typename?: "Query";
  getMarketingModals?: Array<{
    __typename?: "MarketingModalInfo";
    id?: any | null;
    type?: MarketingModalInfoType | null;
    title?: string | null;
    appType?: { __typename?: "AppType"; name?: AppTypeNameEnum | null } | null;
  } | null> | null;
};

export type GetMarketingModalInfoQueryVariables = Exact<{
  appType?: InputMaybe<AppTypeNameEnum>;
  type?: InputMaybe<MarketingModalInfoType>;
}>;

export type GetMarketingModalInfoQuery = {
  __typename?: "Query";
  getMarketingModalInfo?: {
    __typename?: "MarketingModalInfo";
    id?: any | null;
    type?: MarketingModalInfoType | null;
    title?: string | null;
    subTitle?: string | null;
    note?: string | null;
    fileLink?: string | null;
    appType?: { __typename?: "AppType"; name?: AppTypeNameEnum | null } | null;
    features?: Array<{
      __typename?: "MarketingModalInfoFeatureEntity";
      id?: any | null;
      description?: string | null;
    } | null> | null;
  } | null;
};

export type GetMasteringMoneyReportQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetMasteringMoneyReportQuery = {
  __typename?: "Query";
  getMasteringMoneyReport?: Array<{
    __typename?: "MasteringMoneyReport";
    projectId?: string | null;
    projectName?: string | null;
    cost?: number | null;
    costEstimate?: number | null;
    costFact?: number | null;
  } | null> | null;
};

export type GetMobileNotificationsByManagerQueryVariables = Exact<{
  userId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetMobileNotificationsByManagerQuery = {
  __typename?: "Query";
  getMobileNotificationsByManager?: {
    __typename?: "GetMobileNotifications";
    total?: number | null;
    notifications?: Array<{
      __typename?: "MobileNotification";
      id?: string | null;
      name?: string | null;
      description?: string | null;
      photo?: string | null;
      availableFor?: AvailableForMobileNotificationEnum | null;
      isPublished?: boolean | null;
      publishedAt?: any | null;
      participants?: Array<{
        __typename?: "MobileNotificationParticipant";
        id?: string | null;
        user?: { __typename?: "User"; id?: string | null } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetMobileNotificationsByAppQueryVariables = Exact<{
  appId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetMobileNotificationsByAppQuery = {
  __typename?: "Query";
  getMobileNotificationsByApp?: {
    __typename?: "GetMobileNotifications";
    total?: number | null;
    notifications?: Array<{
      __typename?: "MobileNotification";
      id?: string | null;
      name?: string | null;
      description?: string | null;
      photo?: string | null;
      availableFor?: AvailableForMobileNotificationEnum | null;
      isPublished?: boolean | null;
      publishedAt?: any | null;
    } | null> | null;
  } | null;
};

export type GetMobileNotificationQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]>;
}>;

export type GetMobileNotificationQuery = {
  __typename?: "Query";
  getMobileNotification?: {
    __typename?: "MobileNotification";
    availableFor?: AvailableForMobileNotificationEnum | null;
  } | null;
};

export type GetNewsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetNewsQuery = {
  __typename?: "Query";
  getNews?: {
    __typename?: "GetNews";
    total?: number | null;
    news?: Array<{
      __typename?: "News";
      id?: string | null;
      name?: string | null;
      isFor?: NewsIsForEnum | null;
      description?: string | null;
      photo?: string | null;
      youTubeLink?: string | null;
      availableFor?: AvailableForEnum | null;
      isPublished?: boolean | null;
      publishedAt?: any | null;
      participants?: Array<{
        __typename?: "NewsParticipant";
        id?: string | null;
        user?: {
          __typename?: "User";
          id?: string | null;
          lastName?: string | null;
          firstName?: string | null;
          photo?: string | null;
          job?: {
            __typename?: "UserJob";
            id?: string | null;
            name?: string | null;
          } | null;
          role?: { __typename?: "UserRole"; id?: number | null } | null;
        } | null;
        news?: { __typename?: "News"; id?: string | null } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetNewsByManagerQueryVariables = Exact<{
  userId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetNewsByManagerQuery = {
  __typename?: "Query";
  getNewsByManager?: {
    __typename?: "GetNews";
    total?: number | null;
    news?: Array<{
      __typename?: "News";
      id?: string | null;
      name?: string | null;
      isFor?: NewsIsForEnum | null;
      description?: string | null;
      photo?: string | null;
      youTubeLink?: string | null;
      availableFor?: AvailableForEnum | null;
      isPublished?: boolean | null;
      publishedAt?: any | null;
      participants?: Array<{
        __typename?: "NewsParticipant";
        id?: string | null;
        user?: {
          __typename?: "User";
          id?: string | null;
          lastName?: string | null;
          firstName?: string | null;
          photo?: string | null;
          job?: {
            __typename?: "UserJob";
            id?: string | null;
            name?: string | null;
          } | null;
          role?: { __typename?: "UserRole"; id?: number | null } | null;
        } | null;
        news?: { __typename?: "News"; id?: string | null } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetNotificationsByManagerQueryVariables = Exact<{
  userId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetNotificationsByManagerQuery = {
  __typename?: "Query";
  getNotificationsByManager?: {
    __typename?: "GetNotifications";
    total?: number | null;
    notifications?: Array<{
      __typename?: "Notification";
      id?: string | null;
      name?: string | null;
      description?: string | null;
      availableFor?: AvailableForEnum | null;
      isPublished?: boolean | null;
      publishedAt?: any | null;
      participants?: Array<{
        __typename?: "NotificationParticipant";
        id?: string | null;
        isWatched?: boolean | null;
        user?: {
          __typename?: "User";
          id?: string | null;
          lastName?: string | null;
          firstName?: string | null;
          photo?: string | null;
          job?: {
            __typename?: "UserJob";
            id?: string | null;
            name?: string | null;
          } | null;
          role?: { __typename?: "UserRole"; id?: number | null } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetOnboardingThemesEditQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOnboardingThemesEditQuery = {
  __typename?: "Query";
  getOnboardingThemesEdit?: Array<{
    __typename?: "OnboardingTheme";
    id?: string | null;
    title?: string | null;
    description?: string | null;
    isPublished?: boolean | null;
    isFinished?: boolean | null;
    publishedAt?: any | null;
    position?: number | null;
    video?: string | null;
    routeId?: string | null;
  } | null> | null;
};

export type GetOnboardingRoutesEditQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOnboardingRoutesEditQuery = {
  __typename?: "Query";
  getOnboardingRoutesEdit?: Array<{
    __typename?: "OnboardingRoute";
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetUserPaymentsByProjectQueryVariables = Exact<{
  projectId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetUserPaymentsByProjectQuery = {
  __typename?: "Query";
  getUserPaymentsByProject?: {
    __typename?: "GetUserPayments";
    total?: number | null;
    payments?: Array<{
      __typename?: "UserPayment";
      id?: string | null;
      name?: string | null;
      cost?: number | null;
      isPaid?: boolean | null;
      paidDate?: any | null;
      estimatedDate?: any | null;
      isPublished?: boolean | null;
      createdDate?: any | null;
      documents?: Array<{
        __typename?: "UserPaymentDocument";
        id?: string | null;
        name?: string | null;
        fileLink?: string | null;
      } | null> | null;
      status?: {
        __typename?: "UserPaymentStatus";
        status?: UserPaymentStatusEnum | null;
        displayName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetUserPayrollsQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type GetUserPayrollsQuery = {
  __typename?: "Query";
  getUserPayrolls?: Array<{
    __typename?: "UserPayroll";
    id?: string | null;
    name?: any | null;
    hoursPlan?: number | null;
    bonuses?: Array<{
      __typename?: "PayrollBonus";
      id?: string | null;
      name?: string | null;
      value?: number | null;
    } | null> | null;
    user?: {
      __typename?: "User";
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null> | null;
};

export type GetUserPayrollQueryVariables = Exact<{
  payrollId: Scalars["String"];
}>;

export type GetUserPayrollQuery = {
  __typename?: "Query";
  getUserPayroll?: {
    __typename?: "UserPayroll";
    id?: string | null;
    name?: any | null;
    bonusFund?: number | null;
    salaryDescription?: string | null;
    bonusesDescription?: string | null;
    totalDescription?: string | null;
    kpiDescription?: string | null;
    hoursPlan?: number | null;
    bonuses?: Array<{
      __typename?: "PayrollBonus";
      value?: number | null;
    } | null> | null;
    kpi?: Array<{
      __typename?: "PayrollKpi";
      id?: string | null;
      name?: string | null;
      unit?: string | null;
      weigh?: number | null;
      plan?: number | null;
      fact?: number | null;
    } | null> | null;
    parts?: Array<{
      __typename?: "PayrollSalaryPart";
      id?: string | null;
      name?: string | null;
      value?: number | null;
      date?: any | null;
    } | null> | null;
    additionalData?: {
      __typename?: "AdditionalData";
      bonusesValue?: number | null;
      kpiValue?: number | null;
      total?: number | null;
      mainPart?: number | null;
      reports?: Array<{
        __typename?: "Report";
        totalTime?: number | null;
        times?: Array<number | null> | null;
        salary?: number | null;
      } | null> | null;
      reportsByProjects?: Array<{
        __typename?: "ReportByProjects";
        projectName?: string | null;
        time?: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetProjectsQueryVariables = Exact<{
  getProjectsInput: GetProjectsInput;
}>;

export type GetProjectsQuery = {
  __typename?: "Query";
  getProjects?: {
    __typename?: "GetProjects";
    total?: number | null;
    projects?: Array<{
      __typename?: "Project";
      id?: string | null;
      name?: string | null;
      jira?: string | null;
      jiraId?: string | null;
      finishDate?: any | null;
      stages?: Array<string | null> | null;
      projectManager?: {
        __typename?: "User";
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      status?: { __typename?: "ProjectStatus"; id?: number | null } | null;
    } | null> | null;
  } | null;
};

export type GetProjectsLightQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectsLightQuery = {
  __typename?: "Query";
  getProjectsLight?: Array<{
    __typename?: "ProjectLight";
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetProjectsForEmployeesDistributionReportQueryVariables = Exact<{
  getProjectsInput: GetProjectsInput;
}>;

export type GetProjectsForEmployeesDistributionReportQuery = {
  __typename?: "Query";
  getProjects?: {
    __typename?: "GetProjects";
    total?: number | null;
    projects?: Array<{
      __typename?: "Project";
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type GetProjectLocalStatusQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetProjectLocalStatusQuery = {
  __typename?: "Query";
  getProject?: { __typename?: "Project"; isLocal?: boolean | null } | null;
};

export type GetProjectQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetProjectQuery = {
  __typename?: "Query";
  getProject?: {
    __typename?: "Project";
    id?: string | null;
    name?: string | null;
    photo?: string | null;
    startDateEstimate?: any | null;
    finishDateEstimate?: any | null;
    startDate?: any | null;
    finishDate?: any | null;
    cost?: number | null;
    stack?: Array<string | null> | null;
    budget?: number | null;
    blockDiagrams?: string | null;
    contractTemplates?: string | null;
    finalAgreement?: string | null;
    brief?: string | null;
    testTaskFile?: string | null;
    renderingFile?: string | null;
    designFigma?: string | null;
    risks?: string | null;
    riskScore?: number | null;
    links?: Array<{
      __typename?: "ProjectLink";
      id?: string | null;
      title?: string | null;
      link?: string | null;
      login?: string | null;
      password?: string | null;
    } | null> | null;
    client?: {
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    projectManager?: {
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
    } | null;
    contactPersons?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
      job?: { __typename?: "UserJob"; name?: string | null } | null;
    } | null> | null;
    additionalContacts?: Array<{
      __typename?: "ProjectAdditionalContact";
      id?: string | null;
      name?: string | null;
      phone?: string | null;
      description?: string | null;
    } | null> | null;
    employees?: Array<{
      __typename?: "User";
      id?: string | null;
      photo?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null> | null;
    status?: {
      __typename?: "ProjectStatus";
      id?: number | null;
      displayName?: string | null;
    } | null;
    projectType?: {
      __typename?: "ProjectType";
      id?: string | null;
      name?: string | null;
    } | null;
    presentations?: Array<{
      __typename?: "ProjectPresentation";
      id?: string | null;
      link?: string | null;
      title?: string | null;
    } | null> | null;
    files?: Array<{
      __typename?: "ProjectFile";
      id?: string | null;
      file?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type GetProjectEpicsQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetProjectEpicsQuery = {
  __typename?: "Query";
  getProjectEpics?: Array<{
    __typename?: "ProjectEpic";
    epicKey?: string | null;
    epicName?: string | null;
  } | null> | null;
};

export type GetProjectStepsByProjectQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetProjectStepsByProjectQuery = {
  __typename?: "Query";
  getProjectStepsByProject?: Array<{
    __typename?: "ProjectStep";
    id?: string | null;
    name?: string | null;
    key?: string | null;
    startDateEstimate?: string | null;
    startDateFact?: string | null;
    finishDateEstimate?: string | null;
    finishDateFact?: string | null;
    status?: {
      __typename?: "ProjectTaskStatus";
      id?: string | null;
      name?: string | null;
    } | null;
  } | null> | null;
};

export type GetProjectStepsByProjectLightQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetProjectStepsByProjectLightQuery = {
  __typename?: "Query";
  getProjectStepsByProject?: Array<{
    __typename?: "ProjectStep";
    id?: string | null;
    name?: string | null;
    key?: string | null;
  } | null> | null;
};

export type GetProjectStepsFromJiraQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetProjectStepsFromJiraQuery = {
  __typename?: "Query";
  getProjectStepsFromJira?: Array<{
    __typename?: "Issue";
    expand?: string | null;
    id?: string | null;
    self?: string | null;
    key?: string | null;
    fields?: {
      __typename?: "IssueFields";
      summary?: string | null;
      duedate?: string | null;
      customfield_10015?: string | null;
    } | null;
  } | null> | null;
};

export type GetProjectTasksByEpicQueryVariables = Exact<{
  epicKey: Scalars["String"];
}>;

export type GetProjectTasksByEpicQuery = {
  __typename?: "Query";
  getProjectTasksByEpic?: Array<{
    __typename?: "ProjectTask";
    id?: string | null;
    name?: string | null;
    key?: string | null;
    assigneeName?: string | null;
    startDateEstimate?: string | null;
    startDateFact?: string | null;
    finishDateEstimate?: string | null;
    finishDateFact?: string | null;
    timeEstimate?: number | null;
    timeSpent?: number | null;
    taskStatusId?: string | null;
    assignee?: { __typename?: "User"; id?: string | null } | null;
    labels?: Array<{
      __typename?: "ProjectTaskLabel";
      id?: string | null;
      name?: string | null;
      color?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetProjectTasksListByEpicQueryVariables = Exact<{
  epicKey: Scalars["String"];
}>;

export type GetProjectTasksListByEpicQuery = {
  __typename?: "Query";
  getProjectTasksByEpic?: Array<{
    __typename?: "ProjectTask";
    id?: string | null;
    name?: string | null;
    assignee?: {
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
    } | null;
  } | null> | null;
};

export type GetProjectTasksByProjectQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetProjectTasksByProjectQuery = {
  __typename?: "Query";
  getProjectTasksByProject?: Array<{
    __typename?: "ProjectTask";
    id?: string | null;
    name?: string | null;
    key?: string | null;
    assigneeName?: string | null;
    startDateEstimate?: string | null;
    startDateFact?: string | null;
    finishDateEstimate?: string | null;
    finishDateFact?: string | null;
    timeEstimate?: number | null;
    timeSpent?: number | null;
    assignee?: { __typename?: "User"; photo?: string | null } | null;
    labels?: Array<{
      __typename?: "ProjectTaskLabel";
      id?: string | null;
      name?: string | null;
      color?: string | null;
      isBase?: boolean | null;
    } | null> | null;
  } | null> | null;
};

export type GetProjectTasksListQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetProjectTasksListQuery = {
  __typename?: "Query";
  getProjectTasksByProject?: Array<{
    __typename?: "ProjectTask";
    id?: string | null;
    name?: string | null;
    epicName?: string | null;
  } | null> | null;
};

export type GetProjectTaskQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetProjectTaskQuery = {
  __typename?: "Query";
  getProjectTask?: {
    __typename?: "ProjectTask";
    id?: string | null;
    name?: string | null;
    description?: string | null;
    key?: string | null;
    epicKey?: string | null;
    assigneeId?: string | null;
    responsibleId?: string | null;
    startDateEstimate?: string | null;
    startDateFact?: string | null;
    finishDateEstimate?: string | null;
    finishDateFact?: string | null;
    timeEstimate?: number | null;
    timeSpent?: number | null;
    comments?: Array<{
      __typename?: "ProjectTaskComment";
      id?: string | null;
      text?: string | null;
      file?: string | null;
      createdBy?: {
        __typename?: "User";
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        photo?: string | null;
      } | null;
    } | null> | null;
    items?: Array<{
      __typename?: "ProjectTaskCheckListItem";
      id?: string | null;
      name?: string | null;
      isCompleted?: boolean | null;
    } | null> | null;
    labels?: Array<{
      __typename?: "ProjectTaskLabel";
      id?: string | null;
      name?: string | null;
      color?: string | null;
    } | null> | null;
    createdBy?: {
      __typename?: "User";
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
    } | null;
  } | null;
};

export type GetProjectTasksByWorkflowStepQueryVariables = Exact<{
  projectId: Scalars["String"];
  workflowStepId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetProjectTasksByWorkflowStepQuery = {
  __typename?: "Query";
  getProjectTasksByWorkflowStep?: {
    __typename?: "GetProjectTasksByWorkflowStep";
    total?: number | null;
    tasks?: Array<{
      __typename?: "ProjectTask";
      id?: string | null;
      name?: string | null;
      assignee?: {
        __typename?: "User";
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        photo?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetPmProjectsLightQueryVariables = Exact<{ [key: string]: never }>;

export type GetPmProjectsLightQuery = {
  __typename?: "Query";
  getPMProjectsLight?: Array<{
    __typename?: "ProjectLight";
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetLateTasksQueryVariables = Exact<{
  projectId: Scalars["String"];
  taskStatusId?: InputMaybe<Scalars["String"]>;
}>;

export type GetLateTasksQuery = {
  __typename?: "Query";
  getLateTasks?: Array<{
    __typename?: "LateTasks";
    id?: string | null;
    name?: string | null;
    lateReason?: string | null;
    assigneeName?: string | null;
    startDateFact?: string | null;
    startDateEstimate?: string | null;
    finishDateFact?: string | null;
    finishDateEstimate?: string | null;
    cost?: number | null;
    costEstimate?: number | null;
    lateStart?: number | null;
    lateFinish?: number | null;
  } | null> | null;
};

export type GetProjectTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectTypesQuery = {
  __typename?: "Query";
  getProjectTypes?: Array<{
    __typename?: "ProjectType";
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetQuoteCollectionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetQuoteCollectionsQuery = {
  __typename?: "Query";
  getQuoteCollections?: Array<{
    __typename?: "QuoteCollection";
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetRateHistoryQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type GetRateHistoryQuery = {
  __typename?: "Query";
  getRateHistory?: Array<{
    __typename?: "UserRateHistory";
    id?: string | null;
    rate?: number | null;
    changeDate?: any | null;
  } | null> | null;
};

export type GetVacanciesPlanOptionsFieldsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetVacanciesPlanOptionsFieldsQuery = {
  __typename?: "Query";
  getVacanciesPlanOptionsFields?: {
    __typename?: "VacanciesPlanOptionFields";
    statuses?: Array<{
      __typename?: "VacanciesPlanStatus";
      displayName?: string | null;
      id?: number | null;
    } | null> | null;
    jobs?: Array<{
      __typename?: "UserJob";
      id?: string | null;
      name?: string | null;
    } | null> | null;
    positions?: Array<{
      __typename?: "UserPosition";
      id?: number | null;
      displayName?: string | null;
    } | null> | null;
  } | null;
};

export type GetVacanciesQueryVariables = Exact<{ [key: string]: never }>;

export type GetVacanciesQuery = {
  __typename?: "Query";
  getVacanciesPlanOptionsFields?: {
    __typename?: "VacanciesPlanOptionFields";
    jobs?: Array<{
      __typename?: "UserJob";
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type GetVacanciesPlanLightQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetVacanciesPlanLightQuery = {
  __typename?: "Query";
  getVacanciesPlanLight?: Array<{
    __typename?: "VacanciesPlanLight";
    name?: string | null;
    count?: number | null;
  } | null> | null;
};

export type GetVacanciesPlansByNameQueryVariables = Exact<{
  userId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
}>;

export type GetVacanciesPlansByNameQuery = {
  __typename?: "Query";
  getVacanciesPlansByName?: {
    __typename?: "VacanciesPlansByName";
    progress?: number | null;
    plans?: Array<{
      __typename?: "VacanciesPlan";
      id?: string | null;
      experience?: number | null;
      comment?: string | null;
      job?: {
        __typename?: "UserJob";
        id?: string | null;
        name?: string | null;
      } | null;
      position?: {
        __typename?: "UserPosition";
        id?: number | null;
        displayName?: string | null;
      } | null;
      status?: {
        __typename?: "VacanciesPlanStatus";
        id?: number | null;
        displayName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetReportsQueryVariables = Exact<{
  type?: InputMaybe<AdminReportType>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetReportsQuery = {
  __typename?: "Query";
  getReports?: {
    __typename?: "AdminReportPaginated";
    total?: number | null;
    reports?: Array<{
      __typename?: "AdminReport";
      id?: string | null;
      name?: string | null;
      link?: string | null;
      startDate?: any | null;
      endDate?: any | null;
    } | null> | null;
  } | null;
};

export type GetNewLessonReflectionReportsQueryVariables = Exact<{
  from: Scalars["Date"];
  to: Scalars["Date"];
}>;

export type GetNewLessonReflectionReportsQuery = {
  __typename?: "Query";
  getNewLessonReflectionReports?: Array<{
    __typename?: "NewLessonReport";
    schoolId?: string | null;
    schoolName?: string | null;
    subjectName?: string | null;
    educationPlanName?: string | null;
    educationPlanChapterName?: string | null;
    lessonName?: string | null;
    teacherEmail?: string | null;
    teacherFirstName?: string | null;
    teacherLastName?: string | null;
    efficiencyPercent?: number | null;
  } | null> | null;
};

export type GetCreatedPaymentsAdminReportsQueryVariables = Exact<{
  topFilter?: InputMaybe<TopFilterAdminReportCreatedPaymentsInput>;
  filter?: InputMaybe<FilterAdminReportCreatedPaymentsInput>;
}>;

export type GetCreatedPaymentsAdminReportsQuery = {
  __typename?: "Query";
  getCreatedPaymentsAdminReports?: {
    __typename?: "GetAdminReportsType";
    dataTotal?: number | null;
    dataChart?: Array<{
      __typename?: "AdminReportChartType";
      tag?: Array<any | null> | null;
      value?: number | null;
    } | null> | null;
  } | null;
};

export type GetRegisteredUsersAdminReportsQueryVariables = Exact<{
  topFilter?: InputMaybe<TopFilterAdminReportRegisteredUsersInput>;
  filter?: InputMaybe<FilterAdminReportRegisteredUsersInput>;
}>;

export type GetRegisteredUsersAdminReportsQuery = {
  __typename?: "Query";
  getRegisteredUsersAdminReports?: {
    __typename?: "GetAdminReportsType";
    dataTotal?: number | null;
    dataChart?: Array<{
      __typename?: "AdminReportChartType";
      tag?: Array<any | null> | null;
      value?: number | null;
    } | null> | null;
  } | null;
};

export type GetSalaryHistoryQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type GetSalaryHistoryQuery = {
  __typename?: "Query";
  getSalaryHistory?: Array<{
    __typename?: "UserSalaryHistory";
    id?: string | null;
    description?: string | null;
    difference?: number | null;
    previous?: number | null;
    current?: number | null;
    changeDate?: any | null;
  } | null> | null;
};

export type GetSchoolsForAdminPanelQueryVariables = Exact<{
  params: GetSchoolsForAdminPanelParams;
}>;

export type GetSchoolsForAdminPanelQuery = {
  __typename?: "Query";
  getSchoolsForAdminPanel?: {
    __typename?: "GetSchools";
    total?: number | null;
    schools?: Array<{
      __typename?: "School";
      id?: string | null;
      name?: string | null;
      phone?: string | null;
      email?: string | null;
      idShort?: string | null;
      isPersonal?: boolean | null;
      teachersCount?: number | null;
      pupilsCount?: number | null;
      subscriptionStatus?: SchoolsSubscriptionStatus | null;
      subscriptionExpiredDate?: any | null;
      isTrialSubscription?: boolean | null;
      manager?: {
        __typename?: "User";
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      country?: {
        __typename?: "SchoolCountry";
        id?: string | null;
        nameEN?: string | null;
      } | null;
      district?: {
        __typename?: "SchoolDistrict";
        id?: string | null;
        name?: string | null;
      } | null;
      region?: {
        __typename?: "SchoolRegion";
        id?: string | null;
        name?: string | null;
      } | null;
      city?: {
        __typename?: "SchoolCity";
        id?: string | null;
        name?: string | null;
      } | null;
      admins?: Array<{
        __typename?: "User";
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        email?: string | null;
        createdDate?: any | null;
      } | null> | null;
      createdBy?: {
        __typename?: "User";
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        email?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetSchoolQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetSchoolQuery = {
  __typename?: "Query";
  getSchool?: {
    __typename?: "School";
    id?: string | null;
    name?: string | null;
    phone?: string | null;
    photo?: string | null;
    email?: string | null;
    idShort?: string | null;
    isPersonal?: boolean | null;
    managerId?: string | null;
    countryId?: string | null;
    regionId?: string | null;
    districtId?: string | null;
    cityId?: string | null;
    fundingType?: SchoolFundingType | null;
    communityId?: any | null;
    isInternalUse?: boolean | null;
    whiteLabelCollectionId?: any | null;
    admins?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      createdDate?: any | null;
    } | null> | null;
    community?: {
      __typename?: "TerritorialCommunity";
      id?: any | null;
      fullName?: string | null;
    } | null;
    teachers?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null> | null;
    planUsers?: {
      __typename?: "SchoolPlanUsers";
      teachers?: {
        __typename?: "SchoolPlanUsersCount";
        plan?: number | null;
        current?: number | null;
        currentPercent?: number | null;
      } | null;
      pupils?: {
        __typename?: "SchoolPlanUsersCount";
        plan?: number | null;
        current?: number | null;
        currentPercent?: number | null;
      } | null;
      parents?: {
        __typename?: "SchoolPlanUsersCount";
        plan?: number | null;
        current?: number | null;
        currentPercent?: number | null;
      } | null;
      admins?: {
        __typename?: "SchoolPlanUsersCount";
        plan?: number | null;
        current?: number | null;
        currentPercent?: number | null;
      } | null;
      all?: {
        __typename?: "SchoolPlanUsersCount";
        plan?: number | null;
        current?: number | null;
        currentPercent?: number | null;
      } | null;
    } | null;
    subscriptionInfo?: {
      __typename?: "SubscriptionInfo";
      plan?: {
        __typename?: "SubscriptionPlan";
        id?: string | null;
        name?: SubscriptionPlanNameEnum | null;
      } | null;
    } | null;
    createdBy?: {
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
};

export type GetPupilsBySchoolQueryVariables = Exact<{
  schoolId: Scalars["String"];
}>;

export type GetPupilsBySchoolQuery = {
  __typename?: "Query";
  getPupilsBySchool?: {
    __typename?: "GetUsers";
    total?: number | null;
    users?: Array<{
      __typename?: "User";
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
    } | null> | null;
  } | null;
};

export type GetUsersForSchoolQueryVariables = Exact<{
  getUsersData: GetUsersInput;
}>;

export type GetUsersForSchoolQuery = {
  __typename?: "Query";
  getUsers?: {
    __typename?: "GetUsers";
    total?: number | null;
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
    } | null> | null;
  } | null;
};

export type GetSchoolsForSelectorQueryVariables = Exact<{
  params: GetSchoolsParams;
}>;

export type GetSchoolsForSelectorQuery = {
  __typename?: "Query";
  getSchools?: {
    __typename?: "GetSchools";
    total?: number | null;
    schools?: Array<{
      __typename?: "School";
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type GetSchoolGradesQueryVariables = Exact<{ [key: string]: never }>;

export type GetSchoolGradesQuery = {
  __typename?: "Query";
  getSchoolGrades?: Array<{
    __typename?: "SchoolGrade";
    id?: string | null;
    name?: number | null;
  } | null> | null;
};

export type GetSchoolUsersCsvQueryVariables = Exact<{
  schoolId: Scalars["UUID"];
}>;

export type GetSchoolUsersCsvQuery = {
  __typename?: "Query";
  getSchoolUsersCSV?: string | null;
};

export type GetValidateUserInvitationsListQueryVariables = Exact<{
  list: Scalars["Upload"];
}>;

export type GetValidateUserInvitationsListQuery = {
  __typename?: "Query";
  validateUserInvitationsList?: {
    __typename?: "ValidationInvitationListResponse";
    success: boolean;
    errors: Array<{
      __typename?: "CSVValidationErrors";
      line: number;
      errors: Array<string>;
    } | null>;
  } | null;
};

export type GetCoursesForAdminPanelQueryVariables = Exact<{
  schoolId: Scalars["UUID"];
}>;

export type GetCoursesForAdminPanelQuery = {
  __typename?: "Query";
  getCoursesForAdminPanel?: {
    __typename?: "PaginatedCourse";
    courses?: Array<{
      __typename?: "Course";
      id?: string | null;
      name?: string | null;
      description?: string | null;
      courseIsAvailable?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetSchoolParticipantsQueryVariables = Exact<{
  schoolId: Scalars["UUID"];
  searchKey?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SchoolParticipantSortKey>;
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetSchoolParticipantsQuery = {
  __typename?: "Query";
  getSchoolParticipants?: {
    __typename?: "SchoolParticipantsPaginated";
    total?: number | null;
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      fullName?: string | null;
      phone?: string | null;
      email?: string | null;
      role?: { __typename?: "UserRole"; role?: UserRoleEnum | null } | null;
      country?: { __typename?: "SchoolCountry"; name?: string | null } | null;
    } | null> | null;
  } | null;
};

export type GetSchoolConnectionStatisticQueryVariables = Exact<{
  roles: TeacherPupilRoleEnum;
  to: Scalars["Date"];
  from: Scalars["Date"];
  schoolId: Scalars["UUID"];
}>;

export type GetSchoolConnectionStatisticQuery = {
  __typename?: "Query";
  getSchoolConnectionStatistic?: {
    __typename?: "ChartDataWithTotal";
    dataTotal?: number | null;
    dataChart?: Array<{
      __typename?: "AdminPanelChartData";
      tag?: Array<any | null> | null;
      value?: number | null;
    } | null> | null;
  } | null;
};

export type GetSchoolPayersQueryVariables = Exact<{ [key: string]: never }>;

export type GetSchoolPayersQuery = {
  __typename?: "Query";
  getSchoolPayers?: Array<{
    __typename?: "SchoolPayer";
    id?: any | null;
    name?: string | null;
  } | null> | null;
};

export type GetTerritorialCommunitiesQueryVariables = Exact<{
  data?: InputMaybe<TerritorialCommunityInput>;
  take?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetTerritorialCommunitiesQuery = {
  __typename?: "Query";
  getTerritorialCommunities?: {
    __typename?: "GetTerritorialCommunities";
    total?: number | null;
    communities?: Array<{
      __typename?: "TerritorialCommunity";
      fullName?: string | null;
      id?: any | null;
      countryId?: any | null;
      cityId?: any | null;
      regionId?: any | null;
    } | null> | null;
  } | null;
};

export type GetSchoolCitiesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["String"]>;
  districtId?: InputMaybe<Scalars["String"]>;
}>;

export type GetSchoolCitiesQuery = {
  __typename?: "Query";
  getSchoolCities?: Array<{
    __typename?: "SchoolCity";
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetSchoolCountriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
}>;

export type GetSchoolCountriesQuery = {
  __typename?: "Query";
  getSchoolCountries?: Array<{
    __typename?: "SchoolCountry";
    id?: string | null;
    nameEN?: string | null;
    nameUKR?: string | null;
  } | null> | null;
};

export type GetSchoolDistrictsByRegionQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["String"]>;
}>;

export type GetSchoolDistrictsByRegionQuery = {
  __typename?: "Query";
  getSchoolDistrictsByRegion?: Array<{
    __typename?: "SchoolDistrict";
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetPaymentsHistoryBySchoolQueryVariables = Exact<{
  schoolId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  withTrial?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetPaymentsHistoryBySchoolQuery = {
  __typename?: "Query";
  getPaymentsHistoryBySchool?: {
    __typename?: "GetPaymentsHistory";
    total?: number | null;
    payments?: Array<{
      __typename?: "Payment";
      id?: string | null;
      paymentDate?: any | null;
      expiredDate?: any | null;
      type?: PaymentContentType | null;
      paymentType?: PaymentType | null;
      validity?: number | null;
      pupilsCount?: number | null;
      teachersCount?: number | null;
      total?: number | null;
      space?: PaymentSpaceType | null;
      amount?: number | null;
      price?: number | null;
      isActivated?: boolean | null;
      isManual?: boolean | null;
      plan?: {
        __typename?: "SubscriptionPlan";
        name?: SubscriptionPlanNameEnum | null;
        description?: string | null;
        monthsCount?: number | null;
      } | null;
      payer?: {
        __typename?: "SchoolPayer";
        id?: any | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CheckContentByProductIdQueryVariables = Exact<{
  productId: Scalars["Int"];
}>;

export type CheckContentByProductIdQuery = {
  __typename?: "Query";
  checkContentByProductId?: boolean | null;
};

export type GetPurchasePaymentsBySchoolQueryVariables = Exact<{
  schoolId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetPurchasePaymentsBySchoolQuery = {
  __typename?: "Query";
  getPurchasePaymentsBySchool?: {
    __typename?: "GetPaymentsHistory";
    total?: number | null;
    payments?: Array<{
      __typename?: "Payment";
      id?: string | null;
      paymentDate?: any | null;
      paymentType?: PaymentType | null;
      price?: number | null;
      isActivated?: boolean | null;
      content?: {
        __typename?: "UserLibraryContent";
        productId?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetSchoolRegionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  searchKey?: InputMaybe<Scalars["String"]>;
  countryId?: InputMaybe<Scalars["String"]>;
}>;

export type GetSchoolRegionsQuery = {
  __typename?: "Query";
  getSchoolRegions?: Array<{
    __typename?: "SchoolRegion";
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetSmartNotificationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<SmartNotificationTypeEnum>;
}>;

export type GetSmartNotificationsQuery = {
  __typename?: "Query";
  getSmartNotifications?: {
    __typename?: "GetSmartNotifications";
    total?: number | null;
    notifications?: Array<{
      __typename?: "SmartNotification";
      id?: string | null;
      description?: string | null;
      link?: string | null;
      isPublished?: boolean | null;
      createdDate?: any | null;
      photo?: string | null;
      photoName?: string | null;
      applicationMainModelId?: string | null;
    } | null> | null;
  } | null;
};

export type GetSmartNotificationQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]>;
}>;

export type GetSmartNotificationQuery = {
  __typename?: "Query";
  getSmartNotification?: {
    __typename?: "SmartNotification";
    id?: string | null;
    description?: string | null;
    photo?: string | null;
    photoName?: string | null;
    pollLists?: Array<{
      __typename?: "SmartNotificationPollList";
      id?: string | null;
      name?: string | null;
    } | null> | null;
    participants?: Array<{
      __typename?: "SmartNotificationParticipant";
      user?: { __typename?: "User"; id?: string | null } | null;
    } | null> | null;
  } | null;
};

export type GetSmartNotificationByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]>;
  contentType?: InputMaybe<ContentTypeInput>;
}>;

export type GetSmartNotificationByIdQuery = {
  __typename?: "Query";
  getSmartNotification?: {
    __typename?: "SmartNotification";
    id?: string | null;
    type?: SmartNotificationTypeEnum | null;
    description?: string | null;
    isPublished?: boolean | null;
    createdDate?: any | null;
    applicationMainModelId?: string | null;
    pollLists?: Array<{
      __typename?: "SmartNotificationPollList";
      id?: string | null;
    } | null> | null;
  } | null;
};

export type GetAnswersWithPercentsBySmartNotificationIdQueryVariables = Exact<{
  smartNotificationId: Scalars["String"];
}>;

export type GetAnswersWithPercentsBySmartNotificationIdQuery = {
  __typename?: "Query";
  getAnswersWithPercentsBySmartNotificationId?: {
    __typename?: "GetSmartNotificationAnswers";
    total?: number | null;
    answers?: Array<{
      __typename?: "SmartNotificationAnswersWithPercents";
      total?: number | null;
      pollListId?: string | null;
      ratting?: number | null;
      percents?: number | null;
    } | null> | null;
  } | null;
};

export type GetAnswersBySmartNotificationIdQueryVariables = Exact<{
  smartNotificationId: Scalars["String"];
}>;

export type GetAnswersBySmartNotificationIdQuery = {
  __typename?: "Query";
  getAnswersBySmartNotificationId?: Array<{
    __typename?: "SmartNotificationAnswer";
    id?: string | null;
    ratting?: number | null;
    createdDate?: any | null;
    createdBy?: {
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      email?: string | null;
      phone?: string | null;
      birthday?: string | null;
      deletedDate?: any | null;
      city?: { __typename?: "City"; name?: string | null } | null;
      role?: {
        __typename?: "UserRole";
        role?: UserRoleEnum | null;
        displayName?: string | null;
      } | null;
    } | null;
  } | null> | null;
};

export type GetAnswersBySmartNotificationPollListIdQueryVariables = Exact<{
  pollListId: Scalars["String"];
}>;

export type GetAnswersBySmartNotificationPollListIdQuery = {
  __typename?: "Query";
  getAnswersBySmartNotificationPollListId?: Array<{
    __typename?: "SmartNotificationAnswer";
    id?: string | null;
    ratting?: number | null;
  } | null> | null;
};

export type GetRatingAnswersCountBySmartNotificationIdQueryVariables = Exact<{
  smartNotificationId: Scalars["String"];
}>;

export type GetRatingAnswersCountBySmartNotificationIdQuery = {
  __typename?: "Query";
  getRatingAnswersCountBySmartNotificationId?: number | null;
};

export type GetUsersBySmartNotificationAnswerQueryVariables = Exact<{
  smartNotificationId: Scalars["String"];
  ratting?: InputMaybe<Scalars["Int"]>;
  pollListId?: InputMaybe<Scalars["String"]>;
}>;

export type GetUsersBySmartNotificationAnswerQuery = {
  __typename?: "Query";
  getUsersBySmartNotificationAnswer?: {
    __typename?: "GetUsers";
    total?: number | null;
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
    } | null> | null;
  } | null;
};

export type GetUserBySmartNotificationAnswerQueryVariables = Exact<{
  smartNotificationId: Scalars["String"];
  ratting?: InputMaybe<Scalars["Int"]>;
}>;

export type GetUserBySmartNotificationAnswerQuery = {
  __typename?: "Query";
  getUsersBySmartNotificationAnswer?: {
    __typename?: "GetUsers";
    total?: number | null;
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      phone?: string | null;
      email?: string | null;
      birthday?: string | null;
      role?: { __typename?: "UserRole"; displayName?: string | null } | null;
      city?: { __typename?: "City"; name?: string | null } | null;
    } | null> | null;
  } | null;
};

export type GetSmartNotificationPollListQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]>;
}>;

export type GetSmartNotificationPollListQuery = {
  __typename?: "Query";
  getSmartNotificationPollList?: {
    __typename?: "SmartNotificationPollList";
    id?: string | null;
    name?: string | null;
    notificationId?: string | null;
  } | null;
};

export type GetSmartNotificationPollListByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]>;
}>;

export type GetSmartNotificationPollListByIdQuery = {
  __typename?: "Query";
  getSmartNotificationPollList?: {
    __typename?: "SmartNotificationPollList";
    id?: string | null;
    name?: string | null;
    answers?: Array<{
      __typename?: "SmartNotificationAnswer";
      id?: string | null;
      createdDate?: any | null;
      createdBy?: {
        __typename?: "User";
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
        email?: string | null;
        phone?: string | null;
        birthday?: string | null;
        deletedDate?: any | null;
        city?: { __typename?: "City"; name?: string | null } | null;
        role?: {
          __typename?: "UserRole";
          role?: UserRoleEnum | null;
          displayName?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetPollListsBySmartNotificationIdQueryVariables = Exact<{
  smartNotificationId: Scalars["String"];
}>;

export type GetPollListsBySmartNotificationIdQuery = {
  __typename?: "Query";
  getPollListsBySmartNotificationId?: Array<{
    __typename?: "SmartNotificationPollList";
    id?: string | null;
    name?: string | null;
    notificationId?: string | null;
  } | null> | null;
};

export type GetSubscriptionPlansQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSubscriptionPlansQuery = {
  __typename?: "Query";
  getSubscriptionPlans?: Array<{
    __typename?: "SubscriptionPlan";
    id?: string | null;
    name?: SubscriptionPlanNameEnum | null;
    description?: string | null;
    trialPeriod?: number | null;
    price?: number | null;
  } | null> | null;
};

export type GetSubscriptionPlansV2QueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSubscriptionPlansV2Query = {
  __typename?: "Query";
  getSubscriptionPlansV2?: Array<{
    __typename?: "SubscriptionPlan";
    id?: string | null;
    name?: SubscriptionPlanNameEnum | null;
    description?: string | null;
    type?: SubscriptionPlanTypeEnum | null;
  } | null> | null;
};

export type GetSubscriptionPlansV3ForAdminPanelQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetSubscriptionPlansV3ForAdminPanelQuery = {
  __typename?: "Query";
  getSubscriptionPlansV3ForAdminPanel?: Array<{
    __typename?: "SubscriptionPlan";
    id?: string | null;
    name?: SubscriptionPlanNameEnum | null;
    description?: string | null;
    type?: SubscriptionPlanTypeEnum | null;
  } | null> | null;
};

export type GetAdminPanelSubscriptionsPaymentTypeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAdminPanelSubscriptionsPaymentTypeQuery = {
  __typename?: "Query";
  getAdminPanelSubscriptionsPaymentType?: Array<{
    __typename?: "PaymentTypeResponse";
    id?: any | null;
    name?: PaymentType | null;
  } | null> | null;
};

export type GetSubscriptionPlansForManualPromotionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSubscriptionPlansForManualPromotionQuery = {
  __typename?: "Query";
  getSubscriptionPlansForManualPromotion?: Array<{
    __typename?: "SubscriptionPlan";
    id?: string | null;
    name?: SubscriptionPlanNameEnum | null;
    description?: string | null;
    monthsCount?: number | null;
  } | null> | null;
};

export type GetManualPromotionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetManualPromotionsQuery = {
  __typename?: "Query";
  getManualPromotions?: {
    __typename?: "GetManualPromotionsResponse";
    promotions?: Array<{
      __typename?: "Promotion";
      startDate?: any | null;
      name?: string | null;
      id?: any | null;
      expiredDate?: any | null;
      discount?: number | null;
      code?: string | null;
      allowedPlans?: Array<{
        __typename?: "SubscriptionPlan";
        monthsCount?: number | null;
        id?: string | null;
        description?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetProjectTaskLabelQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetProjectTaskLabelQuery = {
  __typename?: "Query";
  getProjectTaskLabel?: {
    __typename?: "ProjectTaskLabel";
    id?: string | null;
    name?: string | null;
    color?: string | null;
    isBase?: boolean | null;
    isMutable?: boolean | null;
  } | null;
};

export type GetBaseProjectTaskLabelsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBaseProjectTaskLabelsQuery = {
  __typename?: "Query";
  getBaseProjectTaskLabels?: Array<{
    __typename?: "ProjectTaskLabel";
    id?: string | null;
    name?: string | null;
    color?: string | null;
  } | null> | null;
};

export type GetProjectTaskLabelsByProjectQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetProjectTaskLabelsByProjectQuery = {
  __typename?: "Query";
  getProjectTaskLabelsByProject?: Array<{
    __typename?: "ProjectTaskLabel";
    id?: string | null;
    name?: string | null;
    color?: string | null;
    isBase?: boolean | null;
    isMutable?: boolean | null;
  } | null> | null;
};

export type GetAvailableProjectTaskLabelsQueryVariables = Exact<{
  projectId: Scalars["String"];
  projectTaskId: Scalars["String"];
}>;

export type GetAvailableProjectTaskLabelsQuery = {
  __typename?: "Query";
  getAvailableProjectTaskLabels?: Array<{
    __typename?: "ProjectTaskLabel";
    id?: string | null;
    name?: string | null;
    color?: string | null;
  } | null> | null;
};

export type GetProjectTaskStatusQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetProjectTaskStatusQuery = {
  __typename?: "Query";
  getProjectTaskStatus?: {
    __typename?: "ProjectTaskStatus";
    id?: string | null;
    name?: string | null;
    isBase?: boolean | null;
  } | null;
};

export type GetBaseProjectTaskStatusesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBaseProjectTaskStatusesQuery = {
  __typename?: "Query";
  getBaseProjectTaskStatuses?: Array<{
    __typename?: "ProjectTaskStatus";
    id?: string | null;
    name?: string | null;
    isBase?: boolean | null;
  } | null> | null;
};

export type GetProjectTaskStatusesByProjectQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetProjectTaskStatusesByProjectQuery = {
  __typename?: "Query";
  getProjectTaskStatusesByProject?: Array<{
    __typename?: "ProjectTaskStatus";
    id?: string | null;
    name?: string | null;
    isBase?: boolean | null;
  } | null> | null;
};

export type GetTasksWithProblemsQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetTasksWithProblemsQuery = {
  __typename?: "Query";
  getTasksWithProblems?: {
    __typename?: "TasksWithProblems";
    done?: Array<{
      __typename?: "DoneProblems";
      key?: string | null;
      name?: string | null;
      startDateEstimate?: string | null;
      finishDateEstimate?: string | null;
      startDateFact?: string | null;
      finishDateFact?: string | null;
      assigneeJiraId?: string | null;
    } | null> | null;
    dateEstimate?: Array<{
      __typename?: "DateEstimateProblems";
      key?: string | null;
      name?: string | null;
      startDateEstimate?: string | null;
      finishDateEstimate?: string | null;
    } | null> | null;
    assignee?: Array<{
      __typename?: "AssigneeProblems";
      key?: string | null;
      name?: string | null;
      assigneeJiraId?: string | null;
    } | null> | null;
    noEpic?: Array<{
      __typename?: "NoEpicProblems";
      key?: string | null;
      name?: string | null;
      epicKey?: string | null;
    } | null> | null;
  } | null;
};

export type GetClassroomsByTeacherQueryVariables = Exact<{
  teacherId: Scalars["String"];
  schoolId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetClassroomsByTeacherQuery = {
  __typename?: "Query";
  getClassroomsByTeacher?: {
    __typename?: "GetClassrooms";
    classrooms?: Array<{
      __typename?: "Classroom";
      id?: string | null;
      name?: string | null;
      teacherId?: string | null;
    } | null> | null;
  } | null;
};

export type GetClassroomsByPupilQueryVariables = Exact<{
  pupilId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetClassroomsByPupilQuery = {
  __typename?: "Query";
  getClassroomsByPupil?: {
    __typename?: "GetClassrooms";
    classrooms?: Array<{
      __typename?: "Classroom";
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type GetSchoolClassroomQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetSchoolClassroomQuery = {
  __typename?: "Query";
  getSchoolClassroom?: {
    __typename?: "SchoolClassroom";
    id?: string | null;
    grade?: number | null;
    letter?: string | null;
    schoolId?: string | null;
  } | null;
};

export type GetSchoolClassroomsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetSchoolClassroomsQuery = {
  __typename?: "Query";
  getSchoolClassrooms?: {
    __typename?: "GetSchoolClassrooms";
    schoolClassrooms?: Array<{
      __typename?: "SchoolClassroom";
      id?: string | null;
      grade?: number | null;
      letter?: string | null;
      schoolId?: string | null;
    } | null> | null;
  } | null;
};

export type GetClassroomsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetClassroomsQuery = {
  __typename?: "Query";
  getClassrooms?: {
    __typename?: "GetClassrooms";
    total?: number | null;
    classrooms?: Array<{
      __typename?: "Classroom";
      id?: string | null;
      name?: string | null;
      teacherId?: string | null;
    } | null> | null;
  } | null;
};

export type GetTimeReportQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetTimeReportQuery = {
  __typename?: "Query";
  getTimeReport?: Array<{
    __typename?: "TimeReport";
    projectId?: string | null;
    projectName?: string | null;
    total?: number | null;
    totalTimeEstimate?: number | null;
    totalTimeSpent?: number | null;
    difference?: number | null;
  } | null> | null;
};

export type GetTimeReportByEpicQueryVariables = Exact<{
  epicKey: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetTimeReportByEpicQuery = {
  __typename?: "Query";
  getTimeReportByEpic?: {
    __typename?: "GetTimeReportByEpic";
    totalTimeEstimate?: number | null;
    totalTimeSpent?: number | null;
    tasks?: Array<{
      __typename?: "TaskWithTime";
      id?: string | null;
      name?: string | null;
      assigneeName?: string | null;
      status?: string | null;
      timeEstimate?: number | null;
      timeSpent?: number | null;
    } | null> | null;
  } | null;
};

export type GetTraningsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetTraningsQuery = {
  __typename?: "Query";
  getTranings?: {
    __typename?: "GetTranings";
    total?: number | null;
    tranings?: Array<{
      __typename?: "Traning";
      id?: string | null;
      nameEN?: string | null;
      nameUKR?: string | null;
      contentEN?: string | null;
      contentUKR?: string | null;
      startTitleEN?: string | null;
      startTitleUKR?: string | null;
      endTitleEN?: string | null;
      endTitleUKR?: string | null;
      photo?: string | null;
      startVideo?: string | null;
      endVideo?: string | null;
      isPublished?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetTraningQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetTraningQuery = {
  __typename?: "Query";
  getTraning?: {
    __typename?: "Traning";
    id?: string | null;
    nameEN?: string | null;
    nameUKR?: string | null;
    contentEN?: string | null;
    contentUKR?: string | null;
    startTitleEN?: string | null;
    startTitleUKR?: string | null;
    endTitleEN?: string | null;
    endTitleUKR?: string | null;
    photo?: string | null;
    startVideo?: string | null;
    endVideo?: string | null;
    isPublished?: boolean | null;
    exercises?: Array<{
      __typename?: "Exercise";
      id?: string | null;
      contentEN?: string | null;
      contentUKR?: string | null;
      video?: string | null;
      pauseTime?: number | null;
      type?: ExerciseTypeEnum | null;
    } | null> | null;
  } | null;
};

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserInfoQuery = {
  __typename?: "Query";
  getUserInfo?: {
    __typename?: "User";
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    email?: string | null;
    photo?: string | null;
    userPermissions?: Array<string | null> | null;
    role?: { __typename?: "UserRole"; role?: UserRoleEnum | null } | null;
    job?: { __typename?: "UserJob"; name?: string | null } | null;
    status?: {
      __typename?: "UserStatus";
      status?: UserStatusEnum | null;
    } | null;
  } | null;
};

export type GetUserInfoLightQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserInfoLightQuery = {
  __typename?: "Query";
  getUserInfo?: {
    __typename?: "User";
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    photo?: string | null;
  } | null;
};

export type GetPmForDropDownQueryVariables = Exact<{
  getUsersData: GetUsersInput;
}>;

export type GetPmForDropDownQuery = {
  __typename?: "Query";
  getUsers?: {
    __typename?: "GetUsers";
    total?: number | null;
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
      job?: { __typename?: "UserJob"; name?: string | null } | null;
    } | null> | null;
  } | null;
};

export type GetUsersQueryVariables = Exact<{
  getUsersData: GetUsersInput;
}>;

export type GetUsersQuery = {
  __typename?: "Query";
  getUsers?: {
    __typename?: "GetUsers";
    total?: number | null;
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      phone?: string | null;
      birthday?: string | null;
      email?: string | null;
      address?: string | null;
      addressReal?: string | null;
      photo?: string | null;
      instagram?: string | null;
      telegram?: string | null;
      contract?: boolean | null;
      drfo?: boolean | null;
      nda?: boolean | null;
      inn?: boolean | null;
      diploma?: boolean | null;
      passport?: boolean | null;
      salary?: number | null;
      creditCard?: string | null;
      bonuses?: Array<string | null> | null;
      updatedDate?: any | null;
      role?: {
        __typename?: "UserRole";
        id?: number | null;
        role?: UserRoleEnum | null;
        displayName?: string | null;
      } | null;
      status?: {
        __typename?: "UserStatus";
        id?: number | null;
        displayName?: string | null;
        status?: UserStatusEnum | null;
      } | null;
      position?: {
        __typename?: "UserPosition";
        id?: number | null;
        position?: UserPositionEnum | null;
        displayName?: string | null;
      } | null;
      stats?: {
        __typename?: "UserStats";
        flex?: number | null;
        kpi?: number | null;
        levelValue?: number | null;
      } | null;
      projects?: Array<{
        __typename?: "Project";
        id?: string | null;
        jiraId?: string | null;
        name?: string | null;
        links?: Array<{
          __typename?: "ProjectLink";
          id?: string | null;
          title?: string | null;
          link?: string | null;
          login?: string | null;
          password?: string | null;
        } | null> | null;
        status?: {
          __typename?: "ProjectStatus";
          displayName?: string | null;
        } | null;
      } | null> | null;
      groups?: Array<{
        __typename?: "UserGroup";
        id?: string | null;
        name?: string | null;
        members?: Array<{
          __typename?: "User";
          id?: string | null;
          firstName?: string | null;
        } | null> | null;
      } | null> | null;
      friends?: Array<{
        __typename?: "User";
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
      } | null> | null;
      createdBy?: {
        __typename?: "User";
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      job?: {
        __typename?: "UserJob";
        id?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetUsersForEmployeesReportQueryVariables = Exact<{
  getUsersData: GetUsersInput;
}>;

export type GetUsersForEmployeesReportQuery = {
  __typename?: "Query";
  getUsers?: {
    __typename?: "GetUsers";
    total?: number | null;
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      lastName?: string | null;
      firstName?: string | null;
      isRemote?: boolean | null;
      city?: { __typename?: "City"; name?: string | null } | null;
      job?: { __typename?: "UserJob"; name?: string | null } | null;
      department?: {
        __typename?: "UserDepartment";
        name?: string | null;
      } | null;
      agreement?: { __typename?: "UserAgreement"; name?: string | null } | null;
    } | null> | null;
  } | null;
};

export type GetUsersParticipantsQueryVariables = Exact<{
  getUsersData: GetUsersInput;
}>;

export type GetUsersParticipantsQuery = {
  __typename?: "Query";
  getUsers?: {
    __typename?: "GetUsers";
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      photo?: string | null;
      lastName?: string | null;
      firstName?: string | null;
    } | null> | null;
  } | null;
};

export type GetUsersForParticipantsModalLightQueryVariables = Exact<{
  getUsersData: GetUsersInput;
}>;

export type GetUsersForParticipantsModalLightQuery = {
  __typename?: "Query";
  getUsers?: {
    __typename?: "GetUsers";
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      photo?: string | null;
      lastName?: string | null;
      firstName?: string | null;
    } | null> | null;
  } | null;
};

export type GetUsersForDropdownQueryVariables = Exact<{
  getUsersData: GetUsersInput;
}>;

export type GetUsersForDropdownQuery = {
  __typename?: "Query";
  getUsers?: {
    __typename?: "GetUsers";
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      lastName?: string | null;
      firstName?: string | null;
      role?: {
        __typename?: "UserRole";
        id?: number | null;
        role?: UserRoleEnum | null;
        displayName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetUsersWithoutApplicationMainQueryVariables = Exact<{
  applicationMainId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetUsersWithoutApplicationMainQuery = {
  __typename?: "Query";
  getUsersWithoutApplicationMain?: {
    __typename?: "GetUsers";
    total?: number | null;
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      photo?: string | null;
      lastName?: string | null;
      firstName?: string | null;
    } | null> | null;
  } | null;
};

export type GetUserQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type GetUserQuery = {
  __typename?: "Query";
  getUser?: {
    __typename?: "User";
    id?: string | null;
    oneCId?: string | null;
    trackerId?: number | null;
    jiraId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    phone?: string | null;
    birthday?: string | null;
    email?: string | null;
    address?: string | null;
    addressReal?: string | null;
    diploma?: boolean | null;
    contractType?: string | null;
    passport?: boolean | null;
    idCard?: boolean | null;
    excerpt?: boolean | null;
    photo?: string | null;
    instagram?: string | null;
    telegram?: string | null;
    contract?: boolean | null;
    drfo?: boolean | null;
    nda?: boolean | null;
    inn?: boolean | null;
    bonuses?: Array<string | null> | null;
    salary?: number | null;
    creditCard?: string | null;
    rate?: number | null;
    previousSalary?: number | null;
    promotionDate?: any | null;
    promotionReason?: string | null;
    loadPercent?: number | null;
    hireDate?: any | null;
    isRemote?: boolean | null;
    agreementType?: {
      __typename?: "UserAgreementType";
      id?: number | null;
      type?: UserAgreementTypesEnum | null;
      displayName?: string | null;
    } | null;
    agreement?: {
      __typename?: "UserAgreement";
      id?: string | null;
      name?: string | null;
    } | null;
    role?: {
      __typename?: "UserRole";
      id?: number | null;
      role?: UserRoleEnum | null;
      displayName?: string | null;
    } | null;
    status?: {
      __typename?: "UserStatus";
      id?: number | null;
      displayName?: string | null;
      status?: UserStatusEnum | null;
    } | null;
    position?: {
      __typename?: "UserPosition";
      id?: number | null;
      position?: UserPositionEnum | null;
      displayName?: string | null;
    } | null;
    job?: {
      __typename?: "UserJob";
      id?: string | null;
      name?: string | null;
    } | null;
    projects?: Array<{
      __typename?: "Project";
      id?: string | null;
      name?: string | null;
      stages?: Array<string | null> | null;
      status?: { __typename?: "ProjectStatus"; id?: number | null } | null;
    } | null> | null;
    groups?: Array<{
      __typename?: "UserGroup";
      id?: string | null;
      name?: string | null;
    } | null> | null;
    city?: {
      __typename?: "City";
      id?: string | null;
      name?: string | null;
    } | null;
    department?: {
      __typename?: "UserDepartment";
      id?: string | null;
      name?: string | null;
    } | null;
    trackerContracts?: Array<{
      __typename?: "Contract";
      id?: string | null;
      project?: {
        __typename?: "Project";
        id?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetUsersWithoutProjectQueryVariables = Exact<{
  projectId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetUsersWithoutProjectQuery = {
  __typename?: "Query";
  getUsersWithoutProject?: {
    __typename?: "GetUsers";
    total?: number | null;
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
    } | null> | null;
  } | null;
};

export type GetUserInfoPayrollQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type GetUserInfoPayrollQuery = {
  __typename?: "Query";
  getUser?: {
    __typename?: "User";
    salary?: number | null;
    firstName?: string | null;
    lastName?: string | null;
    job?: {
      __typename?: "UserJob";
      id?: string | null;
      name?: string | null;
    } | null;
  } | null;
};

export type GetUserStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserStatusesQuery = {
  __typename?: "Query";
  getUserStatuses?: Array<{
    __typename?: "UserStatus";
    id?: number | null;
    status?: UserStatusEnum | null;
    displayName?: string | null;
  } | null> | null;
};

export type GetUserRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserRolesQuery = {
  __typename?: "Query";
  getUserRoles?: Array<{
    __typename?: "UserRole";
    id?: number | null;
    role?: UserRoleEnum | null;
    displayName?: string | null;
  } | null> | null;
};

export type GetUserPositionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserPositionsQuery = {
  __typename?: "Query";
  getUserPositions?: Array<{
    __typename?: "UserPosition";
    id?: number | null;
    position?: UserPositionEnum | null;
    displayName?: string | null;
  } | null> | null;
};

export type GetUserOptionFieldsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserOptionFieldsQuery = {
  __typename?: "Query";
  getUserOptionFields?: {
    __typename?: "UserOptionFields";
    roles?: Array<{
      __typename?: "UserRole";
      id?: number | null;
      role?: UserRoleEnum | null;
      displayName?: string | null;
    } | null> | null;
    statuses?: Array<{
      __typename?: "UserStatus";
      id?: number | null;
      status?: UserStatusEnum | null;
      displayName?: string | null;
    } | null> | null;
    jobs?: Array<{
      __typename?: "UserJob";
      id?: string | null;
      name?: string | null;
    } | null> | null;
    positions?: Array<{
      __typename?: "UserPosition";
      id?: number | null;
      position?: UserPositionEnum | null;
      displayName?: string | null;
    } | null> | null;
    cities?: Array<{
      __typename?: "City";
      id?: string | null;
      name?: string | null;
    } | null> | null;
    agreements?: Array<{
      __typename?: "UserAgreement";
      id?: string | null;
      name?: string | null;
    } | null> | null;
    departments?: Array<{
      __typename?: "UserDepartment";
      id?: string | null;
      name?: string | null;
    } | null> | null;
    agreementType?: Array<{
      __typename?: "UserAgreementType";
      id?: number | null;
      type?: UserAgreementTypesEnum | null;
      displayName?: string | null;
    } | null> | null;
  } | null;
};

export type GetUserOptionFieldsForCreateVacancyReportQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserOptionFieldsForCreateVacancyReportQuery = {
  __typename?: "Query";
  getUserOptionFields?: {
    __typename?: "UserOptionFields";
    jobs?: Array<{
      __typename?: "UserJob";
      id?: string | null;
      name?: string | null;
    } | null> | null;
    positions?: Array<{
      __typename?: "UserPosition";
      id?: number | null;
      position?: UserPositionEnum | null;
      displayName?: string | null;
    } | null> | null;
  } | null;
};

export type GetUsersCountLightQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersCountLightQuery = {
  __typename?: "Query";
  getUsersCountLight?: Array<{
    __typename?: "UsersCountLight";
    status?: UsersCountStatusesEnum | null;
    count?: number | null;
  } | null> | null;
};

export type GetUsersAppQueryVariables = Exact<{
  getUsersData: GetUsersInput;
}>;

export type GetUsersAppQuery = {
  __typename?: "Query";
  getUsers?: {
    __typename?: "GetUsers";
    total?: number | null;
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
      birthday?: string | null;
      country?: {
        __typename?: "SchoolCountry";
        id?: string | null;
        nameUKR?: string | null;
      } | null;
      role?: {
        __typename?: "UserRole";
        id?: number | null;
        role?: UserRoleEnum | null;
        displayName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetUsersByApplicationIdQueryVariables = Exact<{
  applicationId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetUsersByApplicationIdQuery = {
  __typename?: "Query";
  getUsersByApplicationId?: {
    __typename?: "GetUsers";
    total?: number | null;
    users?: Array<{
      __typename?: "User";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      instagram?: string | null;
      telegram?: string | null;
    } | null> | null;
  } | null;
};

export type GetUserAppQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type GetUserAppQuery = {
  __typename?: "Query";
  getUser?: {
    __typename?: "User";
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    birthday?: string | null;
    phone?: string | null;
    email?: string | null;
    photo?: string | null;
    countryId?: string | null;
    schoolRegionId?: string | null;
    schoolClassroomId?: string | null;
    schoolCityId?: string | null;
    schoolId?: string | null;
    applicationId?: string | null;
    schoolStatus?: SchoolUserStatusEnum | null;
    createdDate?: any | null;
    role?: {
      __typename?: "UserRole";
      id?: number | null;
      role?: UserRoleEnum | null;
      displayName?: string | null;
    } | null;
    schools?: Array<{
      __typename?: "School";
      id?: string | null;
      name?: string | null;
      isPersonal?: boolean | null;
    } | null> | null;
    status?: {
      __typename?: "UserStatus";
      id?: number | null;
      status?: UserStatusEnum | null;
    } | null;
    subjects?: Array<{
      __typename?: "ApplicationMain";
      id?: string | null;
      name?: string | null;
      applicationId?: string | null;
      application?: {
        __typename?: "Application";
        id?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
    application?: {
      __typename?: "Application";
      id?: string | null;
      name?: string | null;
    } | null;
  } | null;
};

export type GetArBookUsersCountLightQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetArBookUsersCountLightQuery = {
  __typename?: "Query";
  getARBookUsersCountLight?: Array<{
    __typename?: "ARBookUsersCountLight";
    role?: UserRoleEnum | null;
    count?: number | null;
  } | null> | null;
};

export type GetUserGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserGroupsQuery = {
  __typename?: "Query";
  getUserGroups?: Array<{
    __typename?: "UserGroup";
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetUserFeatureFlagsByUserIdQueryVariables = Exact<{
  userId: Scalars["UUID"];
}>;

export type GetUserFeatureFlagsByUserIdQuery = {
  __typename?: "Query";
  getUserFeatureFlagsByUserId?: Array<{
    __typename?: "FeatureFlag";
    id?: string | null;
    name?: string | null;
    flag?: string | null;
    isGranted?: boolean | null;
  } | null> | null;
};

export type GetUserFeatureFlagsBySchoolIdQueryVariables = Exact<{
  schoolId: Scalars["UUID"];
}>;

export type GetUserFeatureFlagsBySchoolIdQuery = {
  __typename?: "Query";
  getUserFeatureFlagsBySchoolId?: Array<{
    __typename?: "FeatureFlag";
    id?: string | null;
    name?: string | null;
    flag?: string | null;
    isGranted?: boolean | null;
  } | null> | null;
};

export type GetWhiteLabelCollectionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetWhiteLabelCollectionsQuery = {
  __typename?: "Query";
  getWhiteLabelCollections?: Array<{
    __typename?: "WhiteLabelCollection";
    id?: any | null;
    image?: string | null;
    systemLogo?: string | null;
    isPublished?: boolean | null;
    name?: string | null;
    description?: string | null;
    welcomeHtml?: string | null;
  } | null> | null;
};

export type GetWhiteLabelCollectionFullQueryVariables = Exact<{
  getWhiteLabelCollectionId: Scalars["UUID"];
}>;

export type GetWhiteLabelCollectionFullQuery = {
  __typename?: "Query";
  getWhiteLabelCollection?: {
    __typename?: "WhiteLabelCollection";
    id?: any | null;
    name?: string | null;
    description?: string | null;
    isPublished?: boolean | null;
    image?: string | null;
    schoolLogo?: string | null;
    systemLogo?: string | null;
    systemLogoLarge?: string | null;
    banner?: string | null;
    textColor?: string | null;
    backgroundColor?: string | null;
    slug?: string | null;
    welcomeHtml?: string | null;
  } | null;
};

export type GetWhiteLabelCollectionsForSchoolQueryVariables = Exact<{
  filters?: InputMaybe<GetWhiteLabelCollectionsFilters>;
}>;

export type GetWhiteLabelCollectionsForSchoolQuery = {
  __typename?: "Query";
  getWhiteLabelCollections?: Array<{
    __typename?: "WhiteLabelCollection";
    id?: any | null;
    name?: string | null;
  } | null> | null;
};

export type GetKanbanWorkflowStepQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetKanbanWorkflowStepQuery = {
  __typename?: "Query";
  getKanbanWorkflowStep?: {
    __typename?: "KanbanWorkflowStep";
    id?: string | null;
    name?: string | null;
    taskStatusId?: string | null;
    isBase?: boolean | null;
  } | null;
};

export type GetBaseKanbanWorkflowStepsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBaseKanbanWorkflowStepsQuery = {
  __typename?: "Query";
  getBaseKanbanWorkflowSteps?: Array<{
    __typename?: "KanbanWorkflowStep";
    id?: string | null;
    name?: string | null;
    taskStatusId?: string | null;
  } | null> | null;
};

export type GetKanbanWorkflowStepsByProjectQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetKanbanWorkflowStepsByProjectQuery = {
  __typename?: "Query";
  getKanbanWorkflowStepsByProject?: Array<{
    __typename?: "KanbanWorkflowStep";
    id?: string | null;
    name?: string | null;
    taskStatusId?: string | null;
    isBase?: boolean | null;
    order?: number | null;
  } | null> | null;
};

export const CreateAchieveDocument = gql`
  mutation createAchieve($achieveData: CreateAchieveInput!, $photo: Upload) {
    createAchieve(achieveData: $achieveData, photo: $photo) {
      id
    }
  }
`;
export type CreateAchieveMutationFn = Apollo.MutationFunction<
  CreateAchieveMutation,
  CreateAchieveMutationVariables
>;

/**
 * __useCreateAchieveMutation__
 *
 * To run a mutation, you first call `useCreateAchieveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAchieveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAchieveMutation, { data, loading, error }] = useCreateAchieveMutation({
 *   variables: {
 *      achieveData: // value for 'achieveData'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useCreateAchieveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAchieveMutation,
    CreateAchieveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAchieveMutation,
    CreateAchieveMutationVariables
  >(CreateAchieveDocument, options);
}
export type CreateAchieveMutationHookResult = ReturnType<
  typeof useCreateAchieveMutation
>;
export type CreateAchieveMutationResult =
  Apollo.MutationResult<CreateAchieveMutation>;
export type CreateAchieveMutationOptions = Apollo.BaseMutationOptions<
  CreateAchieveMutation,
  CreateAchieveMutationVariables
>;
export const UpdateAchieveDocument = gql`
  mutation updateAchieve($achieveData: UpdateAchieveInput!, $photo: Upload) {
    updateAchieve(achieveData: $achieveData, photo: $photo) {
      id
    }
  }
`;
export type UpdateAchieveMutationFn = Apollo.MutationFunction<
  UpdateAchieveMutation,
  UpdateAchieveMutationVariables
>;

/**
 * __useUpdateAchieveMutation__
 *
 * To run a mutation, you first call `useUpdateAchieveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAchieveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAchieveMutation, { data, loading, error }] = useUpdateAchieveMutation({
 *   variables: {
 *      achieveData: // value for 'achieveData'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useUpdateAchieveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAchieveMutation,
    UpdateAchieveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAchieveMutation,
    UpdateAchieveMutationVariables
  >(UpdateAchieveDocument, options);
}
export type UpdateAchieveMutationHookResult = ReturnType<
  typeof useUpdateAchieveMutation
>;
export type UpdateAchieveMutationResult =
  Apollo.MutationResult<UpdateAchieveMutation>;
export type UpdateAchieveMutationOptions = Apollo.BaseMutationOptions<
  UpdateAchieveMutation,
  UpdateAchieveMutationVariables
>;
export const PublishAchieveDocument = gql`
  mutation publishAchieve($id: String!) {
    publishAchieve(id: $id)
  }
`;
export type PublishAchieveMutationFn = Apollo.MutationFunction<
  PublishAchieveMutation,
  PublishAchieveMutationVariables
>;

/**
 * __usePublishAchieveMutation__
 *
 * To run a mutation, you first call `usePublishAchieveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishAchieveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishAchieveMutation, { data, loading, error }] = usePublishAchieveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishAchieveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishAchieveMutation,
    PublishAchieveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishAchieveMutation,
    PublishAchieveMutationVariables
  >(PublishAchieveDocument, options);
}
export type PublishAchieveMutationHookResult = ReturnType<
  typeof usePublishAchieveMutation
>;
export type PublishAchieveMutationResult =
  Apollo.MutationResult<PublishAchieveMutation>;
export type PublishAchieveMutationOptions = Apollo.BaseMutationOptions<
  PublishAchieveMutation,
  PublishAchieveMutationVariables
>;
export const DeleteAchieveDocument = gql`
  mutation deleteAchieve($id: String!) {
    deleteAchieve(id: $id)
  }
`;
export type DeleteAchieveMutationFn = Apollo.MutationFunction<
  DeleteAchieveMutation,
  DeleteAchieveMutationVariables
>;

/**
 * __useDeleteAchieveMutation__
 *
 * To run a mutation, you first call `useDeleteAchieveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAchieveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAchieveMutation, { data, loading, error }] = useDeleteAchieveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAchieveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAchieveMutation,
    DeleteAchieveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAchieveMutation,
    DeleteAchieveMutationVariables
  >(DeleteAchieveDocument, options);
}
export type DeleteAchieveMutationHookResult = ReturnType<
  typeof useDeleteAchieveMutation
>;
export type DeleteAchieveMutationResult =
  Apollo.MutationResult<DeleteAchieveMutation>;
export type DeleteAchieveMutationOptions = Apollo.BaseMutationOptions<
  DeleteAchieveMutation,
  DeleteAchieveMutationVariables
>;
export const CreateUserAgreementDocument = gql`
  mutation createUserAgreement($name: String!) {
    createUserAgreement(name: $name) {
      id
    }
  }
`;
export type CreateUserAgreementMutationFn = Apollo.MutationFunction<
  CreateUserAgreementMutation,
  CreateUserAgreementMutationVariables
>;

/**
 * __useCreateUserAgreementMutation__
 *
 * To run a mutation, you first call `useCreateUserAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAgreementMutation, { data, loading, error }] = useCreateUserAgreementMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateUserAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserAgreementMutation,
    CreateUserAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserAgreementMutation,
    CreateUserAgreementMutationVariables
  >(CreateUserAgreementDocument, options);
}
export type CreateUserAgreementMutationHookResult = ReturnType<
  typeof useCreateUserAgreementMutation
>;
export type CreateUserAgreementMutationResult =
  Apollo.MutationResult<CreateUserAgreementMutation>;
export type CreateUserAgreementMutationOptions = Apollo.BaseMutationOptions<
  CreateUserAgreementMutation,
  CreateUserAgreementMutationVariables
>;
export const UpdateUserAgreementDocument = gql`
  mutation updateUserAgreement($userAgreementId: String!, $name: String!) {
    updateUserAgreement(userAgreementId: $userAgreementId, name: $name) {
      id
    }
  }
`;
export type UpdateUserAgreementMutationFn = Apollo.MutationFunction<
  UpdateUserAgreementMutation,
  UpdateUserAgreementMutationVariables
>;

/**
 * __useUpdateUserAgreementMutation__
 *
 * To run a mutation, you first call `useUpdateUserAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAgreementMutation, { data, loading, error }] = useUpdateUserAgreementMutation({
 *   variables: {
 *      userAgreementId: // value for 'userAgreementId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUserAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserAgreementMutation,
    UpdateUserAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserAgreementMutation,
    UpdateUserAgreementMutationVariables
  >(UpdateUserAgreementDocument, options);
}
export type UpdateUserAgreementMutationHookResult = ReturnType<
  typeof useUpdateUserAgreementMutation
>;
export type UpdateUserAgreementMutationResult =
  Apollo.MutationResult<UpdateUserAgreementMutation>;
export type UpdateUserAgreementMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserAgreementMutation,
  UpdateUserAgreementMutationVariables
>;
export const DeleteUserAgreementDocument = gql`
  mutation deleteUserAgreement($userAgreementId: String!) {
    deleteUserAgreement(userAgreementId: $userAgreementId)
  }
`;
export type DeleteUserAgreementMutationFn = Apollo.MutationFunction<
  DeleteUserAgreementMutation,
  DeleteUserAgreementMutationVariables
>;

/**
 * __useDeleteUserAgreementMutation__
 *
 * To run a mutation, you first call `useDeleteUserAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAgreementMutation, { data, loading, error }] = useDeleteUserAgreementMutation({
 *   variables: {
 *      userAgreementId: // value for 'userAgreementId'
 *   },
 * });
 */
export function useDeleteUserAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserAgreementMutation,
    DeleteUserAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserAgreementMutation,
    DeleteUserAgreementMutationVariables
  >(DeleteUserAgreementDocument, options);
}
export type DeleteUserAgreementMutationHookResult = ReturnType<
  typeof useDeleteUserAgreementMutation
>;
export type DeleteUserAgreementMutationResult =
  Apollo.MutationResult<DeleteUserAgreementMutation>;
export type DeleteUserAgreementMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserAgreementMutation,
  DeleteUserAgreementMutationVariables
>;
export const CreateAnimationBundleDocument = gql`
  mutation createAnimationBundle(
    $animationData: CreateAnimationBundleInput!
    $photo: Upload
  ) {
    createAnimationBundle(animationData: $animationData, photo: $photo) {
      id
      type {
        type
      }
      participants {
        id
      }
      iosBundle {
        id
        name
        fileLink
      }
      androidBundle {
        id
        name
        fileLink
      }
    }
  }
`;
export type CreateAnimationBundleMutationFn = Apollo.MutationFunction<
  CreateAnimationBundleMutation,
  CreateAnimationBundleMutationVariables
>;

/**
 * __useCreateAnimationBundleMutation__
 *
 * To run a mutation, you first call `useCreateAnimationBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnimationBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnimationBundleMutation, { data, loading, error }] = useCreateAnimationBundleMutation({
 *   variables: {
 *      animationData: // value for 'animationData'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useCreateAnimationBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnimationBundleMutation,
    CreateAnimationBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAnimationBundleMutation,
    CreateAnimationBundleMutationVariables
  >(CreateAnimationBundleDocument, options);
}
export type CreateAnimationBundleMutationHookResult = ReturnType<
  typeof useCreateAnimationBundleMutation
>;
export type CreateAnimationBundleMutationResult =
  Apollo.MutationResult<CreateAnimationBundleMutation>;
export type CreateAnimationBundleMutationOptions = Apollo.BaseMutationOptions<
  CreateAnimationBundleMutation,
  CreateAnimationBundleMutationVariables
>;
export const UpdateAnimationBundleDocument = gql`
  mutation updateAnimationBundle(
    $animationData: UpdateAnimationBundleInput!
    $photo: Upload
  ) {
    updateAnimationBundle(animationData: $animationData, photo: $photo) {
      id
    }
  }
`;
export type UpdateAnimationBundleMutationFn = Apollo.MutationFunction<
  UpdateAnimationBundleMutation,
  UpdateAnimationBundleMutationVariables
>;

/**
 * __useUpdateAnimationBundleMutation__
 *
 * To run a mutation, you first call `useUpdateAnimationBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnimationBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnimationBundleMutation, { data, loading, error }] = useUpdateAnimationBundleMutation({
 *   variables: {
 *      animationData: // value for 'animationData'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useUpdateAnimationBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnimationBundleMutation,
    UpdateAnimationBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAnimationBundleMutation,
    UpdateAnimationBundleMutationVariables
  >(UpdateAnimationBundleDocument, options);
}
export type UpdateAnimationBundleMutationHookResult = ReturnType<
  typeof useUpdateAnimationBundleMutation
>;
export type UpdateAnimationBundleMutationResult =
  Apollo.MutationResult<UpdateAnimationBundleMutation>;
export type UpdateAnimationBundleMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnimationBundleMutation,
  UpdateAnimationBundleMutationVariables
>;
export const DeleteAnimationBundleDocument = gql`
  mutation deleteAnimationBundle($animationId: String!) {
    deleteAnimationBundle(animationId: $animationId)
  }
`;
export type DeleteAnimationBundleMutationFn = Apollo.MutationFunction<
  DeleteAnimationBundleMutation,
  DeleteAnimationBundleMutationVariables
>;

/**
 * __useDeleteAnimationBundleMutation__
 *
 * To run a mutation, you first call `useDeleteAnimationBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnimationBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnimationBundleMutation, { data, loading, error }] = useDeleteAnimationBundleMutation({
 *   variables: {
 *      animationId: // value for 'animationId'
 *   },
 * });
 */
export function useDeleteAnimationBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAnimationBundleMutation,
    DeleteAnimationBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAnimationBundleMutation,
    DeleteAnimationBundleMutationVariables
  >(DeleteAnimationBundleDocument, options);
}
export type DeleteAnimationBundleMutationHookResult = ReturnType<
  typeof useDeleteAnimationBundleMutation
>;
export type DeleteAnimationBundleMutationResult =
  Apollo.MutationResult<DeleteAnimationBundleMutation>;
export type DeleteAnimationBundleMutationOptions = Apollo.BaseMutationOptions<
  DeleteAnimationBundleMutation,
  DeleteAnimationBundleMutationVariables
>;
export const AssignAnimationBundleParticipantsDocument = gql`
  mutation assignAnimationBundleParticipants(
    $animationId: String!
    $userIds: [String]!
    $availableForEveryone: Boolean!
  ) {
    assignAnimationBundleParticipants(
      animationId: $animationId
      userIds: $userIds
      availableForEveryone: $availableForEveryone
    )
  }
`;
export type AssignAnimationBundleParticipantsMutationFn =
  Apollo.MutationFunction<
    AssignAnimationBundleParticipantsMutation,
    AssignAnimationBundleParticipantsMutationVariables
  >;

/**
 * __useAssignAnimationBundleParticipantsMutation__
 *
 * To run a mutation, you first call `useAssignAnimationBundleParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignAnimationBundleParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignAnimationBundleParticipantsMutation, { data, loading, error }] = useAssignAnimationBundleParticipantsMutation({
 *   variables: {
 *      animationId: // value for 'animationId'
 *      userIds: // value for 'userIds'
 *      availableForEveryone: // value for 'availableForEveryone'
 *   },
 * });
 */
export function useAssignAnimationBundleParticipantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignAnimationBundleParticipantsMutation,
    AssignAnimationBundleParticipantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignAnimationBundleParticipantsMutation,
    AssignAnimationBundleParticipantsMutationVariables
  >(AssignAnimationBundleParticipantsDocument, options);
}
export type AssignAnimationBundleParticipantsMutationHookResult = ReturnType<
  typeof useAssignAnimationBundleParticipantsMutation
>;
export type AssignAnimationBundleParticipantsMutationResult =
  Apollo.MutationResult<AssignAnimationBundleParticipantsMutation>;
export type AssignAnimationBundleParticipantsMutationOptions =
  Apollo.BaseMutationOptions<
    AssignAnimationBundleParticipantsMutation,
    AssignAnimationBundleParticipantsMutationVariables
  >;
export const SetIosBundleDocument = gql`
  mutation setIosBundle(
    $animationId: String!
    $deletedIosBundleId: String
    $newIosBundle: Upload
  ) {
    setIosBundle(
      animationId: $animationId
      deletedIosBundleId: $deletedIosBundleId
      newIosBundle: $newIosBundle
    )
  }
`;
export type SetIosBundleMutationFn = Apollo.MutationFunction<
  SetIosBundleMutation,
  SetIosBundleMutationVariables
>;

/**
 * __useSetIosBundleMutation__
 *
 * To run a mutation, you first call `useSetIosBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIosBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIosBundleMutation, { data, loading, error }] = useSetIosBundleMutation({
 *   variables: {
 *      animationId: // value for 'animationId'
 *      deletedIosBundleId: // value for 'deletedIosBundleId'
 *      newIosBundle: // value for 'newIosBundle'
 *   },
 * });
 */
export function useSetIosBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetIosBundleMutation,
    SetIosBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetIosBundleMutation,
    SetIosBundleMutationVariables
  >(SetIosBundleDocument, options);
}
export type SetIosBundleMutationHookResult = ReturnType<
  typeof useSetIosBundleMutation
>;
export type SetIosBundleMutationResult =
  Apollo.MutationResult<SetIosBundleMutation>;
export type SetIosBundleMutationOptions = Apollo.BaseMutationOptions<
  SetIosBundleMutation,
  SetIosBundleMutationVariables
>;
export const SetAndroidBundleDocument = gql`
  mutation setAndroidBundle(
    $animationId: String!
    $deletedAndroidBundleId: String
    $newAndroidBundle: Upload
  ) {
    setAndroidBundle(
      animationId: $animationId
      deletedAndroidBundleId: $deletedAndroidBundleId
      newAndroidBundle: $newAndroidBundle
    )
  }
`;
export type SetAndroidBundleMutationFn = Apollo.MutationFunction<
  SetAndroidBundleMutation,
  SetAndroidBundleMutationVariables
>;

/**
 * __useSetAndroidBundleMutation__
 *
 * To run a mutation, you first call `useSetAndroidBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAndroidBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAndroidBundleMutation, { data, loading, error }] = useSetAndroidBundleMutation({
 *   variables: {
 *      animationId: // value for 'animationId'
 *      deletedAndroidBundleId: // value for 'deletedAndroidBundleId'
 *      newAndroidBundle: // value for 'newAndroidBundle'
 *   },
 * });
 */
export function useSetAndroidBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAndroidBundleMutation,
    SetAndroidBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetAndroidBundleMutation,
    SetAndroidBundleMutationVariables
  >(SetAndroidBundleDocument, options);
}
export type SetAndroidBundleMutationHookResult = ReturnType<
  typeof useSetAndroidBundleMutation
>;
export type SetAndroidBundleMutationResult =
  Apollo.MutationResult<SetAndroidBundleMutation>;
export type SetAndroidBundleMutationOptions = Apollo.BaseMutationOptions<
  SetAndroidBundleMutation,
  SetAndroidBundleMutationVariables
>;
export const CreateAppMainThemeBackgroundDocument = gql`
  mutation createAppMainThemeBackground(
    $name: String!
    $fileIOS: Upload!
    $fileAndroid: Upload!
    $fileWebGl: Upload!
    $manifestIOS: Upload
    $manifestAndroid: Upload
    $manifestWebGl: Upload
  ) {
    createAppMainThemeBackground(
      name: $name
      fileIOS: $fileIOS
      fileAndroid: $fileAndroid
      fileWebGl: $fileWebGl
      manifestIOS: $manifestIOS
      manifestAndroid: $manifestAndroid
      manifestWebGl: $manifestWebGl
    ) {
      id
    }
  }
`;
export type CreateAppMainThemeBackgroundMutationFn = Apollo.MutationFunction<
  CreateAppMainThemeBackgroundMutation,
  CreateAppMainThemeBackgroundMutationVariables
>;

/**
 * __useCreateAppMainThemeBackgroundMutation__
 *
 * To run a mutation, you first call `useCreateAppMainThemeBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppMainThemeBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppMainThemeBackgroundMutation, { data, loading, error }] = useCreateAppMainThemeBackgroundMutation({
 *   variables: {
 *      name: // value for 'name'
 *      fileIOS: // value for 'fileIOS'
 *      fileAndroid: // value for 'fileAndroid'
 *      fileWebGl: // value for 'fileWebGl'
 *      manifestIOS: // value for 'manifestIOS'
 *      manifestAndroid: // value for 'manifestAndroid'
 *      manifestWebGl: // value for 'manifestWebGl'
 *   },
 * });
 */
export function useCreateAppMainThemeBackgroundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppMainThemeBackgroundMutation,
    CreateAppMainThemeBackgroundMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAppMainThemeBackgroundMutation,
    CreateAppMainThemeBackgroundMutationVariables
  >(CreateAppMainThemeBackgroundDocument, options);
}
export type CreateAppMainThemeBackgroundMutationHookResult = ReturnType<
  typeof useCreateAppMainThemeBackgroundMutation
>;
export type CreateAppMainThemeBackgroundMutationResult =
  Apollo.MutationResult<CreateAppMainThemeBackgroundMutation>;
export type CreateAppMainThemeBackgroundMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAppMainThemeBackgroundMutation,
    CreateAppMainThemeBackgroundMutationVariables
  >;
export const UpdateAppMainThemeBackgroundDocument = gql`
  mutation updateAppMainThemeBackground(
    $id: String!
    $name: String
    $fileIOS: Upload
    $fileAndroid: Upload
    $fileWebGl: Upload
    $manifestIOS: Upload
    $manifestAndroid: Upload
    $manifestWebGl: Upload
  ) {
    updateAppMainThemeBackground(
      id: $id
      name: $name
      fileIOS: $fileIOS
      fileAndroid: $fileAndroid
      fileWebGl: $fileWebGl
      manifestIOS: $manifestIOS
      manifestAndroid: $manifestAndroid
      manifestWebGl: $manifestWebGl
    ) {
      id
    }
  }
`;
export type UpdateAppMainThemeBackgroundMutationFn = Apollo.MutationFunction<
  UpdateAppMainThemeBackgroundMutation,
  UpdateAppMainThemeBackgroundMutationVariables
>;

/**
 * __useUpdateAppMainThemeBackgroundMutation__
 *
 * To run a mutation, you first call `useUpdateAppMainThemeBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppMainThemeBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppMainThemeBackgroundMutation, { data, loading, error }] = useUpdateAppMainThemeBackgroundMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      fileIOS: // value for 'fileIOS'
 *      fileAndroid: // value for 'fileAndroid'
 *      fileWebGl: // value for 'fileWebGl'
 *      manifestIOS: // value for 'manifestIOS'
 *      manifestAndroid: // value for 'manifestAndroid'
 *      manifestWebGl: // value for 'manifestWebGl'
 *   },
 * });
 */
export function useUpdateAppMainThemeBackgroundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppMainThemeBackgroundMutation,
    UpdateAppMainThemeBackgroundMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppMainThemeBackgroundMutation,
    UpdateAppMainThemeBackgroundMutationVariables
  >(UpdateAppMainThemeBackgroundDocument, options);
}
export type UpdateAppMainThemeBackgroundMutationHookResult = ReturnType<
  typeof useUpdateAppMainThemeBackgroundMutation
>;
export type UpdateAppMainThemeBackgroundMutationResult =
  Apollo.MutationResult<UpdateAppMainThemeBackgroundMutation>;
export type UpdateAppMainThemeBackgroundMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAppMainThemeBackgroundMutation,
    UpdateAppMainThemeBackgroundMutationVariables
  >;
export const DeleteAppMainThemeBackgroundDocument = gql`
  mutation deleteAppMainThemeBackground($id: String!) {
    deleteAppMainThemeBackground(id: $id)
  }
`;
export type DeleteAppMainThemeBackgroundMutationFn = Apollo.MutationFunction<
  DeleteAppMainThemeBackgroundMutation,
  DeleteAppMainThemeBackgroundMutationVariables
>;

/**
 * __useDeleteAppMainThemeBackgroundMutation__
 *
 * To run a mutation, you first call `useDeleteAppMainThemeBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppMainThemeBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppMainThemeBackgroundMutation, { data, loading, error }] = useDeleteAppMainThemeBackgroundMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAppMainThemeBackgroundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAppMainThemeBackgroundMutation,
    DeleteAppMainThemeBackgroundMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAppMainThemeBackgroundMutation,
    DeleteAppMainThemeBackgroundMutationVariables
  >(DeleteAppMainThemeBackgroundDocument, options);
}
export type DeleteAppMainThemeBackgroundMutationHookResult = ReturnType<
  typeof useDeleteAppMainThemeBackgroundMutation
>;
export type DeleteAppMainThemeBackgroundMutationResult =
  Apollo.MutationResult<DeleteAppMainThemeBackgroundMutation>;
export type DeleteAppMainThemeBackgroundMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteAppMainThemeBackgroundMutation,
    DeleteAppMainThemeBackgroundMutationVariables
  >;
export const UpdateApplicationFilesDocument = gql`
  mutation updateApplicationFiles(
    $applicationId: String!
    $file: Upload!
    $ApplicationFileType: ApplicationFileTypeEnum!
  ) {
    updateApplicationFiles(
      applicationId: $applicationId
      file: $file
      ApplicationFileType: $ApplicationFileType
    )
  }
`;
export type UpdateApplicationFilesMutationFn = Apollo.MutationFunction<
  UpdateApplicationFilesMutation,
  UpdateApplicationFilesMutationVariables
>;

/**
 * __useUpdateApplicationFilesMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationFilesMutation, { data, loading, error }] = useUpdateApplicationFilesMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      file: // value for 'file'
 *      ApplicationFileType: // value for 'ApplicationFileType'
 *   },
 * });
 */
export function useUpdateApplicationFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationFilesMutation,
    UpdateApplicationFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationFilesMutation,
    UpdateApplicationFilesMutationVariables
  >(UpdateApplicationFilesDocument, options);
}
export type UpdateApplicationFilesMutationHookResult = ReturnType<
  typeof useUpdateApplicationFilesMutation
>;
export type UpdateApplicationFilesMutationResult =
  Apollo.MutationResult<UpdateApplicationFilesMutation>;
export type UpdateApplicationFilesMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationFilesMutation,
  UpdateApplicationFilesMutationVariables
>;
export const DeleteApplicationFileDocument = gql`
  mutation deleteApplicationFile(
    $applicationId: String!
    $ApplicationFileType: ApplicationFileTypeEnum!
  ) {
    deleteApplicationFile(
      applicationId: $applicationId
      ApplicationFileType: $ApplicationFileType
    )
  }
`;
export type DeleteApplicationFileMutationFn = Apollo.MutationFunction<
  DeleteApplicationFileMutation,
  DeleteApplicationFileMutationVariables
>;

/**
 * __useDeleteApplicationFileMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationFileMutation, { data, loading, error }] = useDeleteApplicationFileMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      ApplicationFileType: // value for 'ApplicationFileType'
 *   },
 * });
 */
export function useDeleteApplicationFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApplicationFileMutation,
    DeleteApplicationFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApplicationFileMutation,
    DeleteApplicationFileMutationVariables
  >(DeleteApplicationFileDocument, options);
}
export type DeleteApplicationFileMutationHookResult = ReturnType<
  typeof useDeleteApplicationFileMutation
>;
export type DeleteApplicationFileMutationResult =
  Apollo.MutationResult<DeleteApplicationFileMutation>;
export type DeleteApplicationFileMutationOptions = Apollo.BaseMutationOptions<
  DeleteApplicationFileMutation,
  DeleteApplicationFileMutationVariables
>;
export const CreateApplicationLinkDocument = gql`
  mutation createApplicationLink(
    $name: String!
    $link: String!
    $applicationId: String!
  ) {
    createApplicationLink(
      name: $name
      link: $link
      applicationId: $applicationId
    ) {
      id
    }
  }
`;
export type CreateApplicationLinkMutationFn = Apollo.MutationFunction<
  CreateApplicationLinkMutation,
  CreateApplicationLinkMutationVariables
>;

/**
 * __useCreateApplicationLinkMutation__
 *
 * To run a mutation, you first call `useCreateApplicationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationLinkMutation, { data, loading, error }] = useCreateApplicationLinkMutation({
 *   variables: {
 *      name: // value for 'name'
 *      link: // value for 'link'
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useCreateApplicationLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApplicationLinkMutation,
    CreateApplicationLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateApplicationLinkMutation,
    CreateApplicationLinkMutationVariables
  >(CreateApplicationLinkDocument, options);
}
export type CreateApplicationLinkMutationHookResult = ReturnType<
  typeof useCreateApplicationLinkMutation
>;
export type CreateApplicationLinkMutationResult =
  Apollo.MutationResult<CreateApplicationLinkMutation>;
export type CreateApplicationLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicationLinkMutation,
  CreateApplicationLinkMutationVariables
>;
export const UpdateApplicationLinkDocument = gql`
  mutation updateApplicationLink(
    $name: String
    $link: String
    $applicationLinkId: String!
  ) {
    updateApplicationLink(
      name: $name
      link: $link
      applicationLinkId: $applicationLinkId
    ) {
      id
    }
  }
`;
export type UpdateApplicationLinkMutationFn = Apollo.MutationFunction<
  UpdateApplicationLinkMutation,
  UpdateApplicationLinkMutationVariables
>;

/**
 * __useUpdateApplicationLinkMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationLinkMutation, { data, loading, error }] = useUpdateApplicationLinkMutation({
 *   variables: {
 *      name: // value for 'name'
 *      link: // value for 'link'
 *      applicationLinkId: // value for 'applicationLinkId'
 *   },
 * });
 */
export function useUpdateApplicationLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationLinkMutation,
    UpdateApplicationLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationLinkMutation,
    UpdateApplicationLinkMutationVariables
  >(UpdateApplicationLinkDocument, options);
}
export type UpdateApplicationLinkMutationHookResult = ReturnType<
  typeof useUpdateApplicationLinkMutation
>;
export type UpdateApplicationLinkMutationResult =
  Apollo.MutationResult<UpdateApplicationLinkMutation>;
export type UpdateApplicationLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationLinkMutation,
  UpdateApplicationLinkMutationVariables
>;
export const DeleteApplicationLinkDocument = gql`
  mutation deleteApplicationLink($id: String!) {
    deleteApplicationLink(id: $id)
  }
`;
export type DeleteApplicationLinkMutationFn = Apollo.MutationFunction<
  DeleteApplicationLinkMutation,
  DeleteApplicationLinkMutationVariables
>;

/**
 * __useDeleteApplicationLinkMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationLinkMutation, { data, loading, error }] = useDeleteApplicationLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApplicationLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApplicationLinkMutation,
    DeleteApplicationLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApplicationLinkMutation,
    DeleteApplicationLinkMutationVariables
  >(DeleteApplicationLinkDocument, options);
}
export type DeleteApplicationLinkMutationHookResult = ReturnType<
  typeof useDeleteApplicationLinkMutation
>;
export type DeleteApplicationLinkMutationResult =
  Apollo.MutationResult<DeleteApplicationLinkMutation>;
export type DeleteApplicationLinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteApplicationLinkMutation,
  DeleteApplicationLinkMutationVariables
>;
export const CreateApplicationMainDocument = gql`
  mutation createApplicationMain(
    $applicationMainData: CreateApplicationMainInput!
  ) {
    createApplicationMain(applicationMainData: $applicationMainData) {
      id
    }
  }
`;
export type CreateApplicationMainMutationFn = Apollo.MutationFunction<
  CreateApplicationMainMutation,
  CreateApplicationMainMutationVariables
>;

/**
 * __useCreateApplicationMainMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMainMutation, { data, loading, error }] = useCreateApplicationMainMutation({
 *   variables: {
 *      applicationMainData: // value for 'applicationMainData'
 *   },
 * });
 */
export function useCreateApplicationMainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApplicationMainMutation,
    CreateApplicationMainMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateApplicationMainMutation,
    CreateApplicationMainMutationVariables
  >(CreateApplicationMainDocument, options);
}
export type CreateApplicationMainMutationHookResult = ReturnType<
  typeof useCreateApplicationMainMutation
>;
export type CreateApplicationMainMutationResult =
  Apollo.MutationResult<CreateApplicationMainMutation>;
export type CreateApplicationMainMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicationMainMutation,
  CreateApplicationMainMutationVariables
>;
export const UpdateApplicationMainDocument = gql`
  mutation updateApplicationMain(
    $applicationMainData: UpdateApplicationMainInput!
  ) {
    updateApplicationMain(applicationMainData: $applicationMainData) {
      id
    }
  }
`;
export type UpdateApplicationMainMutationFn = Apollo.MutationFunction<
  UpdateApplicationMainMutation,
  UpdateApplicationMainMutationVariables
>;

/**
 * __useUpdateApplicationMainMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMainMutation, { data, loading, error }] = useUpdateApplicationMainMutation({
 *   variables: {
 *      applicationMainData: // value for 'applicationMainData'
 *   },
 * });
 */
export function useUpdateApplicationMainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationMainMutation,
    UpdateApplicationMainMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationMainMutation,
    UpdateApplicationMainMutationVariables
  >(UpdateApplicationMainDocument, options);
}
export type UpdateApplicationMainMutationHookResult = ReturnType<
  typeof useUpdateApplicationMainMutation
>;
export type UpdateApplicationMainMutationResult =
  Apollo.MutationResult<UpdateApplicationMainMutation>;
export type UpdateApplicationMainMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationMainMutation,
  UpdateApplicationMainMutationVariables
>;
export const UpdateApplicationMainPhotoDocument = gql`
  mutation updateApplicationMainPhoto(
    $applicationMainId: String!
    $photo: Upload
    $icon: Upload
  ) {
    updateApplicationMainPhoto(
      applicationMainId: $applicationMainId
      photo: $photo
      icon: $icon
    )
  }
`;
export type UpdateApplicationMainPhotoMutationFn = Apollo.MutationFunction<
  UpdateApplicationMainPhotoMutation,
  UpdateApplicationMainPhotoMutationVariables
>;

/**
 * __useUpdateApplicationMainPhotoMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMainPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMainPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMainPhotoMutation, { data, loading, error }] = useUpdateApplicationMainPhotoMutation({
 *   variables: {
 *      applicationMainId: // value for 'applicationMainId'
 *      photo: // value for 'photo'
 *      icon: // value for 'icon'
 *   },
 * });
 */
export function useUpdateApplicationMainPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationMainPhotoMutation,
    UpdateApplicationMainPhotoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationMainPhotoMutation,
    UpdateApplicationMainPhotoMutationVariables
  >(UpdateApplicationMainPhotoDocument, options);
}
export type UpdateApplicationMainPhotoMutationHookResult = ReturnType<
  typeof useUpdateApplicationMainPhotoMutation
>;
export type UpdateApplicationMainPhotoMutationResult =
  Apollo.MutationResult<UpdateApplicationMainPhotoMutation>;
export type UpdateApplicationMainPhotoMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationMainPhotoMutation,
    UpdateApplicationMainPhotoMutationVariables
  >;
export const DeleteApplicationMainDocument = gql`
  mutation deleteApplicationMain($id: String!) {
    deleteApplicationMain(id: $id)
  }
`;
export type DeleteApplicationMainMutationFn = Apollo.MutationFunction<
  DeleteApplicationMainMutation,
  DeleteApplicationMainMutationVariables
>;

/**
 * __useDeleteApplicationMainMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationMainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationMainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationMainMutation, { data, loading, error }] = useDeleteApplicationMainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApplicationMainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApplicationMainMutation,
    DeleteApplicationMainMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApplicationMainMutation,
    DeleteApplicationMainMutationVariables
  >(DeleteApplicationMainDocument, options);
}
export type DeleteApplicationMainMutationHookResult = ReturnType<
  typeof useDeleteApplicationMainMutation
>;
export type DeleteApplicationMainMutationResult =
  Apollo.MutationResult<DeleteApplicationMainMutation>;
export type DeleteApplicationMainMutationOptions = Apollo.BaseMutationOptions<
  DeleteApplicationMainMutation,
  DeleteApplicationMainMutationVariables
>;
export const DeleteApplicationMainIconDocument = gql`
  mutation deleteApplicationMainIcon($applicationMainId: String!) {
    deleteApplicationMainIcon(applicationMainId: $applicationMainId)
  }
`;
export type DeleteApplicationMainIconMutationFn = Apollo.MutationFunction<
  DeleteApplicationMainIconMutation,
  DeleteApplicationMainIconMutationVariables
>;

/**
 * __useDeleteApplicationMainIconMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationMainIconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationMainIconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationMainIconMutation, { data, loading, error }] = useDeleteApplicationMainIconMutation({
 *   variables: {
 *      applicationMainId: // value for 'applicationMainId'
 *   },
 * });
 */
export function useDeleteApplicationMainIconMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApplicationMainIconMutation,
    DeleteApplicationMainIconMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApplicationMainIconMutation,
    DeleteApplicationMainIconMutationVariables
  >(DeleteApplicationMainIconDocument, options);
}
export type DeleteApplicationMainIconMutationHookResult = ReturnType<
  typeof useDeleteApplicationMainIconMutation
>;
export type DeleteApplicationMainIconMutationResult =
  Apollo.MutationResult<DeleteApplicationMainIconMutation>;
export type DeleteApplicationMainIconMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteApplicationMainIconMutation,
    DeleteApplicationMainIconMutationVariables
  >;
export const PublishApplicationMainDocument = gql`
  mutation publishApplicationMain($id: String!) {
    publishApplicationMain(id: $id)
  }
`;
export type PublishApplicationMainMutationFn = Apollo.MutationFunction<
  PublishApplicationMainMutation,
  PublishApplicationMainMutationVariables
>;

/**
 * __usePublishApplicationMainMutation__
 *
 * To run a mutation, you first call `usePublishApplicationMainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishApplicationMainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishApplicationMainMutation, { data, loading, error }] = usePublishApplicationMainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishApplicationMainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishApplicationMainMutation,
    PublishApplicationMainMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishApplicationMainMutation,
    PublishApplicationMainMutationVariables
  >(PublishApplicationMainDocument, options);
}
export type PublishApplicationMainMutationHookResult = ReturnType<
  typeof usePublishApplicationMainMutation
>;
export type PublishApplicationMainMutationResult =
  Apollo.MutationResult<PublishApplicationMainMutation>;
export type PublishApplicationMainMutationOptions = Apollo.BaseMutationOptions<
  PublishApplicationMainMutation,
  PublishApplicationMainMutationVariables
>;
export const SetAccessToApplicationMainDocument = gql`
  mutation setAccessToApplicationMain(
    $applicationMainData: HandleAccessInput!
  ) {
    handleAccess(applicationMainData: $applicationMainData)
  }
`;
export type SetAccessToApplicationMainMutationFn = Apollo.MutationFunction<
  SetAccessToApplicationMainMutation,
  SetAccessToApplicationMainMutationVariables
>;

/**
 * __useSetAccessToApplicationMainMutation__
 *
 * To run a mutation, you first call `useSetAccessToApplicationMainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccessToApplicationMainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccessToApplicationMainMutation, { data, loading, error }] = useSetAccessToApplicationMainMutation({
 *   variables: {
 *      applicationMainData: // value for 'applicationMainData'
 *   },
 * });
 */
export function useSetAccessToApplicationMainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAccessToApplicationMainMutation,
    SetAccessToApplicationMainMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetAccessToApplicationMainMutation,
    SetAccessToApplicationMainMutationVariables
  >(SetAccessToApplicationMainDocument, options);
}
export type SetAccessToApplicationMainMutationHookResult = ReturnType<
  typeof useSetAccessToApplicationMainMutation
>;
export type SetAccessToApplicationMainMutationResult =
  Apollo.MutationResult<SetAccessToApplicationMainMutation>;
export type SetAccessToApplicationMainMutationOptions =
  Apollo.BaseMutationOptions<
    SetAccessToApplicationMainMutation,
    SetAccessToApplicationMainMutationVariables
  >;
export const CreateApplicationMainModelDocument = gql`
  mutation createApplicationMainModel(
    $modelData: CreateApplicationMainModelInput!
  ) {
    createApplicationModel(modelData: $modelData) {
      id
    }
  }
`;
export type CreateApplicationMainModelMutationFn = Apollo.MutationFunction<
  CreateApplicationMainModelMutation,
  CreateApplicationMainModelMutationVariables
>;

/**
 * __useCreateApplicationMainModelMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMainModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMainModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMainModelMutation, { data, loading, error }] = useCreateApplicationMainModelMutation({
 *   variables: {
 *      modelData: // value for 'modelData'
 *   },
 * });
 */
export function useCreateApplicationMainModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApplicationMainModelMutation,
    CreateApplicationMainModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateApplicationMainModelMutation,
    CreateApplicationMainModelMutationVariables
  >(CreateApplicationMainModelDocument, options);
}
export type CreateApplicationMainModelMutationHookResult = ReturnType<
  typeof useCreateApplicationMainModelMutation
>;
export type CreateApplicationMainModelMutationResult =
  Apollo.MutationResult<CreateApplicationMainModelMutation>;
export type CreateApplicationMainModelMutationOptions =
  Apollo.BaseMutationOptions<
    CreateApplicationMainModelMutation,
    CreateApplicationMainModelMutationVariables
  >;
export const UpdateApplicationMainModelDocument = gql`
  mutation updateApplicationMainModel(
    $modelData: UpdateApplicationMainModelInput!
  ) {
    updateApplicationModel(modelData: $modelData) {
      id
    }
  }
`;
export type UpdateApplicationMainModelMutationFn = Apollo.MutationFunction<
  UpdateApplicationMainModelMutation,
  UpdateApplicationMainModelMutationVariables
>;

/**
 * __useUpdateApplicationMainModelMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMainModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMainModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMainModelMutation, { data, loading, error }] = useUpdateApplicationMainModelMutation({
 *   variables: {
 *      modelData: // value for 'modelData'
 *   },
 * });
 */
export function useUpdateApplicationMainModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationMainModelMutation,
    UpdateApplicationMainModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationMainModelMutation,
    UpdateApplicationMainModelMutationVariables
  >(UpdateApplicationMainModelDocument, options);
}
export type UpdateApplicationMainModelMutationHookResult = ReturnType<
  typeof useUpdateApplicationMainModelMutation
>;
export type UpdateApplicationMainModelMutationResult =
  Apollo.MutationResult<UpdateApplicationMainModelMutation>;
export type UpdateApplicationMainModelMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationMainModelMutation,
    UpdateApplicationMainModelMutationVariables
  >;
export const DeleteApplicationMainModelDocument = gql`
  mutation deleteApplicationMainModel($id: String!) {
    deleteApplicationModel(id: $id)
  }
`;
export type DeleteApplicationMainModelMutationFn = Apollo.MutationFunction<
  DeleteApplicationMainModelMutation,
  DeleteApplicationMainModelMutationVariables
>;

/**
 * __useDeleteApplicationMainModelMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationMainModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationMainModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationMainModelMutation, { data, loading, error }] = useDeleteApplicationMainModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApplicationMainModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApplicationMainModelMutation,
    DeleteApplicationMainModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApplicationMainModelMutation,
    DeleteApplicationMainModelMutationVariables
  >(DeleteApplicationMainModelDocument, options);
}
export type DeleteApplicationMainModelMutationHookResult = ReturnType<
  typeof useDeleteApplicationMainModelMutation
>;
export type DeleteApplicationMainModelMutationResult =
  Apollo.MutationResult<DeleteApplicationMainModelMutation>;
export type DeleteApplicationMainModelMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteApplicationMainModelMutation,
    DeleteApplicationMainModelMutationVariables
  >;
export const PublishApplicationMainModelDocument = gql`
  mutation publishApplicationMainModel($id: String!) {
    publishApplicationMainModel(id: $id)
  }
`;
export type PublishApplicationMainModelMutationFn = Apollo.MutationFunction<
  PublishApplicationMainModelMutation,
  PublishApplicationMainModelMutationVariables
>;

/**
 * __usePublishApplicationMainModelMutation__
 *
 * To run a mutation, you first call `usePublishApplicationMainModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishApplicationMainModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishApplicationMainModelMutation, { data, loading, error }] = usePublishApplicationMainModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishApplicationMainModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishApplicationMainModelMutation,
    PublishApplicationMainModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishApplicationMainModelMutation,
    PublishApplicationMainModelMutationVariables
  >(PublishApplicationMainModelDocument, options);
}
export type PublishApplicationMainModelMutationHookResult = ReturnType<
  typeof usePublishApplicationMainModelMutation
>;
export type PublishApplicationMainModelMutationResult =
  Apollo.MutationResult<PublishApplicationMainModelMutation>;
export type PublishApplicationMainModelMutationOptions =
  Apollo.BaseMutationOptions<
    PublishApplicationMainModelMutation,
    PublishApplicationMainModelMutationVariables
  >;
export const CreateContentPreviewDocument = gql`
  mutation createContentPreview(
    $contentLink: ContentLinkInput!
    $file: Upload
    $videoUrl: String
  ) {
    createContentPreview(
      contentLink: $contentLink
      file: $file
      videoUrl: $videoUrl
    ) {
      id
    }
  }
`;
export type CreateContentPreviewMutationFn = Apollo.MutationFunction<
  CreateContentPreviewMutation,
  CreateContentPreviewMutationVariables
>;

/**
 * __useCreateContentPreviewMutation__
 *
 * To run a mutation, you first call `useCreateContentPreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentPreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentPreviewMutation, { data, loading, error }] = useCreateContentPreviewMutation({
 *   variables: {
 *      contentLink: // value for 'contentLink'
 *      file: // value for 'file'
 *      videoUrl: // value for 'videoUrl'
 *   },
 * });
 */
export function useCreateContentPreviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContentPreviewMutation,
    CreateContentPreviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContentPreviewMutation,
    CreateContentPreviewMutationVariables
  >(CreateContentPreviewDocument, options);
}
export type CreateContentPreviewMutationHookResult = ReturnType<
  typeof useCreateContentPreviewMutation
>;
export type CreateContentPreviewMutationResult =
  Apollo.MutationResult<CreateContentPreviewMutation>;
export type CreateContentPreviewMutationOptions = Apollo.BaseMutationOptions<
  CreateContentPreviewMutation,
  CreateContentPreviewMutationVariables
>;
export const UpdateContentPreviewOrderDocument = gql`
  mutation updateContentPreviewOrder(
    $updateContentPreviewOrderId: String!
    $order: Int!
  ) {
    updateContentPreviewOrder(id: $updateContentPreviewOrderId, order: $order)
  }
`;
export type UpdateContentPreviewOrderMutationFn = Apollo.MutationFunction<
  UpdateContentPreviewOrderMutation,
  UpdateContentPreviewOrderMutationVariables
>;

/**
 * __useUpdateContentPreviewOrderMutation__
 *
 * To run a mutation, you first call `useUpdateContentPreviewOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentPreviewOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentPreviewOrderMutation, { data, loading, error }] = useUpdateContentPreviewOrderMutation({
 *   variables: {
 *      updateContentPreviewOrderId: // value for 'updateContentPreviewOrderId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateContentPreviewOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContentPreviewOrderMutation,
    UpdateContentPreviewOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContentPreviewOrderMutation,
    UpdateContentPreviewOrderMutationVariables
  >(UpdateContentPreviewOrderDocument, options);
}
export type UpdateContentPreviewOrderMutationHookResult = ReturnType<
  typeof useUpdateContentPreviewOrderMutation
>;
export type UpdateContentPreviewOrderMutationResult =
  Apollo.MutationResult<UpdateContentPreviewOrderMutation>;
export type UpdateContentPreviewOrderMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateContentPreviewOrderMutation,
    UpdateContentPreviewOrderMutationVariables
  >;
export const DeleteContentPreviewDocument = gql`
  mutation deleteContentPreview($deleteContentPreviewId: String!) {
    deleteContentPreview(id: $deleteContentPreviewId)
  }
`;
export type DeleteContentPreviewMutationFn = Apollo.MutationFunction<
  DeleteContentPreviewMutation,
  DeleteContentPreviewMutationVariables
>;

/**
 * __useDeleteContentPreviewMutation__
 *
 * To run a mutation, you first call `useDeleteContentPreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentPreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentPreviewMutation, { data, loading, error }] = useDeleteContentPreviewMutation({
 *   variables: {
 *      deleteContentPreviewId: // value for 'deleteContentPreviewId'
 *   },
 * });
 */
export function useDeleteContentPreviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteContentPreviewMutation,
    DeleteContentPreviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteContentPreviewMutation,
    DeleteContentPreviewMutationVariables
  >(DeleteContentPreviewDocument, options);
}
export type DeleteContentPreviewMutationHookResult = ReturnType<
  typeof useDeleteContentPreviewMutation
>;
export type DeleteContentPreviewMutationResult =
  Apollo.MutationResult<DeleteContentPreviewMutation>;
export type DeleteContentPreviewMutationOptions = Apollo.BaseMutationOptions<
  DeleteContentPreviewMutation,
  DeleteContentPreviewMutationVariables
>;
export const SetApplicationMainModelExperimentTypeDocument = gql`
  mutation setApplicationMainModelExperimentType(
    $setApplicationMainModelExperimentTypeId: String!
    $contentType: ContentTypeEnum
  ) {
    setApplicationMainModelExperimentType(
      id: $setApplicationMainModelExperimentTypeId
      contentType: $contentType
    )
  }
`;
export type SetApplicationMainModelExperimentTypeMutationFn =
  Apollo.MutationFunction<
    SetApplicationMainModelExperimentTypeMutation,
    SetApplicationMainModelExperimentTypeMutationVariables
  >;

/**
 * __useSetApplicationMainModelExperimentTypeMutation__
 *
 * To run a mutation, you first call `useSetApplicationMainModelExperimentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetApplicationMainModelExperimentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setApplicationMainModelExperimentTypeMutation, { data, loading, error }] = useSetApplicationMainModelExperimentTypeMutation({
 *   variables: {
 *      setApplicationMainModelExperimentTypeId: // value for 'setApplicationMainModelExperimentTypeId'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useSetApplicationMainModelExperimentTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetApplicationMainModelExperimentTypeMutation,
    SetApplicationMainModelExperimentTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetApplicationMainModelExperimentTypeMutation,
    SetApplicationMainModelExperimentTypeMutationVariables
  >(SetApplicationMainModelExperimentTypeDocument, options);
}
export type SetApplicationMainModelExperimentTypeMutationHookResult =
  ReturnType<typeof useSetApplicationMainModelExperimentTypeMutation>;
export type SetApplicationMainModelExperimentTypeMutationResult =
  Apollo.MutationResult<SetApplicationMainModelExperimentTypeMutation>;
export type SetApplicationMainModelExperimentTypeMutationOptions =
  Apollo.BaseMutationOptions<
    SetApplicationMainModelExperimentTypeMutation,
    SetApplicationMainModelExperimentTypeMutationVariables
  >;
export const CreateApplicationMainThemeDocument = gql`
  mutation createApplicationMainTheme(
    $applicationMainId: String!
    $nameEN: String
    $nameUKR: String
  ) {
    createApplicationMainTheme(
      applicationMainId: $applicationMainId
      nameEN: $nameEN
      nameUKR: $nameUKR
    ) {
      id
    }
  }
`;
export type CreateApplicationMainThemeMutationFn = Apollo.MutationFunction<
  CreateApplicationMainThemeMutation,
  CreateApplicationMainThemeMutationVariables
>;

/**
 * __useCreateApplicationMainThemeMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMainThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMainThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMainThemeMutation, { data, loading, error }] = useCreateApplicationMainThemeMutation({
 *   variables: {
 *      applicationMainId: // value for 'applicationMainId'
 *      nameEN: // value for 'nameEN'
 *      nameUKR: // value for 'nameUKR'
 *   },
 * });
 */
export function useCreateApplicationMainThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApplicationMainThemeMutation,
    CreateApplicationMainThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateApplicationMainThemeMutation,
    CreateApplicationMainThemeMutationVariables
  >(CreateApplicationMainThemeDocument, options);
}
export type CreateApplicationMainThemeMutationHookResult = ReturnType<
  typeof useCreateApplicationMainThemeMutation
>;
export type CreateApplicationMainThemeMutationResult =
  Apollo.MutationResult<CreateApplicationMainThemeMutation>;
export type CreateApplicationMainThemeMutationOptions =
  Apollo.BaseMutationOptions<
    CreateApplicationMainThemeMutation,
    CreateApplicationMainThemeMutationVariables
  >;
export const UpdateApplicationMainThemeDocument = gql`
  mutation updateApplicationMainTheme(
    $id: String!
    $nameEN: String
    $nameUKR: String
    $backgroundId: String
    $analyticsMark: String
    $subscriptionPlan: SubscriptionPlanStatusEnum
  ) {
    updateApplicationMainTheme(
      id: $id
      nameEN: $nameEN
      nameUKR: $nameUKR
      backgroundId: $backgroundId
      analyticsMark: $analyticsMark
      subscriptionPlan: $subscriptionPlan
    ) {
      id
    }
  }
`;
export type UpdateApplicationMainThemeMutationFn = Apollo.MutationFunction<
  UpdateApplicationMainThemeMutation,
  UpdateApplicationMainThemeMutationVariables
>;

/**
 * __useUpdateApplicationMainThemeMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMainThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMainThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMainThemeMutation, { data, loading, error }] = useUpdateApplicationMainThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      nameEN: // value for 'nameEN'
 *      nameUKR: // value for 'nameUKR'
 *      backgroundId: // value for 'backgroundId'
 *      analyticsMark: // value for 'analyticsMark'
 *      subscriptionPlan: // value for 'subscriptionPlan'
 *   },
 * });
 */
export function useUpdateApplicationMainThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationMainThemeMutation,
    UpdateApplicationMainThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationMainThemeMutation,
    UpdateApplicationMainThemeMutationVariables
  >(UpdateApplicationMainThemeDocument, options);
}
export type UpdateApplicationMainThemeMutationHookResult = ReturnType<
  typeof useUpdateApplicationMainThemeMutation
>;
export type UpdateApplicationMainThemeMutationResult =
  Apollo.MutationResult<UpdateApplicationMainThemeMutation>;
export type UpdateApplicationMainThemeMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationMainThemeMutation,
    UpdateApplicationMainThemeMutationVariables
  >;
export const DeleteApplicationMainThemeDocument = gql`
  mutation deleteApplicationMainTheme($id: String!) {
    deleteApplicationMainTheme(id: $id)
  }
`;
export type DeleteApplicationMainThemeMutationFn = Apollo.MutationFunction<
  DeleteApplicationMainThemeMutation,
  DeleteApplicationMainThemeMutationVariables
>;

/**
 * __useDeleteApplicationMainThemeMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationMainThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationMainThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationMainThemeMutation, { data, loading, error }] = useDeleteApplicationMainThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApplicationMainThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApplicationMainThemeMutation,
    DeleteApplicationMainThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApplicationMainThemeMutation,
    DeleteApplicationMainThemeMutationVariables
  >(DeleteApplicationMainThemeDocument, options);
}
export type DeleteApplicationMainThemeMutationHookResult = ReturnType<
  typeof useDeleteApplicationMainThemeMutation
>;
export type DeleteApplicationMainThemeMutationResult =
  Apollo.MutationResult<DeleteApplicationMainThemeMutation>;
export type DeleteApplicationMainThemeMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteApplicationMainThemeMutation,
    DeleteApplicationMainThemeMutationVariables
  >;
export const AddApplicationMainThemePhotoDocument = gql`
  mutation addApplicationMainThemePhoto($id: String!, $photo: Upload!) {
    addApplicationMainThemePhoto(id: $id, photo: $photo)
  }
`;
export type AddApplicationMainThemePhotoMutationFn = Apollo.MutationFunction<
  AddApplicationMainThemePhotoMutation,
  AddApplicationMainThemePhotoMutationVariables
>;

/**
 * __useAddApplicationMainThemePhotoMutation__
 *
 * To run a mutation, you first call `useAddApplicationMainThemePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApplicationMainThemePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApplicationMainThemePhotoMutation, { data, loading, error }] = useAddApplicationMainThemePhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useAddApplicationMainThemePhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddApplicationMainThemePhotoMutation,
    AddApplicationMainThemePhotoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddApplicationMainThemePhotoMutation,
    AddApplicationMainThemePhotoMutationVariables
  >(AddApplicationMainThemePhotoDocument, options);
}
export type AddApplicationMainThemePhotoMutationHookResult = ReturnType<
  typeof useAddApplicationMainThemePhotoMutation
>;
export type AddApplicationMainThemePhotoMutationResult =
  Apollo.MutationResult<AddApplicationMainThemePhotoMutation>;
export type AddApplicationMainThemePhotoMutationOptions =
  Apollo.BaseMutationOptions<
    AddApplicationMainThemePhotoMutation,
    AddApplicationMainThemePhotoMutationVariables
  >;
export const DeleteApplicationMainThemePhotoDocument = gql`
  mutation deleteApplicationMainThemePhoto($id: String!) {
    deleteApplicationMainThemePhoto(id: $id)
  }
`;
export type DeleteApplicationMainThemePhotoMutationFn = Apollo.MutationFunction<
  DeleteApplicationMainThemePhotoMutation,
  DeleteApplicationMainThemePhotoMutationVariables
>;

/**
 * __useDeleteApplicationMainThemePhotoMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationMainThemePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationMainThemePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationMainThemePhotoMutation, { data, loading, error }] = useDeleteApplicationMainThemePhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApplicationMainThemePhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApplicationMainThemePhotoMutation,
    DeleteApplicationMainThemePhotoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApplicationMainThemePhotoMutation,
    DeleteApplicationMainThemePhotoMutationVariables
  >(DeleteApplicationMainThemePhotoDocument, options);
}
export type DeleteApplicationMainThemePhotoMutationHookResult = ReturnType<
  typeof useDeleteApplicationMainThemePhotoMutation
>;
export type DeleteApplicationMainThemePhotoMutationResult =
  Apollo.MutationResult<DeleteApplicationMainThemePhotoMutation>;
export type DeleteApplicationMainThemePhotoMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteApplicationMainThemePhotoMutation,
    DeleteApplicationMainThemePhotoMutationVariables
  >;
export const UpdateApplicationModelPhotoDocument = gql`
  mutation updateApplicationModelPhoto($modelId: String!, $file: Upload!) {
    updateApplicationModelPhoto(modelId: $modelId, file: $file)
  }
`;
export type UpdateApplicationModelPhotoMutationFn = Apollo.MutationFunction<
  UpdateApplicationModelPhotoMutation,
  UpdateApplicationModelPhotoMutationVariables
>;

/**
 * __useUpdateApplicationModelPhotoMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationModelPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationModelPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationModelPhotoMutation, { data, loading, error }] = useUpdateApplicationModelPhotoMutation({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateApplicationModelPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationModelPhotoMutation,
    UpdateApplicationModelPhotoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationModelPhotoMutation,
    UpdateApplicationModelPhotoMutationVariables
  >(UpdateApplicationModelPhotoDocument, options);
}
export type UpdateApplicationModelPhotoMutationHookResult = ReturnType<
  typeof useUpdateApplicationModelPhotoMutation
>;
export type UpdateApplicationModelPhotoMutationResult =
  Apollo.MutationResult<UpdateApplicationModelPhotoMutation>;
export type UpdateApplicationModelPhotoMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationModelPhotoMutation,
    UpdateApplicationModelPhotoMutationVariables
  >;
export const UpdateApplicationModelMarkerPhotoDocument = gql`
  mutation updateApplicationModelMarkerPhoto(
    $modelId: String!
    $file: Upload
    $isMarker: Boolean!
  ) {
    updateApplicationModelMarkerPhoto(
      modelId: $modelId
      file: $file
      isMarker: $isMarker
    )
  }
`;
export type UpdateApplicationModelMarkerPhotoMutationFn =
  Apollo.MutationFunction<
    UpdateApplicationModelMarkerPhotoMutation,
    UpdateApplicationModelMarkerPhotoMutationVariables
  >;

/**
 * __useUpdateApplicationModelMarkerPhotoMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationModelMarkerPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationModelMarkerPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationModelMarkerPhotoMutation, { data, loading, error }] = useUpdateApplicationModelMarkerPhotoMutation({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      file: // value for 'file'
 *      isMarker: // value for 'isMarker'
 *   },
 * });
 */
export function useUpdateApplicationModelMarkerPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationModelMarkerPhotoMutation,
    UpdateApplicationModelMarkerPhotoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationModelMarkerPhotoMutation,
    UpdateApplicationModelMarkerPhotoMutationVariables
  >(UpdateApplicationModelMarkerPhotoDocument, options);
}
export type UpdateApplicationModelMarkerPhotoMutationHookResult = ReturnType<
  typeof useUpdateApplicationModelMarkerPhotoMutation
>;
export type UpdateApplicationModelMarkerPhotoMutationResult =
  Apollo.MutationResult<UpdateApplicationModelMarkerPhotoMutation>;
export type UpdateApplicationModelMarkerPhotoMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationModelMarkerPhotoMutation,
    UpdateApplicationModelMarkerPhotoMutationVariables
  >;
export const UpdateApplicationModelMapMarkerPhotoDocument = gql`
  mutation updateApplicationModelMapMarkerPhoto(
    $modelId: String!
    $file: Upload!
  ) {
    updateApplicationModelMapMarkerPhoto(modelId: $modelId, file: $file)
  }
`;
export type UpdateApplicationModelMapMarkerPhotoMutationFn =
  Apollo.MutationFunction<
    UpdateApplicationModelMapMarkerPhotoMutation,
    UpdateApplicationModelMapMarkerPhotoMutationVariables
  >;

/**
 * __useUpdateApplicationModelMapMarkerPhotoMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationModelMapMarkerPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationModelMapMarkerPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationModelMapMarkerPhotoMutation, { data, loading, error }] = useUpdateApplicationModelMapMarkerPhotoMutation({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateApplicationModelMapMarkerPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationModelMapMarkerPhotoMutation,
    UpdateApplicationModelMapMarkerPhotoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationModelMapMarkerPhotoMutation,
    UpdateApplicationModelMapMarkerPhotoMutationVariables
  >(UpdateApplicationModelMapMarkerPhotoDocument, options);
}
export type UpdateApplicationModelMapMarkerPhotoMutationHookResult = ReturnType<
  typeof useUpdateApplicationModelMapMarkerPhotoMutation
>;
export type UpdateApplicationModelMapMarkerPhotoMutationResult =
  Apollo.MutationResult<UpdateApplicationModelMapMarkerPhotoMutation>;
export type UpdateApplicationModelMapMarkerPhotoMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationModelMapMarkerPhotoMutation,
    UpdateApplicationModelMapMarkerPhotoMutationVariables
  >;
export const CreateApplicationMainModelFileDocument = gql`
  mutation createApplicationMainModelFile(
    $fileData: CreateApplicationMainModelFileInput!
  ) {
    createApplicationMainModelFile(fileData: $fileData) {
      id
    }
  }
`;
export type CreateApplicationMainModelFileMutationFn = Apollo.MutationFunction<
  CreateApplicationMainModelFileMutation,
  CreateApplicationMainModelFileMutationVariables
>;

/**
 * __useCreateApplicationMainModelFileMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMainModelFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMainModelFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMainModelFileMutation, { data, loading, error }] = useCreateApplicationMainModelFileMutation({
 *   variables: {
 *      fileData: // value for 'fileData'
 *   },
 * });
 */
export function useCreateApplicationMainModelFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApplicationMainModelFileMutation,
    CreateApplicationMainModelFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateApplicationMainModelFileMutation,
    CreateApplicationMainModelFileMutationVariables
  >(CreateApplicationMainModelFileDocument, options);
}
export type CreateApplicationMainModelFileMutationHookResult = ReturnType<
  typeof useCreateApplicationMainModelFileMutation
>;
export type CreateApplicationMainModelFileMutationResult =
  Apollo.MutationResult<CreateApplicationMainModelFileMutation>;
export type CreateApplicationMainModelFileMutationOptions =
  Apollo.BaseMutationOptions<
    CreateApplicationMainModelFileMutation,
    CreateApplicationMainModelFileMutationVariables
  >;
export const UpdateApplicationModelBundleDocument = gql`
  mutation updateApplicationModelBundle(
    $modelId: String!
    $bundleType: ApplicationBundleTypeEnum!
    $bundleFileType: BundleFileTypeEnum
    $file: Upload
    $bundleLink: String
  ) {
    updateApplicationModelBundle(
      modelId: $modelId
      bundleType: $bundleType
      bundleFileType: $bundleFileType
      file: $file
      bundleLink: $bundleLink
    )
  }
`;
export type UpdateApplicationModelBundleMutationFn = Apollo.MutationFunction<
  UpdateApplicationModelBundleMutation,
  UpdateApplicationModelBundleMutationVariables
>;

/**
 * __useUpdateApplicationModelBundleMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationModelBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationModelBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationModelBundleMutation, { data, loading, error }] = useUpdateApplicationModelBundleMutation({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      bundleType: // value for 'bundleType'
 *      bundleFileType: // value for 'bundleFileType'
 *      file: // value for 'file'
 *      bundleLink: // value for 'bundleLink'
 *   },
 * });
 */
export function useUpdateApplicationModelBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationModelBundleMutation,
    UpdateApplicationModelBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationModelBundleMutation,
    UpdateApplicationModelBundleMutationVariables
  >(UpdateApplicationModelBundleDocument, options);
}
export type UpdateApplicationModelBundleMutationHookResult = ReturnType<
  typeof useUpdateApplicationModelBundleMutation
>;
export type UpdateApplicationModelBundleMutationResult =
  Apollo.MutationResult<UpdateApplicationModelBundleMutation>;
export type UpdateApplicationModelBundleMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationModelBundleMutation,
    UpdateApplicationModelBundleMutationVariables
  >;
export const DeleteApplicationMainModelFilesDocument = gql`
  mutation deleteApplicationMainModelFiles(
    $modelId: String!
    $modelFileType: ModelFileTypeEnum!
  ) {
    deleteApplicationModelFiles(
      modelId: $modelId
      modelFileType: $modelFileType
    )
  }
`;
export type DeleteApplicationMainModelFilesMutationFn = Apollo.MutationFunction<
  DeleteApplicationMainModelFilesMutation,
  DeleteApplicationMainModelFilesMutationVariables
>;

/**
 * __useDeleteApplicationMainModelFilesMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationMainModelFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationMainModelFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationMainModelFilesMutation, { data, loading, error }] = useDeleteApplicationMainModelFilesMutation({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      modelFileType: // value for 'modelFileType'
 *   },
 * });
 */
export function useDeleteApplicationMainModelFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApplicationMainModelFilesMutation,
    DeleteApplicationMainModelFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApplicationMainModelFilesMutation,
    DeleteApplicationMainModelFilesMutationVariables
  >(DeleteApplicationMainModelFilesDocument, options);
}
export type DeleteApplicationMainModelFilesMutationHookResult = ReturnType<
  typeof useDeleteApplicationMainModelFilesMutation
>;
export type DeleteApplicationMainModelFilesMutationResult =
  Apollo.MutationResult<DeleteApplicationMainModelFilesMutation>;
export type DeleteApplicationMainModelFilesMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteApplicationMainModelFilesMutation,
    DeleteApplicationMainModelFilesMutationVariables
  >;
export const DeleteApplicationMainModelFileDocument = gql`
  mutation deleteApplicationMainModelFile($fileId: String!) {
    deleteApplicationMainModelFile(fileId: $fileId)
  }
`;
export type DeleteApplicationMainModelFileMutationFn = Apollo.MutationFunction<
  DeleteApplicationMainModelFileMutation,
  DeleteApplicationMainModelFileMutationVariables
>;

/**
 * __useDeleteApplicationMainModelFileMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationMainModelFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationMainModelFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationMainModelFileMutation, { data, loading, error }] = useDeleteApplicationMainModelFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteApplicationMainModelFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApplicationMainModelFileMutation,
    DeleteApplicationMainModelFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApplicationMainModelFileMutation,
    DeleteApplicationMainModelFileMutationVariables
  >(DeleteApplicationMainModelFileDocument, options);
}
export type DeleteApplicationMainModelFileMutationHookResult = ReturnType<
  typeof useDeleteApplicationMainModelFileMutation
>;
export type DeleteApplicationMainModelFileMutationResult =
  Apollo.MutationResult<DeleteApplicationMainModelFileMutation>;
export type DeleteApplicationMainModelFileMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteApplicationMainModelFileMutation,
    DeleteApplicationMainModelFileMutationVariables
  >;
export const UpdateApplicationMainModelFileDocument = gql`
  mutation updateApplicationMainModelFile(
    $fileData: UpdateApplicationMainModelFileInput!
  ) {
    updateApplicationMainModelFile(fileData: $fileData) {
      id
    }
  }
`;
export type UpdateApplicationMainModelFileMutationFn = Apollo.MutationFunction<
  UpdateApplicationMainModelFileMutation,
  UpdateApplicationMainModelFileMutationVariables
>;

/**
 * __useUpdateApplicationMainModelFileMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMainModelFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMainModelFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMainModelFileMutation, { data, loading, error }] = useUpdateApplicationMainModelFileMutation({
 *   variables: {
 *      fileData: // value for 'fileData'
 *   },
 * });
 */
export function useUpdateApplicationMainModelFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationMainModelFileMutation,
    UpdateApplicationMainModelFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationMainModelFileMutation,
    UpdateApplicationMainModelFileMutationVariables
  >(UpdateApplicationMainModelFileDocument, options);
}
export type UpdateApplicationMainModelFileMutationHookResult = ReturnType<
  typeof useUpdateApplicationMainModelFileMutation
>;
export type UpdateApplicationMainModelFileMutationResult =
  Apollo.MutationResult<UpdateApplicationMainModelFileMutation>;
export type UpdateApplicationMainModelFileMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationMainModelFileMutation,
    UpdateApplicationMainModelFileMutationVariables
  >;
export const UpdateApplicationModelColoringPageFileDocument = gql`
  mutation UpdateApplicationModelColoringPageFile(
    $modelId: String!
    $coloringPageFile: Upload!
  ) {
    updateApplicationModelColoringPageFile(
      modelId: $modelId
      coloringPageFile: $coloringPageFile
    )
  }
`;
export type UpdateApplicationModelColoringPageFileMutationFn =
  Apollo.MutationFunction<
    UpdateApplicationModelColoringPageFileMutation,
    UpdateApplicationModelColoringPageFileMutationVariables
  >;

/**
 * __useUpdateApplicationModelColoringPageFileMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationModelColoringPageFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationModelColoringPageFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationModelColoringPageFileMutation, { data, loading, error }] = useUpdateApplicationModelColoringPageFileMutation({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      coloringPageFile: // value for 'coloringPageFile'
 *   },
 * });
 */
export function useUpdateApplicationModelColoringPageFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationModelColoringPageFileMutation,
    UpdateApplicationModelColoringPageFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationModelColoringPageFileMutation,
    UpdateApplicationModelColoringPageFileMutationVariables
  >(UpdateApplicationModelColoringPageFileDocument, options);
}
export type UpdateApplicationModelColoringPageFileMutationHookResult =
  ReturnType<typeof useUpdateApplicationModelColoringPageFileMutation>;
export type UpdateApplicationModelColoringPageFileMutationResult =
  Apollo.MutationResult<UpdateApplicationModelColoringPageFileMutation>;
export type UpdateApplicationModelColoringPageFileMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationModelColoringPageFileMutation,
    UpdateApplicationModelColoringPageFileMutationVariables
  >;
export const CreateApplicationVersionDocument = gql`
  mutation createApplicationVersion(
    $appId: String!
    $os: OS!
    $version: String!
  ) {
    createApplicationVersion(appId: $appId, os: $os, version: $version) {
      appId
      os
      createdDate
    }
  }
`;
export type CreateApplicationVersionMutationFn = Apollo.MutationFunction<
  CreateApplicationVersionMutation,
  CreateApplicationVersionMutationVariables
>;

/**
 * __useCreateApplicationVersionMutation__
 *
 * To run a mutation, you first call `useCreateApplicationVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationVersionMutation, { data, loading, error }] = useCreateApplicationVersionMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *      os: // value for 'os'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useCreateApplicationVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApplicationVersionMutation,
    CreateApplicationVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateApplicationVersionMutation,
    CreateApplicationVersionMutationVariables
  >(CreateApplicationVersionDocument, options);
}
export type CreateApplicationVersionMutationHookResult = ReturnType<
  typeof useCreateApplicationVersionMutation
>;
export type CreateApplicationVersionMutationResult =
  Apollo.MutationResult<CreateApplicationVersionMutation>;
export type CreateApplicationVersionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateApplicationVersionMutation,
    CreateApplicationVersionMutationVariables
  >;
export const CreateApplicationDocument = gql`
  mutation createApplication($applicationData: CreateApplicationInput!) {
    createApplication(applicationData: $applicationData) {
      id
    }
  }
`;
export type CreateApplicationMutationFn = Apollo.MutationFunction<
  CreateApplicationMutation,
  CreateApplicationMutationVariables
>;

/**
 * __useCreateApplicationMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMutation, { data, loading, error }] = useCreateApplicationMutation({
 *   variables: {
 *      applicationData: // value for 'applicationData'
 *   },
 * });
 */
export function useCreateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApplicationMutation,
    CreateApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateApplicationMutation,
    CreateApplicationMutationVariables
  >(CreateApplicationDocument, options);
}
export type CreateApplicationMutationHookResult = ReturnType<
  typeof useCreateApplicationMutation
>;
export type CreateApplicationMutationResult =
  Apollo.MutationResult<CreateApplicationMutation>;
export type CreateApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicationMutation,
  CreateApplicationMutationVariables
>;
export const UpdateApplicationDocument = gql`
  mutation updateApplication($applicationData: UpdateApplicationInput!) {
    updateApplication(applicationData: $applicationData) {
      id
    }
  }
`;
export type UpdateApplicationMutationFn = Apollo.MutationFunction<
  UpdateApplicationMutation,
  UpdateApplicationMutationVariables
>;

/**
 * __useUpdateApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMutation, { data, loading, error }] = useUpdateApplicationMutation({
 *   variables: {
 *      applicationData: // value for 'applicationData'
 *   },
 * });
 */
export function useUpdateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationMutation,
    UpdateApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationMutation,
    UpdateApplicationMutationVariables
  >(UpdateApplicationDocument, options);
}
export type UpdateApplicationMutationHookResult = ReturnType<
  typeof useUpdateApplicationMutation
>;
export type UpdateApplicationMutationResult =
  Apollo.MutationResult<UpdateApplicationMutation>;
export type UpdateApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationMutation,
  UpdateApplicationMutationVariables
>;
export const DeleteApplicationDocument = gql`
  mutation deleteApplication($id: String!) {
    deleteApplication(id: $id)
  }
`;
export type DeleteApplicationMutationFn = Apollo.MutationFunction<
  DeleteApplicationMutation,
  DeleteApplicationMutationVariables
>;

/**
 * __useDeleteApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationMutation, { data, loading, error }] = useDeleteApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApplicationMutation,
    DeleteApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApplicationMutation,
    DeleteApplicationMutationVariables
  >(DeleteApplicationDocument, options);
}
export type DeleteApplicationMutationHookResult = ReturnType<
  typeof useDeleteApplicationMutation
>;
export type DeleteApplicationMutationResult =
  Apollo.MutationResult<DeleteApplicationMutation>;
export type DeleteApplicationMutationOptions = Apollo.BaseMutationOptions<
  DeleteApplicationMutation,
  DeleteApplicationMutationVariables
>;
export const RemoveUsersFromGroupDocument = gql`
  mutation removeUsersFromGroup($userIds: [String]!, $userGroupId: String!) {
    removeUsersFromGroup(userIds: $userIds, userGroupId: $userGroupId)
  }
`;
export type RemoveUsersFromGroupMutationFn = Apollo.MutationFunction<
  RemoveUsersFromGroupMutation,
  RemoveUsersFromGroupMutationVariables
>;

/**
 * __useRemoveUsersFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveUsersFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUsersFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUsersFromGroupMutation, { data, loading, error }] = useRemoveUsersFromGroupMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useRemoveUsersFromGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUsersFromGroupMutation,
    RemoveUsersFromGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveUsersFromGroupMutation,
    RemoveUsersFromGroupMutationVariables
  >(RemoveUsersFromGroupDocument, options);
}
export type RemoveUsersFromGroupMutationHookResult = ReturnType<
  typeof useRemoveUsersFromGroupMutation
>;
export type RemoveUsersFromGroupMutationResult =
  Apollo.MutationResult<RemoveUsersFromGroupMutation>;
export type RemoveUsersFromGroupMutationOptions = Apollo.BaseMutationOptions<
  RemoveUsersFromGroupMutation,
  RemoveUsersFromGroupMutationVariables
>;
export const AssignUsersToGroupDocument = gql`
  mutation assignUsersToGroup($userIds: [String]!, $userGroupId: String!) {
    assignUsersToGroup(userIds: $userIds, userGroupId: $userGroupId)
  }
`;
export type AssignUsersToGroupMutationFn = Apollo.MutationFunction<
  AssignUsersToGroupMutation,
  AssignUsersToGroupMutationVariables
>;

/**
 * __useAssignUsersToGroupMutation__
 *
 * To run a mutation, you first call `useAssignUsersToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUsersToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUsersToGroupMutation, { data, loading, error }] = useAssignUsersToGroupMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useAssignUsersToGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignUsersToGroupMutation,
    AssignUsersToGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignUsersToGroupMutation,
    AssignUsersToGroupMutationVariables
  >(AssignUsersToGroupDocument, options);
}
export type AssignUsersToGroupMutationHookResult = ReturnType<
  typeof useAssignUsersToGroupMutation
>;
export type AssignUsersToGroupMutationResult =
  Apollo.MutationResult<AssignUsersToGroupMutation>;
export type AssignUsersToGroupMutationOptions = Apollo.BaseMutationOptions<
  AssignUsersToGroupMutation,
  AssignUsersToGroupMutationVariables
>;
export const AssignUsersToProjectDocument = gql`
  mutation assignUsersToProject($userIds: [String]!, $projectId: String!) {
    assignUsersToProject(userIds: $userIds, projectId: $projectId)
  }
`;
export type AssignUsersToProjectMutationFn = Apollo.MutationFunction<
  AssignUsersToProjectMutation,
  AssignUsersToProjectMutationVariables
>;

/**
 * __useAssignUsersToProjectMutation__
 *
 * To run a mutation, you first call `useAssignUsersToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUsersToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUsersToProjectMutation, { data, loading, error }] = useAssignUsersToProjectMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAssignUsersToProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignUsersToProjectMutation,
    AssignUsersToProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignUsersToProjectMutation,
    AssignUsersToProjectMutationVariables
  >(AssignUsersToProjectDocument, options);
}
export type AssignUsersToProjectMutationHookResult = ReturnType<
  typeof useAssignUsersToProjectMutation
>;
export type AssignUsersToProjectMutationResult =
  Apollo.MutationResult<AssignUsersToProjectMutation>;
export type AssignUsersToProjectMutationOptions = Apollo.BaseMutationOptions<
  AssignUsersToProjectMutation,
  AssignUsersToProjectMutationVariables
>;
export const RemoveUsersFromProjectDocument = gql`
  mutation removeUsersFromProject($userIds: [String]!, $projectId: String!) {
    removeUsersFromProject(userIds: $userIds, projectId: $projectId)
  }
`;
export type RemoveUsersFromProjectMutationFn = Apollo.MutationFunction<
  RemoveUsersFromProjectMutation,
  RemoveUsersFromProjectMutationVariables
>;

/**
 * __useRemoveUsersFromProjectMutation__
 *
 * To run a mutation, you first call `useRemoveUsersFromProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUsersFromProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUsersFromProjectMutation, { data, loading, error }] = useRemoveUsersFromProjectMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRemoveUsersFromProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUsersFromProjectMutation,
    RemoveUsersFromProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveUsersFromProjectMutation,
    RemoveUsersFromProjectMutationVariables
  >(RemoveUsersFromProjectDocument, options);
}
export type RemoveUsersFromProjectMutationHookResult = ReturnType<
  typeof useRemoveUsersFromProjectMutation
>;
export type RemoveUsersFromProjectMutationResult =
  Apollo.MutationResult<RemoveUsersFromProjectMutation>;
export type RemoveUsersFromProjectMutationOptions = Apollo.BaseMutationOptions<
  RemoveUsersFromProjectMutation,
  RemoveUsersFromProjectMutationVariables
>;
export const LoginDocument = gql`
  mutation login($email: String!, $password: String!, $isFrom: isFromEnum!) {
    login(email: $email, password: $password, isFrom: $isFrom) {
      token
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      isFrom: // value for 'isFrom'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LogoutDocument = gql`
  mutation logout {
    logout
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const ForgotPasswordDocument = gql`
  mutation forgotPassword($email: String!, $isFrom: isFromEnum!) {
    forgotPassword(email: $email, isFrom: $isFrom)
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      isFrom: // value for 'isFrom'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationHookResult = ReturnType<
  typeof useForgotPasswordMutation
>;
export type ForgotPasswordMutationResult =
  Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword(
    $password: String!
    $token: String!
    $isFrom: isFromEnum!
  ) {
    resetPassword(password: $password, token: $token, isFrom: $isFrom) {
      token
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *      isFrom: // value for 'isFrom'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ConfirmUserDocument = gql`
  mutation confirmUser($token: String!) {
    confirmUser(token: $token)
  }
`;
export type ConfirmUserMutationFn = Apollo.MutationFunction<
  ConfirmUserMutation,
  ConfirmUserMutationVariables
>;

/**
 * __useConfirmUserMutation__
 *
 * To run a mutation, you first call `useConfirmUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserMutation, { data, loading, error }] = useConfirmUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmUserMutation,
    ConfirmUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmUserMutation, ConfirmUserMutationVariables>(
    ConfirmUserDocument,
    options
  );
}
export type ConfirmUserMutationHookResult = ReturnType<
  typeof useConfirmUserMutation
>;
export type ConfirmUserMutationResult =
  Apollo.MutationResult<ConfirmUserMutation>;
export type ConfirmUserMutationOptions = Apollo.BaseMutationOptions<
  ConfirmUserMutation,
  ConfirmUserMutationVariables
>;
export const CreatePayrollBonusDocument = gql`
  mutation createPayrollBonus(
    $payrollId: String!
    $name: String!
    $value: Int!
  ) {
    createPayrollBonus(payrollId: $payrollId, name: $name, value: $value) {
      id
      name
      value
    }
  }
`;
export type CreatePayrollBonusMutationFn = Apollo.MutationFunction<
  CreatePayrollBonusMutation,
  CreatePayrollBonusMutationVariables
>;

/**
 * __useCreatePayrollBonusMutation__
 *
 * To run a mutation, you first call `useCreatePayrollBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayrollBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayrollBonusMutation, { data, loading, error }] = useCreatePayrollBonusMutation({
 *   variables: {
 *      payrollId: // value for 'payrollId'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreatePayrollBonusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePayrollBonusMutation,
    CreatePayrollBonusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePayrollBonusMutation,
    CreatePayrollBonusMutationVariables
  >(CreatePayrollBonusDocument, options);
}
export type CreatePayrollBonusMutationHookResult = ReturnType<
  typeof useCreatePayrollBonusMutation
>;
export type CreatePayrollBonusMutationResult =
  Apollo.MutationResult<CreatePayrollBonusMutation>;
export type CreatePayrollBonusMutationOptions = Apollo.BaseMutationOptions<
  CreatePayrollBonusMutation,
  CreatePayrollBonusMutationVariables
>;
export const UpdatePayrollBonusDocument = gql`
  mutation updatePayrollBonus($id: String!, $name: String, $value: Int) {
    updatePayrollBonus(id: $id, name: $name, value: $value) {
      id
      name
      value
    }
  }
`;
export type UpdatePayrollBonusMutationFn = Apollo.MutationFunction<
  UpdatePayrollBonusMutation,
  UpdatePayrollBonusMutationVariables
>;

/**
 * __useUpdatePayrollBonusMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollBonusMutation, { data, loading, error }] = useUpdatePayrollBonusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdatePayrollBonusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayrollBonusMutation,
    UpdatePayrollBonusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePayrollBonusMutation,
    UpdatePayrollBonusMutationVariables
  >(UpdatePayrollBonusDocument, options);
}
export type UpdatePayrollBonusMutationHookResult = ReturnType<
  typeof useUpdatePayrollBonusMutation
>;
export type UpdatePayrollBonusMutationResult =
  Apollo.MutationResult<UpdatePayrollBonusMutation>;
export type UpdatePayrollBonusMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayrollBonusMutation,
  UpdatePayrollBonusMutationVariables
>;
export const DeletePayrollBonusDocument = gql`
  mutation deletePayrollBonus($bonusId: String!) {
    deletePayrollBonus(bonusId: $bonusId)
  }
`;
export type DeletePayrollBonusMutationFn = Apollo.MutationFunction<
  DeletePayrollBonusMutation,
  DeletePayrollBonusMutationVariables
>;

/**
 * __useDeletePayrollBonusMutation__
 *
 * To run a mutation, you first call `useDeletePayrollBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayrollBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayrollBonusMutation, { data, loading, error }] = useDeletePayrollBonusMutation({
 *   variables: {
 *      bonusId: // value for 'bonusId'
 *   },
 * });
 */
export function useDeletePayrollBonusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePayrollBonusMutation,
    DeletePayrollBonusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePayrollBonusMutation,
    DeletePayrollBonusMutationVariables
  >(DeletePayrollBonusDocument, options);
}
export type DeletePayrollBonusMutationHookResult = ReturnType<
  typeof useDeletePayrollBonusMutation
>;
export type DeletePayrollBonusMutationResult =
  Apollo.MutationResult<DeletePayrollBonusMutation>;
export type DeletePayrollBonusMutationOptions = Apollo.BaseMutationOptions<
  DeletePayrollBonusMutation,
  DeletePayrollBonusMutationVariables
>;
export const CreatePtCheckListItemDocument = gql`
  mutation createPTCheckListItem($projectTaskId: String!, $name: String!) {
    createPTCheckListItem(projectTaskId: $projectTaskId, name: $name) {
      id
    }
  }
`;
export type CreatePtCheckListItemMutationFn = Apollo.MutationFunction<
  CreatePtCheckListItemMutation,
  CreatePtCheckListItemMutationVariables
>;

/**
 * __useCreatePtCheckListItemMutation__
 *
 * To run a mutation, you first call `useCreatePtCheckListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePtCheckListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPtCheckListItemMutation, { data, loading, error }] = useCreatePtCheckListItemMutation({
 *   variables: {
 *      projectTaskId: // value for 'projectTaskId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreatePtCheckListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePtCheckListItemMutation,
    CreatePtCheckListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePtCheckListItemMutation,
    CreatePtCheckListItemMutationVariables
  >(CreatePtCheckListItemDocument, options);
}
export type CreatePtCheckListItemMutationHookResult = ReturnType<
  typeof useCreatePtCheckListItemMutation
>;
export type CreatePtCheckListItemMutationResult =
  Apollo.MutationResult<CreatePtCheckListItemMutation>;
export type CreatePtCheckListItemMutationOptions = Apollo.BaseMutationOptions<
  CreatePtCheckListItemMutation,
  CreatePtCheckListItemMutationVariables
>;
export const UpdatePtCheckListItemDocument = gql`
  mutation updatePTCheckListItem($id: String!, $name: String!) {
    updatePTCheckListItem(id: $id, name: $name) {
      id
    }
  }
`;
export type UpdatePtCheckListItemMutationFn = Apollo.MutationFunction<
  UpdatePtCheckListItemMutation,
  UpdatePtCheckListItemMutationVariables
>;

/**
 * __useUpdatePtCheckListItemMutation__
 *
 * To run a mutation, you first call `useUpdatePtCheckListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePtCheckListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePtCheckListItemMutation, { data, loading, error }] = useUpdatePtCheckListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdatePtCheckListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePtCheckListItemMutation,
    UpdatePtCheckListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePtCheckListItemMutation,
    UpdatePtCheckListItemMutationVariables
  >(UpdatePtCheckListItemDocument, options);
}
export type UpdatePtCheckListItemMutationHookResult = ReturnType<
  typeof useUpdatePtCheckListItemMutation
>;
export type UpdatePtCheckListItemMutationResult =
  Apollo.MutationResult<UpdatePtCheckListItemMutation>;
export type UpdatePtCheckListItemMutationOptions = Apollo.BaseMutationOptions<
  UpdatePtCheckListItemMutation,
  UpdatePtCheckListItemMutationVariables
>;
export const DeletePtCheckListItemDocument = gql`
  mutation deletePTCheckListItem($id: String!) {
    deletePTCheckListItem(id: $id)
  }
`;
export type DeletePtCheckListItemMutationFn = Apollo.MutationFunction<
  DeletePtCheckListItemMutation,
  DeletePtCheckListItemMutationVariables
>;

/**
 * __useDeletePtCheckListItemMutation__
 *
 * To run a mutation, you first call `useDeletePtCheckListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePtCheckListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePtCheckListItemMutation, { data, loading, error }] = useDeletePtCheckListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePtCheckListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePtCheckListItemMutation,
    DeletePtCheckListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePtCheckListItemMutation,
    DeletePtCheckListItemMutationVariables
  >(DeletePtCheckListItemDocument, options);
}
export type DeletePtCheckListItemMutationHookResult = ReturnType<
  typeof useDeletePtCheckListItemMutation
>;
export type DeletePtCheckListItemMutationResult =
  Apollo.MutationResult<DeletePtCheckListItemMutation>;
export type DeletePtCheckListItemMutationOptions = Apollo.BaseMutationOptions<
  DeletePtCheckListItemMutation,
  DeletePtCheckListItemMutationVariables
>;
export const ChangePtCheckListItemStatusDocument = gql`
  mutation changePTCheckListItemStatus($id: String!) {
    changePTCheckListItemStatus(id: $id) {
      id
    }
  }
`;
export type ChangePtCheckListItemStatusMutationFn = Apollo.MutationFunction<
  ChangePtCheckListItemStatusMutation,
  ChangePtCheckListItemStatusMutationVariables
>;

/**
 * __useChangePtCheckListItemStatusMutation__
 *
 * To run a mutation, you first call `useChangePtCheckListItemStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePtCheckListItemStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePtCheckListItemStatusMutation, { data, loading, error }] = useChangePtCheckListItemStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChangePtCheckListItemStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePtCheckListItemStatusMutation,
    ChangePtCheckListItemStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePtCheckListItemStatusMutation,
    ChangePtCheckListItemStatusMutationVariables
  >(ChangePtCheckListItemStatusDocument, options);
}
export type ChangePtCheckListItemStatusMutationHookResult = ReturnType<
  typeof useChangePtCheckListItemStatusMutation
>;
export type ChangePtCheckListItemStatusMutationResult =
  Apollo.MutationResult<ChangePtCheckListItemStatusMutation>;
export type ChangePtCheckListItemStatusMutationOptions =
  Apollo.BaseMutationOptions<
    ChangePtCheckListItemStatusMutation,
    ChangePtCheckListItemStatusMutationVariables
  >;
export const DeleteClassroomDocument = gql`
  mutation deleteClassroom($id: String!) {
    deleteClassroom(id: $id)
  }
`;
export type DeleteClassroomMutationFn = Apollo.MutationFunction<
  DeleteClassroomMutation,
  DeleteClassroomMutationVariables
>;

/**
 * __useDeleteClassroomMutation__
 *
 * To run a mutation, you first call `useDeleteClassroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassroomMutation, { data, loading, error }] = useDeleteClassroomMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClassroomMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClassroomMutation,
    DeleteClassroomMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClassroomMutation,
    DeleteClassroomMutationVariables
  >(DeleteClassroomDocument, options);
}
export type DeleteClassroomMutationHookResult = ReturnType<
  typeof useDeleteClassroomMutation
>;
export type DeleteClassroomMutationResult =
  Apollo.MutationResult<DeleteClassroomMutation>;
export type DeleteClassroomMutationOptions = Apollo.BaseMutationOptions<
  DeleteClassroomMutation,
  DeleteClassroomMutationVariables
>;
export const CreateClientSiteModelDocument = gql`
  mutation createClientSiteModel(
    $name: String!
    $clientSiteId: String!
    $itemIds: [String]!
  ) {
    createClientSiteModel(
      name: $name
      clientSiteId: $clientSiteId
      itemIds: $itemIds
    ) {
      id
      name
      itemId
      items {
        id
        itemId
      }
    }
  }
`;
export type CreateClientSiteModelMutationFn = Apollo.MutationFunction<
  CreateClientSiteModelMutation,
  CreateClientSiteModelMutationVariables
>;

/**
 * __useCreateClientSiteModelMutation__
 *
 * To run a mutation, you first call `useCreateClientSiteModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientSiteModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientSiteModelMutation, { data, loading, error }] = useCreateClientSiteModelMutation({
 *   variables: {
 *      name: // value for 'name'
 *      clientSiteId: // value for 'clientSiteId'
 *      itemIds: // value for 'itemIds'
 *   },
 * });
 */
export function useCreateClientSiteModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientSiteModelMutation,
    CreateClientSiteModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientSiteModelMutation,
    CreateClientSiteModelMutationVariables
  >(CreateClientSiteModelDocument, options);
}
export type CreateClientSiteModelMutationHookResult = ReturnType<
  typeof useCreateClientSiteModelMutation
>;
export type CreateClientSiteModelMutationResult =
  Apollo.MutationResult<CreateClientSiteModelMutation>;
export type CreateClientSiteModelMutationOptions = Apollo.BaseMutationOptions<
  CreateClientSiteModelMutation,
  CreateClientSiteModelMutationVariables
>;
export const UpdateClientSiteModelDocument = gql`
  mutation updateClientSiteModel($id: String!, $name: String!) {
    updateClientSiteModel(id: $id, name: $name) {
      id
    }
  }
`;
export type UpdateClientSiteModelMutationFn = Apollo.MutationFunction<
  UpdateClientSiteModelMutation,
  UpdateClientSiteModelMutationVariables
>;

/**
 * __useUpdateClientSiteModelMutation__
 *
 * To run a mutation, you first call `useUpdateClientSiteModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientSiteModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientSiteModelMutation, { data, loading, error }] = useUpdateClientSiteModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateClientSiteModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientSiteModelMutation,
    UpdateClientSiteModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientSiteModelMutation,
    UpdateClientSiteModelMutationVariables
  >(UpdateClientSiteModelDocument, options);
}
export type UpdateClientSiteModelMutationHookResult = ReturnType<
  typeof useUpdateClientSiteModelMutation
>;
export type UpdateClientSiteModelMutationResult =
  Apollo.MutationResult<UpdateClientSiteModelMutation>;
export type UpdateClientSiteModelMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientSiteModelMutation,
  UpdateClientSiteModelMutationVariables
>;
export const DeleteClientSiteModelDocument = gql`
  mutation deleteClientSiteModel($id: String!) {
    deleteClientSiteModel(id: $id)
  }
`;
export type DeleteClientSiteModelMutationFn = Apollo.MutationFunction<
  DeleteClientSiteModelMutation,
  DeleteClientSiteModelMutationVariables
>;

/**
 * __useDeleteClientSiteModelMutation__
 *
 * To run a mutation, you first call `useDeleteClientSiteModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientSiteModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientSiteModelMutation, { data, loading, error }] = useDeleteClientSiteModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClientSiteModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientSiteModelMutation,
    DeleteClientSiteModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientSiteModelMutation,
    DeleteClientSiteModelMutationVariables
  >(DeleteClientSiteModelDocument, options);
}
export type DeleteClientSiteModelMutationHookResult = ReturnType<
  typeof useDeleteClientSiteModelMutation
>;
export type DeleteClientSiteModelMutationResult =
  Apollo.MutationResult<DeleteClientSiteModelMutation>;
export type DeleteClientSiteModelMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientSiteModelMutation,
  DeleteClientSiteModelMutationVariables
>;
export const UploadClientSiteModelFileDocument = gql`
  mutation uploadClientSiteModelFile(
    $clientSiteModelId: String!
    $file: Upload!
    $fileType: ClientSiteModelFilesEnum!
  ) {
    uploadClientSiteModelFile(
      clientSiteModelId: $clientSiteModelId
      file: $file
      fileType: $fileType
    )
  }
`;
export type UploadClientSiteModelFileMutationFn = Apollo.MutationFunction<
  UploadClientSiteModelFileMutation,
  UploadClientSiteModelFileMutationVariables
>;

/**
 * __useUploadClientSiteModelFileMutation__
 *
 * To run a mutation, you first call `useUploadClientSiteModelFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadClientSiteModelFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadClientSiteModelFileMutation, { data, loading, error }] = useUploadClientSiteModelFileMutation({
 *   variables: {
 *      clientSiteModelId: // value for 'clientSiteModelId'
 *      file: // value for 'file'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useUploadClientSiteModelFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadClientSiteModelFileMutation,
    UploadClientSiteModelFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadClientSiteModelFileMutation,
    UploadClientSiteModelFileMutationVariables
  >(UploadClientSiteModelFileDocument, options);
}
export type UploadClientSiteModelFileMutationHookResult = ReturnType<
  typeof useUploadClientSiteModelFileMutation
>;
export type UploadClientSiteModelFileMutationResult =
  Apollo.MutationResult<UploadClientSiteModelFileMutation>;
export type UploadClientSiteModelFileMutationOptions =
  Apollo.BaseMutationOptions<
    UploadClientSiteModelFileMutation,
    UploadClientSiteModelFileMutationVariables
  >;
export const DeleteClientSiteModelFileDocument = gql`
  mutation deleteClientSiteModelFile(
    $clientSiteModelId: String!
    $fileType: ClientSiteModelFilesEnum!
  ) {
    deleteClientSiteModelFile(
      clientSiteModelId: $clientSiteModelId
      fileType: $fileType
    )
  }
`;
export type DeleteClientSiteModelFileMutationFn = Apollo.MutationFunction<
  DeleteClientSiteModelFileMutation,
  DeleteClientSiteModelFileMutationVariables
>;

/**
 * __useDeleteClientSiteModelFileMutation__
 *
 * To run a mutation, you first call `useDeleteClientSiteModelFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientSiteModelFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientSiteModelFileMutation, { data, loading, error }] = useDeleteClientSiteModelFileMutation({
 *   variables: {
 *      clientSiteModelId: // value for 'clientSiteModelId'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useDeleteClientSiteModelFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientSiteModelFileMutation,
    DeleteClientSiteModelFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientSiteModelFileMutation,
    DeleteClientSiteModelFileMutationVariables
  >(DeleteClientSiteModelFileDocument, options);
}
export type DeleteClientSiteModelFileMutationHookResult = ReturnType<
  typeof useDeleteClientSiteModelFileMutation
>;
export type DeleteClientSiteModelFileMutationResult =
  Apollo.MutationResult<DeleteClientSiteModelFileMutation>;
export type DeleteClientSiteModelFileMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteClientSiteModelFileMutation,
    DeleteClientSiteModelFileMutationVariables
  >;
export const CreateClientSiteModelItemDocument = gql`
  mutation createClientSiteModelItem(
    $clientSiteModelId: String!
    $itemId: String!
  ) {
    createClientSiteModelItem(
      clientSiteModelId: $clientSiteModelId
      itemId: $itemId
    ) {
      id
    }
  }
`;
export type CreateClientSiteModelItemMutationFn = Apollo.MutationFunction<
  CreateClientSiteModelItemMutation,
  CreateClientSiteModelItemMutationVariables
>;

/**
 * __useCreateClientSiteModelItemMutation__
 *
 * To run a mutation, you first call `useCreateClientSiteModelItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientSiteModelItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientSiteModelItemMutation, { data, loading, error }] = useCreateClientSiteModelItemMutation({
 *   variables: {
 *      clientSiteModelId: // value for 'clientSiteModelId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useCreateClientSiteModelItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientSiteModelItemMutation,
    CreateClientSiteModelItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientSiteModelItemMutation,
    CreateClientSiteModelItemMutationVariables
  >(CreateClientSiteModelItemDocument, options);
}
export type CreateClientSiteModelItemMutationHookResult = ReturnType<
  typeof useCreateClientSiteModelItemMutation
>;
export type CreateClientSiteModelItemMutationResult =
  Apollo.MutationResult<CreateClientSiteModelItemMutation>;
export type CreateClientSiteModelItemMutationOptions =
  Apollo.BaseMutationOptions<
    CreateClientSiteModelItemMutation,
    CreateClientSiteModelItemMutationVariables
  >;
export const UpdateClientSiteModelItemDocument = gql`
  mutation updateClientSiteModelItem($id: String!, $itemId: String!) {
    updateClientSiteModelItem(id: $id, itemId: $itemId) {
      id
    }
  }
`;
export type UpdateClientSiteModelItemMutationFn = Apollo.MutationFunction<
  UpdateClientSiteModelItemMutation,
  UpdateClientSiteModelItemMutationVariables
>;

/**
 * __useUpdateClientSiteModelItemMutation__
 *
 * To run a mutation, you first call `useUpdateClientSiteModelItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientSiteModelItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientSiteModelItemMutation, { data, loading, error }] = useUpdateClientSiteModelItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useUpdateClientSiteModelItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientSiteModelItemMutation,
    UpdateClientSiteModelItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientSiteModelItemMutation,
    UpdateClientSiteModelItemMutationVariables
  >(UpdateClientSiteModelItemDocument, options);
}
export type UpdateClientSiteModelItemMutationHookResult = ReturnType<
  typeof useUpdateClientSiteModelItemMutation
>;
export type UpdateClientSiteModelItemMutationResult =
  Apollo.MutationResult<UpdateClientSiteModelItemMutation>;
export type UpdateClientSiteModelItemMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateClientSiteModelItemMutation,
    UpdateClientSiteModelItemMutationVariables
  >;
export const DeleteClientSiteModelItemDocument = gql`
  mutation deleteClientSiteModelItem($id: String!) {
    deleteClientSiteModelItem(id: $id)
  }
`;
export type DeleteClientSiteModelItemMutationFn = Apollo.MutationFunction<
  DeleteClientSiteModelItemMutation,
  DeleteClientSiteModelItemMutationVariables
>;

/**
 * __useDeleteClientSiteModelItemMutation__
 *
 * To run a mutation, you first call `useDeleteClientSiteModelItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientSiteModelItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientSiteModelItemMutation, { data, loading, error }] = useDeleteClientSiteModelItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClientSiteModelItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientSiteModelItemMutation,
    DeleteClientSiteModelItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientSiteModelItemMutation,
    DeleteClientSiteModelItemMutationVariables
  >(DeleteClientSiteModelItemDocument, options);
}
export type DeleteClientSiteModelItemMutationHookResult = ReturnType<
  typeof useDeleteClientSiteModelItemMutation
>;
export type DeleteClientSiteModelItemMutationResult =
  Apollo.MutationResult<DeleteClientSiteModelItemMutation>;
export type DeleteClientSiteModelItemMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteClientSiteModelItemMutation,
    DeleteClientSiteModelItemMutationVariables
  >;
export const CreateClientSiteDocument = gql`
  mutation createClientSite(
    $name: String!
    $link: String
    $iframe: String!
    $key: String!
  ) {
    createClientSite(name: $name, link: $link, iframe: $iframe, key: $key) {
      id
      name
      link
      iframe
      models {
        id
        name
      }
    }
  }
`;
export type CreateClientSiteMutationFn = Apollo.MutationFunction<
  CreateClientSiteMutation,
  CreateClientSiteMutationVariables
>;

/**
 * __useCreateClientSiteMutation__
 *
 * To run a mutation, you first call `useCreateClientSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientSiteMutation, { data, loading, error }] = useCreateClientSiteMutation({
 *   variables: {
 *      name: // value for 'name'
 *      link: // value for 'link'
 *      iframe: // value for 'iframe'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useCreateClientSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientSiteMutation,
    CreateClientSiteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientSiteMutation,
    CreateClientSiteMutationVariables
  >(CreateClientSiteDocument, options);
}
export type CreateClientSiteMutationHookResult = ReturnType<
  typeof useCreateClientSiteMutation
>;
export type CreateClientSiteMutationResult =
  Apollo.MutationResult<CreateClientSiteMutation>;
export type CreateClientSiteMutationOptions = Apollo.BaseMutationOptions<
  CreateClientSiteMutation,
  CreateClientSiteMutationVariables
>;
export const UpdateClientSiteDocument = gql`
  mutation updateClientSite(
    $id: String!
    $name: String!
    $link: String
    $iframe: String!
    $key: String!
  ) {
    updateClientSite(
      id: $id
      name: $name
      link: $link
      iframe: $iframe
      key: $key
    ) {
      id
      name
      link
      iframe
      models {
        id
        name
      }
    }
  }
`;
export type UpdateClientSiteMutationFn = Apollo.MutationFunction<
  UpdateClientSiteMutation,
  UpdateClientSiteMutationVariables
>;

/**
 * __useUpdateClientSiteMutation__
 *
 * To run a mutation, you first call `useUpdateClientSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientSiteMutation, { data, loading, error }] = useUpdateClientSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      link: // value for 'link'
 *      iframe: // value for 'iframe'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useUpdateClientSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientSiteMutation,
    UpdateClientSiteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientSiteMutation,
    UpdateClientSiteMutationVariables
  >(UpdateClientSiteDocument, options);
}
export type UpdateClientSiteMutationHookResult = ReturnType<
  typeof useUpdateClientSiteMutation
>;
export type UpdateClientSiteMutationResult =
  Apollo.MutationResult<UpdateClientSiteMutation>;
export type UpdateClientSiteMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientSiteMutation,
  UpdateClientSiteMutationVariables
>;
export const DeleteClientSiteDocument = gql`
  mutation deleteClientSite($id: String!) {
    deleteClientSite(id: $id)
  }
`;
export type DeleteClientSiteMutationFn = Apollo.MutationFunction<
  DeleteClientSiteMutation,
  DeleteClientSiteMutationVariables
>;

/**
 * __useDeleteClientSiteMutation__
 *
 * To run a mutation, you first call `useDeleteClientSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientSiteMutation, { data, loading, error }] = useDeleteClientSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClientSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientSiteMutation,
    DeleteClientSiteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientSiteMutation,
    DeleteClientSiteMutationVariables
  >(DeleteClientSiteDocument, options);
}
export type DeleteClientSiteMutationHookResult = ReturnType<
  typeof useDeleteClientSiteMutation
>;
export type DeleteClientSiteMutationResult =
  Apollo.MutationResult<DeleteClientSiteMutation>;
export type DeleteClientSiteMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientSiteMutation,
  DeleteClientSiteMutationVariables
>;
export const UpdateProjectCorrectionDocument = gql`
  mutation updateProjectCorrection(
    $projectCorrectionData: UpdateProjectCorrectionInput!
  ) {
    updateProjectCorrection(projectCorrectionData: $projectCorrectionData) {
      id
    }
  }
`;
export type UpdateProjectCorrectionMutationFn = Apollo.MutationFunction<
  UpdateProjectCorrectionMutation,
  UpdateProjectCorrectionMutationVariables
>;

/**
 * __useUpdateProjectCorrectionMutation__
 *
 * To run a mutation, you first call `useUpdateProjectCorrectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectCorrectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectCorrectionMutation, { data, loading, error }] = useUpdateProjectCorrectionMutation({
 *   variables: {
 *      projectCorrectionData: // value for 'projectCorrectionData'
 *   },
 * });
 */
export function useUpdateProjectCorrectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectCorrectionMutation,
    UpdateProjectCorrectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectCorrectionMutation,
    UpdateProjectCorrectionMutationVariables
  >(UpdateProjectCorrectionDocument, options);
}
export type UpdateProjectCorrectionMutationHookResult = ReturnType<
  typeof useUpdateProjectCorrectionMutation
>;
export type UpdateProjectCorrectionMutationResult =
  Apollo.MutationResult<UpdateProjectCorrectionMutation>;
export type UpdateProjectCorrectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectCorrectionMutation,
  UpdateProjectCorrectionMutationVariables
>;
export const CreateCorrectionCommentDocument = gql`
  mutation createCorrectionComment(
    $correctionCommentData: CreateCorrectionCommentInput!
  ) {
    createCorrectionComment(correctionCommentData: $correctionCommentData) {
      id
    }
  }
`;
export type CreateCorrectionCommentMutationFn = Apollo.MutationFunction<
  CreateCorrectionCommentMutation,
  CreateCorrectionCommentMutationVariables
>;

/**
 * __useCreateCorrectionCommentMutation__
 *
 * To run a mutation, you first call `useCreateCorrectionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCorrectionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCorrectionCommentMutation, { data, loading, error }] = useCreateCorrectionCommentMutation({
 *   variables: {
 *      correctionCommentData: // value for 'correctionCommentData'
 *   },
 * });
 */
export function useCreateCorrectionCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCorrectionCommentMutation,
    CreateCorrectionCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCorrectionCommentMutation,
    CreateCorrectionCommentMutationVariables
  >(CreateCorrectionCommentDocument, options);
}
export type CreateCorrectionCommentMutationHookResult = ReturnType<
  typeof useCreateCorrectionCommentMutation
>;
export type CreateCorrectionCommentMutationResult =
  Apollo.MutationResult<CreateCorrectionCommentMutation>;
export type CreateCorrectionCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCorrectionCommentMutation,
  CreateCorrectionCommentMutationVariables
>;
export const UpdateCorrectionCommentDocument = gql`
  mutation updateCorrectionComment(
    $correctionCommentData: UpdateCorrectionCommentInput!
  ) {
    updateCorrectionComment(correctionCommentData: $correctionCommentData) {
      id
    }
  }
`;
export type UpdateCorrectionCommentMutationFn = Apollo.MutationFunction<
  UpdateCorrectionCommentMutation,
  UpdateCorrectionCommentMutationVariables
>;

/**
 * __useUpdateCorrectionCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCorrectionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCorrectionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCorrectionCommentMutation, { data, loading, error }] = useUpdateCorrectionCommentMutation({
 *   variables: {
 *      correctionCommentData: // value for 'correctionCommentData'
 *   },
 * });
 */
export function useUpdateCorrectionCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCorrectionCommentMutation,
    UpdateCorrectionCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCorrectionCommentMutation,
    UpdateCorrectionCommentMutationVariables
  >(UpdateCorrectionCommentDocument, options);
}
export type UpdateCorrectionCommentMutationHookResult = ReturnType<
  typeof useUpdateCorrectionCommentMutation
>;
export type UpdateCorrectionCommentMutationResult =
  Apollo.MutationResult<UpdateCorrectionCommentMutation>;
export type UpdateCorrectionCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateCorrectionCommentMutation,
  UpdateCorrectionCommentMutationVariables
>;
export const DeleteCorrectionCommentDocument = gql`
  mutation deleteCorrectionComment($correctionCommentId: String!) {
    deleteCorrectionComment(correctionCommentId: $correctionCommentId)
  }
`;
export type DeleteCorrectionCommentMutationFn = Apollo.MutationFunction<
  DeleteCorrectionCommentMutation,
  DeleteCorrectionCommentMutationVariables
>;

/**
 * __useDeleteCorrectionCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCorrectionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCorrectionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCorrectionCommentMutation, { data, loading, error }] = useDeleteCorrectionCommentMutation({
 *   variables: {
 *      correctionCommentId: // value for 'correctionCommentId'
 *   },
 * });
 */
export function useDeleteCorrectionCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCorrectionCommentMutation,
    DeleteCorrectionCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCorrectionCommentMutation,
    DeleteCorrectionCommentMutationVariables
  >(DeleteCorrectionCommentDocument, options);
}
export type DeleteCorrectionCommentMutationHookResult = ReturnType<
  typeof useDeleteCorrectionCommentMutation
>;
export type DeleteCorrectionCommentMutationResult =
  Apollo.MutationResult<DeleteCorrectionCommentMutation>;
export type DeleteCorrectionCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCorrectionCommentMutation,
  DeleteCorrectionCommentMutationVariables
>;
export const CreateUserDepartmentDocument = gql`
  mutation createUserDepartment($name: String!) {
    createUserDepartment(name: $name) {
      id
    }
  }
`;
export type CreateUserDepartmentMutationFn = Apollo.MutationFunction<
  CreateUserDepartmentMutation,
  CreateUserDepartmentMutationVariables
>;

/**
 * __useCreateUserDepartmentMutation__
 *
 * To run a mutation, you first call `useCreateUserDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserDepartmentMutation, { data, loading, error }] = useCreateUserDepartmentMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateUserDepartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserDepartmentMutation,
    CreateUserDepartmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserDepartmentMutation,
    CreateUserDepartmentMutationVariables
  >(CreateUserDepartmentDocument, options);
}
export type CreateUserDepartmentMutationHookResult = ReturnType<
  typeof useCreateUserDepartmentMutation
>;
export type CreateUserDepartmentMutationResult =
  Apollo.MutationResult<CreateUserDepartmentMutation>;
export type CreateUserDepartmentMutationOptions = Apollo.BaseMutationOptions<
  CreateUserDepartmentMutation,
  CreateUserDepartmentMutationVariables
>;
export const UpdateUserDepartmentDocument = gql`
  mutation updateUserDepartment($departmentId: String!, $name: String!) {
    updateUserDepartment(departmentId: $departmentId, name: $name) {
      id
    }
  }
`;
export type UpdateUserDepartmentMutationFn = Apollo.MutationFunction<
  UpdateUserDepartmentMutation,
  UpdateUserDepartmentMutationVariables
>;

/**
 * __useUpdateUserDepartmentMutation__
 *
 * To run a mutation, you first call `useUpdateUserDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDepartmentMutation, { data, loading, error }] = useUpdateUserDepartmentMutation({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUserDepartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserDepartmentMutation,
    UpdateUserDepartmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserDepartmentMutation,
    UpdateUserDepartmentMutationVariables
  >(UpdateUserDepartmentDocument, options);
}
export type UpdateUserDepartmentMutationHookResult = ReturnType<
  typeof useUpdateUserDepartmentMutation
>;
export type UpdateUserDepartmentMutationResult =
  Apollo.MutationResult<UpdateUserDepartmentMutation>;
export type UpdateUserDepartmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserDepartmentMutation,
  UpdateUserDepartmentMutationVariables
>;
export const DeleteUserDepartmentDocument = gql`
  mutation deleteUserDepartment($departmentId: String!) {
    deleteUserDepartment(departmentId: $departmentId)
  }
`;
export type DeleteUserDepartmentMutationFn = Apollo.MutationFunction<
  DeleteUserDepartmentMutation,
  DeleteUserDepartmentMutationVariables
>;

/**
 * __useDeleteUserDepartmentMutation__
 *
 * To run a mutation, you first call `useDeleteUserDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserDepartmentMutation, { data, loading, error }] = useDeleteUserDepartmentMutation({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useDeleteUserDepartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserDepartmentMutation,
    DeleteUserDepartmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserDepartmentMutation,
    DeleteUserDepartmentMutationVariables
  >(DeleteUserDepartmentDocument, options);
}
export type DeleteUserDepartmentMutationHookResult = ReturnType<
  typeof useDeleteUserDepartmentMutation
>;
export type DeleteUserDepartmentMutationResult =
  Apollo.MutationResult<DeleteUserDepartmentMutation>;
export type DeleteUserDepartmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserDepartmentMutation,
  DeleteUserDepartmentMutationVariables
>;
export const CreateEmployeesDistributionDocument = gql`
  mutation createEmployeesDistribution(
    $distributionData: CreateEmployeesDistribution!
  ) {
    createEmployeesDistribution(distributionData: $distributionData) {
      id
    }
  }
`;
export type CreateEmployeesDistributionMutationFn = Apollo.MutationFunction<
  CreateEmployeesDistributionMutation,
  CreateEmployeesDistributionMutationVariables
>;

/**
 * __useCreateEmployeesDistributionMutation__
 *
 * To run a mutation, you first call `useCreateEmployeesDistributionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeesDistributionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeesDistributionMutation, { data, loading, error }] = useCreateEmployeesDistributionMutation({
 *   variables: {
 *      distributionData: // value for 'distributionData'
 *   },
 * });
 */
export function useCreateEmployeesDistributionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEmployeesDistributionMutation,
    CreateEmployeesDistributionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEmployeesDistributionMutation,
    CreateEmployeesDistributionMutationVariables
  >(CreateEmployeesDistributionDocument, options);
}
export type CreateEmployeesDistributionMutationHookResult = ReturnType<
  typeof useCreateEmployeesDistributionMutation
>;
export type CreateEmployeesDistributionMutationResult =
  Apollo.MutationResult<CreateEmployeesDistributionMutation>;
export type CreateEmployeesDistributionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateEmployeesDistributionMutation,
    CreateEmployeesDistributionMutationVariables
  >;
export const CreateEmployeesDistributionsDocument = gql`
  mutation createEmployeesDistributions($name: String!) {
    createEmployeesDistributions(name: $name) {
      id
    }
  }
`;
export type CreateEmployeesDistributionsMutationFn = Apollo.MutationFunction<
  CreateEmployeesDistributionsMutation,
  CreateEmployeesDistributionsMutationVariables
>;

/**
 * __useCreateEmployeesDistributionsMutation__
 *
 * To run a mutation, you first call `useCreateEmployeesDistributionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeesDistributionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeesDistributionsMutation, { data, loading, error }] = useCreateEmployeesDistributionsMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateEmployeesDistributionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEmployeesDistributionsMutation,
    CreateEmployeesDistributionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEmployeesDistributionsMutation,
    CreateEmployeesDistributionsMutationVariables
  >(CreateEmployeesDistributionsDocument, options);
}
export type CreateEmployeesDistributionsMutationHookResult = ReturnType<
  typeof useCreateEmployeesDistributionsMutation
>;
export type CreateEmployeesDistributionsMutationResult =
  Apollo.MutationResult<CreateEmployeesDistributionsMutation>;
export type CreateEmployeesDistributionsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateEmployeesDistributionsMutation,
    CreateEmployeesDistributionsMutationVariables
  >;
export const UpdateEmployeesDistributionDocument = gql`
  mutation updateEmployeesDistribution(
    $distributionData: UpdateEmployeesDistribution!
  ) {
    updateEmployeesDistribution(distributionData: $distributionData) {
      id
    }
  }
`;
export type UpdateEmployeesDistributionMutationFn = Apollo.MutationFunction<
  UpdateEmployeesDistributionMutation,
  UpdateEmployeesDistributionMutationVariables
>;

/**
 * __useUpdateEmployeesDistributionMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeesDistributionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeesDistributionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeesDistributionMutation, { data, loading, error }] = useUpdateEmployeesDistributionMutation({
 *   variables: {
 *      distributionData: // value for 'distributionData'
 *   },
 * });
 */
export function useUpdateEmployeesDistributionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmployeesDistributionMutation,
    UpdateEmployeesDistributionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEmployeesDistributionMutation,
    UpdateEmployeesDistributionMutationVariables
  >(UpdateEmployeesDistributionDocument, options);
}
export type UpdateEmployeesDistributionMutationHookResult = ReturnType<
  typeof useUpdateEmployeesDistributionMutation
>;
export type UpdateEmployeesDistributionMutationResult =
  Apollo.MutationResult<UpdateEmployeesDistributionMutation>;
export type UpdateEmployeesDistributionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateEmployeesDistributionMutation,
    UpdateEmployeesDistributionMutationVariables
  >;
export const DeleteEmployeesDistributionDocument = gql`
  mutation deleteEmployeesDistribution($id: String!) {
    deleteEmployeesDistribution(id: $id)
  }
`;
export type DeleteEmployeesDistributionMutationFn = Apollo.MutationFunction<
  DeleteEmployeesDistributionMutation,
  DeleteEmployeesDistributionMutationVariables
>;

/**
 * __useDeleteEmployeesDistributionMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeesDistributionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeesDistributionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeesDistributionMutation, { data, loading, error }] = useDeleteEmployeesDistributionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmployeesDistributionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEmployeesDistributionMutation,
    DeleteEmployeesDistributionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEmployeesDistributionMutation,
    DeleteEmployeesDistributionMutationVariables
  >(DeleteEmployeesDistributionDocument, options);
}
export type DeleteEmployeesDistributionMutationHookResult = ReturnType<
  typeof useDeleteEmployeesDistributionMutation
>;
export type DeleteEmployeesDistributionMutationResult =
  Apollo.MutationResult<DeleteEmployeesDistributionMutation>;
export type DeleteEmployeesDistributionMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteEmployeesDistributionMutation,
    DeleteEmployeesDistributionMutationVariables
  >;
export const CreateEventDocument = gql`
  mutation createEvent(
    $name: String!
    $photo: Upload
    $description: String
    $speaker: String
  ) {
    createEvent(
      name: $name
      photo: $photo
      description: $description
      speaker: $speaker
    ) {
      id
      name
      photo
      description
      speaker
    }
  }
`;
export type CreateEventMutationFn = Apollo.MutationFunction<
  CreateEventMutation,
  CreateEventMutationVariables
>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      name: // value for 'name'
 *      photo: // value for 'photo'
 *      description: // value for 'description'
 *      speaker: // value for 'speaker'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventMutation,
    CreateEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(
    CreateEventDocument,
    options
  );
}
export type CreateEventMutationHookResult = ReturnType<
  typeof useCreateEventMutation
>;
export type CreateEventMutationResult =
  Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<
  CreateEventMutation,
  CreateEventMutationVariables
>;
export const DeleteEventDocument = gql`
  mutation deleteEvent($id: String!) {
    deleteEvent(id: $id)
  }
`;
export type DeleteEventMutationFn = Apollo.MutationFunction<
  DeleteEventMutation,
  DeleteEventMutationVariables
>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEventMutation,
    DeleteEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(
    DeleteEventDocument,
    options
  );
}
export type DeleteEventMutationHookResult = ReturnType<
  typeof useDeleteEventMutation
>;
export type DeleteEventMutationResult =
  Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventMutation,
  DeleteEventMutationVariables
>;
export const UpdateEventDocument = gql`
  mutation updateEvent(
    $id: String!
    $name: String
    $photo: Upload
    $speaker: String
    $description: String
  ) {
    updateEvent(
      id: $id
      name: $name
      photo: $photo
      speaker: $speaker
      description: $description
    ) {
      id
      name
      photo
      description
      speaker
    }
  }
`;
export type UpdateEventMutationFn = Apollo.MutationFunction<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      photo: // value for 'photo'
 *      speaker: // value for 'speaker'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEventMutation,
    UpdateEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(
    UpdateEventDocument,
    options
  );
}
export type UpdateEventMutationHookResult = ReturnType<
  typeof useUpdateEventMutation
>;
export type UpdateEventMutationResult =
  Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;
export const CreateEventDateDocument = gql`
  mutation createEventDate(
    $date: String!
    $link: String
    $userLimit: Int
    $eventId: String!
  ) {
    createEventDate(
      date: $date
      link: $link
      userLimit: $userLimit
      eventId: $eventId
    ) {
      date
      link
      userLimit
    }
  }
`;
export type CreateEventDateMutationFn = Apollo.MutationFunction<
  CreateEventDateMutation,
  CreateEventDateMutationVariables
>;

/**
 * __useCreateEventDateMutation__
 *
 * To run a mutation, you first call `useCreateEventDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventDateMutation, { data, loading, error }] = useCreateEventDateMutation({
 *   variables: {
 *      date: // value for 'date'
 *      link: // value for 'link'
 *      userLimit: // value for 'userLimit'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useCreateEventDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventDateMutation,
    CreateEventDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEventDateMutation,
    CreateEventDateMutationVariables
  >(CreateEventDateDocument, options);
}
export type CreateEventDateMutationHookResult = ReturnType<
  typeof useCreateEventDateMutation
>;
export type CreateEventDateMutationResult =
  Apollo.MutationResult<CreateEventDateMutation>;
export type CreateEventDateMutationOptions = Apollo.BaseMutationOptions<
  CreateEventDateMutation,
  CreateEventDateMutationVariables
>;
export const UpdateEventDateDocument = gql`
  mutation updateEventDate(
    $id: String!
    $date: String
    $link: String
    $userLimit: Int
    $eventId: String
  ) {
    updateEventDate(
      id: $id
      date: $date
      link: $link
      userLimit: $userLimit
      eventId: $eventId
    ) {
      id
      date
      link
      userLimit
    }
  }
`;
export type UpdateEventDateMutationFn = Apollo.MutationFunction<
  UpdateEventDateMutation,
  UpdateEventDateMutationVariables
>;

/**
 * __useUpdateEventDateMutation__
 *
 * To run a mutation, you first call `useUpdateEventDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventDateMutation, { data, loading, error }] = useUpdateEventDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *      link: // value for 'link'
 *      userLimit: // value for 'userLimit'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useUpdateEventDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEventDateMutation,
    UpdateEventDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEventDateMutation,
    UpdateEventDateMutationVariables
  >(UpdateEventDateDocument, options);
}
export type UpdateEventDateMutationHookResult = ReturnType<
  typeof useUpdateEventDateMutation
>;
export type UpdateEventDateMutationResult =
  Apollo.MutationResult<UpdateEventDateMutation>;
export type UpdateEventDateMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventDateMutation,
  UpdateEventDateMutationVariables
>;
export const DeleteEventDateDocument = gql`
  mutation deleteEventDate($id: String!) {
    deleteEventDate(id: $id)
  }
`;
export type DeleteEventDateMutationFn = Apollo.MutationFunction<
  DeleteEventDateMutation,
  DeleteEventDateMutationVariables
>;

/**
 * __useDeleteEventDateMutation__
 *
 * To run a mutation, you first call `useDeleteEventDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventDateMutation, { data, loading, error }] = useDeleteEventDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEventDateMutation,
    DeleteEventDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEventDateMutation,
    DeleteEventDateMutationVariables
  >(DeleteEventDateDocument, options);
}
export type DeleteEventDateMutationHookResult = ReturnType<
  typeof useDeleteEventDateMutation
>;
export type DeleteEventDateMutationResult =
  Apollo.MutationResult<DeleteEventDateMutation>;
export type DeleteEventDateMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventDateMutation,
  DeleteEventDateMutationVariables
>;
export const EventDatePresentUserDocument = gql`
  mutation eventDatePresentUser($eventDateId: String!, $userIds: [String]!) {
    eventDatePresentUser(eventDateId: $eventDateId, userIds: $userIds)
  }
`;
export type EventDatePresentUserMutationFn = Apollo.MutationFunction<
  EventDatePresentUserMutation,
  EventDatePresentUserMutationVariables
>;

/**
 * __useEventDatePresentUserMutation__
 *
 * To run a mutation, you first call `useEventDatePresentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventDatePresentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventDatePresentUserMutation, { data, loading, error }] = useEventDatePresentUserMutation({
 *   variables: {
 *      eventDateId: // value for 'eventDateId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useEventDatePresentUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventDatePresentUserMutation,
    EventDatePresentUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EventDatePresentUserMutation,
    EventDatePresentUserMutationVariables
  >(EventDatePresentUserDocument, options);
}
export type EventDatePresentUserMutationHookResult = ReturnType<
  typeof useEventDatePresentUserMutation
>;
export type EventDatePresentUserMutationResult =
  Apollo.MutationResult<EventDatePresentUserMutation>;
export type EventDatePresentUserMutationOptions = Apollo.BaseMutationOptions<
  EventDatePresentUserMutation,
  EventDatePresentUserMutationVariables
>;
export const EventDateAbsentUserDocument = gql`
  mutation eventDateAbsentUser($eventDateId: String!, $userId: String!) {
    eventDateAbsentUser(eventDateId: $eventDateId, userId: $userId)
  }
`;
export type EventDateAbsentUserMutationFn = Apollo.MutationFunction<
  EventDateAbsentUserMutation,
  EventDateAbsentUserMutationVariables
>;

/**
 * __useEventDateAbsentUserMutation__
 *
 * To run a mutation, you first call `useEventDateAbsentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventDateAbsentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventDateAbsentUserMutation, { data, loading, error }] = useEventDateAbsentUserMutation({
 *   variables: {
 *      eventDateId: // value for 'eventDateId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEventDateAbsentUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventDateAbsentUserMutation,
    EventDateAbsentUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EventDateAbsentUserMutation,
    EventDateAbsentUserMutationVariables
  >(EventDateAbsentUserDocument, options);
}
export type EventDateAbsentUserMutationHookResult = ReturnType<
  typeof useEventDateAbsentUserMutation
>;
export type EventDateAbsentUserMutationResult =
  Apollo.MutationResult<EventDateAbsentUserMutation>;
export type EventDateAbsentUserMutationOptions = Apollo.BaseMutationOptions<
  EventDateAbsentUserMutation,
  EventDateAbsentUserMutationVariables
>;
export const CreateExerciseDocument = gql`
  mutation createExercise($exerciseData: CreateExerciseInput!) {
    createExercise(exerciseData: $exerciseData) {
      id
      type
    }
  }
`;
export type CreateExerciseMutationFn = Apollo.MutationFunction<
  CreateExerciseMutation,
  CreateExerciseMutationVariables
>;

/**
 * __useCreateExerciseMutation__
 *
 * To run a mutation, you first call `useCreateExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExerciseMutation, { data, loading, error }] = useCreateExerciseMutation({
 *   variables: {
 *      exerciseData: // value for 'exerciseData'
 *   },
 * });
 */
export function useCreateExerciseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExerciseMutation,
    CreateExerciseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExerciseMutation,
    CreateExerciseMutationVariables
  >(CreateExerciseDocument, options);
}
export type CreateExerciseMutationHookResult = ReturnType<
  typeof useCreateExerciseMutation
>;
export type CreateExerciseMutationResult =
  Apollo.MutationResult<CreateExerciseMutation>;
export type CreateExerciseMutationOptions = Apollo.BaseMutationOptions<
  CreateExerciseMutation,
  CreateExerciseMutationVariables
>;
export const UpdateExerciseDocument = gql`
  mutation updateExercise($id: String!, $exerciseData: UpdateExerciseInput!) {
    updateExercise(id: $id, exerciseData: $exerciseData) {
      contentEN
      contentUKR
      video
      pauseTime
      type
    }
  }
`;
export type UpdateExerciseMutationFn = Apollo.MutationFunction<
  UpdateExerciseMutation,
  UpdateExerciseMutationVariables
>;

/**
 * __useUpdateExerciseMutation__
 *
 * To run a mutation, you first call `useUpdateExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExerciseMutation, { data, loading, error }] = useUpdateExerciseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      exerciseData: // value for 'exerciseData'
 *   },
 * });
 */
export function useUpdateExerciseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExerciseMutation,
    UpdateExerciseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateExerciseMutation,
    UpdateExerciseMutationVariables
  >(UpdateExerciseDocument, options);
}
export type UpdateExerciseMutationHookResult = ReturnType<
  typeof useUpdateExerciseMutation
>;
export type UpdateExerciseMutationResult =
  Apollo.MutationResult<UpdateExerciseMutation>;
export type UpdateExerciseMutationOptions = Apollo.BaseMutationOptions<
  UpdateExerciseMutation,
  UpdateExerciseMutationVariables
>;
export const DeleteExerciseDocument = gql`
  mutation deleteExercise($id: String!) {
    deleteExercise(id: $id)
  }
`;
export type DeleteExerciseMutationFn = Apollo.MutationFunction<
  DeleteExerciseMutation,
  DeleteExerciseMutationVariables
>;

/**
 * __useDeleteExerciseMutation__
 *
 * To run a mutation, you first call `useDeleteExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExerciseMutation, { data, loading, error }] = useDeleteExerciseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExerciseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExerciseMutation,
    DeleteExerciseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteExerciseMutation,
    DeleteExerciseMutationVariables
  >(DeleteExerciseDocument, options);
}
export type DeleteExerciseMutationHookResult = ReturnType<
  typeof useDeleteExerciseMutation
>;
export type DeleteExerciseMutationResult =
  Apollo.MutationResult<DeleteExerciseMutation>;
export type DeleteExerciseMutationOptions = Apollo.BaseMutationOptions<
  DeleteExerciseMutation,
  DeleteExerciseMutationVariables
>;
export const UpdateFeedbackDocument = gql`
  mutation updateFeedback($feedbackData: UpdateFeedbackInput!) {
    updateFeedback(feedbackData: $feedbackData) {
      id
    }
  }
`;
export type UpdateFeedbackMutationFn = Apollo.MutationFunction<
  UpdateFeedbackMutation,
  UpdateFeedbackMutationVariables
>;

/**
 * __useUpdateFeedbackMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackMutation, { data, loading, error }] = useUpdateFeedbackMutation({
 *   variables: {
 *      feedbackData: // value for 'feedbackData'
 *   },
 * });
 */
export function useUpdateFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFeedbackMutation,
    UpdateFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFeedbackMutation,
    UpdateFeedbackMutationVariables
  >(UpdateFeedbackDocument, options);
}
export type UpdateFeedbackMutationHookResult = ReturnType<
  typeof useUpdateFeedbackMutation
>;
export type UpdateFeedbackMutationResult =
  Apollo.MutationResult<UpdateFeedbackMutation>;
export type UpdateFeedbackMutationOptions = Apollo.BaseMutationOptions<
  UpdateFeedbackMutation,
  UpdateFeedbackMutationVariables
>;
export const CreateFeedbackDocument = gql`
  mutation createFeedback($text: String!, $files: [Upload]) {
    createFeedback(text: $text, files: $files) {
      text
      files {
        file
        name
      }
    }
  }
`;
export type CreateFeedbackMutationFn = Apollo.MutationFunction<
  CreateFeedbackMutation,
  CreateFeedbackMutationVariables
>;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      text: // value for 'text'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useCreateFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFeedbackMutation,
    CreateFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFeedbackMutation,
    CreateFeedbackMutationVariables
  >(CreateFeedbackDocument, options);
}
export type CreateFeedbackMutationHookResult = ReturnType<
  typeof useCreateFeedbackMutation
>;
export type CreateFeedbackMutationResult =
  Apollo.MutationResult<CreateFeedbackMutation>;
export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<
  CreateFeedbackMutation,
  CreateFeedbackMutationVariables
>;
export const CreateFeedbackCommentDocument = gql`
  mutation createFeedbackComment(
    $text: String!
    $feedbackId: String!
    $files: [Upload]
  ) {
    createFeedbackComment(text: $text, feedbackId: $feedbackId, files: $files) {
      text
      id
      files {
        id
        file
        name
      }
    }
  }
`;
export type CreateFeedbackCommentMutationFn = Apollo.MutationFunction<
  CreateFeedbackCommentMutation,
  CreateFeedbackCommentMutationVariables
>;

/**
 * __useCreateFeedbackCommentMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackCommentMutation, { data, loading, error }] = useCreateFeedbackCommentMutation({
 *   variables: {
 *      text: // value for 'text'
 *      feedbackId: // value for 'feedbackId'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useCreateFeedbackCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFeedbackCommentMutation,
    CreateFeedbackCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFeedbackCommentMutation,
    CreateFeedbackCommentMutationVariables
  >(CreateFeedbackCommentDocument, options);
}
export type CreateFeedbackCommentMutationHookResult = ReturnType<
  typeof useCreateFeedbackCommentMutation
>;
export type CreateFeedbackCommentMutationResult =
  Apollo.MutationResult<CreateFeedbackCommentMutation>;
export type CreateFeedbackCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateFeedbackCommentMutation,
  CreateFeedbackCommentMutationVariables
>;
export const UpdateFeedbackCommentDocument = gql`
  mutation updateFeedbackComment($id: String!, $text: String) {
    updateFeedbackComment(id: $id, text: $text) {
      id
      text
      files {
        file
        name
      }
    }
  }
`;
export type UpdateFeedbackCommentMutationFn = Apollo.MutationFunction<
  UpdateFeedbackCommentMutation,
  UpdateFeedbackCommentMutationVariables
>;

/**
 * __useUpdateFeedbackCommentMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackCommentMutation, { data, loading, error }] = useUpdateFeedbackCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateFeedbackCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFeedbackCommentMutation,
    UpdateFeedbackCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFeedbackCommentMutation,
    UpdateFeedbackCommentMutationVariables
  >(UpdateFeedbackCommentDocument, options);
}
export type UpdateFeedbackCommentMutationHookResult = ReturnType<
  typeof useUpdateFeedbackCommentMutation
>;
export type UpdateFeedbackCommentMutationResult =
  Apollo.MutationResult<UpdateFeedbackCommentMutation>;
export type UpdateFeedbackCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateFeedbackCommentMutation,
  UpdateFeedbackCommentMutationVariables
>;
export const DeleteFeedbackCommentDocument = gql`
  mutation deleteFeedbackComment($feedbackCommentId: String!) {
    deleteFeedbackComment(feedbackCommentId: $feedbackCommentId)
  }
`;
export type DeleteFeedbackCommentMutationFn = Apollo.MutationFunction<
  DeleteFeedbackCommentMutation,
  DeleteFeedbackCommentMutationVariables
>;

/**
 * __useDeleteFeedbackCommentMutation__
 *
 * To run a mutation, you first call `useDeleteFeedbackCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedbackCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedbackCommentMutation, { data, loading, error }] = useDeleteFeedbackCommentMutation({
 *   variables: {
 *      feedbackCommentId: // value for 'feedbackCommentId'
 *   },
 * });
 */
export function useDeleteFeedbackCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFeedbackCommentMutation,
    DeleteFeedbackCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFeedbackCommentMutation,
    DeleteFeedbackCommentMutationVariables
  >(DeleteFeedbackCommentDocument, options);
}
export type DeleteFeedbackCommentMutationHookResult = ReturnType<
  typeof useDeleteFeedbackCommentMutation
>;
export type DeleteFeedbackCommentMutationResult =
  Apollo.MutationResult<DeleteFeedbackCommentMutation>;
export type DeleteFeedbackCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteFeedbackCommentMutation,
  DeleteFeedbackCommentMutationVariables
>;
export const DeleteFeedbackDocument = gql`
  mutation deleteFeedback($feedbackId: String!) {
    deleteFeedback(feedbackId: $feedbackId)
  }
`;
export type DeleteFeedbackMutationFn = Apollo.MutationFunction<
  DeleteFeedbackMutation,
  DeleteFeedbackMutationVariables
>;

/**
 * __useDeleteFeedbackMutation__
 *
 * To run a mutation, you first call `useDeleteFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedbackMutation, { data, loading, error }] = useDeleteFeedbackMutation({
 *   variables: {
 *      feedbackId: // value for 'feedbackId'
 *   },
 * });
 */
export function useDeleteFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFeedbackMutation,
    DeleteFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFeedbackMutation,
    DeleteFeedbackMutationVariables
  >(DeleteFeedbackDocument, options);
}
export type DeleteFeedbackMutationHookResult = ReturnType<
  typeof useDeleteFeedbackMutation
>;
export type DeleteFeedbackMutationResult =
  Apollo.MutationResult<DeleteFeedbackMutation>;
export type DeleteFeedbackMutationOptions = Apollo.BaseMutationOptions<
  DeleteFeedbackMutation,
  DeleteFeedbackMutationVariables
>;
export const CreateRobotsHistoryLinkDocument = gql`
  mutation CreateRobotsHistoryLink($input: CreateRobotsHistoryInput!) {
    createRobotsHistoryLink(input: $input) {
      id
      link
      order
    }
  }
`;
export type CreateRobotsHistoryLinkMutationFn = Apollo.MutationFunction<
  CreateRobotsHistoryLinkMutation,
  CreateRobotsHistoryLinkMutationVariables
>;

/**
 * __useCreateRobotsHistoryLinkMutation__
 *
 * To run a mutation, you first call `useCreateRobotsHistoryLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRobotsHistoryLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRobotsHistoryLinkMutation, { data, loading, error }] = useCreateRobotsHistoryLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRobotsHistoryLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRobotsHistoryLinkMutation,
    CreateRobotsHistoryLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRobotsHistoryLinkMutation,
    CreateRobotsHistoryLinkMutationVariables
  >(CreateRobotsHistoryLinkDocument, options);
}
export type CreateRobotsHistoryLinkMutationHookResult = ReturnType<
  typeof useCreateRobotsHistoryLinkMutation
>;
export type CreateRobotsHistoryLinkMutationResult =
  Apollo.MutationResult<CreateRobotsHistoryLinkMutation>;
export type CreateRobotsHistoryLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateRobotsHistoryLinkMutation,
  CreateRobotsHistoryLinkMutationVariables
>;
export const DeleteRobotsHistoryDocument = gql`
  mutation DeleteRobotsHistory($deleteRobotsHistoryId: UUID!) {
    deleteRobotsHistory(id: $deleteRobotsHistoryId)
  }
`;
export type DeleteRobotsHistoryMutationFn = Apollo.MutationFunction<
  DeleteRobotsHistoryMutation,
  DeleteRobotsHistoryMutationVariables
>;

/**
 * __useDeleteRobotsHistoryMutation__
 *
 * To run a mutation, you first call `useDeleteRobotsHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRobotsHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRobotsHistoryMutation, { data, loading, error }] = useDeleteRobotsHistoryMutation({
 *   variables: {
 *      deleteRobotsHistoryId: // value for 'deleteRobotsHistoryId'
 *   },
 * });
 */
export function useDeleteRobotsHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRobotsHistoryMutation,
    DeleteRobotsHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRobotsHistoryMutation,
    DeleteRobotsHistoryMutationVariables
  >(DeleteRobotsHistoryDocument, options);
}
export type DeleteRobotsHistoryMutationHookResult = ReturnType<
  typeof useDeleteRobotsHistoryMutation
>;
export type DeleteRobotsHistoryMutationResult =
  Apollo.MutationResult<DeleteRobotsHistoryMutation>;
export type DeleteRobotsHistoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteRobotsHistoryMutation,
  DeleteRobotsHistoryMutationVariables
>;
export const CreateAwardDocument = gql`
  mutation createAward($input: CreateAwardInput!) {
    createAward(input: $input) {
      id
    }
  }
`;
export type CreateAwardMutationFn = Apollo.MutationFunction<
  CreateAwardMutation,
  CreateAwardMutationVariables
>;

/**
 * __useCreateAwardMutation__
 *
 * To run a mutation, you first call `useCreateAwardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAwardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAwardMutation, { data, loading, error }] = useCreateAwardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAwardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAwardMutation,
    CreateAwardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAwardMutation, CreateAwardMutationVariables>(
    CreateAwardDocument,
    options
  );
}
export type CreateAwardMutationHookResult = ReturnType<
  typeof useCreateAwardMutation
>;
export type CreateAwardMutationResult =
  Apollo.MutationResult<CreateAwardMutation>;
export type CreateAwardMutationOptions = Apollo.BaseMutationOptions<
  CreateAwardMutation,
  CreateAwardMutationVariables
>;
export const UpdateAwardDocument = gql`
  mutation updateAward($input: UpdateAwardInput!) {
    updateAward(input: $input) {
      id
    }
  }
`;
export type UpdateAwardMutationFn = Apollo.MutationFunction<
  UpdateAwardMutation,
  UpdateAwardMutationVariables
>;

/**
 * __useUpdateAwardMutation__
 *
 * To run a mutation, you first call `useUpdateAwardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAwardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAwardMutation, { data, loading, error }] = useUpdateAwardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAwardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAwardMutation,
    UpdateAwardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAwardMutation, UpdateAwardMutationVariables>(
    UpdateAwardDocument,
    options
  );
}
export type UpdateAwardMutationHookResult = ReturnType<
  typeof useUpdateAwardMutation
>;
export type UpdateAwardMutationResult =
  Apollo.MutationResult<UpdateAwardMutation>;
export type UpdateAwardMutationOptions = Apollo.BaseMutationOptions<
  UpdateAwardMutation,
  UpdateAwardMutationVariables
>;
export const DeleteAwardDocument = gql`
  mutation deleteAward($deleteAwardId: UUID!) {
    deleteAward(id: $deleteAwardId)
  }
`;
export type DeleteAwardMutationFn = Apollo.MutationFunction<
  DeleteAwardMutation,
  DeleteAwardMutationVariables
>;

/**
 * __useDeleteAwardMutation__
 *
 * To run a mutation, you first call `useDeleteAwardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAwardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAwardMutation, { data, loading, error }] = useDeleteAwardMutation({
 *   variables: {
 *      deleteAwardId: // value for 'deleteAwardId'
 *   },
 * });
 */
export function useDeleteAwardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAwardMutation,
    DeleteAwardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAwardMutation, DeleteAwardMutationVariables>(
    DeleteAwardDocument,
    options
  );
}
export type DeleteAwardMutationHookResult = ReturnType<
  typeof useDeleteAwardMutation
>;
export type DeleteAwardMutationResult =
  Apollo.MutationResult<DeleteAwardMutation>;
export type DeleteAwardMutationOptions = Apollo.BaseMutationOptions<
  DeleteAwardMutation,
  DeleteAwardMutationVariables
>;
export const PublishAwardsDocument = gql`
  mutation publishAwards($ids: [UUID!]!) {
    publishAwards(ids: $ids)
  }
`;
export type PublishAwardsMutationFn = Apollo.MutationFunction<
  PublishAwardsMutation,
  PublishAwardsMutationVariables
>;

/**
 * __usePublishAwardsMutation__
 *
 * To run a mutation, you first call `usePublishAwardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishAwardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishAwardsMutation, { data, loading, error }] = usePublishAwardsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePublishAwardsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishAwardsMutation,
    PublishAwardsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishAwardsMutation,
    PublishAwardsMutationVariables
  >(PublishAwardsDocument, options);
}
export type PublishAwardsMutationHookResult = ReturnType<
  typeof usePublishAwardsMutation
>;
export type PublishAwardsMutationResult =
  Apollo.MutationResult<PublishAwardsMutation>;
export type PublishAwardsMutationOptions = Apollo.BaseMutationOptions<
  PublishAwardsMutation,
  PublishAwardsMutationVariables
>;
export const UnpublishAwardsDocument = gql`
  mutation unpublishAwards($ids: [UUID!]!) {
    unpublishAwards(ids: $ids)
  }
`;
export type UnpublishAwardsMutationFn = Apollo.MutationFunction<
  UnpublishAwardsMutation,
  UnpublishAwardsMutationVariables
>;

/**
 * __useUnpublishAwardsMutation__
 *
 * To run a mutation, you first call `useUnpublishAwardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishAwardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishAwardsMutation, { data, loading, error }] = useUnpublishAwardsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUnpublishAwardsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnpublishAwardsMutation,
    UnpublishAwardsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnpublishAwardsMutation,
    UnpublishAwardsMutationVariables
  >(UnpublishAwardsDocument, options);
}
export type UnpublishAwardsMutationHookResult = ReturnType<
  typeof useUnpublishAwardsMutation
>;
export type UnpublishAwardsMutationResult =
  Apollo.MutationResult<UnpublishAwardsMutation>;
export type UnpublishAwardsMutationOptions = Apollo.BaseMutationOptions<
  UnpublishAwardsMutation,
  UnpublishAwardsMutationVariables
>;
export const ReorderAwardsDocument = gql`
  mutation reorderAwards($input: [AwardReorderInput!]!) {
    reorderAwards(input: $input)
  }
`;
export type ReorderAwardsMutationFn = Apollo.MutationFunction<
  ReorderAwardsMutation,
  ReorderAwardsMutationVariables
>;

/**
 * __useReorderAwardsMutation__
 *
 * To run a mutation, you first call `useReorderAwardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderAwardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderAwardsMutation, { data, loading, error }] = useReorderAwardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderAwardsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderAwardsMutation,
    ReorderAwardsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReorderAwardsMutation,
    ReorderAwardsMutationVariables
  >(ReorderAwardsDocument, options);
}
export type ReorderAwardsMutationHookResult = ReturnType<
  typeof useReorderAwardsMutation
>;
export type ReorderAwardsMutationResult =
  Apollo.MutationResult<ReorderAwardsMutation>;
export type ReorderAwardsMutationOptions = Apollo.BaseMutationOptions<
  ReorderAwardsMutation,
  ReorderAwardsMutationVariables
>;
export const CreateGameModelDocument = gql`
  mutation createGameModel($input: GameModelInput!) {
    createGameModel(input: $input) {
      id
    }
  }
`;
export type CreateGameModelMutationFn = Apollo.MutationFunction<
  CreateGameModelMutation,
  CreateGameModelMutationVariables
>;

/**
 * __useCreateGameModelMutation__
 *
 * To run a mutation, you first call `useCreateGameModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameModelMutation, { data, loading, error }] = useCreateGameModelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGameModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGameModelMutation,
    CreateGameModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateGameModelMutation,
    CreateGameModelMutationVariables
  >(CreateGameModelDocument, options);
}
export type CreateGameModelMutationHookResult = ReturnType<
  typeof useCreateGameModelMutation
>;
export type CreateGameModelMutationResult =
  Apollo.MutationResult<CreateGameModelMutation>;
export type CreateGameModelMutationOptions = Apollo.BaseMutationOptions<
  CreateGameModelMutation,
  CreateGameModelMutationVariables
>;
export const DeleteGameModelDocument = gql`
  mutation DeleteGameModel($deleteGameModelId: UUID!) {
    deleteGameModel(id: $deleteGameModelId)
  }
`;
export type DeleteGameModelMutationFn = Apollo.MutationFunction<
  DeleteGameModelMutation,
  DeleteGameModelMutationVariables
>;

/**
 * __useDeleteGameModelMutation__
 *
 * To run a mutation, you first call `useDeleteGameModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGameModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGameModelMutation, { data, loading, error }] = useDeleteGameModelMutation({
 *   variables: {
 *      deleteGameModelId: // value for 'deleteGameModelId'
 *   },
 * });
 */
export function useDeleteGameModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGameModelMutation,
    DeleteGameModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGameModelMutation,
    DeleteGameModelMutationVariables
  >(DeleteGameModelDocument, options);
}
export type DeleteGameModelMutationHookResult = ReturnType<
  typeof useDeleteGameModelMutation
>;
export type DeleteGameModelMutationResult =
  Apollo.MutationResult<DeleteGameModelMutation>;
export type DeleteGameModelMutationOptions = Apollo.BaseMutationOptions<
  DeleteGameModelMutation,
  DeleteGameModelMutationVariables
>;
export const UpdateGameModelDocument = gql`
  mutation updateGameModel($updateGameModelId: UUID!, $input: GameModelInput!) {
    updateGameModel(id: $updateGameModelId, input: $input) {
      id
    }
  }
`;
export type UpdateGameModelMutationFn = Apollo.MutationFunction<
  UpdateGameModelMutation,
  UpdateGameModelMutationVariables
>;

/**
 * __useUpdateGameModelMutation__
 *
 * To run a mutation, you first call `useUpdateGameModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameModelMutation, { data, loading, error }] = useUpdateGameModelMutation({
 *   variables: {
 *      updateGameModelId: // value for 'updateGameModelId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGameModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGameModelMutation,
    UpdateGameModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGameModelMutation,
    UpdateGameModelMutationVariables
  >(UpdateGameModelDocument, options);
}
export type UpdateGameModelMutationHookResult = ReturnType<
  typeof useUpdateGameModelMutation
>;
export type UpdateGameModelMutationResult =
  Apollo.MutationResult<UpdateGameModelMutation>;
export type UpdateGameModelMutationOptions = Apollo.BaseMutationOptions<
  UpdateGameModelMutation,
  UpdateGameModelMutationVariables
>;
export const CreateGameModelStateDocument = gql`
  mutation createGameModelState($input: GameModelStateInput!) {
    createGameModelState(input: $input) {
      id
    }
  }
`;
export type CreateGameModelStateMutationFn = Apollo.MutationFunction<
  CreateGameModelStateMutation,
  CreateGameModelStateMutationVariables
>;

/**
 * __useCreateGameModelStateMutation__
 *
 * To run a mutation, you first call `useCreateGameModelStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameModelStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameModelStateMutation, { data, loading, error }] = useCreateGameModelStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGameModelStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGameModelStateMutation,
    CreateGameModelStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateGameModelStateMutation,
    CreateGameModelStateMutationVariables
  >(CreateGameModelStateDocument, options);
}
export type CreateGameModelStateMutationHookResult = ReturnType<
  typeof useCreateGameModelStateMutation
>;
export type CreateGameModelStateMutationResult =
  Apollo.MutationResult<CreateGameModelStateMutation>;
export type CreateGameModelStateMutationOptions = Apollo.BaseMutationOptions<
  CreateGameModelStateMutation,
  CreateGameModelStateMutationVariables
>;
export const DeleteGameModelStateDocument = gql`
  mutation deleteGameModelState($deleteGameModelStateId: UUID!) {
    deleteGameModelState(id: $deleteGameModelStateId)
  }
`;
export type DeleteGameModelStateMutationFn = Apollo.MutationFunction<
  DeleteGameModelStateMutation,
  DeleteGameModelStateMutationVariables
>;

/**
 * __useDeleteGameModelStateMutation__
 *
 * To run a mutation, you first call `useDeleteGameModelStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGameModelStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGameModelStateMutation, { data, loading, error }] = useDeleteGameModelStateMutation({
 *   variables: {
 *      deleteGameModelStateId: // value for 'deleteGameModelStateId'
 *   },
 * });
 */
export function useDeleteGameModelStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGameModelStateMutation,
    DeleteGameModelStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGameModelStateMutation,
    DeleteGameModelStateMutationVariables
  >(DeleteGameModelStateDocument, options);
}
export type DeleteGameModelStateMutationHookResult = ReturnType<
  typeof useDeleteGameModelStateMutation
>;
export type DeleteGameModelStateMutationResult =
  Apollo.MutationResult<DeleteGameModelStateMutation>;
export type DeleteGameModelStateMutationOptions = Apollo.BaseMutationOptions<
  DeleteGameModelStateMutation,
  DeleteGameModelStateMutationVariables
>;
export const UpdateGameModelStateDocument = gql`
  mutation updateGameModelState(
    $updateGameModelStateId: UUID!
    $input: GameModelStateInput!
  ) {
    updateGameModelState(id: $updateGameModelStateId, input: $input) {
      id
    }
  }
`;
export type UpdateGameModelStateMutationFn = Apollo.MutationFunction<
  UpdateGameModelStateMutation,
  UpdateGameModelStateMutationVariables
>;

/**
 * __useUpdateGameModelStateMutation__
 *
 * To run a mutation, you first call `useUpdateGameModelStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameModelStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameModelStateMutation, { data, loading, error }] = useUpdateGameModelStateMutation({
 *   variables: {
 *      updateGameModelStateId: // value for 'updateGameModelStateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGameModelStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGameModelStateMutation,
    UpdateGameModelStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGameModelStateMutation,
    UpdateGameModelStateMutationVariables
  >(UpdateGameModelStateDocument, options);
}
export type UpdateGameModelStateMutationHookResult = ReturnType<
  typeof useUpdateGameModelStateMutation
>;
export type UpdateGameModelStateMutationResult =
  Apollo.MutationResult<UpdateGameModelStateMutation>;
export type UpdateGameModelStateMutationOptions = Apollo.BaseMutationOptions<
  UpdateGameModelStateMutation,
  UpdateGameModelStateMutationVariables
>;
export const UpdateGameModelStateFileDocument = gql`
  mutation updateGameModelStateFile(
    $updateGameModelStateFileId: UUID!
    $file: Upload!
    $fileType: GameModelStateFileTypeEnum!
  ) {
    updateGameModelStateFile(
      id: $updateGameModelStateFileId
      file: $file
      fileType: $fileType
    )
  }
`;
export type UpdateGameModelStateFileMutationFn = Apollo.MutationFunction<
  UpdateGameModelStateFileMutation,
  UpdateGameModelStateFileMutationVariables
>;

/**
 * __useUpdateGameModelStateFileMutation__
 *
 * To run a mutation, you first call `useUpdateGameModelStateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameModelStateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameModelStateFileMutation, { data, loading, error }] = useUpdateGameModelStateFileMutation({
 *   variables: {
 *      updateGameModelStateFileId: // value for 'updateGameModelStateFileId'
 *      file: // value for 'file'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useUpdateGameModelStateFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGameModelStateFileMutation,
    UpdateGameModelStateFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGameModelStateFileMutation,
    UpdateGameModelStateFileMutationVariables
  >(UpdateGameModelStateFileDocument, options);
}
export type UpdateGameModelStateFileMutationHookResult = ReturnType<
  typeof useUpdateGameModelStateFileMutation
>;
export type UpdateGameModelStateFileMutationResult =
  Apollo.MutationResult<UpdateGameModelStateFileMutation>;
export type UpdateGameModelStateFileMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateGameModelStateFileMutation,
    UpdateGameModelStateFileMutationVariables
  >;
export const DeleteGameModelStateFileDocument = gql`
  mutation deleteGameModelStateFile(
    $deleteGameModelStateFileId: UUID!
    $fileType: GameModelStateFileTypeEnum!
  ) {
    deleteGameModelStateFile(
      id: $deleteGameModelStateFileId
      fileType: $fileType
    )
  }
`;
export type DeleteGameModelStateFileMutationFn = Apollo.MutationFunction<
  DeleteGameModelStateFileMutation,
  DeleteGameModelStateFileMutationVariables
>;

/**
 * __useDeleteGameModelStateFileMutation__
 *
 * To run a mutation, you first call `useDeleteGameModelStateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGameModelStateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGameModelStateFileMutation, { data, loading, error }] = useDeleteGameModelStateFileMutation({
 *   variables: {
 *      deleteGameModelStateFileId: // value for 'deleteGameModelStateFileId'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useDeleteGameModelStateFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGameModelStateFileMutation,
    DeleteGameModelStateFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGameModelStateFileMutation,
    DeleteGameModelStateFileMutationVariables
  >(DeleteGameModelStateFileDocument, options);
}
export type DeleteGameModelStateFileMutationHookResult = ReturnType<
  typeof useDeleteGameModelStateFileMutation
>;
export type DeleteGameModelStateFileMutationResult =
  Apollo.MutationResult<DeleteGameModelStateFileMutation>;
export type DeleteGameModelStateFileMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteGameModelStateFileMutation,
    DeleteGameModelStateFileMutationVariables
  >;
export const UpdateRewardDocument = gql`
  mutation updateReward($updateRewardId: UUID!, $input: RewardInput!) {
    updateReward(id: $updateRewardId, input: $input) {
      id
      name
      order
      type
    }
  }
`;
export type UpdateRewardMutationFn = Apollo.MutationFunction<
  UpdateRewardMutation,
  UpdateRewardMutationVariables
>;

/**
 * __useUpdateRewardMutation__
 *
 * To run a mutation, you first call `useUpdateRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRewardMutation, { data, loading, error }] = useUpdateRewardMutation({
 *   variables: {
 *      updateRewardId: // value for 'updateRewardId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRewardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRewardMutation,
    UpdateRewardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRewardMutation,
    UpdateRewardMutationVariables
  >(UpdateRewardDocument, options);
}
export type UpdateRewardMutationHookResult = ReturnType<
  typeof useUpdateRewardMutation
>;
export type UpdateRewardMutationResult =
  Apollo.MutationResult<UpdateRewardMutation>;
export type UpdateRewardMutationOptions = Apollo.BaseMutationOptions<
  UpdateRewardMutation,
  UpdateRewardMutationVariables
>;
export const CreateGameModelArtifactDocument = gql`
  mutation createGameModelArtifact($input: GameModelArtifactInput!) {
    createGameModelArtifact(input: $input) {
      id
    }
  }
`;
export type CreateGameModelArtifactMutationFn = Apollo.MutationFunction<
  CreateGameModelArtifactMutation,
  CreateGameModelArtifactMutationVariables
>;

/**
 * __useCreateGameModelArtifactMutation__
 *
 * To run a mutation, you first call `useCreateGameModelArtifactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameModelArtifactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameModelArtifactMutation, { data, loading, error }] = useCreateGameModelArtifactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGameModelArtifactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGameModelArtifactMutation,
    CreateGameModelArtifactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateGameModelArtifactMutation,
    CreateGameModelArtifactMutationVariables
  >(CreateGameModelArtifactDocument, options);
}
export type CreateGameModelArtifactMutationHookResult = ReturnType<
  typeof useCreateGameModelArtifactMutation
>;
export type CreateGameModelArtifactMutationResult =
  Apollo.MutationResult<CreateGameModelArtifactMutation>;
export type CreateGameModelArtifactMutationOptions = Apollo.BaseMutationOptions<
  CreateGameModelArtifactMutation,
  CreateGameModelArtifactMutationVariables
>;
export const DeleteGameModelArtifactDocument = gql`
  mutation deleteGameModelArtifact($deleteGameModelArtifactId: UUID!) {
    deleteGameModelArtifact(id: $deleteGameModelArtifactId)
  }
`;
export type DeleteGameModelArtifactMutationFn = Apollo.MutationFunction<
  DeleteGameModelArtifactMutation,
  DeleteGameModelArtifactMutationVariables
>;

/**
 * __useDeleteGameModelArtifactMutation__
 *
 * To run a mutation, you first call `useDeleteGameModelArtifactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGameModelArtifactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGameModelArtifactMutation, { data, loading, error }] = useDeleteGameModelArtifactMutation({
 *   variables: {
 *      deleteGameModelArtifactId: // value for 'deleteGameModelArtifactId'
 *   },
 * });
 */
export function useDeleteGameModelArtifactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGameModelArtifactMutation,
    DeleteGameModelArtifactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGameModelArtifactMutation,
    DeleteGameModelArtifactMutationVariables
  >(DeleteGameModelArtifactDocument, options);
}
export type DeleteGameModelArtifactMutationHookResult = ReturnType<
  typeof useDeleteGameModelArtifactMutation
>;
export type DeleteGameModelArtifactMutationResult =
  Apollo.MutationResult<DeleteGameModelArtifactMutation>;
export type DeleteGameModelArtifactMutationOptions = Apollo.BaseMutationOptions<
  DeleteGameModelArtifactMutation,
  DeleteGameModelArtifactMutationVariables
>;
export const UpdateGameModelArtifactDocument = gql`
  mutation updateGameModelArtifact(
    $updateGameModelArtifactId: UUID!
    $input: GameModelArtifactInput!
  ) {
    updateGameModelArtifact(id: $updateGameModelArtifactId, input: $input) {
      id
    }
  }
`;
export type UpdateGameModelArtifactMutationFn = Apollo.MutationFunction<
  UpdateGameModelArtifactMutation,
  UpdateGameModelArtifactMutationVariables
>;

/**
 * __useUpdateGameModelArtifactMutation__
 *
 * To run a mutation, you first call `useUpdateGameModelArtifactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameModelArtifactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameModelArtifactMutation, { data, loading, error }] = useUpdateGameModelArtifactMutation({
 *   variables: {
 *      updateGameModelArtifactId: // value for 'updateGameModelArtifactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGameModelArtifactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGameModelArtifactMutation,
    UpdateGameModelArtifactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGameModelArtifactMutation,
    UpdateGameModelArtifactMutationVariables
  >(UpdateGameModelArtifactDocument, options);
}
export type UpdateGameModelArtifactMutationHookResult = ReturnType<
  typeof useUpdateGameModelArtifactMutation
>;
export type UpdateGameModelArtifactMutationResult =
  Apollo.MutationResult<UpdateGameModelArtifactMutation>;
export type UpdateGameModelArtifactMutationOptions = Apollo.BaseMutationOptions<
  UpdateGameModelArtifactMutation,
  UpdateGameModelArtifactMutationVariables
>;
export const UpdateGameModelArtifactFileDocument = gql`
  mutation updateGameModelArtifactFile(
    $updateGameModelArtifactFileId: UUID!
    $file: Upload!
    $fileType: GameModelArtifactFileTypeEnum!
  ) {
    updateGameModelArtifactFile(
      id: $updateGameModelArtifactFileId
      file: $file
      fileType: $fileType
    )
  }
`;
export type UpdateGameModelArtifactFileMutationFn = Apollo.MutationFunction<
  UpdateGameModelArtifactFileMutation,
  UpdateGameModelArtifactFileMutationVariables
>;

/**
 * __useUpdateGameModelArtifactFileMutation__
 *
 * To run a mutation, you first call `useUpdateGameModelArtifactFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameModelArtifactFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameModelArtifactFileMutation, { data, loading, error }] = useUpdateGameModelArtifactFileMutation({
 *   variables: {
 *      updateGameModelArtifactFileId: // value for 'updateGameModelArtifactFileId'
 *      file: // value for 'file'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useUpdateGameModelArtifactFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGameModelArtifactFileMutation,
    UpdateGameModelArtifactFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGameModelArtifactFileMutation,
    UpdateGameModelArtifactFileMutationVariables
  >(UpdateGameModelArtifactFileDocument, options);
}
export type UpdateGameModelArtifactFileMutationHookResult = ReturnType<
  typeof useUpdateGameModelArtifactFileMutation
>;
export type UpdateGameModelArtifactFileMutationResult =
  Apollo.MutationResult<UpdateGameModelArtifactFileMutation>;
export type UpdateGameModelArtifactFileMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateGameModelArtifactFileMutation,
    UpdateGameModelArtifactFileMutationVariables
  >;
export const DeleteGameModelArtifactFileDocument = gql`
  mutation deleteGameModelArtifactFile(
    $deleteGameModelArtifactFileId: UUID!
    $fileType: GameModelArtifactFileTypeEnum!
  ) {
    deleteGameModelArtifactFile(
      id: $deleteGameModelArtifactFileId
      fileType: $fileType
    )
  }
`;
export type DeleteGameModelArtifactFileMutationFn = Apollo.MutationFunction<
  DeleteGameModelArtifactFileMutation,
  DeleteGameModelArtifactFileMutationVariables
>;

/**
 * __useDeleteGameModelArtifactFileMutation__
 *
 * To run a mutation, you first call `useDeleteGameModelArtifactFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGameModelArtifactFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGameModelArtifactFileMutation, { data, loading, error }] = useDeleteGameModelArtifactFileMutation({
 *   variables: {
 *      deleteGameModelArtifactFileId: // value for 'deleteGameModelArtifactFileId'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useDeleteGameModelArtifactFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGameModelArtifactFileMutation,
    DeleteGameModelArtifactFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGameModelArtifactFileMutation,
    DeleteGameModelArtifactFileMutationVariables
  >(DeleteGameModelArtifactFileDocument, options);
}
export type DeleteGameModelArtifactFileMutationHookResult = ReturnType<
  typeof useDeleteGameModelArtifactFileMutation
>;
export type DeleteGameModelArtifactFileMutationResult =
  Apollo.MutationResult<DeleteGameModelArtifactFileMutation>;
export type DeleteGameModelArtifactFileMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteGameModelArtifactFileMutation,
    DeleteGameModelArtifactFileMutationVariables
  >;
export const CreateUserJobDocument = gql`
  mutation createUserJob($name: String!) {
    createUserJob(name: $name) {
      id
      name
    }
  }
`;
export type CreateUserJobMutationFn = Apollo.MutationFunction<
  CreateUserJobMutation,
  CreateUserJobMutationVariables
>;

/**
 * __useCreateUserJobMutation__
 *
 * To run a mutation, you first call `useCreateUserJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserJobMutation, { data, loading, error }] = useCreateUserJobMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateUserJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserJobMutation,
    CreateUserJobMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserJobMutation,
    CreateUserJobMutationVariables
  >(CreateUserJobDocument, options);
}
export type CreateUserJobMutationHookResult = ReturnType<
  typeof useCreateUserJobMutation
>;
export type CreateUserJobMutationResult =
  Apollo.MutationResult<CreateUserJobMutation>;
export type CreateUserJobMutationOptions = Apollo.BaseMutationOptions<
  CreateUserJobMutation,
  CreateUserJobMutationVariables
>;
export const UpdateUserJobDocument = gql`
  mutation updateUserJob($userJobId: String!, $name: String!) {
    updateUserJob(userJobId: $userJobId, name: $name) {
      id
      name
    }
  }
`;
export type UpdateUserJobMutationFn = Apollo.MutationFunction<
  UpdateUserJobMutation,
  UpdateUserJobMutationVariables
>;

/**
 * __useUpdateUserJobMutation__
 *
 * To run a mutation, you first call `useUpdateUserJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserJobMutation, { data, loading, error }] = useUpdateUserJobMutation({
 *   variables: {
 *      userJobId: // value for 'userJobId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUserJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserJobMutation,
    UpdateUserJobMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserJobMutation,
    UpdateUserJobMutationVariables
  >(UpdateUserJobDocument, options);
}
export type UpdateUserJobMutationHookResult = ReturnType<
  typeof useUpdateUserJobMutation
>;
export type UpdateUserJobMutationResult =
  Apollo.MutationResult<UpdateUserJobMutation>;
export type UpdateUserJobMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserJobMutation,
  UpdateUserJobMutationVariables
>;
export const DeleteUserJobDocument = gql`
  mutation deleteUserJob($userJobId: String!) {
    deleteUserJob(userJobId: $userJobId)
  }
`;
export type DeleteUserJobMutationFn = Apollo.MutationFunction<
  DeleteUserJobMutation,
  DeleteUserJobMutationVariables
>;

/**
 * __useDeleteUserJobMutation__
 *
 * To run a mutation, you first call `useDeleteUserJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserJobMutation, { data, loading, error }] = useDeleteUserJobMutation({
 *   variables: {
 *      userJobId: // value for 'userJobId'
 *   },
 * });
 */
export function useDeleteUserJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserJobMutation,
    DeleteUserJobMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserJobMutation,
    DeleteUserJobMutationVariables
  >(DeleteUserJobDocument, options);
}
export type DeleteUserJobMutationHookResult = ReturnType<
  typeof useDeleteUserJobMutation
>;
export type DeleteUserJobMutationResult =
  Apollo.MutationResult<DeleteUserJobMutation>;
export type DeleteUserJobMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserJobMutation,
  DeleteUserJobMutationVariables
>;
export const CreateProjectKanbanDocument = gql`
  mutation createProjectKanban($projectId: String!, $name: String!) {
    createProjectKanban(projectId: $projectId, name: $name) {
      id
    }
  }
`;
export type CreateProjectKanbanMutationFn = Apollo.MutationFunction<
  CreateProjectKanbanMutation,
  CreateProjectKanbanMutationVariables
>;

/**
 * __useCreateProjectKanbanMutation__
 *
 * To run a mutation, you first call `useCreateProjectKanbanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectKanbanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectKanbanMutation, { data, loading, error }] = useCreateProjectKanbanMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProjectKanbanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectKanbanMutation,
    CreateProjectKanbanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectKanbanMutation,
    CreateProjectKanbanMutationVariables
  >(CreateProjectKanbanDocument, options);
}
export type CreateProjectKanbanMutationHookResult = ReturnType<
  typeof useCreateProjectKanbanMutation
>;
export type CreateProjectKanbanMutationResult =
  Apollo.MutationResult<CreateProjectKanbanMutation>;
export type CreateProjectKanbanMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectKanbanMutation,
  CreateProjectKanbanMutationVariables
>;
export const RestartSprintDocument = gql`
  mutation restartSprint($id: String!, $name: String!) {
    restartSprint(id: $id, name: $name) {
      name
      projectId
    }
  }
`;
export type RestartSprintMutationFn = Apollo.MutationFunction<
  RestartSprintMutation,
  RestartSprintMutationVariables
>;

/**
 * __useRestartSprintMutation__
 *
 * To run a mutation, you first call `useRestartSprintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartSprintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartSprintMutation, { data, loading, error }] = useRestartSprintMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRestartSprintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartSprintMutation,
    RestartSprintMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestartSprintMutation,
    RestartSprintMutationVariables
  >(RestartSprintDocument, options);
}
export type RestartSprintMutationHookResult = ReturnType<
  typeof useRestartSprintMutation
>;
export type RestartSprintMutationResult =
  Apollo.MutationResult<RestartSprintMutation>;
export type RestartSprintMutationOptions = Apollo.BaseMutationOptions<
  RestartSprintMutation,
  RestartSprintMutationVariables
>;
export const DeleteProjectKanbanDocument = gql`
  mutation deleteProjectKanban($id: String!) {
    deleteProjectKanban(id: $id)
  }
`;
export type DeleteProjectKanbanMutationFn = Apollo.MutationFunction<
  DeleteProjectKanbanMutation,
  DeleteProjectKanbanMutationVariables
>;

/**
 * __useDeleteProjectKanbanMutation__
 *
 * To run a mutation, you first call `useDeleteProjectKanbanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectKanbanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectKanbanMutation, { data, loading, error }] = useDeleteProjectKanbanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectKanbanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectKanbanMutation,
    DeleteProjectKanbanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectKanbanMutation,
    DeleteProjectKanbanMutationVariables
  >(DeleteProjectKanbanDocument, options);
}
export type DeleteProjectKanbanMutationHookResult = ReturnType<
  typeof useDeleteProjectKanbanMutation
>;
export type DeleteProjectKanbanMutationResult =
  Apollo.MutationResult<DeleteProjectKanbanMutation>;
export type DeleteProjectKanbanMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectKanbanMutation,
  DeleteProjectKanbanMutationVariables
>;
export const ClearProjectKanbanDocument = gql`
  mutation clearProjectKanban($id: String!) {
    clearProjectKanban(id: $id)
  }
`;
export type ClearProjectKanbanMutationFn = Apollo.MutationFunction<
  ClearProjectKanbanMutation,
  ClearProjectKanbanMutationVariables
>;

/**
 * __useClearProjectKanbanMutation__
 *
 * To run a mutation, you first call `useClearProjectKanbanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearProjectKanbanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearProjectKanbanMutation, { data, loading, error }] = useClearProjectKanbanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClearProjectKanbanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearProjectKanbanMutation,
    ClearProjectKanbanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClearProjectKanbanMutation,
    ClearProjectKanbanMutationVariables
  >(ClearProjectKanbanDocument, options);
}
export type ClearProjectKanbanMutationHookResult = ReturnType<
  typeof useClearProjectKanbanMutation
>;
export type ClearProjectKanbanMutationResult =
  Apollo.MutationResult<ClearProjectKanbanMutation>;
export type ClearProjectKanbanMutationOptions = Apollo.BaseMutationOptions<
  ClearProjectKanbanMutation,
  ClearProjectKanbanMutationVariables
>;
export const CreateKanbanWorkflowStepForProjectDocument = gql`
  mutation createKanbanWorkflowStepForProject(
    $name: String!
    $taskStatusId: String!
    $projectId: String!
    $order: Int!
  ) {
    createKanbanWorkflowStepForProject(
      name: $name
      taskStatusId: $taskStatusId
      projectId: $projectId
      order: $order
    ) {
      id
    }
  }
`;
export type CreateKanbanWorkflowStepForProjectMutationFn =
  Apollo.MutationFunction<
    CreateKanbanWorkflowStepForProjectMutation,
    CreateKanbanWorkflowStepForProjectMutationVariables
  >;

/**
 * __useCreateKanbanWorkflowStepForProjectMutation__
 *
 * To run a mutation, you first call `useCreateKanbanWorkflowStepForProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKanbanWorkflowStepForProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKanbanWorkflowStepForProjectMutation, { data, loading, error }] = useCreateKanbanWorkflowStepForProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      taskStatusId: // value for 'taskStatusId'
 *      projectId: // value for 'projectId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateKanbanWorkflowStepForProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKanbanWorkflowStepForProjectMutation,
    CreateKanbanWorkflowStepForProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateKanbanWorkflowStepForProjectMutation,
    CreateKanbanWorkflowStepForProjectMutationVariables
  >(CreateKanbanWorkflowStepForProjectDocument, options);
}
export type CreateKanbanWorkflowStepForProjectMutationHookResult = ReturnType<
  typeof useCreateKanbanWorkflowStepForProjectMutation
>;
export type CreateKanbanWorkflowStepForProjectMutationResult =
  Apollo.MutationResult<CreateKanbanWorkflowStepForProjectMutation>;
export type CreateKanbanWorkflowStepForProjectMutationOptions =
  Apollo.BaseMutationOptions<
    CreateKanbanWorkflowStepForProjectMutation,
    CreateKanbanWorkflowStepForProjectMutationVariables
  >;
export const UpdateKanbanWorkflowStepDocument = gql`
  mutation updateKanbanWorkflowStep(
    $id: String!
    $name: String!
    $order: Int!
  ) {
    updateKanbanWorkflowStep(id: $id, name: $name, order: $order) {
      id
    }
  }
`;
export type UpdateKanbanWorkflowStepMutationFn = Apollo.MutationFunction<
  UpdateKanbanWorkflowStepMutation,
  UpdateKanbanWorkflowStepMutationVariables
>;

/**
 * __useUpdateKanbanWorkflowStepMutation__
 *
 * To run a mutation, you first call `useUpdateKanbanWorkflowStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKanbanWorkflowStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKanbanWorkflowStepMutation, { data, loading, error }] = useUpdateKanbanWorkflowStepMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateKanbanWorkflowStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateKanbanWorkflowStepMutation,
    UpdateKanbanWorkflowStepMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateKanbanWorkflowStepMutation,
    UpdateKanbanWorkflowStepMutationVariables
  >(UpdateKanbanWorkflowStepDocument, options);
}
export type UpdateKanbanWorkflowStepMutationHookResult = ReturnType<
  typeof useUpdateKanbanWorkflowStepMutation
>;
export type UpdateKanbanWorkflowStepMutationResult =
  Apollo.MutationResult<UpdateKanbanWorkflowStepMutation>;
export type UpdateKanbanWorkflowStepMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateKanbanWorkflowStepMutation,
    UpdateKanbanWorkflowStepMutationVariables
  >;
export const DeleteKanbanWorkflowStepDocument = gql`
  mutation deleteKanbanWorkflowStep($id: String!) {
    deleteKanbanWorkflowStep(id: $id)
  }
`;
export type DeleteKanbanWorkflowStepMutationFn = Apollo.MutationFunction<
  DeleteKanbanWorkflowStepMutation,
  DeleteKanbanWorkflowStepMutationVariables
>;

/**
 * __useDeleteKanbanWorkflowStepMutation__
 *
 * To run a mutation, you first call `useDeleteKanbanWorkflowStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKanbanWorkflowStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKanbanWorkflowStepMutation, { data, loading, error }] = useDeleteKanbanWorkflowStepMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKanbanWorkflowStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteKanbanWorkflowStepMutation,
    DeleteKanbanWorkflowStepMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteKanbanWorkflowStepMutation,
    DeleteKanbanWorkflowStepMutationVariables
  >(DeleteKanbanWorkflowStepDocument, options);
}
export type DeleteKanbanWorkflowStepMutationHookResult = ReturnType<
  typeof useDeleteKanbanWorkflowStepMutation
>;
export type DeleteKanbanWorkflowStepMutationResult =
  Apollo.MutationResult<DeleteKanbanWorkflowStepMutation>;
export type DeleteKanbanWorkflowStepMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteKanbanWorkflowStepMutation,
    DeleteKanbanWorkflowStepMutationVariables
  >;
export const CreateKnowledgeBaseBlockDocument = gql`
  mutation createKnowledgeBaseBlock(
    $type: KnowledgeBaseBlockTypeEnum!
    $themeId: String!
    $blockTypeData: blockTypeInput
  ) {
    createKnowledgeBaseBlock(
      type: $type
      themeId: $themeId
      blockTypeData: $blockTypeData
    ) {
      id
    }
  }
`;
export type CreateKnowledgeBaseBlockMutationFn = Apollo.MutationFunction<
  CreateKnowledgeBaseBlockMutation,
  CreateKnowledgeBaseBlockMutationVariables
>;

/**
 * __useCreateKnowledgeBaseBlockMutation__
 *
 * To run a mutation, you first call `useCreateKnowledgeBaseBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKnowledgeBaseBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKnowledgeBaseBlockMutation, { data, loading, error }] = useCreateKnowledgeBaseBlockMutation({
 *   variables: {
 *      type: // value for 'type'
 *      themeId: // value for 'themeId'
 *      blockTypeData: // value for 'blockTypeData'
 *   },
 * });
 */
export function useCreateKnowledgeBaseBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKnowledgeBaseBlockMutation,
    CreateKnowledgeBaseBlockMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateKnowledgeBaseBlockMutation,
    CreateKnowledgeBaseBlockMutationVariables
  >(CreateKnowledgeBaseBlockDocument, options);
}
export type CreateKnowledgeBaseBlockMutationHookResult = ReturnType<
  typeof useCreateKnowledgeBaseBlockMutation
>;
export type CreateKnowledgeBaseBlockMutationResult =
  Apollo.MutationResult<CreateKnowledgeBaseBlockMutation>;
export type CreateKnowledgeBaseBlockMutationOptions =
  Apollo.BaseMutationOptions<
    CreateKnowledgeBaseBlockMutation,
    CreateKnowledgeBaseBlockMutationVariables
  >;
export const UpdateKnowledgeBaseBlockDocument = gql`
  mutation updateKnowledgeBaseBlock(
    $id: String!
    $blockTypeData: blockTypeInput
  ) {
    updateKnowledgeBaseBlock(id: $id, blockTypeData: $blockTypeData) {
      id
    }
  }
`;
export type UpdateKnowledgeBaseBlockMutationFn = Apollo.MutationFunction<
  UpdateKnowledgeBaseBlockMutation,
  UpdateKnowledgeBaseBlockMutationVariables
>;

/**
 * __useUpdateKnowledgeBaseBlockMutation__
 *
 * To run a mutation, you first call `useUpdateKnowledgeBaseBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKnowledgeBaseBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKnowledgeBaseBlockMutation, { data, loading, error }] = useUpdateKnowledgeBaseBlockMutation({
 *   variables: {
 *      id: // value for 'id'
 *      blockTypeData: // value for 'blockTypeData'
 *   },
 * });
 */
export function useUpdateKnowledgeBaseBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateKnowledgeBaseBlockMutation,
    UpdateKnowledgeBaseBlockMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateKnowledgeBaseBlockMutation,
    UpdateKnowledgeBaseBlockMutationVariables
  >(UpdateKnowledgeBaseBlockDocument, options);
}
export type UpdateKnowledgeBaseBlockMutationHookResult = ReturnType<
  typeof useUpdateKnowledgeBaseBlockMutation
>;
export type UpdateKnowledgeBaseBlockMutationResult =
  Apollo.MutationResult<UpdateKnowledgeBaseBlockMutation>;
export type UpdateKnowledgeBaseBlockMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateKnowledgeBaseBlockMutation,
    UpdateKnowledgeBaseBlockMutationVariables
  >;
export const DeleteKnowledgeBaseBlockDocument = gql`
  mutation deleteKnowledgeBaseBlock($id: String!) {
    deleteKnowledgeBaseBlock(id: $id)
  }
`;
export type DeleteKnowledgeBaseBlockMutationFn = Apollo.MutationFunction<
  DeleteKnowledgeBaseBlockMutation,
  DeleteKnowledgeBaseBlockMutationVariables
>;

/**
 * __useDeleteKnowledgeBaseBlockMutation__
 *
 * To run a mutation, you first call `useDeleteKnowledgeBaseBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKnowledgeBaseBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKnowledgeBaseBlockMutation, { data, loading, error }] = useDeleteKnowledgeBaseBlockMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKnowledgeBaseBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteKnowledgeBaseBlockMutation,
    DeleteKnowledgeBaseBlockMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteKnowledgeBaseBlockMutation,
    DeleteKnowledgeBaseBlockMutationVariables
  >(DeleteKnowledgeBaseBlockDocument, options);
}
export type DeleteKnowledgeBaseBlockMutationHookResult = ReturnType<
  typeof useDeleteKnowledgeBaseBlockMutation
>;
export type DeleteKnowledgeBaseBlockMutationResult =
  Apollo.MutationResult<DeleteKnowledgeBaseBlockMutation>;
export type DeleteKnowledgeBaseBlockMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteKnowledgeBaseBlockMutation,
    DeleteKnowledgeBaseBlockMutationVariables
  >;
export const UploudKnowledgeBaseBlockPhotoDocument = gql`
  mutation uploudKnowledgeBaseBlockPhoto($blockId: String!, $photo: Upload!) {
    uploudKnowledgeBaseBlockPhoto(blockId: $blockId, photo: $photo) {
      id
    }
  }
`;
export type UploudKnowledgeBaseBlockPhotoMutationFn = Apollo.MutationFunction<
  UploudKnowledgeBaseBlockPhotoMutation,
  UploudKnowledgeBaseBlockPhotoMutationVariables
>;

/**
 * __useUploudKnowledgeBaseBlockPhotoMutation__
 *
 * To run a mutation, you first call `useUploudKnowledgeBaseBlockPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploudKnowledgeBaseBlockPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploudKnowledgeBaseBlockPhotoMutation, { data, loading, error }] = useUploudKnowledgeBaseBlockPhotoMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useUploudKnowledgeBaseBlockPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploudKnowledgeBaseBlockPhotoMutation,
    UploudKnowledgeBaseBlockPhotoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploudKnowledgeBaseBlockPhotoMutation,
    UploudKnowledgeBaseBlockPhotoMutationVariables
  >(UploudKnowledgeBaseBlockPhotoDocument, options);
}
export type UploudKnowledgeBaseBlockPhotoMutationHookResult = ReturnType<
  typeof useUploudKnowledgeBaseBlockPhotoMutation
>;
export type UploudKnowledgeBaseBlockPhotoMutationResult =
  Apollo.MutationResult<UploudKnowledgeBaseBlockPhotoMutation>;
export type UploudKnowledgeBaseBlockPhotoMutationOptions =
  Apollo.BaseMutationOptions<
    UploudKnowledgeBaseBlockPhotoMutation,
    UploudKnowledgeBaseBlockPhotoMutationVariables
  >;
export const HandleKnowledgeBaseBlockEditorPhotoDocument = gql`
  mutation handleKnowledgeBaseBlockEditorPhoto(
    $blockId: String!
    $photo: Upload
  ) {
    handleKnowledgeBaseBlockEditorPhoto(blockId: $blockId, photo: $photo) {
      id
      photo
    }
  }
`;
export type HandleKnowledgeBaseBlockEditorPhotoMutationFn =
  Apollo.MutationFunction<
    HandleKnowledgeBaseBlockEditorPhotoMutation,
    HandleKnowledgeBaseBlockEditorPhotoMutationVariables
  >;

/**
 * __useHandleKnowledgeBaseBlockEditorPhotoMutation__
 *
 * To run a mutation, you first call `useHandleKnowledgeBaseBlockEditorPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandleKnowledgeBaseBlockEditorPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handleKnowledgeBaseBlockEditorPhotoMutation, { data, loading, error }] = useHandleKnowledgeBaseBlockEditorPhotoMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useHandleKnowledgeBaseBlockEditorPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HandleKnowledgeBaseBlockEditorPhotoMutation,
    HandleKnowledgeBaseBlockEditorPhotoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HandleKnowledgeBaseBlockEditorPhotoMutation,
    HandleKnowledgeBaseBlockEditorPhotoMutationVariables
  >(HandleKnowledgeBaseBlockEditorPhotoDocument, options);
}
export type HandleKnowledgeBaseBlockEditorPhotoMutationHookResult = ReturnType<
  typeof useHandleKnowledgeBaseBlockEditorPhotoMutation
>;
export type HandleKnowledgeBaseBlockEditorPhotoMutationResult =
  Apollo.MutationResult<HandleKnowledgeBaseBlockEditorPhotoMutation>;
export type HandleKnowledgeBaseBlockEditorPhotoMutationOptions =
  Apollo.BaseMutationOptions<
    HandleKnowledgeBaseBlockEditorPhotoMutation,
    HandleKnowledgeBaseBlockEditorPhotoMutationVariables
  >;
export const CreateKnowledgeBaseBlockListItemDocument = gql`
  mutation createKnowledgeBaseBlockListItem(
    $blockId: String!
    $content: String!
    $isNumbered: Boolean
    $photo: Upload
    $video: String
    $photoTitle: String
  ) {
    createKnowledgeBaseBlockListItem(
      blockId: $blockId
      content: $content
      isNumbered: $isNumbered
      photo: $photo
      video: $video
      photoTitle: $photoTitle
    ) {
      id
    }
  }
`;
export type CreateKnowledgeBaseBlockListItemMutationFn =
  Apollo.MutationFunction<
    CreateKnowledgeBaseBlockListItemMutation,
    CreateKnowledgeBaseBlockListItemMutationVariables
  >;

/**
 * __useCreateKnowledgeBaseBlockListItemMutation__
 *
 * To run a mutation, you first call `useCreateKnowledgeBaseBlockListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKnowledgeBaseBlockListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKnowledgeBaseBlockListItemMutation, { data, loading, error }] = useCreateKnowledgeBaseBlockListItemMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      content: // value for 'content'
 *      isNumbered: // value for 'isNumbered'
 *      photo: // value for 'photo'
 *      video: // value for 'video'
 *      photoTitle: // value for 'photoTitle'
 *   },
 * });
 */
export function useCreateKnowledgeBaseBlockListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKnowledgeBaseBlockListItemMutation,
    CreateKnowledgeBaseBlockListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateKnowledgeBaseBlockListItemMutation,
    CreateKnowledgeBaseBlockListItemMutationVariables
  >(CreateKnowledgeBaseBlockListItemDocument, options);
}
export type CreateKnowledgeBaseBlockListItemMutationHookResult = ReturnType<
  typeof useCreateKnowledgeBaseBlockListItemMutation
>;
export type CreateKnowledgeBaseBlockListItemMutationResult =
  Apollo.MutationResult<CreateKnowledgeBaseBlockListItemMutation>;
export type CreateKnowledgeBaseBlockListItemMutationOptions =
  Apollo.BaseMutationOptions<
    CreateKnowledgeBaseBlockListItemMutation,
    CreateKnowledgeBaseBlockListItemMutationVariables
  >;
export const UpdateKnowledgeBaseBlockListItemDocument = gql`
  mutation updateKnowledgeBaseBlockListItem(
    $listItemId: String!
    $content: String!
    $isNumbered: Boolean
    $photo: Upload
    $video: String
    $photoTitle: String
  ) {
    updateKnowledgeBaseBlockListItem(
      listItemId: $listItemId
      content: $content
      isNumbered: $isNumbered
      photo: $photo
      video: $video
      photoTitle: $photoTitle
    ) {
      id
    }
  }
`;
export type UpdateKnowledgeBaseBlockListItemMutationFn =
  Apollo.MutationFunction<
    UpdateKnowledgeBaseBlockListItemMutation,
    UpdateKnowledgeBaseBlockListItemMutationVariables
  >;

/**
 * __useUpdateKnowledgeBaseBlockListItemMutation__
 *
 * To run a mutation, you first call `useUpdateKnowledgeBaseBlockListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKnowledgeBaseBlockListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKnowledgeBaseBlockListItemMutation, { data, loading, error }] = useUpdateKnowledgeBaseBlockListItemMutation({
 *   variables: {
 *      listItemId: // value for 'listItemId'
 *      content: // value for 'content'
 *      isNumbered: // value for 'isNumbered'
 *      photo: // value for 'photo'
 *      video: // value for 'video'
 *      photoTitle: // value for 'photoTitle'
 *   },
 * });
 */
export function useUpdateKnowledgeBaseBlockListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateKnowledgeBaseBlockListItemMutation,
    UpdateKnowledgeBaseBlockListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateKnowledgeBaseBlockListItemMutation,
    UpdateKnowledgeBaseBlockListItemMutationVariables
  >(UpdateKnowledgeBaseBlockListItemDocument, options);
}
export type UpdateKnowledgeBaseBlockListItemMutationHookResult = ReturnType<
  typeof useUpdateKnowledgeBaseBlockListItemMutation
>;
export type UpdateKnowledgeBaseBlockListItemMutationResult =
  Apollo.MutationResult<UpdateKnowledgeBaseBlockListItemMutation>;
export type UpdateKnowledgeBaseBlockListItemMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateKnowledgeBaseBlockListItemMutation,
    UpdateKnowledgeBaseBlockListItemMutationVariables
  >;
export const DeleteKnowledgeBaseBlockListItemDocument = gql`
  mutation deleteKnowledgeBaseBlockListItem($id: String!) {
    deleteKnowledgeBaseBlockListItem(id: $id)
  }
`;
export type DeleteKnowledgeBaseBlockListItemMutationFn =
  Apollo.MutationFunction<
    DeleteKnowledgeBaseBlockListItemMutation,
    DeleteKnowledgeBaseBlockListItemMutationVariables
  >;

/**
 * __useDeleteKnowledgeBaseBlockListItemMutation__
 *
 * To run a mutation, you first call `useDeleteKnowledgeBaseBlockListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKnowledgeBaseBlockListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKnowledgeBaseBlockListItemMutation, { data, loading, error }] = useDeleteKnowledgeBaseBlockListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKnowledgeBaseBlockListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteKnowledgeBaseBlockListItemMutation,
    DeleteKnowledgeBaseBlockListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteKnowledgeBaseBlockListItemMutation,
    DeleteKnowledgeBaseBlockListItemMutationVariables
  >(DeleteKnowledgeBaseBlockListItemDocument, options);
}
export type DeleteKnowledgeBaseBlockListItemMutationHookResult = ReturnType<
  typeof useDeleteKnowledgeBaseBlockListItemMutation
>;
export type DeleteKnowledgeBaseBlockListItemMutationResult =
  Apollo.MutationResult<DeleteKnowledgeBaseBlockListItemMutation>;
export type DeleteKnowledgeBaseBlockListItemMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteKnowledgeBaseBlockListItemMutation,
    DeleteKnowledgeBaseBlockListItemMutationVariables
  >;
export const CreateKnowledgeBaseCategoryDocument = gql`
  mutation createKnowledgeBaseCategory(
    $name: String!
    $isFor: KnowledgeBaseCategoryIsForEnum!
    $photo: Upload
    $backgroundColor: String
  ) {
    createKnowledgeBaseCategory(
      name: $name
      isFor: $isFor
      photo: $photo
      backgroundColor: $backgroundColor
    ) {
      id
    }
  }
`;
export type CreateKnowledgeBaseCategoryMutationFn = Apollo.MutationFunction<
  CreateKnowledgeBaseCategoryMutation,
  CreateKnowledgeBaseCategoryMutationVariables
>;

/**
 * __useCreateKnowledgeBaseCategoryMutation__
 *
 * To run a mutation, you first call `useCreateKnowledgeBaseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKnowledgeBaseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKnowledgeBaseCategoryMutation, { data, loading, error }] = useCreateKnowledgeBaseCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      isFor: // value for 'isFor'
 *      photo: // value for 'photo'
 *      backgroundColor: // value for 'backgroundColor'
 *   },
 * });
 */
export function useCreateKnowledgeBaseCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKnowledgeBaseCategoryMutation,
    CreateKnowledgeBaseCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateKnowledgeBaseCategoryMutation,
    CreateKnowledgeBaseCategoryMutationVariables
  >(CreateKnowledgeBaseCategoryDocument, options);
}
export type CreateKnowledgeBaseCategoryMutationHookResult = ReturnType<
  typeof useCreateKnowledgeBaseCategoryMutation
>;
export type CreateKnowledgeBaseCategoryMutationResult =
  Apollo.MutationResult<CreateKnowledgeBaseCategoryMutation>;
export type CreateKnowledgeBaseCategoryMutationOptions =
  Apollo.BaseMutationOptions<
    CreateKnowledgeBaseCategoryMutation,
    CreateKnowledgeBaseCategoryMutationVariables
  >;
export const UpdateKnowledgeBaseCategoryDocument = gql`
  mutation updateKnowledgeBaseCategory(
    $id: String!
    $name: String
    $isFor: KnowledgeBaseCategoryIsForEnum!
    $photo: Upload
    $backgroundColor: String
  ) {
    updateKnowledgeBaseCategory(
      id: $id
      name: $name
      isFor: $isFor
      photo: $photo
      backgroundColor: $backgroundColor
    ) {
      id
    }
  }
`;
export type UpdateKnowledgeBaseCategoryMutationFn = Apollo.MutationFunction<
  UpdateKnowledgeBaseCategoryMutation,
  UpdateKnowledgeBaseCategoryMutationVariables
>;

/**
 * __useUpdateKnowledgeBaseCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateKnowledgeBaseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKnowledgeBaseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKnowledgeBaseCategoryMutation, { data, loading, error }] = useUpdateKnowledgeBaseCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      isFor: // value for 'isFor'
 *      photo: // value for 'photo'
 *      backgroundColor: // value for 'backgroundColor'
 *   },
 * });
 */
export function useUpdateKnowledgeBaseCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateKnowledgeBaseCategoryMutation,
    UpdateKnowledgeBaseCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateKnowledgeBaseCategoryMutation,
    UpdateKnowledgeBaseCategoryMutationVariables
  >(UpdateKnowledgeBaseCategoryDocument, options);
}
export type UpdateKnowledgeBaseCategoryMutationHookResult = ReturnType<
  typeof useUpdateKnowledgeBaseCategoryMutation
>;
export type UpdateKnowledgeBaseCategoryMutationResult =
  Apollo.MutationResult<UpdateKnowledgeBaseCategoryMutation>;
export type UpdateKnowledgeBaseCategoryMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateKnowledgeBaseCategoryMutation,
    UpdateKnowledgeBaseCategoryMutationVariables
  >;
export const DeleteKnowledgeBaseCategoryDocument = gql`
  mutation deleteKnowledgeBaseCategory($id: String!) {
    deleteKnowledgeBaseCategory(id: $id)
  }
`;
export type DeleteKnowledgeBaseCategoryMutationFn = Apollo.MutationFunction<
  DeleteKnowledgeBaseCategoryMutation,
  DeleteKnowledgeBaseCategoryMutationVariables
>;

/**
 * __useDeleteKnowledgeBaseCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteKnowledgeBaseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKnowledgeBaseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKnowledgeBaseCategoryMutation, { data, loading, error }] = useDeleteKnowledgeBaseCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKnowledgeBaseCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteKnowledgeBaseCategoryMutation,
    DeleteKnowledgeBaseCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteKnowledgeBaseCategoryMutation,
    DeleteKnowledgeBaseCategoryMutationVariables
  >(DeleteKnowledgeBaseCategoryDocument, options);
}
export type DeleteKnowledgeBaseCategoryMutationHookResult = ReturnType<
  typeof useDeleteKnowledgeBaseCategoryMutation
>;
export type DeleteKnowledgeBaseCategoryMutationResult =
  Apollo.MutationResult<DeleteKnowledgeBaseCategoryMutation>;
export type DeleteKnowledgeBaseCategoryMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteKnowledgeBaseCategoryMutation,
    DeleteKnowledgeBaseCategoryMutationVariables
  >;
export const SetKnowledgeBaseCategoryParticipantsDocument = gql`
  mutation setKnowledgeBaseCategoryParticipants(
    $knowledgeBaseCategoryData: SetKnowledgeBaseCategoryParticipantsInput!
  ) {
    setKnowledgeBaseCategoryParticipants(
      knowledgeBaseCategoryData: $knowledgeBaseCategoryData
    )
  }
`;
export type SetKnowledgeBaseCategoryParticipantsMutationFn =
  Apollo.MutationFunction<
    SetKnowledgeBaseCategoryParticipantsMutation,
    SetKnowledgeBaseCategoryParticipantsMutationVariables
  >;

/**
 * __useSetKnowledgeBaseCategoryParticipantsMutation__
 *
 * To run a mutation, you first call `useSetKnowledgeBaseCategoryParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetKnowledgeBaseCategoryParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setKnowledgeBaseCategoryParticipantsMutation, { data, loading, error }] = useSetKnowledgeBaseCategoryParticipantsMutation({
 *   variables: {
 *      knowledgeBaseCategoryData: // value for 'knowledgeBaseCategoryData'
 *   },
 * });
 */
export function useSetKnowledgeBaseCategoryParticipantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetKnowledgeBaseCategoryParticipantsMutation,
    SetKnowledgeBaseCategoryParticipantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetKnowledgeBaseCategoryParticipantsMutation,
    SetKnowledgeBaseCategoryParticipantsMutationVariables
  >(SetKnowledgeBaseCategoryParticipantsDocument, options);
}
export type SetKnowledgeBaseCategoryParticipantsMutationHookResult = ReturnType<
  typeof useSetKnowledgeBaseCategoryParticipantsMutation
>;
export type SetKnowledgeBaseCategoryParticipantsMutationResult =
  Apollo.MutationResult<SetKnowledgeBaseCategoryParticipantsMutation>;
export type SetKnowledgeBaseCategoryParticipantsMutationOptions =
  Apollo.BaseMutationOptions<
    SetKnowledgeBaseCategoryParticipantsMutation,
    SetKnowledgeBaseCategoryParticipantsMutationVariables
  >;
export const CreateKnowledgeBaseBlockTestAnswerDocument = gql`
  mutation createKnowledgeBaseBlockTestAnswer(
    $blockId: String!
    $content: String!
    $isAnswerTrue: Boolean!
  ) {
    createKnowledgeBaseBlockTestAnswer(
      blockId: $blockId
      content: $content
      isAnswerTrue: $isAnswerTrue
    ) {
      id
    }
  }
`;
export type CreateKnowledgeBaseBlockTestAnswerMutationFn =
  Apollo.MutationFunction<
    CreateKnowledgeBaseBlockTestAnswerMutation,
    CreateKnowledgeBaseBlockTestAnswerMutationVariables
  >;

/**
 * __useCreateKnowledgeBaseBlockTestAnswerMutation__
 *
 * To run a mutation, you first call `useCreateKnowledgeBaseBlockTestAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKnowledgeBaseBlockTestAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKnowledgeBaseBlockTestAnswerMutation, { data, loading, error }] = useCreateKnowledgeBaseBlockTestAnswerMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      content: // value for 'content'
 *      isAnswerTrue: // value for 'isAnswerTrue'
 *   },
 * });
 */
export function useCreateKnowledgeBaseBlockTestAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKnowledgeBaseBlockTestAnswerMutation,
    CreateKnowledgeBaseBlockTestAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateKnowledgeBaseBlockTestAnswerMutation,
    CreateKnowledgeBaseBlockTestAnswerMutationVariables
  >(CreateKnowledgeBaseBlockTestAnswerDocument, options);
}
export type CreateKnowledgeBaseBlockTestAnswerMutationHookResult = ReturnType<
  typeof useCreateKnowledgeBaseBlockTestAnswerMutation
>;
export type CreateKnowledgeBaseBlockTestAnswerMutationResult =
  Apollo.MutationResult<CreateKnowledgeBaseBlockTestAnswerMutation>;
export type CreateKnowledgeBaseBlockTestAnswerMutationOptions =
  Apollo.BaseMutationOptions<
    CreateKnowledgeBaseBlockTestAnswerMutation,
    CreateKnowledgeBaseBlockTestAnswerMutationVariables
  >;
export const UpdateKnowledgeBaseBlockTestAnswerDocument = gql`
  mutation updateKnowledgeBaseBlockTestAnswer(
    $answerId: String!
    $content: String!
    $isAnswerTrue: Boolean!
  ) {
    updateKnowledgeBaseBlockTestAnswer(
      answerId: $answerId
      content: $content
      isAnswerTrue: $isAnswerTrue
    ) {
      id
    }
  }
`;
export type UpdateKnowledgeBaseBlockTestAnswerMutationFn =
  Apollo.MutationFunction<
    UpdateKnowledgeBaseBlockTestAnswerMutation,
    UpdateKnowledgeBaseBlockTestAnswerMutationVariables
  >;

/**
 * __useUpdateKnowledgeBaseBlockTestAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateKnowledgeBaseBlockTestAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKnowledgeBaseBlockTestAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKnowledgeBaseBlockTestAnswerMutation, { data, loading, error }] = useUpdateKnowledgeBaseBlockTestAnswerMutation({
 *   variables: {
 *      answerId: // value for 'answerId'
 *      content: // value for 'content'
 *      isAnswerTrue: // value for 'isAnswerTrue'
 *   },
 * });
 */
export function useUpdateKnowledgeBaseBlockTestAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateKnowledgeBaseBlockTestAnswerMutation,
    UpdateKnowledgeBaseBlockTestAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateKnowledgeBaseBlockTestAnswerMutation,
    UpdateKnowledgeBaseBlockTestAnswerMutationVariables
  >(UpdateKnowledgeBaseBlockTestAnswerDocument, options);
}
export type UpdateKnowledgeBaseBlockTestAnswerMutationHookResult = ReturnType<
  typeof useUpdateKnowledgeBaseBlockTestAnswerMutation
>;
export type UpdateKnowledgeBaseBlockTestAnswerMutationResult =
  Apollo.MutationResult<UpdateKnowledgeBaseBlockTestAnswerMutation>;
export type UpdateKnowledgeBaseBlockTestAnswerMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateKnowledgeBaseBlockTestAnswerMutation,
    UpdateKnowledgeBaseBlockTestAnswerMutationVariables
  >;
export const DeleteKnowledgeBaseBlockTestAnswerDocument = gql`
  mutation deleteKnowledgeBaseBlockTestAnswer($id: String!) {
    deleteKnowledgeBaseBlockTestAnswer(id: $id)
  }
`;
export type DeleteKnowledgeBaseBlockTestAnswerMutationFn =
  Apollo.MutationFunction<
    DeleteKnowledgeBaseBlockTestAnswerMutation,
    DeleteKnowledgeBaseBlockTestAnswerMutationVariables
  >;

/**
 * __useDeleteKnowledgeBaseBlockTestAnswerMutation__
 *
 * To run a mutation, you first call `useDeleteKnowledgeBaseBlockTestAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKnowledgeBaseBlockTestAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKnowledgeBaseBlockTestAnswerMutation, { data, loading, error }] = useDeleteKnowledgeBaseBlockTestAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKnowledgeBaseBlockTestAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteKnowledgeBaseBlockTestAnswerMutation,
    DeleteKnowledgeBaseBlockTestAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteKnowledgeBaseBlockTestAnswerMutation,
    DeleteKnowledgeBaseBlockTestAnswerMutationVariables
  >(DeleteKnowledgeBaseBlockTestAnswerDocument, options);
}
export type DeleteKnowledgeBaseBlockTestAnswerMutationHookResult = ReturnType<
  typeof useDeleteKnowledgeBaseBlockTestAnswerMutation
>;
export type DeleteKnowledgeBaseBlockTestAnswerMutationResult =
  Apollo.MutationResult<DeleteKnowledgeBaseBlockTestAnswerMutation>;
export type DeleteKnowledgeBaseBlockTestAnswerMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteKnowledgeBaseBlockTestAnswerMutation,
    DeleteKnowledgeBaseBlockTestAnswerMutationVariables
  >;
export const CreateKnowledgeBaseThemeDocument = gql`
  mutation createKnowledgeBaseTheme($name: String!, $categoryId: String!) {
    createKnowledgeBaseTheme(name: $name, categoryId: $categoryId) {
      id
    }
  }
`;
export type CreateKnowledgeBaseThemeMutationFn = Apollo.MutationFunction<
  CreateKnowledgeBaseThemeMutation,
  CreateKnowledgeBaseThemeMutationVariables
>;

/**
 * __useCreateKnowledgeBaseThemeMutation__
 *
 * To run a mutation, you first call `useCreateKnowledgeBaseThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKnowledgeBaseThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKnowledgeBaseThemeMutation, { data, loading, error }] = useCreateKnowledgeBaseThemeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useCreateKnowledgeBaseThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKnowledgeBaseThemeMutation,
    CreateKnowledgeBaseThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateKnowledgeBaseThemeMutation,
    CreateKnowledgeBaseThemeMutationVariables
  >(CreateKnowledgeBaseThemeDocument, options);
}
export type CreateKnowledgeBaseThemeMutationHookResult = ReturnType<
  typeof useCreateKnowledgeBaseThemeMutation
>;
export type CreateKnowledgeBaseThemeMutationResult =
  Apollo.MutationResult<CreateKnowledgeBaseThemeMutation>;
export type CreateKnowledgeBaseThemeMutationOptions =
  Apollo.BaseMutationOptions<
    CreateKnowledgeBaseThemeMutation,
    CreateKnowledgeBaseThemeMutationVariables
  >;
export const UpdateKnowledgeBaseThemeDocument = gql`
  mutation updateKnowledgeBaseTheme(
    $id: String!
    $name: String
    $categoryId: String!
  ) {
    updateKnowledgeBaseTheme(id: $id, name: $name, categoryId: $categoryId) {
      id
    }
  }
`;
export type UpdateKnowledgeBaseThemeMutationFn = Apollo.MutationFunction<
  UpdateKnowledgeBaseThemeMutation,
  UpdateKnowledgeBaseThemeMutationVariables
>;

/**
 * __useUpdateKnowledgeBaseThemeMutation__
 *
 * To run a mutation, you first call `useUpdateKnowledgeBaseThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKnowledgeBaseThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKnowledgeBaseThemeMutation, { data, loading, error }] = useUpdateKnowledgeBaseThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useUpdateKnowledgeBaseThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateKnowledgeBaseThemeMutation,
    UpdateKnowledgeBaseThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateKnowledgeBaseThemeMutation,
    UpdateKnowledgeBaseThemeMutationVariables
  >(UpdateKnowledgeBaseThemeDocument, options);
}
export type UpdateKnowledgeBaseThemeMutationHookResult = ReturnType<
  typeof useUpdateKnowledgeBaseThemeMutation
>;
export type UpdateKnowledgeBaseThemeMutationResult =
  Apollo.MutationResult<UpdateKnowledgeBaseThemeMutation>;
export type UpdateKnowledgeBaseThemeMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateKnowledgeBaseThemeMutation,
    UpdateKnowledgeBaseThemeMutationVariables
  >;
export const DeleteKnowledgeBaseThemeDocument = gql`
  mutation deleteKnowledgeBaseTheme($id: String!) {
    deleteKnowledgeBaseTheme(id: $id)
  }
`;
export type DeleteKnowledgeBaseThemeMutationFn = Apollo.MutationFunction<
  DeleteKnowledgeBaseThemeMutation,
  DeleteKnowledgeBaseThemeMutationVariables
>;

/**
 * __useDeleteKnowledgeBaseThemeMutation__
 *
 * To run a mutation, you first call `useDeleteKnowledgeBaseThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKnowledgeBaseThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKnowledgeBaseThemeMutation, { data, loading, error }] = useDeleteKnowledgeBaseThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKnowledgeBaseThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteKnowledgeBaseThemeMutation,
    DeleteKnowledgeBaseThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteKnowledgeBaseThemeMutation,
    DeleteKnowledgeBaseThemeMutationVariables
  >(DeleteKnowledgeBaseThemeDocument, options);
}
export type DeleteKnowledgeBaseThemeMutationHookResult = ReturnType<
  typeof useDeleteKnowledgeBaseThemeMutation
>;
export type DeleteKnowledgeBaseThemeMutationResult =
  Apollo.MutationResult<DeleteKnowledgeBaseThemeMutation>;
export type DeleteKnowledgeBaseThemeMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteKnowledgeBaseThemeMutation,
    DeleteKnowledgeBaseThemeMutationVariables
  >;
export const PublishKnowledgeBaseThemeDocument = gql`
  mutation publishKnowledgeBaseTheme($id: String!) {
    publishKnowledgeBaseTheme(id: $id)
  }
`;
export type PublishKnowledgeBaseThemeMutationFn = Apollo.MutationFunction<
  PublishKnowledgeBaseThemeMutation,
  PublishKnowledgeBaseThemeMutationVariables
>;

/**
 * __usePublishKnowledgeBaseThemeMutation__
 *
 * To run a mutation, you first call `usePublishKnowledgeBaseThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishKnowledgeBaseThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishKnowledgeBaseThemeMutation, { data, loading, error }] = usePublishKnowledgeBaseThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishKnowledgeBaseThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishKnowledgeBaseThemeMutation,
    PublishKnowledgeBaseThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishKnowledgeBaseThemeMutation,
    PublishKnowledgeBaseThemeMutationVariables
  >(PublishKnowledgeBaseThemeDocument, options);
}
export type PublishKnowledgeBaseThemeMutationHookResult = ReturnType<
  typeof usePublishKnowledgeBaseThemeMutation
>;
export type PublishKnowledgeBaseThemeMutationResult =
  Apollo.MutationResult<PublishKnowledgeBaseThemeMutation>;
export type PublishKnowledgeBaseThemeMutationOptions =
  Apollo.BaseMutationOptions<
    PublishKnowledgeBaseThemeMutation,
    PublishKnowledgeBaseThemeMutationVariables
  >;
export const PublishKnowledgeBaseTestDocument = gql`
  mutation publishKnowledgeBaseTest($themeId: String!) {
    publishKnowledgeBaseTestBlocksByThemeId(themeId: $themeId)
  }
`;
export type PublishKnowledgeBaseTestMutationFn = Apollo.MutationFunction<
  PublishKnowledgeBaseTestMutation,
  PublishKnowledgeBaseTestMutationVariables
>;

/**
 * __usePublishKnowledgeBaseTestMutation__
 *
 * To run a mutation, you first call `usePublishKnowledgeBaseTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishKnowledgeBaseTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishKnowledgeBaseTestMutation, { data, loading, error }] = usePublishKnowledgeBaseTestMutation({
 *   variables: {
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function usePublishKnowledgeBaseTestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishKnowledgeBaseTestMutation,
    PublishKnowledgeBaseTestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishKnowledgeBaseTestMutation,
    PublishKnowledgeBaseTestMutationVariables
  >(PublishKnowledgeBaseTestDocument, options);
}
export type PublishKnowledgeBaseTestMutationHookResult = ReturnType<
  typeof usePublishKnowledgeBaseTestMutation
>;
export type PublishKnowledgeBaseTestMutationResult =
  Apollo.MutationResult<PublishKnowledgeBaseTestMutation>;
export type PublishKnowledgeBaseTestMutationOptions =
  Apollo.BaseMutationOptions<
    PublishKnowledgeBaseTestMutation,
    PublishKnowledgeBaseTestMutationVariables
  >;
export const CreatePayrollKpiDocument = gql`
  mutation createPayrollKpi($kpiData: CreatePayrollKpiInput!) {
    createPayrollKpi(kpiData: $kpiData) {
      id
      name
    }
  }
`;
export type CreatePayrollKpiMutationFn = Apollo.MutationFunction<
  CreatePayrollKpiMutation,
  CreatePayrollKpiMutationVariables
>;

/**
 * __useCreatePayrollKpiMutation__
 *
 * To run a mutation, you first call `useCreatePayrollKpiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayrollKpiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayrollKpiMutation, { data, loading, error }] = useCreatePayrollKpiMutation({
 *   variables: {
 *      kpiData: // value for 'kpiData'
 *   },
 * });
 */
export function useCreatePayrollKpiMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePayrollKpiMutation,
    CreatePayrollKpiMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePayrollKpiMutation,
    CreatePayrollKpiMutationVariables
  >(CreatePayrollKpiDocument, options);
}
export type CreatePayrollKpiMutationHookResult = ReturnType<
  typeof useCreatePayrollKpiMutation
>;
export type CreatePayrollKpiMutationResult =
  Apollo.MutationResult<CreatePayrollKpiMutation>;
export type CreatePayrollKpiMutationOptions = Apollo.BaseMutationOptions<
  CreatePayrollKpiMutation,
  CreatePayrollKpiMutationVariables
>;
export const UpdatePayrollKpiDocument = gql`
  mutation updatePayrollKpi($kpiData: UpdatePayrollKpiInput!) {
    updatePayrollKpi(kpiData: $kpiData) {
      id
      name
    }
  }
`;
export type UpdatePayrollKpiMutationFn = Apollo.MutationFunction<
  UpdatePayrollKpiMutation,
  UpdatePayrollKpiMutationVariables
>;

/**
 * __useUpdatePayrollKpiMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollKpiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollKpiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollKpiMutation, { data, loading, error }] = useUpdatePayrollKpiMutation({
 *   variables: {
 *      kpiData: // value for 'kpiData'
 *   },
 * });
 */
export function useUpdatePayrollKpiMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayrollKpiMutation,
    UpdatePayrollKpiMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePayrollKpiMutation,
    UpdatePayrollKpiMutationVariables
  >(UpdatePayrollKpiDocument, options);
}
export type UpdatePayrollKpiMutationHookResult = ReturnType<
  typeof useUpdatePayrollKpiMutation
>;
export type UpdatePayrollKpiMutationResult =
  Apollo.MutationResult<UpdatePayrollKpiMutation>;
export type UpdatePayrollKpiMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayrollKpiMutation,
  UpdatePayrollKpiMutationVariables
>;
export const DeletePayrollKpiDocument = gql`
  mutation deletePayrollKpi($kpiId: String!) {
    deletePayrollKpi(kpiId: $kpiId)
  }
`;
export type DeletePayrollKpiMutationFn = Apollo.MutationFunction<
  DeletePayrollKpiMutation,
  DeletePayrollKpiMutationVariables
>;

/**
 * __useDeletePayrollKpiMutation__
 *
 * To run a mutation, you first call `useDeletePayrollKpiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayrollKpiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayrollKpiMutation, { data, loading, error }] = useDeletePayrollKpiMutation({
 *   variables: {
 *      kpiId: // value for 'kpiId'
 *   },
 * });
 */
export function useDeletePayrollKpiMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePayrollKpiMutation,
    DeletePayrollKpiMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePayrollKpiMutation,
    DeletePayrollKpiMutationVariables
  >(DeletePayrollKpiDocument, options);
}
export type DeletePayrollKpiMutationHookResult = ReturnType<
  typeof useDeletePayrollKpiMutation
>;
export type DeletePayrollKpiMutationResult =
  Apollo.MutationResult<DeletePayrollKpiMutation>;
export type DeletePayrollKpiMutationOptions = Apollo.BaseMutationOptions<
  DeletePayrollKpiMutation,
  DeletePayrollKpiMutationVariables
>;
export const CreateLessonDocument = gql`
  mutation createLesson(
    $mainId: String!
    $themeId: String
    $modelId: String
    $name: String!
  ) {
    createLesson(
      mainId: $mainId
      themeId: $themeId
      modelId: $modelId
      name: $name
    ) {
      id
    }
  }
`;
export type CreateLessonMutationFn = Apollo.MutationFunction<
  CreateLessonMutation,
  CreateLessonMutationVariables
>;

/**
 * __useCreateLessonMutation__
 *
 * To run a mutation, you first call `useCreateLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLessonMutation, { data, loading, error }] = useCreateLessonMutation({
 *   variables: {
 *      mainId: // value for 'mainId'
 *      themeId: // value for 'themeId'
 *      modelId: // value for 'modelId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateLessonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLessonMutation,
    CreateLessonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLessonMutation,
    CreateLessonMutationVariables
  >(CreateLessonDocument, options);
}
export type CreateLessonMutationHookResult = ReturnType<
  typeof useCreateLessonMutation
>;
export type CreateLessonMutationResult =
  Apollo.MutationResult<CreateLessonMutation>;
export type CreateLessonMutationOptions = Apollo.BaseMutationOptions<
  CreateLessonMutation,
  CreateLessonMutationVariables
>;
export const CreateLessonBlockDocument = gql`
  mutation createLessonBlock($type: LessonBlockTypeEnum!, $pageId: String!) {
    createLessonBlock(type: $type, pageId: $pageId) {
      id
    }
  }
`;
export type CreateLessonBlockMutationFn = Apollo.MutationFunction<
  CreateLessonBlockMutation,
  CreateLessonBlockMutationVariables
>;

/**
 * __useCreateLessonBlockMutation__
 *
 * To run a mutation, you first call `useCreateLessonBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLessonBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLessonBlockMutation, { data, loading, error }] = useCreateLessonBlockMutation({
 *   variables: {
 *      type: // value for 'type'
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useCreateLessonBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLessonBlockMutation,
    CreateLessonBlockMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLessonBlockMutation,
    CreateLessonBlockMutationVariables
  >(CreateLessonBlockDocument, options);
}
export type CreateLessonBlockMutationHookResult = ReturnType<
  typeof useCreateLessonBlockMutation
>;
export type CreateLessonBlockMutationResult =
  Apollo.MutationResult<CreateLessonBlockMutation>;
export type CreateLessonBlockMutationOptions = Apollo.BaseMutationOptions<
  CreateLessonBlockMutation,
  CreateLessonBlockMutationVariables
>;
export const PublishLessonDocument = gql`
  mutation publishLesson($lessonId: String!) {
    publishLesson(lessonId: $lessonId)
  }
`;
export type PublishLessonMutationFn = Apollo.MutationFunction<
  PublishLessonMutation,
  PublishLessonMutationVariables
>;

/**
 * __usePublishLessonMutation__
 *
 * To run a mutation, you first call `usePublishLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishLessonMutation, { data, loading, error }] = usePublishLessonMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function usePublishLessonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishLessonMutation,
    PublishLessonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishLessonMutation,
    PublishLessonMutationVariables
  >(PublishLessonDocument, options);
}
export type PublishLessonMutationHookResult = ReturnType<
  typeof usePublishLessonMutation
>;
export type PublishLessonMutationResult =
  Apollo.MutationResult<PublishLessonMutation>;
export type PublishLessonMutationOptions = Apollo.BaseMutationOptions<
  PublishLessonMutation,
  PublishLessonMutationVariables
>;
export const DeleteLessonDocument = gql`
  mutation deleteLesson($lessonId: String!) {
    deleteLesson(lessonId: $lessonId)
  }
`;
export type DeleteLessonMutationFn = Apollo.MutationFunction<
  DeleteLessonMutation,
  DeleteLessonMutationVariables
>;

/**
 * __useDeleteLessonMutation__
 *
 * To run a mutation, you first call `useDeleteLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLessonMutation, { data, loading, error }] = useDeleteLessonMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useDeleteLessonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLessonMutation,
    DeleteLessonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLessonMutation,
    DeleteLessonMutationVariables
  >(DeleteLessonDocument, options);
}
export type DeleteLessonMutationHookResult = ReturnType<
  typeof useDeleteLessonMutation
>;
export type DeleteLessonMutationResult =
  Apollo.MutationResult<DeleteLessonMutation>;
export type DeleteLessonMutationOptions = Apollo.BaseMutationOptions<
  DeleteLessonMutation,
  DeleteLessonMutationVariables
>;
export const UpdateLessonBlockDocument = gql`
  mutation updateLessonBlock($lessonBlockData: UpdateLessonBlockInput!) {
    updateLessonBlock(lessonBlockData: $lessonBlockData) {
      id
    }
  }
`;
export type UpdateLessonBlockMutationFn = Apollo.MutationFunction<
  UpdateLessonBlockMutation,
  UpdateLessonBlockMutationVariables
>;

/**
 * __useUpdateLessonBlockMutation__
 *
 * To run a mutation, you first call `useUpdateLessonBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLessonBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLessonBlockMutation, { data, loading, error }] = useUpdateLessonBlockMutation({
 *   variables: {
 *      lessonBlockData: // value for 'lessonBlockData'
 *   },
 * });
 */
export function useUpdateLessonBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLessonBlockMutation,
    UpdateLessonBlockMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLessonBlockMutation,
    UpdateLessonBlockMutationVariables
  >(UpdateLessonBlockDocument, options);
}
export type UpdateLessonBlockMutationHookResult = ReturnType<
  typeof useUpdateLessonBlockMutation
>;
export type UpdateLessonBlockMutationResult =
  Apollo.MutationResult<UpdateLessonBlockMutation>;
export type UpdateLessonBlockMutationOptions = Apollo.BaseMutationOptions<
  UpdateLessonBlockMutation,
  UpdateLessonBlockMutationVariables
>;
export const DeleteLessonBlockDocument = gql`
  mutation deleteLessonBlock($lessonBlockId: String!) {
    deleteLessonBlock(lessonBlockId: $lessonBlockId)
  }
`;
export type DeleteLessonBlockMutationFn = Apollo.MutationFunction<
  DeleteLessonBlockMutation,
  DeleteLessonBlockMutationVariables
>;

/**
 * __useDeleteLessonBlockMutation__
 *
 * To run a mutation, you first call `useDeleteLessonBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLessonBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLessonBlockMutation, { data, loading, error }] = useDeleteLessonBlockMutation({
 *   variables: {
 *      lessonBlockId: // value for 'lessonBlockId'
 *   },
 * });
 */
export function useDeleteLessonBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLessonBlockMutation,
    DeleteLessonBlockMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLessonBlockMutation,
    DeleteLessonBlockMutationVariables
  >(DeleteLessonBlockDocument, options);
}
export type DeleteLessonBlockMutationHookResult = ReturnType<
  typeof useDeleteLessonBlockMutation
>;
export type DeleteLessonBlockMutationResult =
  Apollo.MutationResult<DeleteLessonBlockMutation>;
export type DeleteLessonBlockMutationOptions = Apollo.BaseMutationOptions<
  DeleteLessonBlockMutation,
  DeleteLessonBlockMutationVariables
>;
export const CreateLessonListItemDocument = gql`
  mutation createLessonListItem(
    $lessonBlockId: String!
    $lessonListItemData: LessonBlockListInput!
  ) {
    createLessonListItem(
      lessonBlockId: $lessonBlockId
      lessonListItemData: $lessonListItemData
    ) {
      id
    }
  }
`;
export type CreateLessonListItemMutationFn = Apollo.MutationFunction<
  CreateLessonListItemMutation,
  CreateLessonListItemMutationVariables
>;

/**
 * __useCreateLessonListItemMutation__
 *
 * To run a mutation, you first call `useCreateLessonListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLessonListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLessonListItemMutation, { data, loading, error }] = useCreateLessonListItemMutation({
 *   variables: {
 *      lessonBlockId: // value for 'lessonBlockId'
 *      lessonListItemData: // value for 'lessonListItemData'
 *   },
 * });
 */
export function useCreateLessonListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLessonListItemMutation,
    CreateLessonListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLessonListItemMutation,
    CreateLessonListItemMutationVariables
  >(CreateLessonListItemDocument, options);
}
export type CreateLessonListItemMutationHookResult = ReturnType<
  typeof useCreateLessonListItemMutation
>;
export type CreateLessonListItemMutationResult =
  Apollo.MutationResult<CreateLessonListItemMutation>;
export type CreateLessonListItemMutationOptions = Apollo.BaseMutationOptions<
  CreateLessonListItemMutation,
  CreateLessonListItemMutationVariables
>;
export const UpdateLessonListItemDocument = gql`
  mutation updateLessonListItem(
    $listItemId: String!
    $lessonListItemData: LessonBlockListInput!
  ) {
    updateLessonListItem(
      listItemId: $listItemId
      lessonListItemData: $lessonListItemData
    ) {
      id
    }
  }
`;
export type UpdateLessonListItemMutationFn = Apollo.MutationFunction<
  UpdateLessonListItemMutation,
  UpdateLessonListItemMutationVariables
>;

/**
 * __useUpdateLessonListItemMutation__
 *
 * To run a mutation, you first call `useUpdateLessonListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLessonListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLessonListItemMutation, { data, loading, error }] = useUpdateLessonListItemMutation({
 *   variables: {
 *      listItemId: // value for 'listItemId'
 *      lessonListItemData: // value for 'lessonListItemData'
 *   },
 * });
 */
export function useUpdateLessonListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLessonListItemMutation,
    UpdateLessonListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLessonListItemMutation,
    UpdateLessonListItemMutationVariables
  >(UpdateLessonListItemDocument, options);
}
export type UpdateLessonListItemMutationHookResult = ReturnType<
  typeof useUpdateLessonListItemMutation
>;
export type UpdateLessonListItemMutationResult =
  Apollo.MutationResult<UpdateLessonListItemMutation>;
export type UpdateLessonListItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateLessonListItemMutation,
  UpdateLessonListItemMutationVariables
>;
export const DeleteLessonListItemDocument = gql`
  mutation deleteLessonListItem($listItemId: String!) {
    deleteLessonListItem(listItemId: $listItemId)
  }
`;
export type DeleteLessonListItemMutationFn = Apollo.MutationFunction<
  DeleteLessonListItemMutation,
  DeleteLessonListItemMutationVariables
>;

/**
 * __useDeleteLessonListItemMutation__
 *
 * To run a mutation, you first call `useDeleteLessonListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLessonListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLessonListItemMutation, { data, loading, error }] = useDeleteLessonListItemMutation({
 *   variables: {
 *      listItemId: // value for 'listItemId'
 *   },
 * });
 */
export function useDeleteLessonListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLessonListItemMutation,
    DeleteLessonListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLessonListItemMutation,
    DeleteLessonListItemMutationVariables
  >(DeleteLessonListItemDocument, options);
}
export type DeleteLessonListItemMutationHookResult = ReturnType<
  typeof useDeleteLessonListItemMutation
>;
export type DeleteLessonListItemMutationResult =
  Apollo.MutationResult<DeleteLessonListItemMutation>;
export type DeleteLessonListItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteLessonListItemMutation,
  DeleteLessonListItemMutationVariables
>;
export const CreateManualPromotionDocument = gql`
  mutation createManualPromotion($data: CreateManualPromotionInput!) {
    createManualPromotion(data: $data)
  }
`;
export type CreateManualPromotionMutationFn = Apollo.MutationFunction<
  CreateManualPromotionMutation,
  CreateManualPromotionMutationVariables
>;

/**
 * __useCreateManualPromotionMutation__
 *
 * To run a mutation, you first call `useCreateManualPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualPromotionMutation, { data, loading, error }] = useCreateManualPromotionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateManualPromotionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManualPromotionMutation,
    CreateManualPromotionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateManualPromotionMutation,
    CreateManualPromotionMutationVariables
  >(CreateManualPromotionDocument, options);
}
export type CreateManualPromotionMutationHookResult = ReturnType<
  typeof useCreateManualPromotionMutation
>;
export type CreateManualPromotionMutationResult =
  Apollo.MutationResult<CreateManualPromotionMutation>;
export type CreateManualPromotionMutationOptions = Apollo.BaseMutationOptions<
  CreateManualPromotionMutation,
  CreateManualPromotionMutationVariables
>;
export const UpdateManualPromotionDocument = gql`
  mutation updateManualPromotion(
    $updateManualPromotionId: UUID!
    $data: UpdateManualPromotionInput!
  ) {
    updateManualPromotion(id: $updateManualPromotionId, data: $data)
  }
`;
export type UpdateManualPromotionMutationFn = Apollo.MutationFunction<
  UpdateManualPromotionMutation,
  UpdateManualPromotionMutationVariables
>;

/**
 * __useUpdateManualPromotionMutation__
 *
 * To run a mutation, you first call `useUpdateManualPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManualPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManualPromotionMutation, { data, loading, error }] = useUpdateManualPromotionMutation({
 *   variables: {
 *      updateManualPromotionId: // value for 'updateManualPromotionId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateManualPromotionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateManualPromotionMutation,
    UpdateManualPromotionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateManualPromotionMutation,
    UpdateManualPromotionMutationVariables
  >(UpdateManualPromotionDocument, options);
}
export type UpdateManualPromotionMutationHookResult = ReturnType<
  typeof useUpdateManualPromotionMutation
>;
export type UpdateManualPromotionMutationResult =
  Apollo.MutationResult<UpdateManualPromotionMutation>;
export type UpdateManualPromotionMutationOptions = Apollo.BaseMutationOptions<
  UpdateManualPromotionMutation,
  UpdateManualPromotionMutationVariables
>;
export const DeleteManualPromotionDocument = gql`
  mutation deleteManualPromotion($deleteManualPromotionId: UUID!) {
    deleteManualPromotion(id: $deleteManualPromotionId)
  }
`;
export type DeleteManualPromotionMutationFn = Apollo.MutationFunction<
  DeleteManualPromotionMutation,
  DeleteManualPromotionMutationVariables
>;

/**
 * __useDeleteManualPromotionMutation__
 *
 * To run a mutation, you first call `useDeleteManualPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManualPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManualPromotionMutation, { data, loading, error }] = useDeleteManualPromotionMutation({
 *   variables: {
 *      deleteManualPromotionId: // value for 'deleteManualPromotionId'
 *   },
 * });
 */
export function useDeleteManualPromotionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteManualPromotionMutation,
    DeleteManualPromotionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteManualPromotionMutation,
    DeleteManualPromotionMutationVariables
  >(DeleteManualPromotionDocument, options);
}
export type DeleteManualPromotionMutationHookResult = ReturnType<
  typeof useDeleteManualPromotionMutation
>;
export type DeleteManualPromotionMutationResult =
  Apollo.MutationResult<DeleteManualPromotionMutation>;
export type DeleteManualPromotionMutationOptions = Apollo.BaseMutationOptions<
  DeleteManualPromotionMutation,
  DeleteManualPromotionMutationVariables
>;
export const CreateApplicationModelInMarketContentDocument = gql`
  mutation createApplicationModelInMarketContent(
    $id: String!
    $lang: LanguagesEnum!
  ) {
    createApplicationModelInMarketContent(id: $id, lang: $lang)
  }
`;
export type CreateApplicationModelInMarketContentMutationFn =
  Apollo.MutationFunction<
    CreateApplicationModelInMarketContentMutation,
    CreateApplicationModelInMarketContentMutationVariables
  >;

/**
 * __useCreateApplicationModelInMarketContentMutation__
 *
 * To run a mutation, you first call `useCreateApplicationModelInMarketContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationModelInMarketContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationModelInMarketContentMutation, { data, loading, error }] = useCreateApplicationModelInMarketContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useCreateApplicationModelInMarketContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApplicationModelInMarketContentMutation,
    CreateApplicationModelInMarketContentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateApplicationModelInMarketContentMutation,
    CreateApplicationModelInMarketContentMutationVariables
  >(CreateApplicationModelInMarketContentDocument, options);
}
export type CreateApplicationModelInMarketContentMutationHookResult =
  ReturnType<typeof useCreateApplicationModelInMarketContentMutation>;
export type CreateApplicationModelInMarketContentMutationResult =
  Apollo.MutationResult<CreateApplicationModelInMarketContentMutation>;
export type CreateApplicationModelInMarketContentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateApplicationModelInMarketContentMutation,
    CreateApplicationModelInMarketContentMutationVariables
  >;
export const UpdateApplicationModelInMarketContentDocument = gql`
  mutation updateApplicationModelInMarketContent(
    $id: String!
    $lang: LanguagesEnum!
  ) {
    updateApplicationModelInMarketContent(id: $id, lang: $lang)
  }
`;
export type UpdateApplicationModelInMarketContentMutationFn =
  Apollo.MutationFunction<
    UpdateApplicationModelInMarketContentMutation,
    UpdateApplicationModelInMarketContentMutationVariables
  >;

/**
 * __useUpdateApplicationModelInMarketContentMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationModelInMarketContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationModelInMarketContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationModelInMarketContentMutation, { data, loading, error }] = useUpdateApplicationModelInMarketContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useUpdateApplicationModelInMarketContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationModelInMarketContentMutation,
    UpdateApplicationModelInMarketContentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationModelInMarketContentMutation,
    UpdateApplicationModelInMarketContentMutationVariables
  >(UpdateApplicationModelInMarketContentDocument, options);
}
export type UpdateApplicationModelInMarketContentMutationHookResult =
  ReturnType<typeof useUpdateApplicationModelInMarketContentMutation>;
export type UpdateApplicationModelInMarketContentMutationResult =
  Apollo.MutationResult<UpdateApplicationModelInMarketContentMutation>;
export type UpdateApplicationModelInMarketContentMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationModelInMarketContentMutation,
    UpdateApplicationModelInMarketContentMutationVariables
  >;
export const UpdateMarketingMediaFileDocument = gql`
  mutation updateMarketingMediaFile($data: MarketingModalInfoInput) {
    updateMarketingModalInfo(data: $data) {
      id
      fileLink
    }
  }
`;
export type UpdateMarketingMediaFileMutationFn = Apollo.MutationFunction<
  UpdateMarketingMediaFileMutation,
  UpdateMarketingMediaFileMutationVariables
>;

/**
 * __useUpdateMarketingMediaFileMutation__
 *
 * To run a mutation, you first call `useUpdateMarketingMediaFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketingMediaFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketingMediaFileMutation, { data, loading, error }] = useUpdateMarketingMediaFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMarketingMediaFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMarketingMediaFileMutation,
    UpdateMarketingMediaFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMarketingMediaFileMutation,
    UpdateMarketingMediaFileMutationVariables
  >(UpdateMarketingMediaFileDocument, options);
}
export type UpdateMarketingMediaFileMutationHookResult = ReturnType<
  typeof useUpdateMarketingMediaFileMutation
>;
export type UpdateMarketingMediaFileMutationResult =
  Apollo.MutationResult<UpdateMarketingMediaFileMutation>;
export type UpdateMarketingMediaFileMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMarketingMediaFileMutation,
    UpdateMarketingMediaFileMutationVariables
  >;
export const UpdateMarketingModalInfoDocument = gql`
  mutation updateMarketingModalInfo($data: MarketingModalInfoInput) {
    updateMarketingModalInfo(data: $data) {
      id
    }
  }
`;
export type UpdateMarketingModalInfoMutationFn = Apollo.MutationFunction<
  UpdateMarketingModalInfoMutation,
  UpdateMarketingModalInfoMutationVariables
>;

/**
 * __useUpdateMarketingModalInfoMutation__
 *
 * To run a mutation, you first call `useUpdateMarketingModalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketingModalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketingModalInfoMutation, { data, loading, error }] = useUpdateMarketingModalInfoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMarketingModalInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMarketingModalInfoMutation,
    UpdateMarketingModalInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMarketingModalInfoMutation,
    UpdateMarketingModalInfoMutationVariables
  >(UpdateMarketingModalInfoDocument, options);
}
export type UpdateMarketingModalInfoMutationHookResult = ReturnType<
  typeof useUpdateMarketingModalInfoMutation
>;
export type UpdateMarketingModalInfoMutationResult =
  Apollo.MutationResult<UpdateMarketingModalInfoMutation>;
export type UpdateMarketingModalInfoMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMarketingModalInfoMutation,
    UpdateMarketingModalInfoMutationVariables
  >;
export const CreateMobileNotificationDocument = gql`
  mutation createMobileNotification(
    $notificationData: CreateMobileNotificationInput!
  ) {
    createMobileNotification(notificationData: $notificationData) {
      id
      participants {
        id
      }
    }
  }
`;
export type CreateMobileNotificationMutationFn = Apollo.MutationFunction<
  CreateMobileNotificationMutation,
  CreateMobileNotificationMutationVariables
>;

/**
 * __useCreateMobileNotificationMutation__
 *
 * To run a mutation, you first call `useCreateMobileNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMobileNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMobileNotificationMutation, { data, loading, error }] = useCreateMobileNotificationMutation({
 *   variables: {
 *      notificationData: // value for 'notificationData'
 *   },
 * });
 */
export function useCreateMobileNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMobileNotificationMutation,
    CreateMobileNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMobileNotificationMutation,
    CreateMobileNotificationMutationVariables
  >(CreateMobileNotificationDocument, options);
}
export type CreateMobileNotificationMutationHookResult = ReturnType<
  typeof useCreateMobileNotificationMutation
>;
export type CreateMobileNotificationMutationResult =
  Apollo.MutationResult<CreateMobileNotificationMutation>;
export type CreateMobileNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateMobileNotificationMutation,
    CreateMobileNotificationMutationVariables
  >;
export const UpdateMobileNotificationDocument = gql`
  mutation updateMobileNotification(
    $notificationData: UpdateMobileNotificationInput!
    $photo: Upload
  ) {
    updateMobileNotification(
      notificationData: $notificationData
      photo: $photo
    ) {
      id
    }
  }
`;
export type UpdateMobileNotificationMutationFn = Apollo.MutationFunction<
  UpdateMobileNotificationMutation,
  UpdateMobileNotificationMutationVariables
>;

/**
 * __useUpdateMobileNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateMobileNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMobileNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMobileNotificationMutation, { data, loading, error }] = useUpdateMobileNotificationMutation({
 *   variables: {
 *      notificationData: // value for 'notificationData'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useUpdateMobileNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMobileNotificationMutation,
    UpdateMobileNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMobileNotificationMutation,
    UpdateMobileNotificationMutationVariables
  >(UpdateMobileNotificationDocument, options);
}
export type UpdateMobileNotificationMutationHookResult = ReturnType<
  typeof useUpdateMobileNotificationMutation
>;
export type UpdateMobileNotificationMutationResult =
  Apollo.MutationResult<UpdateMobileNotificationMutation>;
export type UpdateMobileNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMobileNotificationMutation,
    UpdateMobileNotificationMutationVariables
  >;
export const DeleteMobileNotificationDocument = gql`
  mutation deleteMobileNotification($id: String!) {
    deleteMobileNotification(id: $id)
  }
`;
export type DeleteMobileNotificationMutationFn = Apollo.MutationFunction<
  DeleteMobileNotificationMutation,
  DeleteMobileNotificationMutationVariables
>;

/**
 * __useDeleteMobileNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteMobileNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMobileNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMobileNotificationMutation, { data, loading, error }] = useDeleteMobileNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMobileNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMobileNotificationMutation,
    DeleteMobileNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMobileNotificationMutation,
    DeleteMobileNotificationMutationVariables
  >(DeleteMobileNotificationDocument, options);
}
export type DeleteMobileNotificationMutationHookResult = ReturnType<
  typeof useDeleteMobileNotificationMutation
>;
export type DeleteMobileNotificationMutationResult =
  Apollo.MutationResult<DeleteMobileNotificationMutation>;
export type DeleteMobileNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteMobileNotificationMutation,
    DeleteMobileNotificationMutationVariables
  >;
export const PublishMobileNotificationDocument = gql`
  mutation publishMobileNotification($id: String!) {
    publishMobileNotification(id: $id)
  }
`;
export type PublishMobileNotificationMutationFn = Apollo.MutationFunction<
  PublishMobileNotificationMutation,
  PublishMobileNotificationMutationVariables
>;

/**
 * __usePublishMobileNotificationMutation__
 *
 * To run a mutation, you first call `usePublishMobileNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMobileNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMobileNotificationMutation, { data, loading, error }] = usePublishMobileNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishMobileNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishMobileNotificationMutation,
    PublishMobileNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishMobileNotificationMutation,
    PublishMobileNotificationMutationVariables
  >(PublishMobileNotificationDocument, options);
}
export type PublishMobileNotificationMutationHookResult = ReturnType<
  typeof usePublishMobileNotificationMutation
>;
export type PublishMobileNotificationMutationResult =
  Apollo.MutationResult<PublishMobileNotificationMutation>;
export type PublishMobileNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    PublishMobileNotificationMutation,
    PublishMobileNotificationMutationVariables
  >;
export const SetMobileNotificationParticipantsDocument = gql`
  mutation setMobileNotificationParticipants(
    $notificationData: SetMobileNotificationParticipantsInput!
  ) {
    setMobileNotificationParticipants(notificationData: $notificationData)
  }
`;
export type SetMobileNotificationParticipantsMutationFn =
  Apollo.MutationFunction<
    SetMobileNotificationParticipantsMutation,
    SetMobileNotificationParticipantsMutationVariables
  >;

/**
 * __useSetMobileNotificationParticipantsMutation__
 *
 * To run a mutation, you first call `useSetMobileNotificationParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMobileNotificationParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMobileNotificationParticipantsMutation, { data, loading, error }] = useSetMobileNotificationParticipantsMutation({
 *   variables: {
 *      notificationData: // value for 'notificationData'
 *   },
 * });
 */
export function useSetMobileNotificationParticipantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetMobileNotificationParticipantsMutation,
    SetMobileNotificationParticipantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetMobileNotificationParticipantsMutation,
    SetMobileNotificationParticipantsMutationVariables
  >(SetMobileNotificationParticipantsDocument, options);
}
export type SetMobileNotificationParticipantsMutationHookResult = ReturnType<
  typeof useSetMobileNotificationParticipantsMutation
>;
export type SetMobileNotificationParticipantsMutationResult =
  Apollo.MutationResult<SetMobileNotificationParticipantsMutation>;
export type SetMobileNotificationParticipantsMutationOptions =
  Apollo.BaseMutationOptions<
    SetMobileNotificationParticipantsMutation,
    SetMobileNotificationParticipantsMutationVariables
  >;
export const RemoveMobileNotificationPhotoDocument = gql`
  mutation RemoveMobileNotificationPhoto(
    $removeMobileNotificationPhotoId: String!
  ) {
    removeMobileNotificationPhoto(id: $removeMobileNotificationPhotoId)
  }
`;
export type RemoveMobileNotificationPhotoMutationFn = Apollo.MutationFunction<
  RemoveMobileNotificationPhotoMutation,
  RemoveMobileNotificationPhotoMutationVariables
>;

/**
 * __useRemoveMobileNotificationPhotoMutation__
 *
 * To run a mutation, you first call `useRemoveMobileNotificationPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMobileNotificationPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMobileNotificationPhotoMutation, { data, loading, error }] = useRemoveMobileNotificationPhotoMutation({
 *   variables: {
 *      removeMobileNotificationPhotoId: // value for 'removeMobileNotificationPhotoId'
 *   },
 * });
 */
export function useRemoveMobileNotificationPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMobileNotificationPhotoMutation,
    RemoveMobileNotificationPhotoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveMobileNotificationPhotoMutation,
    RemoveMobileNotificationPhotoMutationVariables
  >(RemoveMobileNotificationPhotoDocument, options);
}
export type RemoveMobileNotificationPhotoMutationHookResult = ReturnType<
  typeof useRemoveMobileNotificationPhotoMutation
>;
export type RemoveMobileNotificationPhotoMutationResult =
  Apollo.MutationResult<RemoveMobileNotificationPhotoMutation>;
export type RemoveMobileNotificationPhotoMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveMobileNotificationPhotoMutation,
    RemoveMobileNotificationPhotoMutationVariables
  >;
export const CreateNewsDocument = gql`
  mutation createNews($newsData: CreateNewsInput!, $photo: Upload) {
    createNews(newsData: $newsData, photo: $photo) {
      id
      name
      isFor
      description
      availableFor
      participants {
        id
        user {
          id
        }
        isWatched
      }
    }
  }
`;
export type CreateNewsMutationFn = Apollo.MutationFunction<
  CreateNewsMutation,
  CreateNewsMutationVariables
>;

/**
 * __useCreateNewsMutation__
 *
 * To run a mutation, you first call `useCreateNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsMutation, { data, loading, error }] = useCreateNewsMutation({
 *   variables: {
 *      newsData: // value for 'newsData'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useCreateNewsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewsMutation,
    CreateNewsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNewsMutation, CreateNewsMutationVariables>(
    CreateNewsDocument,
    options
  );
}
export type CreateNewsMutationHookResult = ReturnType<
  typeof useCreateNewsMutation
>;
export type CreateNewsMutationResult =
  Apollo.MutationResult<CreateNewsMutation>;
export type CreateNewsMutationOptions = Apollo.BaseMutationOptions<
  CreateNewsMutation,
  CreateNewsMutationVariables
>;
export const UpdateNewsDocument = gql`
  mutation updateNews($newsData: UpdateNewsInput!, $photo: Upload) {
    updateNews(newsData: $newsData, photo: $photo) {
      id
    }
  }
`;
export type UpdateNewsMutationFn = Apollo.MutationFunction<
  UpdateNewsMutation,
  UpdateNewsMutationVariables
>;

/**
 * __useUpdateNewsMutation__
 *
 * To run a mutation, you first call `useUpdateNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewsMutation, { data, loading, error }] = useUpdateNewsMutation({
 *   variables: {
 *      newsData: // value for 'newsData'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useUpdateNewsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNewsMutation,
    UpdateNewsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNewsMutation, UpdateNewsMutationVariables>(
    UpdateNewsDocument,
    options
  );
}
export type UpdateNewsMutationHookResult = ReturnType<
  typeof useUpdateNewsMutation
>;
export type UpdateNewsMutationResult =
  Apollo.MutationResult<UpdateNewsMutation>;
export type UpdateNewsMutationOptions = Apollo.BaseMutationOptions<
  UpdateNewsMutation,
  UpdateNewsMutationVariables
>;
export const DeleteNewsDocument = gql`
  mutation deleteNews($id: String!) {
    deleteNews(id: $id)
  }
`;
export type DeleteNewsMutationFn = Apollo.MutationFunction<
  DeleteNewsMutation,
  DeleteNewsMutationVariables
>;

/**
 * __useDeleteNewsMutation__
 *
 * To run a mutation, you first call `useDeleteNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNewsMutation, { data, loading, error }] = useDeleteNewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNewsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNewsMutation,
    DeleteNewsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNewsMutation, DeleteNewsMutationVariables>(
    DeleteNewsDocument,
    options
  );
}
export type DeleteNewsMutationHookResult = ReturnType<
  typeof useDeleteNewsMutation
>;
export type DeleteNewsMutationResult =
  Apollo.MutationResult<DeleteNewsMutation>;
export type DeleteNewsMutationOptions = Apollo.BaseMutationOptions<
  DeleteNewsMutation,
  DeleteNewsMutationVariables
>;
export const PublishNewsDocument = gql`
  mutation publishNews($id: String!) {
    publishNews(id: $id)
  }
`;
export type PublishNewsMutationFn = Apollo.MutationFunction<
  PublishNewsMutation,
  PublishNewsMutationVariables
>;

/**
 * __usePublishNewsMutation__
 *
 * To run a mutation, you first call `usePublishNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishNewsMutation, { data, loading, error }] = usePublishNewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishNewsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishNewsMutation,
    PublishNewsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PublishNewsMutation, PublishNewsMutationVariables>(
    PublishNewsDocument,
    options
  );
}
export type PublishNewsMutationHookResult = ReturnType<
  typeof usePublishNewsMutation
>;
export type PublishNewsMutationResult =
  Apollo.MutationResult<PublishNewsMutation>;
export type PublishNewsMutationOptions = Apollo.BaseMutationOptions<
  PublishNewsMutation,
  PublishNewsMutationVariables
>;
export const SetNewsParticipantsDocument = gql`
  mutation setNewsParticipants($newsData: SetNewsParticipantsInput!) {
    setNewsParticipants(newsData: $newsData)
  }
`;
export type SetNewsParticipantsMutationFn = Apollo.MutationFunction<
  SetNewsParticipantsMutation,
  SetNewsParticipantsMutationVariables
>;

/**
 * __useSetNewsParticipantsMutation__
 *
 * To run a mutation, you first call `useSetNewsParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewsParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewsParticipantsMutation, { data, loading, error }] = useSetNewsParticipantsMutation({
 *   variables: {
 *      newsData: // value for 'newsData'
 *   },
 * });
 */
export function useSetNewsParticipantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetNewsParticipantsMutation,
    SetNewsParticipantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetNewsParticipantsMutation,
    SetNewsParticipantsMutationVariables
  >(SetNewsParticipantsDocument, options);
}
export type SetNewsParticipantsMutationHookResult = ReturnType<
  typeof useSetNewsParticipantsMutation
>;
export type SetNewsParticipantsMutationResult =
  Apollo.MutationResult<SetNewsParticipantsMutation>;
export type SetNewsParticipantsMutationOptions = Apollo.BaseMutationOptions<
  SetNewsParticipantsMutation,
  SetNewsParticipantsMutationVariables
>;
export const CreateNotificationDocument = gql`
  mutation createNotification($notificationData: CreateNotificationInput!) {
    createNotification(notificationData: $notificationData) {
      id
      name
      description
      availableFor
      participants {
        id
        user {
          id
        }
        isWatched
      }
    }
  }
`;
export type CreateNotificationMutationFn = Apollo.MutationFunction<
  CreateNotificationMutation,
  CreateNotificationMutationVariables
>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      notificationData: // value for 'notificationData'
 *   },
 * });
 */
export function useCreateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNotificationMutation,
    CreateNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNotificationMutation,
    CreateNotificationMutationVariables
  >(CreateNotificationDocument, options);
}
export type CreateNotificationMutationHookResult = ReturnType<
  typeof useCreateNotificationMutation
>;
export type CreateNotificationMutationResult =
  Apollo.MutationResult<CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<
  CreateNotificationMutation,
  CreateNotificationMutationVariables
>;
export const UpdateNotificationDocument = gql`
  mutation updateNotification($notificationData: UpdateNotificationInput!) {
    updateNotification(notificationData: $notificationData) {
      id
    }
  }
`;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<
  UpdateNotificationMutation,
  UpdateNotificationMutationVariables
>;

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      notificationData: // value for 'notificationData'
 *   },
 * });
 */
export function useUpdateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >(UpdateNotificationDocument, options);
}
export type UpdateNotificationMutationHookResult = ReturnType<
  typeof useUpdateNotificationMutation
>;
export type UpdateNotificationMutationResult =
  Apollo.MutationResult<UpdateNotificationMutation>;
export type UpdateNotificationMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationMutation,
  UpdateNotificationMutationVariables
>;
export const DeleteNotificationDocument = gql`
  mutation deleteNotification($id: String!) {
    deleteNotification(id: $id)
  }
`;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<
  DeleteNotificationMutation,
  DeleteNotificationMutationVariables
>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNotificationMutation,
    DeleteNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteNotificationMutation,
    DeleteNotificationMutationVariables
  >(DeleteNotificationDocument, options);
}
export type DeleteNotificationMutationHookResult = ReturnType<
  typeof useDeleteNotificationMutation
>;
export type DeleteNotificationMutationResult =
  Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<
  DeleteNotificationMutation,
  DeleteNotificationMutationVariables
>;
export const PublishNotificationDocument = gql`
  mutation publishNotification($id: String!) {
    publishNotification(id: $id)
  }
`;
export type PublishNotificationMutationFn = Apollo.MutationFunction<
  PublishNotificationMutation,
  PublishNotificationMutationVariables
>;

/**
 * __usePublishNotificationMutation__
 *
 * To run a mutation, you first call `usePublishNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishNotificationMutation, { data, loading, error }] = usePublishNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishNotificationMutation,
    PublishNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishNotificationMutation,
    PublishNotificationMutationVariables
  >(PublishNotificationDocument, options);
}
export type PublishNotificationMutationHookResult = ReturnType<
  typeof usePublishNotificationMutation
>;
export type PublishNotificationMutationResult =
  Apollo.MutationResult<PublishNotificationMutation>;
export type PublishNotificationMutationOptions = Apollo.BaseMutationOptions<
  PublishNotificationMutation,
  PublishNotificationMutationVariables
>;
export const SetNotificationParticipantsDocument = gql`
  mutation setNotificationParticipants(
    $notificationData: SetNotificationParticipantsInput!
  ) {
    setNotificationParticipants(notificationData: $notificationData)
  }
`;
export type SetNotificationParticipantsMutationFn = Apollo.MutationFunction<
  SetNotificationParticipantsMutation,
  SetNotificationParticipantsMutationVariables
>;

/**
 * __useSetNotificationParticipantsMutation__
 *
 * To run a mutation, you first call `useSetNotificationParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNotificationParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNotificationParticipantsMutation, { data, loading, error }] = useSetNotificationParticipantsMutation({
 *   variables: {
 *      notificationData: // value for 'notificationData'
 *   },
 * });
 */
export function useSetNotificationParticipantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetNotificationParticipantsMutation,
    SetNotificationParticipantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetNotificationParticipantsMutation,
    SetNotificationParticipantsMutationVariables
  >(SetNotificationParticipantsDocument, options);
}
export type SetNotificationParticipantsMutationHookResult = ReturnType<
  typeof useSetNotificationParticipantsMutation
>;
export type SetNotificationParticipantsMutationResult =
  Apollo.MutationResult<SetNotificationParticipantsMutation>;
export type SetNotificationParticipantsMutationOptions =
  Apollo.BaseMutationOptions<
    SetNotificationParticipantsMutation,
    SetNotificationParticipantsMutationVariables
  >;
export const CreateOnboardingThemeDocument = gql`
  mutation createOnboardingTheme(
    $title: String!
    $description: String!
    $video: String!
    $routeId: String
    $position: Int
  ) {
    createOnboardingTheme(
      title: $title
      description: $description
      video: $video
      routeId: $routeId
      position: $position
    ) {
      id
    }
  }
`;
export type CreateOnboardingThemeMutationFn = Apollo.MutationFunction<
  CreateOnboardingThemeMutation,
  CreateOnboardingThemeMutationVariables
>;

/**
 * __useCreateOnboardingThemeMutation__
 *
 * To run a mutation, you first call `useCreateOnboardingThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnboardingThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnboardingThemeMutation, { data, loading, error }] = useCreateOnboardingThemeMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      video: // value for 'video'
 *      routeId: // value for 'routeId'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useCreateOnboardingThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOnboardingThemeMutation,
    CreateOnboardingThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOnboardingThemeMutation,
    CreateOnboardingThemeMutationVariables
  >(CreateOnboardingThemeDocument, options);
}
export type CreateOnboardingThemeMutationHookResult = ReturnType<
  typeof useCreateOnboardingThemeMutation
>;
export type CreateOnboardingThemeMutationResult =
  Apollo.MutationResult<CreateOnboardingThemeMutation>;
export type CreateOnboardingThemeMutationOptions = Apollo.BaseMutationOptions<
  CreateOnboardingThemeMutation,
  CreateOnboardingThemeMutationVariables
>;
export const UpdateOnboardingThemeDocument = gql`
  mutation updateOnboardingTheme(
    $themeId: String!
    $isFinishedReset: Boolean!
    $title: String!
    $description: String!
    $video: String!
    $routeId: String
    $position: Int
  ) {
    updateOnboardingTheme(
      themeId: $themeId
      isFinishedReset: $isFinishedReset
      title: $title
      description: $description
      video: $video
      routeId: $routeId
      position: $position
    ) {
      id
    }
  }
`;
export type UpdateOnboardingThemeMutationFn = Apollo.MutationFunction<
  UpdateOnboardingThemeMutation,
  UpdateOnboardingThemeMutationVariables
>;

/**
 * __useUpdateOnboardingThemeMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingThemeMutation, { data, loading, error }] = useUpdateOnboardingThemeMutation({
 *   variables: {
 *      themeId: // value for 'themeId'
 *      isFinishedReset: // value for 'isFinishedReset'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      video: // value for 'video'
 *      routeId: // value for 'routeId'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useUpdateOnboardingThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOnboardingThemeMutation,
    UpdateOnboardingThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOnboardingThemeMutation,
    UpdateOnboardingThemeMutationVariables
  >(UpdateOnboardingThemeDocument, options);
}
export type UpdateOnboardingThemeMutationHookResult = ReturnType<
  typeof useUpdateOnboardingThemeMutation
>;
export type UpdateOnboardingThemeMutationResult =
  Apollo.MutationResult<UpdateOnboardingThemeMutation>;
export type UpdateOnboardingThemeMutationOptions = Apollo.BaseMutationOptions<
  UpdateOnboardingThemeMutation,
  UpdateOnboardingThemeMutationVariables
>;
export const DeleteOnboardingThemeDocument = gql`
  mutation deleteOnboardingTheme($themeId: String!) {
    deleteOnboardingTheme(themeId: $themeId) {
      id
    }
  }
`;
export type DeleteOnboardingThemeMutationFn = Apollo.MutationFunction<
  DeleteOnboardingThemeMutation,
  DeleteOnboardingThemeMutationVariables
>;

/**
 * __useDeleteOnboardingThemeMutation__
 *
 * To run a mutation, you first call `useDeleteOnboardingThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOnboardingThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOnboardingThemeMutation, { data, loading, error }] = useDeleteOnboardingThemeMutation({
 *   variables: {
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useDeleteOnboardingThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOnboardingThemeMutation,
    DeleteOnboardingThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOnboardingThemeMutation,
    DeleteOnboardingThemeMutationVariables
  >(DeleteOnboardingThemeDocument, options);
}
export type DeleteOnboardingThemeMutationHookResult = ReturnType<
  typeof useDeleteOnboardingThemeMutation
>;
export type DeleteOnboardingThemeMutationResult =
  Apollo.MutationResult<DeleteOnboardingThemeMutation>;
export type DeleteOnboardingThemeMutationOptions = Apollo.BaseMutationOptions<
  DeleteOnboardingThemeMutation,
  DeleteOnboardingThemeMutationVariables
>;
export const PublishOnboardingThemeDocument = gql`
  mutation publishOnboardingTheme($themeId: String!) {
    publishOnboardingTheme(themeId: $themeId) {
      id
    }
  }
`;
export type PublishOnboardingThemeMutationFn = Apollo.MutationFunction<
  PublishOnboardingThemeMutation,
  PublishOnboardingThemeMutationVariables
>;

/**
 * __usePublishOnboardingThemeMutation__
 *
 * To run a mutation, you first call `usePublishOnboardingThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishOnboardingThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishOnboardingThemeMutation, { data, loading, error }] = usePublishOnboardingThemeMutation({
 *   variables: {
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function usePublishOnboardingThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishOnboardingThemeMutation,
    PublishOnboardingThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishOnboardingThemeMutation,
    PublishOnboardingThemeMutationVariables
  >(PublishOnboardingThemeDocument, options);
}
export type PublishOnboardingThemeMutationHookResult = ReturnType<
  typeof usePublishOnboardingThemeMutation
>;
export type PublishOnboardingThemeMutationResult =
  Apollo.MutationResult<PublishOnboardingThemeMutation>;
export type PublishOnboardingThemeMutationOptions = Apollo.BaseMutationOptions<
  PublishOnboardingThemeMutation,
  PublishOnboardingThemeMutationVariables
>;
export const UnpublishOnboardingThemeDocument = gql`
  mutation unpublishOnboardingTheme($themeId: String!) {
    unpublishOnboardingTheme(themeId: $themeId) {
      id
    }
  }
`;
export type UnpublishOnboardingThemeMutationFn = Apollo.MutationFunction<
  UnpublishOnboardingThemeMutation,
  UnpublishOnboardingThemeMutationVariables
>;

/**
 * __useUnpublishOnboardingThemeMutation__
 *
 * To run a mutation, you first call `useUnpublishOnboardingThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishOnboardingThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishOnboardingThemeMutation, { data, loading, error }] = useUnpublishOnboardingThemeMutation({
 *   variables: {
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useUnpublishOnboardingThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnpublishOnboardingThemeMutation,
    UnpublishOnboardingThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnpublishOnboardingThemeMutation,
    UnpublishOnboardingThemeMutationVariables
  >(UnpublishOnboardingThemeDocument, options);
}
export type UnpublishOnboardingThemeMutationHookResult = ReturnType<
  typeof useUnpublishOnboardingThemeMutation
>;
export type UnpublishOnboardingThemeMutationResult =
  Apollo.MutationResult<UnpublishOnboardingThemeMutation>;
export type UnpublishOnboardingThemeMutationOptions =
  Apollo.BaseMutationOptions<
    UnpublishOnboardingThemeMutation,
    UnpublishOnboardingThemeMutationVariables
  >;
export const ReorderOnboardingThemeDocument = gql`
  mutation reorderOnboardingTheme($themeId: String!, $position: Int!) {
    reorderOnboardingTheme(themeId: $themeId, position: $position) {
      id
    }
  }
`;
export type ReorderOnboardingThemeMutationFn = Apollo.MutationFunction<
  ReorderOnboardingThemeMutation,
  ReorderOnboardingThemeMutationVariables
>;

/**
 * __useReorderOnboardingThemeMutation__
 *
 * To run a mutation, you first call `useReorderOnboardingThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderOnboardingThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderOnboardingThemeMutation, { data, loading, error }] = useReorderOnboardingThemeMutation({
 *   variables: {
 *      themeId: // value for 'themeId'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useReorderOnboardingThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderOnboardingThemeMutation,
    ReorderOnboardingThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReorderOnboardingThemeMutation,
    ReorderOnboardingThemeMutationVariables
  >(ReorderOnboardingThemeDocument, options);
}
export type ReorderOnboardingThemeMutationHookResult = ReturnType<
  typeof useReorderOnboardingThemeMutation
>;
export type ReorderOnboardingThemeMutationResult =
  Apollo.MutationResult<ReorderOnboardingThemeMutation>;
export type ReorderOnboardingThemeMutationOptions = Apollo.BaseMutationOptions<
  ReorderOnboardingThemeMutation,
  ReorderOnboardingThemeMutationVariables
>;
export const UpdateUserPaymentDocument = gql`
  mutation updateUserPayment($paymentData: UpdateUserPaymentInput!) {
    updateUserPayment(paymentData: $paymentData) {
      id
    }
  }
`;
export type UpdateUserPaymentMutationFn = Apollo.MutationFunction<
  UpdateUserPaymentMutation,
  UpdateUserPaymentMutationVariables
>;

/**
 * __useUpdateUserPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateUserPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPaymentMutation, { data, loading, error }] = useUpdateUserPaymentMutation({
 *   variables: {
 *      paymentData: // value for 'paymentData'
 *   },
 * });
 */
export function useUpdateUserPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPaymentMutation,
    UpdateUserPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserPaymentMutation,
    UpdateUserPaymentMutationVariables
  >(UpdateUserPaymentDocument, options);
}
export type UpdateUserPaymentMutationHookResult = ReturnType<
  typeof useUpdateUserPaymentMutation
>;
export type UpdateUserPaymentMutationResult =
  Apollo.MutationResult<UpdateUserPaymentMutation>;
export type UpdateUserPaymentMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPaymentMutation,
  UpdateUserPaymentMutationVariables
>;
export const CreateUserPaymentDocument = gql`
  mutation createUserPayment($paymentData: CreateUserPaymentInput!) {
    createUserPayment(paymentData: $paymentData) {
      id
    }
  }
`;
export type CreateUserPaymentMutationFn = Apollo.MutationFunction<
  CreateUserPaymentMutation,
  CreateUserPaymentMutationVariables
>;

/**
 * __useCreateUserPaymentMutation__
 *
 * To run a mutation, you first call `useCreateUserPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserPaymentMutation, { data, loading, error }] = useCreateUserPaymentMutation({
 *   variables: {
 *      paymentData: // value for 'paymentData'
 *   },
 * });
 */
export function useCreateUserPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserPaymentMutation,
    CreateUserPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserPaymentMutation,
    CreateUserPaymentMutationVariables
  >(CreateUserPaymentDocument, options);
}
export type CreateUserPaymentMutationHookResult = ReturnType<
  typeof useCreateUserPaymentMutation
>;
export type CreateUserPaymentMutationResult =
  Apollo.MutationResult<CreateUserPaymentMutation>;
export type CreateUserPaymentMutationOptions = Apollo.BaseMutationOptions<
  CreateUserPaymentMutation,
  CreateUserPaymentMutationVariables
>;
export const PayUserPaymentDocument = gql`
  mutation payUserPayment($paymentId: String!) {
    payUserPayment(paymentId: $paymentId)
  }
`;
export type PayUserPaymentMutationFn = Apollo.MutationFunction<
  PayUserPaymentMutation,
  PayUserPaymentMutationVariables
>;

/**
 * __usePayUserPaymentMutation__
 *
 * To run a mutation, you first call `usePayUserPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayUserPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payUserPaymentMutation, { data, loading, error }] = usePayUserPaymentMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function usePayUserPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayUserPaymentMutation,
    PayUserPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayUserPaymentMutation,
    PayUserPaymentMutationVariables
  >(PayUserPaymentDocument, options);
}
export type PayUserPaymentMutationHookResult = ReturnType<
  typeof usePayUserPaymentMutation
>;
export type PayUserPaymentMutationResult =
  Apollo.MutationResult<PayUserPaymentMutation>;
export type PayUserPaymentMutationOptions = Apollo.BaseMutationOptions<
  PayUserPaymentMutation,
  PayUserPaymentMutationVariables
>;
export const PublishUserPaymentDocument = gql`
  mutation publishUserPayment($paymentId: String!) {
    publishUserPayment(paymentId: $paymentId)
  }
`;
export type PublishUserPaymentMutationFn = Apollo.MutationFunction<
  PublishUserPaymentMutation,
  PublishUserPaymentMutationVariables
>;

/**
 * __usePublishUserPaymentMutation__
 *
 * To run a mutation, you first call `usePublishUserPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishUserPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishUserPaymentMutation, { data, loading, error }] = usePublishUserPaymentMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function usePublishUserPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishUserPaymentMutation,
    PublishUserPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishUserPaymentMutation,
    PublishUserPaymentMutationVariables
  >(PublishUserPaymentDocument, options);
}
export type PublishUserPaymentMutationHookResult = ReturnType<
  typeof usePublishUserPaymentMutation
>;
export type PublishUserPaymentMutationResult =
  Apollo.MutationResult<PublishUserPaymentMutation>;
export type PublishUserPaymentMutationOptions = Apollo.BaseMutationOptions<
  PublishUserPaymentMutation,
  PublishUserPaymentMutationVariables
>;
export const SetUserPaymentDocumentsDocument = gql`
  mutation setUserPaymentDocuments(
    $paymentId: String!
    $deletedDocuments: [String]
    $newDocuments: [Upload]
  ) {
    setUserPaymentDocuments(
      paymentId: $paymentId
      deletedDocuments: $deletedDocuments
      newDocuments: $newDocuments
    )
  }
`;
export type SetUserPaymentDocumentsMutationFn = Apollo.MutationFunction<
  SetUserPaymentDocumentsMutation,
  SetUserPaymentDocumentsMutationVariables
>;

/**
 * __useSetUserPaymentDocumentsMutation__
 *
 * To run a mutation, you first call `useSetUserPaymentDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserPaymentDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserPaymentDocumentsMutation, { data, loading, error }] = useSetUserPaymentDocumentsMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *      deletedDocuments: // value for 'deletedDocuments'
 *      newDocuments: // value for 'newDocuments'
 *   },
 * });
 */
export function useSetUserPaymentDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserPaymentDocumentsMutation,
    SetUserPaymentDocumentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetUserPaymentDocumentsMutation,
    SetUserPaymentDocumentsMutationVariables
  >(SetUserPaymentDocumentsDocument, options);
}
export type SetUserPaymentDocumentsMutationHookResult = ReturnType<
  typeof useSetUserPaymentDocumentsMutation
>;
export type SetUserPaymentDocumentsMutationResult =
  Apollo.MutationResult<SetUserPaymentDocumentsMutation>;
export type SetUserPaymentDocumentsMutationOptions = Apollo.BaseMutationOptions<
  SetUserPaymentDocumentsMutation,
  SetUserPaymentDocumentsMutationVariables
>;
export const CreateUserPayrollDocument = gql`
  mutation createUserPayroll($payrollData: CreateUserPayrollInput!) {
    createUserPayroll(payrollData: $payrollData) {
      id
      name
    }
  }
`;
export type CreateUserPayrollMutationFn = Apollo.MutationFunction<
  CreateUserPayrollMutation,
  CreateUserPayrollMutationVariables
>;

/**
 * __useCreateUserPayrollMutation__
 *
 * To run a mutation, you first call `useCreateUserPayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserPayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserPayrollMutation, { data, loading, error }] = useCreateUserPayrollMutation({
 *   variables: {
 *      payrollData: // value for 'payrollData'
 *   },
 * });
 */
export function useCreateUserPayrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserPayrollMutation,
    CreateUserPayrollMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserPayrollMutation,
    CreateUserPayrollMutationVariables
  >(CreateUserPayrollDocument, options);
}
export type CreateUserPayrollMutationHookResult = ReturnType<
  typeof useCreateUserPayrollMutation
>;
export type CreateUserPayrollMutationResult =
  Apollo.MutationResult<CreateUserPayrollMutation>;
export type CreateUserPayrollMutationOptions = Apollo.BaseMutationOptions<
  CreateUserPayrollMutation,
  CreateUserPayrollMutationVariables
>;
export const UpdateUserPayrollDocument = gql`
  mutation updateUserPayroll($payrollData: UpdateUserPayrollInput!) {
    updateUserPayroll(payrollData: $payrollData) {
      id
      bonuses {
        name
        value
      }
      kpi {
        id
        name
        unit
        weigh
        plan
        fact
      }
      hoursPlan
      additionalData {
        kpiValue
      }
    }
  }
`;
export type UpdateUserPayrollMutationFn = Apollo.MutationFunction<
  UpdateUserPayrollMutation,
  UpdateUserPayrollMutationVariables
>;

/**
 * __useUpdateUserPayrollMutation__
 *
 * To run a mutation, you first call `useUpdateUserPayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPayrollMutation, { data, loading, error }] = useUpdateUserPayrollMutation({
 *   variables: {
 *      payrollData: // value for 'payrollData'
 *   },
 * });
 */
export function useUpdateUserPayrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPayrollMutation,
    UpdateUserPayrollMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserPayrollMutation,
    UpdateUserPayrollMutationVariables
  >(UpdateUserPayrollDocument, options);
}
export type UpdateUserPayrollMutationHookResult = ReturnType<
  typeof useUpdateUserPayrollMutation
>;
export type UpdateUserPayrollMutationResult =
  Apollo.MutationResult<UpdateUserPayrollMutation>;
export type UpdateUserPayrollMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPayrollMutation,
  UpdateUserPayrollMutationVariables
>;
export const DeleteUserPayrollDocument = gql`
  mutation deleteUserPayroll($payrollId: String!) {
    deleteUserPayroll(payrollId: $payrollId)
  }
`;
export type DeleteUserPayrollMutationFn = Apollo.MutationFunction<
  DeleteUserPayrollMutation,
  DeleteUserPayrollMutationVariables
>;

/**
 * __useDeleteUserPayrollMutation__
 *
 * To run a mutation, you first call `useDeleteUserPayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserPayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserPayrollMutation, { data, loading, error }] = useDeleteUserPayrollMutation({
 *   variables: {
 *      payrollId: // value for 'payrollId'
 *   },
 * });
 */
export function useDeleteUserPayrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserPayrollMutation,
    DeleteUserPayrollMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserPayrollMutation,
    DeleteUserPayrollMutationVariables
  >(DeleteUserPayrollDocument, options);
}
export type DeleteUserPayrollMutationHookResult = ReturnType<
  typeof useDeleteUserPayrollMutation
>;
export type DeleteUserPayrollMutationResult =
  Apollo.MutationResult<DeleteUserPayrollMutation>;
export type DeleteUserPayrollMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserPayrollMutation,
  DeleteUserPayrollMutationVariables
>;
export const CreateProjectDocument = gql`
  mutation createProject($jiraKey: String!, $name: String!) {
    createProject(jiraKey: $jiraKey, name: $name) {
      id
    }
  }
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      jiraKey: // value for 'jiraKey'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CreateProjectDocument, options);
}
export type CreateProjectMutationHookResult = ReturnType<
  typeof useCreateProjectMutation
>;
export type CreateProjectMutationResult =
  Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const CreateProjectByJiraDataDocument = gql`
  mutation createProjectByJiraData($jira: String!, $jiraKey: String!) {
    createProjectByJiraData(jira: $jira, jiraKey: $jiraKey) {
      id
      jira
    }
  }
`;
export type CreateProjectByJiraDataMutationFn = Apollo.MutationFunction<
  CreateProjectByJiraDataMutation,
  CreateProjectByJiraDataMutationVariables
>;

/**
 * __useCreateProjectByJiraDataMutation__
 *
 * To run a mutation, you first call `useCreateProjectByJiraDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectByJiraDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectByJiraDataMutation, { data, loading, error }] = useCreateProjectByJiraDataMutation({
 *   variables: {
 *      jira: // value for 'jira'
 *      jiraKey: // value for 'jiraKey'
 *   },
 * });
 */
export function useCreateProjectByJiraDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectByJiraDataMutation,
    CreateProjectByJiraDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectByJiraDataMutation,
    CreateProjectByJiraDataMutationVariables
  >(CreateProjectByJiraDataDocument, options);
}
export type CreateProjectByJiraDataMutationHookResult = ReturnType<
  typeof useCreateProjectByJiraDataMutation
>;
export type CreateProjectByJiraDataMutationResult =
  Apollo.MutationResult<CreateProjectByJiraDataMutation>;
export type CreateProjectByJiraDataMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectByJiraDataMutation,
  CreateProjectByJiraDataMutationVariables
>;
export const DeleteProjectDocument = gql`
  mutation deleteProject($projectId: String!) {
    deleteProject(projectId: $projectId)
  }
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >(DeleteProjectDocument, options);
}
export type DeleteProjectMutationHookResult = ReturnType<
  typeof useDeleteProjectMutation
>;
export type DeleteProjectMutationResult =
  Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const UpdateProjectDocument = gql`
  mutation updateProject($projectData: UpdateProjectInput!, $photo: Upload) {
    updateProject(projectData: $projectData, photo: $photo) {
      cost
      name
    }
  }
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      projectData: // value for 'projectData'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >(UpdateProjectDocument, options);
}
export type UpdateProjectMutationHookResult = ReturnType<
  typeof useUpdateProjectMutation
>;
export type UpdateProjectMutationResult =
  Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const SyncProjectDocument = gql`
  mutation syncProject($projectId: String!) {
    syncProject(projectId: $projectId) {
      tasksToAdd
      tasksToDelete
      tasksToUpdate
    }
  }
`;
export type SyncProjectMutationFn = Apollo.MutationFunction<
  SyncProjectMutation,
  SyncProjectMutationVariables
>;

/**
 * __useSyncProjectMutation__
 *
 * To run a mutation, you first call `useSyncProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncProjectMutation, { data, loading, error }] = useSyncProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSyncProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncProjectMutation,
    SyncProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SyncProjectMutation, SyncProjectMutationVariables>(
    SyncProjectDocument,
    options
  );
}
export type SyncProjectMutationHookResult = ReturnType<
  typeof useSyncProjectMutation
>;
export type SyncProjectMutationResult =
  Apollo.MutationResult<SyncProjectMutation>;
export type SyncProjectMutationOptions = Apollo.BaseMutationOptions<
  SyncProjectMutation,
  SyncProjectMutationVariables
>;
export const AssignProjectsToUserDocument = gql`
  mutation assignProjectsToUser($userId: String!, $projectIds: [String]!) {
    assignProjectsToUser(userId: $userId, projectIds: $projectIds)
  }
`;
export type AssignProjectsToUserMutationFn = Apollo.MutationFunction<
  AssignProjectsToUserMutation,
  AssignProjectsToUserMutationVariables
>;

/**
 * __useAssignProjectsToUserMutation__
 *
 * To run a mutation, you first call `useAssignProjectsToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignProjectsToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignProjectsToUserMutation, { data, loading, error }] = useAssignProjectsToUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useAssignProjectsToUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignProjectsToUserMutation,
    AssignProjectsToUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignProjectsToUserMutation,
    AssignProjectsToUserMutationVariables
  >(AssignProjectsToUserDocument, options);
}
export type AssignProjectsToUserMutationHookResult = ReturnType<
  typeof useAssignProjectsToUserMutation
>;
export type AssignProjectsToUserMutationResult =
  Apollo.MutationResult<AssignProjectsToUserMutation>;
export type AssignProjectsToUserMutationOptions = Apollo.BaseMutationOptions<
  AssignProjectsToUserMutation,
  AssignProjectsToUserMutationVariables
>;
export const RemoveProjectsFromUserDocument = gql`
  mutation removeProjectsFromUser($userId: String!, $projectIds: [String]!) {
    removeProjectsFromUser(userId: $userId, projectIds: $projectIds)
  }
`;
export type RemoveProjectsFromUserMutationFn = Apollo.MutationFunction<
  RemoveProjectsFromUserMutation,
  RemoveProjectsFromUserMutationVariables
>;

/**
 * __useRemoveProjectsFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveProjectsFromUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectsFromUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectsFromUserMutation, { data, loading, error }] = useRemoveProjectsFromUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useRemoveProjectsFromUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveProjectsFromUserMutation,
    RemoveProjectsFromUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveProjectsFromUserMutation,
    RemoveProjectsFromUserMutationVariables
  >(RemoveProjectsFromUserDocument, options);
}
export type RemoveProjectsFromUserMutationHookResult = ReturnType<
  typeof useRemoveProjectsFromUserMutation
>;
export type RemoveProjectsFromUserMutationResult =
  Apollo.MutationResult<RemoveProjectsFromUserMutation>;
export type RemoveProjectsFromUserMutationOptions = Apollo.BaseMutationOptions<
  RemoveProjectsFromUserMutation,
  RemoveProjectsFromUserMutationVariables
>;
export const UpdateProjectFilesDocument = gql`
  mutation updateProjectFiles(
    $projectId: String!
    $deleteFileId: String
    $uploadFile: UploadFileInput
  ) {
    updateProjectFiles(
      projectId: $projectId
      deleteFileId: $deleteFileId
      uploadFile: $uploadFile
    ) {
      id
    }
  }
`;
export type UpdateProjectFilesMutationFn = Apollo.MutationFunction<
  UpdateProjectFilesMutation,
  UpdateProjectFilesMutationVariables
>;

/**
 * __useUpdateProjectFilesMutation__
 *
 * To run a mutation, you first call `useUpdateProjectFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectFilesMutation, { data, loading, error }] = useUpdateProjectFilesMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      deleteFileId: // value for 'deleteFileId'
 *      uploadFile: // value for 'uploadFile'
 *   },
 * });
 */
export function useUpdateProjectFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectFilesMutation,
    UpdateProjectFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectFilesMutation,
    UpdateProjectFilesMutationVariables
  >(UpdateProjectFilesDocument, options);
}
export type UpdateProjectFilesMutationHookResult = ReturnType<
  typeof useUpdateProjectFilesMutation
>;
export type UpdateProjectFilesMutationResult =
  Apollo.MutationResult<UpdateProjectFilesMutation>;
export type UpdateProjectFilesMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectFilesMutation,
  UpdateProjectFilesMutationVariables
>;
export const CreateProjectAdditionalContactDocument = gql`
  mutation createProjectAdditionalContact(
    $projectAdditionalContactData: CreateProjectAdditionalContactInput!
  ) {
    createProjectAdditionalContact(
      projectAdditionalContactData: $projectAdditionalContactData
    ) {
      name
      phone
      description
    }
  }
`;
export type CreateProjectAdditionalContactMutationFn = Apollo.MutationFunction<
  CreateProjectAdditionalContactMutation,
  CreateProjectAdditionalContactMutationVariables
>;

/**
 * __useCreateProjectAdditionalContactMutation__
 *
 * To run a mutation, you first call `useCreateProjectAdditionalContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectAdditionalContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectAdditionalContactMutation, { data, loading, error }] = useCreateProjectAdditionalContactMutation({
 *   variables: {
 *      projectAdditionalContactData: // value for 'projectAdditionalContactData'
 *   },
 * });
 */
export function useCreateProjectAdditionalContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectAdditionalContactMutation,
    CreateProjectAdditionalContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectAdditionalContactMutation,
    CreateProjectAdditionalContactMutationVariables
  >(CreateProjectAdditionalContactDocument, options);
}
export type CreateProjectAdditionalContactMutationHookResult = ReturnType<
  typeof useCreateProjectAdditionalContactMutation
>;
export type CreateProjectAdditionalContactMutationResult =
  Apollo.MutationResult<CreateProjectAdditionalContactMutation>;
export type CreateProjectAdditionalContactMutationOptions =
  Apollo.BaseMutationOptions<
    CreateProjectAdditionalContactMutation,
    CreateProjectAdditionalContactMutationVariables
  >;
export const UpdateProjectAdditionalContactDocument = gql`
  mutation updateProjectAdditionalContact(
    $projectAdditionalContactData: UpdateProjectAdditionalContactInput!
  ) {
    updateProjectAdditionalContact(
      projectAdditionalContactData: $projectAdditionalContactData
    ) {
      id
      name
      phone
      description
    }
  }
`;
export type UpdateProjectAdditionalContactMutationFn = Apollo.MutationFunction<
  UpdateProjectAdditionalContactMutation,
  UpdateProjectAdditionalContactMutationVariables
>;

/**
 * __useUpdateProjectAdditionalContactMutation__
 *
 * To run a mutation, you first call `useUpdateProjectAdditionalContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectAdditionalContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectAdditionalContactMutation, { data, loading, error }] = useUpdateProjectAdditionalContactMutation({
 *   variables: {
 *      projectAdditionalContactData: // value for 'projectAdditionalContactData'
 *   },
 * });
 */
export function useUpdateProjectAdditionalContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectAdditionalContactMutation,
    UpdateProjectAdditionalContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectAdditionalContactMutation,
    UpdateProjectAdditionalContactMutationVariables
  >(UpdateProjectAdditionalContactDocument, options);
}
export type UpdateProjectAdditionalContactMutationHookResult = ReturnType<
  typeof useUpdateProjectAdditionalContactMutation
>;
export type UpdateProjectAdditionalContactMutationResult =
  Apollo.MutationResult<UpdateProjectAdditionalContactMutation>;
export type UpdateProjectAdditionalContactMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateProjectAdditionalContactMutation,
    UpdateProjectAdditionalContactMutationVariables
  >;
export const DeleteProjectAdditionalContactDocument = gql`
  mutation deleteProjectAdditionalContact(
    $projectAdditionalContactId: String!
  ) {
    deleteProjectAdditionalContact(
      projectAdditionalContactId: $projectAdditionalContactId
    )
  }
`;
export type DeleteProjectAdditionalContactMutationFn = Apollo.MutationFunction<
  DeleteProjectAdditionalContactMutation,
  DeleteProjectAdditionalContactMutationVariables
>;

/**
 * __useDeleteProjectAdditionalContactMutation__
 *
 * To run a mutation, you first call `useDeleteProjectAdditionalContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectAdditionalContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectAdditionalContactMutation, { data, loading, error }] = useDeleteProjectAdditionalContactMutation({
 *   variables: {
 *      projectAdditionalContactId: // value for 'projectAdditionalContactId'
 *   },
 * });
 */
export function useDeleteProjectAdditionalContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectAdditionalContactMutation,
    DeleteProjectAdditionalContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectAdditionalContactMutation,
    DeleteProjectAdditionalContactMutationVariables
  >(DeleteProjectAdditionalContactDocument, options);
}
export type DeleteProjectAdditionalContactMutationHookResult = ReturnType<
  typeof useDeleteProjectAdditionalContactMutation
>;
export type DeleteProjectAdditionalContactMutationResult =
  Apollo.MutationResult<DeleteProjectAdditionalContactMutation>;
export type DeleteProjectAdditionalContactMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteProjectAdditionalContactMutation,
    DeleteProjectAdditionalContactMutationVariables
  >;
export const CreateProjectLinkDocument = gql`
  mutation createProjectLink($projectLinkData: CreateProjectLinkInput!) {
    createProjectLink(projectLinkData: $projectLinkData) {
      title
    }
  }
`;
export type CreateProjectLinkMutationFn = Apollo.MutationFunction<
  CreateProjectLinkMutation,
  CreateProjectLinkMutationVariables
>;

/**
 * __useCreateProjectLinkMutation__
 *
 * To run a mutation, you first call `useCreateProjectLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectLinkMutation, { data, loading, error }] = useCreateProjectLinkMutation({
 *   variables: {
 *      projectLinkData: // value for 'projectLinkData'
 *   },
 * });
 */
export function useCreateProjectLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectLinkMutation,
    CreateProjectLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectLinkMutation,
    CreateProjectLinkMutationVariables
  >(CreateProjectLinkDocument, options);
}
export type CreateProjectLinkMutationHookResult = ReturnType<
  typeof useCreateProjectLinkMutation
>;
export type CreateProjectLinkMutationResult =
  Apollo.MutationResult<CreateProjectLinkMutation>;
export type CreateProjectLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectLinkMutation,
  CreateProjectLinkMutationVariables
>;
export const UpdateProjectLinkDocument = gql`
  mutation updateProjectLink($projectLinkData: UpdateProjectLinkInput!) {
    updateProjectLink(projectLinkData: $projectLinkData) {
      id
    }
  }
`;
export type UpdateProjectLinkMutationFn = Apollo.MutationFunction<
  UpdateProjectLinkMutation,
  UpdateProjectLinkMutationVariables
>;

/**
 * __useUpdateProjectLinkMutation__
 *
 * To run a mutation, you first call `useUpdateProjectLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectLinkMutation, { data, loading, error }] = useUpdateProjectLinkMutation({
 *   variables: {
 *      projectLinkData: // value for 'projectLinkData'
 *   },
 * });
 */
export function useUpdateProjectLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectLinkMutation,
    UpdateProjectLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectLinkMutation,
    UpdateProjectLinkMutationVariables
  >(UpdateProjectLinkDocument, options);
}
export type UpdateProjectLinkMutationHookResult = ReturnType<
  typeof useUpdateProjectLinkMutation
>;
export type UpdateProjectLinkMutationResult =
  Apollo.MutationResult<UpdateProjectLinkMutation>;
export type UpdateProjectLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectLinkMutation,
  UpdateProjectLinkMutationVariables
>;
export const DeleteProjectLinkDocument = gql`
  mutation deleteProjectLink($projectLinkId: String!) {
    deleteProjectLink(projectLinkId: $projectLinkId)
  }
`;
export type DeleteProjectLinkMutationFn = Apollo.MutationFunction<
  DeleteProjectLinkMutation,
  DeleteProjectLinkMutationVariables
>;

/**
 * __useDeleteProjectLinkMutation__
 *
 * To run a mutation, you first call `useDeleteProjectLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectLinkMutation, { data, loading, error }] = useDeleteProjectLinkMutation({
 *   variables: {
 *      projectLinkId: // value for 'projectLinkId'
 *   },
 * });
 */
export function useDeleteProjectLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectLinkMutation,
    DeleteProjectLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectLinkMutation,
    DeleteProjectLinkMutationVariables
  >(DeleteProjectLinkDocument, options);
}
export type DeleteProjectLinkMutationHookResult = ReturnType<
  typeof useDeleteProjectLinkMutation
>;
export type DeleteProjectLinkMutationResult =
  Apollo.MutationResult<DeleteProjectLinkMutation>;
export type DeleteProjectLinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectLinkMutation,
  DeleteProjectLinkMutationVariables
>;
export const CreateProjectPresentationDocument = gql`
  mutation createProjectPresentation(
    $projectId: String!
    $link: String!
    $title: String
  ) {
    createProjectPresentation(
      projectId: $projectId
      link: $link
      title: $title
    ) {
      id
    }
  }
`;
export type CreateProjectPresentationMutationFn = Apollo.MutationFunction<
  CreateProjectPresentationMutation,
  CreateProjectPresentationMutationVariables
>;

/**
 * __useCreateProjectPresentationMutation__
 *
 * To run a mutation, you first call `useCreateProjectPresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectPresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectPresentationMutation, { data, loading, error }] = useCreateProjectPresentationMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      link: // value for 'link'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateProjectPresentationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectPresentationMutation,
    CreateProjectPresentationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectPresentationMutation,
    CreateProjectPresentationMutationVariables
  >(CreateProjectPresentationDocument, options);
}
export type CreateProjectPresentationMutationHookResult = ReturnType<
  typeof useCreateProjectPresentationMutation
>;
export type CreateProjectPresentationMutationResult =
  Apollo.MutationResult<CreateProjectPresentationMutation>;
export type CreateProjectPresentationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateProjectPresentationMutation,
    CreateProjectPresentationMutationVariables
  >;
export const UpdateProjectPresentationDocument = gql`
  mutation updateProjectPresentation(
    $id: String!
    $link: String
    $title: String
  ) {
    updateProjectPresentation(id: $id, link: $link, title: $title) {
      id
    }
  }
`;
export type UpdateProjectPresentationMutationFn = Apollo.MutationFunction<
  UpdateProjectPresentationMutation,
  UpdateProjectPresentationMutationVariables
>;

/**
 * __useUpdateProjectPresentationMutation__
 *
 * To run a mutation, you first call `useUpdateProjectPresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectPresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectPresentationMutation, { data, loading, error }] = useUpdateProjectPresentationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      link: // value for 'link'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateProjectPresentationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectPresentationMutation,
    UpdateProjectPresentationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectPresentationMutation,
    UpdateProjectPresentationMutationVariables
  >(UpdateProjectPresentationDocument, options);
}
export type UpdateProjectPresentationMutationHookResult = ReturnType<
  typeof useUpdateProjectPresentationMutation
>;
export type UpdateProjectPresentationMutationResult =
  Apollo.MutationResult<UpdateProjectPresentationMutation>;
export type UpdateProjectPresentationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateProjectPresentationMutation,
    UpdateProjectPresentationMutationVariables
  >;
export const DeleteProjectPresentationDocument = gql`
  mutation deleteProjectPresentation($projectPresentationId: String!) {
    deleteProjectPresentation(projectPresentationId: $projectPresentationId)
  }
`;
export type DeleteProjectPresentationMutationFn = Apollo.MutationFunction<
  DeleteProjectPresentationMutation,
  DeleteProjectPresentationMutationVariables
>;

/**
 * __useDeleteProjectPresentationMutation__
 *
 * To run a mutation, you first call `useDeleteProjectPresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectPresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectPresentationMutation, { data, loading, error }] = useDeleteProjectPresentationMutation({
 *   variables: {
 *      projectPresentationId: // value for 'projectPresentationId'
 *   },
 * });
 */
export function useDeleteProjectPresentationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectPresentationMutation,
    DeleteProjectPresentationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectPresentationMutation,
    DeleteProjectPresentationMutationVariables
  >(DeleteProjectPresentationDocument, options);
}
export type DeleteProjectPresentationMutationHookResult = ReturnType<
  typeof useDeleteProjectPresentationMutation
>;
export type DeleteProjectPresentationMutationResult =
  Apollo.MutationResult<DeleteProjectPresentationMutation>;
export type DeleteProjectPresentationMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteProjectPresentationMutation,
    DeleteProjectPresentationMutationVariables
  >;
export const CreateProjectStepDocument = gql`
  mutation createProjectStep($projectStepData: CreateProjectStepInput!) {
    createProjectStep(projectStepData: $projectStepData) {
      id
    }
  }
`;
export type CreateProjectStepMutationFn = Apollo.MutationFunction<
  CreateProjectStepMutation,
  CreateProjectStepMutationVariables
>;

/**
 * __useCreateProjectStepMutation__
 *
 * To run a mutation, you first call `useCreateProjectStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectStepMutation, { data, loading, error }] = useCreateProjectStepMutation({
 *   variables: {
 *      projectStepData: // value for 'projectStepData'
 *   },
 * });
 */
export function useCreateProjectStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectStepMutation,
    CreateProjectStepMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectStepMutation,
    CreateProjectStepMutationVariables
  >(CreateProjectStepDocument, options);
}
export type CreateProjectStepMutationHookResult = ReturnType<
  typeof useCreateProjectStepMutation
>;
export type CreateProjectStepMutationResult =
  Apollo.MutationResult<CreateProjectStepMutation>;
export type CreateProjectStepMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectStepMutation,
  CreateProjectStepMutationVariables
>;
export const UpdateProjectStepDocument = gql`
  mutation updateProjectStep($projectStepData: UpdateProjectStepInput!) {
    updateProjectStep(projectStepData: $projectStepData) {
      id
    }
  }
`;
export type UpdateProjectStepMutationFn = Apollo.MutationFunction<
  UpdateProjectStepMutation,
  UpdateProjectStepMutationVariables
>;

/**
 * __useUpdateProjectStepMutation__
 *
 * To run a mutation, you first call `useUpdateProjectStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectStepMutation, { data, loading, error }] = useUpdateProjectStepMutation({
 *   variables: {
 *      projectStepData: // value for 'projectStepData'
 *   },
 * });
 */
export function useUpdateProjectStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectStepMutation,
    UpdateProjectStepMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectStepMutation,
    UpdateProjectStepMutationVariables
  >(UpdateProjectStepDocument, options);
}
export type UpdateProjectStepMutationHookResult = ReturnType<
  typeof useUpdateProjectStepMutation
>;
export type UpdateProjectStepMutationResult =
  Apollo.MutationResult<UpdateProjectStepMutation>;
export type UpdateProjectStepMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectStepMutation,
  UpdateProjectStepMutationVariables
>;
export const DeleteProjectStepDocument = gql`
  mutation deleteProjectStep($projectStepId: String!) {
    deleteProjectStep(projectStepId: $projectStepId)
  }
`;
export type DeleteProjectStepMutationFn = Apollo.MutationFunction<
  DeleteProjectStepMutation,
  DeleteProjectStepMutationVariables
>;

/**
 * __useDeleteProjectStepMutation__
 *
 * To run a mutation, you first call `useDeleteProjectStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectStepMutation, { data, loading, error }] = useDeleteProjectStepMutation({
 *   variables: {
 *      projectStepId: // value for 'projectStepId'
 *   },
 * });
 */
export function useDeleteProjectStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectStepMutation,
    DeleteProjectStepMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectStepMutation,
    DeleteProjectStepMutationVariables
  >(DeleteProjectStepDocument, options);
}
export type DeleteProjectStepMutationHookResult = ReturnType<
  typeof useDeleteProjectStepMutation
>;
export type DeleteProjectStepMutationResult =
  Apollo.MutationResult<DeleteProjectStepMutation>;
export type DeleteProjectStepMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectStepMutation,
  DeleteProjectStepMutationVariables
>;
export const CreateProjectTaskInKanbanDocument = gql`
  mutation createProjectTaskInKanban(
    $taskData: CreateProjectTaskInput!
    $workflowStepId: String!
    $workflowStepOrder: Int!
  ) {
    createProjectTaskInKanban(
      taskData: $taskData
      workflowStepId: $workflowStepId
      workflowStepOrder: $workflowStepOrder
    ) {
      id
    }
  }
`;
export type CreateProjectTaskInKanbanMutationFn = Apollo.MutationFunction<
  CreateProjectTaskInKanbanMutation,
  CreateProjectTaskInKanbanMutationVariables
>;

/**
 * __useCreateProjectTaskInKanbanMutation__
 *
 * To run a mutation, you first call `useCreateProjectTaskInKanbanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTaskInKanbanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTaskInKanbanMutation, { data, loading, error }] = useCreateProjectTaskInKanbanMutation({
 *   variables: {
 *      taskData: // value for 'taskData'
 *      workflowStepId: // value for 'workflowStepId'
 *      workflowStepOrder: // value for 'workflowStepOrder'
 *   },
 * });
 */
export function useCreateProjectTaskInKanbanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectTaskInKanbanMutation,
    CreateProjectTaskInKanbanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectTaskInKanbanMutation,
    CreateProjectTaskInKanbanMutationVariables
  >(CreateProjectTaskInKanbanDocument, options);
}
export type CreateProjectTaskInKanbanMutationHookResult = ReturnType<
  typeof useCreateProjectTaskInKanbanMutation
>;
export type CreateProjectTaskInKanbanMutationResult =
  Apollo.MutationResult<CreateProjectTaskInKanbanMutation>;
export type CreateProjectTaskInKanbanMutationOptions =
  Apollo.BaseMutationOptions<
    CreateProjectTaskInKanbanMutation,
    CreateProjectTaskInKanbanMutationVariables
  >;
export const AddProjectTasksToKanbanDocument = gql`
  mutation addProjectTasksToKanban(
    $tasksIds: [String]!
    $workflowStepId: String!
    $workflowStepStartOrder: Int!
  ) {
    addProjectTasksToKanban(
      tasksIds: $tasksIds
      workflowStepId: $workflowStepId
      workflowStepStartOrder: $workflowStepStartOrder
    )
  }
`;
export type AddProjectTasksToKanbanMutationFn = Apollo.MutationFunction<
  AddProjectTasksToKanbanMutation,
  AddProjectTasksToKanbanMutationVariables
>;

/**
 * __useAddProjectTasksToKanbanMutation__
 *
 * To run a mutation, you first call `useAddProjectTasksToKanbanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectTasksToKanbanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectTasksToKanbanMutation, { data, loading, error }] = useAddProjectTasksToKanbanMutation({
 *   variables: {
 *      tasksIds: // value for 'tasksIds'
 *      workflowStepId: // value for 'workflowStepId'
 *      workflowStepStartOrder: // value for 'workflowStepStartOrder'
 *   },
 * });
 */
export function useAddProjectTasksToKanbanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProjectTasksToKanbanMutation,
    AddProjectTasksToKanbanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProjectTasksToKanbanMutation,
    AddProjectTasksToKanbanMutationVariables
  >(AddProjectTasksToKanbanDocument, options);
}
export type AddProjectTasksToKanbanMutationHookResult = ReturnType<
  typeof useAddProjectTasksToKanbanMutation
>;
export type AddProjectTasksToKanbanMutationResult =
  Apollo.MutationResult<AddProjectTasksToKanbanMutation>;
export type AddProjectTasksToKanbanMutationOptions = Apollo.BaseMutationOptions<
  AddProjectTasksToKanbanMutation,
  AddProjectTasksToKanbanMutationVariables
>;
export const ChangeProjectTaskWorkflowStepDocument = gql`
  mutation changeProjectTaskWorkflowStep(
    $tasksId: String!
    $workflowStepId: String!
    $workflowStepOrder: Int!
  ) {
    changeProjectTaskWorkflowStep(
      tasksId: $tasksId
      workflowStepId: $workflowStepId
      workflowStepOrder: $workflowStepOrder
    )
  }
`;
export type ChangeProjectTaskWorkflowStepMutationFn = Apollo.MutationFunction<
  ChangeProjectTaskWorkflowStepMutation,
  ChangeProjectTaskWorkflowStepMutationVariables
>;

/**
 * __useChangeProjectTaskWorkflowStepMutation__
 *
 * To run a mutation, you first call `useChangeProjectTaskWorkflowStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProjectTaskWorkflowStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProjectTaskWorkflowStepMutation, { data, loading, error }] = useChangeProjectTaskWorkflowStepMutation({
 *   variables: {
 *      tasksId: // value for 'tasksId'
 *      workflowStepId: // value for 'workflowStepId'
 *      workflowStepOrder: // value for 'workflowStepOrder'
 *   },
 * });
 */
export function useChangeProjectTaskWorkflowStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeProjectTaskWorkflowStepMutation,
    ChangeProjectTaskWorkflowStepMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeProjectTaskWorkflowStepMutation,
    ChangeProjectTaskWorkflowStepMutationVariables
  >(ChangeProjectTaskWorkflowStepDocument, options);
}
export type ChangeProjectTaskWorkflowStepMutationHookResult = ReturnType<
  typeof useChangeProjectTaskWorkflowStepMutation
>;
export type ChangeProjectTaskWorkflowStepMutationResult =
  Apollo.MutationResult<ChangeProjectTaskWorkflowStepMutation>;
export type ChangeProjectTaskWorkflowStepMutationOptions =
  Apollo.BaseMutationOptions<
    ChangeProjectTaskWorkflowStepMutation,
    ChangeProjectTaskWorkflowStepMutationVariables
  >;
export const CreateProjectTypeDocument = gql`
  mutation createProjectType($name: String!) {
    createProjectType(name: $name) {
      id
    }
  }
`;
export type CreateProjectTypeMutationFn = Apollo.MutationFunction<
  CreateProjectTypeMutation,
  CreateProjectTypeMutationVariables
>;

/**
 * __useCreateProjectTypeMutation__
 *
 * To run a mutation, you first call `useCreateProjectTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTypeMutation, { data, loading, error }] = useCreateProjectTypeMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProjectTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectTypeMutation,
    CreateProjectTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectTypeMutation,
    CreateProjectTypeMutationVariables
  >(CreateProjectTypeDocument, options);
}
export type CreateProjectTypeMutationHookResult = ReturnType<
  typeof useCreateProjectTypeMutation
>;
export type CreateProjectTypeMutationResult =
  Apollo.MutationResult<CreateProjectTypeMutation>;
export type CreateProjectTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectTypeMutation,
  CreateProjectTypeMutationVariables
>;
export const UpdateProjectTypeDocument = gql`
  mutation updateProjectType($typeId: String!, $name: String!) {
    updateProjectType(typeId: $typeId, name: $name) {
      id
    }
  }
`;
export type UpdateProjectTypeMutationFn = Apollo.MutationFunction<
  UpdateProjectTypeMutation,
  UpdateProjectTypeMutationVariables
>;

/**
 * __useUpdateProjectTypeMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTypeMutation, { data, loading, error }] = useUpdateProjectTypeMutation({
 *   variables: {
 *      typeId: // value for 'typeId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateProjectTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTypeMutation,
    UpdateProjectTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTypeMutation,
    UpdateProjectTypeMutationVariables
  >(UpdateProjectTypeDocument, options);
}
export type UpdateProjectTypeMutationHookResult = ReturnType<
  typeof useUpdateProjectTypeMutation
>;
export type UpdateProjectTypeMutationResult =
  Apollo.MutationResult<UpdateProjectTypeMutation>;
export type UpdateProjectTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTypeMutation,
  UpdateProjectTypeMutationVariables
>;
export const DeleteProjectTypeDocument = gql`
  mutation deleteProjectType($typeId: String!) {
    deleteProjectType(typeId: $typeId)
  }
`;
export type DeleteProjectTypeMutationFn = Apollo.MutationFunction<
  DeleteProjectTypeMutation,
  DeleteProjectTypeMutationVariables
>;

/**
 * __useDeleteProjectTypeMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTypeMutation, { data, loading, error }] = useDeleteProjectTypeMutation({
 *   variables: {
 *      typeId: // value for 'typeId'
 *   },
 * });
 */
export function useDeleteProjectTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectTypeMutation,
    DeleteProjectTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectTypeMutation,
    DeleteProjectTypeMutationVariables
  >(DeleteProjectTypeDocument, options);
}
export type DeleteProjectTypeMutationHookResult = ReturnType<
  typeof useDeleteProjectTypeMutation
>;
export type DeleteProjectTypeMutationResult =
  Apollo.MutationResult<DeleteProjectTypeMutation>;
export type DeleteProjectTypeMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectTypeMutation,
  DeleteProjectTypeMutationVariables
>;
export const CreateQuoteCollectionDocument = gql`
  mutation createQuoteCollection($name: String!, $file: Upload!) {
    createQuoteCollection(name: $name, file: $file) {
      id
    }
  }
`;
export type CreateQuoteCollectionMutationFn = Apollo.MutationFunction<
  CreateQuoteCollectionMutation,
  CreateQuoteCollectionMutationVariables
>;

/**
 * __useCreateQuoteCollectionMutation__
 *
 * To run a mutation, you first call `useCreateQuoteCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteCollectionMutation, { data, loading, error }] = useCreateQuoteCollectionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateQuoteCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuoteCollectionMutation,
    CreateQuoteCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateQuoteCollectionMutation,
    CreateQuoteCollectionMutationVariables
  >(CreateQuoteCollectionDocument, options);
}
export type CreateQuoteCollectionMutationHookResult = ReturnType<
  typeof useCreateQuoteCollectionMutation
>;
export type CreateQuoteCollectionMutationResult =
  Apollo.MutationResult<CreateQuoteCollectionMutation>;
export type CreateQuoteCollectionMutationOptions = Apollo.BaseMutationOptions<
  CreateQuoteCollectionMutation,
  CreateQuoteCollectionMutationVariables
>;
export const UpdateQuoteCollectionDocument = gql`
  mutation updateQuoteCollection(
    $updateQuoteCollectionId: UUID!
    $name: String
    $file: Upload
  ) {
    updateQuoteCollection(
      id: $updateQuoteCollectionId
      name: $name
      file: $file
    )
  }
`;
export type UpdateQuoteCollectionMutationFn = Apollo.MutationFunction<
  UpdateQuoteCollectionMutation,
  UpdateQuoteCollectionMutationVariables
>;

/**
 * __useUpdateQuoteCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteCollectionMutation, { data, loading, error }] = useUpdateQuoteCollectionMutation({
 *   variables: {
 *      updateQuoteCollectionId: // value for 'updateQuoteCollectionId'
 *      name: // value for 'name'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateQuoteCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQuoteCollectionMutation,
    UpdateQuoteCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateQuoteCollectionMutation,
    UpdateQuoteCollectionMutationVariables
  >(UpdateQuoteCollectionDocument, options);
}
export type UpdateQuoteCollectionMutationHookResult = ReturnType<
  typeof useUpdateQuoteCollectionMutation
>;
export type UpdateQuoteCollectionMutationResult =
  Apollo.MutationResult<UpdateQuoteCollectionMutation>;
export type UpdateQuoteCollectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateQuoteCollectionMutation,
  UpdateQuoteCollectionMutationVariables
>;
export const DeleteQuoteCollectionDocument = gql`
  mutation deleteQuoteCollection($deleteQuoteCollectionId: UUID!) {
    deleteQuoteCollection(id: $deleteQuoteCollectionId)
  }
`;
export type DeleteQuoteCollectionMutationFn = Apollo.MutationFunction<
  DeleteQuoteCollectionMutation,
  DeleteQuoteCollectionMutationVariables
>;

/**
 * __useDeleteQuoteCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteQuoteCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuoteCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuoteCollectionMutation, { data, loading, error }] = useDeleteQuoteCollectionMutation({
 *   variables: {
 *      deleteQuoteCollectionId: // value for 'deleteQuoteCollectionId'
 *   },
 * });
 */
export function useDeleteQuoteCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteQuoteCollectionMutation,
    DeleteQuoteCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteQuoteCollectionMutation,
    DeleteQuoteCollectionMutationVariables
  >(DeleteQuoteCollectionDocument, options);
}
export type DeleteQuoteCollectionMutationHookResult = ReturnType<
  typeof useDeleteQuoteCollectionMutation
>;
export type DeleteQuoteCollectionMutationResult =
  Apollo.MutationResult<DeleteQuoteCollectionMutation>;
export type DeleteQuoteCollectionMutationOptions = Apollo.BaseMutationOptions<
  DeleteQuoteCollectionMutation,
  DeleteQuoteCollectionMutationVariables
>;
export const CreateRateHistoryDocument = gql`
  mutation createRateHistory(
    $userId: String!
    $rate: Int!
    $changeDate: Date!
  ) {
    createRateHistory(userId: $userId, rate: $rate, changeDate: $changeDate) {
      id
    }
  }
`;
export type CreateRateHistoryMutationFn = Apollo.MutationFunction<
  CreateRateHistoryMutation,
  CreateRateHistoryMutationVariables
>;

/**
 * __useCreateRateHistoryMutation__
 *
 * To run a mutation, you first call `useCreateRateHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRateHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRateHistoryMutation, { data, loading, error }] = useCreateRateHistoryMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      rate: // value for 'rate'
 *      changeDate: // value for 'changeDate'
 *   },
 * });
 */
export function useCreateRateHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRateHistoryMutation,
    CreateRateHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRateHistoryMutation,
    CreateRateHistoryMutationVariables
  >(CreateRateHistoryDocument, options);
}
export type CreateRateHistoryMutationHookResult = ReturnType<
  typeof useCreateRateHistoryMutation
>;
export type CreateRateHistoryMutationResult =
  Apollo.MutationResult<CreateRateHistoryMutation>;
export type CreateRateHistoryMutationOptions = Apollo.BaseMutationOptions<
  CreateRateHistoryMutation,
  CreateRateHistoryMutationVariables
>;
export const UpdateRateHistoryDocument = gql`
  mutation updateRateHistory($id: String!, $rate: Int, $changeDate: Date) {
    updateRateHistory(id: $id, rate: $rate, changeDate: $changeDate) {
      id
    }
  }
`;
export type UpdateRateHistoryMutationFn = Apollo.MutationFunction<
  UpdateRateHistoryMutation,
  UpdateRateHistoryMutationVariables
>;

/**
 * __useUpdateRateHistoryMutation__
 *
 * To run a mutation, you first call `useUpdateRateHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRateHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRateHistoryMutation, { data, loading, error }] = useUpdateRateHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rate: // value for 'rate'
 *      changeDate: // value for 'changeDate'
 *   },
 * });
 */
export function useUpdateRateHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRateHistoryMutation,
    UpdateRateHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRateHistoryMutation,
    UpdateRateHistoryMutationVariables
  >(UpdateRateHistoryDocument, options);
}
export type UpdateRateHistoryMutationHookResult = ReturnType<
  typeof useUpdateRateHistoryMutation
>;
export type UpdateRateHistoryMutationResult =
  Apollo.MutationResult<UpdateRateHistoryMutation>;
export type UpdateRateHistoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateRateHistoryMutation,
  UpdateRateHistoryMutationVariables
>;
export const DeleteSRateHistoryDocument = gql`
  mutation deleteSRateHistory($id: String!) {
    deleteSRateHistory(id: $id)
  }
`;
export type DeleteSRateHistoryMutationFn = Apollo.MutationFunction<
  DeleteSRateHistoryMutation,
  DeleteSRateHistoryMutationVariables
>;

/**
 * __useDeleteSRateHistoryMutation__
 *
 * To run a mutation, you first call `useDeleteSRateHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSRateHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSRateHistoryMutation, { data, loading, error }] = useDeleteSRateHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSRateHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSRateHistoryMutation,
    DeleteSRateHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSRateHistoryMutation,
    DeleteSRateHistoryMutationVariables
  >(DeleteSRateHistoryDocument, options);
}
export type DeleteSRateHistoryMutationHookResult = ReturnType<
  typeof useDeleteSRateHistoryMutation
>;
export type DeleteSRateHistoryMutationResult =
  Apollo.MutationResult<DeleteSRateHistoryMutation>;
export type DeleteSRateHistoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteSRateHistoryMutation,
  DeleteSRateHistoryMutationVariables
>;
export const CreateUserDocument = gql`
  mutation createUser($userData: CreateUserInput!, $isFrom: isFromEnum!) {
    createUser(userData: $userData, isFrom: $isFrom) {
      id
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userData: // value for 'userData'
 *      isFrom: // value for 'isFrom'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const CreateVacanciesPlanDocument = gql`
  mutation createVacanciesPlan($planData: CreateVacanciesPlanInput!) {
    createVacanciesPlan(planData: $planData) {
      name
      id
    }
  }
`;
export type CreateVacanciesPlanMutationFn = Apollo.MutationFunction<
  CreateVacanciesPlanMutation,
  CreateVacanciesPlanMutationVariables
>;

/**
 * __useCreateVacanciesPlanMutation__
 *
 * To run a mutation, you first call `useCreateVacanciesPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVacanciesPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVacanciesPlanMutation, { data, loading, error }] = useCreateVacanciesPlanMutation({
 *   variables: {
 *      planData: // value for 'planData'
 *   },
 * });
 */
export function useCreateVacanciesPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVacanciesPlanMutation,
    CreateVacanciesPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateVacanciesPlanMutation,
    CreateVacanciesPlanMutationVariables
  >(CreateVacanciesPlanDocument, options);
}
export type CreateVacanciesPlanMutationHookResult = ReturnType<
  typeof useCreateVacanciesPlanMutation
>;
export type CreateVacanciesPlanMutationResult =
  Apollo.MutationResult<CreateVacanciesPlanMutation>;
export type CreateVacanciesPlanMutationOptions = Apollo.BaseMutationOptions<
  CreateVacanciesPlanMutation,
  CreateVacanciesPlanMutationVariables
>;
export const UpdateVacanciesPlanDocument = gql`
  mutation updateVacanciesPlan($planData: UpdateVacanciesPlanInput!) {
    updateVacanciesPlan(planData: $planData) {
      name
      id
      status {
        id
        displayName
      }
    }
  }
`;
export type UpdateVacanciesPlanMutationFn = Apollo.MutationFunction<
  UpdateVacanciesPlanMutation,
  UpdateVacanciesPlanMutationVariables
>;

/**
 * __useUpdateVacanciesPlanMutation__
 *
 * To run a mutation, you first call `useUpdateVacanciesPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVacanciesPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVacanciesPlanMutation, { data, loading, error }] = useUpdateVacanciesPlanMutation({
 *   variables: {
 *      planData: // value for 'planData'
 *   },
 * });
 */
export function useUpdateVacanciesPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVacanciesPlanMutation,
    UpdateVacanciesPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVacanciesPlanMutation,
    UpdateVacanciesPlanMutationVariables
  >(UpdateVacanciesPlanDocument, options);
}
export type UpdateVacanciesPlanMutationHookResult = ReturnType<
  typeof useUpdateVacanciesPlanMutation
>;
export type UpdateVacanciesPlanMutationResult =
  Apollo.MutationResult<UpdateVacanciesPlanMutation>;
export type UpdateVacanciesPlanMutationOptions = Apollo.BaseMutationOptions<
  UpdateVacanciesPlanMutation,
  UpdateVacanciesPlanMutationVariables
>;
export const DeleteVacanciesPlanDocument = gql`
  mutation deleteVacanciesPlan($planId: String!) {
    deleteVacanciesPlan(planId: $planId)
  }
`;
export type DeleteVacanciesPlanMutationFn = Apollo.MutationFunction<
  DeleteVacanciesPlanMutation,
  DeleteVacanciesPlanMutationVariables
>;

/**
 * __useDeleteVacanciesPlanMutation__
 *
 * To run a mutation, you first call `useDeleteVacanciesPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVacanciesPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVacanciesPlanMutation, { data, loading, error }] = useDeleteVacanciesPlanMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useDeleteVacanciesPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVacanciesPlanMutation,
    DeleteVacanciesPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteVacanciesPlanMutation,
    DeleteVacanciesPlanMutationVariables
  >(DeleteVacanciesPlanDocument, options);
}
export type DeleteVacanciesPlanMutationHookResult = ReturnType<
  typeof useDeleteVacanciesPlanMutation
>;
export type DeleteVacanciesPlanMutationResult =
  Apollo.MutationResult<DeleteVacanciesPlanMutation>;
export type DeleteVacanciesPlanMutationOptions = Apollo.BaseMutationOptions<
  DeleteVacanciesPlanMutation,
  DeleteVacanciesPlanMutationVariables
>;
export const CreateTeacherGroupsClassroomsPupilsReportDocument = gql`
  mutation createTeacherGroupsClassroomsPupilsReport(
    $startDate: Date
    $endDate: Date
  ) {
    createTeacherGroupsClassroomsPupilsReport(
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      name
      type
      link
    }
  }
`;
export type CreateTeacherGroupsClassroomsPupilsReportMutationFn =
  Apollo.MutationFunction<
    CreateTeacherGroupsClassroomsPupilsReportMutation,
    CreateTeacherGroupsClassroomsPupilsReportMutationVariables
  >;

/**
 * __useCreateTeacherGroupsClassroomsPupilsReportMutation__
 *
 * To run a mutation, you first call `useCreateTeacherGroupsClassroomsPupilsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeacherGroupsClassroomsPupilsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeacherGroupsClassroomsPupilsReportMutation, { data, loading, error }] = useCreateTeacherGroupsClassroomsPupilsReportMutation({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCreateTeacherGroupsClassroomsPupilsReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTeacherGroupsClassroomsPupilsReportMutation,
    CreateTeacherGroupsClassroomsPupilsReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTeacherGroupsClassroomsPupilsReportMutation,
    CreateTeacherGroupsClassroomsPupilsReportMutationVariables
  >(CreateTeacherGroupsClassroomsPupilsReportDocument, options);
}
export type CreateTeacherGroupsClassroomsPupilsReportMutationHookResult =
  ReturnType<typeof useCreateTeacherGroupsClassroomsPupilsReportMutation>;
export type CreateTeacherGroupsClassroomsPupilsReportMutationResult =
  Apollo.MutationResult<CreateTeacherGroupsClassroomsPupilsReportMutation>;
export type CreateTeacherGroupsClassroomsPupilsReportMutationOptions =
  Apollo.BaseMutationOptions<
    CreateTeacherGroupsClassroomsPupilsReportMutation,
    CreateTeacherGroupsClassroomsPupilsReportMutationVariables
  >;
export const DeleteTeacherGroupsClassroomsPupilsReportDocument = gql`
  mutation deleteTeacherGroupsClassroomsPupilsReport($id: String!) {
    deleteTeacherGroupsClassroomsPupilsReport(id: $id)
  }
`;
export type DeleteTeacherGroupsClassroomsPupilsReportMutationFn =
  Apollo.MutationFunction<
    DeleteTeacherGroupsClassroomsPupilsReportMutation,
    DeleteTeacherGroupsClassroomsPupilsReportMutationVariables
  >;

/**
 * __useDeleteTeacherGroupsClassroomsPupilsReportMutation__
 *
 * To run a mutation, you first call `useDeleteTeacherGroupsClassroomsPupilsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeacherGroupsClassroomsPupilsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeacherGroupsClassroomsPupilsReportMutation, { data, loading, error }] = useDeleteTeacherGroupsClassroomsPupilsReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeacherGroupsClassroomsPupilsReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTeacherGroupsClassroomsPupilsReportMutation,
    DeleteTeacherGroupsClassroomsPupilsReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTeacherGroupsClassroomsPupilsReportMutation,
    DeleteTeacherGroupsClassroomsPupilsReportMutationVariables
  >(DeleteTeacherGroupsClassroomsPupilsReportDocument, options);
}
export type DeleteTeacherGroupsClassroomsPupilsReportMutationHookResult =
  ReturnType<typeof useDeleteTeacherGroupsClassroomsPupilsReportMutation>;
export type DeleteTeacherGroupsClassroomsPupilsReportMutationResult =
  Apollo.MutationResult<DeleteTeacherGroupsClassroomsPupilsReportMutation>;
export type DeleteTeacherGroupsClassroomsPupilsReportMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteTeacherGroupsClassroomsPupilsReportMutation,
    DeleteTeacherGroupsClassroomsPupilsReportMutationVariables
  >;
export const CreateSalaryHistoryDocument = gql`
  mutation createSalaryHistory(
    $userId: String!
    $newSalary: Int!
    $changeDate: Date!
    $description: String
  ) {
    createSalaryHistory(
      userId: $userId
      newSalary: $newSalary
      changeDate: $changeDate
      description: $description
    ) {
      id
    }
  }
`;
export type CreateSalaryHistoryMutationFn = Apollo.MutationFunction<
  CreateSalaryHistoryMutation,
  CreateSalaryHistoryMutationVariables
>;

/**
 * __useCreateSalaryHistoryMutation__
 *
 * To run a mutation, you first call `useCreateSalaryHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalaryHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalaryHistoryMutation, { data, loading, error }] = useCreateSalaryHistoryMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      newSalary: // value for 'newSalary'
 *      changeDate: // value for 'changeDate'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateSalaryHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSalaryHistoryMutation,
    CreateSalaryHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSalaryHistoryMutation,
    CreateSalaryHistoryMutationVariables
  >(CreateSalaryHistoryDocument, options);
}
export type CreateSalaryHistoryMutationHookResult = ReturnType<
  typeof useCreateSalaryHistoryMutation
>;
export type CreateSalaryHistoryMutationResult =
  Apollo.MutationResult<CreateSalaryHistoryMutation>;
export type CreateSalaryHistoryMutationOptions = Apollo.BaseMutationOptions<
  CreateSalaryHistoryMutation,
  CreateSalaryHistoryMutationVariables
>;
export const UpdateSalaryHistoryDocument = gql`
  mutation updateSalaryHistory(
    $salaryHistoryId: String!
    $newSalary: Int
    $changeDate: Date
    $description: String
  ) {
    updateSalaryHistory(
      salaryHistoryId: $salaryHistoryId
      newSalary: $newSalary
      changeDate: $changeDate
      description: $description
    ) {
      id
    }
  }
`;
export type UpdateSalaryHistoryMutationFn = Apollo.MutationFunction<
  UpdateSalaryHistoryMutation,
  UpdateSalaryHistoryMutationVariables
>;

/**
 * __useUpdateSalaryHistoryMutation__
 *
 * To run a mutation, you first call `useUpdateSalaryHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalaryHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalaryHistoryMutation, { data, loading, error }] = useUpdateSalaryHistoryMutation({
 *   variables: {
 *      salaryHistoryId: // value for 'salaryHistoryId'
 *      newSalary: // value for 'newSalary'
 *      changeDate: // value for 'changeDate'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateSalaryHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSalaryHistoryMutation,
    UpdateSalaryHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSalaryHistoryMutation,
    UpdateSalaryHistoryMutationVariables
  >(UpdateSalaryHistoryDocument, options);
}
export type UpdateSalaryHistoryMutationHookResult = ReturnType<
  typeof useUpdateSalaryHistoryMutation
>;
export type UpdateSalaryHistoryMutationResult =
  Apollo.MutationResult<UpdateSalaryHistoryMutation>;
export type UpdateSalaryHistoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateSalaryHistoryMutation,
  UpdateSalaryHistoryMutationVariables
>;
export const DeleteSalaryHistoryDocument = gql`
  mutation deleteSalaryHistory($salaryHistoryId: String!) {
    deleteSalaryHistory(salaryHistoryId: $salaryHistoryId)
  }
`;
export type DeleteSalaryHistoryMutationFn = Apollo.MutationFunction<
  DeleteSalaryHistoryMutation,
  DeleteSalaryHistoryMutationVariables
>;

/**
 * __useDeleteSalaryHistoryMutation__
 *
 * To run a mutation, you first call `useDeleteSalaryHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalaryHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalaryHistoryMutation, { data, loading, error }] = useDeleteSalaryHistoryMutation({
 *   variables: {
 *      salaryHistoryId: // value for 'salaryHistoryId'
 *   },
 * });
 */
export function useDeleteSalaryHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSalaryHistoryMutation,
    DeleteSalaryHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSalaryHistoryMutation,
    DeleteSalaryHistoryMutationVariables
  >(DeleteSalaryHistoryDocument, options);
}
export type DeleteSalaryHistoryMutationHookResult = ReturnType<
  typeof useDeleteSalaryHistoryMutation
>;
export type DeleteSalaryHistoryMutationResult =
  Apollo.MutationResult<DeleteSalaryHistoryMutation>;
export type DeleteSalaryHistoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteSalaryHistoryMutation,
  DeleteSalaryHistoryMutationVariables
>;
export const CreatePayrollSalaryPartDocument = gql`
  mutation createPayrollSalaryPart(
    $payrollId: String!
    $name: String!
    $value: Int!
    $date: Date!
  ) {
    createPayrollSalaryPart(
      payrollId: $payrollId
      name: $name
      value: $value
      date: $date
    ) {
      id
    }
  }
`;
export type CreatePayrollSalaryPartMutationFn = Apollo.MutationFunction<
  CreatePayrollSalaryPartMutation,
  CreatePayrollSalaryPartMutationVariables
>;

/**
 * __useCreatePayrollSalaryPartMutation__
 *
 * To run a mutation, you first call `useCreatePayrollSalaryPartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayrollSalaryPartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayrollSalaryPartMutation, { data, loading, error }] = useCreatePayrollSalaryPartMutation({
 *   variables: {
 *      payrollId: // value for 'payrollId'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useCreatePayrollSalaryPartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePayrollSalaryPartMutation,
    CreatePayrollSalaryPartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePayrollSalaryPartMutation,
    CreatePayrollSalaryPartMutationVariables
  >(CreatePayrollSalaryPartDocument, options);
}
export type CreatePayrollSalaryPartMutationHookResult = ReturnType<
  typeof useCreatePayrollSalaryPartMutation
>;
export type CreatePayrollSalaryPartMutationResult =
  Apollo.MutationResult<CreatePayrollSalaryPartMutation>;
export type CreatePayrollSalaryPartMutationOptions = Apollo.BaseMutationOptions<
  CreatePayrollSalaryPartMutation,
  CreatePayrollSalaryPartMutationVariables
>;
export const UpdatePayrollSalaryPartDocument = gql`
  mutation updatePayrollSalaryPart(
    $id: String!
    $name: String
    $value: Int
    $date: Date
  ) {
    updatePayrollSalaryPart(id: $id, name: $name, value: $value, date: $date) {
      id
    }
  }
`;
export type UpdatePayrollSalaryPartMutationFn = Apollo.MutationFunction<
  UpdatePayrollSalaryPartMutation,
  UpdatePayrollSalaryPartMutationVariables
>;

/**
 * __useUpdatePayrollSalaryPartMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollSalaryPartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollSalaryPartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollSalaryPartMutation, { data, loading, error }] = useUpdatePayrollSalaryPartMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdatePayrollSalaryPartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayrollSalaryPartMutation,
    UpdatePayrollSalaryPartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePayrollSalaryPartMutation,
    UpdatePayrollSalaryPartMutationVariables
  >(UpdatePayrollSalaryPartDocument, options);
}
export type UpdatePayrollSalaryPartMutationHookResult = ReturnType<
  typeof useUpdatePayrollSalaryPartMutation
>;
export type UpdatePayrollSalaryPartMutationResult =
  Apollo.MutationResult<UpdatePayrollSalaryPartMutation>;
export type UpdatePayrollSalaryPartMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayrollSalaryPartMutation,
  UpdatePayrollSalaryPartMutationVariables
>;
export const CreateSchoolDocument = gql`
  mutation createSchool($schoolData: CreateSchoolInput!, $photo: Upload) {
    createSchool(schoolData: $schoolData, photo: $photo) {
      id
    }
  }
`;
export type CreateSchoolMutationFn = Apollo.MutationFunction<
  CreateSchoolMutation,
  CreateSchoolMutationVariables
>;

/**
 * __useCreateSchoolMutation__
 *
 * To run a mutation, you first call `useCreateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolMutation, { data, loading, error }] = useCreateSchoolMutation({
 *   variables: {
 *      schoolData: // value for 'schoolData'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useCreateSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSchoolMutation,
    CreateSchoolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSchoolMutation,
    CreateSchoolMutationVariables
  >(CreateSchoolDocument, options);
}
export type CreateSchoolMutationHookResult = ReturnType<
  typeof useCreateSchoolMutation
>;
export type CreateSchoolMutationResult =
  Apollo.MutationResult<CreateSchoolMutation>;
export type CreateSchoolMutationOptions = Apollo.BaseMutationOptions<
  CreateSchoolMutation,
  CreateSchoolMutationVariables
>;
export const UpdateSchoolDocument = gql`
  mutation updateSchool(
    $schoolData: CreateSchoolInput!
    $photo: Upload
    $id: String!
  ) {
    updateSchool(schoolData: $schoolData, photo: $photo, id: $id) {
      id
    }
  }
`;
export type UpdateSchoolMutationFn = Apollo.MutationFunction<
  UpdateSchoolMutation,
  UpdateSchoolMutationVariables
>;

/**
 * __useUpdateSchoolMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolMutation, { data, loading, error }] = useUpdateSchoolMutation({
 *   variables: {
 *      schoolData: // value for 'schoolData'
 *      photo: // value for 'photo'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSchoolMutation,
    UpdateSchoolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSchoolMutation,
    UpdateSchoolMutationVariables
  >(UpdateSchoolDocument, options);
}
export type UpdateSchoolMutationHookResult = ReturnType<
  typeof useUpdateSchoolMutation
>;
export type UpdateSchoolMutationResult =
  Apollo.MutationResult<UpdateSchoolMutation>;
export type UpdateSchoolMutationOptions = Apollo.BaseMutationOptions<
  UpdateSchoolMutation,
  UpdateSchoolMutationVariables
>;
export const DeleteSchoolDocument = gql`
  mutation deleteSchool($id: String!) {
    deleteSchool(id: $id)
  }
`;
export type DeleteSchoolMutationFn = Apollo.MutationFunction<
  DeleteSchoolMutation,
  DeleteSchoolMutationVariables
>;

/**
 * __useDeleteSchoolMutation__
 *
 * To run a mutation, you first call `useDeleteSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchoolMutation, { data, loading, error }] = useDeleteSchoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSchoolMutation,
    DeleteSchoolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSchoolMutation,
    DeleteSchoolMutationVariables
  >(DeleteSchoolDocument, options);
}
export type DeleteSchoolMutationHookResult = ReturnType<
  typeof useDeleteSchoolMutation
>;
export type DeleteSchoolMutationResult =
  Apollo.MutationResult<DeleteSchoolMutation>;
export type DeleteSchoolMutationOptions = Apollo.BaseMutationOptions<
  DeleteSchoolMutation,
  DeleteSchoolMutationVariables
>;
export const AddSchoolAdminDocument = gql`
  mutation addSchoolAdmin(
    $schoolId: String!
    $teacherId: String!
    $teacherPosition: String
  ) {
    addSchoolAdmin(
      schoolId: $schoolId
      teacherId: $teacherId
      teacherPosition: $teacherPosition
    )
  }
`;
export type AddSchoolAdminMutationFn = Apollo.MutationFunction<
  AddSchoolAdminMutation,
  AddSchoolAdminMutationVariables
>;

/**
 * __useAddSchoolAdminMutation__
 *
 * To run a mutation, you first call `useAddSchoolAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSchoolAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSchoolAdminMutation, { data, loading, error }] = useAddSchoolAdminMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      teacherId: // value for 'teacherId'
 *      teacherPosition: // value for 'teacherPosition'
 *   },
 * });
 */
export function useAddSchoolAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSchoolAdminMutation,
    AddSchoolAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSchoolAdminMutation,
    AddSchoolAdminMutationVariables
  >(AddSchoolAdminDocument, options);
}
export type AddSchoolAdminMutationHookResult = ReturnType<
  typeof useAddSchoolAdminMutation
>;
export type AddSchoolAdminMutationResult =
  Apollo.MutationResult<AddSchoolAdminMutation>;
export type AddSchoolAdminMutationOptions = Apollo.BaseMutationOptions<
  AddSchoolAdminMutation,
  AddSchoolAdminMutationVariables
>;
export const AddTeacherToSchoolDocument = gql`
  mutation addTeacherToSchool(
    $schoolId: String!
    $teacherPosition: String
    $teacherStatus: SchoolUserStatusEnum
    $teacherId: String!
  ) {
    addTeacherToSchool(
      schoolId: $schoolId
      teacherPosition: $teacherPosition
      teacherStatus: $teacherStatus
      teacherId: $teacherId
    )
  }
`;
export type AddTeacherToSchoolMutationFn = Apollo.MutationFunction<
  AddTeacherToSchoolMutation,
  AddTeacherToSchoolMutationVariables
>;

/**
 * __useAddTeacherToSchoolMutation__
 *
 * To run a mutation, you first call `useAddTeacherToSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeacherToSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeacherToSchoolMutation, { data, loading, error }] = useAddTeacherToSchoolMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      teacherPosition: // value for 'teacherPosition'
 *      teacherStatus: // value for 'teacherStatus'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useAddTeacherToSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTeacherToSchoolMutation,
    AddTeacherToSchoolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTeacherToSchoolMutation,
    AddTeacherToSchoolMutationVariables
  >(AddTeacherToSchoolDocument, options);
}
export type AddTeacherToSchoolMutationHookResult = ReturnType<
  typeof useAddTeacherToSchoolMutation
>;
export type AddTeacherToSchoolMutationResult =
  Apollo.MutationResult<AddTeacherToSchoolMutation>;
export type AddTeacherToSchoolMutationOptions = Apollo.BaseMutationOptions<
  AddTeacherToSchoolMutation,
  AddTeacherToSchoolMutationVariables
>;
export const RemoveTeacherFromSchoolDocument = gql`
  mutation removeTeacherFromSchool($schoolId: String!, $teacherId: String!) {
    removeTeacherFromSchool(schoolId: $schoolId, teacherId: $teacherId)
  }
`;
export type RemoveTeacherFromSchoolMutationFn = Apollo.MutationFunction<
  RemoveTeacherFromSchoolMutation,
  RemoveTeacherFromSchoolMutationVariables
>;

/**
 * __useRemoveTeacherFromSchoolMutation__
 *
 * To run a mutation, you first call `useRemoveTeacherFromSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeacherFromSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeacherFromSchoolMutation, { data, loading, error }] = useRemoveTeacherFromSchoolMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useRemoveTeacherFromSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTeacherFromSchoolMutation,
    RemoveTeacherFromSchoolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveTeacherFromSchoolMutation,
    RemoveTeacherFromSchoolMutationVariables
  >(RemoveTeacherFromSchoolDocument, options);
}
export type RemoveTeacherFromSchoolMutationHookResult = ReturnType<
  typeof useRemoveTeacherFromSchoolMutation
>;
export type RemoveTeacherFromSchoolMutationResult =
  Apollo.MutationResult<RemoveTeacherFromSchoolMutation>;
export type RemoveTeacherFromSchoolMutationOptions = Apollo.BaseMutationOptions<
  RemoveTeacherFromSchoolMutation,
  RemoveTeacherFromSchoolMutationVariables
>;
export const InviteUsersByListDocument = gql`
  mutation inviteUsersByList($list: Upload!, $schoolId: UUID!) {
    inviteUsersByListV3(list: $list, schoolId: $schoolId) {
      line
      errors
    }
  }
`;
export type InviteUsersByListMutationFn = Apollo.MutationFunction<
  InviteUsersByListMutation,
  InviteUsersByListMutationVariables
>;

/**
 * __useInviteUsersByListMutation__
 *
 * To run a mutation, you first call `useInviteUsersByListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersByListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersByListMutation, { data, loading, error }] = useInviteUsersByListMutation({
 *   variables: {
 *      list: // value for 'list'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useInviteUsersByListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUsersByListMutation,
    InviteUsersByListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteUsersByListMutation,
    InviteUsersByListMutationVariables
  >(InviteUsersByListDocument, options);
}
export type InviteUsersByListMutationHookResult = ReturnType<
  typeof useInviteUsersByListMutation
>;
export type InviteUsersByListMutationResult =
  Apollo.MutationResult<InviteUsersByListMutation>;
export type InviteUsersByListMutationOptions = Apollo.BaseMutationOptions<
  InviteUsersByListMutation,
  InviteUsersByListMutationVariables
>;
export const InviteUsersByListV3Document = gql`
  mutation inviteUsersByListV3($list: Upload!, $schoolId: UUID!) {
    inviteUsersByListV3(list: $list, schoolId: $schoolId) {
      line
      errors
    }
  }
`;
export type InviteUsersByListV3MutationFn = Apollo.MutationFunction<
  InviteUsersByListV3Mutation,
  InviteUsersByListV3MutationVariables
>;

/**
 * __useInviteUsersByListV3Mutation__
 *
 * To run a mutation, you first call `useInviteUsersByListV3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersByListV3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersByListV3Mutation, { data, loading, error }] = useInviteUsersByListV3Mutation({
 *   variables: {
 *      list: // value for 'list'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useInviteUsersByListV3Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUsersByListV3Mutation,
    InviteUsersByListV3MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteUsersByListV3Mutation,
    InviteUsersByListV3MutationVariables
  >(InviteUsersByListV3Document, options);
}
export type InviteUsersByListV3MutationHookResult = ReturnType<
  typeof useInviteUsersByListV3Mutation
>;
export type InviteUsersByListV3MutationResult =
  Apollo.MutationResult<InviteUsersByListV3Mutation>;
export type InviteUsersByListV3MutationOptions = Apollo.BaseMutationOptions<
  InviteUsersByListV3Mutation,
  InviteUsersByListV3MutationVariables
>;
export const AddCourseToSchoolDocument = gql`
  mutation addCourseToSchool($schoolId: UUID!, $courseId: UUID!) {
    addCourseToSchool(schoolId: $schoolId, courseId: $courseId)
  }
`;
export type AddCourseToSchoolMutationFn = Apollo.MutationFunction<
  AddCourseToSchoolMutation,
  AddCourseToSchoolMutationVariables
>;

/**
 * __useAddCourseToSchoolMutation__
 *
 * To run a mutation, you first call `useAddCourseToSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCourseToSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCourseToSchoolMutation, { data, loading, error }] = useAddCourseToSchoolMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useAddCourseToSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCourseToSchoolMutation,
    AddCourseToSchoolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCourseToSchoolMutation,
    AddCourseToSchoolMutationVariables
  >(AddCourseToSchoolDocument, options);
}
export type AddCourseToSchoolMutationHookResult = ReturnType<
  typeof useAddCourseToSchoolMutation
>;
export type AddCourseToSchoolMutationResult =
  Apollo.MutationResult<AddCourseToSchoolMutation>;
export type AddCourseToSchoolMutationOptions = Apollo.BaseMutationOptions<
  AddCourseToSchoolMutation,
  AddCourseToSchoolMutationVariables
>;
export const RemoveCourseFromSchoolDocument = gql`
  mutation removeCourseFromSchool($schoolId: UUID!, $courseId: UUID!) {
    removeCourseFromSchool(schoolId: $schoolId, courseId: $courseId)
  }
`;
export type RemoveCourseFromSchoolMutationFn = Apollo.MutationFunction<
  RemoveCourseFromSchoolMutation,
  RemoveCourseFromSchoolMutationVariables
>;

/**
 * __useRemoveCourseFromSchoolMutation__
 *
 * To run a mutation, you first call `useRemoveCourseFromSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCourseFromSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCourseFromSchoolMutation, { data, loading, error }] = useRemoveCourseFromSchoolMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useRemoveCourseFromSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCourseFromSchoolMutation,
    RemoveCourseFromSchoolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCourseFromSchoolMutation,
    RemoveCourseFromSchoolMutationVariables
  >(RemoveCourseFromSchoolDocument, options);
}
export type RemoveCourseFromSchoolMutationHookResult = ReturnType<
  typeof useRemoveCourseFromSchoolMutation
>;
export type RemoveCourseFromSchoolMutationResult =
  Apollo.MutationResult<RemoveCourseFromSchoolMutation>;
export type RemoveCourseFromSchoolMutationOptions = Apollo.BaseMutationOptions<
  RemoveCourseFromSchoolMutation,
  RemoveCourseFromSchoolMutationVariables
>;
export const AddCourseToSchoolV3Document = gql`
  mutation AddCourseToSchoolV3($schoolId: UUID!, $courseId: UUID!) {
    addCourseToSchoolV3(schoolId: $schoolId, courseId: $courseId)
  }
`;
export type AddCourseToSchoolV3MutationFn = Apollo.MutationFunction<
  AddCourseToSchoolV3Mutation,
  AddCourseToSchoolV3MutationVariables
>;

/**
 * __useAddCourseToSchoolV3Mutation__
 *
 * To run a mutation, you first call `useAddCourseToSchoolV3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCourseToSchoolV3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCourseToSchoolV3Mutation, { data, loading, error }] = useAddCourseToSchoolV3Mutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useAddCourseToSchoolV3Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCourseToSchoolV3Mutation,
    AddCourseToSchoolV3MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCourseToSchoolV3Mutation,
    AddCourseToSchoolV3MutationVariables
  >(AddCourseToSchoolV3Document, options);
}
export type AddCourseToSchoolV3MutationHookResult = ReturnType<
  typeof useAddCourseToSchoolV3Mutation
>;
export type AddCourseToSchoolV3MutationResult =
  Apollo.MutationResult<AddCourseToSchoolV3Mutation>;
export type AddCourseToSchoolV3MutationOptions = Apollo.BaseMutationOptions<
  AddCourseToSchoolV3Mutation,
  AddCourseToSchoolV3MutationVariables
>;
export const CreateSubscriptionPlanPaymentFromAdminPanelDocument = gql`
  mutation createSubscriptionPlanPaymentFromAdminPanel(
    $data: SubscriptionPlanPaymentData!
  ) {
    createSubscriptionPlanPaymentFromAdminPanel(data: $data) {
      id
    }
  }
`;
export type CreateSubscriptionPlanPaymentFromAdminPanelMutationFn =
  Apollo.MutationFunction<
    CreateSubscriptionPlanPaymentFromAdminPanelMutation,
    CreateSubscriptionPlanPaymentFromAdminPanelMutationVariables
  >;

/**
 * __useCreateSubscriptionPlanPaymentFromAdminPanelMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionPlanPaymentFromAdminPanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionPlanPaymentFromAdminPanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionPlanPaymentFromAdminPanelMutation, { data, loading, error }] = useCreateSubscriptionPlanPaymentFromAdminPanelMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSubscriptionPlanPaymentFromAdminPanelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubscriptionPlanPaymentFromAdminPanelMutation,
    CreateSubscriptionPlanPaymentFromAdminPanelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSubscriptionPlanPaymentFromAdminPanelMutation,
    CreateSubscriptionPlanPaymentFromAdminPanelMutationVariables
  >(CreateSubscriptionPlanPaymentFromAdminPanelDocument, options);
}
export type CreateSubscriptionPlanPaymentFromAdminPanelMutationHookResult =
  ReturnType<typeof useCreateSubscriptionPlanPaymentFromAdminPanelMutation>;
export type CreateSubscriptionPlanPaymentFromAdminPanelMutationResult =
  Apollo.MutationResult<CreateSubscriptionPlanPaymentFromAdminPanelMutation>;
export type CreateSubscriptionPlanPaymentFromAdminPanelMutationOptions =
  Apollo.BaseMutationOptions<
    CreateSubscriptionPlanPaymentFromAdminPanelMutation,
    CreateSubscriptionPlanPaymentFromAdminPanelMutationVariables
  >;
export const CreatePaymentFormAdminPanelDocument = gql`
  mutation createPaymentFormAdminPanel($data: PaymentData!) {
    createPaymentFormAdminPanel(data: $data) {
      id
    }
  }
`;
export type CreatePaymentFormAdminPanelMutationFn = Apollo.MutationFunction<
  CreatePaymentFormAdminPanelMutation,
  CreatePaymentFormAdminPanelMutationVariables
>;

/**
 * __useCreatePaymentFormAdminPanelMutation__
 *
 * To run a mutation, you first call `useCreatePaymentFormAdminPanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentFormAdminPanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentFormAdminPanelMutation, { data, loading, error }] = useCreatePaymentFormAdminPanelMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaymentFormAdminPanelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentFormAdminPanelMutation,
    CreatePaymentFormAdminPanelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePaymentFormAdminPanelMutation,
    CreatePaymentFormAdminPanelMutationVariables
  >(CreatePaymentFormAdminPanelDocument, options);
}
export type CreatePaymentFormAdminPanelMutationHookResult = ReturnType<
  typeof useCreatePaymentFormAdminPanelMutation
>;
export type CreatePaymentFormAdminPanelMutationResult =
  Apollo.MutationResult<CreatePaymentFormAdminPanelMutation>;
export type CreatePaymentFormAdminPanelMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePaymentFormAdminPanelMutation,
    CreatePaymentFormAdminPanelMutationVariables
  >;
export const HandlePaymentActivationDocument = gql`
  mutation handlePaymentActivation($id: String!, $isActivated: Boolean) {
    handlePaymentActivation(id: $id, isActivated: $isActivated)
  }
`;
export type HandlePaymentActivationMutationFn = Apollo.MutationFunction<
  HandlePaymentActivationMutation,
  HandlePaymentActivationMutationVariables
>;

/**
 * __useHandlePaymentActivationMutation__
 *
 * To run a mutation, you first call `useHandlePaymentActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandlePaymentActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handlePaymentActivationMutation, { data, loading, error }] = useHandlePaymentActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActivated: // value for 'isActivated'
 *   },
 * });
 */
export function useHandlePaymentActivationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HandlePaymentActivationMutation,
    HandlePaymentActivationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HandlePaymentActivationMutation,
    HandlePaymentActivationMutationVariables
  >(HandlePaymentActivationDocument, options);
}
export type HandlePaymentActivationMutationHookResult = ReturnType<
  typeof useHandlePaymentActivationMutation
>;
export type HandlePaymentActivationMutationResult =
  Apollo.MutationResult<HandlePaymentActivationMutation>;
export type HandlePaymentActivationMutationOptions = Apollo.BaseMutationOptions<
  HandlePaymentActivationMutation,
  HandlePaymentActivationMutationVariables
>;
export const DeletePaymentDocument = gql`
  mutation deletePayment($id: String!) {
    deletePayment(id: $id)
  }
`;
export type DeletePaymentMutationFn = Apollo.MutationFunction<
  DeletePaymentMutation,
  DeletePaymentMutationVariables
>;

/**
 * __useDeletePaymentMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMutation, { data, loading, error }] = useDeletePaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePaymentMutation,
    DeletePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePaymentMutation,
    DeletePaymentMutationVariables
  >(DeletePaymentDocument, options);
}
export type DeletePaymentMutationHookResult = ReturnType<
  typeof useDeletePaymentMutation
>;
export type DeletePaymentMutationResult =
  Apollo.MutationResult<DeletePaymentMutation>;
export type DeletePaymentMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentMutation,
  DeletePaymentMutationVariables
>;
export const CreatePurchasePaymentFromAdminPanelDocument = gql`
  mutation CreatePurchasePaymentFromAdminPanel(
    $schoolId: UUID!
    $paymentDate: Date!
    $paymentType: AllowedAdminPanelPaymentType!
    $productId: Int!
    $price: Float!
  ) {
    createPurchasePaymentFromAdminPanel(
      schoolId: $schoolId
      paymentDate: $paymentDate
      paymentType: $paymentType
      productId: $productId
      price: $price
    )
  }
`;
export type CreatePurchasePaymentFromAdminPanelMutationFn =
  Apollo.MutationFunction<
    CreatePurchasePaymentFromAdminPanelMutation,
    CreatePurchasePaymentFromAdminPanelMutationVariables
  >;

/**
 * __useCreatePurchasePaymentFromAdminPanelMutation__
 *
 * To run a mutation, you first call `useCreatePurchasePaymentFromAdminPanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchasePaymentFromAdminPanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchasePaymentFromAdminPanelMutation, { data, loading, error }] = useCreatePurchasePaymentFromAdminPanelMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      paymentDate: // value for 'paymentDate'
 *      paymentType: // value for 'paymentType'
 *      productId: // value for 'productId'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useCreatePurchasePaymentFromAdminPanelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePurchasePaymentFromAdminPanelMutation,
    CreatePurchasePaymentFromAdminPanelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePurchasePaymentFromAdminPanelMutation,
    CreatePurchasePaymentFromAdminPanelMutationVariables
  >(CreatePurchasePaymentFromAdminPanelDocument, options);
}
export type CreatePurchasePaymentFromAdminPanelMutationHookResult = ReturnType<
  typeof useCreatePurchasePaymentFromAdminPanelMutation
>;
export type CreatePurchasePaymentFromAdminPanelMutationResult =
  Apollo.MutationResult<CreatePurchasePaymentFromAdminPanelMutation>;
export type CreatePurchasePaymentFromAdminPanelMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePurchasePaymentFromAdminPanelMutation,
    CreatePurchasePaymentFromAdminPanelMutationVariables
  >;
export const DeactivatePurchasePaymentDocument = gql`
  mutation DeactivatePurchasePayment($paymentId: UUID!) {
    deactivatePurchasePayment(paymentId: $paymentId)
  }
`;
export type DeactivatePurchasePaymentMutationFn = Apollo.MutationFunction<
  DeactivatePurchasePaymentMutation,
  DeactivatePurchasePaymentMutationVariables
>;

/**
 * __useDeactivatePurchasePaymentMutation__
 *
 * To run a mutation, you first call `useDeactivatePurchasePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivatePurchasePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivatePurchasePaymentMutation, { data, loading, error }] = useDeactivatePurchasePaymentMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useDeactivatePurchasePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivatePurchasePaymentMutation,
    DeactivatePurchasePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivatePurchasePaymentMutation,
    DeactivatePurchasePaymentMutationVariables
  >(DeactivatePurchasePaymentDocument, options);
}
export type DeactivatePurchasePaymentMutationHookResult = ReturnType<
  typeof useDeactivatePurchasePaymentMutation
>;
export type DeactivatePurchasePaymentMutationResult =
  Apollo.MutationResult<DeactivatePurchasePaymentMutation>;
export type DeactivatePurchasePaymentMutationOptions =
  Apollo.BaseMutationOptions<
    DeactivatePurchasePaymentMutation,
    DeactivatePurchasePaymentMutationVariables
  >;
export const CreatePersonalSubscriptionV3FromAdminPanelDocument = gql`
  mutation createPersonalSubscriptionV3FromAdminPanel($data: PaymentDataV3!) {
    createPersonalSubscriptionV3FromAdminPanel(data: $data)
  }
`;
export type CreatePersonalSubscriptionV3FromAdminPanelMutationFn =
  Apollo.MutationFunction<
    CreatePersonalSubscriptionV3FromAdminPanelMutation,
    CreatePersonalSubscriptionV3FromAdminPanelMutationVariables
  >;

/**
 * __useCreatePersonalSubscriptionV3FromAdminPanelMutation__
 *
 * To run a mutation, you first call `useCreatePersonalSubscriptionV3FromAdminPanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonalSubscriptionV3FromAdminPanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonalSubscriptionV3FromAdminPanelMutation, { data, loading, error }] = useCreatePersonalSubscriptionV3FromAdminPanelMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePersonalSubscriptionV3FromAdminPanelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePersonalSubscriptionV3FromAdminPanelMutation,
    CreatePersonalSubscriptionV3FromAdminPanelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePersonalSubscriptionV3FromAdminPanelMutation,
    CreatePersonalSubscriptionV3FromAdminPanelMutationVariables
  >(CreatePersonalSubscriptionV3FromAdminPanelDocument, options);
}
export type CreatePersonalSubscriptionV3FromAdminPanelMutationHookResult =
  ReturnType<typeof useCreatePersonalSubscriptionV3FromAdminPanelMutation>;
export type CreatePersonalSubscriptionV3FromAdminPanelMutationResult =
  Apollo.MutationResult<CreatePersonalSubscriptionV3FromAdminPanelMutation>;
export type CreatePersonalSubscriptionV3FromAdminPanelMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePersonalSubscriptionV3FromAdminPanelMutation,
    CreatePersonalSubscriptionV3FromAdminPanelMutationVariables
  >;
export const SetTaskBoundariesDocument = gql`
  mutation setTaskBoundaries(
    $id: String!
    $startDateEstimate: String!
    $finishDateEstimate: String!
    $isEpic: Boolean
  ) {
    setTaskBoundaries(
      id: $id
      startDateEstimate: $startDateEstimate
      finishDateEstimate: $finishDateEstimate
      isEpic: $isEpic
    )
  }
`;
export type SetTaskBoundariesMutationFn = Apollo.MutationFunction<
  SetTaskBoundariesMutation,
  SetTaskBoundariesMutationVariables
>;

/**
 * __useSetTaskBoundariesMutation__
 *
 * To run a mutation, you first call `useSetTaskBoundariesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTaskBoundariesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTaskBoundariesMutation, { data, loading, error }] = useSetTaskBoundariesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startDateEstimate: // value for 'startDateEstimate'
 *      finishDateEstimate: // value for 'finishDateEstimate'
 *      isEpic: // value for 'isEpic'
 *   },
 * });
 */
export function useSetTaskBoundariesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetTaskBoundariesMutation,
    SetTaskBoundariesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetTaskBoundariesMutation,
    SetTaskBoundariesMutationVariables
  >(SetTaskBoundariesDocument, options);
}
export type SetTaskBoundariesMutationHookResult = ReturnType<
  typeof useSetTaskBoundariesMutation
>;
export type SetTaskBoundariesMutationResult =
  Apollo.MutationResult<SetTaskBoundariesMutation>;
export type SetTaskBoundariesMutationOptions = Apollo.BaseMutationOptions<
  SetTaskBoundariesMutation,
  SetTaskBoundariesMutationVariables
>;
export const CreateSmartNotificationDocument = gql`
  mutation createSmartNotification(
    $smartNotificationData: CreateSmartNotificationInput!
    $photo: Upload
    $video: Upload
  ) {
    createSmartNotification(
      smartNotificationData: $smartNotificationData
      photo: $photo
      video: $video
    ) {
      id
    }
  }
`;
export type CreateSmartNotificationMutationFn = Apollo.MutationFunction<
  CreateSmartNotificationMutation,
  CreateSmartNotificationMutationVariables
>;

/**
 * __useCreateSmartNotificationMutation__
 *
 * To run a mutation, you first call `useCreateSmartNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSmartNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSmartNotificationMutation, { data, loading, error }] = useCreateSmartNotificationMutation({
 *   variables: {
 *      smartNotificationData: // value for 'smartNotificationData'
 *      photo: // value for 'photo'
 *      video: // value for 'video'
 *   },
 * });
 */
export function useCreateSmartNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSmartNotificationMutation,
    CreateSmartNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSmartNotificationMutation,
    CreateSmartNotificationMutationVariables
  >(CreateSmartNotificationDocument, options);
}
export type CreateSmartNotificationMutationHookResult = ReturnType<
  typeof useCreateSmartNotificationMutation
>;
export type CreateSmartNotificationMutationResult =
  Apollo.MutationResult<CreateSmartNotificationMutation>;
export type CreateSmartNotificationMutationOptions = Apollo.BaseMutationOptions<
  CreateSmartNotificationMutation,
  CreateSmartNotificationMutationVariables
>;
export const UpdateSmartNotificationDocument = gql`
  mutation updateSmartNotification(
    $smartNotificationData: UpdateSmartNotificationInput!
    $photo: Upload
  ) {
    updateSmartNotification(
      smartNotificationData: $smartNotificationData
      photo: $photo
    ) {
      id
    }
  }
`;
export type UpdateSmartNotificationMutationFn = Apollo.MutationFunction<
  UpdateSmartNotificationMutation,
  UpdateSmartNotificationMutationVariables
>;

/**
 * __useUpdateSmartNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateSmartNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmartNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmartNotificationMutation, { data, loading, error }] = useUpdateSmartNotificationMutation({
 *   variables: {
 *      smartNotificationData: // value for 'smartNotificationData'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useUpdateSmartNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSmartNotificationMutation,
    UpdateSmartNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSmartNotificationMutation,
    UpdateSmartNotificationMutationVariables
  >(UpdateSmartNotificationDocument, options);
}
export type UpdateSmartNotificationMutationHookResult = ReturnType<
  typeof useUpdateSmartNotificationMutation
>;
export type UpdateSmartNotificationMutationResult =
  Apollo.MutationResult<UpdateSmartNotificationMutation>;
export type UpdateSmartNotificationMutationOptions = Apollo.BaseMutationOptions<
  UpdateSmartNotificationMutation,
  UpdateSmartNotificationMutationVariables
>;
export const DeleteSmartNotificationDocument = gql`
  mutation deleteSmartNotification($id: String!) {
    deleteSmartNotification(id: $id)
  }
`;
export type DeleteSmartNotificationMutationFn = Apollo.MutationFunction<
  DeleteSmartNotificationMutation,
  DeleteSmartNotificationMutationVariables
>;

/**
 * __useDeleteSmartNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteSmartNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSmartNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSmartNotificationMutation, { data, loading, error }] = useDeleteSmartNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSmartNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSmartNotificationMutation,
    DeleteSmartNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSmartNotificationMutation,
    DeleteSmartNotificationMutationVariables
  >(DeleteSmartNotificationDocument, options);
}
export type DeleteSmartNotificationMutationHookResult = ReturnType<
  typeof useDeleteSmartNotificationMutation
>;
export type DeleteSmartNotificationMutationResult =
  Apollo.MutationResult<DeleteSmartNotificationMutation>;
export type DeleteSmartNotificationMutationOptions = Apollo.BaseMutationOptions<
  DeleteSmartNotificationMutation,
  DeleteSmartNotificationMutationVariables
>;
export const PublishSmartNotificationDocument = gql`
  mutation publishSmartNotification($id: String!) {
    publishSmartNotification(id: $id)
  }
`;
export type PublishSmartNotificationMutationFn = Apollo.MutationFunction<
  PublishSmartNotificationMutation,
  PublishSmartNotificationMutationVariables
>;

/**
 * __usePublishSmartNotificationMutation__
 *
 * To run a mutation, you first call `usePublishSmartNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishSmartNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishSmartNotificationMutation, { data, loading, error }] = usePublishSmartNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishSmartNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishSmartNotificationMutation,
    PublishSmartNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishSmartNotificationMutation,
    PublishSmartNotificationMutationVariables
  >(PublishSmartNotificationDocument, options);
}
export type PublishSmartNotificationMutationHookResult = ReturnType<
  typeof usePublishSmartNotificationMutation
>;
export type PublishSmartNotificationMutationResult =
  Apollo.MutationResult<PublishSmartNotificationMutation>;
export type PublishSmartNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    PublishSmartNotificationMutation,
    PublishSmartNotificationMutationVariables
  >;
export const UnpublishSmartNotificationDocument = gql`
  mutation unpublishSmartNotification($id: String!) {
    unpublishSmartNotification(id: $id)
  }
`;
export type UnpublishSmartNotificationMutationFn = Apollo.MutationFunction<
  UnpublishSmartNotificationMutation,
  UnpublishSmartNotificationMutationVariables
>;

/**
 * __useUnpublishSmartNotificationMutation__
 *
 * To run a mutation, you first call `useUnpublishSmartNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishSmartNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishSmartNotificationMutation, { data, loading, error }] = useUnpublishSmartNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnpublishSmartNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnpublishSmartNotificationMutation,
    UnpublishSmartNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnpublishSmartNotificationMutation,
    UnpublishSmartNotificationMutationVariables
  >(UnpublishSmartNotificationDocument, options);
}
export type UnpublishSmartNotificationMutationHookResult = ReturnType<
  typeof useUnpublishSmartNotificationMutation
>;
export type UnpublishSmartNotificationMutationResult =
  Apollo.MutationResult<UnpublishSmartNotificationMutation>;
export type UnpublishSmartNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    UnpublishSmartNotificationMutation,
    UnpublishSmartNotificationMutationVariables
  >;
export const DeleteSmartNotificationPhotoDocument = gql`
  mutation deleteSmartNotificationPhoto($id: String!) {
    deleteSmartNotificationPhoto(id: $id)
  }
`;
export type DeleteSmartNotificationPhotoMutationFn = Apollo.MutationFunction<
  DeleteSmartNotificationPhotoMutation,
  DeleteSmartNotificationPhotoMutationVariables
>;

/**
 * __useDeleteSmartNotificationPhotoMutation__
 *
 * To run a mutation, you first call `useDeleteSmartNotificationPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSmartNotificationPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSmartNotificationPhotoMutation, { data, loading, error }] = useDeleteSmartNotificationPhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSmartNotificationPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSmartNotificationPhotoMutation,
    DeleteSmartNotificationPhotoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSmartNotificationPhotoMutation,
    DeleteSmartNotificationPhotoMutationVariables
  >(DeleteSmartNotificationPhotoDocument, options);
}
export type DeleteSmartNotificationPhotoMutationHookResult = ReturnType<
  typeof useDeleteSmartNotificationPhotoMutation
>;
export type DeleteSmartNotificationPhotoMutationResult =
  Apollo.MutationResult<DeleteSmartNotificationPhotoMutation>;
export type DeleteSmartNotificationPhotoMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteSmartNotificationPhotoMutation,
    DeleteSmartNotificationPhotoMutationVariables
  >;
export const SetSmartNotificationParticipantsDocument = gql`
  mutation setSmartNotificationParticipants(
    $notificationData: SetSmartNotificationParticipantsInput!
  ) {
    setSmartNotificationParticipants(notificationData: $notificationData)
  }
`;
export type SetSmartNotificationParticipantsMutationFn =
  Apollo.MutationFunction<
    SetSmartNotificationParticipantsMutation,
    SetSmartNotificationParticipantsMutationVariables
  >;

/**
 * __useSetSmartNotificationParticipantsMutation__
 *
 * To run a mutation, you first call `useSetSmartNotificationParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSmartNotificationParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSmartNotificationParticipantsMutation, { data, loading, error }] = useSetSmartNotificationParticipantsMutation({
 *   variables: {
 *      notificationData: // value for 'notificationData'
 *   },
 * });
 */
export function useSetSmartNotificationParticipantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSmartNotificationParticipantsMutation,
    SetSmartNotificationParticipantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetSmartNotificationParticipantsMutation,
    SetSmartNotificationParticipantsMutationVariables
  >(SetSmartNotificationParticipantsDocument, options);
}
export type SetSmartNotificationParticipantsMutationHookResult = ReturnType<
  typeof useSetSmartNotificationParticipantsMutation
>;
export type SetSmartNotificationParticipantsMutationResult =
  Apollo.MutationResult<SetSmartNotificationParticipantsMutation>;
export type SetSmartNotificationParticipantsMutationOptions =
  Apollo.BaseMutationOptions<
    SetSmartNotificationParticipantsMutation,
    SetSmartNotificationParticipantsMutationVariables
  >;
export const CreateSmartNotificationPollListDocument = gql`
  mutation createSmartNotificationPollList(
    $name: String!
    $notificationId: String!
  ) {
    createSmartNotificationPollList(
      name: $name
      notificationId: $notificationId
    ) {
      id
    }
  }
`;
export type CreateSmartNotificationPollListMutationFn = Apollo.MutationFunction<
  CreateSmartNotificationPollListMutation,
  CreateSmartNotificationPollListMutationVariables
>;

/**
 * __useCreateSmartNotificationPollListMutation__
 *
 * To run a mutation, you first call `useCreateSmartNotificationPollListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSmartNotificationPollListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSmartNotificationPollListMutation, { data, loading, error }] = useCreateSmartNotificationPollListMutation({
 *   variables: {
 *      name: // value for 'name'
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useCreateSmartNotificationPollListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSmartNotificationPollListMutation,
    CreateSmartNotificationPollListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSmartNotificationPollListMutation,
    CreateSmartNotificationPollListMutationVariables
  >(CreateSmartNotificationPollListDocument, options);
}
export type CreateSmartNotificationPollListMutationHookResult = ReturnType<
  typeof useCreateSmartNotificationPollListMutation
>;
export type CreateSmartNotificationPollListMutationResult =
  Apollo.MutationResult<CreateSmartNotificationPollListMutation>;
export type CreateSmartNotificationPollListMutationOptions =
  Apollo.BaseMutationOptions<
    CreateSmartNotificationPollListMutation,
    CreateSmartNotificationPollListMutationVariables
  >;
export const UpdateSmartNotificationPollListDocument = gql`
  mutation updateSmartNotificationPollList($id: String!, $name: String!) {
    updateSmartNotificationPollList(id: $id, name: $name) {
      id
    }
  }
`;
export type UpdateSmartNotificationPollListMutationFn = Apollo.MutationFunction<
  UpdateSmartNotificationPollListMutation,
  UpdateSmartNotificationPollListMutationVariables
>;

/**
 * __useUpdateSmartNotificationPollListMutation__
 *
 * To run a mutation, you first call `useUpdateSmartNotificationPollListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmartNotificationPollListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmartNotificationPollListMutation, { data, loading, error }] = useUpdateSmartNotificationPollListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateSmartNotificationPollListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSmartNotificationPollListMutation,
    UpdateSmartNotificationPollListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSmartNotificationPollListMutation,
    UpdateSmartNotificationPollListMutationVariables
  >(UpdateSmartNotificationPollListDocument, options);
}
export type UpdateSmartNotificationPollListMutationHookResult = ReturnType<
  typeof useUpdateSmartNotificationPollListMutation
>;
export type UpdateSmartNotificationPollListMutationResult =
  Apollo.MutationResult<UpdateSmartNotificationPollListMutation>;
export type UpdateSmartNotificationPollListMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateSmartNotificationPollListMutation,
    UpdateSmartNotificationPollListMutationVariables
  >;
export const DeleteSmartNotificationPollListDocument = gql`
  mutation deleteSmartNotificationPollList($id: String!) {
    deleteSmartNotificationPollList(id: $id)
  }
`;
export type DeleteSmartNotificationPollListMutationFn = Apollo.MutationFunction<
  DeleteSmartNotificationPollListMutation,
  DeleteSmartNotificationPollListMutationVariables
>;

/**
 * __useDeleteSmartNotificationPollListMutation__
 *
 * To run a mutation, you first call `useDeleteSmartNotificationPollListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSmartNotificationPollListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSmartNotificationPollListMutation, { data, loading, error }] = useDeleteSmartNotificationPollListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSmartNotificationPollListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSmartNotificationPollListMutation,
    DeleteSmartNotificationPollListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSmartNotificationPollListMutation,
    DeleteSmartNotificationPollListMutationVariables
  >(DeleteSmartNotificationPollListDocument, options);
}
export type DeleteSmartNotificationPollListMutationHookResult = ReturnType<
  typeof useDeleteSmartNotificationPollListMutation
>;
export type DeleteSmartNotificationPollListMutationResult =
  Apollo.MutationResult<DeleteSmartNotificationPollListMutation>;
export type DeleteSmartNotificationPollListMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteSmartNotificationPollListMutation,
    DeleteSmartNotificationPollListMutationVariables
  >;
export const AddSubjectToTeacherDocument = gql`
  mutation addSubjectToTeacher(
    $appMainId: String!
    $userId: String!
    $schoolId: String!
  ) {
    addSubjectToTeacher(
      appMainId: $appMainId
      userId: $userId
      schoolId: $schoolId
    )
  }
`;
export type AddSubjectToTeacherMutationFn = Apollo.MutationFunction<
  AddSubjectToTeacherMutation,
  AddSubjectToTeacherMutationVariables
>;

/**
 * __useAddSubjectToTeacherMutation__
 *
 * To run a mutation, you first call `useAddSubjectToTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubjectToTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubjectToTeacherMutation, { data, loading, error }] = useAddSubjectToTeacherMutation({
 *   variables: {
 *      appMainId: // value for 'appMainId'
 *      userId: // value for 'userId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useAddSubjectToTeacherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSubjectToTeacherMutation,
    AddSubjectToTeacherMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSubjectToTeacherMutation,
    AddSubjectToTeacherMutationVariables
  >(AddSubjectToTeacherDocument, options);
}
export type AddSubjectToTeacherMutationHookResult = ReturnType<
  typeof useAddSubjectToTeacherMutation
>;
export type AddSubjectToTeacherMutationResult =
  Apollo.MutationResult<AddSubjectToTeacherMutation>;
export type AddSubjectToTeacherMutationOptions = Apollo.BaseMutationOptions<
  AddSubjectToTeacherMutation,
  AddSubjectToTeacherMutationVariables
>;
export const RemoveSubjectFromTeacherDocument = gql`
  mutation removeSubjectFromTeacher(
    $appMainId: String!
    $userId: String!
    $schoolId: String!
  ) {
    removeSubjectFromTeacher(
      appMainId: $appMainId
      userId: $userId
      schoolId: $schoolId
    )
  }
`;
export type RemoveSubjectFromTeacherMutationFn = Apollo.MutationFunction<
  RemoveSubjectFromTeacherMutation,
  RemoveSubjectFromTeacherMutationVariables
>;

/**
 * __useRemoveSubjectFromTeacherMutation__
 *
 * To run a mutation, you first call `useRemoveSubjectFromTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSubjectFromTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSubjectFromTeacherMutation, { data, loading, error }] = useRemoveSubjectFromTeacherMutation({
 *   variables: {
 *      appMainId: // value for 'appMainId'
 *      userId: // value for 'userId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useRemoveSubjectFromTeacherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSubjectFromTeacherMutation,
    RemoveSubjectFromTeacherMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveSubjectFromTeacherMutation,
    RemoveSubjectFromTeacherMutationVariables
  >(RemoveSubjectFromTeacherDocument, options);
}
export type RemoveSubjectFromTeacherMutationHookResult = ReturnType<
  typeof useRemoveSubjectFromTeacherMutation
>;
export type RemoveSubjectFromTeacherMutationResult =
  Apollo.MutationResult<RemoveSubjectFromTeacherMutation>;
export type RemoveSubjectFromTeacherMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveSubjectFromTeacherMutation,
    RemoveSubjectFromTeacherMutationVariables
  >;
export const SyncProjectTaskWithTrackerDocument = gql`
  mutation syncProjectTaskWithTracker($id: String!) {
    syncProjectTaskWithTracker(id: $id)
  }
`;
export type SyncProjectTaskWithTrackerMutationFn = Apollo.MutationFunction<
  SyncProjectTaskWithTrackerMutation,
  SyncProjectTaskWithTrackerMutationVariables
>;

/**
 * __useSyncProjectTaskWithTrackerMutation__
 *
 * To run a mutation, you first call `useSyncProjectTaskWithTrackerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncProjectTaskWithTrackerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncProjectTaskWithTrackerMutation, { data, loading, error }] = useSyncProjectTaskWithTrackerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSyncProjectTaskWithTrackerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncProjectTaskWithTrackerMutation,
    SyncProjectTaskWithTrackerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyncProjectTaskWithTrackerMutation,
    SyncProjectTaskWithTrackerMutationVariables
  >(SyncProjectTaskWithTrackerDocument, options);
}
export type SyncProjectTaskWithTrackerMutationHookResult = ReturnType<
  typeof useSyncProjectTaskWithTrackerMutation
>;
export type SyncProjectTaskWithTrackerMutationResult =
  Apollo.MutationResult<SyncProjectTaskWithTrackerMutation>;
export type SyncProjectTaskWithTrackerMutationOptions =
  Apollo.BaseMutationOptions<
    SyncProjectTaskWithTrackerMutation,
    SyncProjectTaskWithTrackerMutationVariables
  >;
export const CreateProjectTaskDocument = gql`
  mutation createProjectTask($taskData: CreateProjectTaskInput!) {
    createProjectTask(taskData: $taskData) {
      id
    }
  }
`;
export type CreateProjectTaskMutationFn = Apollo.MutationFunction<
  CreateProjectTaskMutation,
  CreateProjectTaskMutationVariables
>;

/**
 * __useCreateProjectTaskMutation__
 *
 * To run a mutation, you first call `useCreateProjectTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTaskMutation, { data, loading, error }] = useCreateProjectTaskMutation({
 *   variables: {
 *      taskData: // value for 'taskData'
 *   },
 * });
 */
export function useCreateProjectTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectTaskMutation,
    CreateProjectTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectTaskMutation,
    CreateProjectTaskMutationVariables
  >(CreateProjectTaskDocument, options);
}
export type CreateProjectTaskMutationHookResult = ReturnType<
  typeof useCreateProjectTaskMutation
>;
export type CreateProjectTaskMutationResult =
  Apollo.MutationResult<CreateProjectTaskMutation>;
export type CreateProjectTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectTaskMutation,
  CreateProjectTaskMutationVariables
>;
export const UpdateProjectTaskDocument = gql`
  mutation updateProjectTask($taskData: UpdateProjectTaskInput!) {
    updateProjectTask(taskData: $taskData) {
      id
    }
  }
`;
export type UpdateProjectTaskMutationFn = Apollo.MutationFunction<
  UpdateProjectTaskMutation,
  UpdateProjectTaskMutationVariables
>;

/**
 * __useUpdateProjectTaskMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTaskMutation, { data, loading, error }] = useUpdateProjectTaskMutation({
 *   variables: {
 *      taskData: // value for 'taskData'
 *   },
 * });
 */
export function useUpdateProjectTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTaskMutation,
    UpdateProjectTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTaskMutation,
    UpdateProjectTaskMutationVariables
  >(UpdateProjectTaskDocument, options);
}
export type UpdateProjectTaskMutationHookResult = ReturnType<
  typeof useUpdateProjectTaskMutation
>;
export type UpdateProjectTaskMutationResult =
  Apollo.MutationResult<UpdateProjectTaskMutation>;
export type UpdateProjectTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTaskMutation,
  UpdateProjectTaskMutationVariables
>;
export const DeleteProjectTaskDocument = gql`
  mutation deleteProjectTask($id: String!) {
    deleteProjectTask(id: $id)
  }
`;
export type DeleteProjectTaskMutationFn = Apollo.MutationFunction<
  DeleteProjectTaskMutation,
  DeleteProjectTaskMutationVariables
>;

/**
 * __useDeleteProjectTaskMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTaskMutation, { data, loading, error }] = useDeleteProjectTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectTaskMutation,
    DeleteProjectTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectTaskMutation,
    DeleteProjectTaskMutationVariables
  >(DeleteProjectTaskDocument, options);
}
export type DeleteProjectTaskMutationHookResult = ReturnType<
  typeof useDeleteProjectTaskMutation
>;
export type DeleteProjectTaskMutationResult =
  Apollo.MutationResult<DeleteProjectTaskMutation>;
export type DeleteProjectTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectTaskMutation,
  DeleteProjectTaskMutationVariables
>;
export const ImportProjectTasksFromGSheetsDocument = gql`
  mutation importProjectTasksFromGSheets(
    $projectId: String!
    $gSheetId: String!
    $gSheetTitle: String!
  ) {
    importProjectTasksFromGSheets(
      projectId: $projectId
      gSheetId: $gSheetId
      gSheetTitle: $gSheetTitle
    ) {
      epicsCount
      tasksCount
    }
  }
`;
export type ImportProjectTasksFromGSheetsMutationFn = Apollo.MutationFunction<
  ImportProjectTasksFromGSheetsMutation,
  ImportProjectTasksFromGSheetsMutationVariables
>;

/**
 * __useImportProjectTasksFromGSheetsMutation__
 *
 * To run a mutation, you first call `useImportProjectTasksFromGSheetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportProjectTasksFromGSheetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importProjectTasksFromGSheetsMutation, { data, loading, error }] = useImportProjectTasksFromGSheetsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      gSheetId: // value for 'gSheetId'
 *      gSheetTitle: // value for 'gSheetTitle'
 *   },
 * });
 */
export function useImportProjectTasksFromGSheetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportProjectTasksFromGSheetsMutation,
    ImportProjectTasksFromGSheetsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportProjectTasksFromGSheetsMutation,
    ImportProjectTasksFromGSheetsMutationVariables
  >(ImportProjectTasksFromGSheetsDocument, options);
}
export type ImportProjectTasksFromGSheetsMutationHookResult = ReturnType<
  typeof useImportProjectTasksFromGSheetsMutation
>;
export type ImportProjectTasksFromGSheetsMutationResult =
  Apollo.MutationResult<ImportProjectTasksFromGSheetsMutation>;
export type ImportProjectTasksFromGSheetsMutationOptions =
  Apollo.BaseMutationOptions<
    ImportProjectTasksFromGSheetsMutation,
    ImportProjectTasksFromGSheetsMutationVariables
  >;
export const SetProjectTaskLateReasonDocument = gql`
  mutation setProjectTaskLateReason($tasksId: String!, $lateReason: String!) {
    setProjectTaskLateReason(tasksId: $tasksId, lateReason: $lateReason)
  }
`;
export type SetProjectTaskLateReasonMutationFn = Apollo.MutationFunction<
  SetProjectTaskLateReasonMutation,
  SetProjectTaskLateReasonMutationVariables
>;

/**
 * __useSetProjectTaskLateReasonMutation__
 *
 * To run a mutation, you first call `useSetProjectTaskLateReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectTaskLateReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectTaskLateReasonMutation, { data, loading, error }] = useSetProjectTaskLateReasonMutation({
 *   variables: {
 *      tasksId: // value for 'tasksId'
 *      lateReason: // value for 'lateReason'
 *   },
 * });
 */
export function useSetProjectTaskLateReasonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetProjectTaskLateReasonMutation,
    SetProjectTaskLateReasonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetProjectTaskLateReasonMutation,
    SetProjectTaskLateReasonMutationVariables
  >(SetProjectTaskLateReasonDocument, options);
}
export type SetProjectTaskLateReasonMutationHookResult = ReturnType<
  typeof useSetProjectTaskLateReasonMutation
>;
export type SetProjectTaskLateReasonMutationResult =
  Apollo.MutationResult<SetProjectTaskLateReasonMutation>;
export type SetProjectTaskLateReasonMutationOptions =
  Apollo.BaseMutationOptions<
    SetProjectTaskLateReasonMutation,
    SetProjectTaskLateReasonMutationVariables
  >;
export const CreateProjectTaskCommentDocument = gql`
  mutation createProjectTaskComment(
    $projectTaskId: String!
    $text: String!
    $file: Upload
  ) {
    createProjectTaskComment(
      projectTaskId: $projectTaskId
      text: $text
      file: $file
    ) {
      id
    }
  }
`;
export type CreateProjectTaskCommentMutationFn = Apollo.MutationFunction<
  CreateProjectTaskCommentMutation,
  CreateProjectTaskCommentMutationVariables
>;

/**
 * __useCreateProjectTaskCommentMutation__
 *
 * To run a mutation, you first call `useCreateProjectTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTaskCommentMutation, { data, loading, error }] = useCreateProjectTaskCommentMutation({
 *   variables: {
 *      projectTaskId: // value for 'projectTaskId'
 *      text: // value for 'text'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateProjectTaskCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectTaskCommentMutation,
    CreateProjectTaskCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectTaskCommentMutation,
    CreateProjectTaskCommentMutationVariables
  >(CreateProjectTaskCommentDocument, options);
}
export type CreateProjectTaskCommentMutationHookResult = ReturnType<
  typeof useCreateProjectTaskCommentMutation
>;
export type CreateProjectTaskCommentMutationResult =
  Apollo.MutationResult<CreateProjectTaskCommentMutation>;
export type CreateProjectTaskCommentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateProjectTaskCommentMutation,
    CreateProjectTaskCommentMutationVariables
  >;
export const UpdateProjectTaskCommentDocument = gql`
  mutation updateProjectTaskComment(
    $id: String!
    $text: String!
    $file: Upload
  ) {
    updateProjectTaskComment(id: $id, text: $text, file: $file) {
      id
    }
  }
`;
export type UpdateProjectTaskCommentMutationFn = Apollo.MutationFunction<
  UpdateProjectTaskCommentMutation,
  UpdateProjectTaskCommentMutationVariables
>;

/**
 * __useUpdateProjectTaskCommentMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTaskCommentMutation, { data, loading, error }] = useUpdateProjectTaskCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateProjectTaskCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTaskCommentMutation,
    UpdateProjectTaskCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTaskCommentMutation,
    UpdateProjectTaskCommentMutationVariables
  >(UpdateProjectTaskCommentDocument, options);
}
export type UpdateProjectTaskCommentMutationHookResult = ReturnType<
  typeof useUpdateProjectTaskCommentMutation
>;
export type UpdateProjectTaskCommentMutationResult =
  Apollo.MutationResult<UpdateProjectTaskCommentMutation>;
export type UpdateProjectTaskCommentMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateProjectTaskCommentMutation,
    UpdateProjectTaskCommentMutationVariables
  >;
export const DeleteProjectTaskCommentDocument = gql`
  mutation deleteProjectTaskComment($id: String!) {
    deleteProjectTaskComment(id: $id)
  }
`;
export type DeleteProjectTaskCommentMutationFn = Apollo.MutationFunction<
  DeleteProjectTaskCommentMutation,
  DeleteProjectTaskCommentMutationVariables
>;

/**
 * __useDeleteProjectTaskCommentMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTaskCommentMutation, { data, loading, error }] = useDeleteProjectTaskCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectTaskCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectTaskCommentMutation,
    DeleteProjectTaskCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectTaskCommentMutation,
    DeleteProjectTaskCommentMutationVariables
  >(DeleteProjectTaskCommentDocument, options);
}
export type DeleteProjectTaskCommentMutationHookResult = ReturnType<
  typeof useDeleteProjectTaskCommentMutation
>;
export type DeleteProjectTaskCommentMutationResult =
  Apollo.MutationResult<DeleteProjectTaskCommentMutation>;
export type DeleteProjectTaskCommentMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteProjectTaskCommentMutation,
    DeleteProjectTaskCommentMutationVariables
  >;
export const DeleteProjectTaskCommentFileDocument = gql`
  mutation deleteProjectTaskCommentFile($id: String!) {
    deleteProjectTaskCommentFile(id: $id)
  }
`;
export type DeleteProjectTaskCommentFileMutationFn = Apollo.MutationFunction<
  DeleteProjectTaskCommentFileMutation,
  DeleteProjectTaskCommentFileMutationVariables
>;

/**
 * __useDeleteProjectTaskCommentFileMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTaskCommentFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTaskCommentFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTaskCommentFileMutation, { data, loading, error }] = useDeleteProjectTaskCommentFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectTaskCommentFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectTaskCommentFileMutation,
    DeleteProjectTaskCommentFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectTaskCommentFileMutation,
    DeleteProjectTaskCommentFileMutationVariables
  >(DeleteProjectTaskCommentFileDocument, options);
}
export type DeleteProjectTaskCommentFileMutationHookResult = ReturnType<
  typeof useDeleteProjectTaskCommentFileMutation
>;
export type DeleteProjectTaskCommentFileMutationResult =
  Apollo.MutationResult<DeleteProjectTaskCommentFileMutation>;
export type DeleteProjectTaskCommentFileMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteProjectTaskCommentFileMutation,
    DeleteProjectTaskCommentFileMutationVariables
  >;
export const CreateBaseProjectTaskLabelDocument = gql`
  mutation createBaseProjectTaskLabel($name: String!, $color: String!) {
    createBaseProjectTaskLabel(name: $name, color: $color) {
      id
    }
  }
`;
export type CreateBaseProjectTaskLabelMutationFn = Apollo.MutationFunction<
  CreateBaseProjectTaskLabelMutation,
  CreateBaseProjectTaskLabelMutationVariables
>;

/**
 * __useCreateBaseProjectTaskLabelMutation__
 *
 * To run a mutation, you first call `useCreateBaseProjectTaskLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBaseProjectTaskLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBaseProjectTaskLabelMutation, { data, loading, error }] = useCreateBaseProjectTaskLabelMutation({
 *   variables: {
 *      name: // value for 'name'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useCreateBaseProjectTaskLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBaseProjectTaskLabelMutation,
    CreateBaseProjectTaskLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBaseProjectTaskLabelMutation,
    CreateBaseProjectTaskLabelMutationVariables
  >(CreateBaseProjectTaskLabelDocument, options);
}
export type CreateBaseProjectTaskLabelMutationHookResult = ReturnType<
  typeof useCreateBaseProjectTaskLabelMutation
>;
export type CreateBaseProjectTaskLabelMutationResult =
  Apollo.MutationResult<CreateBaseProjectTaskLabelMutation>;
export type CreateBaseProjectTaskLabelMutationOptions =
  Apollo.BaseMutationOptions<
    CreateBaseProjectTaskLabelMutation,
    CreateBaseProjectTaskLabelMutationVariables
  >;
export const CreateProjectTaskLabelForProjectDocument = gql`
  mutation createProjectTaskLabelForProject(
    $name: String!
    $color: String!
    $projectId: String!
  ) {
    createProjectTaskLabelForProject(
      name: $name
      color: $color
      projectId: $projectId
    ) {
      id
    }
  }
`;
export type CreateProjectTaskLabelForProjectMutationFn =
  Apollo.MutationFunction<
    CreateProjectTaskLabelForProjectMutation,
    CreateProjectTaskLabelForProjectMutationVariables
  >;

/**
 * __useCreateProjectTaskLabelForProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectTaskLabelForProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTaskLabelForProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTaskLabelForProjectMutation, { data, loading, error }] = useCreateProjectTaskLabelForProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      color: // value for 'color'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateProjectTaskLabelForProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectTaskLabelForProjectMutation,
    CreateProjectTaskLabelForProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectTaskLabelForProjectMutation,
    CreateProjectTaskLabelForProjectMutationVariables
  >(CreateProjectTaskLabelForProjectDocument, options);
}
export type CreateProjectTaskLabelForProjectMutationHookResult = ReturnType<
  typeof useCreateProjectTaskLabelForProjectMutation
>;
export type CreateProjectTaskLabelForProjectMutationResult =
  Apollo.MutationResult<CreateProjectTaskLabelForProjectMutation>;
export type CreateProjectTaskLabelForProjectMutationOptions =
  Apollo.BaseMutationOptions<
    CreateProjectTaskLabelForProjectMutation,
    CreateProjectTaskLabelForProjectMutationVariables
  >;
export const UpdateProjectTaskLabelDocument = gql`
  mutation updateProjectTaskLabel($id: String!, $name: String, $color: String) {
    updateProjectTaskLabel(id: $id, name: $name, color: $color) {
      id
    }
  }
`;
export type UpdateProjectTaskLabelMutationFn = Apollo.MutationFunction<
  UpdateProjectTaskLabelMutation,
  UpdateProjectTaskLabelMutationVariables
>;

/**
 * __useUpdateProjectTaskLabelMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTaskLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTaskLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTaskLabelMutation, { data, loading, error }] = useUpdateProjectTaskLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useUpdateProjectTaskLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTaskLabelMutation,
    UpdateProjectTaskLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTaskLabelMutation,
    UpdateProjectTaskLabelMutationVariables
  >(UpdateProjectTaskLabelDocument, options);
}
export type UpdateProjectTaskLabelMutationHookResult = ReturnType<
  typeof useUpdateProjectTaskLabelMutation
>;
export type UpdateProjectTaskLabelMutationResult =
  Apollo.MutationResult<UpdateProjectTaskLabelMutation>;
export type UpdateProjectTaskLabelMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTaskLabelMutation,
  UpdateProjectTaskLabelMutationVariables
>;
export const DeleteProjectTaskLabelDocument = gql`
  mutation deleteProjectTaskLabel($id: String!) {
    deleteProjectTaskLabel(id: $id)
  }
`;
export type DeleteProjectTaskLabelMutationFn = Apollo.MutationFunction<
  DeleteProjectTaskLabelMutation,
  DeleteProjectTaskLabelMutationVariables
>;

/**
 * __useDeleteProjectTaskLabelMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTaskLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTaskLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTaskLabelMutation, { data, loading, error }] = useDeleteProjectTaskLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectTaskLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectTaskLabelMutation,
    DeleteProjectTaskLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectTaskLabelMutation,
    DeleteProjectTaskLabelMutationVariables
  >(DeleteProjectTaskLabelDocument, options);
}
export type DeleteProjectTaskLabelMutationHookResult = ReturnType<
  typeof useDeleteProjectTaskLabelMutation
>;
export type DeleteProjectTaskLabelMutationResult =
  Apollo.MutationResult<DeleteProjectTaskLabelMutation>;
export type DeleteProjectTaskLabelMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectTaskLabelMutation,
  DeleteProjectTaskLabelMutationVariables
>;
export const AssignProjectTaskLabelDocument = gql`
  mutation assignProjectTaskLabel($projectTaskId: String!, $labelId: String!) {
    assignProjectTaskLabel(projectTaskId: $projectTaskId, labelId: $labelId)
  }
`;
export type AssignProjectTaskLabelMutationFn = Apollo.MutationFunction<
  AssignProjectTaskLabelMutation,
  AssignProjectTaskLabelMutationVariables
>;

/**
 * __useAssignProjectTaskLabelMutation__
 *
 * To run a mutation, you first call `useAssignProjectTaskLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignProjectTaskLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignProjectTaskLabelMutation, { data, loading, error }] = useAssignProjectTaskLabelMutation({
 *   variables: {
 *      projectTaskId: // value for 'projectTaskId'
 *      labelId: // value for 'labelId'
 *   },
 * });
 */
export function useAssignProjectTaskLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignProjectTaskLabelMutation,
    AssignProjectTaskLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignProjectTaskLabelMutation,
    AssignProjectTaskLabelMutationVariables
  >(AssignProjectTaskLabelDocument, options);
}
export type AssignProjectTaskLabelMutationHookResult = ReturnType<
  typeof useAssignProjectTaskLabelMutation
>;
export type AssignProjectTaskLabelMutationResult =
  Apollo.MutationResult<AssignProjectTaskLabelMutation>;
export type AssignProjectTaskLabelMutationOptions = Apollo.BaseMutationOptions<
  AssignProjectTaskLabelMutation,
  AssignProjectTaskLabelMutationVariables
>;
export const RemoveProjectTaskLabelDocument = gql`
  mutation removeProjectTaskLabel($projectTaskId: String!, $labelId: String!) {
    removeProjectTaskLabel(projectTaskId: $projectTaskId, labelId: $labelId)
  }
`;
export type RemoveProjectTaskLabelMutationFn = Apollo.MutationFunction<
  RemoveProjectTaskLabelMutation,
  RemoveProjectTaskLabelMutationVariables
>;

/**
 * __useRemoveProjectTaskLabelMutation__
 *
 * To run a mutation, you first call `useRemoveProjectTaskLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectTaskLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectTaskLabelMutation, { data, loading, error }] = useRemoveProjectTaskLabelMutation({
 *   variables: {
 *      projectTaskId: // value for 'projectTaskId'
 *      labelId: // value for 'labelId'
 *   },
 * });
 */
export function useRemoveProjectTaskLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveProjectTaskLabelMutation,
    RemoveProjectTaskLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveProjectTaskLabelMutation,
    RemoveProjectTaskLabelMutationVariables
  >(RemoveProjectTaskLabelDocument, options);
}
export type RemoveProjectTaskLabelMutationHookResult = ReturnType<
  typeof useRemoveProjectTaskLabelMutation
>;
export type RemoveProjectTaskLabelMutationResult =
  Apollo.MutationResult<RemoveProjectTaskLabelMutation>;
export type RemoveProjectTaskLabelMutationOptions = Apollo.BaseMutationOptions<
  RemoveProjectTaskLabelMutation,
  RemoveProjectTaskLabelMutationVariables
>;
export const CreateBaseProjectTaskStatusDocument = gql`
  mutation createBaseProjectTaskStatus($name: String!) {
    createBaseProjectTaskStatus(name: $name) {
      id
    }
  }
`;
export type CreateBaseProjectTaskStatusMutationFn = Apollo.MutationFunction<
  CreateBaseProjectTaskStatusMutation,
  CreateBaseProjectTaskStatusMutationVariables
>;

/**
 * __useCreateBaseProjectTaskStatusMutation__
 *
 * To run a mutation, you first call `useCreateBaseProjectTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBaseProjectTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBaseProjectTaskStatusMutation, { data, loading, error }] = useCreateBaseProjectTaskStatusMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateBaseProjectTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBaseProjectTaskStatusMutation,
    CreateBaseProjectTaskStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBaseProjectTaskStatusMutation,
    CreateBaseProjectTaskStatusMutationVariables
  >(CreateBaseProjectTaskStatusDocument, options);
}
export type CreateBaseProjectTaskStatusMutationHookResult = ReturnType<
  typeof useCreateBaseProjectTaskStatusMutation
>;
export type CreateBaseProjectTaskStatusMutationResult =
  Apollo.MutationResult<CreateBaseProjectTaskStatusMutation>;
export type CreateBaseProjectTaskStatusMutationOptions =
  Apollo.BaseMutationOptions<
    CreateBaseProjectTaskStatusMutation,
    CreateBaseProjectTaskStatusMutationVariables
  >;
export const CreateProjectTaskStatusForProjectDocument = gql`
  mutation createProjectTaskStatusForProject(
    $name: String!
    $projectId: String!
  ) {
    createProjectTaskStatusForProject(name: $name, projectId: $projectId) {
      id
    }
  }
`;
export type CreateProjectTaskStatusForProjectMutationFn =
  Apollo.MutationFunction<
    CreateProjectTaskStatusForProjectMutation,
    CreateProjectTaskStatusForProjectMutationVariables
  >;

/**
 * __useCreateProjectTaskStatusForProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectTaskStatusForProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTaskStatusForProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTaskStatusForProjectMutation, { data, loading, error }] = useCreateProjectTaskStatusForProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateProjectTaskStatusForProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectTaskStatusForProjectMutation,
    CreateProjectTaskStatusForProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectTaskStatusForProjectMutation,
    CreateProjectTaskStatusForProjectMutationVariables
  >(CreateProjectTaskStatusForProjectDocument, options);
}
export type CreateProjectTaskStatusForProjectMutationHookResult = ReturnType<
  typeof useCreateProjectTaskStatusForProjectMutation
>;
export type CreateProjectTaskStatusForProjectMutationResult =
  Apollo.MutationResult<CreateProjectTaskStatusForProjectMutation>;
export type CreateProjectTaskStatusForProjectMutationOptions =
  Apollo.BaseMutationOptions<
    CreateProjectTaskStatusForProjectMutation,
    CreateProjectTaskStatusForProjectMutationVariables
  >;
export const UpdateProjectTaskStatusDocument = gql`
  mutation updateProjectTaskStatus($id: String!, $name: String!) {
    updateProjectTaskStatus(id: $id, name: $name) {
      id
    }
  }
`;
export type UpdateProjectTaskStatusMutationFn = Apollo.MutationFunction<
  UpdateProjectTaskStatusMutation,
  UpdateProjectTaskStatusMutationVariables
>;

/**
 * __useUpdateProjectTaskStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTaskStatusMutation, { data, loading, error }] = useUpdateProjectTaskStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateProjectTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectTaskStatusMutation,
    UpdateProjectTaskStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectTaskStatusMutation,
    UpdateProjectTaskStatusMutationVariables
  >(UpdateProjectTaskStatusDocument, options);
}
export type UpdateProjectTaskStatusMutationHookResult = ReturnType<
  typeof useUpdateProjectTaskStatusMutation
>;
export type UpdateProjectTaskStatusMutationResult =
  Apollo.MutationResult<UpdateProjectTaskStatusMutation>;
export type UpdateProjectTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTaskStatusMutation,
  UpdateProjectTaskStatusMutationVariables
>;
export const DeleteProjectTaskStatusDocument = gql`
  mutation deleteProjectTaskStatus($id: String!) {
    deleteProjectTaskStatus(id: $id)
  }
`;
export type DeleteProjectTaskStatusMutationFn = Apollo.MutationFunction<
  DeleteProjectTaskStatusMutation,
  DeleteProjectTaskStatusMutationVariables
>;

/**
 * __useDeleteProjectTaskStatusMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTaskStatusMutation, { data, loading, error }] = useDeleteProjectTaskStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectTaskStatusMutation,
    DeleteProjectTaskStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectTaskStatusMutation,
    DeleteProjectTaskStatusMutationVariables
  >(DeleteProjectTaskStatusDocument, options);
}
export type DeleteProjectTaskStatusMutationHookResult = ReturnType<
  typeof useDeleteProjectTaskStatusMutation
>;
export type DeleteProjectTaskStatusMutationResult =
  Apollo.MutationResult<DeleteProjectTaskStatusMutation>;
export type DeleteProjectTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectTaskStatusMutation,
  DeleteProjectTaskStatusMutationVariables
>;
export const CreateTrackerContractDocument = gql`
  mutation createTrackerContract($userId: String, $projectId: String) {
    createTrackerContract(userId: $userId, projectId: $projectId) {
      id
    }
  }
`;
export type CreateTrackerContractMutationFn = Apollo.MutationFunction<
  CreateTrackerContractMutation,
  CreateTrackerContractMutationVariables
>;

/**
 * __useCreateTrackerContractMutation__
 *
 * To run a mutation, you first call `useCreateTrackerContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrackerContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrackerContractMutation, { data, loading, error }] = useCreateTrackerContractMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateTrackerContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTrackerContractMutation,
    CreateTrackerContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTrackerContractMutation,
    CreateTrackerContractMutationVariables
  >(CreateTrackerContractDocument, options);
}
export type CreateTrackerContractMutationHookResult = ReturnType<
  typeof useCreateTrackerContractMutation
>;
export type CreateTrackerContractMutationResult =
  Apollo.MutationResult<CreateTrackerContractMutation>;
export type CreateTrackerContractMutationOptions = Apollo.BaseMutationOptions<
  CreateTrackerContractMutation,
  CreateTrackerContractMutationVariables
>;
export const DeleteTrackerContractDocument = gql`
  mutation deleteTrackerContract($contractId: String!) {
    deleteTrackerContract(contractId: $contractId)
  }
`;
export type DeleteTrackerContractMutationFn = Apollo.MutationFunction<
  DeleteTrackerContractMutation,
  DeleteTrackerContractMutationVariables
>;

/**
 * __useDeleteTrackerContractMutation__
 *
 * To run a mutation, you first call `useDeleteTrackerContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrackerContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrackerContractMutation, { data, loading, error }] = useDeleteTrackerContractMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useDeleteTrackerContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTrackerContractMutation,
    DeleteTrackerContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTrackerContractMutation,
    DeleteTrackerContractMutationVariables
  >(DeleteTrackerContractDocument, options);
}
export type DeleteTrackerContractMutationHookResult = ReturnType<
  typeof useDeleteTrackerContractMutation
>;
export type DeleteTrackerContractMutationResult =
  Apollo.MutationResult<DeleteTrackerContractMutation>;
export type DeleteTrackerContractMutationOptions = Apollo.BaseMutationOptions<
  DeleteTrackerContractMutation,
  DeleteTrackerContractMutationVariables
>;
export const CreateTraningDocument = gql`
  mutation createTraning($traningData: CreateTraningInput!) {
    createTraning(traningData: $traningData) {
      id
    }
  }
`;
export type CreateTraningMutationFn = Apollo.MutationFunction<
  CreateTraningMutation,
  CreateTraningMutationVariables
>;

/**
 * __useCreateTraningMutation__
 *
 * To run a mutation, you first call `useCreateTraningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTraningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTraningMutation, { data, loading, error }] = useCreateTraningMutation({
 *   variables: {
 *      traningData: // value for 'traningData'
 *   },
 * });
 */
export function useCreateTraningMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTraningMutation,
    CreateTraningMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTraningMutation,
    CreateTraningMutationVariables
  >(CreateTraningDocument, options);
}
export type CreateTraningMutationHookResult = ReturnType<
  typeof useCreateTraningMutation
>;
export type CreateTraningMutationResult =
  Apollo.MutationResult<CreateTraningMutation>;
export type CreateTraningMutationOptions = Apollo.BaseMutationOptions<
  CreateTraningMutation,
  CreateTraningMutationVariables
>;
export const DeleteTraningDocument = gql`
  mutation deleteTraning($id: String!) {
    deleteTraning(id: $id)
  }
`;
export type DeleteTraningMutationFn = Apollo.MutationFunction<
  DeleteTraningMutation,
  DeleteTraningMutationVariables
>;

/**
 * __useDeleteTraningMutation__
 *
 * To run a mutation, you first call `useDeleteTraningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTraningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTraningMutation, { data, loading, error }] = useDeleteTraningMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTraningMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTraningMutation,
    DeleteTraningMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTraningMutation,
    DeleteTraningMutationVariables
  >(DeleteTraningDocument, options);
}
export type DeleteTraningMutationHookResult = ReturnType<
  typeof useDeleteTraningMutation
>;
export type DeleteTraningMutationResult =
  Apollo.MutationResult<DeleteTraningMutation>;
export type DeleteTraningMutationOptions = Apollo.BaseMutationOptions<
  DeleteTraningMutation,
  DeleteTraningMutationVariables
>;
export const PublishTraningDocument = gql`
  mutation publishTraning($id: String!) {
    publishTraning(id: $id)
  }
`;
export type PublishTraningMutationFn = Apollo.MutationFunction<
  PublishTraningMutation,
  PublishTraningMutationVariables
>;

/**
 * __usePublishTraningMutation__
 *
 * To run a mutation, you first call `usePublishTraningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishTraningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishTraningMutation, { data, loading, error }] = usePublishTraningMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishTraningMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishTraningMutation,
    PublishTraningMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishTraningMutation,
    PublishTraningMutationVariables
  >(PublishTraningDocument, options);
}
export type PublishTraningMutationHookResult = ReturnType<
  typeof usePublishTraningMutation
>;
export type PublishTraningMutationResult =
  Apollo.MutationResult<PublishTraningMutation>;
export type PublishTraningMutationOptions = Apollo.BaseMutationOptions<
  PublishTraningMutation,
  PublishTraningMutationVariables
>;
export const UnpublishTraningDocument = gql`
  mutation unpublishTraning($id: String!) {
    unpublishTraning(id: $id)
  }
`;
export type UnpublishTraningMutationFn = Apollo.MutationFunction<
  UnpublishTraningMutation,
  UnpublishTraningMutationVariables
>;

/**
 * __useUnpublishTraningMutation__
 *
 * To run a mutation, you first call `useUnpublishTraningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishTraningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishTraningMutation, { data, loading, error }] = useUnpublishTraningMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnpublishTraningMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnpublishTraningMutation,
    UnpublishTraningMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnpublishTraningMutation,
    UnpublishTraningMutationVariables
  >(UnpublishTraningDocument, options);
}
export type UnpublishTraningMutationHookResult = ReturnType<
  typeof useUnpublishTraningMutation
>;
export type UnpublishTraningMutationResult =
  Apollo.MutationResult<UnpublishTraningMutation>;
export type UnpublishTraningMutationOptions = Apollo.BaseMutationOptions<
  UnpublishTraningMutation,
  UnpublishTraningMutationVariables
>;
export const UpdateTraningDocument = gql`
  mutation updateTraning($id: String!, $traningData: CreateTraningInput!) {
    updateTraning(id: $id, traningData: $traningData) {
      contentEN
      contentUKR
      startTitleEN
      startTitleUKR
      endTitleEN
      endTitleUKR
      photo
      startVideo
      endVideo
    }
  }
`;
export type UpdateTraningMutationFn = Apollo.MutationFunction<
  UpdateTraningMutation,
  UpdateTraningMutationVariables
>;

/**
 * __useUpdateTraningMutation__
 *
 * To run a mutation, you first call `useUpdateTraningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTraningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTraningMutation, { data, loading, error }] = useUpdateTraningMutation({
 *   variables: {
 *      id: // value for 'id'
 *      traningData: // value for 'traningData'
 *   },
 * });
 */
export function useUpdateTraningMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTraningMutation,
    UpdateTraningMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTraningMutation,
    UpdateTraningMutationVariables
  >(UpdateTraningDocument, options);
}
export type UpdateTraningMutationHookResult = ReturnType<
  typeof useUpdateTraningMutation
>;
export type UpdateTraningMutationResult =
  Apollo.MutationResult<UpdateTraningMutation>;
export type UpdateTraningMutationOptions = Apollo.BaseMutationOptions<
  UpdateTraningMutation,
  UpdateTraningMutationVariables
>;
export const UpdateUserInAdminPanelDocument = gql`
  mutation updateUserInAdminPanel(
    $updateUserInAdminPanelId: UUID!
    $userData: UpdateUserInAdminPanelInput!
    $photo: Upload
  ) {
    updateUserInAdminPanel(
      id: $updateUserInAdminPanelId
      userData: $userData
      photo: $photo
    ) {
      role {
        id
        role
        displayName
      }
    }
  }
`;
export type UpdateUserInAdminPanelMutationFn = Apollo.MutationFunction<
  UpdateUserInAdminPanelMutation,
  UpdateUserInAdminPanelMutationVariables
>;

/**
 * __useUpdateUserInAdminPanelMutation__
 *
 * To run a mutation, you first call `useUpdateUserInAdminPanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInAdminPanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInAdminPanelMutation, { data, loading, error }] = useUpdateUserInAdminPanelMutation({
 *   variables: {
 *      updateUserInAdminPanelId: // value for 'updateUserInAdminPanelId'
 *      userData: // value for 'userData'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useUpdateUserInAdminPanelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserInAdminPanelMutation,
    UpdateUserInAdminPanelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserInAdminPanelMutation,
    UpdateUserInAdminPanelMutationVariables
  >(UpdateUserInAdminPanelDocument, options);
}
export type UpdateUserInAdminPanelMutationHookResult = ReturnType<
  typeof useUpdateUserInAdminPanelMutation
>;
export type UpdateUserInAdminPanelMutationResult =
  Apollo.MutationResult<UpdateUserInAdminPanelMutation>;
export type UpdateUserInAdminPanelMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserInAdminPanelMutation,
  UpdateUserInAdminPanelMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($userId: String!) {
    deleteUser(userId: $userId)
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const AssignUserTrackerIdDocument = gql`
  mutation assignUserTrackerId($userId: String) {
    assignUserTrackerId(userId: $userId)
  }
`;
export type AssignUserTrackerIdMutationFn = Apollo.MutationFunction<
  AssignUserTrackerIdMutation,
  AssignUserTrackerIdMutationVariables
>;

/**
 * __useAssignUserTrackerIdMutation__
 *
 * To run a mutation, you first call `useAssignUserTrackerIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUserTrackerIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUserTrackerIdMutation, { data, loading, error }] = useAssignUserTrackerIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAssignUserTrackerIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignUserTrackerIdMutation,
    AssignUserTrackerIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignUserTrackerIdMutation,
    AssignUserTrackerIdMutationVariables
  >(AssignUserTrackerIdDocument, options);
}
export type AssignUserTrackerIdMutationHookResult = ReturnType<
  typeof useAssignUserTrackerIdMutation
>;
export type AssignUserTrackerIdMutationResult =
  Apollo.MutationResult<AssignUserTrackerIdMutation>;
export type AssignUserTrackerIdMutationOptions = Apollo.BaseMutationOptions<
  AssignUserTrackerIdMutation,
  AssignUserTrackerIdMutationVariables
>;
export const InviteUserToTrackerDocument = gql`
  mutation inviteUserToTracker($userId: String) {
    inviteUserToTracker(userId: $userId)
  }
`;
export type InviteUserToTrackerMutationFn = Apollo.MutationFunction<
  InviteUserToTrackerMutation,
  InviteUserToTrackerMutationVariables
>;

/**
 * __useInviteUserToTrackerMutation__
 *
 * To run a mutation, you first call `useInviteUserToTrackerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserToTrackerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserToTrackerMutation, { data, loading, error }] = useInviteUserToTrackerMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInviteUserToTrackerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUserToTrackerMutation,
    InviteUserToTrackerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteUserToTrackerMutation,
    InviteUserToTrackerMutationVariables
  >(InviteUserToTrackerDocument, options);
}
export type InviteUserToTrackerMutationHookResult = ReturnType<
  typeof useInviteUserToTrackerMutation
>;
export type InviteUserToTrackerMutationResult =
  Apollo.MutationResult<InviteUserToTrackerMutation>;
export type InviteUserToTrackerMutationOptions = Apollo.BaseMutationOptions<
  InviteUserToTrackerMutation,
  InviteUserToTrackerMutationVariables
>;
export const BlockUserDocument = gql`
  mutation blockUser($userId: String) {
    blockUser(userId: $userId)
  }
`;
export type BlockUserMutationFn = Apollo.MutationFunction<
  BlockUserMutation,
  BlockUserMutationVariables
>;

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBlockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BlockUserMutation,
    BlockUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BlockUserMutation, BlockUserMutationVariables>(
    BlockUserDocument,
    options
  );
}
export type BlockUserMutationHookResult = ReturnType<
  typeof useBlockUserMutation
>;
export type BlockUserMutationResult = Apollo.MutationResult<BlockUserMutation>;
export type BlockUserMutationOptions = Apollo.BaseMutationOptions<
  BlockUserMutation,
  BlockUserMutationVariables
>;
export const CreateUserGroupDocument = gql`
  mutation createUserGroup($userGroupData: CreateUserGroupInput!) {
    createUserGroup(userGroupData: $userGroupData) {
      id
    }
  }
`;
export type CreateUserGroupMutationFn = Apollo.MutationFunction<
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables
>;

/**
 * __useCreateUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMutation, { data, loading, error }] = useCreateUserGroupMutation({
 *   variables: {
 *      userGroupData: // value for 'userGroupData'
 *   },
 * });
 */
export function useCreateUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserGroupMutation,
    CreateUserGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserGroupMutation,
    CreateUserGroupMutationVariables
  >(CreateUserGroupDocument, options);
}
export type CreateUserGroupMutationHookResult = ReturnType<
  typeof useCreateUserGroupMutation
>;
export type CreateUserGroupMutationResult =
  Apollo.MutationResult<CreateUserGroupMutation>;
export type CreateUserGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables
>;
export const UpdateUserGroupDocument = gql`
  mutation updateUserGroup($userGroupData: UpdateUserGroupInput!) {
    updateUserGroup(userGroupData: $userGroupData) {
      id
    }
  }
`;
export type UpdateUserGroupMutationFn = Apollo.MutationFunction<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;

/**
 * __useUpdateUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupMutation, { data, loading, error }] = useUpdateUserGroupMutation({
 *   variables: {
 *      userGroupData: // value for 'userGroupData'
 *   },
 * });
 */
export function useUpdateUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserGroupMutation,
    UpdateUserGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserGroupMutation,
    UpdateUserGroupMutationVariables
  >(UpdateUserGroupDocument, options);
}
export type UpdateUserGroupMutationHookResult = ReturnType<
  typeof useUpdateUserGroupMutation
>;
export type UpdateUserGroupMutationResult =
  Apollo.MutationResult<UpdateUserGroupMutation>;
export type UpdateUserGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;
export const DeleteUserGroupDocument = gql`
  mutation deleteUserGroup($userGroupId: String!) {
    deleteUserGroup(userGroupId: $userGroupId)
  }
`;
export type DeleteUserGroupMutationFn = Apollo.MutationFunction<
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables
>;

/**
 * __useDeleteUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupMutation, { data, loading, error }] = useDeleteUserGroupMutation({
 *   variables: {
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useDeleteUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserGroupMutation,
    DeleteUserGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserGroupMutation,
    DeleteUserGroupMutationVariables
  >(DeleteUserGroupDocument, options);
}
export type DeleteUserGroupMutationHookResult = ReturnType<
  typeof useDeleteUserGroupMutation
>;
export type DeleteUserGroupMutationResult =
  Apollo.MutationResult<DeleteUserGroupMutation>;
export type DeleteUserGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables
>;
export const AddPermissionByUserIdDocument = gql`
  mutation AddPermissionByUserId($userId: UUID!, $flagId: UUID!) {
    addPermissionByUserId(userId: $userId, flagId: $flagId)
  }
`;
export type AddPermissionByUserIdMutationFn = Apollo.MutationFunction<
  AddPermissionByUserIdMutation,
  AddPermissionByUserIdMutationVariables
>;

/**
 * __useAddPermissionByUserIdMutation__
 *
 * To run a mutation, you first call `useAddPermissionByUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPermissionByUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPermissionByUserIdMutation, { data, loading, error }] = useAddPermissionByUserIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      flagId: // value for 'flagId'
 *   },
 * });
 */
export function useAddPermissionByUserIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPermissionByUserIdMutation,
    AddPermissionByUserIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPermissionByUserIdMutation,
    AddPermissionByUserIdMutationVariables
  >(AddPermissionByUserIdDocument, options);
}
export type AddPermissionByUserIdMutationHookResult = ReturnType<
  typeof useAddPermissionByUserIdMutation
>;
export type AddPermissionByUserIdMutationResult =
  Apollo.MutationResult<AddPermissionByUserIdMutation>;
export type AddPermissionByUserIdMutationOptions = Apollo.BaseMutationOptions<
  AddPermissionByUserIdMutation,
  AddPermissionByUserIdMutationVariables
>;
export const RemovePermissionByUserIdDocument = gql`
  mutation RemovePermissionByUserId($userId: UUID!, $flagId: UUID!) {
    removePermissionByUserId(userId: $userId, flagId: $flagId)
  }
`;
export type RemovePermissionByUserIdMutationFn = Apollo.MutationFunction<
  RemovePermissionByUserIdMutation,
  RemovePermissionByUserIdMutationVariables
>;

/**
 * __useRemovePermissionByUserIdMutation__
 *
 * To run a mutation, you first call `useRemovePermissionByUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePermissionByUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePermissionByUserIdMutation, { data, loading, error }] = useRemovePermissionByUserIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      flagId: // value for 'flagId'
 *   },
 * });
 */
export function useRemovePermissionByUserIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePermissionByUserIdMutation,
    RemovePermissionByUserIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemovePermissionByUserIdMutation,
    RemovePermissionByUserIdMutationVariables
  >(RemovePermissionByUserIdDocument, options);
}
export type RemovePermissionByUserIdMutationHookResult = ReturnType<
  typeof useRemovePermissionByUserIdMutation
>;
export type RemovePermissionByUserIdMutationResult =
  Apollo.MutationResult<RemovePermissionByUserIdMutation>;
export type RemovePermissionByUserIdMutationOptions =
  Apollo.BaseMutationOptions<
    RemovePermissionByUserIdMutation,
    RemovePermissionByUserIdMutationVariables
  >;
export const AddByPersonalSchoolIdPermissionDocument = gql`
  mutation AddByPersonalSchoolIdPermission($schoolId: UUID!, $flagId: UUID!) {
    addByPersonalSchoolIdPermission(schoolId: $schoolId, flagId: $flagId)
  }
`;
export type AddByPersonalSchoolIdPermissionMutationFn = Apollo.MutationFunction<
  AddByPersonalSchoolIdPermissionMutation,
  AddByPersonalSchoolIdPermissionMutationVariables
>;

/**
 * __useAddByPersonalSchoolIdPermissionMutation__
 *
 * To run a mutation, you first call `useAddByPersonalSchoolIdPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddByPersonalSchoolIdPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addByPersonalSchoolIdPermissionMutation, { data, loading, error }] = useAddByPersonalSchoolIdPermissionMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      flagId: // value for 'flagId'
 *   },
 * });
 */
export function useAddByPersonalSchoolIdPermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddByPersonalSchoolIdPermissionMutation,
    AddByPersonalSchoolIdPermissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddByPersonalSchoolIdPermissionMutation,
    AddByPersonalSchoolIdPermissionMutationVariables
  >(AddByPersonalSchoolIdPermissionDocument, options);
}
export type AddByPersonalSchoolIdPermissionMutationHookResult = ReturnType<
  typeof useAddByPersonalSchoolIdPermissionMutation
>;
export type AddByPersonalSchoolIdPermissionMutationResult =
  Apollo.MutationResult<AddByPersonalSchoolIdPermissionMutation>;
export type AddByPersonalSchoolIdPermissionMutationOptions =
  Apollo.BaseMutationOptions<
    AddByPersonalSchoolIdPermissionMutation,
    AddByPersonalSchoolIdPermissionMutationVariables
  >;
export const RemoveByPersonalSchoolIdPermissionDocument = gql`
  mutation RemoveByPersonalSchoolIdPermission(
    $schoolId: UUID!
    $flagId: UUID!
  ) {
    removeByPersonalSchoolIdPermission(schoolId: $schoolId, flagId: $flagId)
  }
`;
export type RemoveByPersonalSchoolIdPermissionMutationFn =
  Apollo.MutationFunction<
    RemoveByPersonalSchoolIdPermissionMutation,
    RemoveByPersonalSchoolIdPermissionMutationVariables
  >;

/**
 * __useRemoveByPersonalSchoolIdPermissionMutation__
 *
 * To run a mutation, you first call `useRemoveByPersonalSchoolIdPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveByPersonalSchoolIdPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeByPersonalSchoolIdPermissionMutation, { data, loading, error }] = useRemoveByPersonalSchoolIdPermissionMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      flagId: // value for 'flagId'
 *   },
 * });
 */
export function useRemoveByPersonalSchoolIdPermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveByPersonalSchoolIdPermissionMutation,
    RemoveByPersonalSchoolIdPermissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveByPersonalSchoolIdPermissionMutation,
    RemoveByPersonalSchoolIdPermissionMutationVariables
  >(RemoveByPersonalSchoolIdPermissionDocument, options);
}
export type RemoveByPersonalSchoolIdPermissionMutationHookResult = ReturnType<
  typeof useRemoveByPersonalSchoolIdPermissionMutation
>;
export type RemoveByPersonalSchoolIdPermissionMutationResult =
  Apollo.MutationResult<RemoveByPersonalSchoolIdPermissionMutation>;
export type RemoveByPersonalSchoolIdPermissionMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveByPersonalSchoolIdPermissionMutation,
    RemoveByPersonalSchoolIdPermissionMutationVariables
  >;
export const PublishedWhiteLabelCollectionDocument = gql`
  mutation publishedWhiteLabelCollection(
    $updateWhiteLabelCollectionId: UUID!
    $isPublished: Boolean
  ) {
    updateWhiteLabelCollection(
      id: $updateWhiteLabelCollectionId
      isPublished: $isPublished
    )
  }
`;
export type PublishedWhiteLabelCollectionMutationFn = Apollo.MutationFunction<
  PublishedWhiteLabelCollectionMutation,
  PublishedWhiteLabelCollectionMutationVariables
>;

/**
 * __usePublishedWhiteLabelCollectionMutation__
 *
 * To run a mutation, you first call `usePublishedWhiteLabelCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishedWhiteLabelCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishedWhiteLabelCollectionMutation, { data, loading, error }] = usePublishedWhiteLabelCollectionMutation({
 *   variables: {
 *      updateWhiteLabelCollectionId: // value for 'updateWhiteLabelCollectionId'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function usePublishedWhiteLabelCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishedWhiteLabelCollectionMutation,
    PublishedWhiteLabelCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishedWhiteLabelCollectionMutation,
    PublishedWhiteLabelCollectionMutationVariables
  >(PublishedWhiteLabelCollectionDocument, options);
}
export type PublishedWhiteLabelCollectionMutationHookResult = ReturnType<
  typeof usePublishedWhiteLabelCollectionMutation
>;
export type PublishedWhiteLabelCollectionMutationResult =
  Apollo.MutationResult<PublishedWhiteLabelCollectionMutation>;
export type PublishedWhiteLabelCollectionMutationOptions =
  Apollo.BaseMutationOptions<
    PublishedWhiteLabelCollectionMutation,
    PublishedWhiteLabelCollectionMutationVariables
  >;
export const DeleteWhiteLabelCollectionDocument = gql`
  mutation deleteWhiteLabelCollection($deleteWhiteLabelCollectionId: UUID!) {
    deleteWhiteLabelCollection(id: $deleteWhiteLabelCollectionId)
  }
`;
export type DeleteWhiteLabelCollectionMutationFn = Apollo.MutationFunction<
  DeleteWhiteLabelCollectionMutation,
  DeleteWhiteLabelCollectionMutationVariables
>;

/**
 * __useDeleteWhiteLabelCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteWhiteLabelCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWhiteLabelCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWhiteLabelCollectionMutation, { data, loading, error }] = useDeleteWhiteLabelCollectionMutation({
 *   variables: {
 *      deleteWhiteLabelCollectionId: // value for 'deleteWhiteLabelCollectionId'
 *   },
 * });
 */
export function useDeleteWhiteLabelCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWhiteLabelCollectionMutation,
    DeleteWhiteLabelCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteWhiteLabelCollectionMutation,
    DeleteWhiteLabelCollectionMutationVariables
  >(DeleteWhiteLabelCollectionDocument, options);
}
export type DeleteWhiteLabelCollectionMutationHookResult = ReturnType<
  typeof useDeleteWhiteLabelCollectionMutation
>;
export type DeleteWhiteLabelCollectionMutationResult =
  Apollo.MutationResult<DeleteWhiteLabelCollectionMutation>;
export type DeleteWhiteLabelCollectionMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteWhiteLabelCollectionMutation,
    DeleteWhiteLabelCollectionMutationVariables
  >;
export const CreateWhiteLabelCollectionDocument = gql`
  mutation CreateWhiteLabelCollection(
    $name: String!
    $schoolLogo: Upload!
    $systemLogo: Upload!
    $systemLogoLarge: Upload!
    $banner: Upload!
    $backgroundColor: String!
    $welcomeHtml: String
    $slug: String
    $textColor: String
    $image: Upload
    $description: String
  ) {
    createWhiteLabelCollection(
      name: $name
      schoolLogo: $schoolLogo
      systemLogo: $systemLogo
      systemLogoLarge: $systemLogoLarge
      banner: $banner
      backgroundColor: $backgroundColor
      welcomeHtml: $welcomeHtml
      slug: $slug
      textColor: $textColor
      image: $image
      description: $description
    )
  }
`;
export type CreateWhiteLabelCollectionMutationFn = Apollo.MutationFunction<
  CreateWhiteLabelCollectionMutation,
  CreateWhiteLabelCollectionMutationVariables
>;

/**
 * __useCreateWhiteLabelCollectionMutation__
 *
 * To run a mutation, you first call `useCreateWhiteLabelCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWhiteLabelCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWhiteLabelCollectionMutation, { data, loading, error }] = useCreateWhiteLabelCollectionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      schoolLogo: // value for 'schoolLogo'
 *      systemLogo: // value for 'systemLogo'
 *      systemLogoLarge: // value for 'systemLogoLarge'
 *      banner: // value for 'banner'
 *      backgroundColor: // value for 'backgroundColor'
 *      welcomeHtml: // value for 'welcomeHtml'
 *      slug: // value for 'slug'
 *      textColor: // value for 'textColor'
 *      image: // value for 'image'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateWhiteLabelCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWhiteLabelCollectionMutation,
    CreateWhiteLabelCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWhiteLabelCollectionMutation,
    CreateWhiteLabelCollectionMutationVariables
  >(CreateWhiteLabelCollectionDocument, options);
}
export type CreateWhiteLabelCollectionMutationHookResult = ReturnType<
  typeof useCreateWhiteLabelCollectionMutation
>;
export type CreateWhiteLabelCollectionMutationResult =
  Apollo.MutationResult<CreateWhiteLabelCollectionMutation>;
export type CreateWhiteLabelCollectionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateWhiteLabelCollectionMutation,
    CreateWhiteLabelCollectionMutationVariables
  >;
export const UpdateWhiteLabelCollectionDocument = gql`
  mutation UpdateWhiteLabelCollection(
    $id: UUID!
    $name: String
    $schoolLogo: Upload
    $systemLogo: Upload
    $systemLogoLarge: Upload
    $banner: Upload
    $backgroundColor: String
    $welcomeHtml: String
    $slug: String
    $textColor: String
    $image: Upload
    $description: String
  ) {
    updateWhiteLabelCollection(
      id: $id
      name: $name
      schoolLogo: $schoolLogo
      systemLogo: $systemLogo
      systemLogoLarge: $systemLogoLarge
      banner: $banner
      backgroundColor: $backgroundColor
      welcomeHtml: $welcomeHtml
      slug: $slug
      textColor: $textColor
      image: $image
      description: $description
    )
  }
`;
export type UpdateWhiteLabelCollectionMutationFn = Apollo.MutationFunction<
  UpdateWhiteLabelCollectionMutation,
  UpdateWhiteLabelCollectionMutationVariables
>;

/**
 * __useUpdateWhiteLabelCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateWhiteLabelCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWhiteLabelCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWhiteLabelCollectionMutation, { data, loading, error }] = useUpdateWhiteLabelCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      schoolLogo: // value for 'schoolLogo'
 *      systemLogo: // value for 'systemLogo'
 *      systemLogoLarge: // value for 'systemLogoLarge'
 *      banner: // value for 'banner'
 *      backgroundColor: // value for 'backgroundColor'
 *      welcomeHtml: // value for 'welcomeHtml'
 *      slug: // value for 'slug'
 *      textColor: // value for 'textColor'
 *      image: // value for 'image'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateWhiteLabelCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWhiteLabelCollectionMutation,
    UpdateWhiteLabelCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWhiteLabelCollectionMutation,
    UpdateWhiteLabelCollectionMutationVariables
  >(UpdateWhiteLabelCollectionDocument, options);
}
export type UpdateWhiteLabelCollectionMutationHookResult = ReturnType<
  typeof useUpdateWhiteLabelCollectionMutation
>;
export type UpdateWhiteLabelCollectionMutationResult =
  Apollo.MutationResult<UpdateWhiteLabelCollectionMutation>;
export type UpdateWhiteLabelCollectionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateWhiteLabelCollectionMutation,
    UpdateWhiteLabelCollectionMutationVariables
  >;
export const CheckIfWhiteLabelCollectionIsInUseDocument = gql`
  mutation checkIfWhiteLabelCollectionIsInUse(
    $checkIfWhiteLabelCollectionIsInUseId: UUID!
  ) {
    checkIfWhiteLabelCollectionIsInUse(
      id: $checkIfWhiteLabelCollectionIsInUseId
    )
  }
`;
export type CheckIfWhiteLabelCollectionIsInUseMutationFn =
  Apollo.MutationFunction<
    CheckIfWhiteLabelCollectionIsInUseMutation,
    CheckIfWhiteLabelCollectionIsInUseMutationVariables
  >;

/**
 * __useCheckIfWhiteLabelCollectionIsInUseMutation__
 *
 * To run a mutation, you first call `useCheckIfWhiteLabelCollectionIsInUseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckIfWhiteLabelCollectionIsInUseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkIfWhiteLabelCollectionIsInUseMutation, { data, loading, error }] = useCheckIfWhiteLabelCollectionIsInUseMutation({
 *   variables: {
 *      checkIfWhiteLabelCollectionIsInUseId: // value for 'checkIfWhiteLabelCollectionIsInUseId'
 *   },
 * });
 */
export function useCheckIfWhiteLabelCollectionIsInUseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckIfWhiteLabelCollectionIsInUseMutation,
    CheckIfWhiteLabelCollectionIsInUseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CheckIfWhiteLabelCollectionIsInUseMutation,
    CheckIfWhiteLabelCollectionIsInUseMutationVariables
  >(CheckIfWhiteLabelCollectionIsInUseDocument, options);
}
export type CheckIfWhiteLabelCollectionIsInUseMutationHookResult = ReturnType<
  typeof useCheckIfWhiteLabelCollectionIsInUseMutation
>;
export type CheckIfWhiteLabelCollectionIsInUseMutationResult =
  Apollo.MutationResult<CheckIfWhiteLabelCollectionIsInUseMutation>;
export type CheckIfWhiteLabelCollectionIsInUseMutationOptions =
  Apollo.BaseMutationOptions<
    CheckIfWhiteLabelCollectionIsInUseMutation,
    CheckIfWhiteLabelCollectionIsInUseMutationVariables
  >;
export const GetAchievesDocument = gql`
  query getAchieves($limit: Int, $skip: Int, $isPublished: Boolean) {
    getAchieves(limit: $limit, skip: $skip, isPublished: $isPublished) {
      achieves {
        id
        name
        photo
        isPublished
        isForTEACHER
        isForPUPIL
        isForPARENT
        isForGUEST
        isLocked
      }
      total
    }
  }
`;

/**
 * __useGetAchievesQuery__
 *
 * To run a query within a React component, call `useGetAchievesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAchievesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAchievesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetAchievesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAchievesQuery,
    GetAchievesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAchievesQuery, GetAchievesQueryVariables>(
    GetAchievesDocument,
    options
  );
}
export function useGetAchievesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAchievesQuery,
    GetAchievesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAchievesQuery, GetAchievesQueryVariables>(
    GetAchievesDocument,
    options
  );
}
export type GetAchievesQueryHookResult = ReturnType<typeof useGetAchievesQuery>;
export type GetAchievesLazyQueryHookResult = ReturnType<
  typeof useGetAchievesLazyQuery
>;
export type GetAchievesQueryResult = Apollo.QueryResult<
  GetAchievesQuery,
  GetAchievesQueryVariables
>;
export const GetAchieveDocument = gql`
  query getAchieve($id: String!) {
    getAchieve(id: $id) {
      id
      name
      descriptionDone
      descriptionProgress
      type
      isLocked
      isPublished
      isForTEACHER
      isForPUPIL
      isForPARENT
      isForGUEST
      photo
    }
  }
`;

/**
 * __useGetAchieveQuery__
 *
 * To run a query within a React component, call `useGetAchieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAchieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAchieveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAchieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAchieveQuery,
    GetAchieveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAchieveQuery, GetAchieveQueryVariables>(
    GetAchieveDocument,
    options
  );
}
export function useGetAchieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAchieveQuery,
    GetAchieveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAchieveQuery, GetAchieveQueryVariables>(
    GetAchieveDocument,
    options
  );
}
export type GetAchieveQueryHookResult = ReturnType<typeof useGetAchieveQuery>;
export type GetAchieveLazyQueryHookResult = ReturnType<
  typeof useGetAchieveLazyQuery
>;
export type GetAchieveQueryResult = Apollo.QueryResult<
  GetAchieveQuery,
  GetAchieveQueryVariables
>;
export const GetAddedTasksReportDocument = gql`
  query getAddedTasksReport($startDate: Date, $endDate: Date) {
    getAddedTasksReport(startDate: $startDate, endDate: $endDate) {
      projectId
      projectName
      count
      timeSpent
      cost
    }
  }
`;

/**
 * __useGetAddedTasksReportQuery__
 *
 * To run a query within a React component, call `useGetAddedTasksReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddedTasksReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddedTasksReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetAddedTasksReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAddedTasksReportQuery,
    GetAddedTasksReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAddedTasksReportQuery,
    GetAddedTasksReportQueryVariables
  >(GetAddedTasksReportDocument, options);
}
export function useGetAddedTasksReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddedTasksReportQuery,
    GetAddedTasksReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAddedTasksReportQuery,
    GetAddedTasksReportQueryVariables
  >(GetAddedTasksReportDocument, options);
}
export type GetAddedTasksReportQueryHookResult = ReturnType<
  typeof useGetAddedTasksReportQuery
>;
export type GetAddedTasksReportLazyQueryHookResult = ReturnType<
  typeof useGetAddedTasksReportLazyQuery
>;
export type GetAddedTasksReportQueryResult = Apollo.QueryResult<
  GetAddedTasksReportQuery,
  GetAddedTasksReportQueryVariables
>;
export const GetAddedTasksEpicsDocument = gql`
  query getAddedTasksEpics(
    $projectId: String!
    $startDate: Date
    $endDate: Date
  ) {
    getAddedTasksEpics(
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
    ) {
      epicKey
      epicName
      totalTimeSpent
      cost
    }
  }
`;

/**
 * __useGetAddedTasksEpicsQuery__
 *
 * To run a query within a React component, call `useGetAddedTasksEpicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddedTasksEpicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddedTasksEpicsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetAddedTasksEpicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAddedTasksEpicsQuery,
    GetAddedTasksEpicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAddedTasksEpicsQuery,
    GetAddedTasksEpicsQueryVariables
  >(GetAddedTasksEpicsDocument, options);
}
export function useGetAddedTasksEpicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddedTasksEpicsQuery,
    GetAddedTasksEpicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAddedTasksEpicsQuery,
    GetAddedTasksEpicsQueryVariables
  >(GetAddedTasksEpicsDocument, options);
}
export type GetAddedTasksEpicsQueryHookResult = ReturnType<
  typeof useGetAddedTasksEpicsQuery
>;
export type GetAddedTasksEpicsLazyQueryHookResult = ReturnType<
  typeof useGetAddedTasksEpicsLazyQuery
>;
export type GetAddedTasksEpicsQueryResult = Apollo.QueryResult<
  GetAddedTasksEpicsQuery,
  GetAddedTasksEpicsQueryVariables
>;
export const GetAddedTasksReportByEpicDocument = gql`
  query getAddedTasksReportByEpic(
    $epicKey: String!
    $startDate: Date
    $endDate: Date
  ) {
    getAddedTasksReportByEpic(
      epicKey: $epicKey
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      name
      assigneeName
      status
      timeSpent
      cost
    }
  }
`;

/**
 * __useGetAddedTasksReportByEpicQuery__
 *
 * To run a query within a React component, call `useGetAddedTasksReportByEpicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddedTasksReportByEpicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddedTasksReportByEpicQuery({
 *   variables: {
 *      epicKey: // value for 'epicKey'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetAddedTasksReportByEpicQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAddedTasksReportByEpicQuery,
    GetAddedTasksReportByEpicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAddedTasksReportByEpicQuery,
    GetAddedTasksReportByEpicQueryVariables
  >(GetAddedTasksReportByEpicDocument, options);
}
export function useGetAddedTasksReportByEpicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddedTasksReportByEpicQuery,
    GetAddedTasksReportByEpicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAddedTasksReportByEpicQuery,
    GetAddedTasksReportByEpicQueryVariables
  >(GetAddedTasksReportByEpicDocument, options);
}
export type GetAddedTasksReportByEpicQueryHookResult = ReturnType<
  typeof useGetAddedTasksReportByEpicQuery
>;
export type GetAddedTasksReportByEpicLazyQueryHookResult = ReturnType<
  typeof useGetAddedTasksReportByEpicLazyQuery
>;
export type GetAddedTasksReportByEpicQueryResult = Apollo.QueryResult<
  GetAddedTasksReportByEpicQuery,
  GetAddedTasksReportByEpicQueryVariables
>;
export const GetUserAgreementsDocument = gql`
  query getUserAgreements {
    getUserAgreements {
      id
      name
    }
  }
`;

/**
 * __useGetUserAgreementsQuery__
 *
 * To run a query within a React component, call `useGetUserAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAgreementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAgreementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserAgreementsQuery,
    GetUserAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserAgreementsQuery,
    GetUserAgreementsQueryVariables
  >(GetUserAgreementsDocument, options);
}
export function useGetUserAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAgreementsQuery,
    GetUserAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserAgreementsQuery,
    GetUserAgreementsQueryVariables
  >(GetUserAgreementsDocument, options);
}
export type GetUserAgreementsQueryHookResult = ReturnType<
  typeof useGetUserAgreementsQuery
>;
export type GetUserAgreementsLazyQueryHookResult = ReturnType<
  typeof useGetUserAgreementsLazyQuery
>;
export type GetUserAgreementsQueryResult = Apollo.QueryResult<
  GetUserAgreementsQuery,
  GetUserAgreementsQueryVariables
>;
export const GetAnimationBundlesDocument = gql`
  query getAnimationBundles($animationData: GetAnimationBundlesInput!) {
    getAnimationBundles(animationData: $animationData) {
      animationBundles {
        id
        type {
          displayName
          type
        }
        nameEN
        nameUKR
        descriptionEN
        descriptionUKR
        photo
        lat
        long
        iosBundle {
          id
          name
          fileLink
        }
        androidBundle {
          id
          name
          fileLink
        }
        participants {
          id
        }
        availableForEveryone
        createdDate
      }
    }
  }
`;

/**
 * __useGetAnimationBundlesQuery__
 *
 * To run a query within a React component, call `useGetAnimationBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnimationBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnimationBundlesQuery({
 *   variables: {
 *      animationData: // value for 'animationData'
 *   },
 * });
 */
export function useGetAnimationBundlesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAnimationBundlesQuery,
    GetAnimationBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAnimationBundlesQuery,
    GetAnimationBundlesQueryVariables
  >(GetAnimationBundlesDocument, options);
}
export function useGetAnimationBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnimationBundlesQuery,
    GetAnimationBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAnimationBundlesQuery,
    GetAnimationBundlesQueryVariables
  >(GetAnimationBundlesDocument, options);
}
export type GetAnimationBundlesQueryHookResult = ReturnType<
  typeof useGetAnimationBundlesQuery
>;
export type GetAnimationBundlesLazyQueryHookResult = ReturnType<
  typeof useGetAnimationBundlesLazyQuery
>;
export type GetAnimationBundlesQueryResult = Apollo.QueryResult<
  GetAnimationBundlesQuery,
  GetAnimationBundlesQueryVariables
>;
export const GetAnimationBundleTypesDocument = gql`
  query getAnimationBundleTypes {
    getAnimationBundleTypes {
      id
      type
      displayName
    }
  }
`;

/**
 * __useGetAnimationBundleTypesQuery__
 *
 * To run a query within a React component, call `useGetAnimationBundleTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnimationBundleTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnimationBundleTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnimationBundleTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAnimationBundleTypesQuery,
    GetAnimationBundleTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAnimationBundleTypesQuery,
    GetAnimationBundleTypesQueryVariables
  >(GetAnimationBundleTypesDocument, options);
}
export function useGetAnimationBundleTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnimationBundleTypesQuery,
    GetAnimationBundleTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAnimationBundleTypesQuery,
    GetAnimationBundleTypesQueryVariables
  >(GetAnimationBundleTypesDocument, options);
}
export type GetAnimationBundleTypesQueryHookResult = ReturnType<
  typeof useGetAnimationBundleTypesQuery
>;
export type GetAnimationBundleTypesLazyQueryHookResult = ReturnType<
  typeof useGetAnimationBundleTypesLazyQuery
>;
export type GetAnimationBundleTypesQueryResult = Apollo.QueryResult<
  GetAnimationBundleTypesQuery,
  GetAnimationBundleTypesQueryVariables
>;
export const GetAppMainThemeBackgroundsDocument = gql`
  query getAppMainThemeBackgrounds {
    getAppMainThemeBackgrounds {
      id
      name
      fileIOS
      fileAndroid
      fileWebGl
      manifestIOS
      manifestAndroid
      manifestWebGl
      themes {
        id
        analyticsMark
      }
    }
  }
`;

/**
 * __useGetAppMainThemeBackgroundsQuery__
 *
 * To run a query within a React component, call `useGetAppMainThemeBackgroundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppMainThemeBackgroundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppMainThemeBackgroundsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppMainThemeBackgroundsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAppMainThemeBackgroundsQuery,
    GetAppMainThemeBackgroundsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAppMainThemeBackgroundsQuery,
    GetAppMainThemeBackgroundsQueryVariables
  >(GetAppMainThemeBackgroundsDocument, options);
}
export function useGetAppMainThemeBackgroundsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppMainThemeBackgroundsQuery,
    GetAppMainThemeBackgroundsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppMainThemeBackgroundsQuery,
    GetAppMainThemeBackgroundsQueryVariables
  >(GetAppMainThemeBackgroundsDocument, options);
}
export type GetAppMainThemeBackgroundsQueryHookResult = ReturnType<
  typeof useGetAppMainThemeBackgroundsQuery
>;
export type GetAppMainThemeBackgroundsLazyQueryHookResult = ReturnType<
  typeof useGetAppMainThemeBackgroundsLazyQuery
>;
export type GetAppMainThemeBackgroundsQueryResult = Apollo.QueryResult<
  GetAppMainThemeBackgroundsQuery,
  GetAppMainThemeBackgroundsQueryVariables
>;
export const GetApplicationsMainDocument = gql`
  query getApplicationsMain(
    $skip: Int
    $limit: Int
    $applicationId: String!
    $isPublished: Boolean
  ) {
    getApplicationsMain(
      skip: $skip
      limit: $limit
      applicationId: $applicationId
      isPublished: $isPublished
    ) {
      applicationsMain {
        id
        name
        description
        photo
        isMap
        availableFor
        isPublished
        applicationId
        showInSubjects
        application {
          id
        }
      }
      total
    }
  }
`;

/**
 * __useGetApplicationsMainQuery__
 *
 * To run a query within a React component, call `useGetApplicationsMainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsMainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationsMainQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      applicationId: // value for 'applicationId'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetApplicationsMainQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationsMainQuery,
    GetApplicationsMainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationsMainQuery,
    GetApplicationsMainQueryVariables
  >(GetApplicationsMainDocument, options);
}
export function useGetApplicationsMainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationsMainQuery,
    GetApplicationsMainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationsMainQuery,
    GetApplicationsMainQueryVariables
  >(GetApplicationsMainDocument, options);
}
export type GetApplicationsMainQueryHookResult = ReturnType<
  typeof useGetApplicationsMainQuery
>;
export type GetApplicationsMainLazyQueryHookResult = ReturnType<
  typeof useGetApplicationsMainLazyQuery
>;
export type GetApplicationsMainQueryResult = Apollo.QueryResult<
  GetApplicationsMainQuery,
  GetApplicationsMainQueryVariables
>;
export const GetApplicationMainDocument = gql`
  query getApplicationMain($id: UUID!) {
    getApplicationMain(id: $id) {
      id
      name
      photo
      icon
      description
      background1
      background2
      background3
      background4
      background5
      isMap
      availableFor
      priority
      analyticsMark
      subscriptionPlan
      showInArBook
      participants {
        id
        photo
        firstName
        lastName
      }
      qr
      isPublished
      display
      themes {
        id
        nameEN
        nameUKR
        photo
        photoName
        backgroundId
        analyticsMark
        subscriptionPlan
        background {
          id
        }
      }
      marketContentCategories {
        id
        categoryId
        lang
      }
    }
  }
`;

/**
 * __useGetApplicationMainQuery__
 *
 * To run a query within a React component, call `useGetApplicationMainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationMainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationMainQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicationMainQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationMainQuery,
    GetApplicationMainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationMainQuery,
    GetApplicationMainQueryVariables
  >(GetApplicationMainDocument, options);
}
export function useGetApplicationMainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationMainQuery,
    GetApplicationMainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationMainQuery,
    GetApplicationMainQueryVariables
  >(GetApplicationMainDocument, options);
}
export type GetApplicationMainQueryHookResult = ReturnType<
  typeof useGetApplicationMainQuery
>;
export type GetApplicationMainLazyQueryHookResult = ReturnType<
  typeof useGetApplicationMainLazyQuery
>;
export type GetApplicationMainQueryResult = Apollo.QueryResult<
  GetApplicationMainQuery,
  GetApplicationMainQueryVariables
>;
export const GetApplicationMainModelsDocument = gql`
  query getApplicationMainModels(
    $skip: Int
    $limit: Int
    $applicationMainId: String
    $isPublished: Boolean
    $themeId: String
    $search: String
    $sort: SortTypeEnum
    $order: OrderTypeEnum
  ) {
    getApplicationMainModels(
      skip: $skip
      limit: $limit
      applicationMainId: $applicationMainId
      isPublished: $isPublished
      themeId: $themeId
      search: $search
      sort: $sort
      order: $order
    ) {
      models {
        id
        name
        description
        photo
        isPublished
        smartNotificationId
        marketId
        isUploadedToMarket
      }
      total
    }
  }
`;

/**
 * __useGetApplicationMainModelsQuery__
 *
 * To run a query within a React component, call `useGetApplicationMainModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationMainModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationMainModelsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      applicationMainId: // value for 'applicationMainId'
 *      isPublished: // value for 'isPublished'
 *      themeId: // value for 'themeId'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetApplicationMainModelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApplicationMainModelsQuery,
    GetApplicationMainModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationMainModelsQuery,
    GetApplicationMainModelsQueryVariables
  >(GetApplicationMainModelsDocument, options);
}
export function useGetApplicationMainModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationMainModelsQuery,
    GetApplicationMainModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationMainModelsQuery,
    GetApplicationMainModelsQueryVariables
  >(GetApplicationMainModelsDocument, options);
}
export type GetApplicationMainModelsQueryHookResult = ReturnType<
  typeof useGetApplicationMainModelsQuery
>;
export type GetApplicationMainModelsLazyQueryHookResult = ReturnType<
  typeof useGetApplicationMainModelsLazyQuery
>;
export type GetApplicationMainModelsQueryResult = Apollo.QueryResult<
  GetApplicationMainModelsQuery,
  GetApplicationMainModelsQueryVariables
>;
export const GetApplicationMainModelsSelectDocument = gql`
  query getApplicationMainModelsSelect(
    $skip: Int
    $limit: Int
    $applicationMainId: String!
    $isPublished: Boolean
  ) {
    getApplicationMainModels(
      skip: $skip
      limit: $limit
      applicationMainId: $applicationMainId
      isPublished: $isPublished
    ) {
      models {
        id
        name
      }
    }
  }
`;

/**
 * __useGetApplicationMainModelsSelectQuery__
 *
 * To run a query within a React component, call `useGetApplicationMainModelsSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationMainModelsSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationMainModelsSelectQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      applicationMainId: // value for 'applicationMainId'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetApplicationMainModelsSelectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationMainModelsSelectQuery,
    GetApplicationMainModelsSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationMainModelsSelectQuery,
    GetApplicationMainModelsSelectQueryVariables
  >(GetApplicationMainModelsSelectDocument, options);
}
export function useGetApplicationMainModelsSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationMainModelsSelectQuery,
    GetApplicationMainModelsSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationMainModelsSelectQuery,
    GetApplicationMainModelsSelectQueryVariables
  >(GetApplicationMainModelsSelectDocument, options);
}
export type GetApplicationMainModelsSelectQueryHookResult = ReturnType<
  typeof useGetApplicationMainModelsSelectQuery
>;
export type GetApplicationMainModelsSelectLazyQueryHookResult = ReturnType<
  typeof useGetApplicationMainModelsSelectLazyQuery
>;
export type GetApplicationMainModelsSelectQueryResult = Apollo.QueryResult<
  GetApplicationMainModelsSelectQuery,
  GetApplicationMainModelsSelectQueryVariables
>;
export const GetApplicationMainModelDocument = gql`
  query getApplicationMainModel($id: String!) {
    getApplicationMainModel(id: $id) {
      id
      marketId
      name
      description
      contentType {
        name
      }
      isUploadedToMarket
      lat
      lang
      longitude
      address
      mapMarkerPhoto
      photo
      isBundle
      childId
      bundles {
        id
        type
        language
        file
        manifest
      }
      schoolGrade {
        id
        name
      }
      schoolGradeId
      inGradeOrder
      subscriptionPlan
      bundleType
      bundlePhoto
      bundleLink
      iosBundle
      vrBundle
      androidBundle
      isMarker
      markerWidth
      markerPhoto
      isMap
      isPublished
      isContour
      themeId
      smartNotificationId
      showSmartNotification
      clicks
      contentAuthor
      schoolGrades {
        id
        name
      }
      educationAreas {
        id
        name
        createdDate
      }
      main {
        id
        name
      }
      mains {
        id
        name
      }
      contentPreviews {
        id
        url
        type
        order
      }
      tags {
        id
        name
        displayName
      }
      coloringPageFile
      libraryContent {
        equalId
        price
      }
    }
  }
`;

/**
 * __useGetApplicationMainModelQuery__
 *
 * To run a query within a React component, call `useGetApplicationMainModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationMainModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationMainModelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicationMainModelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationMainModelQuery,
    GetApplicationMainModelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationMainModelQuery,
    GetApplicationMainModelQueryVariables
  >(GetApplicationMainModelDocument, options);
}
export function useGetApplicationMainModelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationMainModelQuery,
    GetApplicationMainModelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationMainModelQuery,
    GetApplicationMainModelQueryVariables
  >(GetApplicationMainModelDocument, options);
}
export type GetApplicationMainModelQueryHookResult = ReturnType<
  typeof useGetApplicationMainModelQuery
>;
export type GetApplicationMainModelLazyQueryHookResult = ReturnType<
  typeof useGetApplicationMainModelLazyQuery
>;
export type GetApplicationMainModelQueryResult = Apollo.QueryResult<
  GetApplicationMainModelQuery,
  GetApplicationMainModelQueryVariables
>;
export const GetEducationAreasDocument = gql`
  query GetEducationAreas {
    getEducationAreas {
      id
      name
    }
  }
`;

/**
 * __useGetEducationAreasQuery__
 *
 * To run a query within a React component, call `useGetEducationAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEducationAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEducationAreasQuery,
    GetEducationAreasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEducationAreasQuery,
    GetEducationAreasQueryVariables
  >(GetEducationAreasDocument, options);
}
export function useGetEducationAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEducationAreasQuery,
    GetEducationAreasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEducationAreasQuery,
    GetEducationAreasQueryVariables
  >(GetEducationAreasDocument, options);
}
export type GetEducationAreasQueryHookResult = ReturnType<
  typeof useGetEducationAreasQuery
>;
export type GetEducationAreasLazyQueryHookResult = ReturnType<
  typeof useGetEducationAreasLazyQuery
>;
export type GetEducationAreasQueryResult = Apollo.QueryResult<
  GetEducationAreasQuery,
  GetEducationAreasQueryVariables
>;
export const GetApplicationsMainForSelectDocument = gql`
  query getApplicationsMainForSelect(
    $skip: Int
    $limit: Int
    $applicationId: String!
    $isPublished: Boolean
  ) {
    getApplicationsMain(
      skip: $skip
      limit: $limit
      applicationId: $applicationId
      isPublished: $isPublished
    ) {
      applicationsMain {
        id
        name
      }
    }
  }
`;

/**
 * __useGetApplicationsMainForSelectQuery__
 *
 * To run a query within a React component, call `useGetApplicationsMainForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsMainForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationsMainForSelectQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      applicationId: // value for 'applicationId'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetApplicationsMainForSelectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationsMainForSelectQuery,
    GetApplicationsMainForSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationsMainForSelectQuery,
    GetApplicationsMainForSelectQueryVariables
  >(GetApplicationsMainForSelectDocument, options);
}
export function useGetApplicationsMainForSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationsMainForSelectQuery,
    GetApplicationsMainForSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationsMainForSelectQuery,
    GetApplicationsMainForSelectQueryVariables
  >(GetApplicationsMainForSelectDocument, options);
}
export type GetApplicationsMainForSelectQueryHookResult = ReturnType<
  typeof useGetApplicationsMainForSelectQuery
>;
export type GetApplicationsMainForSelectLazyQueryHookResult = ReturnType<
  typeof useGetApplicationsMainForSelectLazyQuery
>;
export type GetApplicationsMainForSelectQueryResult = Apollo.QueryResult<
  GetApplicationsMainForSelectQuery,
  GetApplicationsMainForSelectQueryVariables
>;
export const GetContentTagByContentTypeDocument = gql`
  query getContentTagByContentType($contentType: [ContentTypeEnum!]!) {
    getContentTagByContentType(contentType: $contentType) {
      id
      name
      displayName
    }
  }
`;

/**
 * __useGetContentTagByContentTypeQuery__
 *
 * To run a query within a React component, call `useGetContentTagByContentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentTagByContentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentTagByContentTypeQuery({
 *   variables: {
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useGetContentTagByContentTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContentTagByContentTypeQuery,
    GetContentTagByContentTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContentTagByContentTypeQuery,
    GetContentTagByContentTypeQueryVariables
  >(GetContentTagByContentTypeDocument, options);
}
export function useGetContentTagByContentTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContentTagByContentTypeQuery,
    GetContentTagByContentTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContentTagByContentTypeQuery,
    GetContentTagByContentTypeQueryVariables
  >(GetContentTagByContentTypeDocument, options);
}
export type GetContentTagByContentTypeQueryHookResult = ReturnType<
  typeof useGetContentTagByContentTypeQuery
>;
export type GetContentTagByContentTypeLazyQueryHookResult = ReturnType<
  typeof useGetContentTagByContentTypeLazyQuery
>;
export type GetContentTagByContentTypeQueryResult = Apollo.QueryResult<
  GetContentTagByContentTypeQuery,
  GetContentTagByContentTypeQueryVariables
>;
export const GetApplicationMainThemesByMainIdDocument = gql`
  query getApplicationMainThemesByMainId($applicationMainId: String!) {
    getApplicationMainThemesByMainId(applicationMainId: $applicationMainId) {
      id
      nameEN
      nameUKR
      analyticsMark
      subscriptionPlan
    }
  }
`;

/**
 * __useGetApplicationMainThemesByMainIdQuery__
 *
 * To run a query within a React component, call `useGetApplicationMainThemesByMainIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationMainThemesByMainIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationMainThemesByMainIdQuery({
 *   variables: {
 *      applicationMainId: // value for 'applicationMainId'
 *   },
 * });
 */
export function useGetApplicationMainThemesByMainIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationMainThemesByMainIdQuery,
    GetApplicationMainThemesByMainIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationMainThemesByMainIdQuery,
    GetApplicationMainThemesByMainIdQueryVariables
  >(GetApplicationMainThemesByMainIdDocument, options);
}
export function useGetApplicationMainThemesByMainIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationMainThemesByMainIdQuery,
    GetApplicationMainThemesByMainIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationMainThemesByMainIdQuery,
    GetApplicationMainThemesByMainIdQueryVariables
  >(GetApplicationMainThemesByMainIdDocument, options);
}
export type GetApplicationMainThemesByMainIdQueryHookResult = ReturnType<
  typeof useGetApplicationMainThemesByMainIdQuery
>;
export type GetApplicationMainThemesByMainIdLazyQueryHookResult = ReturnType<
  typeof useGetApplicationMainThemesByMainIdLazyQuery
>;
export type GetApplicationMainThemesByMainIdQueryResult = Apollo.QueryResult<
  GetApplicationMainThemesByMainIdQuery,
  GetApplicationMainThemesByMainIdQueryVariables
>;
export const GetApplicationVersionsDocument = gql`
  query getApplicationVersions($appId: String!) {
    getApplicationVersions(appId: $appId) {
      id
      os
      version
      createdDate
    }
  }
`;

/**
 * __useGetApplicationVersionsQuery__
 *
 * To run a query within a React component, call `useGetApplicationVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationVersionsQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useGetApplicationVersionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationVersionsQuery,
    GetApplicationVersionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationVersionsQuery,
    GetApplicationVersionsQueryVariables
  >(GetApplicationVersionsDocument, options);
}
export function useGetApplicationVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationVersionsQuery,
    GetApplicationVersionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationVersionsQuery,
    GetApplicationVersionsQueryVariables
  >(GetApplicationVersionsDocument, options);
}
export type GetApplicationVersionsQueryHookResult = ReturnType<
  typeof useGetApplicationVersionsQuery
>;
export type GetApplicationVersionsLazyQueryHookResult = ReturnType<
  typeof useGetApplicationVersionsLazyQuery
>;
export type GetApplicationVersionsQueryResult = Apollo.QueryResult<
  GetApplicationVersionsQuery,
  GetApplicationVersionsQueryVariables
>;
export const GetCurrentApplicationVersionDocument = gql`
  query getCurrentApplicationVersion($appId: String!, $os: OS!) {
    getCurrentApplicationVersion(appId: $appId, os: $os)
  }
`;

/**
 * __useGetCurrentApplicationVersionQuery__
 *
 * To run a query within a React component, call `useGetCurrentApplicationVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentApplicationVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentApplicationVersionQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      os: // value for 'os'
 *   },
 * });
 */
export function useGetCurrentApplicationVersionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentApplicationVersionQuery,
    GetCurrentApplicationVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentApplicationVersionQuery,
    GetCurrentApplicationVersionQueryVariables
  >(GetCurrentApplicationVersionDocument, options);
}
export function useGetCurrentApplicationVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentApplicationVersionQuery,
    GetCurrentApplicationVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentApplicationVersionQuery,
    GetCurrentApplicationVersionQueryVariables
  >(GetCurrentApplicationVersionDocument, options);
}
export type GetCurrentApplicationVersionQueryHookResult = ReturnType<
  typeof useGetCurrentApplicationVersionQuery
>;
export type GetCurrentApplicationVersionLazyQueryHookResult = ReturnType<
  typeof useGetCurrentApplicationVersionLazyQuery
>;
export type GetCurrentApplicationVersionQueryResult = Apollo.QueryResult<
  GetCurrentApplicationVersionQuery,
  GetCurrentApplicationVersionQueryVariables
>;
export const GetApplicationsDocument = gql`
  query getApplications($skip: Int, $limit: Int) {
    getApplications(skip: $skip, limit: $limit) {
      applications {
        id
        name
        description
        photo
        privacyPolicy
        userAgreement
        appStoreLink
        playMarketLink
        mainPhoto
        modelPhoto
        email
        phone
        comments
        isPublished
        links {
          id
          name
          link
        }
      }
      total
    }
  }
`;

/**
 * __useGetApplicationsQuery__
 *
 * To run a query within a React component, call `useGetApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApplicationsQuery,
    GetApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApplicationsQuery, GetApplicationsQueryVariables>(
    GetApplicationsDocument,
    options
  );
}
export function useGetApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationsQuery,
    GetApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationsQuery,
    GetApplicationsQueryVariables
  >(GetApplicationsDocument, options);
}
export type GetApplicationsQueryHookResult = ReturnType<
  typeof useGetApplicationsQuery
>;
export type GetApplicationsLazyQueryHookResult = ReturnType<
  typeof useGetApplicationsLazyQuery
>;
export type GetApplicationsQueryResult = Apollo.QueryResult<
  GetApplicationsQuery,
  GetApplicationsQueryVariables
>;
export const GetApplicationDocument = gql`
  query getApplication($id: String!) {
    getApplication(id: $id) {
      id
      name
      photo
      description
      privacyPolicy
      userAgreement
      appStoreLink
      playMarketLink
      mainPhoto
      modelPhoto
      email
      phone
      comments
      isPublished
      links {
        id
        name
        link
      }
    }
  }
`;

/**
 * __useGetApplicationQuery__
 *
 * To run a query within a React component, call `useGetApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationQuery,
    GetApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApplicationQuery, GetApplicationQueryVariables>(
    GetApplicationDocument,
    options
  );
}
export function useGetApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationQuery,
    GetApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApplicationQuery, GetApplicationQueryVariables>(
    GetApplicationDocument,
    options
  );
}
export type GetApplicationQueryHookResult = ReturnType<
  typeof useGetApplicationQuery
>;
export type GetApplicationLazyQueryHookResult = ReturnType<
  typeof useGetApplicationLazyQuery
>;
export type GetApplicationQueryResult = Apollo.QueryResult<
  GetApplicationQuery,
  GetApplicationQueryVariables
>;
export const GetPayrollBonusesDocument = gql`
  query getPayrollBonuses($payrollId: String!) {
    getPayrollBonuses(payrollId: $payrollId) {
      id
      name
      value
    }
  }
`;

/**
 * __useGetPayrollBonusesQuery__
 *
 * To run a query within a React component, call `useGetPayrollBonusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollBonusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollBonusesQuery({
 *   variables: {
 *      payrollId: // value for 'payrollId'
 *   },
 * });
 */
export function useGetPayrollBonusesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayrollBonusesQuery,
    GetPayrollBonusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPayrollBonusesQuery,
    GetPayrollBonusesQueryVariables
  >(GetPayrollBonusesDocument, options);
}
export function useGetPayrollBonusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayrollBonusesQuery,
    GetPayrollBonusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPayrollBonusesQuery,
    GetPayrollBonusesQueryVariables
  >(GetPayrollBonusesDocument, options);
}
export type GetPayrollBonusesQueryHookResult = ReturnType<
  typeof useGetPayrollBonusesQuery
>;
export type GetPayrollBonusesLazyQueryHookResult = ReturnType<
  typeof useGetPayrollBonusesLazyQuery
>;
export type GetPayrollBonusesQueryResult = Apollo.QueryResult<
  GetPayrollBonusesQuery,
  GetPayrollBonusesQueryVariables
>;
export const GetBugsAdditionalTasksReportDocument = gql`
  query getBugsAdditionalTasksReport($projectId: String!) {
    getBugsAdditionalTasksReport(projectId: $projectId) {
      name
      tasks
      percents
      cost
    }
  }
`;

/**
 * __useGetBugsAdditionalTasksReportQuery__
 *
 * To run a query within a React component, call `useGetBugsAdditionalTasksReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBugsAdditionalTasksReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBugsAdditionalTasksReportQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetBugsAdditionalTasksReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBugsAdditionalTasksReportQuery,
    GetBugsAdditionalTasksReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBugsAdditionalTasksReportQuery,
    GetBugsAdditionalTasksReportQueryVariables
  >(GetBugsAdditionalTasksReportDocument, options);
}
export function useGetBugsAdditionalTasksReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBugsAdditionalTasksReportQuery,
    GetBugsAdditionalTasksReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBugsAdditionalTasksReportQuery,
    GetBugsAdditionalTasksReportQueryVariables
  >(GetBugsAdditionalTasksReportDocument, options);
}
export type GetBugsAdditionalTasksReportQueryHookResult = ReturnType<
  typeof useGetBugsAdditionalTasksReportQuery
>;
export type GetBugsAdditionalTasksReportLazyQueryHookResult = ReturnType<
  typeof useGetBugsAdditionalTasksReportLazyQuery
>;
export type GetBugsAdditionalTasksReportQueryResult = Apollo.QueryResult<
  GetBugsAdditionalTasksReportQuery,
  GetBugsAdditionalTasksReportQueryVariables
>;
export const GetChartData2Document = gql`
  query getChartData2($projectId: String!, $isEpic: Boolean, $epicId: String) {
    getChartData2(projectId: $projectId, isEpic: $isEpic, epicId: $epicId) {
      id
      name
      start
      end
      progress
      type
      project
      isClient
      isAdditionalTask
    }
  }
`;

/**
 * __useGetChartData2Query__
 *
 * To run a query within a React component, call `useGetChartData2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetChartData2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartData2Query({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      isEpic: // value for 'isEpic'
 *      epicId: // value for 'epicId'
 *   },
 * });
 */
export function useGetChartData2Query(
  baseOptions: Apollo.QueryHookOptions<
    GetChartData2Query,
    GetChartData2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChartData2Query, GetChartData2QueryVariables>(
    GetChartData2Document,
    options
  );
}
export function useGetChartData2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChartData2Query,
    GetChartData2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChartData2Query, GetChartData2QueryVariables>(
    GetChartData2Document,
    options
  );
}
export type GetChartData2QueryHookResult = ReturnType<
  typeof useGetChartData2Query
>;
export type GetChartData2LazyQueryHookResult = ReturnType<
  typeof useGetChartData2LazyQuery
>;
export type GetChartData2QueryResult = Apollo.QueryResult<
  GetChartData2Query,
  GetChartData2QueryVariables
>;
export const GetModelsByClientSiteIdDocument = gql`
  query getModelsByClientSiteId($clientSiteId: String!) {
    getModelsByClientSiteId(clientSiteId: $clientSiteId) {
      models {
        id
        name
        glbFile
        usdzFile
        gltfFile
        clientSite {
          link
          iframe
        }
        itemId
      }
      total
    }
  }
`;

/**
 * __useGetModelsByClientSiteIdQuery__
 *
 * To run a query within a React component, call `useGetModelsByClientSiteIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelsByClientSiteIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelsByClientSiteIdQuery({
 *   variables: {
 *      clientSiteId: // value for 'clientSiteId'
 *   },
 * });
 */
export function useGetModelsByClientSiteIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetModelsByClientSiteIdQuery,
    GetModelsByClientSiteIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetModelsByClientSiteIdQuery,
    GetModelsByClientSiteIdQueryVariables
  >(GetModelsByClientSiteIdDocument, options);
}
export function useGetModelsByClientSiteIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetModelsByClientSiteIdQuery,
    GetModelsByClientSiteIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetModelsByClientSiteIdQuery,
    GetModelsByClientSiteIdQueryVariables
  >(GetModelsByClientSiteIdDocument, options);
}
export type GetModelsByClientSiteIdQueryHookResult = ReturnType<
  typeof useGetModelsByClientSiteIdQuery
>;
export type GetModelsByClientSiteIdLazyQueryHookResult = ReturnType<
  typeof useGetModelsByClientSiteIdLazyQuery
>;
export type GetModelsByClientSiteIdQueryResult = Apollo.QueryResult<
  GetModelsByClientSiteIdQuery,
  GetModelsByClientSiteIdQueryVariables
>;
export const GetClientSiteModelDocument = gql`
  query getClientSiteModel($id: String!) {
    getClientSiteModel(id: $id) {
      id
      name
      itemId
      items {
        id
        itemId
      }
      clientSite {
        name
        link
      }
      glbFile
      gltfFile
      usdzFile
    }
  }
`;

/**
 * __useGetClientSiteModelQuery__
 *
 * To run a query within a React component, call `useGetClientSiteModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientSiteModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientSiteModelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientSiteModelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientSiteModelQuery,
    GetClientSiteModelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientSiteModelQuery,
    GetClientSiteModelQueryVariables
  >(GetClientSiteModelDocument, options);
}
export function useGetClientSiteModelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientSiteModelQuery,
    GetClientSiteModelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientSiteModelQuery,
    GetClientSiteModelQueryVariables
  >(GetClientSiteModelDocument, options);
}
export type GetClientSiteModelQueryHookResult = ReturnType<
  typeof useGetClientSiteModelQuery
>;
export type GetClientSiteModelLazyQueryHookResult = ReturnType<
  typeof useGetClientSiteModelLazyQuery
>;
export type GetClientSiteModelQueryResult = Apollo.QueryResult<
  GetClientSiteModelQuery,
  GetClientSiteModelQueryVariables
>;
export const GetClientSiteModelByItemIdDocument = gql`
  query getClientSiteModelByItemId($itemId: String!, $siteKey: String!) {
    getClientSiteModelByItemId(itemId: $itemId, siteKey: $siteKey) {
      id
      name
      glbFile
      gltfFile
      usdzFile
    }
  }
`;

/**
 * __useGetClientSiteModelByItemIdQuery__
 *
 * To run a query within a React component, call `useGetClientSiteModelByItemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientSiteModelByItemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientSiteModelByItemIdQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      siteKey: // value for 'siteKey'
 *   },
 * });
 */
export function useGetClientSiteModelByItemIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientSiteModelByItemIdQuery,
    GetClientSiteModelByItemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientSiteModelByItemIdQuery,
    GetClientSiteModelByItemIdQueryVariables
  >(GetClientSiteModelByItemIdDocument, options);
}
export function useGetClientSiteModelByItemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientSiteModelByItemIdQuery,
    GetClientSiteModelByItemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientSiteModelByItemIdQuery,
    GetClientSiteModelByItemIdQueryVariables
  >(GetClientSiteModelByItemIdDocument, options);
}
export type GetClientSiteModelByItemIdQueryHookResult = ReturnType<
  typeof useGetClientSiteModelByItemIdQuery
>;
export type GetClientSiteModelByItemIdLazyQueryHookResult = ReturnType<
  typeof useGetClientSiteModelByItemIdLazyQuery
>;
export type GetClientSiteModelByItemIdQueryResult = Apollo.QueryResult<
  GetClientSiteModelByItemIdQuery,
  GetClientSiteModelByItemIdQueryVariables
>;
export const GetClientSiteModelItemsByModelIdDocument = gql`
  query getClientSiteModelItemsByModelId($clientSiteModelId: String!) {
    getClientSiteModelItemsByModelId(clientSiteModelId: $clientSiteModelId) {
      id
      itemId
    }
  }
`;

/**
 * __useGetClientSiteModelItemsByModelIdQuery__
 *
 * To run a query within a React component, call `useGetClientSiteModelItemsByModelIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientSiteModelItemsByModelIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientSiteModelItemsByModelIdQuery({
 *   variables: {
 *      clientSiteModelId: // value for 'clientSiteModelId'
 *   },
 * });
 */
export function useGetClientSiteModelItemsByModelIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientSiteModelItemsByModelIdQuery,
    GetClientSiteModelItemsByModelIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientSiteModelItemsByModelIdQuery,
    GetClientSiteModelItemsByModelIdQueryVariables
  >(GetClientSiteModelItemsByModelIdDocument, options);
}
export function useGetClientSiteModelItemsByModelIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientSiteModelItemsByModelIdQuery,
    GetClientSiteModelItemsByModelIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientSiteModelItemsByModelIdQuery,
    GetClientSiteModelItemsByModelIdQueryVariables
  >(GetClientSiteModelItemsByModelIdDocument, options);
}
export type GetClientSiteModelItemsByModelIdQueryHookResult = ReturnType<
  typeof useGetClientSiteModelItemsByModelIdQuery
>;
export type GetClientSiteModelItemsByModelIdLazyQueryHookResult = ReturnType<
  typeof useGetClientSiteModelItemsByModelIdLazyQuery
>;
export type GetClientSiteModelItemsByModelIdQueryResult = Apollo.QueryResult<
  GetClientSiteModelItemsByModelIdQuery,
  GetClientSiteModelItemsByModelIdQueryVariables
>;
export const GetClientSitesDocument = gql`
  query getClientSites {
    getClientSites {
      clientSites {
        id
        name
        link
        iframe
        key
      }
      total
    }
  }
`;

/**
 * __useGetClientSitesQuery__
 *
 * To run a query within a React component, call `useGetClientSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientSitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientSitesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClientSitesQuery,
    GetClientSitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientSitesQuery, GetClientSitesQueryVariables>(
    GetClientSitesDocument,
    options
  );
}
export function useGetClientSitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientSitesQuery,
    GetClientSitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientSitesQuery, GetClientSitesQueryVariables>(
    GetClientSitesDocument,
    options
  );
}
export type GetClientSitesQueryHookResult = ReturnType<
  typeof useGetClientSitesQuery
>;
export type GetClientSitesLazyQueryHookResult = ReturnType<
  typeof useGetClientSitesLazyQuery
>;
export type GetClientSitesQueryResult = Apollo.QueryResult<
  GetClientSitesQuery,
  GetClientSitesQueryVariables
>;
export const GetCompletionPctReportDocument = gql`
  query getCompletionPctReport($dates: [String]) {
    getCompletionPctReport(dates: $dates) {
      projectId
      projectName
      prc {
        plan
        fact
      }
    }
  }
`;

/**
 * __useGetCompletionPctReportQuery__
 *
 * To run a query within a React component, call `useGetCompletionPctReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletionPctReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletionPctReportQuery({
 *   variables: {
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useGetCompletionPctReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompletionPctReportQuery,
    GetCompletionPctReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompletionPctReportQuery,
    GetCompletionPctReportQueryVariables
  >(GetCompletionPctReportDocument, options);
}
export function useGetCompletionPctReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompletionPctReportQuery,
    GetCompletionPctReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompletionPctReportQuery,
    GetCompletionPctReportQueryVariables
  >(GetCompletionPctReportDocument, options);
}
export type GetCompletionPctReportQueryHookResult = ReturnType<
  typeof useGetCompletionPctReportQuery
>;
export type GetCompletionPctReportLazyQueryHookResult = ReturnType<
  typeof useGetCompletionPctReportLazyQuery
>;
export type GetCompletionPctReportQueryResult = Apollo.QueryResult<
  GetCompletionPctReportQuery,
  GetCompletionPctReportQueryVariables
>;
export const GetCompletionPctReportByEpicDocument = gql`
  query getCompletionPctReportByEpic($epicKey: String!) {
    getCompletionPctReportByEpic(epicKey: $epicKey) {
      tasks {
        id
        name
        assigneeName
        status
        epicPrc
        projectPrc
      }
      epicCompletionPct
      projectCompletionPct
    }
  }
`;

/**
 * __useGetCompletionPctReportByEpicQuery__
 *
 * To run a query within a React component, call `useGetCompletionPctReportByEpicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletionPctReportByEpicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletionPctReportByEpicQuery({
 *   variables: {
 *      epicKey: // value for 'epicKey'
 *   },
 * });
 */
export function useGetCompletionPctReportByEpicQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompletionPctReportByEpicQuery,
    GetCompletionPctReportByEpicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompletionPctReportByEpicQuery,
    GetCompletionPctReportByEpicQueryVariables
  >(GetCompletionPctReportByEpicDocument, options);
}
export function useGetCompletionPctReportByEpicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompletionPctReportByEpicQuery,
    GetCompletionPctReportByEpicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompletionPctReportByEpicQuery,
    GetCompletionPctReportByEpicQueryVariables
  >(GetCompletionPctReportByEpicDocument, options);
}
export type GetCompletionPctReportByEpicQueryHookResult = ReturnType<
  typeof useGetCompletionPctReportByEpicQuery
>;
export type GetCompletionPctReportByEpicLazyQueryHookResult = ReturnType<
  typeof useGetCompletionPctReportByEpicLazyQuery
>;
export type GetCompletionPctReportByEpicQueryResult = Apollo.QueryResult<
  GetCompletionPctReportByEpicQuery,
  GetCompletionPctReportByEpicQueryVariables
>;
export const GetOldestTaskDateDocument = gql`
  query getOldestTaskDate {
    getOldestTaskDate
  }
`;

/**
 * __useGetOldestTaskDateQuery__
 *
 * To run a query within a React component, call `useGetOldestTaskDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOldestTaskDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOldestTaskDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOldestTaskDateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOldestTaskDateQuery,
    GetOldestTaskDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOldestTaskDateQuery,
    GetOldestTaskDateQueryVariables
  >(GetOldestTaskDateDocument, options);
}
export function useGetOldestTaskDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOldestTaskDateQuery,
    GetOldestTaskDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOldestTaskDateQuery,
    GetOldestTaskDateQueryVariables
  >(GetOldestTaskDateDocument, options);
}
export type GetOldestTaskDateQueryHookResult = ReturnType<
  typeof useGetOldestTaskDateQuery
>;
export type GetOldestTaskDateLazyQueryHookResult = ReturnType<
  typeof useGetOldestTaskDateLazyQuery
>;
export type GetOldestTaskDateQueryResult = Apollo.QueryResult<
  GetOldestTaskDateQuery,
  GetOldestTaskDateQueryVariables
>;
export const GetProjectCorrectionsLightDocument = gql`
  query getProjectCorrectionsLight($projectId: String!) {
    getProjectCorrectionsLight(projectId: $projectId) {
      status
      count
    }
  }
`;

/**
 * __useGetProjectCorrectionsLightQuery__
 *
 * To run a query within a React component, call `useGetProjectCorrectionsLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCorrectionsLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCorrectionsLightQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectCorrectionsLightQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectCorrectionsLightQuery,
    GetProjectCorrectionsLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectCorrectionsLightQuery,
    GetProjectCorrectionsLightQueryVariables
  >(GetProjectCorrectionsLightDocument, options);
}
export function useGetProjectCorrectionsLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectCorrectionsLightQuery,
    GetProjectCorrectionsLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectCorrectionsLightQuery,
    GetProjectCorrectionsLightQueryVariables
  >(GetProjectCorrectionsLightDocument, options);
}
export type GetProjectCorrectionsLightQueryHookResult = ReturnType<
  typeof useGetProjectCorrectionsLightQuery
>;
export type GetProjectCorrectionsLightLazyQueryHookResult = ReturnType<
  typeof useGetProjectCorrectionsLightLazyQuery
>;
export type GetProjectCorrectionsLightQueryResult = Apollo.QueryResult<
  GetProjectCorrectionsLightQuery,
  GetProjectCorrectionsLightQueryVariables
>;
export const GetProjectCorrectionsDocument = gql`
  query getProjectCorrections($projectId: String!, $statuses: [String]) {
    getProjectCorrections(projectId: $projectId, statuses: $statuses) {
      id
      text
      comments {
        id
        text
        createdBy {
          id
          firstName
          lastName
          job {
            name
          }
          photo
        }
        createdDate
      }
      createdDate
    }
  }
`;

/**
 * __useGetProjectCorrectionsQuery__
 *
 * To run a query within a React component, call `useGetProjectCorrectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCorrectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCorrectionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useGetProjectCorrectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectCorrectionsQuery,
    GetProjectCorrectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectCorrectionsQuery,
    GetProjectCorrectionsQueryVariables
  >(GetProjectCorrectionsDocument, options);
}
export function useGetProjectCorrectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectCorrectionsQuery,
    GetProjectCorrectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectCorrectionsQuery,
    GetProjectCorrectionsQueryVariables
  >(GetProjectCorrectionsDocument, options);
}
export type GetProjectCorrectionsQueryHookResult = ReturnType<
  typeof useGetProjectCorrectionsQuery
>;
export type GetProjectCorrectionsLazyQueryHookResult = ReturnType<
  typeof useGetProjectCorrectionsLazyQuery
>;
export type GetProjectCorrectionsQueryResult = Apollo.QueryResult<
  GetProjectCorrectionsQuery,
  GetProjectCorrectionsQueryVariables
>;
export const GetCostReportDocument = gql`
  query getCostReport($startDate: Date, $endDate: Date) {
    getCostReport(startDate: $startDate, endDate: $endDate) {
      projectId
      projectName
      totalEstimate
      costEstimate
      cost
      difference
    }
  }
`;

/**
 * __useGetCostReportQuery__
 *
 * To run a query within a React component, call `useGetCostReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetCostReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCostReportQuery,
    GetCostReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCostReportQuery, GetCostReportQueryVariables>(
    GetCostReportDocument,
    options
  );
}
export function useGetCostReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCostReportQuery,
    GetCostReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCostReportQuery, GetCostReportQueryVariables>(
    GetCostReportDocument,
    options
  );
}
export type GetCostReportQueryHookResult = ReturnType<
  typeof useGetCostReportQuery
>;
export type GetCostReportLazyQueryHookResult = ReturnType<
  typeof useGetCostReportLazyQuery
>;
export type GetCostReportQueryResult = Apollo.QueryResult<
  GetCostReportQuery,
  GetCostReportQueryVariables
>;
export const GetCostReportByEpicDocument = gql`
  query getCostReportByEpic(
    $epicKey: String!
    $startDate: Date
    $endDate: Date
  ) {
    getCostReportByEpic(
      epicKey: $epicKey
      startDate: $startDate
      endDate: $endDate
    ) {
      totalCostEstimate
      totalCost
      totalDifference
      tasks {
        id
        name
        assigneeName
        status
        costEstimate
        cost
        difference
      }
    }
  }
`;

/**
 * __useGetCostReportByEpicQuery__
 *
 * To run a query within a React component, call `useGetCostReportByEpicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostReportByEpicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostReportByEpicQuery({
 *   variables: {
 *      epicKey: // value for 'epicKey'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetCostReportByEpicQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCostReportByEpicQuery,
    GetCostReportByEpicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCostReportByEpicQuery,
    GetCostReportByEpicQueryVariables
  >(GetCostReportByEpicDocument, options);
}
export function useGetCostReportByEpicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCostReportByEpicQuery,
    GetCostReportByEpicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCostReportByEpicQuery,
    GetCostReportByEpicQueryVariables
  >(GetCostReportByEpicDocument, options);
}
export type GetCostReportByEpicQueryHookResult = ReturnType<
  typeof useGetCostReportByEpicQuery
>;
export type GetCostReportByEpicLazyQueryHookResult = ReturnType<
  typeof useGetCostReportByEpicLazyQuery
>;
export type GetCostReportByEpicQueryResult = Apollo.QueryResult<
  GetCostReportByEpicQuery,
  GetCostReportByEpicQueryVariables
>;
export const GetDashboardMasteringMoneyReportDocument = gql`
  query getDashboardMasteringMoneyReport(
    $projectId: String
    $startDate: Date
    $endDate: Date
  ) {
    getDashboardMasteringMoneyReport(
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
    ) {
      projectId
      costFact
      costEstimate
      pct
    }
  }
`;

/**
 * __useGetDashboardMasteringMoneyReportQuery__
 *
 * To run a query within a React component, call `useGetDashboardMasteringMoneyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardMasteringMoneyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardMasteringMoneyReportQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDashboardMasteringMoneyReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardMasteringMoneyReportQuery,
    GetDashboardMasteringMoneyReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardMasteringMoneyReportQuery,
    GetDashboardMasteringMoneyReportQueryVariables
  >(GetDashboardMasteringMoneyReportDocument, options);
}
export function useGetDashboardMasteringMoneyReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardMasteringMoneyReportQuery,
    GetDashboardMasteringMoneyReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardMasteringMoneyReportQuery,
    GetDashboardMasteringMoneyReportQueryVariables
  >(GetDashboardMasteringMoneyReportDocument, options);
}
export type GetDashboardMasteringMoneyReportQueryHookResult = ReturnType<
  typeof useGetDashboardMasteringMoneyReportQuery
>;
export type GetDashboardMasteringMoneyReportLazyQueryHookResult = ReturnType<
  typeof useGetDashboardMasteringMoneyReportLazyQuery
>;
export type GetDashboardMasteringMoneyReportQueryResult = Apollo.QueryResult<
  GetDashboardMasteringMoneyReportQuery,
  GetDashboardMasteringMoneyReportQueryVariables
>;
export const GetDashboardInProgressTasksReportDocument = gql`
  query getDashboardInProgressTasksReport(
    $projectId: String
    $startDate: Date
    $endDate: Date
  ) {
    getDashboardInProgressTasksReport(
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
    ) {
      projectId
      projectName
      tasksCount
    }
  }
`;

/**
 * __useGetDashboardInProgressTasksReportQuery__
 *
 * To run a query within a React component, call `useGetDashboardInProgressTasksReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardInProgressTasksReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardInProgressTasksReportQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDashboardInProgressTasksReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardInProgressTasksReportQuery,
    GetDashboardInProgressTasksReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardInProgressTasksReportQuery,
    GetDashboardInProgressTasksReportQueryVariables
  >(GetDashboardInProgressTasksReportDocument, options);
}
export function useGetDashboardInProgressTasksReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardInProgressTasksReportQuery,
    GetDashboardInProgressTasksReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardInProgressTasksReportQuery,
    GetDashboardInProgressTasksReportQueryVariables
  >(GetDashboardInProgressTasksReportDocument, options);
}
export type GetDashboardInProgressTasksReportQueryHookResult = ReturnType<
  typeof useGetDashboardInProgressTasksReportQuery
>;
export type GetDashboardInProgressTasksReportLazyQueryHookResult = ReturnType<
  typeof useGetDashboardInProgressTasksReportLazyQuery
>;
export type GetDashboardInProgressTasksReportQueryResult = Apollo.QueryResult<
  GetDashboardInProgressTasksReportQuery,
  GetDashboardInProgressTasksReportQueryVariables
>;
export const GetDashboardInProgressTasksDocument = gql`
  query getDashboardInProgressTasks($projectId: String, $taskStatusId: String) {
    getProjectTasks(projectId: $projectId, taskStatusId: $taskStatusId) {
      id
      name
    }
  }
`;

/**
 * __useGetDashboardInProgressTasksQuery__
 *
 * To run a query within a React component, call `useGetDashboardInProgressTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardInProgressTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardInProgressTasksQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      taskStatusId: // value for 'taskStatusId'
 *   },
 * });
 */
export function useGetDashboardInProgressTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardInProgressTasksQuery,
    GetDashboardInProgressTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardInProgressTasksQuery,
    GetDashboardInProgressTasksQueryVariables
  >(GetDashboardInProgressTasksDocument, options);
}
export function useGetDashboardInProgressTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardInProgressTasksQuery,
    GetDashboardInProgressTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardInProgressTasksQuery,
    GetDashboardInProgressTasksQueryVariables
  >(GetDashboardInProgressTasksDocument, options);
}
export type GetDashboardInProgressTasksQueryHookResult = ReturnType<
  typeof useGetDashboardInProgressTasksQuery
>;
export type GetDashboardInProgressTasksLazyQueryHookResult = ReturnType<
  typeof useGetDashboardInProgressTasksLazyQuery
>;
export type GetDashboardInProgressTasksQueryResult = Apollo.QueryResult<
  GetDashboardInProgressTasksQuery,
  GetDashboardInProgressTasksQueryVariables
>;
export const GetDashboardCompletionPctReportDocument = gql`
  query getDashboardCompletionPctReport($projectId: String, $date: Date) {
    getDashboardCompletionPctReport(projectId: $projectId, date: $date) {
      projectId
      projectName
      pct
    }
  }
`;

/**
 * __useGetDashboardCompletionPctReportQuery__
 *
 * To run a query within a React component, call `useGetDashboardCompletionPctReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardCompletionPctReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardCompletionPctReportQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetDashboardCompletionPctReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardCompletionPctReportQuery,
    GetDashboardCompletionPctReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardCompletionPctReportQuery,
    GetDashboardCompletionPctReportQueryVariables
  >(GetDashboardCompletionPctReportDocument, options);
}
export function useGetDashboardCompletionPctReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardCompletionPctReportQuery,
    GetDashboardCompletionPctReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardCompletionPctReportQuery,
    GetDashboardCompletionPctReportQueryVariables
  >(GetDashboardCompletionPctReportDocument, options);
}
export type GetDashboardCompletionPctReportQueryHookResult = ReturnType<
  typeof useGetDashboardCompletionPctReportQuery
>;
export type GetDashboardCompletionPctReportLazyQueryHookResult = ReturnType<
  typeof useGetDashboardCompletionPctReportLazyQuery
>;
export type GetDashboardCompletionPctReportQueryResult = Apollo.QueryResult<
  GetDashboardCompletionPctReportQuery,
  GetDashboardCompletionPctReportQueryVariables
>;
export const GetDashboardAddedTasksReportDocument = gql`
  query getDashboardAddedTasksReport(
    $projectId: String
    $startDate: Date
    $endDate: Date
  ) {
    getDashboardAddedTasksReport(
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
    ) {
      projectId
      projectName
      tasksCount
    }
  }
`;

/**
 * __useGetDashboardAddedTasksReportQuery__
 *
 * To run a query within a React component, call `useGetDashboardAddedTasksReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardAddedTasksReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardAddedTasksReportQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDashboardAddedTasksReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardAddedTasksReportQuery,
    GetDashboardAddedTasksReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardAddedTasksReportQuery,
    GetDashboardAddedTasksReportQueryVariables
  >(GetDashboardAddedTasksReportDocument, options);
}
export function useGetDashboardAddedTasksReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardAddedTasksReportQuery,
    GetDashboardAddedTasksReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardAddedTasksReportQuery,
    GetDashboardAddedTasksReportQueryVariables
  >(GetDashboardAddedTasksReportDocument, options);
}
export type GetDashboardAddedTasksReportQueryHookResult = ReturnType<
  typeof useGetDashboardAddedTasksReportQuery
>;
export type GetDashboardAddedTasksReportLazyQueryHookResult = ReturnType<
  typeof useGetDashboardAddedTasksReportLazyQuery
>;
export type GetDashboardAddedTasksReportQueryResult = Apollo.QueryResult<
  GetDashboardAddedTasksReportQuery,
  GetDashboardAddedTasksReportQueryVariables
>;
export const GetDashboardAddedTasksDocument = gql`
  query getDashboardAddedTasks(
    $projectId: String!
    $startDate: Date
    $endDate: Date
  ) {
    getDashboardAddedTasks(
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
    ) {
      name
    }
  }
`;

/**
 * __useGetDashboardAddedTasksQuery__
 *
 * To run a query within a React component, call `useGetDashboardAddedTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardAddedTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardAddedTasksQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDashboardAddedTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDashboardAddedTasksQuery,
    GetDashboardAddedTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardAddedTasksQuery,
    GetDashboardAddedTasksQueryVariables
  >(GetDashboardAddedTasksDocument, options);
}
export function useGetDashboardAddedTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardAddedTasksQuery,
    GetDashboardAddedTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardAddedTasksQuery,
    GetDashboardAddedTasksQueryVariables
  >(GetDashboardAddedTasksDocument, options);
}
export type GetDashboardAddedTasksQueryHookResult = ReturnType<
  typeof useGetDashboardAddedTasksQuery
>;
export type GetDashboardAddedTasksLazyQueryHookResult = ReturnType<
  typeof useGetDashboardAddedTasksLazyQuery
>;
export type GetDashboardAddedTasksQueryResult = Apollo.QueryResult<
  GetDashboardAddedTasksQuery,
  GetDashboardAddedTasksQueryVariables
>;
export const GetDashboardUserWorkloadReportDocument = gql`
  query getDashboardUserWorkloadReport(
    $projectId: String
    $startDate: Date
    $endDate: Date
  ) {
    getDashboardUserWorkloadReport(
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
    ) {
      userId
      assigneeName
      pct
    }
  }
`;

/**
 * __useGetDashboardUserWorkloadReportQuery__
 *
 * To run a query within a React component, call `useGetDashboardUserWorkloadReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardUserWorkloadReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardUserWorkloadReportQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDashboardUserWorkloadReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardUserWorkloadReportQuery,
    GetDashboardUserWorkloadReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardUserWorkloadReportQuery,
    GetDashboardUserWorkloadReportQueryVariables
  >(GetDashboardUserWorkloadReportDocument, options);
}
export function useGetDashboardUserWorkloadReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardUserWorkloadReportQuery,
    GetDashboardUserWorkloadReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardUserWorkloadReportQuery,
    GetDashboardUserWorkloadReportQueryVariables
  >(GetDashboardUserWorkloadReportDocument, options);
}
export type GetDashboardUserWorkloadReportQueryHookResult = ReturnType<
  typeof useGetDashboardUserWorkloadReportQuery
>;
export type GetDashboardUserWorkloadReportLazyQueryHookResult = ReturnType<
  typeof useGetDashboardUserWorkloadReportLazyQuery
>;
export type GetDashboardUserWorkloadReportQueryResult = Apollo.QueryResult<
  GetDashboardUserWorkloadReportQuery,
  GetDashboardUserWorkloadReportQueryVariables
>;
export const GetDashboardUserWorkloadProjectsDocument = gql`
  query getDashboardUserWorkloadProjects(
    $assigneeId: String!
    $projectId: String
    $startDate: Date
    $endDate: Date
  ) {
    getDashboardUserWorkloadProjects(
      assigneeId: $assigneeId
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
    ) {
      projectName
      totalSeconds
    }
  }
`;

/**
 * __useGetDashboardUserWorkloadProjectsQuery__
 *
 * To run a query within a React component, call `useGetDashboardUserWorkloadProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardUserWorkloadProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardUserWorkloadProjectsQuery({
 *   variables: {
 *      assigneeId: // value for 'assigneeId'
 *      projectId: // value for 'projectId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDashboardUserWorkloadProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDashboardUserWorkloadProjectsQuery,
    GetDashboardUserWorkloadProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardUserWorkloadProjectsQuery,
    GetDashboardUserWorkloadProjectsQueryVariables
  >(GetDashboardUserWorkloadProjectsDocument, options);
}
export function useGetDashboardUserWorkloadProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardUserWorkloadProjectsQuery,
    GetDashboardUserWorkloadProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardUserWorkloadProjectsQuery,
    GetDashboardUserWorkloadProjectsQueryVariables
  >(GetDashboardUserWorkloadProjectsDocument, options);
}
export type GetDashboardUserWorkloadProjectsQueryHookResult = ReturnType<
  typeof useGetDashboardUserWorkloadProjectsQuery
>;
export type GetDashboardUserWorkloadProjectsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardUserWorkloadProjectsLazyQuery
>;
export type GetDashboardUserWorkloadProjectsQueryResult = Apollo.QueryResult<
  GetDashboardUserWorkloadProjectsQuery,
  GetDashboardUserWorkloadProjectsQueryVariables
>;
export const GetDashboardOverdueTasksReportDocument = gql`
  query getDashboardOverdueTasksReport(
    $projectId: String
    $startDate: Date
    $endDate: Date
  ) {
    getDashboardOverdueTasksReport(
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
    ) {
      projectId
      projectName
      tasksCount
    }
  }
`;

/**
 * __useGetDashboardOverdueTasksReportQuery__
 *
 * To run a query within a React component, call `useGetDashboardOverdueTasksReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardOverdueTasksReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardOverdueTasksReportQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDashboardOverdueTasksReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardOverdueTasksReportQuery,
    GetDashboardOverdueTasksReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardOverdueTasksReportQuery,
    GetDashboardOverdueTasksReportQueryVariables
  >(GetDashboardOverdueTasksReportDocument, options);
}
export function useGetDashboardOverdueTasksReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardOverdueTasksReportQuery,
    GetDashboardOverdueTasksReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardOverdueTasksReportQuery,
    GetDashboardOverdueTasksReportQueryVariables
  >(GetDashboardOverdueTasksReportDocument, options);
}
export type GetDashboardOverdueTasksReportQueryHookResult = ReturnType<
  typeof useGetDashboardOverdueTasksReportQuery
>;
export type GetDashboardOverdueTasksReportLazyQueryHookResult = ReturnType<
  typeof useGetDashboardOverdueTasksReportLazyQuery
>;
export type GetDashboardOverdueTasksReportQueryResult = Apollo.QueryResult<
  GetDashboardOverdueTasksReportQuery,
  GetDashboardOverdueTasksReportQueryVariables
>;
export const GetDashboardOverdueTasksDocument = gql`
  query getDashboardOverdueTasks(
    $projectId: String!
    $startDate: Date
    $endDate: Date
  ) {
    getDashboardOverdueTasks(
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
    ) {
      name
    }
  }
`;

/**
 * __useGetDashboardOverdueTasksQuery__
 *
 * To run a query within a React component, call `useGetDashboardOverdueTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardOverdueTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardOverdueTasksQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDashboardOverdueTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDashboardOverdueTasksQuery,
    GetDashboardOverdueTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardOverdueTasksQuery,
    GetDashboardOverdueTasksQueryVariables
  >(GetDashboardOverdueTasksDocument, options);
}
export function useGetDashboardOverdueTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardOverdueTasksQuery,
    GetDashboardOverdueTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardOverdueTasksQuery,
    GetDashboardOverdueTasksQueryVariables
  >(GetDashboardOverdueTasksDocument, options);
}
export type GetDashboardOverdueTasksQueryHookResult = ReturnType<
  typeof useGetDashboardOverdueTasksQuery
>;
export type GetDashboardOverdueTasksLazyQueryHookResult = ReturnType<
  typeof useGetDashboardOverdueTasksLazyQuery
>;
export type GetDashboardOverdueTasksQueryResult = Apollo.QueryResult<
  GetDashboardOverdueTasksQuery,
  GetDashboardOverdueTasksQueryVariables
>;
export const GetDateReportDocument = gql`
  query getDateReport {
    getDateReport {
      projectId
      projectName
      startDateEstimate
      startDate
      startDifference
      finishDateEstimate
      finishDate
      finishDifference
    }
  }
`;

/**
 * __useGetDateReportQuery__
 *
 * To run a query within a React component, call `useGetDateReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDateReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDateReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDateReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDateReportQuery,
    GetDateReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDateReportQuery, GetDateReportQueryVariables>(
    GetDateReportDocument,
    options
  );
}
export function useGetDateReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDateReportQuery,
    GetDateReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDateReportQuery, GetDateReportQueryVariables>(
    GetDateReportDocument,
    options
  );
}
export type GetDateReportQueryHookResult = ReturnType<
  typeof useGetDateReportQuery
>;
export type GetDateReportLazyQueryHookResult = ReturnType<
  typeof useGetDateReportLazyQuery
>;
export type GetDateReportQueryResult = Apollo.QueryResult<
  GetDateReportQuery,
  GetDateReportQueryVariables
>;
export const GetDateReportByEpicDocument = gql`
  query getDateReportByEpic(
    $epicKey: String!
    $startDate: Date
    $endDate: Date
  ) {
    getDateReportByEpic(
      epicKey: $epicKey
      startDate: $startDate
      endDate: $endDate
    ) {
      tasks {
        id
        name
        assigneeName
        status
        startDateEstimate
        startDateFact
        startDifference
        finishDateEstimate
        finishDateFact
        finishDifference
      }
      totalStartDifference
      totalFinishDifference
    }
  }
`;

/**
 * __useGetDateReportByEpicQuery__
 *
 * To run a query within a React component, call `useGetDateReportByEpicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDateReportByEpicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDateReportByEpicQuery({
 *   variables: {
 *      epicKey: // value for 'epicKey'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDateReportByEpicQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDateReportByEpicQuery,
    GetDateReportByEpicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDateReportByEpicQuery,
    GetDateReportByEpicQueryVariables
  >(GetDateReportByEpicDocument, options);
}
export function useGetDateReportByEpicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDateReportByEpicQuery,
    GetDateReportByEpicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDateReportByEpicQuery,
    GetDateReportByEpicQueryVariables
  >(GetDateReportByEpicDocument, options);
}
export type GetDateReportByEpicQueryHookResult = ReturnType<
  typeof useGetDateReportByEpicQuery
>;
export type GetDateReportByEpicLazyQueryHookResult = ReturnType<
  typeof useGetDateReportByEpicLazyQuery
>;
export type GetDateReportByEpicQueryResult = Apollo.QueryResult<
  GetDateReportByEpicQuery,
  GetDateReportByEpicQueryVariables
>;
export const GetDayReportDocument = gql`
  query getDayReport($payrollId: String!, $date: String!) {
    getDayReport(payrollId: $payrollId, date: $date) {
      taskName
      time
    }
  }
`;

/**
 * __useGetDayReportQuery__
 *
 * To run a query within a React component, call `useGetDayReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDayReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDayReportQuery({
 *   variables: {
 *      payrollId: // value for 'payrollId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetDayReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDayReportQuery,
    GetDayReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDayReportQuery, GetDayReportQueryVariables>(
    GetDayReportDocument,
    options
  );
}
export function useGetDayReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDayReportQuery,
    GetDayReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDayReportQuery, GetDayReportQueryVariables>(
    GetDayReportDocument,
    options
  );
}
export type GetDayReportQueryHookResult = ReturnType<
  typeof useGetDayReportQuery
>;
export type GetDayReportLazyQueryHookResult = ReturnType<
  typeof useGetDayReportLazyQuery
>;
export type GetDayReportQueryResult = Apollo.QueryResult<
  GetDayReportQuery,
  GetDayReportQueryVariables
>;
export const GetUserDepartmentsDocument = gql`
  query getUserDepartments {
    getUserDepartments {
      id
      name
    }
  }
`;

/**
 * __useGetUserDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetUserDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDepartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDepartmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserDepartmentsQuery,
    GetUserDepartmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserDepartmentsQuery,
    GetUserDepartmentsQueryVariables
  >(GetUserDepartmentsDocument, options);
}
export function useGetUserDepartmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserDepartmentsQuery,
    GetUserDepartmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserDepartmentsQuery,
    GetUserDepartmentsQueryVariables
  >(GetUserDepartmentsDocument, options);
}
export type GetUserDepartmentsQueryHookResult = ReturnType<
  typeof useGetUserDepartmentsQuery
>;
export type GetUserDepartmentsLazyQueryHookResult = ReturnType<
  typeof useGetUserDepartmentsLazyQuery
>;
export type GetUserDepartmentsQueryResult = Apollo.QueryResult<
  GetUserDepartmentsQuery,
  GetUserDepartmentsQueryVariables
>;
export const GetEmployeeWorkloadDayReportDocument = gql`
  query getEmployeeWorkloadDayReport($userId: String!, $date: Date!) {
    getEmployeeWorkloadDayReport(userId: $userId, date: $date) {
      projectName
      epicKey
      taskName
      key
      seconds
    }
  }
`;

/**
 * __useGetEmployeeWorkloadDayReportQuery__
 *
 * To run a query within a React component, call `useGetEmployeeWorkloadDayReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeWorkloadDayReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeWorkloadDayReportQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetEmployeeWorkloadDayReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmployeeWorkloadDayReportQuery,
    GetEmployeeWorkloadDayReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployeeWorkloadDayReportQuery,
    GetEmployeeWorkloadDayReportQueryVariables
  >(GetEmployeeWorkloadDayReportDocument, options);
}
export function useGetEmployeeWorkloadDayReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeeWorkloadDayReportQuery,
    GetEmployeeWorkloadDayReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployeeWorkloadDayReportQuery,
    GetEmployeeWorkloadDayReportQueryVariables
  >(GetEmployeeWorkloadDayReportDocument, options);
}
export type GetEmployeeWorkloadDayReportQueryHookResult = ReturnType<
  typeof useGetEmployeeWorkloadDayReportQuery
>;
export type GetEmployeeWorkloadDayReportLazyQueryHookResult = ReturnType<
  typeof useGetEmployeeWorkloadDayReportLazyQuery
>;
export type GetEmployeeWorkloadDayReportQueryResult = Apollo.QueryResult<
  GetEmployeeWorkloadDayReportQuery,
  GetEmployeeWorkloadDayReportQueryVariables
>;
export const GetEmployeeWorkloadReportDocument = gql`
  query getEmployeeWorkloadReport(
    $userId: String!
    $startDate: Date
    $endDate: Date
  ) {
    getEmployeeWorkloadReport(
      userId: $userId
      startDate: $startDate
      endDate: $endDate
    ) {
      projectName
      report {
        date
        seconds
      }
    }
  }
`;

/**
 * __useGetEmployeeWorkloadReportQuery__
 *
 * To run a query within a React component, call `useGetEmployeeWorkloadReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeWorkloadReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeWorkloadReportQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetEmployeeWorkloadReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmployeeWorkloadReportQuery,
    GetEmployeeWorkloadReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployeeWorkloadReportQuery,
    GetEmployeeWorkloadReportQueryVariables
  >(GetEmployeeWorkloadReportDocument, options);
}
export function useGetEmployeeWorkloadReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeeWorkloadReportQuery,
    GetEmployeeWorkloadReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployeeWorkloadReportQuery,
    GetEmployeeWorkloadReportQueryVariables
  >(GetEmployeeWorkloadReportDocument, options);
}
export type GetEmployeeWorkloadReportQueryHookResult = ReturnType<
  typeof useGetEmployeeWorkloadReportQuery
>;
export type GetEmployeeWorkloadReportLazyQueryHookResult = ReturnType<
  typeof useGetEmployeeWorkloadReportLazyQuery
>;
export type GetEmployeeWorkloadReportQueryResult = Apollo.QueryResult<
  GetEmployeeWorkloadReportQuery,
  GetEmployeeWorkloadReportQueryVariables
>;
export const GetEmployeeWorkloadReportByUserDocument = gql`
  query getEmployeeWorkloadReportByUser($jobName: String!) {
    getEmployeeWorkloadReportByUser(jobName: $jobName) {
      userId
      userName
      projectCount
      totalHours
      totalDays
      totalWeeks
      totalMonth
    }
  }
`;

/**
 * __useGetEmployeeWorkloadReportByUserQuery__
 *
 * To run a query within a React component, call `useGetEmployeeWorkloadReportByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeWorkloadReportByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeWorkloadReportByUserQuery({
 *   variables: {
 *      jobName: // value for 'jobName'
 *   },
 * });
 */
export function useGetEmployeeWorkloadReportByUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmployeeWorkloadReportByUserQuery,
    GetEmployeeWorkloadReportByUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployeeWorkloadReportByUserQuery,
    GetEmployeeWorkloadReportByUserQueryVariables
  >(GetEmployeeWorkloadReportByUserDocument, options);
}
export function useGetEmployeeWorkloadReportByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeeWorkloadReportByUserQuery,
    GetEmployeeWorkloadReportByUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployeeWorkloadReportByUserQuery,
    GetEmployeeWorkloadReportByUserQueryVariables
  >(GetEmployeeWorkloadReportByUserDocument, options);
}
export type GetEmployeeWorkloadReportByUserQueryHookResult = ReturnType<
  typeof useGetEmployeeWorkloadReportByUserQuery
>;
export type GetEmployeeWorkloadReportByUserLazyQueryHookResult = ReturnType<
  typeof useGetEmployeeWorkloadReportByUserLazyQuery
>;
export type GetEmployeeWorkloadReportByUserQueryResult = Apollo.QueryResult<
  GetEmployeeWorkloadReportByUserQuery,
  GetEmployeeWorkloadReportByUserQueryVariables
>;
export const GetEmployeeWorkloadReportByJobsDocument = gql`
  query getEmployeeWorkloadReportByJobs {
    getEmployeeWorkloadReportByJobs {
      jobName
      totalHours
      totalDays
      totalWeeks
      totalMonth
    }
  }
`;

/**
 * __useGetEmployeeWorkloadReportByJobsQuery__
 *
 * To run a query within a React component, call `useGetEmployeeWorkloadReportByJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeWorkloadReportByJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeWorkloadReportByJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeeWorkloadReportByJobsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmployeeWorkloadReportByJobsQuery,
    GetEmployeeWorkloadReportByJobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployeeWorkloadReportByJobsQuery,
    GetEmployeeWorkloadReportByJobsQueryVariables
  >(GetEmployeeWorkloadReportByJobsDocument, options);
}
export function useGetEmployeeWorkloadReportByJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeeWorkloadReportByJobsQuery,
    GetEmployeeWorkloadReportByJobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployeeWorkloadReportByJobsQuery,
    GetEmployeeWorkloadReportByJobsQueryVariables
  >(GetEmployeeWorkloadReportByJobsDocument, options);
}
export type GetEmployeeWorkloadReportByJobsQueryHookResult = ReturnType<
  typeof useGetEmployeeWorkloadReportByJobsQuery
>;
export type GetEmployeeWorkloadReportByJobsLazyQueryHookResult = ReturnType<
  typeof useGetEmployeeWorkloadReportByJobsLazyQuery
>;
export type GetEmployeeWorkloadReportByJobsQueryResult = Apollo.QueryResult<
  GetEmployeeWorkloadReportByJobsQuery,
  GetEmployeeWorkloadReportByJobsQueryVariables
>;
export const GetEmployeesDistributionLightDocument = gql`
  query getEmployeesDistributionLight {
    getEmployeesDistributionLight {
      name
      count
    }
  }
`;

/**
 * __useGetEmployeesDistributionLightQuery__
 *
 * To run a query within a React component, call `useGetEmployeesDistributionLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesDistributionLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesDistributionLightQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeesDistributionLightQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmployeesDistributionLightQuery,
    GetEmployeesDistributionLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployeesDistributionLightQuery,
    GetEmployeesDistributionLightQueryVariables
  >(GetEmployeesDistributionLightDocument, options);
}
export function useGetEmployeesDistributionLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeesDistributionLightQuery,
    GetEmployeesDistributionLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployeesDistributionLightQuery,
    GetEmployeesDistributionLightQueryVariables
  >(GetEmployeesDistributionLightDocument, options);
}
export type GetEmployeesDistributionLightQueryHookResult = ReturnType<
  typeof useGetEmployeesDistributionLightQuery
>;
export type GetEmployeesDistributionLightLazyQueryHookResult = ReturnType<
  typeof useGetEmployeesDistributionLightLazyQuery
>;
export type GetEmployeesDistributionLightQueryResult = Apollo.QueryResult<
  GetEmployeesDistributionLightQuery,
  GetEmployeesDistributionLightQueryVariables
>;
export const GetEmployeesDistributionDocument = gql`
  query getEmployeesDistribution($id: String!) {
    getEmployeesDistribution(id: $id) {
      id
      name
      projectName
      employeesNumber
      projectType {
        name
      }
    }
  }
`;

/**
 * __useGetEmployeesDistributionQuery__
 *
 * To run a query within a React component, call `useGetEmployeesDistributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesDistributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesDistributionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeesDistributionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmployeesDistributionQuery,
    GetEmployeesDistributionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployeesDistributionQuery,
    GetEmployeesDistributionQueryVariables
  >(GetEmployeesDistributionDocument, options);
}
export function useGetEmployeesDistributionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeesDistributionQuery,
    GetEmployeesDistributionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployeesDistributionQuery,
    GetEmployeesDistributionQueryVariables
  >(GetEmployeesDistributionDocument, options);
}
export type GetEmployeesDistributionQueryHookResult = ReturnType<
  typeof useGetEmployeesDistributionQuery
>;
export type GetEmployeesDistributionLazyQueryHookResult = ReturnType<
  typeof useGetEmployeesDistributionLazyQuery
>;
export type GetEmployeesDistributionQueryResult = Apollo.QueryResult<
  GetEmployeesDistributionQuery,
  GetEmployeesDistributionQueryVariables
>;
export const GetEmployeesDistributionsByNameDocument = gql`
  query getEmployeesDistributionsByName($name: String!) {
    getEmployeesDistributionsByName(name: $name) {
      distributions {
        id
        projectName
        employeesNumber
        projectType {
          name
        }
      }
      additionalData {
        employeesReport {
          projectName
          employeesPercent
        }
        statusReport {
          status
          projectsTypesPercent
        }
      }
    }
  }
`;

/**
 * __useGetEmployeesDistributionsByNameQuery__
 *
 * To run a query within a React component, call `useGetEmployeesDistributionsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesDistributionsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesDistributionsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetEmployeesDistributionsByNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmployeesDistributionsByNameQuery,
    GetEmployeesDistributionsByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployeesDistributionsByNameQuery,
    GetEmployeesDistributionsByNameQueryVariables
  >(GetEmployeesDistributionsByNameDocument, options);
}
export function useGetEmployeesDistributionsByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeesDistributionsByNameQuery,
    GetEmployeesDistributionsByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployeesDistributionsByNameQuery,
    GetEmployeesDistributionsByNameQueryVariables
  >(GetEmployeesDistributionsByNameDocument, options);
}
export type GetEmployeesDistributionsByNameQueryHookResult = ReturnType<
  typeof useGetEmployeesDistributionsByNameQuery
>;
export type GetEmployeesDistributionsByNameLazyQueryHookResult = ReturnType<
  typeof useGetEmployeesDistributionsByNameLazyQuery
>;
export type GetEmployeesDistributionsByNameQueryResult = Apollo.QueryResult<
  GetEmployeesDistributionsByNameQuery,
  GetEmployeesDistributionsByNameQueryVariables
>;
export const GetEpicsProgressReportDocument = gql`
  query getEpicsProgressReport($projectId: String!) {
    getEpicsProgressReport(projectId: $projectId) {
      id
      name
      startDate
      finishDate
      lateEnd
      lateStart
      progress
      total
    }
  }
`;

/**
 * __useGetEpicsProgressReportQuery__
 *
 * To run a query within a React component, call `useGetEpicsProgressReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEpicsProgressReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEpicsProgressReportQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetEpicsProgressReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEpicsProgressReportQuery,
    GetEpicsProgressReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEpicsProgressReportQuery,
    GetEpicsProgressReportQueryVariables
  >(GetEpicsProgressReportDocument, options);
}
export function useGetEpicsProgressReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEpicsProgressReportQuery,
    GetEpicsProgressReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEpicsProgressReportQuery,
    GetEpicsProgressReportQueryVariables
  >(GetEpicsProgressReportDocument, options);
}
export type GetEpicsProgressReportQueryHookResult = ReturnType<
  typeof useGetEpicsProgressReportQuery
>;
export type GetEpicsProgressReportLazyQueryHookResult = ReturnType<
  typeof useGetEpicsProgressReportLazyQuery
>;
export type GetEpicsProgressReportQueryResult = Apollo.QueryResult<
  GetEpicsProgressReportQuery,
  GetEpicsProgressReportQueryVariables
>;
export const GetEventDatesDocument = gql`
  query getEventDates($eventId: String!) {
    getEventDates(eventId: $eventId) {
      id
      date
      link
      userLimit
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useGetEventDatesQuery__
 *
 * To run a query within a React component, call `useGetEventDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventDatesQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetEventDatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventDatesQuery,
    GetEventDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventDatesQuery, GetEventDatesQueryVariables>(
    GetEventDatesDocument,
    options
  );
}
export function useGetEventDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventDatesQuery,
    GetEventDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventDatesQuery, GetEventDatesQueryVariables>(
    GetEventDatesDocument,
    options
  );
}
export type GetEventDatesQueryHookResult = ReturnType<
  typeof useGetEventDatesQuery
>;
export type GetEventDatesLazyQueryHookResult = ReturnType<
  typeof useGetEventDatesLazyQuery
>;
export type GetEventDatesQueryResult = Apollo.QueryResult<
  GetEventDatesQuery,
  GetEventDatesQueryVariables
>;
export const GetEventDateDocument = gql`
  query getEventDate($id: String!) {
    getEventDate(id: $id) {
      users {
        id
        firstName
        lastName
      }
      werePresent {
        id
        firstName
        lastName
        middleName
        email
        phone
        birthday
        city {
          name
        }
        role {
          role
          displayName
        }
      }
      wereNotPresent {
        id
        firstName
        lastName
        middleName
        email
        phone
        birthday
        city {
          name
        }
        role {
          role
          displayName
        }
      }
    }
  }
`;

/**
 * __useGetEventDateQuery__
 *
 * To run a query within a React component, call `useGetEventDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventDateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventDateQuery,
    GetEventDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventDateQuery, GetEventDateQueryVariables>(
    GetEventDateDocument,
    options
  );
}
export function useGetEventDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventDateQuery,
    GetEventDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventDateQuery, GetEventDateQueryVariables>(
    GetEventDateDocument,
    options
  );
}
export type GetEventDateQueryHookResult = ReturnType<
  typeof useGetEventDateQuery
>;
export type GetEventDateLazyQueryHookResult = ReturnType<
  typeof useGetEventDateLazyQuery
>;
export type GetEventDateQueryResult = Apollo.QueryResult<
  GetEventDateQuery,
  GetEventDateQueryVariables
>;
export const GetEventsDocument = gql`
  query getEvents($limit: Int, $skip: Int) {
    getEvents(limit: $limit, skip: $skip) {
      events {
        id
        name
        photo
        dates {
          id
          date
          link
          users {
            firstName
            lastName
          }
        }
        createdBy {
          id
          firstName
          lastName
          middleName
        }
        createdDate
      }
    }
  }
`;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(
    GetEventsDocument,
    options
  );
}
export function useGetEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsQuery,
    GetEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(
    GetEventsDocument,
    options
  );
}
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<
  typeof useGetEventsLazyQuery
>;
export type GetEventsQueryResult = Apollo.QueryResult<
  GetEventsQuery,
  GetEventsQueryVariables
>;
export const GetEventDocument = gql`
  query getEvent($id: String!) {
    getEvent(id: $id) {
      id
      name
      photo
      speaker
      description
      dates {
        id
        date
        link
        userLimit
        users {
          id
          firstName
          lastName
        }
        werePresent {
          id
          firstName
          lastName
        }
        wereNotPresent {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventQuery, GetEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventQuery, GetEventQueryVariables>(
    GetEventDocument,
    options
  );
}
export function useGetEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventQuery,
    GetEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventQuery, GetEventQueryVariables>(
    GetEventDocument,
    options
  );
}
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<
  typeof useGetEventLazyQuery
>;
export type GetEventQueryResult = Apollo.QueryResult<
  GetEventQuery,
  GetEventQueryVariables
>;
export const GetFeedbacksDocument = gql`
  query getFeedbacks($statuses: [FeedbackStatusEnum]) {
    getFeedbacks(statuses: $statuses) {
      status
      id
      text
      comments {
        id
      }
      createdBy {
        id
        firstName
        lastName
        photo
      }
      createdDate
      updatedDate
    }
  }
`;

/**
 * __useGetFeedbacksQuery__
 *
 * To run a query within a React component, call `useGetFeedbacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbacksQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useGetFeedbacksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeedbacksQuery,
    GetFeedbacksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFeedbacksQuery, GetFeedbacksQueryVariables>(
    GetFeedbacksDocument,
    options
  );
}
export function useGetFeedbacksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeedbacksQuery,
    GetFeedbacksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFeedbacksQuery, GetFeedbacksQueryVariables>(
    GetFeedbacksDocument,
    options
  );
}
export type GetFeedbacksQueryHookResult = ReturnType<
  typeof useGetFeedbacksQuery
>;
export type GetFeedbacksLazyQueryHookResult = ReturnType<
  typeof useGetFeedbacksLazyQuery
>;
export type GetFeedbacksQueryResult = Apollo.QueryResult<
  GetFeedbacksQuery,
  GetFeedbacksQueryVariables
>;
export const GetFeedbacksLightDocument = gql`
  query getFeedbacksLight {
    getFeedbacksLight {
      status
      count
    }
  }
`;

/**
 * __useGetFeedbacksLightQuery__
 *
 * To run a query within a React component, call `useGetFeedbacksLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbacksLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbacksLightQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeedbacksLightQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeedbacksLightQuery,
    GetFeedbacksLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFeedbacksLightQuery,
    GetFeedbacksLightQueryVariables
  >(GetFeedbacksLightDocument, options);
}
export function useGetFeedbacksLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeedbacksLightQuery,
    GetFeedbacksLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFeedbacksLightQuery,
    GetFeedbacksLightQueryVariables
  >(GetFeedbacksLightDocument, options);
}
export type GetFeedbacksLightQueryHookResult = ReturnType<
  typeof useGetFeedbacksLightQuery
>;
export type GetFeedbacksLightLazyQueryHookResult = ReturnType<
  typeof useGetFeedbacksLightLazyQuery
>;
export type GetFeedbacksLightQueryResult = Apollo.QueryResult<
  GetFeedbacksLightQuery,
  GetFeedbacksLightQueryVariables
>;
export const GetCommentsByFeedbackIdDocument = gql`
  query getCommentsByFeedbackId($feedbackId: String!) {
    getCommentsByFeedbackId(feedbackId: $feedbackId) {
      id
      text
      files {
        id
        file
        name
      }
      feedback {
        id
      }
      createdBy {
        id
        firstName
        lastName
        photo
      }
      createdDate
      updatedDate
    }
  }
`;

/**
 * __useGetCommentsByFeedbackIdQuery__
 *
 * To run a query within a React component, call `useGetCommentsByFeedbackIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsByFeedbackIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsByFeedbackIdQuery({
 *   variables: {
 *      feedbackId: // value for 'feedbackId'
 *   },
 * });
 */
export function useGetCommentsByFeedbackIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCommentsByFeedbackIdQuery,
    GetCommentsByFeedbackIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCommentsByFeedbackIdQuery,
    GetCommentsByFeedbackIdQueryVariables
  >(GetCommentsByFeedbackIdDocument, options);
}
export function useGetCommentsByFeedbackIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommentsByFeedbackIdQuery,
    GetCommentsByFeedbackIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCommentsByFeedbackIdQuery,
    GetCommentsByFeedbackIdQueryVariables
  >(GetCommentsByFeedbackIdDocument, options);
}
export type GetCommentsByFeedbackIdQueryHookResult = ReturnType<
  typeof useGetCommentsByFeedbackIdQuery
>;
export type GetCommentsByFeedbackIdLazyQueryHookResult = ReturnType<
  typeof useGetCommentsByFeedbackIdLazyQuery
>;
export type GetCommentsByFeedbackIdQueryResult = Apollo.QueryResult<
  GetCommentsByFeedbackIdQuery,
  GetCommentsByFeedbackIdQueryVariables
>;
export const GetFeedbackCommentsDocument = gql`
  query getFeedbackComments {
    getFeedbackComments {
      id
      text
      files {
        file
        name
      }
      feedback {
        id
      }
      createdBy {
        id
        firstName
        lastName
        photo
      }
    }
  }
`;

/**
 * __useGetFeedbackCommentsQuery__
 *
 * To run a query within a React component, call `useGetFeedbackCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackCommentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeedbackCommentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeedbackCommentsQuery,
    GetFeedbackCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFeedbackCommentsQuery,
    GetFeedbackCommentsQueryVariables
  >(GetFeedbackCommentsDocument, options);
}
export function useGetFeedbackCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeedbackCommentsQuery,
    GetFeedbackCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFeedbackCommentsQuery,
    GetFeedbackCommentsQueryVariables
  >(GetFeedbackCommentsDocument, options);
}
export type GetFeedbackCommentsQueryHookResult = ReturnType<
  typeof useGetFeedbackCommentsQuery
>;
export type GetFeedbackCommentsLazyQueryHookResult = ReturnType<
  typeof useGetFeedbackCommentsLazyQuery
>;
export type GetFeedbackCommentsQueryResult = Apollo.QueryResult<
  GetFeedbackCommentsQuery,
  GetFeedbackCommentsQueryVariables
>;
export const GetRobotsHistoryDocument = gql`
  query GetRobotsHistory {
    getRobotsHistory {
      id
      link
      order
    }
  }
`;

/**
 * __useGetRobotsHistoryQuery__
 *
 * To run a query within a React component, call `useGetRobotsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRobotsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRobotsHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRobotsHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRobotsHistoryQuery,
    GetRobotsHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRobotsHistoryQuery, GetRobotsHistoryQueryVariables>(
    GetRobotsHistoryDocument,
    options
  );
}
export function useGetRobotsHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRobotsHistoryQuery,
    GetRobotsHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRobotsHistoryQuery,
    GetRobotsHistoryQueryVariables
  >(GetRobotsHistoryDocument, options);
}
export type GetRobotsHistoryQueryHookResult = ReturnType<
  typeof useGetRobotsHistoryQuery
>;
export type GetRobotsHistoryLazyQueryHookResult = ReturnType<
  typeof useGetRobotsHistoryLazyQuery
>;
export type GetRobotsHistoryQueryResult = Apollo.QueryResult<
  GetRobotsHistoryQuery,
  GetRobotsHistoryQueryVariables
>;
export const GetAwardDocument = gql`
  query getAward($getAwardId: UUID!) {
    getAward(id: $getAwardId) {
      id
      name
      photo
      day
      background
      animation
      energyQuantity
      isPublished
      type
    }
  }
`;

/**
 * __useGetAwardQuery__
 *
 * To run a query within a React component, call `useGetAwardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwardQuery({
 *   variables: {
 *      getAwardId: // value for 'getAwardId'
 *   },
 * });
 */
export function useGetAwardQuery(
  baseOptions: Apollo.QueryHookOptions<GetAwardQuery, GetAwardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAwardQuery, GetAwardQueryVariables>(
    GetAwardDocument,
    options
  );
}
export function useGetAwardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAwardQuery,
    GetAwardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAwardQuery, GetAwardQueryVariables>(
    GetAwardDocument,
    options
  );
}
export type GetAwardQueryHookResult = ReturnType<typeof useGetAwardQuery>;
export type GetAwardLazyQueryHookResult = ReturnType<
  typeof useGetAwardLazyQuery
>;
export type GetAwardQueryResult = Apollo.QueryResult<
  GetAwardQuery,
  GetAwardQueryVariables
>;
export const GetAwardsDocument = gql`
  query getAwards {
    getAwards {
      id
      name
      photo
      day
      background
      animation
      energyQuantity
      isPublished
      type
    }
  }
`;

/**
 * __useGetAwardsQuery__
 *
 * To run a query within a React component, call `useGetAwardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAwardsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAwardsQuery, GetAwardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAwardsQuery, GetAwardsQueryVariables>(
    GetAwardsDocument,
    options
  );
}
export function useGetAwardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAwardsQuery,
    GetAwardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAwardsQuery, GetAwardsQueryVariables>(
    GetAwardsDocument,
    options
  );
}
export type GetAwardsQueryHookResult = ReturnType<typeof useGetAwardsQuery>;
export type GetAwardsLazyQueryHookResult = ReturnType<
  typeof useGetAwardsLazyQuery
>;
export type GetAwardsQueryResult = Apollo.QueryResult<
  GetAwardsQuery,
  GetAwardsQueryVariables
>;
export const GetGameModelsDocument = gql`
  query getGameModels {
    getGameModels {
      id
      type
      name
      description
      background
      isPublished
      isCanBePublished
      statesCount
      order
    }
  }
`;

/**
 * __useGetGameModelsQuery__
 *
 * To run a query within a React component, call `useGetGameModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGameModelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGameModelsQuery,
    GetGameModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGameModelsQuery, GetGameModelsQueryVariables>(
    GetGameModelsDocument,
    options
  );
}
export function useGetGameModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameModelsQuery,
    GetGameModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGameModelsQuery, GetGameModelsQueryVariables>(
    GetGameModelsDocument,
    options
  );
}
export type GetGameModelsQueryHookResult = ReturnType<
  typeof useGetGameModelsQuery
>;
export type GetGameModelsLazyQueryHookResult = ReturnType<
  typeof useGetGameModelsLazyQuery
>;
export type GetGameModelsQueryResult = Apollo.QueryResult<
  GetGameModelsQuery,
  GetGameModelsQueryVariables
>;
export const GetGameModelDocument = gql`
  query getGameModel($getGameModelId: UUID!) {
    getGameModel(id: $getGameModelId) {
      id
      name
      description
      background
      order
    }
  }
`;

/**
 * __useGetGameModelQuery__
 *
 * To run a query within a React component, call `useGetGameModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameModelQuery({
 *   variables: {
 *      getGameModelId: // value for 'getGameModelId'
 *   },
 * });
 */
export function useGetGameModelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGameModelQuery,
    GetGameModelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGameModelQuery, GetGameModelQueryVariables>(
    GetGameModelDocument,
    options
  );
}
export function useGetGameModelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameModelQuery,
    GetGameModelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGameModelQuery, GetGameModelQueryVariables>(
    GetGameModelDocument,
    options
  );
}
export type GetGameModelQueryHookResult = ReturnType<
  typeof useGetGameModelQuery
>;
export type GetGameModelLazyQueryHookResult = ReturnType<
  typeof useGetGameModelLazyQuery
>;
export type GetGameModelQueryResult = Apollo.QueryResult<
  GetGameModelQuery,
  GetGameModelQueryVariables
>;
export const GetGameModelStatesDocument = gql`
  query getGameModelStates($gameModelId: UUID!) {
    getGameModelStates(gameModelId: $gameModelId) {
      id
      stageId
      image
      name
      description
      isPublished
      isCanBePublished
    }
  }
`;

/**
 * __useGetGameModelStatesQuery__
 *
 * To run a query within a React component, call `useGetGameModelStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameModelStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameModelStatesQuery({
 *   variables: {
 *      gameModelId: // value for 'gameModelId'
 *   },
 * });
 */
export function useGetGameModelStatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGameModelStatesQuery,
    GetGameModelStatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGameModelStatesQuery,
    GetGameModelStatesQueryVariables
  >(GetGameModelStatesDocument, options);
}
export function useGetGameModelStatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameModelStatesQuery,
    GetGameModelStatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGameModelStatesQuery,
    GetGameModelStatesQueryVariables
  >(GetGameModelStatesDocument, options);
}
export type GetGameModelStatesQueryHookResult = ReturnType<
  typeof useGetGameModelStatesQuery
>;
export type GetGameModelStatesLazyQueryHookResult = ReturnType<
  typeof useGetGameModelStatesLazyQuery
>;
export type GetGameModelStatesQueryResult = Apollo.QueryResult<
  GetGameModelStatesQuery,
  GetGameModelStatesQueryVariables
>;
export const GetGameModelStatesForSelectDocument = gql`
  query getGameModelStatesForSelect($gameModelId: UUID!) {
    getGameModelStates(gameModelId: $gameModelId) {
      stageId
      name
    }
  }
`;

/**
 * __useGetGameModelStatesForSelectQuery__
 *
 * To run a query within a React component, call `useGetGameModelStatesForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameModelStatesForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameModelStatesForSelectQuery({
 *   variables: {
 *      gameModelId: // value for 'gameModelId'
 *   },
 * });
 */
export function useGetGameModelStatesForSelectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGameModelStatesForSelectQuery,
    GetGameModelStatesForSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGameModelStatesForSelectQuery,
    GetGameModelStatesForSelectQueryVariables
  >(GetGameModelStatesForSelectDocument, options);
}
export function useGetGameModelStatesForSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameModelStatesForSelectQuery,
    GetGameModelStatesForSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGameModelStatesForSelectQuery,
    GetGameModelStatesForSelectQueryVariables
  >(GetGameModelStatesForSelectDocument, options);
}
export type GetGameModelStatesForSelectQueryHookResult = ReturnType<
  typeof useGetGameModelStatesForSelectQuery
>;
export type GetGameModelStatesForSelectLazyQueryHookResult = ReturnType<
  typeof useGetGameModelStatesForSelectLazyQuery
>;
export type GetGameModelStatesForSelectQueryResult = Apollo.QueryResult<
  GetGameModelStatesForSelectQuery,
  GetGameModelStatesForSelectQueryVariables
>;
export const GetGameModelStateDocument = gql`
  query getGameModelState($getGameModelStateId: UUID!) {
    getGameModelState(id: $getGameModelStateId) {
      id
      stageId
      image
      name
      description
      experiencePrice
      energyPrice
      iosBundle
      iosManifest
      androidBundle
      androidManifest
      gameModelId
    }
  }
`;

/**
 * __useGetGameModelStateQuery__
 *
 * To run a query within a React component, call `useGetGameModelStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameModelStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameModelStateQuery({
 *   variables: {
 *      getGameModelStateId: // value for 'getGameModelStateId'
 *   },
 * });
 */
export function useGetGameModelStateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGameModelStateQuery,
    GetGameModelStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGameModelStateQuery,
    GetGameModelStateQueryVariables
  >(GetGameModelStateDocument, options);
}
export function useGetGameModelStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameModelStateQuery,
    GetGameModelStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGameModelStateQuery,
    GetGameModelStateQueryVariables
  >(GetGameModelStateDocument, options);
}
export type GetGameModelStateQueryHookResult = ReturnType<
  typeof useGetGameModelStateQuery
>;
export type GetGameModelStateLazyQueryHookResult = ReturnType<
  typeof useGetGameModelStateLazyQuery
>;
export type GetGameModelStateQueryResult = Apollo.QueryResult<
  GetGameModelStateQuery,
  GetGameModelStateQueryVariables
>;
export const GetGameModelArtifactsDocument = gql`
  query GetGameModelArtifacts(
    $input: GetGameModelArtifactsInput!
    $take: Int
    $skip: Int
  ) {
    getGameModelArtifacts(input: $input, take: $take, skip: $skip) {
      artifacts {
        id
        name
        image
        type
        isPublished
        isDefault
      }
      total
    }
  }
`;

/**
 * __useGetGameModelArtifactsQuery__
 *
 * To run a query within a React component, call `useGetGameModelArtifactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameModelArtifactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameModelArtifactsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetGameModelArtifactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGameModelArtifactsQuery,
    GetGameModelArtifactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGameModelArtifactsQuery,
    GetGameModelArtifactsQueryVariables
  >(GetGameModelArtifactsDocument, options);
}
export function useGetGameModelArtifactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameModelArtifactsQuery,
    GetGameModelArtifactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGameModelArtifactsQuery,
    GetGameModelArtifactsQueryVariables
  >(GetGameModelArtifactsDocument, options);
}
export type GetGameModelArtifactsQueryHookResult = ReturnType<
  typeof useGetGameModelArtifactsQuery
>;
export type GetGameModelArtifactsLazyQueryHookResult = ReturnType<
  typeof useGetGameModelArtifactsLazyQuery
>;
export type GetGameModelArtifactsQueryResult = Apollo.QueryResult<
  GetGameModelArtifactsQuery,
  GetGameModelArtifactsQueryVariables
>;
export const GetGameModelArtifactClothTypesDocument = gql`
  query getGameModelArtifactClothTypes {
    getGameModelArtifactClothTypes {
      id
      name
      displayName
    }
  }
`;

/**
 * __useGetGameModelArtifactClothTypesQuery__
 *
 * To run a query within a React component, call `useGetGameModelArtifactClothTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameModelArtifactClothTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameModelArtifactClothTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGameModelArtifactClothTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGameModelArtifactClothTypesQuery,
    GetGameModelArtifactClothTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGameModelArtifactClothTypesQuery,
    GetGameModelArtifactClothTypesQueryVariables
  >(GetGameModelArtifactClothTypesDocument, options);
}
export function useGetGameModelArtifactClothTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameModelArtifactClothTypesQuery,
    GetGameModelArtifactClothTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGameModelArtifactClothTypesQuery,
    GetGameModelArtifactClothTypesQueryVariables
  >(GetGameModelArtifactClothTypesDocument, options);
}
export type GetGameModelArtifactClothTypesQueryHookResult = ReturnType<
  typeof useGetGameModelArtifactClothTypesQuery
>;
export type GetGameModelArtifactClothTypesLazyQueryHookResult = ReturnType<
  typeof useGetGameModelArtifactClothTypesLazyQuery
>;
export type GetGameModelArtifactClothTypesQueryResult = Apollo.QueryResult<
  GetGameModelArtifactClothTypesQuery,
  GetGameModelArtifactClothTypesQueryVariables
>;
export const GetGameModelStateStagesDocument = gql`
  query getGameModelStateStages {
    getGameModelStateStages {
      id
      name
    }
  }
`;

/**
 * __useGetGameModelStateStagesQuery__
 *
 * To run a query within a React component, call `useGetGameModelStateStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameModelStateStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameModelStateStagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGameModelStateStagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGameModelStateStagesQuery,
    GetGameModelStateStagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGameModelStateStagesQuery,
    GetGameModelStateStagesQueryVariables
  >(GetGameModelStateStagesDocument, options);
}
export function useGetGameModelStateStagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameModelStateStagesQuery,
    GetGameModelStateStagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGameModelStateStagesQuery,
    GetGameModelStateStagesQueryVariables
  >(GetGameModelStateStagesDocument, options);
}
export type GetGameModelStateStagesQueryHookResult = ReturnType<
  typeof useGetGameModelStateStagesQuery
>;
export type GetGameModelStateStagesLazyQueryHookResult = ReturnType<
  typeof useGetGameModelStateStagesLazyQuery
>;
export type GetGameModelStateStagesQueryResult = Apollo.QueryResult<
  GetGameModelStateStagesQuery,
  GetGameModelStateStagesQueryVariables
>;
export const GetRewardGroupsDocument = gql`
  query getRewardGroups {
    getRewardGroups {
      id
      name
      order
      rewards {
        id
        name
        group {
          id
          name
        }
        groupId
        order
        value
        type
      }
    }
  }
`;

/**
 * __useGetRewardGroupsQuery__
 *
 * To run a query within a React component, call `useGetRewardGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRewardGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRewardGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRewardGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRewardGroupsQuery,
    GetRewardGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRewardGroupsQuery, GetRewardGroupsQueryVariables>(
    GetRewardGroupsDocument,
    options
  );
}
export function useGetRewardGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRewardGroupsQuery,
    GetRewardGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRewardGroupsQuery,
    GetRewardGroupsQueryVariables
  >(GetRewardGroupsDocument, options);
}
export type GetRewardGroupsQueryHookResult = ReturnType<
  typeof useGetRewardGroupsQuery
>;
export type GetRewardGroupsLazyQueryHookResult = ReturnType<
  typeof useGetRewardGroupsLazyQuery
>;
export type GetRewardGroupsQueryResult = Apollo.QueryResult<
  GetRewardGroupsQuery,
  GetRewardGroupsQueryVariables
>;
export const GetGameModelArtifactAnimationTypesDocument = gql`
  query getGameModelArtifactAnimationTypes {
    getGameModelArtifactAnimationTypes {
      id
      name
      displayName
    }
  }
`;

/**
 * __useGetGameModelArtifactAnimationTypesQuery__
 *
 * To run a query within a React component, call `useGetGameModelArtifactAnimationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameModelArtifactAnimationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameModelArtifactAnimationTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGameModelArtifactAnimationTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGameModelArtifactAnimationTypesQuery,
    GetGameModelArtifactAnimationTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGameModelArtifactAnimationTypesQuery,
    GetGameModelArtifactAnimationTypesQueryVariables
  >(GetGameModelArtifactAnimationTypesDocument, options);
}
export function useGetGameModelArtifactAnimationTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameModelArtifactAnimationTypesQuery,
    GetGameModelArtifactAnimationTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGameModelArtifactAnimationTypesQuery,
    GetGameModelArtifactAnimationTypesQueryVariables
  >(GetGameModelArtifactAnimationTypesDocument, options);
}
export type GetGameModelArtifactAnimationTypesQueryHookResult = ReturnType<
  typeof useGetGameModelArtifactAnimationTypesQuery
>;
export type GetGameModelArtifactAnimationTypesLazyQueryHookResult = ReturnType<
  typeof useGetGameModelArtifactAnimationTypesLazyQuery
>;
export type GetGameModelArtifactAnimationTypesQueryResult = Apollo.QueryResult<
  GetGameModelArtifactAnimationTypesQuery,
  GetGameModelArtifactAnimationTypesQueryVariables
>;
export const GetGameModelArtifactDocument = gql`
  query getGameModelArtifact($getGameModelArtifactId: UUID!) {
    getGameModelArtifact(id: $getGameModelArtifactId) {
      id
      name
      energyPrice
      type
      clothTypeId
      animationTypeId
      rank
      image
      iosBundle
      iosManifest
      androidBundle
      androidManifest
      animation
      gameModelStateId
      experiencePrice
      isPublished
      isCanBePublished
      isDefault
    }
  }
`;

/**
 * __useGetGameModelArtifactQuery__
 *
 * To run a query within a React component, call `useGetGameModelArtifactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameModelArtifactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameModelArtifactQuery({
 *   variables: {
 *      getGameModelArtifactId: // value for 'getGameModelArtifactId'
 *   },
 * });
 */
export function useGetGameModelArtifactQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGameModelArtifactQuery,
    GetGameModelArtifactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGameModelArtifactQuery,
    GetGameModelArtifactQueryVariables
  >(GetGameModelArtifactDocument, options);
}
export function useGetGameModelArtifactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameModelArtifactQuery,
    GetGameModelArtifactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGameModelArtifactQuery,
    GetGameModelArtifactQueryVariables
  >(GetGameModelArtifactDocument, options);
}
export type GetGameModelArtifactQueryHookResult = ReturnType<
  typeof useGetGameModelArtifactQuery
>;
export type GetGameModelArtifactLazyQueryHookResult = ReturnType<
  typeof useGetGameModelArtifactLazyQuery
>;
export type GetGameModelArtifactQueryResult = Apollo.QueryResult<
  GetGameModelArtifactQuery,
  GetGameModelArtifactQueryVariables
>;
export const GetGiveawayTeachersDocument = gql`
  query getGiveawayTeachers($limit: Int, $skip: Int) {
    getGiveawayTeachers(limit: $limit, skip: $skip) {
      users {
        id
        firstName
        lastName
        phone
        email
        schoolCity {
          name
        }
        school {
          shortName
        }
      }
      total
    }
  }
`;

/**
 * __useGetGiveawayTeachersQuery__
 *
 * To run a query within a React component, call `useGetGiveawayTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiveawayTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiveawayTeachersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetGiveawayTeachersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGiveawayTeachersQuery,
    GetGiveawayTeachersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGiveawayTeachersQuery,
    GetGiveawayTeachersQueryVariables
  >(GetGiveawayTeachersDocument, options);
}
export function useGetGiveawayTeachersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGiveawayTeachersQuery,
    GetGiveawayTeachersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGiveawayTeachersQuery,
    GetGiveawayTeachersQueryVariables
  >(GetGiveawayTeachersDocument, options);
}
export type GetGiveawayTeachersQueryHookResult = ReturnType<
  typeof useGetGiveawayTeachersQuery
>;
export type GetGiveawayTeachersLazyQueryHookResult = ReturnType<
  typeof useGetGiveawayTeachersLazyQuery
>;
export type GetGiveawayTeachersQueryResult = Apollo.QueryResult<
  GetGiveawayTeachersQuery,
  GetGiveawayTeachersQueryVariables
>;
export const GetGiveawayTeachersTwoConditionsDocument = gql`
  query getGiveawayTeachersTwoConditions($limit: Int, $skip: Int) {
    getGiveawayTeachersTwoConditions(limit: $limit, skip: $skip) {
      users {
        id
        firstName
        lastName
        phone
        email
        schoolCity {
          name
        }
        school {
          shortName
        }
      }
      total
    }
  }
`;

/**
 * __useGetGiveawayTeachersTwoConditionsQuery__
 *
 * To run a query within a React component, call `useGetGiveawayTeachersTwoConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiveawayTeachersTwoConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiveawayTeachersTwoConditionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetGiveawayTeachersTwoConditionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGiveawayTeachersTwoConditionsQuery,
    GetGiveawayTeachersTwoConditionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGiveawayTeachersTwoConditionsQuery,
    GetGiveawayTeachersTwoConditionsQueryVariables
  >(GetGiveawayTeachersTwoConditionsDocument, options);
}
export function useGetGiveawayTeachersTwoConditionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGiveawayTeachersTwoConditionsQuery,
    GetGiveawayTeachersTwoConditionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGiveawayTeachersTwoConditionsQuery,
    GetGiveawayTeachersTwoConditionsQueryVariables
  >(GetGiveawayTeachersTwoConditionsDocument, options);
}
export type GetGiveawayTeachersTwoConditionsQueryHookResult = ReturnType<
  typeof useGetGiveawayTeachersTwoConditionsQuery
>;
export type GetGiveawayTeachersTwoConditionsLazyQueryHookResult = ReturnType<
  typeof useGetGiveawayTeachersTwoConditionsLazyQuery
>;
export type GetGiveawayTeachersTwoConditionsQueryResult = Apollo.QueryResult<
  GetGiveawayTeachersTwoConditionsQuery,
  GetGiveawayTeachersTwoConditionsQueryVariables
>;
export const GetUserJobsDocument = gql`
  query getUserJobs {
    getUserJobs {
      id
      name
    }
  }
`;

/**
 * __useGetUserJobsQuery__
 *
 * To run a query within a React component, call `useGetUserJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserJobsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserJobsQuery,
    GetUserJobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserJobsQuery, GetUserJobsQueryVariables>(
    GetUserJobsDocument,
    options
  );
}
export function useGetUserJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserJobsQuery,
    GetUserJobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserJobsQuery, GetUserJobsQueryVariables>(
    GetUserJobsDocument,
    options
  );
}
export type GetUserJobsQueryHookResult = ReturnType<typeof useGetUserJobsQuery>;
export type GetUserJobsLazyQueryHookResult = ReturnType<
  typeof useGetUserJobsLazyQuery
>;
export type GetUserJobsQueryResult = Apollo.QueryResult<
  GetUserJobsQuery,
  GetUserJobsQueryVariables
>;
export const GetProjectKanbanDocument = gql`
  query getProjectKanban($projectId: String!) {
    getProjectKanban(projectId: $projectId) {
      id
      name
      isCompleted
    }
  }
`;

/**
 * __useGetProjectKanbanQuery__
 *
 * To run a query within a React component, call `useGetProjectKanbanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectKanbanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectKanbanQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectKanbanQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectKanbanQuery,
    GetProjectKanbanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectKanbanQuery, GetProjectKanbanQueryVariables>(
    GetProjectKanbanDocument,
    options
  );
}
export function useGetProjectKanbanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectKanbanQuery,
    GetProjectKanbanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectKanbanQuery,
    GetProjectKanbanQueryVariables
  >(GetProjectKanbanDocument, options);
}
export type GetProjectKanbanQueryHookResult = ReturnType<
  typeof useGetProjectKanbanQuery
>;
export type GetProjectKanbanLazyQueryHookResult = ReturnType<
  typeof useGetProjectKanbanLazyQuery
>;
export type GetProjectKanbanQueryResult = Apollo.QueryResult<
  GetProjectKanbanQuery,
  GetProjectKanbanQueryVariables
>;
export const GetKnowledgeBaseBlocksByThemeIdDocument = gql`
  query getKnowledgeBaseBlocksByThemeId($themeId: String!) {
    getKnowledgeBaseBlocksByThemeId(themeId: $themeId) {
      id
      type
      content
      photo
      video
      lists {
        id
        content
        photo
        photoTitle
        video
        blockId
        isNumbered
      }
      testQuestion
      testAnswers {
        id
        content
        blockId
        isAnswerTrue
      }
    }
  }
`;

/**
 * __useGetKnowledgeBaseBlocksByThemeIdQuery__
 *
 * To run a query within a React component, call `useGetKnowledgeBaseBlocksByThemeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgeBaseBlocksByThemeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgeBaseBlocksByThemeIdQuery({
 *   variables: {
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useGetKnowledgeBaseBlocksByThemeIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetKnowledgeBaseBlocksByThemeIdQuery,
    GetKnowledgeBaseBlocksByThemeIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetKnowledgeBaseBlocksByThemeIdQuery,
    GetKnowledgeBaseBlocksByThemeIdQueryVariables
  >(GetKnowledgeBaseBlocksByThemeIdDocument, options);
}
export function useGetKnowledgeBaseBlocksByThemeIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKnowledgeBaseBlocksByThemeIdQuery,
    GetKnowledgeBaseBlocksByThemeIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetKnowledgeBaseBlocksByThemeIdQuery,
    GetKnowledgeBaseBlocksByThemeIdQueryVariables
  >(GetKnowledgeBaseBlocksByThemeIdDocument, options);
}
export type GetKnowledgeBaseBlocksByThemeIdQueryHookResult = ReturnType<
  typeof useGetKnowledgeBaseBlocksByThemeIdQuery
>;
export type GetKnowledgeBaseBlocksByThemeIdLazyQueryHookResult = ReturnType<
  typeof useGetKnowledgeBaseBlocksByThemeIdLazyQuery
>;
export type GetKnowledgeBaseBlocksByThemeIdQueryResult = Apollo.QueryResult<
  GetKnowledgeBaseBlocksByThemeIdQuery,
  GetKnowledgeBaseBlocksByThemeIdQueryVariables
>;
export const GetKnowledgeBaseBlocksTestByThemeIdDocument = gql`
  query getKnowledgeBaseBlocksTestByThemeId($themeId: String!) {
    getKnowledgeBaseBlocksTestByThemeId(themeId: $themeId) {
      id
      type
      testQuestion
      testAnswers {
        id
        content
        blockId
        isAnswerTrue
      }
    }
  }
`;

/**
 * __useGetKnowledgeBaseBlocksTestByThemeIdQuery__
 *
 * To run a query within a React component, call `useGetKnowledgeBaseBlocksTestByThemeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgeBaseBlocksTestByThemeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgeBaseBlocksTestByThemeIdQuery({
 *   variables: {
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useGetKnowledgeBaseBlocksTestByThemeIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetKnowledgeBaseBlocksTestByThemeIdQuery,
    GetKnowledgeBaseBlocksTestByThemeIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetKnowledgeBaseBlocksTestByThemeIdQuery,
    GetKnowledgeBaseBlocksTestByThemeIdQueryVariables
  >(GetKnowledgeBaseBlocksTestByThemeIdDocument, options);
}
export function useGetKnowledgeBaseBlocksTestByThemeIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKnowledgeBaseBlocksTestByThemeIdQuery,
    GetKnowledgeBaseBlocksTestByThemeIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetKnowledgeBaseBlocksTestByThemeIdQuery,
    GetKnowledgeBaseBlocksTestByThemeIdQueryVariables
  >(GetKnowledgeBaseBlocksTestByThemeIdDocument, options);
}
export type GetKnowledgeBaseBlocksTestByThemeIdQueryHookResult = ReturnType<
  typeof useGetKnowledgeBaseBlocksTestByThemeIdQuery
>;
export type GetKnowledgeBaseBlocksTestByThemeIdLazyQueryHookResult = ReturnType<
  typeof useGetKnowledgeBaseBlocksTestByThemeIdLazyQuery
>;
export type GetKnowledgeBaseBlocksTestByThemeIdQueryResult = Apollo.QueryResult<
  GetKnowledgeBaseBlocksTestByThemeIdQuery,
  GetKnowledgeBaseBlocksTestByThemeIdQueryVariables
>;
export const GetKnowledgeBaseBlocksTestHistoryDocument = gql`
  query getKnowledgeBaseBlocksTestHistory($themeId: String!) {
    getKnowledgeBaseBlocksTestHistory(themeId: $themeId) {
      user {
        id
        firstName
        lastName
        photo
        job {
          name
        }
      }
      answers {
        id
        isAnswerTrue
      }
    }
  }
`;

/**
 * __useGetKnowledgeBaseBlocksTestHistoryQuery__
 *
 * To run a query within a React component, call `useGetKnowledgeBaseBlocksTestHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgeBaseBlocksTestHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgeBaseBlocksTestHistoryQuery({
 *   variables: {
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useGetKnowledgeBaseBlocksTestHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetKnowledgeBaseBlocksTestHistoryQuery,
    GetKnowledgeBaseBlocksTestHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetKnowledgeBaseBlocksTestHistoryQuery,
    GetKnowledgeBaseBlocksTestHistoryQueryVariables
  >(GetKnowledgeBaseBlocksTestHistoryDocument, options);
}
export function useGetKnowledgeBaseBlocksTestHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKnowledgeBaseBlocksTestHistoryQuery,
    GetKnowledgeBaseBlocksTestHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetKnowledgeBaseBlocksTestHistoryQuery,
    GetKnowledgeBaseBlocksTestHistoryQueryVariables
  >(GetKnowledgeBaseBlocksTestHistoryDocument, options);
}
export type GetKnowledgeBaseBlocksTestHistoryQueryHookResult = ReturnType<
  typeof useGetKnowledgeBaseBlocksTestHistoryQuery
>;
export type GetKnowledgeBaseBlocksTestHistoryLazyQueryHookResult = ReturnType<
  typeof useGetKnowledgeBaseBlocksTestHistoryLazyQuery
>;
export type GetKnowledgeBaseBlocksTestHistoryQueryResult = Apollo.QueryResult<
  GetKnowledgeBaseBlocksTestHistoryQuery,
  GetKnowledgeBaseBlocksTestHistoryQueryVariables
>;
export const GetBaseKnowledgeBaseCategoriesDocument = gql`
  query getBaseKnowledgeBaseCategories {
    getBaseKnowledgeBaseCategories {
      categories {
        id
        name
        isFor
        photo
        backgroundColor
        availableFor
        participants {
          id
          user {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetBaseKnowledgeBaseCategoriesQuery__
 *
 * To run a query within a React component, call `useGetBaseKnowledgeBaseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseKnowledgeBaseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseKnowledgeBaseCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseKnowledgeBaseCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBaseKnowledgeBaseCategoriesQuery,
    GetBaseKnowledgeBaseCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBaseKnowledgeBaseCategoriesQuery,
    GetBaseKnowledgeBaseCategoriesQueryVariables
  >(GetBaseKnowledgeBaseCategoriesDocument, options);
}
export function useGetBaseKnowledgeBaseCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaseKnowledgeBaseCategoriesQuery,
    GetBaseKnowledgeBaseCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBaseKnowledgeBaseCategoriesQuery,
    GetBaseKnowledgeBaseCategoriesQueryVariables
  >(GetBaseKnowledgeBaseCategoriesDocument, options);
}
export type GetBaseKnowledgeBaseCategoriesQueryHookResult = ReturnType<
  typeof useGetBaseKnowledgeBaseCategoriesQuery
>;
export type GetBaseKnowledgeBaseCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetBaseKnowledgeBaseCategoriesLazyQuery
>;
export type GetBaseKnowledgeBaseCategoriesQueryResult = Apollo.QueryResult<
  GetBaseKnowledgeBaseCategoriesQuery,
  GetBaseKnowledgeBaseCategoriesQueryVariables
>;
export const GetKnowledgeBaseThemeDocument = gql`
  query getKnowledgeBaseTheme($id: String!) {
    getKnowledgeBaseTheme(id: $id) {
      id
      name
      categoryId
    }
  }
`;

/**
 * __useGetKnowledgeBaseThemeQuery__
 *
 * To run a query within a React component, call `useGetKnowledgeBaseThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgeBaseThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgeBaseThemeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetKnowledgeBaseThemeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetKnowledgeBaseThemeQuery,
    GetKnowledgeBaseThemeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetKnowledgeBaseThemeQuery,
    GetKnowledgeBaseThemeQueryVariables
  >(GetKnowledgeBaseThemeDocument, options);
}
export function useGetKnowledgeBaseThemeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKnowledgeBaseThemeQuery,
    GetKnowledgeBaseThemeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetKnowledgeBaseThemeQuery,
    GetKnowledgeBaseThemeQueryVariables
  >(GetKnowledgeBaseThemeDocument, options);
}
export type GetKnowledgeBaseThemeQueryHookResult = ReturnType<
  typeof useGetKnowledgeBaseThemeQuery
>;
export type GetKnowledgeBaseThemeLazyQueryHookResult = ReturnType<
  typeof useGetKnowledgeBaseThemeLazyQuery
>;
export type GetKnowledgeBaseThemeQueryResult = Apollo.QueryResult<
  GetKnowledgeBaseThemeQuery,
  GetKnowledgeBaseThemeQueryVariables
>;
export const GetKnowledgeBaseThemesByCategoryIdDocument = gql`
  query getKnowledgeBaseThemesByCategoryId(
    $categoryId: String!
    $isPublished: Boolean
  ) {
    getKnowledgeBaseThemesByCategoryId(
      categoryId: $categoryId
      isPublished: $isPublished
    ) {
      id
      name
      categoryId
    }
  }
`;

/**
 * __useGetKnowledgeBaseThemesByCategoryIdQuery__
 *
 * To run a query within a React component, call `useGetKnowledgeBaseThemesByCategoryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgeBaseThemesByCategoryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgeBaseThemesByCategoryIdQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetKnowledgeBaseThemesByCategoryIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetKnowledgeBaseThemesByCategoryIdQuery,
    GetKnowledgeBaseThemesByCategoryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetKnowledgeBaseThemesByCategoryIdQuery,
    GetKnowledgeBaseThemesByCategoryIdQueryVariables
  >(GetKnowledgeBaseThemesByCategoryIdDocument, options);
}
export function useGetKnowledgeBaseThemesByCategoryIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKnowledgeBaseThemesByCategoryIdQuery,
    GetKnowledgeBaseThemesByCategoryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetKnowledgeBaseThemesByCategoryIdQuery,
    GetKnowledgeBaseThemesByCategoryIdQueryVariables
  >(GetKnowledgeBaseThemesByCategoryIdDocument, options);
}
export type GetKnowledgeBaseThemesByCategoryIdQueryHookResult = ReturnType<
  typeof useGetKnowledgeBaseThemesByCategoryIdQuery
>;
export type GetKnowledgeBaseThemesByCategoryIdLazyQueryHookResult = ReturnType<
  typeof useGetKnowledgeBaseThemesByCategoryIdLazyQuery
>;
export type GetKnowledgeBaseThemesByCategoryIdQueryResult = Apollo.QueryResult<
  GetKnowledgeBaseThemesByCategoryIdQuery,
  GetKnowledgeBaseThemesByCategoryIdQueryVariables
>;
export const GetLessonsByMasterIdDocument = gql`
  query getLessonsByMasterId($getLessonsParams: GetLessonsParams!) {
    getLessonsByMasterId(getLessonsParams: $getLessonsParams) {
      lessons {
        id
        name
        isPublished
      }
      total
    }
  }
`;

/**
 * __useGetLessonsByMasterIdQuery__
 *
 * To run a query within a React component, call `useGetLessonsByMasterIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonsByMasterIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonsByMasterIdQuery({
 *   variables: {
 *      getLessonsParams: // value for 'getLessonsParams'
 *   },
 * });
 */
export function useGetLessonsByMasterIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLessonsByMasterIdQuery,
    GetLessonsByMasterIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLessonsByMasterIdQuery,
    GetLessonsByMasterIdQueryVariables
  >(GetLessonsByMasterIdDocument, options);
}
export function useGetLessonsByMasterIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLessonsByMasterIdQuery,
    GetLessonsByMasterIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLessonsByMasterIdQuery,
    GetLessonsByMasterIdQueryVariables
  >(GetLessonsByMasterIdDocument, options);
}
export type GetLessonsByMasterIdQueryHookResult = ReturnType<
  typeof useGetLessonsByMasterIdQuery
>;
export type GetLessonsByMasterIdLazyQueryHookResult = ReturnType<
  typeof useGetLessonsByMasterIdLazyQuery
>;
export type GetLessonsByMasterIdQueryResult = Apollo.QueryResult<
  GetLessonsByMasterIdQuery,
  GetLessonsByMasterIdQueryVariables
>;
export const GetLessonDocument = gql`
  query getLesson($lessonId: String!) {
    getLesson(lessonId: $lessonId) {
      name
      pages {
        blocks {
          id
          type
          content
          photo
          video
          videoPreview
          lists {
            id
            content
            isNumbered
            blockId
          }
        }
      }
    }
  }
`;

/**
 * __useGetLessonQuery__
 *
 * To run a query within a React component, call `useGetLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useGetLessonQuery(
  baseOptions: Apollo.QueryHookOptions<GetLessonQuery, GetLessonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLessonQuery, GetLessonQueryVariables>(
    GetLessonDocument,
    options
  );
}
export function useGetLessonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLessonQuery,
    GetLessonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLessonQuery, GetLessonQueryVariables>(
    GetLessonDocument,
    options
  );
}
export type GetLessonQueryHookResult = ReturnType<typeof useGetLessonQuery>;
export type GetLessonLazyQueryHookResult = ReturnType<
  typeof useGetLessonLazyQuery
>;
export type GetLessonQueryResult = Apollo.QueryResult<
  GetLessonQuery,
  GetLessonQueryVariables
>;
export const GetCreatedLessonsBySchoolTeachersDocument = gql`
  query getCreatedLessonsBySchoolTeachers(
    $schoolId: UUID!
    $from: Date!
    $to: Date!
  ) {
    getCreatedLessonsBySchoolTeachers(
      schoolId: $schoolId
      from: $from
      to: $to
    ) {
      dataChart {
        tag
        value
      }
      dataTotal
    }
  }
`;

/**
 * __useGetCreatedLessonsBySchoolTeachersQuery__
 *
 * To run a query within a React component, call `useGetCreatedLessonsBySchoolTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatedLessonsBySchoolTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatedLessonsBySchoolTeachersQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetCreatedLessonsBySchoolTeachersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCreatedLessonsBySchoolTeachersQuery,
    GetCreatedLessonsBySchoolTeachersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCreatedLessonsBySchoolTeachersQuery,
    GetCreatedLessonsBySchoolTeachersQueryVariables
  >(GetCreatedLessonsBySchoolTeachersDocument, options);
}
export function useGetCreatedLessonsBySchoolTeachersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreatedLessonsBySchoolTeachersQuery,
    GetCreatedLessonsBySchoolTeachersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCreatedLessonsBySchoolTeachersQuery,
    GetCreatedLessonsBySchoolTeachersQueryVariables
  >(GetCreatedLessonsBySchoolTeachersDocument, options);
}
export type GetCreatedLessonsBySchoolTeachersQueryHookResult = ReturnType<
  typeof useGetCreatedLessonsBySchoolTeachersQuery
>;
export type GetCreatedLessonsBySchoolTeachersLazyQueryHookResult = ReturnType<
  typeof useGetCreatedLessonsBySchoolTeachersLazyQuery
>;
export type GetCreatedLessonsBySchoolTeachersQueryResult = Apollo.QueryResult<
  GetCreatedLessonsBySchoolTeachersQuery,
  GetCreatedLessonsBySchoolTeachersQueryVariables
>;
export const GetMarketingModalsDocument = gql`
  query getMarketingModals {
    getMarketingModals {
      id
      type
      title
      appType {
        name
      }
    }
  }
`;

/**
 * __useGetMarketingModalsQuery__
 *
 * To run a query within a React component, call `useGetMarketingModalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketingModalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketingModalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarketingModalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMarketingModalsQuery,
    GetMarketingModalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMarketingModalsQuery,
    GetMarketingModalsQueryVariables
  >(GetMarketingModalsDocument, options);
}
export function useGetMarketingModalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMarketingModalsQuery,
    GetMarketingModalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMarketingModalsQuery,
    GetMarketingModalsQueryVariables
  >(GetMarketingModalsDocument, options);
}
export type GetMarketingModalsQueryHookResult = ReturnType<
  typeof useGetMarketingModalsQuery
>;
export type GetMarketingModalsLazyQueryHookResult = ReturnType<
  typeof useGetMarketingModalsLazyQuery
>;
export type GetMarketingModalsQueryResult = Apollo.QueryResult<
  GetMarketingModalsQuery,
  GetMarketingModalsQueryVariables
>;
export const GetMarketingModalInfoDocument = gql`
  query getMarketingModalInfo(
    $appType: AppTypeNameEnum
    $type: MarketingModalInfoType
  ) {
    getMarketingModalInfo(appType: $appType, type: $type) {
      id
      type
      appType {
        name
      }
      title
      subTitle
      features {
        id
        description
      }
      note
      fileLink
    }
  }
`;

/**
 * __useGetMarketingModalInfoQuery__
 *
 * To run a query within a React component, call `useGetMarketingModalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketingModalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketingModalInfoQuery({
 *   variables: {
 *      appType: // value for 'appType'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetMarketingModalInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMarketingModalInfoQuery,
    GetMarketingModalInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMarketingModalInfoQuery,
    GetMarketingModalInfoQueryVariables
  >(GetMarketingModalInfoDocument, options);
}
export function useGetMarketingModalInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMarketingModalInfoQuery,
    GetMarketingModalInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMarketingModalInfoQuery,
    GetMarketingModalInfoQueryVariables
  >(GetMarketingModalInfoDocument, options);
}
export type GetMarketingModalInfoQueryHookResult = ReturnType<
  typeof useGetMarketingModalInfoQuery
>;
export type GetMarketingModalInfoLazyQueryHookResult = ReturnType<
  typeof useGetMarketingModalInfoLazyQuery
>;
export type GetMarketingModalInfoQueryResult = Apollo.QueryResult<
  GetMarketingModalInfoQuery,
  GetMarketingModalInfoQueryVariables
>;
export const GetMasteringMoneyReportDocument = gql`
  query getMasteringMoneyReport($startDate: Date, $endDate: Date) {
    getMasteringMoneyReport(startDate: $startDate, endDate: $endDate) {
      projectId
      projectName
      cost
      costEstimate
      costFact
    }
  }
`;

/**
 * __useGetMasteringMoneyReportQuery__
 *
 * To run a query within a React component, call `useGetMasteringMoneyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasteringMoneyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasteringMoneyReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetMasteringMoneyReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMasteringMoneyReportQuery,
    GetMasteringMoneyReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMasteringMoneyReportQuery,
    GetMasteringMoneyReportQueryVariables
  >(GetMasteringMoneyReportDocument, options);
}
export function useGetMasteringMoneyReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMasteringMoneyReportQuery,
    GetMasteringMoneyReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMasteringMoneyReportQuery,
    GetMasteringMoneyReportQueryVariables
  >(GetMasteringMoneyReportDocument, options);
}
export type GetMasteringMoneyReportQueryHookResult = ReturnType<
  typeof useGetMasteringMoneyReportQuery
>;
export type GetMasteringMoneyReportLazyQueryHookResult = ReturnType<
  typeof useGetMasteringMoneyReportLazyQuery
>;
export type GetMasteringMoneyReportQueryResult = Apollo.QueryResult<
  GetMasteringMoneyReportQuery,
  GetMasteringMoneyReportQueryVariables
>;
export const GetMobileNotificationsByManagerDocument = gql`
  query getMobileNotificationsByManager(
    $userId: String
    $limit: Int
    $skip: Int
    $isPublished: Boolean
  ) {
    getMobileNotificationsByManager(
      userId: $userId
      limit: $limit
      skip: $skip
      isPublished: $isPublished
    ) {
      notifications {
        id
        name
        description
        photo
        availableFor
        participants {
          id
          user {
            id
          }
        }
        isPublished
        publishedAt
      }
      total
    }
  }
`;

/**
 * __useGetMobileNotificationsByManagerQuery__
 *
 * To run a query within a React component, call `useGetMobileNotificationsByManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileNotificationsByManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileNotificationsByManagerQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetMobileNotificationsByManagerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMobileNotificationsByManagerQuery,
    GetMobileNotificationsByManagerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMobileNotificationsByManagerQuery,
    GetMobileNotificationsByManagerQueryVariables
  >(GetMobileNotificationsByManagerDocument, options);
}
export function useGetMobileNotificationsByManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMobileNotificationsByManagerQuery,
    GetMobileNotificationsByManagerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMobileNotificationsByManagerQuery,
    GetMobileNotificationsByManagerQueryVariables
  >(GetMobileNotificationsByManagerDocument, options);
}
export type GetMobileNotificationsByManagerQueryHookResult = ReturnType<
  typeof useGetMobileNotificationsByManagerQuery
>;
export type GetMobileNotificationsByManagerLazyQueryHookResult = ReturnType<
  typeof useGetMobileNotificationsByManagerLazyQuery
>;
export type GetMobileNotificationsByManagerQueryResult = Apollo.QueryResult<
  GetMobileNotificationsByManagerQuery,
  GetMobileNotificationsByManagerQueryVariables
>;
export const GetMobileNotificationsByAppDocument = gql`
  query getMobileNotificationsByApp(
    $appId: String!
    $limit: Int
    $skip: Int
    $isPublished: Boolean
  ) {
    getMobileNotificationsByApp(
      appId: $appId
      limit: $limit
      skip: $skip
      isPublished: $isPublished
    ) {
      notifications {
        id
        name
        description
        photo
        availableFor
        isPublished
        publishedAt
      }
      total
    }
  }
`;

/**
 * __useGetMobileNotificationsByAppQuery__
 *
 * To run a query within a React component, call `useGetMobileNotificationsByAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileNotificationsByAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileNotificationsByAppQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetMobileNotificationsByAppQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMobileNotificationsByAppQuery,
    GetMobileNotificationsByAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMobileNotificationsByAppQuery,
    GetMobileNotificationsByAppQueryVariables
  >(GetMobileNotificationsByAppDocument, options);
}
export function useGetMobileNotificationsByAppLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMobileNotificationsByAppQuery,
    GetMobileNotificationsByAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMobileNotificationsByAppQuery,
    GetMobileNotificationsByAppQueryVariables
  >(GetMobileNotificationsByAppDocument, options);
}
export type GetMobileNotificationsByAppQueryHookResult = ReturnType<
  typeof useGetMobileNotificationsByAppQuery
>;
export type GetMobileNotificationsByAppLazyQueryHookResult = ReturnType<
  typeof useGetMobileNotificationsByAppLazyQuery
>;
export type GetMobileNotificationsByAppQueryResult = Apollo.QueryResult<
  GetMobileNotificationsByAppQuery,
  GetMobileNotificationsByAppQueryVariables
>;
export const GetMobileNotificationDocument = gql`
  query getMobileNotification($id: String) {
    getMobileNotification(id: $id) {
      availableFor
    }
  }
`;

/**
 * __useGetMobileNotificationQuery__
 *
 * To run a query within a React component, call `useGetMobileNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMobileNotificationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMobileNotificationQuery,
    GetMobileNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMobileNotificationQuery,
    GetMobileNotificationQueryVariables
  >(GetMobileNotificationDocument, options);
}
export function useGetMobileNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMobileNotificationQuery,
    GetMobileNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMobileNotificationQuery,
    GetMobileNotificationQueryVariables
  >(GetMobileNotificationDocument, options);
}
export type GetMobileNotificationQueryHookResult = ReturnType<
  typeof useGetMobileNotificationQuery
>;
export type GetMobileNotificationLazyQueryHookResult = ReturnType<
  typeof useGetMobileNotificationLazyQuery
>;
export type GetMobileNotificationQueryResult = Apollo.QueryResult<
  GetMobileNotificationQuery,
  GetMobileNotificationQueryVariables
>;
export const GetNewsDocument = gql`
  query getNews($limit: Int, $skip: Int, $isPublished: Boolean) {
    getNews(limit: $limit, skip: $skip, isPublished: $isPublished) {
      news {
        id
        name
        isFor
        description
        photo
        youTubeLink
        availableFor
        participants {
          id
          user {
            id
            lastName
            firstName
            photo
            job {
              id
              name
            }
            role {
              id
            }
          }
          news {
            id
          }
        }
        isPublished
        publishedAt
      }
      total
    }
  }
`;

/**
 * __useGetNewsQuery__
 *
 * To run a query within a React component, call `useGetNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetNewsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNewsQuery, GetNewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNewsQuery, GetNewsQueryVariables>(
    GetNewsDocument,
    options
  );
}
export function useGetNewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNewsQuery, GetNewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNewsQuery, GetNewsQueryVariables>(
    GetNewsDocument,
    options
  );
}
export type GetNewsQueryHookResult = ReturnType<typeof useGetNewsQuery>;
export type GetNewsLazyQueryHookResult = ReturnType<typeof useGetNewsLazyQuery>;
export type GetNewsQueryResult = Apollo.QueryResult<
  GetNewsQuery,
  GetNewsQueryVariables
>;
export const GetNewsByManagerDocument = gql`
  query getNewsByManager(
    $userId: String
    $limit: Int
    $skip: Int
    $isPublished: Boolean
  ) {
    getNewsByManager(
      userId: $userId
      limit: $limit
      skip: $skip
      isPublished: $isPublished
    ) {
      news {
        id
        name
        isFor
        description
        photo
        youTubeLink
        availableFor
        participants {
          id
          user {
            id
            lastName
            firstName
            photo
            job {
              id
              name
            }
            role {
              id
            }
          }
          news {
            id
          }
        }
        isPublished
        publishedAt
      }
      total
    }
  }
`;

/**
 * __useGetNewsByManagerQuery__
 *
 * To run a query within a React component, call `useGetNewsByManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsByManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsByManagerQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetNewsByManagerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNewsByManagerQuery,
    GetNewsByManagerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNewsByManagerQuery, GetNewsByManagerQueryVariables>(
    GetNewsByManagerDocument,
    options
  );
}
export function useGetNewsByManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNewsByManagerQuery,
    GetNewsByManagerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNewsByManagerQuery,
    GetNewsByManagerQueryVariables
  >(GetNewsByManagerDocument, options);
}
export type GetNewsByManagerQueryHookResult = ReturnType<
  typeof useGetNewsByManagerQuery
>;
export type GetNewsByManagerLazyQueryHookResult = ReturnType<
  typeof useGetNewsByManagerLazyQuery
>;
export type GetNewsByManagerQueryResult = Apollo.QueryResult<
  GetNewsByManagerQuery,
  GetNewsByManagerQueryVariables
>;
export const GetNotificationsByManagerDocument = gql`
  query getNotificationsByManager(
    $userId: String
    $limit: Int
    $skip: Int
    $isPublished: Boolean
  ) {
    getNotificationsByManager(
      userId: $userId
      limit: $limit
      skip: $skip
      isPublished: $isPublished
    ) {
      notifications {
        id
        name
        description
        availableFor
        participants {
          id
          user {
            id
            lastName
            firstName
            photo
            job {
              id
              name
            }
            role {
              id
            }
          }
          isWatched
        }
        isPublished
        publishedAt
      }
      total
    }
  }
`;

/**
 * __useGetNotificationsByManagerQuery__
 *
 * To run a query within a React component, call `useGetNotificationsByManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsByManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsByManagerQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetNotificationsByManagerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNotificationsByManagerQuery,
    GetNotificationsByManagerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNotificationsByManagerQuery,
    GetNotificationsByManagerQueryVariables
  >(GetNotificationsByManagerDocument, options);
}
export function useGetNotificationsByManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationsByManagerQuery,
    GetNotificationsByManagerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotificationsByManagerQuery,
    GetNotificationsByManagerQueryVariables
  >(GetNotificationsByManagerDocument, options);
}
export type GetNotificationsByManagerQueryHookResult = ReturnType<
  typeof useGetNotificationsByManagerQuery
>;
export type GetNotificationsByManagerLazyQueryHookResult = ReturnType<
  typeof useGetNotificationsByManagerLazyQuery
>;
export type GetNotificationsByManagerQueryResult = Apollo.QueryResult<
  GetNotificationsByManagerQuery,
  GetNotificationsByManagerQueryVariables
>;
export const GetOnboardingThemesEditDocument = gql`
  query getOnboardingThemesEdit {
    getOnboardingThemesEdit {
      id
      title
      description
      isPublished
      isFinished
      publishedAt
      position
      video
      routeId
    }
  }
`;

/**
 * __useGetOnboardingThemesEditQuery__
 *
 * To run a query within a React component, call `useGetOnboardingThemesEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingThemesEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingThemesEditQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOnboardingThemesEditQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOnboardingThemesEditQuery,
    GetOnboardingThemesEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOnboardingThemesEditQuery,
    GetOnboardingThemesEditQueryVariables
  >(GetOnboardingThemesEditDocument, options);
}
export function useGetOnboardingThemesEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnboardingThemesEditQuery,
    GetOnboardingThemesEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOnboardingThemesEditQuery,
    GetOnboardingThemesEditQueryVariables
  >(GetOnboardingThemesEditDocument, options);
}
export type GetOnboardingThemesEditQueryHookResult = ReturnType<
  typeof useGetOnboardingThemesEditQuery
>;
export type GetOnboardingThemesEditLazyQueryHookResult = ReturnType<
  typeof useGetOnboardingThemesEditLazyQuery
>;
export type GetOnboardingThemesEditQueryResult = Apollo.QueryResult<
  GetOnboardingThemesEditQuery,
  GetOnboardingThemesEditQueryVariables
>;
export const GetOnboardingRoutesEditDocument = gql`
  query getOnboardingRoutesEdit {
    getOnboardingRoutesEdit {
      id
      name
    }
  }
`;

/**
 * __useGetOnboardingRoutesEditQuery__
 *
 * To run a query within a React component, call `useGetOnboardingRoutesEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingRoutesEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingRoutesEditQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOnboardingRoutesEditQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOnboardingRoutesEditQuery,
    GetOnboardingRoutesEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOnboardingRoutesEditQuery,
    GetOnboardingRoutesEditQueryVariables
  >(GetOnboardingRoutesEditDocument, options);
}
export function useGetOnboardingRoutesEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnboardingRoutesEditQuery,
    GetOnboardingRoutesEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOnboardingRoutesEditQuery,
    GetOnboardingRoutesEditQueryVariables
  >(GetOnboardingRoutesEditDocument, options);
}
export type GetOnboardingRoutesEditQueryHookResult = ReturnType<
  typeof useGetOnboardingRoutesEditQuery
>;
export type GetOnboardingRoutesEditLazyQueryHookResult = ReturnType<
  typeof useGetOnboardingRoutesEditLazyQuery
>;
export type GetOnboardingRoutesEditQueryResult = Apollo.QueryResult<
  GetOnboardingRoutesEditQuery,
  GetOnboardingRoutesEditQueryVariables
>;
export const GetUserPaymentsByProjectDocument = gql`
  query getUserPaymentsByProject($projectId: String!, $limit: Int, $skip: Int) {
    getUserPaymentsByProject(
      projectId: $projectId
      limit: $limit
      skip: $skip
    ) {
      payments {
        id
        name
        cost
        isPaid
        paidDate
        documents {
          id
          name
          fileLink
        }
        status {
          status
          displayName
        }
        estimatedDate
        isPublished
        createdDate
      }
      total
    }
  }
`;

/**
 * __useGetUserPaymentsByProjectQuery__
 *
 * To run a query within a React component, call `useGetUserPaymentsByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPaymentsByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPaymentsByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetUserPaymentsByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserPaymentsByProjectQuery,
    GetUserPaymentsByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserPaymentsByProjectQuery,
    GetUserPaymentsByProjectQueryVariables
  >(GetUserPaymentsByProjectDocument, options);
}
export function useGetUserPaymentsByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPaymentsByProjectQuery,
    GetUserPaymentsByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserPaymentsByProjectQuery,
    GetUserPaymentsByProjectQueryVariables
  >(GetUserPaymentsByProjectDocument, options);
}
export type GetUserPaymentsByProjectQueryHookResult = ReturnType<
  typeof useGetUserPaymentsByProjectQuery
>;
export type GetUserPaymentsByProjectLazyQueryHookResult = ReturnType<
  typeof useGetUserPaymentsByProjectLazyQuery
>;
export type GetUserPaymentsByProjectQueryResult = Apollo.QueryResult<
  GetUserPaymentsByProjectQuery,
  GetUserPaymentsByProjectQueryVariables
>;
export const GetUserPayrollsDocument = gql`
  query getUserPayrolls($userId: String!) {
    getUserPayrolls(userId: $userId) {
      id
      name
      bonuses {
        id
        name
        value
      }
      hoursPlan
      user {
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useGetUserPayrollsQuery__
 *
 * To run a query within a React component, call `useGetUserPayrollsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPayrollsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPayrollsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserPayrollsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserPayrollsQuery,
    GetUserPayrollsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserPayrollsQuery, GetUserPayrollsQueryVariables>(
    GetUserPayrollsDocument,
    options
  );
}
export function useGetUserPayrollsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPayrollsQuery,
    GetUserPayrollsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserPayrollsQuery,
    GetUserPayrollsQueryVariables
  >(GetUserPayrollsDocument, options);
}
export type GetUserPayrollsQueryHookResult = ReturnType<
  typeof useGetUserPayrollsQuery
>;
export type GetUserPayrollsLazyQueryHookResult = ReturnType<
  typeof useGetUserPayrollsLazyQuery
>;
export type GetUserPayrollsQueryResult = Apollo.QueryResult<
  GetUserPayrollsQuery,
  GetUserPayrollsQueryVariables
>;
export const GetUserPayrollDocument = gql`
  query getUserPayroll($payrollId: String!) {
    getUserPayroll(payrollId: $payrollId) {
      id
      name
      bonuses {
        value
      }
      bonusFund
      kpi {
        id
        name
        unit
        weigh
        plan
        fact
      }
      parts {
        id
        name
        value
        date
      }
      salaryDescription
      bonusesDescription
      totalDescription
      kpiDescription
      hoursPlan
      additionalData {
        reports {
          totalTime
          times
          salary
        }
        reportsByProjects {
          projectName
          time
        }
        bonusesValue
        kpiValue
        total
        mainPart
      }
    }
  }
`;

/**
 * __useGetUserPayrollQuery__
 *
 * To run a query within a React component, call `useGetUserPayrollQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPayrollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPayrollQuery({
 *   variables: {
 *      payrollId: // value for 'payrollId'
 *   },
 * });
 */
export function useGetUserPayrollQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserPayrollQuery,
    GetUserPayrollQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserPayrollQuery, GetUserPayrollQueryVariables>(
    GetUserPayrollDocument,
    options
  );
}
export function useGetUserPayrollLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPayrollQuery,
    GetUserPayrollQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserPayrollQuery, GetUserPayrollQueryVariables>(
    GetUserPayrollDocument,
    options
  );
}
export type GetUserPayrollQueryHookResult = ReturnType<
  typeof useGetUserPayrollQuery
>;
export type GetUserPayrollLazyQueryHookResult = ReturnType<
  typeof useGetUserPayrollLazyQuery
>;
export type GetUserPayrollQueryResult = Apollo.QueryResult<
  GetUserPayrollQuery,
  GetUserPayrollQueryVariables
>;
export const GetProjectsDocument = gql`
  query getProjects($getProjectsInput: GetProjectsInput!) {
    getProjects(getProjectsInput: $getProjectsInput) {
      total
      projects {
        id
        name
        jira
        jiraId
        finishDate
        projectManager {
          firstName
          lastName
        }
        status {
          id
        }
        stages
      }
    }
  }
`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      getProjectsInput: // value for 'getProjectsInput'
 *   },
 * });
 */
export function useGetProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options
  );
}
export function useGetProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options
  );
}
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<
  typeof useGetProjectsLazyQuery
>;
export type GetProjectsQueryResult = Apollo.QueryResult<
  GetProjectsQuery,
  GetProjectsQueryVariables
>;
export const GetProjectsLightDocument = gql`
  query getProjectsLight {
    getProjectsLight {
      id
      name
    }
  }
`;

/**
 * __useGetProjectsLightQuery__
 *
 * To run a query within a React component, call `useGetProjectsLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsLightQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectsLightQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectsLightQuery,
    GetProjectsLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsLightQuery, GetProjectsLightQueryVariables>(
    GetProjectsLightDocument,
    options
  );
}
export function useGetProjectsLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsLightQuery,
    GetProjectsLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectsLightQuery,
    GetProjectsLightQueryVariables
  >(GetProjectsLightDocument, options);
}
export type GetProjectsLightQueryHookResult = ReturnType<
  typeof useGetProjectsLightQuery
>;
export type GetProjectsLightLazyQueryHookResult = ReturnType<
  typeof useGetProjectsLightLazyQuery
>;
export type GetProjectsLightQueryResult = Apollo.QueryResult<
  GetProjectsLightQuery,
  GetProjectsLightQueryVariables
>;
export const GetProjectsForEmployeesDistributionReportDocument = gql`
  query getProjectsForEmployeesDistributionReport(
    $getProjectsInput: GetProjectsInput!
  ) {
    getProjects(getProjectsInput: $getProjectsInput) {
      total
      projects {
        id
        name
      }
    }
  }
`;

/**
 * __useGetProjectsForEmployeesDistributionReportQuery__
 *
 * To run a query within a React component, call `useGetProjectsForEmployeesDistributionReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsForEmployeesDistributionReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsForEmployeesDistributionReportQuery({
 *   variables: {
 *      getProjectsInput: // value for 'getProjectsInput'
 *   },
 * });
 */
export function useGetProjectsForEmployeesDistributionReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectsForEmployeesDistributionReportQuery,
    GetProjectsForEmployeesDistributionReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectsForEmployeesDistributionReportQuery,
    GetProjectsForEmployeesDistributionReportQueryVariables
  >(GetProjectsForEmployeesDistributionReportDocument, options);
}
export function useGetProjectsForEmployeesDistributionReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsForEmployeesDistributionReportQuery,
    GetProjectsForEmployeesDistributionReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectsForEmployeesDistributionReportQuery,
    GetProjectsForEmployeesDistributionReportQueryVariables
  >(GetProjectsForEmployeesDistributionReportDocument, options);
}
export type GetProjectsForEmployeesDistributionReportQueryHookResult =
  ReturnType<typeof useGetProjectsForEmployeesDistributionReportQuery>;
export type GetProjectsForEmployeesDistributionReportLazyQueryHookResult =
  ReturnType<typeof useGetProjectsForEmployeesDistributionReportLazyQuery>;
export type GetProjectsForEmployeesDistributionReportQueryResult =
  Apollo.QueryResult<
    GetProjectsForEmployeesDistributionReportQuery,
    GetProjectsForEmployeesDistributionReportQueryVariables
  >;
export const GetProjectLocalStatusDocument = gql`
  query getProjectLocalStatus($projectId: String!) {
    getProject(projectId: $projectId) {
      isLocal
    }
  }
`;

/**
 * __useGetProjectLocalStatusQuery__
 *
 * To run a query within a React component, call `useGetProjectLocalStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectLocalStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectLocalStatusQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectLocalStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectLocalStatusQuery,
    GetProjectLocalStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectLocalStatusQuery,
    GetProjectLocalStatusQueryVariables
  >(GetProjectLocalStatusDocument, options);
}
export function useGetProjectLocalStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectLocalStatusQuery,
    GetProjectLocalStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectLocalStatusQuery,
    GetProjectLocalStatusQueryVariables
  >(GetProjectLocalStatusDocument, options);
}
export type GetProjectLocalStatusQueryHookResult = ReturnType<
  typeof useGetProjectLocalStatusQuery
>;
export type GetProjectLocalStatusLazyQueryHookResult = ReturnType<
  typeof useGetProjectLocalStatusLazyQuery
>;
export type GetProjectLocalStatusQueryResult = Apollo.QueryResult<
  GetProjectLocalStatusQuery,
  GetProjectLocalStatusQueryVariables
>;
export const GetProjectDocument = gql`
  query getProject($projectId: String!) {
    getProject(projectId: $projectId) {
      id
      name
      photo
      startDateEstimate
      finishDateEstimate
      startDate
      finishDate
      cost
      stack
      budget
      links {
        id
        title
        link
        login
        password
      }
      client {
        id
        firstName
        lastName
        phone
      }
      projectManager {
        id
        firstName
        lastName
        photo
      }
      contactPersons {
        id
        firstName
        lastName
        photo
        job {
          name
        }
      }
      additionalContacts {
        id
        name
        phone
        description
      }
      employees {
        id
        photo
        firstName
        lastName
      }
      status {
        id
        displayName
      }
      projectType {
        id
        name
      }
      blockDiagrams
      contractTemplates
      finalAgreement
      brief
      testTaskFile
      renderingFile
      designFigma
      risks
      riskScore
      presentations {
        id
        link
        title
      }
      files {
        id
        file
        name
      }
    }
  }
`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectQuery,
    GetProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options
  );
}
export function useGetProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectQuery,
    GetProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options
  );
}
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<
  typeof useGetProjectLazyQuery
>;
export type GetProjectQueryResult = Apollo.QueryResult<
  GetProjectQuery,
  GetProjectQueryVariables
>;
export const GetProjectEpicsDocument = gql`
  query getProjectEpics($projectId: String!) {
    getProjectEpics(projectId: $projectId) {
      epicKey
      epicName
    }
  }
`;

/**
 * __useGetProjectEpicsQuery__
 *
 * To run a query within a React component, call `useGetProjectEpicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectEpicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectEpicsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectEpicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectEpicsQuery,
    GetProjectEpicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectEpicsQuery, GetProjectEpicsQueryVariables>(
    GetProjectEpicsDocument,
    options
  );
}
export function useGetProjectEpicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectEpicsQuery,
    GetProjectEpicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectEpicsQuery,
    GetProjectEpicsQueryVariables
  >(GetProjectEpicsDocument, options);
}
export type GetProjectEpicsQueryHookResult = ReturnType<
  typeof useGetProjectEpicsQuery
>;
export type GetProjectEpicsLazyQueryHookResult = ReturnType<
  typeof useGetProjectEpicsLazyQuery
>;
export type GetProjectEpicsQueryResult = Apollo.QueryResult<
  GetProjectEpicsQuery,
  GetProjectEpicsQueryVariables
>;
export const GetProjectStepsByProjectDocument = gql`
  query getProjectStepsByProject($projectId: String!) {
    getProjectStepsByProject(projectId: $projectId) {
      id
      name
      key
      startDateEstimate
      startDateFact
      finishDateEstimate
      finishDateFact
      status {
        id
        name
      }
    }
  }
`;

/**
 * __useGetProjectStepsByProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectStepsByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectStepsByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectStepsByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectStepsByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectStepsByProjectQuery,
    GetProjectStepsByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectStepsByProjectQuery,
    GetProjectStepsByProjectQueryVariables
  >(GetProjectStepsByProjectDocument, options);
}
export function useGetProjectStepsByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectStepsByProjectQuery,
    GetProjectStepsByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectStepsByProjectQuery,
    GetProjectStepsByProjectQueryVariables
  >(GetProjectStepsByProjectDocument, options);
}
export type GetProjectStepsByProjectQueryHookResult = ReturnType<
  typeof useGetProjectStepsByProjectQuery
>;
export type GetProjectStepsByProjectLazyQueryHookResult = ReturnType<
  typeof useGetProjectStepsByProjectLazyQuery
>;
export type GetProjectStepsByProjectQueryResult = Apollo.QueryResult<
  GetProjectStepsByProjectQuery,
  GetProjectStepsByProjectQueryVariables
>;
export const GetProjectStepsByProjectLightDocument = gql`
  query getProjectStepsByProjectLight($projectId: String!) {
    getProjectStepsByProject(projectId: $projectId) {
      id
      name
      key
    }
  }
`;

/**
 * __useGetProjectStepsByProjectLightQuery__
 *
 * To run a query within a React component, call `useGetProjectStepsByProjectLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectStepsByProjectLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectStepsByProjectLightQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectStepsByProjectLightQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectStepsByProjectLightQuery,
    GetProjectStepsByProjectLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectStepsByProjectLightQuery,
    GetProjectStepsByProjectLightQueryVariables
  >(GetProjectStepsByProjectLightDocument, options);
}
export function useGetProjectStepsByProjectLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectStepsByProjectLightQuery,
    GetProjectStepsByProjectLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectStepsByProjectLightQuery,
    GetProjectStepsByProjectLightQueryVariables
  >(GetProjectStepsByProjectLightDocument, options);
}
export type GetProjectStepsByProjectLightQueryHookResult = ReturnType<
  typeof useGetProjectStepsByProjectLightQuery
>;
export type GetProjectStepsByProjectLightLazyQueryHookResult = ReturnType<
  typeof useGetProjectStepsByProjectLightLazyQuery
>;
export type GetProjectStepsByProjectLightQueryResult = Apollo.QueryResult<
  GetProjectStepsByProjectLightQuery,
  GetProjectStepsByProjectLightQueryVariables
>;
export const GetProjectStepsFromJiraDocument = gql`
  query getProjectStepsFromJira($projectId: String!) {
    getProjectStepsFromJira(projectId: $projectId) {
      expand
      id
      self
      key
      fields {
        summary
        duedate
        customfield_10015
      }
    }
  }
`;

/**
 * __useGetProjectStepsFromJiraQuery__
 *
 * To run a query within a React component, call `useGetProjectStepsFromJiraQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectStepsFromJiraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectStepsFromJiraQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectStepsFromJiraQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectStepsFromJiraQuery,
    GetProjectStepsFromJiraQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectStepsFromJiraQuery,
    GetProjectStepsFromJiraQueryVariables
  >(GetProjectStepsFromJiraDocument, options);
}
export function useGetProjectStepsFromJiraLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectStepsFromJiraQuery,
    GetProjectStepsFromJiraQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectStepsFromJiraQuery,
    GetProjectStepsFromJiraQueryVariables
  >(GetProjectStepsFromJiraDocument, options);
}
export type GetProjectStepsFromJiraQueryHookResult = ReturnType<
  typeof useGetProjectStepsFromJiraQuery
>;
export type GetProjectStepsFromJiraLazyQueryHookResult = ReturnType<
  typeof useGetProjectStepsFromJiraLazyQuery
>;
export type GetProjectStepsFromJiraQueryResult = Apollo.QueryResult<
  GetProjectStepsFromJiraQuery,
  GetProjectStepsFromJiraQueryVariables
>;
export const GetProjectTasksByEpicDocument = gql`
  query getProjectTasksByEpic($epicKey: String!) {
    getProjectTasksByEpic(epicKey: $epicKey) {
      id
      name
      key
      assignee {
        id
      }
      assigneeName
      startDateEstimate
      startDateFact
      finishDateEstimate
      finishDateFact
      timeEstimate
      timeSpent
      taskStatusId
      labels {
        id
        name
        color
      }
    }
  }
`;

/**
 * __useGetProjectTasksByEpicQuery__
 *
 * To run a query within a React component, call `useGetProjectTasksByEpicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTasksByEpicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTasksByEpicQuery({
 *   variables: {
 *      epicKey: // value for 'epicKey'
 *   },
 * });
 */
export function useGetProjectTasksByEpicQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTasksByEpicQuery,
    GetProjectTasksByEpicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectTasksByEpicQuery,
    GetProjectTasksByEpicQueryVariables
  >(GetProjectTasksByEpicDocument, options);
}
export function useGetProjectTasksByEpicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTasksByEpicQuery,
    GetProjectTasksByEpicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTasksByEpicQuery,
    GetProjectTasksByEpicQueryVariables
  >(GetProjectTasksByEpicDocument, options);
}
export type GetProjectTasksByEpicQueryHookResult = ReturnType<
  typeof useGetProjectTasksByEpicQuery
>;
export type GetProjectTasksByEpicLazyQueryHookResult = ReturnType<
  typeof useGetProjectTasksByEpicLazyQuery
>;
export type GetProjectTasksByEpicQueryResult = Apollo.QueryResult<
  GetProjectTasksByEpicQuery,
  GetProjectTasksByEpicQueryVariables
>;
export const GetProjectTasksListByEpicDocument = gql`
  query getProjectTasksListByEpic($epicKey: String!) {
    getProjectTasksByEpic(epicKey: $epicKey) {
      id
      name
      assignee {
        id
        firstName
        lastName
        photo
      }
    }
  }
`;

/**
 * __useGetProjectTasksListByEpicQuery__
 *
 * To run a query within a React component, call `useGetProjectTasksListByEpicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTasksListByEpicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTasksListByEpicQuery({
 *   variables: {
 *      epicKey: // value for 'epicKey'
 *   },
 * });
 */
export function useGetProjectTasksListByEpicQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTasksListByEpicQuery,
    GetProjectTasksListByEpicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectTasksListByEpicQuery,
    GetProjectTasksListByEpicQueryVariables
  >(GetProjectTasksListByEpicDocument, options);
}
export function useGetProjectTasksListByEpicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTasksListByEpicQuery,
    GetProjectTasksListByEpicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTasksListByEpicQuery,
    GetProjectTasksListByEpicQueryVariables
  >(GetProjectTasksListByEpicDocument, options);
}
export type GetProjectTasksListByEpicQueryHookResult = ReturnType<
  typeof useGetProjectTasksListByEpicQuery
>;
export type GetProjectTasksListByEpicLazyQueryHookResult = ReturnType<
  typeof useGetProjectTasksListByEpicLazyQuery
>;
export type GetProjectTasksListByEpicQueryResult = Apollo.QueryResult<
  GetProjectTasksListByEpicQuery,
  GetProjectTasksListByEpicQueryVariables
>;
export const GetProjectTasksByProjectDocument = gql`
  query getProjectTasksByProject($projectId: String!) {
    getProjectTasksByProject(projectId: $projectId) {
      id
      name
      key
      assignee {
        photo
      }
      assigneeName
      startDateEstimate
      startDateFact
      finishDateEstimate
      finishDateFact
      timeEstimate
      timeSpent
      labels {
        id
        name
        color
        isBase
      }
    }
  }
`;

/**
 * __useGetProjectTasksByProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectTasksByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTasksByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTasksByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectTasksByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTasksByProjectQuery,
    GetProjectTasksByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectTasksByProjectQuery,
    GetProjectTasksByProjectQueryVariables
  >(GetProjectTasksByProjectDocument, options);
}
export function useGetProjectTasksByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTasksByProjectQuery,
    GetProjectTasksByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTasksByProjectQuery,
    GetProjectTasksByProjectQueryVariables
  >(GetProjectTasksByProjectDocument, options);
}
export type GetProjectTasksByProjectQueryHookResult = ReturnType<
  typeof useGetProjectTasksByProjectQuery
>;
export type GetProjectTasksByProjectLazyQueryHookResult = ReturnType<
  typeof useGetProjectTasksByProjectLazyQuery
>;
export type GetProjectTasksByProjectQueryResult = Apollo.QueryResult<
  GetProjectTasksByProjectQuery,
  GetProjectTasksByProjectQueryVariables
>;
export const GetProjectTasksListDocument = gql`
  query getProjectTasksList($projectId: String!) {
    getProjectTasksByProject(projectId: $projectId) {
      id
      name
      epicName
    }
  }
`;

/**
 * __useGetProjectTasksListQuery__
 *
 * To run a query within a React component, call `useGetProjectTasksListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTasksListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTasksListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectTasksListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTasksListQuery,
    GetProjectTasksListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectTasksListQuery,
    GetProjectTasksListQueryVariables
  >(GetProjectTasksListDocument, options);
}
export function useGetProjectTasksListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTasksListQuery,
    GetProjectTasksListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTasksListQuery,
    GetProjectTasksListQueryVariables
  >(GetProjectTasksListDocument, options);
}
export type GetProjectTasksListQueryHookResult = ReturnType<
  typeof useGetProjectTasksListQuery
>;
export type GetProjectTasksListLazyQueryHookResult = ReturnType<
  typeof useGetProjectTasksListLazyQuery
>;
export type GetProjectTasksListQueryResult = Apollo.QueryResult<
  GetProjectTasksListQuery,
  GetProjectTasksListQueryVariables
>;
export const GetProjectTaskDocument = gql`
  query getProjectTask($id: String!) {
    getProjectTask(id: $id) {
      id
      name
      description
      comments {
        id
        text
        file
        createdBy {
          id
          firstName
          lastName
          photo
        }
      }
      items {
        id
        name
        isCompleted
      }
      key
      epicKey
      assigneeId
      responsibleId
      startDateEstimate
      startDateFact
      finishDateEstimate
      finishDateFact
      timeEstimate
      timeSpent
      labels {
        id
        name
        color
      }
      createdBy {
        firstName
        lastName
        photo
      }
    }
  }
`;

/**
 * __useGetProjectTaskQuery__
 *
 * To run a query within a React component, call `useGetProjectTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectTaskQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTaskQuery,
    GetProjectTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectTaskQuery, GetProjectTaskQueryVariables>(
    GetProjectTaskDocument,
    options
  );
}
export function useGetProjectTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTaskQuery,
    GetProjectTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectTaskQuery, GetProjectTaskQueryVariables>(
    GetProjectTaskDocument,
    options
  );
}
export type GetProjectTaskQueryHookResult = ReturnType<
  typeof useGetProjectTaskQuery
>;
export type GetProjectTaskLazyQueryHookResult = ReturnType<
  typeof useGetProjectTaskLazyQuery
>;
export type GetProjectTaskQueryResult = Apollo.QueryResult<
  GetProjectTaskQuery,
  GetProjectTaskQueryVariables
>;
export const GetProjectTasksByWorkflowStepDocument = gql`
  query getProjectTasksByWorkflowStep(
    $projectId: String!
    $workflowStepId: String!
    $limit: Int
    $skip: Int
  ) {
    getProjectTasksByWorkflowStep(
      projectId: $projectId
      workflowStepId: $workflowStepId
      limit: $limit
      skip: $skip
    ) {
      tasks {
        id
        name
        assignee {
          id
          firstName
          lastName
          photo
        }
      }
      total
    }
  }
`;

/**
 * __useGetProjectTasksByWorkflowStepQuery__
 *
 * To run a query within a React component, call `useGetProjectTasksByWorkflowStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTasksByWorkflowStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTasksByWorkflowStepQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      workflowStepId: // value for 'workflowStepId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetProjectTasksByWorkflowStepQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTasksByWorkflowStepQuery,
    GetProjectTasksByWorkflowStepQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectTasksByWorkflowStepQuery,
    GetProjectTasksByWorkflowStepQueryVariables
  >(GetProjectTasksByWorkflowStepDocument, options);
}
export function useGetProjectTasksByWorkflowStepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTasksByWorkflowStepQuery,
    GetProjectTasksByWorkflowStepQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTasksByWorkflowStepQuery,
    GetProjectTasksByWorkflowStepQueryVariables
  >(GetProjectTasksByWorkflowStepDocument, options);
}
export type GetProjectTasksByWorkflowStepQueryHookResult = ReturnType<
  typeof useGetProjectTasksByWorkflowStepQuery
>;
export type GetProjectTasksByWorkflowStepLazyQueryHookResult = ReturnType<
  typeof useGetProjectTasksByWorkflowStepLazyQuery
>;
export type GetProjectTasksByWorkflowStepQueryResult = Apollo.QueryResult<
  GetProjectTasksByWorkflowStepQuery,
  GetProjectTasksByWorkflowStepQueryVariables
>;
export const GetPmProjectsLightDocument = gql`
  query getPMProjectsLight {
    getPMProjectsLight {
      id
      name
    }
  }
`;

/**
 * __useGetPmProjectsLightQuery__
 *
 * To run a query within a React component, call `useGetPmProjectsLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPmProjectsLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPmProjectsLightQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPmProjectsLightQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPmProjectsLightQuery,
    GetPmProjectsLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPmProjectsLightQuery,
    GetPmProjectsLightQueryVariables
  >(GetPmProjectsLightDocument, options);
}
export function useGetPmProjectsLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPmProjectsLightQuery,
    GetPmProjectsLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPmProjectsLightQuery,
    GetPmProjectsLightQueryVariables
  >(GetPmProjectsLightDocument, options);
}
export type GetPmProjectsLightQueryHookResult = ReturnType<
  typeof useGetPmProjectsLightQuery
>;
export type GetPmProjectsLightLazyQueryHookResult = ReturnType<
  typeof useGetPmProjectsLightLazyQuery
>;
export type GetPmProjectsLightQueryResult = Apollo.QueryResult<
  GetPmProjectsLightQuery,
  GetPmProjectsLightQueryVariables
>;
export const GetLateTasksDocument = gql`
  query getLateTasks($projectId: String!, $taskStatusId: String) {
    getLateTasks(projectId: $projectId, taskStatusId: $taskStatusId) {
      id
      name
      lateReason
      assigneeName
      startDateFact
      startDateEstimate
      finishDateFact
      finishDateEstimate
      cost
      costEstimate
      lateStart
      lateFinish
    }
  }
`;

/**
 * __useGetLateTasksQuery__
 *
 * To run a query within a React component, call `useGetLateTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLateTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLateTasksQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      taskStatusId: // value for 'taskStatusId'
 *   },
 * });
 */
export function useGetLateTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLateTasksQuery,
    GetLateTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLateTasksQuery, GetLateTasksQueryVariables>(
    GetLateTasksDocument,
    options
  );
}
export function useGetLateTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLateTasksQuery,
    GetLateTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLateTasksQuery, GetLateTasksQueryVariables>(
    GetLateTasksDocument,
    options
  );
}
export type GetLateTasksQueryHookResult = ReturnType<
  typeof useGetLateTasksQuery
>;
export type GetLateTasksLazyQueryHookResult = ReturnType<
  typeof useGetLateTasksLazyQuery
>;
export type GetLateTasksQueryResult = Apollo.QueryResult<
  GetLateTasksQuery,
  GetLateTasksQueryVariables
>;
export const GetProjectTypesDocument = gql`
  query getProjectTypes {
    getProjectTypes {
      id
      name
    }
  }
`;

/**
 * __useGetProjectTypesQuery__
 *
 * To run a query within a React component, call `useGetProjectTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectTypesQuery,
    GetProjectTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectTypesQuery, GetProjectTypesQueryVariables>(
    GetProjectTypesDocument,
    options
  );
}
export function useGetProjectTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTypesQuery,
    GetProjectTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTypesQuery,
    GetProjectTypesQueryVariables
  >(GetProjectTypesDocument, options);
}
export type GetProjectTypesQueryHookResult = ReturnType<
  typeof useGetProjectTypesQuery
>;
export type GetProjectTypesLazyQueryHookResult = ReturnType<
  typeof useGetProjectTypesLazyQuery
>;
export type GetProjectTypesQueryResult = Apollo.QueryResult<
  GetProjectTypesQuery,
  GetProjectTypesQueryVariables
>;
export const GetQuoteCollectionsDocument = gql`
  query getQuoteCollections {
    getQuoteCollections {
      id
      name
    }
  }
`;

/**
 * __useGetQuoteCollectionsQuery__
 *
 * To run a query within a React component, call `useGetQuoteCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuoteCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetQuoteCollectionsQuery,
    GetQuoteCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetQuoteCollectionsQuery,
    GetQuoteCollectionsQueryVariables
  >(GetQuoteCollectionsDocument, options);
}
export function useGetQuoteCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuoteCollectionsQuery,
    GetQuoteCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetQuoteCollectionsQuery,
    GetQuoteCollectionsQueryVariables
  >(GetQuoteCollectionsDocument, options);
}
export type GetQuoteCollectionsQueryHookResult = ReturnType<
  typeof useGetQuoteCollectionsQuery
>;
export type GetQuoteCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetQuoteCollectionsLazyQuery
>;
export type GetQuoteCollectionsQueryResult = Apollo.QueryResult<
  GetQuoteCollectionsQuery,
  GetQuoteCollectionsQueryVariables
>;
export const GetRateHistoryDocument = gql`
  query getRateHistory($userId: String!) {
    getRateHistory(userId: $userId) {
      id
      rate
      changeDate
    }
  }
`;

/**
 * __useGetRateHistoryQuery__
 *
 * To run a query within a React component, call `useGetRateHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRateHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRateHistoryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetRateHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRateHistoryQuery,
    GetRateHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRateHistoryQuery, GetRateHistoryQueryVariables>(
    GetRateHistoryDocument,
    options
  );
}
export function useGetRateHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRateHistoryQuery,
    GetRateHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRateHistoryQuery, GetRateHistoryQueryVariables>(
    GetRateHistoryDocument,
    options
  );
}
export type GetRateHistoryQueryHookResult = ReturnType<
  typeof useGetRateHistoryQuery
>;
export type GetRateHistoryLazyQueryHookResult = ReturnType<
  typeof useGetRateHistoryLazyQuery
>;
export type GetRateHistoryQueryResult = Apollo.QueryResult<
  GetRateHistoryQuery,
  GetRateHistoryQueryVariables
>;
export const GetVacanciesPlanOptionsFieldsDocument = gql`
  query getVacanciesPlanOptionsFields {
    getVacanciesPlanOptionsFields {
      statuses {
        displayName
        id
      }
      jobs {
        id
        name
      }
      positions {
        id
        displayName
      }
    }
  }
`;

/**
 * __useGetVacanciesPlanOptionsFieldsQuery__
 *
 * To run a query within a React component, call `useGetVacanciesPlanOptionsFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVacanciesPlanOptionsFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVacanciesPlanOptionsFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVacanciesPlanOptionsFieldsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVacanciesPlanOptionsFieldsQuery,
    GetVacanciesPlanOptionsFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVacanciesPlanOptionsFieldsQuery,
    GetVacanciesPlanOptionsFieldsQueryVariables
  >(GetVacanciesPlanOptionsFieldsDocument, options);
}
export function useGetVacanciesPlanOptionsFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVacanciesPlanOptionsFieldsQuery,
    GetVacanciesPlanOptionsFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVacanciesPlanOptionsFieldsQuery,
    GetVacanciesPlanOptionsFieldsQueryVariables
  >(GetVacanciesPlanOptionsFieldsDocument, options);
}
export type GetVacanciesPlanOptionsFieldsQueryHookResult = ReturnType<
  typeof useGetVacanciesPlanOptionsFieldsQuery
>;
export type GetVacanciesPlanOptionsFieldsLazyQueryHookResult = ReturnType<
  typeof useGetVacanciesPlanOptionsFieldsLazyQuery
>;
export type GetVacanciesPlanOptionsFieldsQueryResult = Apollo.QueryResult<
  GetVacanciesPlanOptionsFieldsQuery,
  GetVacanciesPlanOptionsFieldsQueryVariables
>;
export const GetVacanciesDocument = gql`
  query getVacancies {
    getVacanciesPlanOptionsFields {
      jobs {
        id
        name
      }
    }
  }
`;

/**
 * __useGetVacanciesQuery__
 *
 * To run a query within a React component, call `useGetVacanciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVacanciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVacanciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVacanciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVacanciesQuery,
    GetVacanciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVacanciesQuery, GetVacanciesQueryVariables>(
    GetVacanciesDocument,
    options
  );
}
export function useGetVacanciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVacanciesQuery,
    GetVacanciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVacanciesQuery, GetVacanciesQueryVariables>(
    GetVacanciesDocument,
    options
  );
}
export type GetVacanciesQueryHookResult = ReturnType<
  typeof useGetVacanciesQuery
>;
export type GetVacanciesLazyQueryHookResult = ReturnType<
  typeof useGetVacanciesLazyQuery
>;
export type GetVacanciesQueryResult = Apollo.QueryResult<
  GetVacanciesQuery,
  GetVacanciesQueryVariables
>;
export const GetVacanciesPlanLightDocument = gql`
  query getVacanciesPlanLight {
    getVacanciesPlanLight {
      name
      count
    }
  }
`;

/**
 * __useGetVacanciesPlanLightQuery__
 *
 * To run a query within a React component, call `useGetVacanciesPlanLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVacanciesPlanLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVacanciesPlanLightQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVacanciesPlanLightQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVacanciesPlanLightQuery,
    GetVacanciesPlanLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVacanciesPlanLightQuery,
    GetVacanciesPlanLightQueryVariables
  >(GetVacanciesPlanLightDocument, options);
}
export function useGetVacanciesPlanLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVacanciesPlanLightQuery,
    GetVacanciesPlanLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVacanciesPlanLightQuery,
    GetVacanciesPlanLightQueryVariables
  >(GetVacanciesPlanLightDocument, options);
}
export type GetVacanciesPlanLightQueryHookResult = ReturnType<
  typeof useGetVacanciesPlanLightQuery
>;
export type GetVacanciesPlanLightLazyQueryHookResult = ReturnType<
  typeof useGetVacanciesPlanLightLazyQuery
>;
export type GetVacanciesPlanLightQueryResult = Apollo.QueryResult<
  GetVacanciesPlanLightQuery,
  GetVacanciesPlanLightQueryVariables
>;
export const GetVacanciesPlansByNameDocument = gql`
  query getVacanciesPlansByName($userId: String, $name: String!) {
    getVacanciesPlansByName(userId: $userId, name: $name) {
      progress
      plans {
        id
        job {
          id
          name
        }
        position {
          id
          displayName
        }
        experience
        status {
          id
          displayName
        }
        comment
      }
      progress
    }
  }
`;

/**
 * __useGetVacanciesPlansByNameQuery__
 *
 * To run a query within a React component, call `useGetVacanciesPlansByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVacanciesPlansByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVacanciesPlansByNameQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetVacanciesPlansByNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVacanciesPlansByNameQuery,
    GetVacanciesPlansByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVacanciesPlansByNameQuery,
    GetVacanciesPlansByNameQueryVariables
  >(GetVacanciesPlansByNameDocument, options);
}
export function useGetVacanciesPlansByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVacanciesPlansByNameQuery,
    GetVacanciesPlansByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVacanciesPlansByNameQuery,
    GetVacanciesPlansByNameQueryVariables
  >(GetVacanciesPlansByNameDocument, options);
}
export type GetVacanciesPlansByNameQueryHookResult = ReturnType<
  typeof useGetVacanciesPlansByNameQuery
>;
export type GetVacanciesPlansByNameLazyQueryHookResult = ReturnType<
  typeof useGetVacanciesPlansByNameLazyQuery
>;
export type GetVacanciesPlansByNameQueryResult = Apollo.QueryResult<
  GetVacanciesPlansByNameQuery,
  GetVacanciesPlansByNameQueryVariables
>;
export const GetReportsDocument = gql`
  query getReports($type: AdminReportType, $limit: Int, $skip: Int) {
    getReports(type: $type, limit: $limit, skip: $skip) {
      total
      reports {
        id
        name
        link
        startDate
        endDate
      }
    }
  }
`;

/**
 * __useGetReportsQuery__
 *
 * To run a query within a React component, call `useGetReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetReportsQuery,
    GetReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReportsQuery, GetReportsQueryVariables>(
    GetReportsDocument,
    options
  );
}
export function useGetReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReportsQuery,
    GetReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReportsQuery, GetReportsQueryVariables>(
    GetReportsDocument,
    options
  );
}
export type GetReportsQueryHookResult = ReturnType<typeof useGetReportsQuery>;
export type GetReportsLazyQueryHookResult = ReturnType<
  typeof useGetReportsLazyQuery
>;
export type GetReportsQueryResult = Apollo.QueryResult<
  GetReportsQuery,
  GetReportsQueryVariables
>;
export const GetNewLessonReflectionReportsDocument = gql`
  query getNewLessonReflectionReports($from: Date!, $to: Date!) {
    getNewLessonReflectionReports(from: $from, to: $to) {
      schoolId
      schoolName
      subjectName
      educationPlanName
      educationPlanChapterName
      lessonName
      teacherEmail
      teacherFirstName
      teacherLastName
      efficiencyPercent
    }
  }
`;

/**
 * __useGetNewLessonReflectionReportsQuery__
 *
 * To run a query within a React component, call `useGetNewLessonReflectionReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewLessonReflectionReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewLessonReflectionReportsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetNewLessonReflectionReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNewLessonReflectionReportsQuery,
    GetNewLessonReflectionReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNewLessonReflectionReportsQuery,
    GetNewLessonReflectionReportsQueryVariables
  >(GetNewLessonReflectionReportsDocument, options);
}
export function useGetNewLessonReflectionReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNewLessonReflectionReportsQuery,
    GetNewLessonReflectionReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNewLessonReflectionReportsQuery,
    GetNewLessonReflectionReportsQueryVariables
  >(GetNewLessonReflectionReportsDocument, options);
}
export type GetNewLessonReflectionReportsQueryHookResult = ReturnType<
  typeof useGetNewLessonReflectionReportsQuery
>;
export type GetNewLessonReflectionReportsLazyQueryHookResult = ReturnType<
  typeof useGetNewLessonReflectionReportsLazyQuery
>;
export type GetNewLessonReflectionReportsQueryResult = Apollo.QueryResult<
  GetNewLessonReflectionReportsQuery,
  GetNewLessonReflectionReportsQueryVariables
>;
export const GetCreatedPaymentsAdminReportsDocument = gql`
  query GetCreatedPaymentsAdminReports(
    $topFilter: TopFilterAdminReportCreatedPaymentsInput
    $filter: FilterAdminReportCreatedPaymentsInput
  ) {
    getCreatedPaymentsAdminReports(topFilter: $topFilter, filter: $filter) {
      dataTotal
      dataChart {
        tag
        value
      }
    }
  }
`;

/**
 * __useGetCreatedPaymentsAdminReportsQuery__
 *
 * To run a query within a React component, call `useGetCreatedPaymentsAdminReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatedPaymentsAdminReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatedPaymentsAdminReportsQuery({
 *   variables: {
 *      topFilter: // value for 'topFilter'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCreatedPaymentsAdminReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCreatedPaymentsAdminReportsQuery,
    GetCreatedPaymentsAdminReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCreatedPaymentsAdminReportsQuery,
    GetCreatedPaymentsAdminReportsQueryVariables
  >(GetCreatedPaymentsAdminReportsDocument, options);
}
export function useGetCreatedPaymentsAdminReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreatedPaymentsAdminReportsQuery,
    GetCreatedPaymentsAdminReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCreatedPaymentsAdminReportsQuery,
    GetCreatedPaymentsAdminReportsQueryVariables
  >(GetCreatedPaymentsAdminReportsDocument, options);
}
export type GetCreatedPaymentsAdminReportsQueryHookResult = ReturnType<
  typeof useGetCreatedPaymentsAdminReportsQuery
>;
export type GetCreatedPaymentsAdminReportsLazyQueryHookResult = ReturnType<
  typeof useGetCreatedPaymentsAdminReportsLazyQuery
>;
export type GetCreatedPaymentsAdminReportsQueryResult = Apollo.QueryResult<
  GetCreatedPaymentsAdminReportsQuery,
  GetCreatedPaymentsAdminReportsQueryVariables
>;
export const GetRegisteredUsersAdminReportsDocument = gql`
  query GetRegisteredUsersAdminReports(
    $topFilter: TopFilterAdminReportRegisteredUsersInput
    $filter: FilterAdminReportRegisteredUsersInput
  ) {
    getRegisteredUsersAdminReports(topFilter: $topFilter, filter: $filter) {
      dataTotal
      dataChart {
        tag
        value
      }
    }
  }
`;

/**
 * __useGetRegisteredUsersAdminReportsQuery__
 *
 * To run a query within a React component, call `useGetRegisteredUsersAdminReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisteredUsersAdminReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisteredUsersAdminReportsQuery({
 *   variables: {
 *      topFilter: // value for 'topFilter'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetRegisteredUsersAdminReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRegisteredUsersAdminReportsQuery,
    GetRegisteredUsersAdminReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRegisteredUsersAdminReportsQuery,
    GetRegisteredUsersAdminReportsQueryVariables
  >(GetRegisteredUsersAdminReportsDocument, options);
}
export function useGetRegisteredUsersAdminReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegisteredUsersAdminReportsQuery,
    GetRegisteredUsersAdminReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRegisteredUsersAdminReportsQuery,
    GetRegisteredUsersAdminReportsQueryVariables
  >(GetRegisteredUsersAdminReportsDocument, options);
}
export type GetRegisteredUsersAdminReportsQueryHookResult = ReturnType<
  typeof useGetRegisteredUsersAdminReportsQuery
>;
export type GetRegisteredUsersAdminReportsLazyQueryHookResult = ReturnType<
  typeof useGetRegisteredUsersAdminReportsLazyQuery
>;
export type GetRegisteredUsersAdminReportsQueryResult = Apollo.QueryResult<
  GetRegisteredUsersAdminReportsQuery,
  GetRegisteredUsersAdminReportsQueryVariables
>;
export const GetSalaryHistoryDocument = gql`
  query getSalaryHistory($userId: String!) {
    getSalaryHistory(userId: $userId) {
      id
      description
      difference
      previous
      current
      changeDate
    }
  }
`;

/**
 * __useGetSalaryHistoryQuery__
 *
 * To run a query within a React component, call `useGetSalaryHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalaryHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalaryHistoryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetSalaryHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSalaryHistoryQuery,
    GetSalaryHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSalaryHistoryQuery, GetSalaryHistoryQueryVariables>(
    GetSalaryHistoryDocument,
    options
  );
}
export function useGetSalaryHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalaryHistoryQuery,
    GetSalaryHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSalaryHistoryQuery,
    GetSalaryHistoryQueryVariables
  >(GetSalaryHistoryDocument, options);
}
export type GetSalaryHistoryQueryHookResult = ReturnType<
  typeof useGetSalaryHistoryQuery
>;
export type GetSalaryHistoryLazyQueryHookResult = ReturnType<
  typeof useGetSalaryHistoryLazyQuery
>;
export type GetSalaryHistoryQueryResult = Apollo.QueryResult<
  GetSalaryHistoryQuery,
  GetSalaryHistoryQueryVariables
>;
export const GetSchoolsForAdminPanelDocument = gql`
  query getSchoolsForAdminPanel($params: GetSchoolsForAdminPanelParams!) {
    getSchoolsForAdminPanel(params: $params) {
      schools {
        id
        name
        phone
        email
        idShort
        isPersonal
        teachersCount
        pupilsCount
        manager {
          id
          firstName
          lastName
        }
        country {
          id
          nameEN
        }
        district {
          id
          name
        }
        region {
          id
          name
        }
        city {
          id
          name
        }
        admins {
          id
          firstName
          lastName
          phone
          email
          createdDate
        }
        createdBy {
          id
          firstName
          lastName
          phone
          email
        }
        subscriptionStatus
        subscriptionExpiredDate
        isTrialSubscription
      }
      total
    }
  }
`;

/**
 * __useGetSchoolsForAdminPanelQuery__
 *
 * To run a query within a React component, call `useGetSchoolsForAdminPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsForAdminPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsForAdminPanelQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetSchoolsForAdminPanelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSchoolsForAdminPanelQuery,
    GetSchoolsForAdminPanelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSchoolsForAdminPanelQuery,
    GetSchoolsForAdminPanelQueryVariables
  >(GetSchoolsForAdminPanelDocument, options);
}
export function useGetSchoolsForAdminPanelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolsForAdminPanelQuery,
    GetSchoolsForAdminPanelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolsForAdminPanelQuery,
    GetSchoolsForAdminPanelQueryVariables
  >(GetSchoolsForAdminPanelDocument, options);
}
export type GetSchoolsForAdminPanelQueryHookResult = ReturnType<
  typeof useGetSchoolsForAdminPanelQuery
>;
export type GetSchoolsForAdminPanelLazyQueryHookResult = ReturnType<
  typeof useGetSchoolsForAdminPanelLazyQuery
>;
export type GetSchoolsForAdminPanelQueryResult = Apollo.QueryResult<
  GetSchoolsForAdminPanelQuery,
  GetSchoolsForAdminPanelQueryVariables
>;
export const GetSchoolDocument = gql`
  query getSchool($id: String!) {
    getSchool(id: $id) {
      id
      name
      phone
      photo
      email
      idShort
      isPersonal
      managerId
      countryId
      regionId
      districtId
      cityId
      fundingType
      communityId
      isInternalUse
      whiteLabelCollectionId
      admins {
        id
        firstName
        lastName
        createdDate
      }
      community {
        id
        fullName
      }
      teachers {
        id
        firstName
        lastName
      }
      planUsers {
        teachers {
          plan
          current
          currentPercent
        }
        pupils {
          plan
          current
          currentPercent
        }
        parents {
          plan
          current
          currentPercent
        }
        admins {
          plan
          current
          currentPercent
        }
        all {
          plan
          current
          currentPercent
        }
      }
      subscriptionInfo {
        plan {
          id
          name
        }
      }
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useGetSchoolQuery__
 *
 * To run a query within a React component, call `useGetSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSchoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetSchoolQuery, GetSchoolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSchoolQuery, GetSchoolQueryVariables>(
    GetSchoolDocument,
    options
  );
}
export function useGetSchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolQuery,
    GetSchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSchoolQuery, GetSchoolQueryVariables>(
    GetSchoolDocument,
    options
  );
}
export type GetSchoolQueryHookResult = ReturnType<typeof useGetSchoolQuery>;
export type GetSchoolLazyQueryHookResult = ReturnType<
  typeof useGetSchoolLazyQuery
>;
export type GetSchoolQueryResult = Apollo.QueryResult<
  GetSchoolQuery,
  GetSchoolQueryVariables
>;
export const GetPupilsBySchoolDocument = gql`
  query getPupilsBySchool($schoolId: String!) {
    getPupilsBySchool(schoolId: $schoolId) {
      users {
        firstName
        lastName
        middleName
      }
      total
    }
  }
`;

/**
 * __useGetPupilsBySchoolQuery__
 *
 * To run a query within a React component, call `useGetPupilsBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPupilsBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPupilsBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetPupilsBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPupilsBySchoolQuery,
    GetPupilsBySchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPupilsBySchoolQuery,
    GetPupilsBySchoolQueryVariables
  >(GetPupilsBySchoolDocument, options);
}
export function useGetPupilsBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPupilsBySchoolQuery,
    GetPupilsBySchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPupilsBySchoolQuery,
    GetPupilsBySchoolQueryVariables
  >(GetPupilsBySchoolDocument, options);
}
export type GetPupilsBySchoolQueryHookResult = ReturnType<
  typeof useGetPupilsBySchoolQuery
>;
export type GetPupilsBySchoolLazyQueryHookResult = ReturnType<
  typeof useGetPupilsBySchoolLazyQuery
>;
export type GetPupilsBySchoolQueryResult = Apollo.QueryResult<
  GetPupilsBySchoolQuery,
  GetPupilsBySchoolQueryVariables
>;
export const GetUsersForSchoolDocument = gql`
  query getUsersForSchool($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        firstName
        lastName
        email
      }
      total
    }
  }
`;

/**
 * __useGetUsersForSchoolQuery__
 *
 * To run a query within a React component, call `useGetUsersForSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForSchoolQuery({
 *   variables: {
 *      getUsersData: // value for 'getUsersData'
 *   },
 * });
 */
export function useGetUsersForSchoolQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersForSchoolQuery,
    GetUsersForSchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersForSchoolQuery,
    GetUsersForSchoolQueryVariables
  >(GetUsersForSchoolDocument, options);
}
export function useGetUsersForSchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersForSchoolQuery,
    GetUsersForSchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersForSchoolQuery,
    GetUsersForSchoolQueryVariables
  >(GetUsersForSchoolDocument, options);
}
export type GetUsersForSchoolQueryHookResult = ReturnType<
  typeof useGetUsersForSchoolQuery
>;
export type GetUsersForSchoolLazyQueryHookResult = ReturnType<
  typeof useGetUsersForSchoolLazyQuery
>;
export type GetUsersForSchoolQueryResult = Apollo.QueryResult<
  GetUsersForSchoolQuery,
  GetUsersForSchoolQueryVariables
>;
export const GetSchoolsForSelectorDocument = gql`
  query getSchoolsForSelector($params: GetSchoolsParams!) {
    getSchools(params: $params) {
      schools {
        id
        name
      }
      total
    }
  }
`;

/**
 * __useGetSchoolsForSelectorQuery__
 *
 * To run a query within a React component, call `useGetSchoolsForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsForSelectorQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetSchoolsForSelectorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSchoolsForSelectorQuery,
    GetSchoolsForSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSchoolsForSelectorQuery,
    GetSchoolsForSelectorQueryVariables
  >(GetSchoolsForSelectorDocument, options);
}
export function useGetSchoolsForSelectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolsForSelectorQuery,
    GetSchoolsForSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolsForSelectorQuery,
    GetSchoolsForSelectorQueryVariables
  >(GetSchoolsForSelectorDocument, options);
}
export type GetSchoolsForSelectorQueryHookResult = ReturnType<
  typeof useGetSchoolsForSelectorQuery
>;
export type GetSchoolsForSelectorLazyQueryHookResult = ReturnType<
  typeof useGetSchoolsForSelectorLazyQuery
>;
export type GetSchoolsForSelectorQueryResult = Apollo.QueryResult<
  GetSchoolsForSelectorQuery,
  GetSchoolsForSelectorQueryVariables
>;
export const GetSchoolGradesDocument = gql`
  query getSchoolGrades {
    getSchoolGrades {
      id
      name
    }
  }
`;

/**
 * __useGetSchoolGradesQuery__
 *
 * To run a query within a React component, call `useGetSchoolGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolGradesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSchoolGradesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolGradesQuery,
    GetSchoolGradesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSchoolGradesQuery, GetSchoolGradesQueryVariables>(
    GetSchoolGradesDocument,
    options
  );
}
export function useGetSchoolGradesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolGradesQuery,
    GetSchoolGradesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolGradesQuery,
    GetSchoolGradesQueryVariables
  >(GetSchoolGradesDocument, options);
}
export type GetSchoolGradesQueryHookResult = ReturnType<
  typeof useGetSchoolGradesQuery
>;
export type GetSchoolGradesLazyQueryHookResult = ReturnType<
  typeof useGetSchoolGradesLazyQuery
>;
export type GetSchoolGradesQueryResult = Apollo.QueryResult<
  GetSchoolGradesQuery,
  GetSchoolGradesQueryVariables
>;
export const GetSchoolUsersCsvDocument = gql`
  query getSchoolUsersCSV($schoolId: UUID!) {
    getSchoolUsersCSV(schoolId: $schoolId)
  }
`;

/**
 * __useGetSchoolUsersCsvQuery__
 *
 * To run a query within a React component, call `useGetSchoolUsersCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolUsersCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolUsersCsvQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetSchoolUsersCsvQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSchoolUsersCsvQuery,
    GetSchoolUsersCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSchoolUsersCsvQuery,
    GetSchoolUsersCsvQueryVariables
  >(GetSchoolUsersCsvDocument, options);
}
export function useGetSchoolUsersCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolUsersCsvQuery,
    GetSchoolUsersCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolUsersCsvQuery,
    GetSchoolUsersCsvQueryVariables
  >(GetSchoolUsersCsvDocument, options);
}
export type GetSchoolUsersCsvQueryHookResult = ReturnType<
  typeof useGetSchoolUsersCsvQuery
>;
export type GetSchoolUsersCsvLazyQueryHookResult = ReturnType<
  typeof useGetSchoolUsersCsvLazyQuery
>;
export type GetSchoolUsersCsvQueryResult = Apollo.QueryResult<
  GetSchoolUsersCsvQuery,
  GetSchoolUsersCsvQueryVariables
>;
export const GetValidateUserInvitationsListDocument = gql`
  query getValidateUserInvitationsList($list: Upload!) {
    validateUserInvitationsList(list: $list) {
      success
      errors {
        line
        errors
      }
    }
  }
`;

/**
 * __useGetValidateUserInvitationsListQuery__
 *
 * To run a query within a React component, call `useGetValidateUserInvitationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValidateUserInvitationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValidateUserInvitationsListQuery({
 *   variables: {
 *      list: // value for 'list'
 *   },
 * });
 */
export function useGetValidateUserInvitationsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetValidateUserInvitationsListQuery,
    GetValidateUserInvitationsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetValidateUserInvitationsListQuery,
    GetValidateUserInvitationsListQueryVariables
  >(GetValidateUserInvitationsListDocument, options);
}
export function useGetValidateUserInvitationsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetValidateUserInvitationsListQuery,
    GetValidateUserInvitationsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetValidateUserInvitationsListQuery,
    GetValidateUserInvitationsListQueryVariables
  >(GetValidateUserInvitationsListDocument, options);
}
export type GetValidateUserInvitationsListQueryHookResult = ReturnType<
  typeof useGetValidateUserInvitationsListQuery
>;
export type GetValidateUserInvitationsListLazyQueryHookResult = ReturnType<
  typeof useGetValidateUserInvitationsListLazyQuery
>;
export type GetValidateUserInvitationsListQueryResult = Apollo.QueryResult<
  GetValidateUserInvitationsListQuery,
  GetValidateUserInvitationsListQueryVariables
>;
export const GetCoursesForAdminPanelDocument = gql`
  query getCoursesForAdminPanel($schoolId: UUID!) {
    getCoursesForAdminPanel(schoolId: $schoolId) {
      courses {
        id
        name
        description
        courseIsAvailable
      }
    }
  }
`;

/**
 * __useGetCoursesForAdminPanelQuery__
 *
 * To run a query within a React component, call `useGetCoursesForAdminPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesForAdminPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesForAdminPanelQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetCoursesForAdminPanelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCoursesForAdminPanelQuery,
    GetCoursesForAdminPanelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCoursesForAdminPanelQuery,
    GetCoursesForAdminPanelQueryVariables
  >(GetCoursesForAdminPanelDocument, options);
}
export function useGetCoursesForAdminPanelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoursesForAdminPanelQuery,
    GetCoursesForAdminPanelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCoursesForAdminPanelQuery,
    GetCoursesForAdminPanelQueryVariables
  >(GetCoursesForAdminPanelDocument, options);
}
export type GetCoursesForAdminPanelQueryHookResult = ReturnType<
  typeof useGetCoursesForAdminPanelQuery
>;
export type GetCoursesForAdminPanelLazyQueryHookResult = ReturnType<
  typeof useGetCoursesForAdminPanelLazyQuery
>;
export type GetCoursesForAdminPanelQueryResult = Apollo.QueryResult<
  GetCoursesForAdminPanelQuery,
  GetCoursesForAdminPanelQueryVariables
>;
export const GetSchoolParticipantsDocument = gql`
  query getSchoolParticipants(
    $schoolId: UUID!
    $searchKey: String
    $sort: SchoolParticipantSortKey
    $limit: Int
    $skip: Int
  ) {
    getSchoolParticipants(
      schoolId: $schoolId
      searchKey: $searchKey
      sort: $sort
      limit: $limit
      skip: $skip
    ) {
      total
      users {
        id
        fullName
        phone
        email
        role {
          role
        }
        country {
          name
        }
      }
    }
  }
`;

/**
 * __useGetSchoolParticipantsQuery__
 *
 * To run a query within a React component, call `useGetSchoolParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolParticipantsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      searchKey: // value for 'searchKey'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetSchoolParticipantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSchoolParticipantsQuery,
    GetSchoolParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSchoolParticipantsQuery,
    GetSchoolParticipantsQueryVariables
  >(GetSchoolParticipantsDocument, options);
}
export function useGetSchoolParticipantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolParticipantsQuery,
    GetSchoolParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolParticipantsQuery,
    GetSchoolParticipantsQueryVariables
  >(GetSchoolParticipantsDocument, options);
}
export type GetSchoolParticipantsQueryHookResult = ReturnType<
  typeof useGetSchoolParticipantsQuery
>;
export type GetSchoolParticipantsLazyQueryHookResult = ReturnType<
  typeof useGetSchoolParticipantsLazyQuery
>;
export type GetSchoolParticipantsQueryResult = Apollo.QueryResult<
  GetSchoolParticipantsQuery,
  GetSchoolParticipantsQueryVariables
>;
export const GetSchoolConnectionStatisticDocument = gql`
  query getSchoolConnectionStatistic(
    $roles: TeacherPupilRoleEnum!
    $to: Date!
    $from: Date!
    $schoolId: UUID!
  ) {
    getSchoolConnectionStatistic(
      roles: $roles
      to: $to
      from: $from
      schoolId: $schoolId
    ) {
      dataChart {
        tag
        value
      }
      dataTotal
    }
  }
`;

/**
 * __useGetSchoolConnectionStatisticQuery__
 *
 * To run a query within a React component, call `useGetSchoolConnectionStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolConnectionStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolConnectionStatisticQuery({
 *   variables: {
 *      roles: // value for 'roles'
 *      to: // value for 'to'
 *      from: // value for 'from'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetSchoolConnectionStatisticQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSchoolConnectionStatisticQuery,
    GetSchoolConnectionStatisticQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSchoolConnectionStatisticQuery,
    GetSchoolConnectionStatisticQueryVariables
  >(GetSchoolConnectionStatisticDocument, options);
}
export function useGetSchoolConnectionStatisticLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolConnectionStatisticQuery,
    GetSchoolConnectionStatisticQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolConnectionStatisticQuery,
    GetSchoolConnectionStatisticQueryVariables
  >(GetSchoolConnectionStatisticDocument, options);
}
export type GetSchoolConnectionStatisticQueryHookResult = ReturnType<
  typeof useGetSchoolConnectionStatisticQuery
>;
export type GetSchoolConnectionStatisticLazyQueryHookResult = ReturnType<
  typeof useGetSchoolConnectionStatisticLazyQuery
>;
export type GetSchoolConnectionStatisticQueryResult = Apollo.QueryResult<
  GetSchoolConnectionStatisticQuery,
  GetSchoolConnectionStatisticQueryVariables
>;
export const GetSchoolPayersDocument = gql`
  query getSchoolPayers {
    getSchoolPayers {
      id
      name
    }
  }
`;

/**
 * __useGetSchoolPayersQuery__
 *
 * To run a query within a React component, call `useGetSchoolPayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolPayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolPayersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSchoolPayersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolPayersQuery,
    GetSchoolPayersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSchoolPayersQuery, GetSchoolPayersQueryVariables>(
    GetSchoolPayersDocument,
    options
  );
}
export function useGetSchoolPayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolPayersQuery,
    GetSchoolPayersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolPayersQuery,
    GetSchoolPayersQueryVariables
  >(GetSchoolPayersDocument, options);
}
export type GetSchoolPayersQueryHookResult = ReturnType<
  typeof useGetSchoolPayersQuery
>;
export type GetSchoolPayersLazyQueryHookResult = ReturnType<
  typeof useGetSchoolPayersLazyQuery
>;
export type GetSchoolPayersQueryResult = Apollo.QueryResult<
  GetSchoolPayersQuery,
  GetSchoolPayersQueryVariables
>;
export const GetTerritorialCommunitiesDocument = gql`
  query getTerritorialCommunities(
    $data: TerritorialCommunityInput
    $take: Int
    $skip: Int
  ) {
    getTerritorialCommunities(data: $data, take: $take, skip: $skip) {
      communities {
        fullName
        id
        countryId
        cityId
        regionId
      }
      total
    }
  }
`;

/**
 * __useGetTerritorialCommunitiesQuery__
 *
 * To run a query within a React component, call `useGetTerritorialCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerritorialCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerritorialCommunitiesQuery({
 *   variables: {
 *      data: // value for 'data'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetTerritorialCommunitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTerritorialCommunitiesQuery,
    GetTerritorialCommunitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTerritorialCommunitiesQuery,
    GetTerritorialCommunitiesQueryVariables
  >(GetTerritorialCommunitiesDocument, options);
}
export function useGetTerritorialCommunitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTerritorialCommunitiesQuery,
    GetTerritorialCommunitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTerritorialCommunitiesQuery,
    GetTerritorialCommunitiesQueryVariables
  >(GetTerritorialCommunitiesDocument, options);
}
export type GetTerritorialCommunitiesQueryHookResult = ReturnType<
  typeof useGetTerritorialCommunitiesQuery
>;
export type GetTerritorialCommunitiesLazyQueryHookResult = ReturnType<
  typeof useGetTerritorialCommunitiesLazyQuery
>;
export type GetTerritorialCommunitiesQueryResult = Apollo.QueryResult<
  GetTerritorialCommunitiesQuery,
  GetTerritorialCommunitiesQueryVariables
>;
export const GetSchoolCitiesDocument = gql`
  query getSchoolCities(
    $limit: Int
    $skip: Int
    $searchKey: String
    $regionId: String
    $districtId: String
  ) {
    getSchoolCities(
      limit: $limit
      skip: $skip
      searchKey: $searchKey
      regionId: $regionId
      districtId: $districtId
    ) {
      id
      name
    }
  }
`;

/**
 * __useGetSchoolCitiesQuery__
 *
 * To run a query within a React component, call `useGetSchoolCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolCitiesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      searchKey: // value for 'searchKey'
 *      regionId: // value for 'regionId'
 *      districtId: // value for 'districtId'
 *   },
 * });
 */
export function useGetSchoolCitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolCitiesQuery,
    GetSchoolCitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSchoolCitiesQuery, GetSchoolCitiesQueryVariables>(
    GetSchoolCitiesDocument,
    options
  );
}
export function useGetSchoolCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolCitiesQuery,
    GetSchoolCitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolCitiesQuery,
    GetSchoolCitiesQueryVariables
  >(GetSchoolCitiesDocument, options);
}
export type GetSchoolCitiesQueryHookResult = ReturnType<
  typeof useGetSchoolCitiesQuery
>;
export type GetSchoolCitiesLazyQueryHookResult = ReturnType<
  typeof useGetSchoolCitiesLazyQuery
>;
export type GetSchoolCitiesQueryResult = Apollo.QueryResult<
  GetSchoolCitiesQuery,
  GetSchoolCitiesQueryVariables
>;
export const GetSchoolCountriesDocument = gql`
  query getSchoolCountries($limit: Int, $skip: Int, $searchKey: String) {
    getSchoolCountries(limit: $limit, skip: $skip, searchKey: $searchKey) {
      id
      nameEN
      nameUKR
    }
  }
`;

/**
 * __useGetSchoolCountriesQuery__
 *
 * To run a query within a React component, call `useGetSchoolCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolCountriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      searchKey: // value for 'searchKey'
 *   },
 * });
 */
export function useGetSchoolCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolCountriesQuery,
    GetSchoolCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSchoolCountriesQuery,
    GetSchoolCountriesQueryVariables
  >(GetSchoolCountriesDocument, options);
}
export function useGetSchoolCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolCountriesQuery,
    GetSchoolCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolCountriesQuery,
    GetSchoolCountriesQueryVariables
  >(GetSchoolCountriesDocument, options);
}
export type GetSchoolCountriesQueryHookResult = ReturnType<
  typeof useGetSchoolCountriesQuery
>;
export type GetSchoolCountriesLazyQueryHookResult = ReturnType<
  typeof useGetSchoolCountriesLazyQuery
>;
export type GetSchoolCountriesQueryResult = Apollo.QueryResult<
  GetSchoolCountriesQuery,
  GetSchoolCountriesQueryVariables
>;
export const GetSchoolDistrictsByRegionDocument = gql`
  query getSchoolDistrictsByRegion(
    $limit: Int
    $skip: Int
    $searchKey: String
    $regionId: String
  ) {
    getSchoolDistrictsByRegion(
      limit: $limit
      skip: $skip
      searchKey: $searchKey
      regionId: $regionId
    ) {
      id
      name
    }
  }
`;

/**
 * __useGetSchoolDistrictsByRegionQuery__
 *
 * To run a query within a React component, call `useGetSchoolDistrictsByRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolDistrictsByRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolDistrictsByRegionQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      searchKey: // value for 'searchKey'
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useGetSchoolDistrictsByRegionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolDistrictsByRegionQuery,
    GetSchoolDistrictsByRegionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSchoolDistrictsByRegionQuery,
    GetSchoolDistrictsByRegionQueryVariables
  >(GetSchoolDistrictsByRegionDocument, options);
}
export function useGetSchoolDistrictsByRegionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolDistrictsByRegionQuery,
    GetSchoolDistrictsByRegionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolDistrictsByRegionQuery,
    GetSchoolDistrictsByRegionQueryVariables
  >(GetSchoolDistrictsByRegionDocument, options);
}
export type GetSchoolDistrictsByRegionQueryHookResult = ReturnType<
  typeof useGetSchoolDistrictsByRegionQuery
>;
export type GetSchoolDistrictsByRegionLazyQueryHookResult = ReturnType<
  typeof useGetSchoolDistrictsByRegionLazyQuery
>;
export type GetSchoolDistrictsByRegionQueryResult = Apollo.QueryResult<
  GetSchoolDistrictsByRegionQuery,
  GetSchoolDistrictsByRegionQueryVariables
>;
export const GetPaymentsHistoryBySchoolDocument = gql`
  query getPaymentsHistoryBySchool(
    $schoolId: String!
    $limit: Int
    $skip: Int
    $withTrial: Boolean
  ) {
    getPaymentsHistoryBySchool(
      schoolId: $schoolId
      limit: $limit
      skip: $skip
      withTrial: $withTrial
    ) {
      payments {
        id
        paymentDate
        expiredDate
        plan {
          name
          description
          monthsCount
        }
        payer {
          id
          name
        }
        type
        paymentType
        validity
        pupilsCount
        teachersCount
        total
        space
        amount
        price
        isActivated
        isManual
      }
      total
    }
  }
`;

/**
 * __useGetPaymentsHistoryBySchoolQuery__
 *
 * To run a query within a React component, call `useGetPaymentsHistoryBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsHistoryBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsHistoryBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      withTrial: // value for 'withTrial'
 *   },
 * });
 */
export function useGetPaymentsHistoryBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentsHistoryBySchoolQuery,
    GetPaymentsHistoryBySchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaymentsHistoryBySchoolQuery,
    GetPaymentsHistoryBySchoolQueryVariables
  >(GetPaymentsHistoryBySchoolDocument, options);
}
export function useGetPaymentsHistoryBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentsHistoryBySchoolQuery,
    GetPaymentsHistoryBySchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentsHistoryBySchoolQuery,
    GetPaymentsHistoryBySchoolQueryVariables
  >(GetPaymentsHistoryBySchoolDocument, options);
}
export type GetPaymentsHistoryBySchoolQueryHookResult = ReturnType<
  typeof useGetPaymentsHistoryBySchoolQuery
>;
export type GetPaymentsHistoryBySchoolLazyQueryHookResult = ReturnType<
  typeof useGetPaymentsHistoryBySchoolLazyQuery
>;
export type GetPaymentsHistoryBySchoolQueryResult = Apollo.QueryResult<
  GetPaymentsHistoryBySchoolQuery,
  GetPaymentsHistoryBySchoolQueryVariables
>;
export const CheckContentByProductIdDocument = gql`
  query CheckContentByProductId($productId: Int!) {
    checkContentByProductId(productId: $productId)
  }
`;

/**
 * __useCheckContentByProductIdQuery__
 *
 * To run a query within a React component, call `useCheckContentByProductIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckContentByProductIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckContentByProductIdQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useCheckContentByProductIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckContentByProductIdQuery,
    CheckContentByProductIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckContentByProductIdQuery,
    CheckContentByProductIdQueryVariables
  >(CheckContentByProductIdDocument, options);
}
export function useCheckContentByProductIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckContentByProductIdQuery,
    CheckContentByProductIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckContentByProductIdQuery,
    CheckContentByProductIdQueryVariables
  >(CheckContentByProductIdDocument, options);
}
export type CheckContentByProductIdQueryHookResult = ReturnType<
  typeof useCheckContentByProductIdQuery
>;
export type CheckContentByProductIdLazyQueryHookResult = ReturnType<
  typeof useCheckContentByProductIdLazyQuery
>;
export type CheckContentByProductIdQueryResult = Apollo.QueryResult<
  CheckContentByProductIdQuery,
  CheckContentByProductIdQueryVariables
>;
export const GetPurchasePaymentsBySchoolDocument = gql`
  query GetPurchasePaymentsBySchool(
    $schoolId: String!
    $limit: Int
    $skip: Int
  ) {
    getPurchasePaymentsBySchool(
      schoolId: $schoolId
      limit: $limit
      skip: $skip
    ) {
      total
      payments {
        id
        paymentDate
        paymentType
        content {
          productId
        }
        price
        isActivated
      }
    }
  }
`;

/**
 * __useGetPurchasePaymentsBySchoolQuery__
 *
 * To run a query within a React component, call `useGetPurchasePaymentsBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchasePaymentsBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchasePaymentsBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetPurchasePaymentsBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPurchasePaymentsBySchoolQuery,
    GetPurchasePaymentsBySchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPurchasePaymentsBySchoolQuery,
    GetPurchasePaymentsBySchoolQueryVariables
  >(GetPurchasePaymentsBySchoolDocument, options);
}
export function useGetPurchasePaymentsBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPurchasePaymentsBySchoolQuery,
    GetPurchasePaymentsBySchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPurchasePaymentsBySchoolQuery,
    GetPurchasePaymentsBySchoolQueryVariables
  >(GetPurchasePaymentsBySchoolDocument, options);
}
export type GetPurchasePaymentsBySchoolQueryHookResult = ReturnType<
  typeof useGetPurchasePaymentsBySchoolQuery
>;
export type GetPurchasePaymentsBySchoolLazyQueryHookResult = ReturnType<
  typeof useGetPurchasePaymentsBySchoolLazyQuery
>;
export type GetPurchasePaymentsBySchoolQueryResult = Apollo.QueryResult<
  GetPurchasePaymentsBySchoolQuery,
  GetPurchasePaymentsBySchoolQueryVariables
>;
export const GetSchoolRegionsDocument = gql`
  query getSchoolRegions(
    $limit: Int
    $skip: Int
    $searchKey: String
    $countryId: String
  ) {
    getSchoolRegions(
      limit: $limit
      skip: $skip
      searchKey: $searchKey
      countryId: $countryId
    ) {
      id
      name
    }
  }
`;

/**
 * __useGetSchoolRegionsQuery__
 *
 * To run a query within a React component, call `useGetSchoolRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolRegionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      searchKey: // value for 'searchKey'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useGetSchoolRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolRegionsQuery,
    GetSchoolRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSchoolRegionsQuery, GetSchoolRegionsQueryVariables>(
    GetSchoolRegionsDocument,
    options
  );
}
export function useGetSchoolRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolRegionsQuery,
    GetSchoolRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolRegionsQuery,
    GetSchoolRegionsQueryVariables
  >(GetSchoolRegionsDocument, options);
}
export type GetSchoolRegionsQueryHookResult = ReturnType<
  typeof useGetSchoolRegionsQuery
>;
export type GetSchoolRegionsLazyQueryHookResult = ReturnType<
  typeof useGetSchoolRegionsLazyQuery
>;
export type GetSchoolRegionsQueryResult = Apollo.QueryResult<
  GetSchoolRegionsQuery,
  GetSchoolRegionsQueryVariables
>;
export const GetSmartNotificationsDocument = gql`
  query getSmartNotifications(
    $limit: Int
    $skip: Int
    $isPublished: Boolean
    $type: SmartNotificationTypeEnum
  ) {
    getSmartNotifications(
      limit: $limit
      skip: $skip
      isPublished: $isPublished
      type: $type
    ) {
      notifications {
        id
        description
        link
        isPublished
        createdDate
        photo
        photoName
        applicationMainModelId
      }
      total
    }
  }
`;

/**
 * __useGetSmartNotificationsQuery__
 *
 * To run a query within a React component, call `useGetSmartNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmartNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmartNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      isPublished: // value for 'isPublished'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetSmartNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSmartNotificationsQuery,
    GetSmartNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSmartNotificationsQuery,
    GetSmartNotificationsQueryVariables
  >(GetSmartNotificationsDocument, options);
}
export function useGetSmartNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSmartNotificationsQuery,
    GetSmartNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSmartNotificationsQuery,
    GetSmartNotificationsQueryVariables
  >(GetSmartNotificationsDocument, options);
}
export type GetSmartNotificationsQueryHookResult = ReturnType<
  typeof useGetSmartNotificationsQuery
>;
export type GetSmartNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetSmartNotificationsLazyQuery
>;
export type GetSmartNotificationsQueryResult = Apollo.QueryResult<
  GetSmartNotificationsQuery,
  GetSmartNotificationsQueryVariables
>;
export const GetSmartNotificationDocument = gql`
  query getSmartNotification($id: String) {
    getSmartNotification(id: $id) {
      id
      description
      photo
      photoName
      pollLists {
        id
        name
      }
      participants {
        user {
          id
        }
      }
    }
  }
`;

/**
 * __useGetSmartNotificationQuery__
 *
 * To run a query within a React component, call `useGetSmartNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmartNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmartNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSmartNotificationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSmartNotificationQuery,
    GetSmartNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSmartNotificationQuery,
    GetSmartNotificationQueryVariables
  >(GetSmartNotificationDocument, options);
}
export function useGetSmartNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSmartNotificationQuery,
    GetSmartNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSmartNotificationQuery,
    GetSmartNotificationQueryVariables
  >(GetSmartNotificationDocument, options);
}
export type GetSmartNotificationQueryHookResult = ReturnType<
  typeof useGetSmartNotificationQuery
>;
export type GetSmartNotificationLazyQueryHookResult = ReturnType<
  typeof useGetSmartNotificationLazyQuery
>;
export type GetSmartNotificationQueryResult = Apollo.QueryResult<
  GetSmartNotificationQuery,
  GetSmartNotificationQueryVariables
>;
export const GetSmartNotificationByIdDocument = gql`
  query getSmartNotificationById($id: String, $contentType: ContentTypeInput) {
    getSmartNotification(id: $id, contentType: $contentType) {
      id
      type
      description
      isPublished
      createdDate
      pollLists {
        id
      }
      applicationMainModelId
    }
  }
`;

/**
 * __useGetSmartNotificationByIdQuery__
 *
 * To run a query within a React component, call `useGetSmartNotificationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmartNotificationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmartNotificationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useGetSmartNotificationByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSmartNotificationByIdQuery,
    GetSmartNotificationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSmartNotificationByIdQuery,
    GetSmartNotificationByIdQueryVariables
  >(GetSmartNotificationByIdDocument, options);
}
export function useGetSmartNotificationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSmartNotificationByIdQuery,
    GetSmartNotificationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSmartNotificationByIdQuery,
    GetSmartNotificationByIdQueryVariables
  >(GetSmartNotificationByIdDocument, options);
}
export type GetSmartNotificationByIdQueryHookResult = ReturnType<
  typeof useGetSmartNotificationByIdQuery
>;
export type GetSmartNotificationByIdLazyQueryHookResult = ReturnType<
  typeof useGetSmartNotificationByIdLazyQuery
>;
export type GetSmartNotificationByIdQueryResult = Apollo.QueryResult<
  GetSmartNotificationByIdQuery,
  GetSmartNotificationByIdQueryVariables
>;
export const GetAnswersWithPercentsBySmartNotificationIdDocument = gql`
  query getAnswersWithPercentsBySmartNotificationId(
    $smartNotificationId: String!
  ) {
    getAnswersWithPercentsBySmartNotificationId(
      smartNotificationId: $smartNotificationId
    ) {
      total
      answers {
        total
        pollListId
        ratting
        percents
      }
    }
  }
`;

/**
 * __useGetAnswersWithPercentsBySmartNotificationIdQuery__
 *
 * To run a query within a React component, call `useGetAnswersWithPercentsBySmartNotificationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswersWithPercentsBySmartNotificationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswersWithPercentsBySmartNotificationIdQuery({
 *   variables: {
 *      smartNotificationId: // value for 'smartNotificationId'
 *   },
 * });
 */
export function useGetAnswersWithPercentsBySmartNotificationIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAnswersWithPercentsBySmartNotificationIdQuery,
    GetAnswersWithPercentsBySmartNotificationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAnswersWithPercentsBySmartNotificationIdQuery,
    GetAnswersWithPercentsBySmartNotificationIdQueryVariables
  >(GetAnswersWithPercentsBySmartNotificationIdDocument, options);
}
export function useGetAnswersWithPercentsBySmartNotificationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnswersWithPercentsBySmartNotificationIdQuery,
    GetAnswersWithPercentsBySmartNotificationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAnswersWithPercentsBySmartNotificationIdQuery,
    GetAnswersWithPercentsBySmartNotificationIdQueryVariables
  >(GetAnswersWithPercentsBySmartNotificationIdDocument, options);
}
export type GetAnswersWithPercentsBySmartNotificationIdQueryHookResult =
  ReturnType<typeof useGetAnswersWithPercentsBySmartNotificationIdQuery>;
export type GetAnswersWithPercentsBySmartNotificationIdLazyQueryHookResult =
  ReturnType<typeof useGetAnswersWithPercentsBySmartNotificationIdLazyQuery>;
export type GetAnswersWithPercentsBySmartNotificationIdQueryResult =
  Apollo.QueryResult<
    GetAnswersWithPercentsBySmartNotificationIdQuery,
    GetAnswersWithPercentsBySmartNotificationIdQueryVariables
  >;
export const GetAnswersBySmartNotificationIdDocument = gql`
  query getAnswersBySmartNotificationId($smartNotificationId: String!) {
    getAnswersBySmartNotificationId(smartNotificationId: $smartNotificationId) {
      id
      ratting
      createdBy {
        id
        firstName
        lastName
        middleName
        email
        phone
        birthday
        deletedDate
        city {
          name
        }
        role {
          role
          displayName
        }
      }
      createdDate
    }
  }
`;

/**
 * __useGetAnswersBySmartNotificationIdQuery__
 *
 * To run a query within a React component, call `useGetAnswersBySmartNotificationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswersBySmartNotificationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswersBySmartNotificationIdQuery({
 *   variables: {
 *      smartNotificationId: // value for 'smartNotificationId'
 *   },
 * });
 */
export function useGetAnswersBySmartNotificationIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAnswersBySmartNotificationIdQuery,
    GetAnswersBySmartNotificationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAnswersBySmartNotificationIdQuery,
    GetAnswersBySmartNotificationIdQueryVariables
  >(GetAnswersBySmartNotificationIdDocument, options);
}
export function useGetAnswersBySmartNotificationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnswersBySmartNotificationIdQuery,
    GetAnswersBySmartNotificationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAnswersBySmartNotificationIdQuery,
    GetAnswersBySmartNotificationIdQueryVariables
  >(GetAnswersBySmartNotificationIdDocument, options);
}
export type GetAnswersBySmartNotificationIdQueryHookResult = ReturnType<
  typeof useGetAnswersBySmartNotificationIdQuery
>;
export type GetAnswersBySmartNotificationIdLazyQueryHookResult = ReturnType<
  typeof useGetAnswersBySmartNotificationIdLazyQuery
>;
export type GetAnswersBySmartNotificationIdQueryResult = Apollo.QueryResult<
  GetAnswersBySmartNotificationIdQuery,
  GetAnswersBySmartNotificationIdQueryVariables
>;
export const GetAnswersBySmartNotificationPollListIdDocument = gql`
  query getAnswersBySmartNotificationPollListId($pollListId: String!) {
    getAnswersBySmartNotificationPollListId(pollListId: $pollListId) {
      id
      ratting
    }
  }
`;

/**
 * __useGetAnswersBySmartNotificationPollListIdQuery__
 *
 * To run a query within a React component, call `useGetAnswersBySmartNotificationPollListIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswersBySmartNotificationPollListIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswersBySmartNotificationPollListIdQuery({
 *   variables: {
 *      pollListId: // value for 'pollListId'
 *   },
 * });
 */
export function useGetAnswersBySmartNotificationPollListIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAnswersBySmartNotificationPollListIdQuery,
    GetAnswersBySmartNotificationPollListIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAnswersBySmartNotificationPollListIdQuery,
    GetAnswersBySmartNotificationPollListIdQueryVariables
  >(GetAnswersBySmartNotificationPollListIdDocument, options);
}
export function useGetAnswersBySmartNotificationPollListIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnswersBySmartNotificationPollListIdQuery,
    GetAnswersBySmartNotificationPollListIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAnswersBySmartNotificationPollListIdQuery,
    GetAnswersBySmartNotificationPollListIdQueryVariables
  >(GetAnswersBySmartNotificationPollListIdDocument, options);
}
export type GetAnswersBySmartNotificationPollListIdQueryHookResult = ReturnType<
  typeof useGetAnswersBySmartNotificationPollListIdQuery
>;
export type GetAnswersBySmartNotificationPollListIdLazyQueryHookResult =
  ReturnType<typeof useGetAnswersBySmartNotificationPollListIdLazyQuery>;
export type GetAnswersBySmartNotificationPollListIdQueryResult =
  Apollo.QueryResult<
    GetAnswersBySmartNotificationPollListIdQuery,
    GetAnswersBySmartNotificationPollListIdQueryVariables
  >;
export const GetRatingAnswersCountBySmartNotificationIdDocument = gql`
  query getRatingAnswersCountBySmartNotificationId(
    $smartNotificationId: String!
  ) {
    getRatingAnswersCountBySmartNotificationId(
      smartNotificationId: $smartNotificationId
    )
  }
`;

/**
 * __useGetRatingAnswersCountBySmartNotificationIdQuery__
 *
 * To run a query within a React component, call `useGetRatingAnswersCountBySmartNotificationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRatingAnswersCountBySmartNotificationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRatingAnswersCountBySmartNotificationIdQuery({
 *   variables: {
 *      smartNotificationId: // value for 'smartNotificationId'
 *   },
 * });
 */
export function useGetRatingAnswersCountBySmartNotificationIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRatingAnswersCountBySmartNotificationIdQuery,
    GetRatingAnswersCountBySmartNotificationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRatingAnswersCountBySmartNotificationIdQuery,
    GetRatingAnswersCountBySmartNotificationIdQueryVariables
  >(GetRatingAnswersCountBySmartNotificationIdDocument, options);
}
export function useGetRatingAnswersCountBySmartNotificationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRatingAnswersCountBySmartNotificationIdQuery,
    GetRatingAnswersCountBySmartNotificationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRatingAnswersCountBySmartNotificationIdQuery,
    GetRatingAnswersCountBySmartNotificationIdQueryVariables
  >(GetRatingAnswersCountBySmartNotificationIdDocument, options);
}
export type GetRatingAnswersCountBySmartNotificationIdQueryHookResult =
  ReturnType<typeof useGetRatingAnswersCountBySmartNotificationIdQuery>;
export type GetRatingAnswersCountBySmartNotificationIdLazyQueryHookResult =
  ReturnType<typeof useGetRatingAnswersCountBySmartNotificationIdLazyQuery>;
export type GetRatingAnswersCountBySmartNotificationIdQueryResult =
  Apollo.QueryResult<
    GetRatingAnswersCountBySmartNotificationIdQuery,
    GetRatingAnswersCountBySmartNotificationIdQueryVariables
  >;
export const GetUsersBySmartNotificationAnswerDocument = gql`
  query getUsersBySmartNotificationAnswer(
    $smartNotificationId: String!
    $ratting: Int
    $pollListId: String
  ) {
    getUsersBySmartNotificationAnswer(
      smartNotificationId: $smartNotificationId
      ratting: $ratting
      pollListId: $pollListId
    ) {
      users {
        id
        firstName
        lastName
        photo
      }
      total
    }
  }
`;

/**
 * __useGetUsersBySmartNotificationAnswerQuery__
 *
 * To run a query within a React component, call `useGetUsersBySmartNotificationAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersBySmartNotificationAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersBySmartNotificationAnswerQuery({
 *   variables: {
 *      smartNotificationId: // value for 'smartNotificationId'
 *      ratting: // value for 'ratting'
 *      pollListId: // value for 'pollListId'
 *   },
 * });
 */
export function useGetUsersBySmartNotificationAnswerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersBySmartNotificationAnswerQuery,
    GetUsersBySmartNotificationAnswerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersBySmartNotificationAnswerQuery,
    GetUsersBySmartNotificationAnswerQueryVariables
  >(GetUsersBySmartNotificationAnswerDocument, options);
}
export function useGetUsersBySmartNotificationAnswerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersBySmartNotificationAnswerQuery,
    GetUsersBySmartNotificationAnswerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersBySmartNotificationAnswerQuery,
    GetUsersBySmartNotificationAnswerQueryVariables
  >(GetUsersBySmartNotificationAnswerDocument, options);
}
export type GetUsersBySmartNotificationAnswerQueryHookResult = ReturnType<
  typeof useGetUsersBySmartNotificationAnswerQuery
>;
export type GetUsersBySmartNotificationAnswerLazyQueryHookResult = ReturnType<
  typeof useGetUsersBySmartNotificationAnswerLazyQuery
>;
export type GetUsersBySmartNotificationAnswerQueryResult = Apollo.QueryResult<
  GetUsersBySmartNotificationAnswerQuery,
  GetUsersBySmartNotificationAnswerQueryVariables
>;
export const GetUserBySmartNotificationAnswerDocument = gql`
  query getUserBySmartNotificationAnswer(
    $smartNotificationId: String!
    $ratting: Int
  ) {
    getUsersBySmartNotificationAnswer(
      smartNotificationId: $smartNotificationId
      ratting: $ratting
    ) {
      users {
        id
        firstName
        lastName
        middleName
        phone
        email
        birthday
        role {
          displayName
        }
        city {
          name
        }
      }
      total
    }
  }
`;

/**
 * __useGetUserBySmartNotificationAnswerQuery__
 *
 * To run a query within a React component, call `useGetUserBySmartNotificationAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBySmartNotificationAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBySmartNotificationAnswerQuery({
 *   variables: {
 *      smartNotificationId: // value for 'smartNotificationId'
 *      ratting: // value for 'ratting'
 *   },
 * });
 */
export function useGetUserBySmartNotificationAnswerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserBySmartNotificationAnswerQuery,
    GetUserBySmartNotificationAnswerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserBySmartNotificationAnswerQuery,
    GetUserBySmartNotificationAnswerQueryVariables
  >(GetUserBySmartNotificationAnswerDocument, options);
}
export function useGetUserBySmartNotificationAnswerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserBySmartNotificationAnswerQuery,
    GetUserBySmartNotificationAnswerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserBySmartNotificationAnswerQuery,
    GetUserBySmartNotificationAnswerQueryVariables
  >(GetUserBySmartNotificationAnswerDocument, options);
}
export type GetUserBySmartNotificationAnswerQueryHookResult = ReturnType<
  typeof useGetUserBySmartNotificationAnswerQuery
>;
export type GetUserBySmartNotificationAnswerLazyQueryHookResult = ReturnType<
  typeof useGetUserBySmartNotificationAnswerLazyQuery
>;
export type GetUserBySmartNotificationAnswerQueryResult = Apollo.QueryResult<
  GetUserBySmartNotificationAnswerQuery,
  GetUserBySmartNotificationAnswerQueryVariables
>;
export const GetSmartNotificationPollListDocument = gql`
  query getSmartNotificationPollList($id: String) {
    getSmartNotificationPollList(id: $id) {
      id
      name
      notificationId
    }
  }
`;

/**
 * __useGetSmartNotificationPollListQuery__
 *
 * To run a query within a React component, call `useGetSmartNotificationPollListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmartNotificationPollListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmartNotificationPollListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSmartNotificationPollListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSmartNotificationPollListQuery,
    GetSmartNotificationPollListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSmartNotificationPollListQuery,
    GetSmartNotificationPollListQueryVariables
  >(GetSmartNotificationPollListDocument, options);
}
export function useGetSmartNotificationPollListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSmartNotificationPollListQuery,
    GetSmartNotificationPollListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSmartNotificationPollListQuery,
    GetSmartNotificationPollListQueryVariables
  >(GetSmartNotificationPollListDocument, options);
}
export type GetSmartNotificationPollListQueryHookResult = ReturnType<
  typeof useGetSmartNotificationPollListQuery
>;
export type GetSmartNotificationPollListLazyQueryHookResult = ReturnType<
  typeof useGetSmartNotificationPollListLazyQuery
>;
export type GetSmartNotificationPollListQueryResult = Apollo.QueryResult<
  GetSmartNotificationPollListQuery,
  GetSmartNotificationPollListQueryVariables
>;
export const GetSmartNotificationPollListByIdDocument = gql`
  query getSmartNotificationPollListById($id: String) {
    getSmartNotificationPollList(id: $id) {
      id
      name
      answers {
        id
        createdDate
        createdBy {
          id
          firstName
          lastName
          middleName
          email
          phone
          birthday
          deletedDate
          city {
            name
          }
          role {
            role
            displayName
          }
        }
      }
    }
  }
`;

/**
 * __useGetSmartNotificationPollListByIdQuery__
 *
 * To run a query within a React component, call `useGetSmartNotificationPollListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmartNotificationPollListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmartNotificationPollListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSmartNotificationPollListByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSmartNotificationPollListByIdQuery,
    GetSmartNotificationPollListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSmartNotificationPollListByIdQuery,
    GetSmartNotificationPollListByIdQueryVariables
  >(GetSmartNotificationPollListByIdDocument, options);
}
export function useGetSmartNotificationPollListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSmartNotificationPollListByIdQuery,
    GetSmartNotificationPollListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSmartNotificationPollListByIdQuery,
    GetSmartNotificationPollListByIdQueryVariables
  >(GetSmartNotificationPollListByIdDocument, options);
}
export type GetSmartNotificationPollListByIdQueryHookResult = ReturnType<
  typeof useGetSmartNotificationPollListByIdQuery
>;
export type GetSmartNotificationPollListByIdLazyQueryHookResult = ReturnType<
  typeof useGetSmartNotificationPollListByIdLazyQuery
>;
export type GetSmartNotificationPollListByIdQueryResult = Apollo.QueryResult<
  GetSmartNotificationPollListByIdQuery,
  GetSmartNotificationPollListByIdQueryVariables
>;
export const GetPollListsBySmartNotificationIdDocument = gql`
  query getPollListsBySmartNotificationId($smartNotificationId: String!) {
    getPollListsBySmartNotificationId(
      smartNotificationId: $smartNotificationId
    ) {
      id
      name
      notificationId
    }
  }
`;

/**
 * __useGetPollListsBySmartNotificationIdQuery__
 *
 * To run a query within a React component, call `useGetPollListsBySmartNotificationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPollListsBySmartNotificationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPollListsBySmartNotificationIdQuery({
 *   variables: {
 *      smartNotificationId: // value for 'smartNotificationId'
 *   },
 * });
 */
export function useGetPollListsBySmartNotificationIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPollListsBySmartNotificationIdQuery,
    GetPollListsBySmartNotificationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPollListsBySmartNotificationIdQuery,
    GetPollListsBySmartNotificationIdQueryVariables
  >(GetPollListsBySmartNotificationIdDocument, options);
}
export function useGetPollListsBySmartNotificationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPollListsBySmartNotificationIdQuery,
    GetPollListsBySmartNotificationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPollListsBySmartNotificationIdQuery,
    GetPollListsBySmartNotificationIdQueryVariables
  >(GetPollListsBySmartNotificationIdDocument, options);
}
export type GetPollListsBySmartNotificationIdQueryHookResult = ReturnType<
  typeof useGetPollListsBySmartNotificationIdQuery
>;
export type GetPollListsBySmartNotificationIdLazyQueryHookResult = ReturnType<
  typeof useGetPollListsBySmartNotificationIdLazyQuery
>;
export type GetPollListsBySmartNotificationIdQueryResult = Apollo.QueryResult<
  GetPollListsBySmartNotificationIdQuery,
  GetPollListsBySmartNotificationIdQueryVariables
>;
export const GetSubscriptionPlansDocument = gql`
  query getSubscriptionPlans {
    getSubscriptionPlans {
      id
      name
      description
      trialPeriod
      price
    }
  }
`;

/**
 * __useGetSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionPlansQuery,
    GetSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionPlansQuery,
    GetSubscriptionPlansQueryVariables
  >(GetSubscriptionPlansDocument, options);
}
export function useGetSubscriptionPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionPlansQuery,
    GetSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionPlansQuery,
    GetSubscriptionPlansQueryVariables
  >(GetSubscriptionPlansDocument, options);
}
export type GetSubscriptionPlansQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlansQuery
>;
export type GetSubscriptionPlansLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlansLazyQuery
>;
export type GetSubscriptionPlansQueryResult = Apollo.QueryResult<
  GetSubscriptionPlansQuery,
  GetSubscriptionPlansQueryVariables
>;
export const GetSubscriptionPlansV2Document = gql`
  query getSubscriptionPlansV2 {
    getSubscriptionPlansV2 {
      id
      name
      description
      type
    }
  }
`;

/**
 * __useGetSubscriptionPlansV2Query__
 *
 * To run a query within a React component, call `useGetSubscriptionPlansV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionPlansV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionPlansV2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionPlansV2Query(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionPlansV2Query,
    GetSubscriptionPlansV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionPlansV2Query,
    GetSubscriptionPlansV2QueryVariables
  >(GetSubscriptionPlansV2Document, options);
}
export function useGetSubscriptionPlansV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionPlansV2Query,
    GetSubscriptionPlansV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionPlansV2Query,
    GetSubscriptionPlansV2QueryVariables
  >(GetSubscriptionPlansV2Document, options);
}
export type GetSubscriptionPlansV2QueryHookResult = ReturnType<
  typeof useGetSubscriptionPlansV2Query
>;
export type GetSubscriptionPlansV2LazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlansV2LazyQuery
>;
export type GetSubscriptionPlansV2QueryResult = Apollo.QueryResult<
  GetSubscriptionPlansV2Query,
  GetSubscriptionPlansV2QueryVariables
>;
export const GetSubscriptionPlansV3ForAdminPanelDocument = gql`
  query getSubscriptionPlansV3ForAdminPanel($schoolId: UUID) {
    getSubscriptionPlansV3ForAdminPanel(schoolId: $schoolId) {
      id
      name
      description
      type
    }
  }
`;

/**
 * __useGetSubscriptionPlansV3ForAdminPanelQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionPlansV3ForAdminPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionPlansV3ForAdminPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionPlansV3ForAdminPanelQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetSubscriptionPlansV3ForAdminPanelQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionPlansV3ForAdminPanelQuery,
    GetSubscriptionPlansV3ForAdminPanelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionPlansV3ForAdminPanelQuery,
    GetSubscriptionPlansV3ForAdminPanelQueryVariables
  >(GetSubscriptionPlansV3ForAdminPanelDocument, options);
}
export function useGetSubscriptionPlansV3ForAdminPanelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionPlansV3ForAdminPanelQuery,
    GetSubscriptionPlansV3ForAdminPanelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionPlansV3ForAdminPanelQuery,
    GetSubscriptionPlansV3ForAdminPanelQueryVariables
  >(GetSubscriptionPlansV3ForAdminPanelDocument, options);
}
export type GetSubscriptionPlansV3ForAdminPanelQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlansV3ForAdminPanelQuery
>;
export type GetSubscriptionPlansV3ForAdminPanelLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlansV3ForAdminPanelLazyQuery
>;
export type GetSubscriptionPlansV3ForAdminPanelQueryResult = Apollo.QueryResult<
  GetSubscriptionPlansV3ForAdminPanelQuery,
  GetSubscriptionPlansV3ForAdminPanelQueryVariables
>;
export const GetAdminPanelSubscriptionsPaymentTypeDocument = gql`
  query getAdminPanelSubscriptionsPaymentType {
    getAdminPanelSubscriptionsPaymentType {
      id
      name
    }
  }
`;

/**
 * __useGetAdminPanelSubscriptionsPaymentTypeQuery__
 *
 * To run a query within a React component, call `useGetAdminPanelSubscriptionsPaymentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminPanelSubscriptionsPaymentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminPanelSubscriptionsPaymentTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminPanelSubscriptionsPaymentTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminPanelSubscriptionsPaymentTypeQuery,
    GetAdminPanelSubscriptionsPaymentTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdminPanelSubscriptionsPaymentTypeQuery,
    GetAdminPanelSubscriptionsPaymentTypeQueryVariables
  >(GetAdminPanelSubscriptionsPaymentTypeDocument, options);
}
export function useGetAdminPanelSubscriptionsPaymentTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminPanelSubscriptionsPaymentTypeQuery,
    GetAdminPanelSubscriptionsPaymentTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdminPanelSubscriptionsPaymentTypeQuery,
    GetAdminPanelSubscriptionsPaymentTypeQueryVariables
  >(GetAdminPanelSubscriptionsPaymentTypeDocument, options);
}
export type GetAdminPanelSubscriptionsPaymentTypeQueryHookResult = ReturnType<
  typeof useGetAdminPanelSubscriptionsPaymentTypeQuery
>;
export type GetAdminPanelSubscriptionsPaymentTypeLazyQueryHookResult =
  ReturnType<typeof useGetAdminPanelSubscriptionsPaymentTypeLazyQuery>;
export type GetAdminPanelSubscriptionsPaymentTypeQueryResult =
  Apollo.QueryResult<
    GetAdminPanelSubscriptionsPaymentTypeQuery,
    GetAdminPanelSubscriptionsPaymentTypeQueryVariables
  >;
export const GetSubscriptionPlansForManualPromotionDocument = gql`
  query getSubscriptionPlansForManualPromotion {
    getSubscriptionPlansForManualPromotion {
      id
      name
      description
      monthsCount
    }
  }
`;

/**
 * __useGetSubscriptionPlansForManualPromotionQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionPlansForManualPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionPlansForManualPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionPlansForManualPromotionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionPlansForManualPromotionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionPlansForManualPromotionQuery,
    GetSubscriptionPlansForManualPromotionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionPlansForManualPromotionQuery,
    GetSubscriptionPlansForManualPromotionQueryVariables
  >(GetSubscriptionPlansForManualPromotionDocument, options);
}
export function useGetSubscriptionPlansForManualPromotionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionPlansForManualPromotionQuery,
    GetSubscriptionPlansForManualPromotionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionPlansForManualPromotionQuery,
    GetSubscriptionPlansForManualPromotionQueryVariables
  >(GetSubscriptionPlansForManualPromotionDocument, options);
}
export type GetSubscriptionPlansForManualPromotionQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlansForManualPromotionQuery
>;
export type GetSubscriptionPlansForManualPromotionLazyQueryHookResult =
  ReturnType<typeof useGetSubscriptionPlansForManualPromotionLazyQuery>;
export type GetSubscriptionPlansForManualPromotionQueryResult =
  Apollo.QueryResult<
    GetSubscriptionPlansForManualPromotionQuery,
    GetSubscriptionPlansForManualPromotionQueryVariables
  >;
export const GetManualPromotionsDocument = gql`
  query getManualPromotions {
    getManualPromotions {
      promotions {
        startDate
        name
        id
        expiredDate
        discount
        code
        allowedPlans {
          monthsCount
          id
          description
        }
      }
    }
  }
`;

/**
 * __useGetManualPromotionsQuery__
 *
 * To run a query within a React component, call `useGetManualPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManualPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManualPromotionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManualPromotionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManualPromotionsQuery,
    GetManualPromotionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManualPromotionsQuery,
    GetManualPromotionsQueryVariables
  >(GetManualPromotionsDocument, options);
}
export function useGetManualPromotionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManualPromotionsQuery,
    GetManualPromotionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManualPromotionsQuery,
    GetManualPromotionsQueryVariables
  >(GetManualPromotionsDocument, options);
}
export type GetManualPromotionsQueryHookResult = ReturnType<
  typeof useGetManualPromotionsQuery
>;
export type GetManualPromotionsLazyQueryHookResult = ReturnType<
  typeof useGetManualPromotionsLazyQuery
>;
export type GetManualPromotionsQueryResult = Apollo.QueryResult<
  GetManualPromotionsQuery,
  GetManualPromotionsQueryVariables
>;
export const GetProjectTaskLabelDocument = gql`
  query getProjectTaskLabel($id: String!) {
    getProjectTaskLabel(id: $id) {
      id
      name
      color
      isBase
      isMutable
    }
  }
`;

/**
 * __useGetProjectTaskLabelQuery__
 *
 * To run a query within a React component, call `useGetProjectTaskLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTaskLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTaskLabelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectTaskLabelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTaskLabelQuery,
    GetProjectTaskLabelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectTaskLabelQuery,
    GetProjectTaskLabelQueryVariables
  >(GetProjectTaskLabelDocument, options);
}
export function useGetProjectTaskLabelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTaskLabelQuery,
    GetProjectTaskLabelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTaskLabelQuery,
    GetProjectTaskLabelQueryVariables
  >(GetProjectTaskLabelDocument, options);
}
export type GetProjectTaskLabelQueryHookResult = ReturnType<
  typeof useGetProjectTaskLabelQuery
>;
export type GetProjectTaskLabelLazyQueryHookResult = ReturnType<
  typeof useGetProjectTaskLabelLazyQuery
>;
export type GetProjectTaskLabelQueryResult = Apollo.QueryResult<
  GetProjectTaskLabelQuery,
  GetProjectTaskLabelQueryVariables
>;
export const GetBaseProjectTaskLabelsDocument = gql`
  query getBaseProjectTaskLabels {
    getBaseProjectTaskLabels {
      id
      name
      color
    }
  }
`;

/**
 * __useGetBaseProjectTaskLabelsQuery__
 *
 * To run a query within a React component, call `useGetBaseProjectTaskLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseProjectTaskLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseProjectTaskLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseProjectTaskLabelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBaseProjectTaskLabelsQuery,
    GetBaseProjectTaskLabelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBaseProjectTaskLabelsQuery,
    GetBaseProjectTaskLabelsQueryVariables
  >(GetBaseProjectTaskLabelsDocument, options);
}
export function useGetBaseProjectTaskLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaseProjectTaskLabelsQuery,
    GetBaseProjectTaskLabelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBaseProjectTaskLabelsQuery,
    GetBaseProjectTaskLabelsQueryVariables
  >(GetBaseProjectTaskLabelsDocument, options);
}
export type GetBaseProjectTaskLabelsQueryHookResult = ReturnType<
  typeof useGetBaseProjectTaskLabelsQuery
>;
export type GetBaseProjectTaskLabelsLazyQueryHookResult = ReturnType<
  typeof useGetBaseProjectTaskLabelsLazyQuery
>;
export type GetBaseProjectTaskLabelsQueryResult = Apollo.QueryResult<
  GetBaseProjectTaskLabelsQuery,
  GetBaseProjectTaskLabelsQueryVariables
>;
export const GetProjectTaskLabelsByProjectDocument = gql`
  query getProjectTaskLabelsByProject($projectId: String!) {
    getProjectTaskLabelsByProject(projectId: $projectId) {
      id
      name
      color
      isBase
      isMutable
    }
  }
`;

/**
 * __useGetProjectTaskLabelsByProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectTaskLabelsByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTaskLabelsByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTaskLabelsByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectTaskLabelsByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTaskLabelsByProjectQuery,
    GetProjectTaskLabelsByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectTaskLabelsByProjectQuery,
    GetProjectTaskLabelsByProjectQueryVariables
  >(GetProjectTaskLabelsByProjectDocument, options);
}
export function useGetProjectTaskLabelsByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTaskLabelsByProjectQuery,
    GetProjectTaskLabelsByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTaskLabelsByProjectQuery,
    GetProjectTaskLabelsByProjectQueryVariables
  >(GetProjectTaskLabelsByProjectDocument, options);
}
export type GetProjectTaskLabelsByProjectQueryHookResult = ReturnType<
  typeof useGetProjectTaskLabelsByProjectQuery
>;
export type GetProjectTaskLabelsByProjectLazyQueryHookResult = ReturnType<
  typeof useGetProjectTaskLabelsByProjectLazyQuery
>;
export type GetProjectTaskLabelsByProjectQueryResult = Apollo.QueryResult<
  GetProjectTaskLabelsByProjectQuery,
  GetProjectTaskLabelsByProjectQueryVariables
>;
export const GetAvailableProjectTaskLabelsDocument = gql`
  query getAvailableProjectTaskLabels(
    $projectId: String!
    $projectTaskId: String!
  ) {
    getAvailableProjectTaskLabels(
      projectId: $projectId
      projectTaskId: $projectTaskId
    ) {
      id
      name
      color
    }
  }
`;

/**
 * __useGetAvailableProjectTaskLabelsQuery__
 *
 * To run a query within a React component, call `useGetAvailableProjectTaskLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableProjectTaskLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableProjectTaskLabelsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      projectTaskId: // value for 'projectTaskId'
 *   },
 * });
 */
export function useGetAvailableProjectTaskLabelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvailableProjectTaskLabelsQuery,
    GetAvailableProjectTaskLabelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableProjectTaskLabelsQuery,
    GetAvailableProjectTaskLabelsQueryVariables
  >(GetAvailableProjectTaskLabelsDocument, options);
}
export function useGetAvailableProjectTaskLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableProjectTaskLabelsQuery,
    GetAvailableProjectTaskLabelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableProjectTaskLabelsQuery,
    GetAvailableProjectTaskLabelsQueryVariables
  >(GetAvailableProjectTaskLabelsDocument, options);
}
export type GetAvailableProjectTaskLabelsQueryHookResult = ReturnType<
  typeof useGetAvailableProjectTaskLabelsQuery
>;
export type GetAvailableProjectTaskLabelsLazyQueryHookResult = ReturnType<
  typeof useGetAvailableProjectTaskLabelsLazyQuery
>;
export type GetAvailableProjectTaskLabelsQueryResult = Apollo.QueryResult<
  GetAvailableProjectTaskLabelsQuery,
  GetAvailableProjectTaskLabelsQueryVariables
>;
export const GetProjectTaskStatusDocument = gql`
  query getProjectTaskStatus($id: String!) {
    getProjectTaskStatus(id: $id) {
      id
      name
      isBase
    }
  }
`;

/**
 * __useGetProjectTaskStatusQuery__
 *
 * To run a query within a React component, call `useGetProjectTaskStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTaskStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTaskStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectTaskStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTaskStatusQuery,
    GetProjectTaskStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectTaskStatusQuery,
    GetProjectTaskStatusQueryVariables
  >(GetProjectTaskStatusDocument, options);
}
export function useGetProjectTaskStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTaskStatusQuery,
    GetProjectTaskStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTaskStatusQuery,
    GetProjectTaskStatusQueryVariables
  >(GetProjectTaskStatusDocument, options);
}
export type GetProjectTaskStatusQueryHookResult = ReturnType<
  typeof useGetProjectTaskStatusQuery
>;
export type GetProjectTaskStatusLazyQueryHookResult = ReturnType<
  typeof useGetProjectTaskStatusLazyQuery
>;
export type GetProjectTaskStatusQueryResult = Apollo.QueryResult<
  GetProjectTaskStatusQuery,
  GetProjectTaskStatusQueryVariables
>;
export const GetBaseProjectTaskStatusesDocument = gql`
  query getBaseProjectTaskStatuses {
    getBaseProjectTaskStatuses {
      id
      name
      isBase
    }
  }
`;

/**
 * __useGetBaseProjectTaskStatusesQuery__
 *
 * To run a query within a React component, call `useGetBaseProjectTaskStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseProjectTaskStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseProjectTaskStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseProjectTaskStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBaseProjectTaskStatusesQuery,
    GetBaseProjectTaskStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBaseProjectTaskStatusesQuery,
    GetBaseProjectTaskStatusesQueryVariables
  >(GetBaseProjectTaskStatusesDocument, options);
}
export function useGetBaseProjectTaskStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaseProjectTaskStatusesQuery,
    GetBaseProjectTaskStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBaseProjectTaskStatusesQuery,
    GetBaseProjectTaskStatusesQueryVariables
  >(GetBaseProjectTaskStatusesDocument, options);
}
export type GetBaseProjectTaskStatusesQueryHookResult = ReturnType<
  typeof useGetBaseProjectTaskStatusesQuery
>;
export type GetBaseProjectTaskStatusesLazyQueryHookResult = ReturnType<
  typeof useGetBaseProjectTaskStatusesLazyQuery
>;
export type GetBaseProjectTaskStatusesQueryResult = Apollo.QueryResult<
  GetBaseProjectTaskStatusesQuery,
  GetBaseProjectTaskStatusesQueryVariables
>;
export const GetProjectTaskStatusesByProjectDocument = gql`
  query getProjectTaskStatusesByProject($projectId: String!) {
    getProjectTaskStatusesByProject(projectId: $projectId) {
      id
      name
      isBase
    }
  }
`;

/**
 * __useGetProjectTaskStatusesByProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectTaskStatusesByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTaskStatusesByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTaskStatusesByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectTaskStatusesByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTaskStatusesByProjectQuery,
    GetProjectTaskStatusesByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectTaskStatusesByProjectQuery,
    GetProjectTaskStatusesByProjectQueryVariables
  >(GetProjectTaskStatusesByProjectDocument, options);
}
export function useGetProjectTaskStatusesByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTaskStatusesByProjectQuery,
    GetProjectTaskStatusesByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTaskStatusesByProjectQuery,
    GetProjectTaskStatusesByProjectQueryVariables
  >(GetProjectTaskStatusesByProjectDocument, options);
}
export type GetProjectTaskStatusesByProjectQueryHookResult = ReturnType<
  typeof useGetProjectTaskStatusesByProjectQuery
>;
export type GetProjectTaskStatusesByProjectLazyQueryHookResult = ReturnType<
  typeof useGetProjectTaskStatusesByProjectLazyQuery
>;
export type GetProjectTaskStatusesByProjectQueryResult = Apollo.QueryResult<
  GetProjectTaskStatusesByProjectQuery,
  GetProjectTaskStatusesByProjectQueryVariables
>;
export const GetTasksWithProblemsDocument = gql`
  query getTasksWithProblems($projectId: String!) {
    getTasksWithProblems(projectId: $projectId) {
      done {
        key
        name
        startDateEstimate
        finishDateEstimate
        startDateFact
        finishDateFact
        assigneeJiraId
      }
      dateEstimate {
        key
        name
        startDateEstimate
        finishDateEstimate
      }
      assignee {
        key
        name
        assigneeJiraId
      }
      noEpic {
        key
        name
        epicKey
      }
    }
  }
`;

/**
 * __useGetTasksWithProblemsQuery__
 *
 * To run a query within a React component, call `useGetTasksWithProblemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksWithProblemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksWithProblemsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetTasksWithProblemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTasksWithProblemsQuery,
    GetTasksWithProblemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTasksWithProblemsQuery,
    GetTasksWithProblemsQueryVariables
  >(GetTasksWithProblemsDocument, options);
}
export function useGetTasksWithProblemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTasksWithProblemsQuery,
    GetTasksWithProblemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTasksWithProblemsQuery,
    GetTasksWithProblemsQueryVariables
  >(GetTasksWithProblemsDocument, options);
}
export type GetTasksWithProblemsQueryHookResult = ReturnType<
  typeof useGetTasksWithProblemsQuery
>;
export type GetTasksWithProblemsLazyQueryHookResult = ReturnType<
  typeof useGetTasksWithProblemsLazyQuery
>;
export type GetTasksWithProblemsQueryResult = Apollo.QueryResult<
  GetTasksWithProblemsQuery,
  GetTasksWithProblemsQueryVariables
>;
export const GetClassroomsByTeacherDocument = gql`
  query getClassroomsByTeacher(
    $teacherId: String!
    $schoolId: String!
    $limit: Int
    $skip: Int
    $isArchived: Boolean
  ) {
    getClassroomsByTeacher(
      teacherId: $teacherId
      schoolId: $schoolId
      limit: $limit
      skip: $skip
      isArchived: $isArchived
    ) {
      classrooms {
        id
        name
        teacherId
      }
    }
  }
`;

/**
 * __useGetClassroomsByTeacherQuery__
 *
 * To run a query within a React component, call `useGetClassroomsByTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassroomsByTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassroomsByTeacherQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      schoolId: // value for 'schoolId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useGetClassroomsByTeacherQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassroomsByTeacherQuery,
    GetClassroomsByTeacherQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassroomsByTeacherQuery,
    GetClassroomsByTeacherQueryVariables
  >(GetClassroomsByTeacherDocument, options);
}
export function useGetClassroomsByTeacherLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassroomsByTeacherQuery,
    GetClassroomsByTeacherQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassroomsByTeacherQuery,
    GetClassroomsByTeacherQueryVariables
  >(GetClassroomsByTeacherDocument, options);
}
export type GetClassroomsByTeacherQueryHookResult = ReturnType<
  typeof useGetClassroomsByTeacherQuery
>;
export type GetClassroomsByTeacherLazyQueryHookResult = ReturnType<
  typeof useGetClassroomsByTeacherLazyQuery
>;
export type GetClassroomsByTeacherQueryResult = Apollo.QueryResult<
  GetClassroomsByTeacherQuery,
  GetClassroomsByTeacherQueryVariables
>;
export const GetClassroomsByPupilDocument = gql`
  query getClassroomsByPupil($pupilId: String!, $limit: Int, $skip: Int) {
    getClassroomsByPupil(pupilId: $pupilId, limit: $limit, skip: $skip) {
      classrooms {
        id
        name
      }
    }
  }
`;

/**
 * __useGetClassroomsByPupilQuery__
 *
 * To run a query within a React component, call `useGetClassroomsByPupilQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassroomsByPupilQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassroomsByPupilQuery({
 *   variables: {
 *      pupilId: // value for 'pupilId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetClassroomsByPupilQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassroomsByPupilQuery,
    GetClassroomsByPupilQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassroomsByPupilQuery,
    GetClassroomsByPupilQueryVariables
  >(GetClassroomsByPupilDocument, options);
}
export function useGetClassroomsByPupilLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassroomsByPupilQuery,
    GetClassroomsByPupilQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassroomsByPupilQuery,
    GetClassroomsByPupilQueryVariables
  >(GetClassroomsByPupilDocument, options);
}
export type GetClassroomsByPupilQueryHookResult = ReturnType<
  typeof useGetClassroomsByPupilQuery
>;
export type GetClassroomsByPupilLazyQueryHookResult = ReturnType<
  typeof useGetClassroomsByPupilLazyQuery
>;
export type GetClassroomsByPupilQueryResult = Apollo.QueryResult<
  GetClassroomsByPupilQuery,
  GetClassroomsByPupilQueryVariables
>;
export const GetSchoolClassroomDocument = gql`
  query getSchoolClassroom($id: String!) {
    getSchoolClassroom(id: $id) {
      id
      grade
      letter
      schoolId
    }
  }
`;

/**
 * __useGetSchoolClassroomQuery__
 *
 * To run a query within a React component, call `useGetSchoolClassroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolClassroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolClassroomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSchoolClassroomQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSchoolClassroomQuery,
    GetSchoolClassroomQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSchoolClassroomQuery,
    GetSchoolClassroomQueryVariables
  >(GetSchoolClassroomDocument, options);
}
export function useGetSchoolClassroomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolClassroomQuery,
    GetSchoolClassroomQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolClassroomQuery,
    GetSchoolClassroomQueryVariables
  >(GetSchoolClassroomDocument, options);
}
export type GetSchoolClassroomQueryHookResult = ReturnType<
  typeof useGetSchoolClassroomQuery
>;
export type GetSchoolClassroomLazyQueryHookResult = ReturnType<
  typeof useGetSchoolClassroomLazyQuery
>;
export type GetSchoolClassroomQueryResult = Apollo.QueryResult<
  GetSchoolClassroomQuery,
  GetSchoolClassroomQueryVariables
>;
export const GetSchoolClassroomsDocument = gql`
  query getSchoolClassrooms($limit: Int, $skip: Int) {
    getSchoolClassrooms(limit: $limit, skip: $skip) {
      schoolClassrooms {
        id
        grade
        letter
        schoolId
      }
    }
  }
`;

/**
 * __useGetSchoolClassroomsQuery__
 *
 * To run a query within a React component, call `useGetSchoolClassroomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolClassroomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolClassroomsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetSchoolClassroomsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolClassroomsQuery,
    GetSchoolClassroomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSchoolClassroomsQuery,
    GetSchoolClassroomsQueryVariables
  >(GetSchoolClassroomsDocument, options);
}
export function useGetSchoolClassroomsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolClassroomsQuery,
    GetSchoolClassroomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolClassroomsQuery,
    GetSchoolClassroomsQueryVariables
  >(GetSchoolClassroomsDocument, options);
}
export type GetSchoolClassroomsQueryHookResult = ReturnType<
  typeof useGetSchoolClassroomsQuery
>;
export type GetSchoolClassroomsLazyQueryHookResult = ReturnType<
  typeof useGetSchoolClassroomsLazyQuery
>;
export type GetSchoolClassroomsQueryResult = Apollo.QueryResult<
  GetSchoolClassroomsQuery,
  GetSchoolClassroomsQueryVariables
>;
export const GetClassroomsDocument = gql`
  query getClassrooms($limit: Int, $skip: Int, $isArchived: Boolean) {
    getClassrooms(limit: $limit, skip: $skip, isArchived: $isArchived) {
      classrooms {
        id
        name
        teacherId
      }
      total
    }
  }
`;

/**
 * __useGetClassroomsQuery__
 *
 * To run a query within a React component, call `useGetClassroomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassroomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassroomsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useGetClassroomsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClassroomsQuery,
    GetClassroomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClassroomsQuery, GetClassroomsQueryVariables>(
    GetClassroomsDocument,
    options
  );
}
export function useGetClassroomsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassroomsQuery,
    GetClassroomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClassroomsQuery, GetClassroomsQueryVariables>(
    GetClassroomsDocument,
    options
  );
}
export type GetClassroomsQueryHookResult = ReturnType<
  typeof useGetClassroomsQuery
>;
export type GetClassroomsLazyQueryHookResult = ReturnType<
  typeof useGetClassroomsLazyQuery
>;
export type GetClassroomsQueryResult = Apollo.QueryResult<
  GetClassroomsQuery,
  GetClassroomsQueryVariables
>;
export const GetTimeReportDocument = gql`
  query getTimeReport($startDate: Date, $endDate: Date) {
    getTimeReport(startDate: $startDate, endDate: $endDate) {
      projectId
      projectName
      total
      totalTimeEstimate
      totalTimeSpent
      difference
    }
  }
`;

/**
 * __useGetTimeReportQuery__
 *
 * To run a query within a React component, call `useGetTimeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetTimeReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTimeReportQuery,
    GetTimeReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTimeReportQuery, GetTimeReportQueryVariables>(
    GetTimeReportDocument,
    options
  );
}
export function useGetTimeReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTimeReportQuery,
    GetTimeReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTimeReportQuery, GetTimeReportQueryVariables>(
    GetTimeReportDocument,
    options
  );
}
export type GetTimeReportQueryHookResult = ReturnType<
  typeof useGetTimeReportQuery
>;
export type GetTimeReportLazyQueryHookResult = ReturnType<
  typeof useGetTimeReportLazyQuery
>;
export type GetTimeReportQueryResult = Apollo.QueryResult<
  GetTimeReportQuery,
  GetTimeReportQueryVariables
>;
export const GetTimeReportByEpicDocument = gql`
  query getTimeReportByEpic(
    $epicKey: String!
    $startDate: Date
    $endDate: Date
  ) {
    getTimeReportByEpic(
      epicKey: $epicKey
      startDate: $startDate
      endDate: $endDate
    ) {
      tasks {
        id
        name
        assigneeName
        status
        timeEstimate
        timeSpent
      }
      totalTimeEstimate
      totalTimeSpent
    }
  }
`;

/**
 * __useGetTimeReportByEpicQuery__
 *
 * To run a query within a React component, call `useGetTimeReportByEpicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeReportByEpicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeReportByEpicQuery({
 *   variables: {
 *      epicKey: // value for 'epicKey'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetTimeReportByEpicQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTimeReportByEpicQuery,
    GetTimeReportByEpicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTimeReportByEpicQuery,
    GetTimeReportByEpicQueryVariables
  >(GetTimeReportByEpicDocument, options);
}
export function useGetTimeReportByEpicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTimeReportByEpicQuery,
    GetTimeReportByEpicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTimeReportByEpicQuery,
    GetTimeReportByEpicQueryVariables
  >(GetTimeReportByEpicDocument, options);
}
export type GetTimeReportByEpicQueryHookResult = ReturnType<
  typeof useGetTimeReportByEpicQuery
>;
export type GetTimeReportByEpicLazyQueryHookResult = ReturnType<
  typeof useGetTimeReportByEpicLazyQuery
>;
export type GetTimeReportByEpicQueryResult = Apollo.QueryResult<
  GetTimeReportByEpicQuery,
  GetTimeReportByEpicQueryVariables
>;
export const GetTraningsDocument = gql`
  query getTranings($limit: Int, $skip: Int, $isPublished: Boolean) {
    getTranings(limit: $limit, skip: $skip, isPublished: $isPublished) {
      tranings {
        id
        nameEN
        nameUKR
        contentEN
        contentUKR
        startTitleEN
        startTitleUKR
        endTitleEN
        endTitleUKR
        photo
        startVideo
        endVideo
        isPublished
      }
      total
    }
  }
`;

/**
 * __useGetTraningsQuery__
 *
 * To run a query within a React component, call `useGetTraningsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTraningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTraningsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetTraningsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTraningsQuery,
    GetTraningsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTraningsQuery, GetTraningsQueryVariables>(
    GetTraningsDocument,
    options
  );
}
export function useGetTraningsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTraningsQuery,
    GetTraningsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTraningsQuery, GetTraningsQueryVariables>(
    GetTraningsDocument,
    options
  );
}
export type GetTraningsQueryHookResult = ReturnType<typeof useGetTraningsQuery>;
export type GetTraningsLazyQueryHookResult = ReturnType<
  typeof useGetTraningsLazyQuery
>;
export type GetTraningsQueryResult = Apollo.QueryResult<
  GetTraningsQuery,
  GetTraningsQueryVariables
>;
export const GetTraningDocument = gql`
  query getTraning($id: String!) {
    getTraning(id: $id) {
      id
      nameEN
      nameUKR
      contentEN
      contentUKR
      startTitleEN
      startTitleUKR
      endTitleEN
      endTitleUKR
      photo
      startVideo
      endVideo
      exercises {
        id
        contentEN
        contentUKR
        video
        pauseTime
        type
      }
      isPublished
    }
  }
`;

/**
 * __useGetTraningQuery__
 *
 * To run a query within a React component, call `useGetTraningQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTraningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTraningQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTraningQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTraningQuery,
    GetTraningQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTraningQuery, GetTraningQueryVariables>(
    GetTraningDocument,
    options
  );
}
export function useGetTraningLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTraningQuery,
    GetTraningQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTraningQuery, GetTraningQueryVariables>(
    GetTraningDocument,
    options
  );
}
export type GetTraningQueryHookResult = ReturnType<typeof useGetTraningQuery>;
export type GetTraningLazyQueryHookResult = ReturnType<
  typeof useGetTraningLazyQuery
>;
export type GetTraningQueryResult = Apollo.QueryResult<
  GetTraningQuery,
  GetTraningQueryVariables
>;
export const GetUserInfoDocument = gql`
  query getUserInfo {
    getUserInfo {
      id
      firstName
      lastName
      phone
      email
      photo
      role {
        role
      }
      job {
        name
      }
      status {
        status
      }
      userPermissions
    }
  }
`;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserInfoQuery,
    GetUserInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(
    GetUserInfoDocument,
    options
  );
}
export function useGetUserInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserInfoQuery,
    GetUserInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(
    GetUserInfoDocument,
    options
  );
}
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<
  typeof useGetUserInfoLazyQuery
>;
export type GetUserInfoQueryResult = Apollo.QueryResult<
  GetUserInfoQuery,
  GetUserInfoQueryVariables
>;
export const GetUserInfoLightDocument = gql`
  query getUserInfoLight {
    getUserInfo {
      id
      firstName
      lastName
      photo
    }
  }
`;

/**
 * __useGetUserInfoLightQuery__
 *
 * To run a query within a React component, call `useGetUserInfoLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoLightQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoLightQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserInfoLightQuery,
    GetUserInfoLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserInfoLightQuery, GetUserInfoLightQueryVariables>(
    GetUserInfoLightDocument,
    options
  );
}
export function useGetUserInfoLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserInfoLightQuery,
    GetUserInfoLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserInfoLightQuery,
    GetUserInfoLightQueryVariables
  >(GetUserInfoLightDocument, options);
}
export type GetUserInfoLightQueryHookResult = ReturnType<
  typeof useGetUserInfoLightQuery
>;
export type GetUserInfoLightLazyQueryHookResult = ReturnType<
  typeof useGetUserInfoLightLazyQuery
>;
export type GetUserInfoLightQueryResult = Apollo.QueryResult<
  GetUserInfoLightQuery,
  GetUserInfoLightQueryVariables
>;
export const GetPmForDropDownDocument = gql`
  query getPmForDropDown($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        firstName
        lastName
        photo
        job {
          name
        }
      }
      total
    }
  }
`;

/**
 * __useGetPmForDropDownQuery__
 *
 * To run a query within a React component, call `useGetPmForDropDownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPmForDropDownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPmForDropDownQuery({
 *   variables: {
 *      getUsersData: // value for 'getUsersData'
 *   },
 * });
 */
export function useGetPmForDropDownQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPmForDropDownQuery,
    GetPmForDropDownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPmForDropDownQuery, GetPmForDropDownQueryVariables>(
    GetPmForDropDownDocument,
    options
  );
}
export function useGetPmForDropDownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPmForDropDownQuery,
    GetPmForDropDownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPmForDropDownQuery,
    GetPmForDropDownQueryVariables
  >(GetPmForDropDownDocument, options);
}
export type GetPmForDropDownQueryHookResult = ReturnType<
  typeof useGetPmForDropDownQuery
>;
export type GetPmForDropDownLazyQueryHookResult = ReturnType<
  typeof useGetPmForDropDownLazyQuery
>;
export type GetPmForDropDownQueryResult = Apollo.QueryResult<
  GetPmForDropDownQuery,
  GetPmForDropDownQueryVariables
>;
export const GetUsersDocument = gql`
  query getUsers($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        firstName
        lastName
        middleName
        phone
        birthday
        email
        address
        addressReal
        photo
        instagram
        telegram
        contract
        drfo
        nda
        inn
        diploma
        passport
        salary
        creditCard
        bonuses
        role {
          id
          role
          displayName
        }
        status {
          id
          displayName
          status
        }
        position {
          id
          position
          displayName
        }
        stats {
          flex
          kpi
          levelValue
        }
        projects {
          id
          jiraId
          name
          links {
            id
            title
            link
            login
            password
          }
          status {
            displayName
          }
        }
        groups {
          id
          name
          members {
            id
            firstName
          }
        }
        friends {
          id
          firstName
          lastName
          middleName
        }
        createdBy {
          firstName
          lastName
          phone
          id
          email
        }
        job {
          id
          name
        }
        updatedDate
      }
      total
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      getUsersData: // value for 'getUsersData'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>;
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>;
export const GetUsersForEmployeesReportDocument = gql`
  query getUsersForEmployeesReport($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        lastName
        firstName
        city {
          name
        }
        job {
          name
        }
        department {
          name
        }
        isRemote
        agreement {
          name
        }
      }
      total
    }
  }
`;

/**
 * __useGetUsersForEmployeesReportQuery__
 *
 * To run a query within a React component, call `useGetUsersForEmployeesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForEmployeesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForEmployeesReportQuery({
 *   variables: {
 *      getUsersData: // value for 'getUsersData'
 *   },
 * });
 */
export function useGetUsersForEmployeesReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersForEmployeesReportQuery,
    GetUsersForEmployeesReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersForEmployeesReportQuery,
    GetUsersForEmployeesReportQueryVariables
  >(GetUsersForEmployeesReportDocument, options);
}
export function useGetUsersForEmployeesReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersForEmployeesReportQuery,
    GetUsersForEmployeesReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersForEmployeesReportQuery,
    GetUsersForEmployeesReportQueryVariables
  >(GetUsersForEmployeesReportDocument, options);
}
export type GetUsersForEmployeesReportQueryHookResult = ReturnType<
  typeof useGetUsersForEmployeesReportQuery
>;
export type GetUsersForEmployeesReportLazyQueryHookResult = ReturnType<
  typeof useGetUsersForEmployeesReportLazyQuery
>;
export type GetUsersForEmployeesReportQueryResult = Apollo.QueryResult<
  GetUsersForEmployeesReportQuery,
  GetUsersForEmployeesReportQueryVariables
>;
export const GetUsersParticipantsDocument = gql`
  query getUsersParticipants($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        photo
        lastName
        firstName
      }
    }
  }
`;

/**
 * __useGetUsersParticipantsQuery__
 *
 * To run a query within a React component, call `useGetUsersParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersParticipantsQuery({
 *   variables: {
 *      getUsersData: // value for 'getUsersData'
 *   },
 * });
 */
export function useGetUsersParticipantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersParticipantsQuery,
    GetUsersParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersParticipantsQuery,
    GetUsersParticipantsQueryVariables
  >(GetUsersParticipantsDocument, options);
}
export function useGetUsersParticipantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersParticipantsQuery,
    GetUsersParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersParticipantsQuery,
    GetUsersParticipantsQueryVariables
  >(GetUsersParticipantsDocument, options);
}
export type GetUsersParticipantsQueryHookResult = ReturnType<
  typeof useGetUsersParticipantsQuery
>;
export type GetUsersParticipantsLazyQueryHookResult = ReturnType<
  typeof useGetUsersParticipantsLazyQuery
>;
export type GetUsersParticipantsQueryResult = Apollo.QueryResult<
  GetUsersParticipantsQuery,
  GetUsersParticipantsQueryVariables
>;
export const GetUsersForParticipantsModalLightDocument = gql`
  query getUsersForParticipantsModalLight($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        photo
        lastName
        firstName
      }
    }
  }
`;

/**
 * __useGetUsersForParticipantsModalLightQuery__
 *
 * To run a query within a React component, call `useGetUsersForParticipantsModalLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForParticipantsModalLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForParticipantsModalLightQuery({
 *   variables: {
 *      getUsersData: // value for 'getUsersData'
 *   },
 * });
 */
export function useGetUsersForParticipantsModalLightQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersForParticipantsModalLightQuery,
    GetUsersForParticipantsModalLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersForParticipantsModalLightQuery,
    GetUsersForParticipantsModalLightQueryVariables
  >(GetUsersForParticipantsModalLightDocument, options);
}
export function useGetUsersForParticipantsModalLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersForParticipantsModalLightQuery,
    GetUsersForParticipantsModalLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersForParticipantsModalLightQuery,
    GetUsersForParticipantsModalLightQueryVariables
  >(GetUsersForParticipantsModalLightDocument, options);
}
export type GetUsersForParticipantsModalLightQueryHookResult = ReturnType<
  typeof useGetUsersForParticipantsModalLightQuery
>;
export type GetUsersForParticipantsModalLightLazyQueryHookResult = ReturnType<
  typeof useGetUsersForParticipantsModalLightLazyQuery
>;
export type GetUsersForParticipantsModalLightQueryResult = Apollo.QueryResult<
  GetUsersForParticipantsModalLightQuery,
  GetUsersForParticipantsModalLightQueryVariables
>;
export const GetUsersForDropdownDocument = gql`
  query getUsersForDropdown($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        lastName
        firstName
        role {
          id
          role
          displayName
        }
      }
    }
  }
`;

/**
 * __useGetUsersForDropdownQuery__
 *
 * To run a query within a React component, call `useGetUsersForDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForDropdownQuery({
 *   variables: {
 *      getUsersData: // value for 'getUsersData'
 *   },
 * });
 */
export function useGetUsersForDropdownQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersForDropdownQuery,
    GetUsersForDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersForDropdownQuery,
    GetUsersForDropdownQueryVariables
  >(GetUsersForDropdownDocument, options);
}
export function useGetUsersForDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersForDropdownQuery,
    GetUsersForDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersForDropdownQuery,
    GetUsersForDropdownQueryVariables
  >(GetUsersForDropdownDocument, options);
}
export type GetUsersForDropdownQueryHookResult = ReturnType<
  typeof useGetUsersForDropdownQuery
>;
export type GetUsersForDropdownLazyQueryHookResult = ReturnType<
  typeof useGetUsersForDropdownLazyQuery
>;
export type GetUsersForDropdownQueryResult = Apollo.QueryResult<
  GetUsersForDropdownQuery,
  GetUsersForDropdownQueryVariables
>;
export const GetUsersWithoutApplicationMainDocument = gql`
  query getUsersWithoutApplicationMain(
    $applicationMainId: String!
    $limit: Int
    $skip: Int
  ) {
    getUsersWithoutApplicationMain(
      applicationMainId: $applicationMainId
      limit: $limit
      skip: $skip
    ) {
      users {
        id
        photo
        lastName
        firstName
      }
      total
    }
  }
`;

/**
 * __useGetUsersWithoutApplicationMainQuery__
 *
 * To run a query within a React component, call `useGetUsersWithoutApplicationMainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersWithoutApplicationMainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersWithoutApplicationMainQuery({
 *   variables: {
 *      applicationMainId: // value for 'applicationMainId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetUsersWithoutApplicationMainQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersWithoutApplicationMainQuery,
    GetUsersWithoutApplicationMainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersWithoutApplicationMainQuery,
    GetUsersWithoutApplicationMainQueryVariables
  >(GetUsersWithoutApplicationMainDocument, options);
}
export function useGetUsersWithoutApplicationMainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersWithoutApplicationMainQuery,
    GetUsersWithoutApplicationMainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersWithoutApplicationMainQuery,
    GetUsersWithoutApplicationMainQueryVariables
  >(GetUsersWithoutApplicationMainDocument, options);
}
export type GetUsersWithoutApplicationMainQueryHookResult = ReturnType<
  typeof useGetUsersWithoutApplicationMainQuery
>;
export type GetUsersWithoutApplicationMainLazyQueryHookResult = ReturnType<
  typeof useGetUsersWithoutApplicationMainLazyQuery
>;
export type GetUsersWithoutApplicationMainQueryResult = Apollo.QueryResult<
  GetUsersWithoutApplicationMainQuery,
  GetUsersWithoutApplicationMainQueryVariables
>;
export const GetUserDocument = gql`
  query getUser($userId: String!) {
    getUser(userId: $userId) {
      id
      oneCId
      trackerId
      jiraId
      firstName
      lastName
      middleName
      phone
      birthday
      email
      address
      addressReal
      diploma
      contractType
      passport
      idCard
      excerpt
      photo
      instagram
      telegram
      contract
      contractType
      drfo
      nda
      inn
      diploma
      passport
      bonuses
      salary
      creditCard
      rate
      previousSalary
      promotionDate
      promotionReason
      loadPercent
      hireDate
      isRemote
      agreementType {
        id
        type
        displayName
      }
      agreement {
        id
        name
      }
      role {
        id
        role
        displayName
      }
      status {
        id
        displayName
        status
      }
      position {
        id
        position
        displayName
      }
      job {
        id
        name
      }
      projects {
        id
        name
        status {
          id
        }
        stages
      }
      groups {
        id
        name
      }
      city {
        id
        name
      }
      department {
        id
        name
      }
      trackerContracts {
        id
        project {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetUsersWithoutProjectDocument = gql`
  query getUsersWithoutProject($projectId: String!, $limit: Int, $skip: Int) {
    getUsersWithoutProject(projectId: $projectId, limit: $limit, skip: $skip) {
      total
      users {
        id
        firstName
        lastName
        photo
      }
    }
  }
`;

/**
 * __useGetUsersWithoutProjectQuery__
 *
 * To run a query within a React component, call `useGetUsersWithoutProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersWithoutProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersWithoutProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetUsersWithoutProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersWithoutProjectQuery,
    GetUsersWithoutProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersWithoutProjectQuery,
    GetUsersWithoutProjectQueryVariables
  >(GetUsersWithoutProjectDocument, options);
}
export function useGetUsersWithoutProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersWithoutProjectQuery,
    GetUsersWithoutProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersWithoutProjectQuery,
    GetUsersWithoutProjectQueryVariables
  >(GetUsersWithoutProjectDocument, options);
}
export type GetUsersWithoutProjectQueryHookResult = ReturnType<
  typeof useGetUsersWithoutProjectQuery
>;
export type GetUsersWithoutProjectLazyQueryHookResult = ReturnType<
  typeof useGetUsersWithoutProjectLazyQuery
>;
export type GetUsersWithoutProjectQueryResult = Apollo.QueryResult<
  GetUsersWithoutProjectQuery,
  GetUsersWithoutProjectQueryVariables
>;
export const GetUserInfoPayrollDocument = gql`
  query getUserInfoPayroll($userId: String!) {
    getUser(userId: $userId) {
      salary
      job {
        id
        name
      }
      firstName
      lastName
    }
  }
`;

/**
 * __useGetUserInfoPayrollQuery__
 *
 * To run a query within a React component, call `useGetUserInfoPayrollQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoPayrollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoPayrollQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserInfoPayrollQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserInfoPayrollQuery,
    GetUserInfoPayrollQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserInfoPayrollQuery,
    GetUserInfoPayrollQueryVariables
  >(GetUserInfoPayrollDocument, options);
}
export function useGetUserInfoPayrollLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserInfoPayrollQuery,
    GetUserInfoPayrollQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserInfoPayrollQuery,
    GetUserInfoPayrollQueryVariables
  >(GetUserInfoPayrollDocument, options);
}
export type GetUserInfoPayrollQueryHookResult = ReturnType<
  typeof useGetUserInfoPayrollQuery
>;
export type GetUserInfoPayrollLazyQueryHookResult = ReturnType<
  typeof useGetUserInfoPayrollLazyQuery
>;
export type GetUserInfoPayrollQueryResult = Apollo.QueryResult<
  GetUserInfoPayrollQuery,
  GetUserInfoPayrollQueryVariables
>;
export const GetUserStatusesDocument = gql`
  query getUserStatuses {
    getUserStatuses {
      id
      status
      displayName
    }
  }
`;

/**
 * __useGetUserStatusesQuery__
 *
 * To run a query within a React component, call `useGetUserStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserStatusesQuery,
    GetUserStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserStatusesQuery, GetUserStatusesQueryVariables>(
    GetUserStatusesDocument,
    options
  );
}
export function useGetUserStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserStatusesQuery,
    GetUserStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserStatusesQuery,
    GetUserStatusesQueryVariables
  >(GetUserStatusesDocument, options);
}
export type GetUserStatusesQueryHookResult = ReturnType<
  typeof useGetUserStatusesQuery
>;
export type GetUserStatusesLazyQueryHookResult = ReturnType<
  typeof useGetUserStatusesLazyQuery
>;
export type GetUserStatusesQueryResult = Apollo.QueryResult<
  GetUserStatusesQuery,
  GetUserStatusesQueryVariables
>;
export const GetUserRolesDocument = gql`
  query getUserRoles {
    getUserRoles {
      id
      role
      displayName
    }
  }
`;

/**
 * __useGetUserRolesQuery__
 *
 * To run a query within a React component, call `useGetUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserRolesQuery,
    GetUserRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(
    GetUserRolesDocument,
    options
  );
}
export function useGetUserRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRolesQuery,
    GetUserRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(
    GetUserRolesDocument,
    options
  );
}
export type GetUserRolesQueryHookResult = ReturnType<
  typeof useGetUserRolesQuery
>;
export type GetUserRolesLazyQueryHookResult = ReturnType<
  typeof useGetUserRolesLazyQuery
>;
export type GetUserRolesQueryResult = Apollo.QueryResult<
  GetUserRolesQuery,
  GetUserRolesQueryVariables
>;
export const GetUserPositionsDocument = gql`
  query getUserPositions {
    getUserPositions {
      id
      position
      displayName
    }
  }
`;

/**
 * __useGetUserPositionsQuery__
 *
 * To run a query within a React component, call `useGetUserPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPositionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserPositionsQuery,
    GetUserPositionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserPositionsQuery, GetUserPositionsQueryVariables>(
    GetUserPositionsDocument,
    options
  );
}
export function useGetUserPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPositionsQuery,
    GetUserPositionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserPositionsQuery,
    GetUserPositionsQueryVariables
  >(GetUserPositionsDocument, options);
}
export type GetUserPositionsQueryHookResult = ReturnType<
  typeof useGetUserPositionsQuery
>;
export type GetUserPositionsLazyQueryHookResult = ReturnType<
  typeof useGetUserPositionsLazyQuery
>;
export type GetUserPositionsQueryResult = Apollo.QueryResult<
  GetUserPositionsQuery,
  GetUserPositionsQueryVariables
>;
export const GetUserOptionFieldsDocument = gql`
  query getUserOptionFields {
    getUserOptionFields {
      roles {
        id
        role
        displayName
      }
      statuses {
        id
        status
        displayName
      }
      jobs {
        id
        name
      }
      positions {
        id
        position
        displayName
      }
      cities {
        id
        name
      }
      agreements {
        id
        name
      }
      departments {
        id
        name
      }
      agreementType {
        id
        type
        displayName
      }
    }
  }
`;

/**
 * __useGetUserOptionFieldsQuery__
 *
 * To run a query within a React component, call `useGetUserOptionFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOptionFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOptionFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserOptionFieldsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserOptionFieldsQuery,
    GetUserOptionFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserOptionFieldsQuery,
    GetUserOptionFieldsQueryVariables
  >(GetUserOptionFieldsDocument, options);
}
export function useGetUserOptionFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserOptionFieldsQuery,
    GetUserOptionFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserOptionFieldsQuery,
    GetUserOptionFieldsQueryVariables
  >(GetUserOptionFieldsDocument, options);
}
export type GetUserOptionFieldsQueryHookResult = ReturnType<
  typeof useGetUserOptionFieldsQuery
>;
export type GetUserOptionFieldsLazyQueryHookResult = ReturnType<
  typeof useGetUserOptionFieldsLazyQuery
>;
export type GetUserOptionFieldsQueryResult = Apollo.QueryResult<
  GetUserOptionFieldsQuery,
  GetUserOptionFieldsQueryVariables
>;
export const GetUserOptionFieldsForCreateVacancyReportDocument = gql`
  query getUserOptionFieldsForCreateVacancyReport {
    getUserOptionFields {
      jobs {
        id
        name
      }
      positions {
        id
        position
        displayName
      }
    }
  }
`;

/**
 * __useGetUserOptionFieldsForCreateVacancyReportQuery__
 *
 * To run a query within a React component, call `useGetUserOptionFieldsForCreateVacancyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOptionFieldsForCreateVacancyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOptionFieldsForCreateVacancyReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserOptionFieldsForCreateVacancyReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserOptionFieldsForCreateVacancyReportQuery,
    GetUserOptionFieldsForCreateVacancyReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserOptionFieldsForCreateVacancyReportQuery,
    GetUserOptionFieldsForCreateVacancyReportQueryVariables
  >(GetUserOptionFieldsForCreateVacancyReportDocument, options);
}
export function useGetUserOptionFieldsForCreateVacancyReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserOptionFieldsForCreateVacancyReportQuery,
    GetUserOptionFieldsForCreateVacancyReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserOptionFieldsForCreateVacancyReportQuery,
    GetUserOptionFieldsForCreateVacancyReportQueryVariables
  >(GetUserOptionFieldsForCreateVacancyReportDocument, options);
}
export type GetUserOptionFieldsForCreateVacancyReportQueryHookResult =
  ReturnType<typeof useGetUserOptionFieldsForCreateVacancyReportQuery>;
export type GetUserOptionFieldsForCreateVacancyReportLazyQueryHookResult =
  ReturnType<typeof useGetUserOptionFieldsForCreateVacancyReportLazyQuery>;
export type GetUserOptionFieldsForCreateVacancyReportQueryResult =
  Apollo.QueryResult<
    GetUserOptionFieldsForCreateVacancyReportQuery,
    GetUserOptionFieldsForCreateVacancyReportQueryVariables
  >;
export const GetUsersCountLightDocument = gql`
  query getUsersCountLight {
    getUsersCountLight {
      status
      count
    }
  }
`;

/**
 * __useGetUsersCountLightQuery__
 *
 * To run a query within a React component, call `useGetUsersCountLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersCountLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersCountLightQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersCountLightQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersCountLightQuery,
    GetUsersCountLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersCountLightQuery,
    GetUsersCountLightQueryVariables
  >(GetUsersCountLightDocument, options);
}
export function useGetUsersCountLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersCountLightQuery,
    GetUsersCountLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersCountLightQuery,
    GetUsersCountLightQueryVariables
  >(GetUsersCountLightDocument, options);
}
export type GetUsersCountLightQueryHookResult = ReturnType<
  typeof useGetUsersCountLightQuery
>;
export type GetUsersCountLightLazyQueryHookResult = ReturnType<
  typeof useGetUsersCountLightLazyQuery
>;
export type GetUsersCountLightQueryResult = Apollo.QueryResult<
  GetUsersCountLightQuery,
  GetUsersCountLightQueryVariables
>;
export const GetUsersAppDocument = gql`
  query getUsersApp($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        firstName
        lastName
        email
        phone
        birthday
        country {
          id
          nameUKR
        }
        role {
          id
          role
          displayName
        }
      }
      total
    }
  }
`;

/**
 * __useGetUsersAppQuery__
 *
 * To run a query within a React component, call `useGetUsersAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersAppQuery({
 *   variables: {
 *      getUsersData: // value for 'getUsersData'
 *   },
 * });
 */
export function useGetUsersAppQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersAppQuery,
    GetUsersAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersAppQuery, GetUsersAppQueryVariables>(
    GetUsersAppDocument,
    options
  );
}
export function useGetUsersAppLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersAppQuery,
    GetUsersAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersAppQuery, GetUsersAppQueryVariables>(
    GetUsersAppDocument,
    options
  );
}
export type GetUsersAppQueryHookResult = ReturnType<typeof useGetUsersAppQuery>;
export type GetUsersAppLazyQueryHookResult = ReturnType<
  typeof useGetUsersAppLazyQuery
>;
export type GetUsersAppQueryResult = Apollo.QueryResult<
  GetUsersAppQuery,
  GetUsersAppQueryVariables
>;
export const GetUsersByApplicationIdDocument = gql`
  query getUsersByApplicationId(
    $applicationId: String!
    $limit: Int
    $skip: Int
  ) {
    getUsersByApplicationId(
      applicationId: $applicationId
      limit: $limit
      skip: $skip
    ) {
      users {
        id
        firstName
        lastName
        phone
        instagram
        telegram
      }
      total
    }
  }
`;

/**
 * __useGetUsersByApplicationIdQuery__
 *
 * To run a query within a React component, call `useGetUsersByApplicationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByApplicationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByApplicationIdQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetUsersByApplicationIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersByApplicationIdQuery,
    GetUsersByApplicationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersByApplicationIdQuery,
    GetUsersByApplicationIdQueryVariables
  >(GetUsersByApplicationIdDocument, options);
}
export function useGetUsersByApplicationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersByApplicationIdQuery,
    GetUsersByApplicationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersByApplicationIdQuery,
    GetUsersByApplicationIdQueryVariables
  >(GetUsersByApplicationIdDocument, options);
}
export type GetUsersByApplicationIdQueryHookResult = ReturnType<
  typeof useGetUsersByApplicationIdQuery
>;
export type GetUsersByApplicationIdLazyQueryHookResult = ReturnType<
  typeof useGetUsersByApplicationIdLazyQuery
>;
export type GetUsersByApplicationIdQueryResult = Apollo.QueryResult<
  GetUsersByApplicationIdQuery,
  GetUsersByApplicationIdQueryVariables
>;
export const GetUserAppDocument = gql`
  query getUserApp($userId: String!) {
    getUser(userId: $userId) {
      id
      firstName
      lastName
      birthday
      phone
      email
      photo
      role {
        id
        role
        displayName
      }
      countryId
      schoolRegionId
      schoolClassroomId
      schoolCityId
      schoolId
      schools {
        id
        name
        isPersonal
      }
      applicationId
      schoolStatus
      status {
        id
        status
      }
      subjects {
        id
        name
        applicationId
        application {
          id
          name
        }
      }
      application {
        id
        name
      }
      createdDate
    }
  }
`;

/**
 * __useGetUserAppQuery__
 *
 * To run a query within a React component, call `useGetUserAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAppQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserAppQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAppQuery,
    GetUserAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserAppQuery, GetUserAppQueryVariables>(
    GetUserAppDocument,
    options
  );
}
export function useGetUserAppLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAppQuery,
    GetUserAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserAppQuery, GetUserAppQueryVariables>(
    GetUserAppDocument,
    options
  );
}
export type GetUserAppQueryHookResult = ReturnType<typeof useGetUserAppQuery>;
export type GetUserAppLazyQueryHookResult = ReturnType<
  typeof useGetUserAppLazyQuery
>;
export type GetUserAppQueryResult = Apollo.QueryResult<
  GetUserAppQuery,
  GetUserAppQueryVariables
>;
export const GetArBookUsersCountLightDocument = gql`
  query getARBookUsersCountLight {
    getARBookUsersCountLight {
      role
      count
    }
  }
`;

/**
 * __useGetArBookUsersCountLightQuery__
 *
 * To run a query within a React component, call `useGetArBookUsersCountLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArBookUsersCountLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArBookUsersCountLightQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArBookUsersCountLightQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetArBookUsersCountLightQuery,
    GetArBookUsersCountLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetArBookUsersCountLightQuery,
    GetArBookUsersCountLightQueryVariables
  >(GetArBookUsersCountLightDocument, options);
}
export function useGetArBookUsersCountLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArBookUsersCountLightQuery,
    GetArBookUsersCountLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetArBookUsersCountLightQuery,
    GetArBookUsersCountLightQueryVariables
  >(GetArBookUsersCountLightDocument, options);
}
export type GetArBookUsersCountLightQueryHookResult = ReturnType<
  typeof useGetArBookUsersCountLightQuery
>;
export type GetArBookUsersCountLightLazyQueryHookResult = ReturnType<
  typeof useGetArBookUsersCountLightLazyQuery
>;
export type GetArBookUsersCountLightQueryResult = Apollo.QueryResult<
  GetArBookUsersCountLightQuery,
  GetArBookUsersCountLightQueryVariables
>;
export const GetUserGroupsDocument = gql`
  query getUserGroups {
    getUserGroups {
      id
      name
    }
  }
`;

/**
 * __useGetUserGroupsQuery__
 *
 * To run a query within a React component, call `useGetUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserGroupsQuery,
    GetUserGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserGroupsQuery, GetUserGroupsQueryVariables>(
    GetUserGroupsDocument,
    options
  );
}
export function useGetUserGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserGroupsQuery,
    GetUserGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserGroupsQuery, GetUserGroupsQueryVariables>(
    GetUserGroupsDocument,
    options
  );
}
export type GetUserGroupsQueryHookResult = ReturnType<
  typeof useGetUserGroupsQuery
>;
export type GetUserGroupsLazyQueryHookResult = ReturnType<
  typeof useGetUserGroupsLazyQuery
>;
export type GetUserGroupsQueryResult = Apollo.QueryResult<
  GetUserGroupsQuery,
  GetUserGroupsQueryVariables
>;
export const GetUserFeatureFlagsByUserIdDocument = gql`
  query GetUserFeatureFlagsByUserId($userId: UUID!) {
    getUserFeatureFlagsByUserId(userId: $userId) {
      id
      name
      flag
      isGranted
    }
  }
`;

/**
 * __useGetUserFeatureFlagsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserFeatureFlagsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFeatureFlagsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFeatureFlagsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserFeatureFlagsByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserFeatureFlagsByUserIdQuery,
    GetUserFeatureFlagsByUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserFeatureFlagsByUserIdQuery,
    GetUserFeatureFlagsByUserIdQueryVariables
  >(GetUserFeatureFlagsByUserIdDocument, options);
}
export function useGetUserFeatureFlagsByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserFeatureFlagsByUserIdQuery,
    GetUserFeatureFlagsByUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserFeatureFlagsByUserIdQuery,
    GetUserFeatureFlagsByUserIdQueryVariables
  >(GetUserFeatureFlagsByUserIdDocument, options);
}
export type GetUserFeatureFlagsByUserIdQueryHookResult = ReturnType<
  typeof useGetUserFeatureFlagsByUserIdQuery
>;
export type GetUserFeatureFlagsByUserIdLazyQueryHookResult = ReturnType<
  typeof useGetUserFeatureFlagsByUserIdLazyQuery
>;
export type GetUserFeatureFlagsByUserIdQueryResult = Apollo.QueryResult<
  GetUserFeatureFlagsByUserIdQuery,
  GetUserFeatureFlagsByUserIdQueryVariables
>;
export const GetUserFeatureFlagsBySchoolIdDocument = gql`
  query GetUserFeatureFlagsBySchoolId($schoolId: UUID!) {
    getUserFeatureFlagsBySchoolId(schoolId: $schoolId) {
      id
      name
      flag
      isGranted
    }
  }
`;

/**
 * __useGetUserFeatureFlagsBySchoolIdQuery__
 *
 * To run a query within a React component, call `useGetUserFeatureFlagsBySchoolIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFeatureFlagsBySchoolIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFeatureFlagsBySchoolIdQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetUserFeatureFlagsBySchoolIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserFeatureFlagsBySchoolIdQuery,
    GetUserFeatureFlagsBySchoolIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserFeatureFlagsBySchoolIdQuery,
    GetUserFeatureFlagsBySchoolIdQueryVariables
  >(GetUserFeatureFlagsBySchoolIdDocument, options);
}
export function useGetUserFeatureFlagsBySchoolIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserFeatureFlagsBySchoolIdQuery,
    GetUserFeatureFlagsBySchoolIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserFeatureFlagsBySchoolIdQuery,
    GetUserFeatureFlagsBySchoolIdQueryVariables
  >(GetUserFeatureFlagsBySchoolIdDocument, options);
}
export type GetUserFeatureFlagsBySchoolIdQueryHookResult = ReturnType<
  typeof useGetUserFeatureFlagsBySchoolIdQuery
>;
export type GetUserFeatureFlagsBySchoolIdLazyQueryHookResult = ReturnType<
  typeof useGetUserFeatureFlagsBySchoolIdLazyQuery
>;
export type GetUserFeatureFlagsBySchoolIdQueryResult = Apollo.QueryResult<
  GetUserFeatureFlagsBySchoolIdQuery,
  GetUserFeatureFlagsBySchoolIdQueryVariables
>;
export const GetWhiteLabelCollectionsDocument = gql`
  query getWhiteLabelCollections {
    getWhiteLabelCollections {
      id
      image
      systemLogo
      isPublished
      name
      description
      welcomeHtml
    }
  }
`;

/**
 * __useGetWhiteLabelCollectionsQuery__
 *
 * To run a query within a React component, call `useGetWhiteLabelCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWhiteLabelCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWhiteLabelCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWhiteLabelCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWhiteLabelCollectionsQuery,
    GetWhiteLabelCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWhiteLabelCollectionsQuery,
    GetWhiteLabelCollectionsQueryVariables
  >(GetWhiteLabelCollectionsDocument, options);
}
export function useGetWhiteLabelCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWhiteLabelCollectionsQuery,
    GetWhiteLabelCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWhiteLabelCollectionsQuery,
    GetWhiteLabelCollectionsQueryVariables
  >(GetWhiteLabelCollectionsDocument, options);
}
export type GetWhiteLabelCollectionsQueryHookResult = ReturnType<
  typeof useGetWhiteLabelCollectionsQuery
>;
export type GetWhiteLabelCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetWhiteLabelCollectionsLazyQuery
>;
export type GetWhiteLabelCollectionsQueryResult = Apollo.QueryResult<
  GetWhiteLabelCollectionsQuery,
  GetWhiteLabelCollectionsQueryVariables
>;
export const GetWhiteLabelCollectionFullDocument = gql`
  query GetWhiteLabelCollectionFull($getWhiteLabelCollectionId: UUID!) {
    getWhiteLabelCollection(id: $getWhiteLabelCollectionId) {
      id
      name
      description
      isPublished
      image
      schoolLogo
      systemLogo
      systemLogoLarge
      banner
      textColor
      backgroundColor
      slug
      welcomeHtml
    }
  }
`;

/**
 * __useGetWhiteLabelCollectionFullQuery__
 *
 * To run a query within a React component, call `useGetWhiteLabelCollectionFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWhiteLabelCollectionFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWhiteLabelCollectionFullQuery({
 *   variables: {
 *      getWhiteLabelCollectionId: // value for 'getWhiteLabelCollectionId'
 *   },
 * });
 */
export function useGetWhiteLabelCollectionFullQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWhiteLabelCollectionFullQuery,
    GetWhiteLabelCollectionFullQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWhiteLabelCollectionFullQuery,
    GetWhiteLabelCollectionFullQueryVariables
  >(GetWhiteLabelCollectionFullDocument, options);
}
export function useGetWhiteLabelCollectionFullLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWhiteLabelCollectionFullQuery,
    GetWhiteLabelCollectionFullQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWhiteLabelCollectionFullQuery,
    GetWhiteLabelCollectionFullQueryVariables
  >(GetWhiteLabelCollectionFullDocument, options);
}
export type GetWhiteLabelCollectionFullQueryHookResult = ReturnType<
  typeof useGetWhiteLabelCollectionFullQuery
>;
export type GetWhiteLabelCollectionFullLazyQueryHookResult = ReturnType<
  typeof useGetWhiteLabelCollectionFullLazyQuery
>;
export type GetWhiteLabelCollectionFullQueryResult = Apollo.QueryResult<
  GetWhiteLabelCollectionFullQuery,
  GetWhiteLabelCollectionFullQueryVariables
>;
export const GetWhiteLabelCollectionsForSchoolDocument = gql`
  query getWhiteLabelCollectionsForSchool(
    $filters: getWhiteLabelCollectionsFilters
  ) {
    getWhiteLabelCollections(filters: $filters) {
      id
      name
    }
  }
`;

/**
 * __useGetWhiteLabelCollectionsForSchoolQuery__
 *
 * To run a query within a React component, call `useGetWhiteLabelCollectionsForSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWhiteLabelCollectionsForSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWhiteLabelCollectionsForSchoolQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetWhiteLabelCollectionsForSchoolQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWhiteLabelCollectionsForSchoolQuery,
    GetWhiteLabelCollectionsForSchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWhiteLabelCollectionsForSchoolQuery,
    GetWhiteLabelCollectionsForSchoolQueryVariables
  >(GetWhiteLabelCollectionsForSchoolDocument, options);
}
export function useGetWhiteLabelCollectionsForSchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWhiteLabelCollectionsForSchoolQuery,
    GetWhiteLabelCollectionsForSchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWhiteLabelCollectionsForSchoolQuery,
    GetWhiteLabelCollectionsForSchoolQueryVariables
  >(GetWhiteLabelCollectionsForSchoolDocument, options);
}
export type GetWhiteLabelCollectionsForSchoolQueryHookResult = ReturnType<
  typeof useGetWhiteLabelCollectionsForSchoolQuery
>;
export type GetWhiteLabelCollectionsForSchoolLazyQueryHookResult = ReturnType<
  typeof useGetWhiteLabelCollectionsForSchoolLazyQuery
>;
export type GetWhiteLabelCollectionsForSchoolQueryResult = Apollo.QueryResult<
  GetWhiteLabelCollectionsForSchoolQuery,
  GetWhiteLabelCollectionsForSchoolQueryVariables
>;
export const GetKanbanWorkflowStepDocument = gql`
  query getKanbanWorkflowStep($id: String!) {
    getKanbanWorkflowStep(id: $id) {
      id
      name
      taskStatusId
      isBase
    }
  }
`;

/**
 * __useGetKanbanWorkflowStepQuery__
 *
 * To run a query within a React component, call `useGetKanbanWorkflowStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKanbanWorkflowStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKanbanWorkflowStepQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetKanbanWorkflowStepQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetKanbanWorkflowStepQuery,
    GetKanbanWorkflowStepQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetKanbanWorkflowStepQuery,
    GetKanbanWorkflowStepQueryVariables
  >(GetKanbanWorkflowStepDocument, options);
}
export function useGetKanbanWorkflowStepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKanbanWorkflowStepQuery,
    GetKanbanWorkflowStepQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetKanbanWorkflowStepQuery,
    GetKanbanWorkflowStepQueryVariables
  >(GetKanbanWorkflowStepDocument, options);
}
export type GetKanbanWorkflowStepQueryHookResult = ReturnType<
  typeof useGetKanbanWorkflowStepQuery
>;
export type GetKanbanWorkflowStepLazyQueryHookResult = ReturnType<
  typeof useGetKanbanWorkflowStepLazyQuery
>;
export type GetKanbanWorkflowStepQueryResult = Apollo.QueryResult<
  GetKanbanWorkflowStepQuery,
  GetKanbanWorkflowStepQueryVariables
>;
export const GetBaseKanbanWorkflowStepsDocument = gql`
  query getBaseKanbanWorkflowSteps {
    getBaseKanbanWorkflowSteps {
      id
      name
      taskStatusId
    }
  }
`;

/**
 * __useGetBaseKanbanWorkflowStepsQuery__
 *
 * To run a query within a React component, call `useGetBaseKanbanWorkflowStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseKanbanWorkflowStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseKanbanWorkflowStepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseKanbanWorkflowStepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBaseKanbanWorkflowStepsQuery,
    GetBaseKanbanWorkflowStepsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBaseKanbanWorkflowStepsQuery,
    GetBaseKanbanWorkflowStepsQueryVariables
  >(GetBaseKanbanWorkflowStepsDocument, options);
}
export function useGetBaseKanbanWorkflowStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaseKanbanWorkflowStepsQuery,
    GetBaseKanbanWorkflowStepsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBaseKanbanWorkflowStepsQuery,
    GetBaseKanbanWorkflowStepsQueryVariables
  >(GetBaseKanbanWorkflowStepsDocument, options);
}
export type GetBaseKanbanWorkflowStepsQueryHookResult = ReturnType<
  typeof useGetBaseKanbanWorkflowStepsQuery
>;
export type GetBaseKanbanWorkflowStepsLazyQueryHookResult = ReturnType<
  typeof useGetBaseKanbanWorkflowStepsLazyQuery
>;
export type GetBaseKanbanWorkflowStepsQueryResult = Apollo.QueryResult<
  GetBaseKanbanWorkflowStepsQuery,
  GetBaseKanbanWorkflowStepsQueryVariables
>;
export const GetKanbanWorkflowStepsByProjectDocument = gql`
  query getKanbanWorkflowStepsByProject($projectId: String!) {
    getKanbanWorkflowStepsByProject(projectId: $projectId) {
      id
      name
      taskStatusId
      isBase
      order
    }
  }
`;

/**
 * __useGetKanbanWorkflowStepsByProjectQuery__
 *
 * To run a query within a React component, call `useGetKanbanWorkflowStepsByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKanbanWorkflowStepsByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKanbanWorkflowStepsByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetKanbanWorkflowStepsByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetKanbanWorkflowStepsByProjectQuery,
    GetKanbanWorkflowStepsByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetKanbanWorkflowStepsByProjectQuery,
    GetKanbanWorkflowStepsByProjectQueryVariables
  >(GetKanbanWorkflowStepsByProjectDocument, options);
}
export function useGetKanbanWorkflowStepsByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKanbanWorkflowStepsByProjectQuery,
    GetKanbanWorkflowStepsByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetKanbanWorkflowStepsByProjectQuery,
    GetKanbanWorkflowStepsByProjectQueryVariables
  >(GetKanbanWorkflowStepsByProjectDocument, options);
}
export type GetKanbanWorkflowStepsByProjectQueryHookResult = ReturnType<
  typeof useGetKanbanWorkflowStepsByProjectQuery
>;
export type GetKanbanWorkflowStepsByProjectLazyQueryHookResult = ReturnType<
  typeof useGetKanbanWorkflowStepsByProjectLazyQuery
>;
export type GetKanbanWorkflowStepsByProjectQueryResult = Apollo.QueryResult<
  GetKanbanWorkflowStepsByProjectQuery,
  GetKanbanWorkflowStepsByProjectQueryVariables
>;
