import styled from "styled-components";
import {
  Button,
  Checkbox,
  Container,
  makeStyles,
  MenuItem,
  TextField,
  Box,
  Switch,
  createTheme,
  FormControl,
  Select,
} from "@material-ui/core";
import amber from "@material-ui/core/colors/amber";
import { Link, NavLink } from "react-router-dom";

export const primary = "#FFB61D";
export const lightBlack = "#101010";
export const secondary = "#C4C4C4";
export const white = "#ffffff";
export const red = "#ff2020";
export const green = "#00c608";
export const black = "#000000";

export const defaultMaterialTheme = createTheme({
  palette: {
    primary: amber,
  },
});

export const ButtonCustom = styled(Button)<{
  isActive?: boolean;
  width?: string;
  main_color?: string;
}>`
  &.MuiButton-root {
    background-color: ${(props) =>
      props.isActive
        ? props.main_color
          ? `${props.main_color}`
          : `black`
        : `${primary}`};
    color: ${white};
    box-shadow: none;
    border-radius: 2px;
    width: ${(props) => (props.width ? `${props.width}px` : "180px")};

    &.MuiButton-text {
      padding: 10px 10px 9px 10px;
    }

    &:hover {
      background-color: ${(props) => (props.isActive ? `black` : `${primary}`)};
    }

    &:focus {
      background-color: ${(props) => (props.isActive ? `black` : `${primary}`)};
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    }

    &:disabled {
      background-color: ${secondary};
    }
  }
`;

export const ButtonUploadCustom = styled.div`
  color: ${black};
  padding-bottom: 1rem;
  cursor: pointer;
`;

export const ButtonCustomCancel = styled(Button)<{}>`
  &.MuiButton-root {
    background-color: ${secondary};
    color: ${white};
    box-shadow: none;
    border-radius: 2px;
    &:hover {
      background-color: ${secondary};
    }
    &:focus {
      background-color: ${secondary};
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    }
  }
`;

export const ButtonCustomWithoutBoxShadowCancel = styled(Button)<{}>`
  &.MuiButton-root {
    background-color: ${secondary};
    color: ${white};
    box-shadow: none;
    border-radius: 0;
    &:hover {
      background-color: ${secondary};
    }
    &:focus {
      background-color: ${secondary};
      //box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    }
  }
`;

export const ButtonPlus = styled(Button)<{
  width?: number;
  noPlus?: boolean;
  padding?: string;
}>`
  &.MuiButton-root {
    background-color: ${white};
    color: black;
    width: ${({ width }) => (width ? `${width}px` : "180px")};
    padding: ${({ padding }) => (padding ? padding : "10px 8px")};
    &:hover {
      color: black;
      background-color: ${white};
    }
    &:after {
      content: "+";
      visibility: ${({ noPlus }) => (noPlus ? "hidden" : "visible")};
      width: ${({ noPlus }) => (noPlus ? 0 : "initial")};
      margin-left: ${({ noPlus }) => (noPlus ? 0 : "4px")};
      color: ${primary};
      font-weight: bold;
    }

    &:disabled {
      background-color: ${secondary};
    }
  }
`;

export const ButtonPlusSmall = styled(Button)<{ plus?: boolean }>`
  &.MuiButton-root {
    background-color: ${primary};
    position: relative;
    color: white;
    width: 20px;
    height: 20px;
    min-width: 20px;

    &:disabled {
      background-color: ${secondary};
    }

    &:hover {
      color: white;
      background-color: ${primary};
    }

    &:after {
      content: "+";
      color: white;
      position: absolute;
      display: block;
      width: 20px;
      height: 20px;
      bottom: 2px;
      font-weight: bold;
    }
  }
`;

export const ButtonCustomWithDarkText = styled(Button)<{
  active?: boolean;
  width?: string;
}>`
  &.MuiButton-root {
    background-color: ${(props) =>
      props.active ? `${primary}` : `${secondary}`};
    color: ${black};
    box-shadow: none;
    border-radius: 2px;
    width: ${(props) => (props.width ? `${props.width}px` : null)};
    &:hover {
      background-color: ${(props) =>
        props.active ? `${primary}` : `${secondary}`};
    }
    &:focus {
      background-color: ${(props) =>
        props.active ? `${primary}` : `${secondary}`};
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    }
  }
  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const ButtonCustomWithDarkTextTopFilter = styled(
  ButtonCustomWithDarkText
)`
  text-transform: none;
  margin-left: 10px;
  padding: 10px 28px;
  border-radius: 4px;
  max-height: 35px;
`;

export const Filter = styled.div<{ primary?: boolean }>`
  display: flex;
  margin: 1rem 0 0.8rem;
  padding: 14px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  justify-items: center;
`;

export const FilterButtonEndIcon = styled.div`
  display: block;
  font-size: 14px !important;
  line-height: 16px;
`;

export const FilterButton = styled(Button)<{
  active?: boolean;
  width?: string;
}>`
  &.MuiButton-root {
    background-color: transparent !important;
    color: ${(props) => (props.active ? `${primary}` : `${secondary}`)};
    box-shadow: none;
    position: relative;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border-radius: 2px;
    margin-right: 20px;
    width: ${(props) => (props.width ? `${props.width}px` : null)};
    &:hover {
      background-color: ${(props) =>
        props.active ? `${primary}` : `${secondary}`};
    }
    &:focus {
      background-color: ${(props) =>
        props.active ? `${primary}` : `${secondary}`};
    }
  }
`;

export const ButtonCustomBackgroundTransparent = styled(Button)<{
  delete?: boolean;
}>`
  background-color: transparent;
  color: black;
  text-transform: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: inherit;
    background-color: transparent;
  }
`;

export const ButtonCustomWithIcon = styled(Button)`
  background-color: transparent;
  color: ${secondary};
  text-transform: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: inherit;
    background-color: transparent;
  }
`;

export const useStyles = makeStyles({
  root: {
    margin: 0,
    marginBottom: "1rem",

    "& label.Mui-focused": {
      color: `${primary}`,
    },
    "& .MuiFilledInput-root": { backgroundColor: "#F9F9F9" },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: `${primary}`,
    },
    "& .MuiFilledInput-underline.Mui-disabled:before": {
      borderBottomStyle: "none",
    },
    "& .MuiFilledInput-underline.Mui-error:after": {
      borderBottomColor: `${red}`,
    },
    "&:after": {
      borderBottom: `2px solid ${primary}`,
    },
    "& label.MuiInputLabel-root": {
      // color: `${primary}`,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: `${primary}`,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: `${primary}`,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: black,
    },
  },
});

export const useGrayBackgroundDictionaryStyles = makeStyles({
  root: {
    margin: 0,
    marginBottom: "1rem",
    backgroundColor: `${secondary}`,

    "& label.Mui-focused": {
      color: `${primary}`,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: `${primary}`,
    },
    "& .MuiFilledInput-underline.Mui-error:after": {
      borderBottomColor: `${red}`,
    },
    "&:after": {
      borderBottom: `2px solid ${primary}`,
    },
    "& label.MuiInputLabel-root": {
      // color: `${primary}`,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: `${primary}`,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: `${primary}`,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: black,
    },
  },
});

export const useWhiteDictionaryStyles = makeStyles({
  root: {
    margin: 0,
    marginBottom: "0.5rem",
    backgroundColor: `${white}`,

    "& .MuiFilledInput-root": {
      backgroundColor: `${white}`,
    },

    "& label.Mui-focused": {
      color: `${primary}`,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: `${primary}`,
    },
    "& .MuiFilledInput-underline.Mui-error:after": {
      borderBottomColor: `${red}`,
    },
    "&:after": {
      borderBottom: `2px solid ${primary}`,
    },
    "& label.MuiInputLabel-root": {
      // color: `${primary}`,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: `${primary}`,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: `${primary}`,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: black,
    },
  },
});

export const useTransparentDictionaryStyles = makeStyles({
  root: {
    margin: 0,
    backgroundColor: "transparent",
    textAlign: "center",

    "& label.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#fafafa",
    },
    "& .MuiFilledInput-underline.Mui-error:after": {
      borderBottomColor: `${red}`,
    },
    "& label.MuiInputLabel-root": {
      color: `${primary}`,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: `${primary}`,
      backgroundColor: "transparent",
    },

    "& .MuiInputBase-input.Mui-disabled": {
      color: black,
    },
    "& .MuiFilledInput-underline": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        content: "",
        borderBottom: "2px solid #FFB61D",
      },
    },

    "& .MuiFilledInput-underline.Mui-focused": {
      "&::after": {
        content: "",
        borderBottom: "2px solid #FFB61D",
      },
    },
    "& .MuiFilledInput-input": {
      padding: "16px 12px 10px",
      backgroundColor: "#fafafa",

      "&::after": {
        content: "",
        borderBottom: "none",
      },
    },
  },
});

export const useProjectFormStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  rootFormControl: {
    width: "100%",
    marginBottom: "20px",
  },
});

export const useStylesSnackbar = makeStyles(() => ({
  root: {
    position: "fixed",
    top: "85px",
    height: "fit-content",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
}));

export const TextFieldStyled = styled(TextField)`
  .MuiInput-underline:after {
    border-bottom: 2px solid ${primary};
  }
`;

export const Wrapper = styled.div`
  width: 550px;
  margin-top: 90px;
  padding: 24px;
  background: ${white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  align-self: flex-start;
  height: 400px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  .button__backLogin {
    margin-top: 11px;
    &.MuiButton-root {
      background-color: ${primary};
      color: ${white};
      box-shadow: none;
      border-radius: 2px;
      &:hover {
        background-color: ${primary};
      }
      &:focus {
        background-color: ${primary};
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  font-size: 42px;
  text-transform: uppercase;
  color: ${secondary};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }

  &.link-like {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const MenuItemStyle = styled(MenuItem)<{ prim: boolean }>`
  &.MuiMenuItem-root {
    color: ${({ prim }) => (prim ? `${red}` : `${black}`)};
  }
  &:hover {
    background-color: transparent;
  }
`;
export const ImageWrapper = styled.div`
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const UserAvatarDefaultStyle = styled.img<{ src: string }>`
  border-radius: 100%;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  overflow: hidden;
  img {
    ${({ src }) => `content: url(${src})`};
    overflow: hidden;
    border-radius: 100%;
    width: 100px;
    height: 100px;
  }
`;

interface IUserAvatarStyle {
  prim?: string;
  size?: number;
}

export const UserAvatarStyle = styled.img<IUserAvatarStyle>`
  ${({ prim }) => `background: url("${prim}") no-repeat center/100%`};
  border-radius: 100%;
  margin: 0 auto;
  width: ${(props) => (props.size ? `${props.size}px` : "100px")};
  height: ${(props) => (props.size ? `${props.size}px` : "100px")};
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const SmallUserAvatarStyle = styled.img<{ prim?: string }>`
  ${({ prim }) => `background: url("${prim}") no-repeat center/100%`};
  border-radius: 100%;
  width: 60px;
  height: 60px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const ProjectAvatarStyle = styled.img<{ prim?: string }>`
  ${({ prim }) => `background: url("${prim}") no-repeat center / cover`};
  border-radius: 100%;
  width: 80%;
  height: 190px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const SectionTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  color: ${secondary};
  margin-bottom: 24px;
`;

export const Subtitle = styled.h3`
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: ${lightBlack};
`;

export const CheckBoxCustom = styled(Checkbox)`
  color: ${red};
`;

export const CheckboxStyled = styled(Checkbox)`
  color: ${primary};

  &.Mui-checked {
    color: ${primary};
  }
`;

export const UserName = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-right: 20px;
`;

export const CustomContainer = styled(Container)`
  max-width: unset !important;
`;

export const LoadingBox = styled(Box)`
  color: ${primary};
  text-align: center;
`;

export const CustomNavBar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 35px 0;
  width: 100%;
`;

export const CustomNavBarNoMargin = styled(CustomNavBar)`
  display: flex;
  justify-content: flex-start;
  margin: 0;
  width: 100%;
  max-width: 989px;
  flex-wrap: wrap;
  row-gap: 10px;
`;

export const CustomTopFilterContainer = styled(CustomNavBar)`
  margin: 40px 0;
  max-width: 1140px;
`;

export const CustomFilterContainer = styled(CustomNavBar)`
  padding: 0 27px;
  margin: 15px 0 40px;
`;

export const TopFilterPeriodContainer = styled(Box)`
  width: fit-content;
`;

export const TopFilterSelectContainer = styled(TopFilterPeriodContainer)`
  justifycontent: "flex-end";
`;

export const CustomNavLink = styled(NavLink)`
  text-decoration: none;
`;

export const MenuItemDelete = styled(MenuItemStyle)`
  &.MuiMenuItem-root {
    color: red;
  }
`;

export const CancelButton = styled(ButtonCustom)`
  &.MuiButton-root,
  &.MuiButton-root:focus,
  &.MuiButton-root:hover {
    background-color: ${secondary};
  }
`;

export const ButtonsWrapper = styled.div<{ mt?: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "40px")};
`;

export const ModalInput = styled(TextField)`
  display: block;

  & .MuiInputBase-root {
    color: ${lightBlack};
    width: 100%;

    & ::-webkit-outer-spin-button {
      appearance: none;
    }
    & ::-webkit-inner-spin-button {
      appearance: none;
    }
    &.MuiFilledInput-underline:after {
      border-bottom-color: ${primary};
    }
    &.MuiFilledInput-underline.Mui-error:after {
      border-bottom-color: ${red};
    }
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
        border-color: ${primary};
      }
    }
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
        border-color: ${primary};
      }
    }
  }

  & .MuiInputBase-multiline {
    box-sizing: border-box;
    min-width: 370px;
    min-height: 90px;
    padding: 10px;
    color: ${lightBlack};
  }

  & .MuiInputBase-inputMultiline {
    padding: 14px 0;
  }

  & .MuiInput-underline.Mui-error:after {
    border-bottom-color: red;
  }

  & .MuiInput-underline {
    &:before {
      border-bottom-color: #eee;
    }

    &:after {
      border-bottom-color: ${primary};
    }

    &:hover:not(.Mui-disabled):before {
      border-bottom-color: ${secondary};
    }
  }

  & .MuiFormLabel-root {
    color: ${secondary};
    &.Mui-Focused {
      color: ${secondary};
    }
  }

  & .MuiFormLabel-asterisk {
    color: #e85050;
  }
`;

export const AddBtnBgTransparent = styled.button`
  cursor: pointer;
  border: none;
  position: relative;
  background-color: transparent;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #fff;

  &::after {
    content: "+";
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    font-size: 19px;
    color: #ffb61d;
    background-color: #fff;
    right: -40px;
    border-radius: 3px;
    top: 0;
    padding: 1px;
    margin: auto;
  }
`;

export const Txt = styled.p`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fff;
`;

export const ValueTxt = styled(Txt)`
  color: ${lightBlack};
`;

export const TableWrapper = styled.div`
  position: relative;
  margin-top: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export const Cell = styled.div`
  box-sizing: border-box;
  border: 1px solid #eee;
  margin: 0;
  position: relative;
  height: 46px;
`;

export const HeaderTxt = styled(Txt)`
  font-weight: bold;
`;

export const MainSalaryGrid = styled.div`
  border-radius: 4px;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    height: 24px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-button:single-button {
    width: 24px;
    height: 24px;
  }

  &::-webkit-scrollbar-track {
    height: 20px;
    width: 20px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    height: 14px;
    width: 14px;
    border: 3px solid #fff;
    background: ${secondary};
    border-radius: 7px;
  }
`;

export const MainTableRow = styled.tr`
  display: grid;
  margin: 1rem 0 0.8rem;
  padding: 14px;
  border-radius: 14px;
  background-color: white;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  justify-items: center;
`;

export const MainTableHeaderTitle = styled.td`
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-right: 20px;

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;

export const MainTableRows = styled.div`
  max-height: 545px;
  overflow-y: auto;
  padding: 3px;
  display: flex;
  flex-direction: column;
`;

export const MainTableFooter = styled.div`
  padding: 0 14px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
`;

export const MainTableCell = styled.td`
  margin: auto;
  text-align: center;
`;

export const MainTableStatusCell = styled(MainTableCell)<{ status: boolean }>`
  color: ${(props) => (props.status ? primary : "red")};
`;

export const MainTableImage = styled(UserAvatarStyle)`
  height: 88px;
  width: 88px;
`;

export const ModalWithPhotoContent = styled.div`
  position: relative;
  padding: 20px;
  min-width: 550px;
`;

export const ModalWithPhotoInputs = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalWithPhotoForm = styled.div`
  flex-basis: 75%;
`;

export const ModalWithPhotoInput = styled(ModalInput)`
  margin-bottom: 15px;
  box-sizing: border-box;

  &:last-of-type {
    margin-bottom: 0;
  }

  & .MuiInputBase-root {
    padding: 0 10px;
  }

  & .MuiFormLabel-root {
    padding: 0 10px;
  }

  & .MuiFormHelperText-root {
    color: ${red};
    max-width: 440px;
  }
`;

export const ModalWithGradeOrderInput = styled(ModalWithPhotoInput)`
  & .MuiFormLabel-root {
    padding: 0;
  }
`;

export const HiddenInput = styled.input`
  visibility: hidden;
  position: absolute;
  //height: 0;
  //width: 0;
  //display: inline;
`;

export const HiddenInputLabel = styled.label`
  z-index: 2;
`;

export const UnderlayButton = styled(ButtonCustom)`
  z-index: -1;
`;

export const PrimaryLink = styled.a`
  color: ${primary};
  font-size: 14px;
  line-height: 16px;
  &:not([href]) {
    color: ${lightBlack};
  }
`;

export const WhiteLink = styled(PrimaryLink)`
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
`;

export const BundleLink = styled(PrimaryLink)`
  &:not([href^="http"]) {
    color: ${secondary};
    text-decoration: none;
    pointer-events: none;
    cursor: default;
  }
`;

export const TableGrid = styled.div`
  grid-column: 1 / span 4;
  grid-row: 1;
  display: grid;
  grid-template-rows: 46px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-items: center;
`;

export const HeaderGrid = styled(TableGrid)`
  background-color: ${lightBlack};
`;

export const DatePickerButton = styled(Button)<{ isActive?: boolean }>`
  background-color: ${(props) => (props.isActive ? primary : secondary)};
  text-transform: none;
  width: 147px;
`;

export const DatePickerButtonTopFilter = styled(DatePickerButton)`
  width: fit-content;
  padding: 10px 32px;
  max-height: 35px;
`;

export const PlaceholderBlock = styled.div`
  text-align: center;
  font-size: 16px;
`;

export const TextPhotoName = styled(Box)`
  cursor: pointer;
  min-width: 130px;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: underline;
`;

export const PreviewImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const StyledSwitch = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #ffb61d;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #ffb61d;
  }
`;

export const VerticalChartContainer = styled(Box)<{
  isFilterDisplay?: boolean;
}>`
  margin-bottom: 20px;
  max-width: 1140px;
  height: 667px;
  background-color: ${white};
  ${({ isFilterDisplay }) => isFilterDisplay && "margin-top:94px"}
`;

export const VerticalChartTitle = styled(Box)`
  background-color: ${black};
  color: ${white};
  font-size: 14px;
  font-weight: bold;
  padding: 11px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const VerticalChartSubTitle = styled(Box)`
  margin-top: 20px;
  margin-left: 27px;
  color: #828282;
`;

export const VerticalChartQuantity = styled(Box)`
  margin-left: 27px;
  font-weight: bold;
`;

export const VerticalChartBarContainer = styled.div<{ noFilters?: boolean }>`
  padding: 0 20px;
  max-width: 1100px;
  overflow-x: auto;
  ${({ noFilters }) => noFilters && "margin-top:96px"}
`;

export const VerticalChartBarContainerBody = styled.div`
  height: 395px;
`;

export const StyledFilterSelectFormControl = styled(FormControl)`
  border-radius: 2px;
  background-color: #b3b3b3;
  width: 340px;
  height: 35px;
  margin-left: 20px;
  & .MuiInput-underline:before,
  & .MuiInput-underline:after {
    all: unset;
    &:focus,
    &:active,
    &:hover {
      all: unset;
    }
  }
`;

export const StyledFilterSelectList = styled(Select)`
  line-height: 1.14;
  letter-spacing: 0.28px;
  font-size: 14px;
  font-weight: 500;
  padding-top: 4px;

  & .MuiInput-input {
    padding-left: 20px;
  }
  & .MuiSelect-icon {
    margin-right: 15px;
    fill: ${black};
  }
`;

export const ImportFileUpload = styled.input`
  position: absolute;
  visibility: hidden;
  height: 0;
  width: 0;
  display: inline;
`;
