export const FEATURE_FLAGS = {
  Dashboard: true,
  Projects: false,
  ProblemTasks: false,
  OverdueTasks: false,
  People: true,
  Schools: true,
  PersonalSchools: true,
  Events: false,
  Training: true,
  Reports: false,
  ReportsARBook: true,
  MobileApps: true,
  MobileAppUsers: true,
  Sites: true,
  Notifications: true,
  KnowledgeBase: true,
  Feedback: true,
  Giveaway: true,
  AskedQuestions: true,
  Settings: true,
  Onboarding: true,
  WhiteLabel:true,
};
