import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CreateSchoolMutationVariables,
  School as SchoolModel,
  SchoolFundingType,
  UpdateSchoolMutationVariables,
  User,
} from "../../generated/graphql";
import {
  SelectCountry,
  SelectRegion,
  SelectCities,
  SelectManager,
  SelectAdmins,
} from "./Selectors";
import { SchoolParticipants } from "./SchoolParticipants";
import { SchoolSubscription } from "./SchoolSubscription";
import {
  useSetSchoolData,
  useUpdateSchoolData,
  useSchoolErrors,
} from "./hooks";
import { Box, Paper } from "@material-ui/core";
import { AvatarPicker } from "../shared/AvatarPicker";
import { LoadingProgress } from "../shared/LoadingProgress";
import { StyledTextField } from "./Style";
import { ButtonCustom, primary } from "../shared/Style/Style";
import { isEmail, validatePhone } from "../../core/validators/validators";
import { SchoolAdminResolver } from "../shared/Utils/OtherOperations/schoolAdminResolver";
import { mapUsersIds } from "./utils";
import {
  ImportExportParticipants,
  ImportNotificationModal,
} from "./ImportExportParticipants";
import { SuccessSnackbar } from "../shared/SuccessSnackbar";
import { Courses } from "./Courses/Courses";
import { SelectSchoolFundingType } from "./Selectors/SelectSchoolFundingType";
import Checkbox from "@material-ui/core/Checkbox";
import { SelectCommunities } from "./Selectors/SelectCommunities";
import { SelectWhiteLabelCollections } from "./Selectors/SelectWhiteLabelCollections";

interface SchoolProps {
  disable: boolean;
  schoolDataUpdate?: SchoolModel;
  createSchool?(
    variables: CreateSchoolMutationVariables,
    admins: string[]
  ): void;
  updateSchool?(variables: UpdateSchoolMutationVariables): void;
  updateAdmins?(variables: string[]): void;
}

export const School = ({
  disable,
  schoolDataUpdate,
  createSchool,
  updateSchool,
  updateAdmins,
}: SchoolProps) => {
  const { push } = useHistory();
  const { schoolData, setSchoolData } = useSetSchoolData(schoolDataUpdate);
  const { schoolErrors, setSchoolErrors } = useSchoolErrors();

  const [importModal, setImportModal] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState(false);

  const {
    id,
    name,
    phone,
    email,
    isPersonal,
    idShort,
    admins,
    managerId,
    countryId,
    regionId,
    cityId,
    file,
    photo,
    fundingType,
    isInternalUse,
    communityId,
    community,
    whiteLabelCollectionId,
  } = schoolData;

  const handleAddFile = (file: File) => {
    setSchoolData((prev) => ({ ...prev, file }));
  };

  const handlerSetManager = (managerId: string) => {
    setSchoolData((prev) => ({ ...prev, managerId }));
  };

  const handlerSetAdmins = (admins: User[]) => {
    setSchoolData((prev) => ({ ...prev, admins: [...admins] }));
  };

  const handlerSetCountry = (countryId: string) => {
    setSchoolData((prev) => ({ ...prev, countryId, regionId: "", cityId: "" }));
  };

  const handlerSetRegionId = (regionId: string) => {
    setSchoolData((prev) => ({ ...prev, regionId, cityId: "" }));
  };

  const handlerSetCity = (cityId: string) => {
    setSchoolData((prev) => ({ ...prev, cityId, communityId: undefined }));
  };
  const handleSetSchoolFundingType = (fundingType: SchoolFundingType) => {
    setSchoolData((prev) => ({ ...prev, fundingType }));
  };

  const handleSetSchoolWhiteLabelCollection = (
    whiteLabelCollectionId: string
  ) => {
    setSchoolData((prev) => ({ ...prev, whiteLabelCollectionId }));
  };

  const handlerSetCommunity = (communityId: string) => {
    if (communityId == "") {
      communityId = undefined;
    }

    setSchoolData((prev) => ({ ...prev, communityId }));
  };

  const resolveSchoolDataValue = (fieldName: string): string | null => {
    const field = schoolData[fieldName];
    return field?.length > 0 ? field : null;
  };

  const saveData = () => {
    if (!name) setSchoolErrors((prev) => ({ ...prev, name: true }));
    if (!managerId && updateSchool)
      setSchoolErrors((prev) => ({ ...prev, manager: true }));
    if (!countryId && updateSchool)
      setSchoolErrors((prev) => ({ ...prev, country: true }));
    if (!regionId && updateSchool)
      setSchoolErrors((prev) => ({ ...prev, region: true }));
    if (!cityId && updateSchool)
      setSchoolErrors((prev) => ({ ...prev, city: true }));
    if (!admins.length && !isPersonal && updateSchool)
      setSchoolErrors((prev) => ({ ...prev, admins: true }));

    if (!name) return;

    if (
      updateSchool &&
      (!managerId ||
        !countryId ||
        !regionId ||
        !cityId ||
        (!admins.length && !isPersonal))
    ) {
      return;
    }

    const variables = {
      schoolData: {
        name,
        phone: resolveSchoolDataValue("phone"),
        email: resolveSchoolDataValue("email"),
        managerId: resolveSchoolDataValue("managerId"),
        countryId: resolveSchoolDataValue("countryId"),
        regionId: resolveSchoolDataValue("regionId"),
        districtId: resolveSchoolDataValue("districtId"),
        cityId: resolveSchoolDataValue("cityId"),
        fundingType,
        communityId,
        isInternalUse,
        whiteLabelCollectionId,
      },
      photo: file,
    };

    if (createSchool) {
      return createSchool(variables, mapUsersIds(admins));
    }

    if (updateAdmins) {
      updateAdmins(mapUsersIds(admins));
    }

    updateSchool({ ...variables, id });
  };

  const handlerSchoolSubscription = (field: string) => {
    setSchoolErrors((prev) => ({ ...prev, [field]: true }));
  };

  useUpdateSchoolData(schoolDataUpdate, setSchoolData);

  useEffect(() => {
    if (name) setSchoolErrors((prev) => ({ ...prev, name: false }));
    if (managerId) setSchoolErrors((prev) => ({ ...prev, manager: false }));
    if (countryId) setSchoolErrors((prev) => ({ ...prev, country: false }));
    if (regionId) setSchoolErrors((prev) => ({ ...prev, region: false }));
    if (cityId) setSchoolErrors((prev) => ({ ...prev, city: false }));
    if (admins?.length > 0)
      setSchoolErrors((prev) => ({ ...prev, admins: false }));
  }, [name, managerId, countryId, regionId, cityId, admins]);

  useEffect(() => {
    if (!phone?.length) return;

    setSchoolErrors((prev) => ({ ...prev, phone: !validatePhone(phone) }));
  }, [phone]);

  useEffect(() => {
    if (!email?.length) return;

    setSchoolErrors((prev) => ({ ...prev, email: !!isEmail(email) }));
  }, [email]);

  const schoolAdmin = useMemo(() => {
    return SchoolAdminResolver.getDataForSingleSchool(admins);
  }, [admins]);

  if (disable) {
    return <LoadingProgress />;
  }

  return (
    <>
      <Box display="flex" width="100%" justifyContent="flex-end">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="50%"
          m={0}
        >
          <p>Школа для внутрішнього користування</p>
          <Checkbox
            style={{
              color: primary,
            }}
            checked={isInternalUse}
            onChange={({ target: { checked } }) =>
              setSchoolData((prev) => ({
                ...prev,
                isInternalUse: checked,
              }))
            }
          />
        </Box>
      </Box>

      <Paper elevation={3}>
        <Box p="20px 30px">
          <Box display="flex" mb="20px">
            <Box
              flex="0 0 50%"
              display="flex"
              flexDirection="column"
              borderRight="1px solid #ccc"
            >
              <Box display="flex">
                <Box flex="0 0 30%">
                  <AvatarPicker photo={photo} uploadHandler={handleAddFile} />
                </Box>
                <Box flex="0 0 70%" padding="0 30px">
                  <StyledTextField
                    fullWidth
                    variant="filled"
                    label="Название школы"
                    error={schoolErrors.name}
                    value={name ?? ""}
                    onChange={({ target: { value } }) =>
                      setSchoolData((prev) => ({ ...prev, name: value }))
                    }
                  />

                  <StyledTextField
                    fullWidth
                    variant="filled"
                    label="Номер телефона"
                    error={schoolErrors.phone}
                    value={phone ?? ""}
                    onChange={({ target: { value } }) =>
                      setSchoolData((prev) => ({ ...prev, phone: value }))
                    }
                  />

                  <StyledTextField
                    type="email"
                    fullWidth
                    variant="filled"
                    label="Почта"
                    error={schoolErrors.email}
                    value={email ?? ""}
                    onChange={({ target: { value } }) =>
                      setSchoolData((prev) => ({ ...prev, email: value }))
                    }
                  />

                  {updateSchool ? (
                    <StyledTextField
                      disabled
                      fullWidth
                      variant="filled"
                      label="ID школы"
                      value={idShort ?? ""}
                    />
                  ) : null}

                  <SelectSchoolFundingType
                    fundingType={fundingType}
                    setFundingType={handleSetSchoolFundingType}
                  />

                  {isPersonal ? (
                    <StyledTextField
                      fullWidth
                      variant="filled"
                      label="Администратор"
                      value={schoolAdmin.fullName}
                      onClick={() =>
                        push(`/mobileAppUsers/${schoolAdmin.id}/editUser`)
                      }
                      InputProps={{
                        readOnly: true,
                        style: { color: primary },
                      }}
                    />
                  ) : (
                    <SelectAdmins
                      admins={admins}
                      inputError={schoolErrors.admins}
                      setAdmins={handlerSetAdmins}
                    />
                  )}

                  <SelectManager
                    managerId={managerId}
                    inputError={schoolErrors.manager}
                    setManagerId={handlerSetManager}
                  />

                  <SelectCountry
                    countryId={countryId}
                    inputError={schoolErrors.country}
                    setCountryId={handlerSetCountry}
                  />

                  {countryId ? (
                    <SelectRegion
                      countryId={countryId}
                      regionId={regionId}
                      inputError={schoolErrors.region}
                      setRegionId={handlerSetRegionId}
                    />
                  ) : null}

                  {regionId ? (
                    <SelectCities
                      regionId={regionId}
                      cityId={cityId}
                      inputError={schoolErrors.city}
                      setCityId={handlerSetCity}
                    />
                  ) : null}
                  {cityId ? (
                    <SelectCommunities
                      community={community}
                      regionId={regionId}
                      communityId={communityId}
                      setCommunityId={handlerSetCommunity}
                    />
                  ) : null}
                  {!isPersonal ? (
                    <SelectWhiteLabelCollections
                      collectionId={whiteLabelCollectionId}
                      setCollectionId={handleSetSchoolWhiteLabelCollection}
                    />
                  ) : null}
                </Box>
              </Box>
            </Box>
            <Box flex="0 0 50%">
              {updateSchool ? (
                <>
                  <ImportExportParticipants
                    schoolId={id}
                    openImportModal={() => setImportModal(true)}
                  />

                  <SchoolParticipants planUsers={schoolDataUpdate?.planUsers} />
                  <SchoolSubscription
                    schoolId={id}
                    isPersonal={isPersonal}
                    disableFormSubmit={!name || !managerId || !admins?.length}
                    onSubscriptionError={handlerSchoolSubscription}
                  />
                  {isPersonal && <Courses />}
                </>
              ) : null}
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <ButtonCustom onClick={saveData}>Сохранить</ButtonCustom>
          </Box>
        </Box>
      </Paper>

      {importModal && (
        <ImportNotificationModal
          open={importModal}
          close={() => setImportModal(false)}
          successNotification={() => setSuccessSnackbar(true)}
          schoolId={id}
        />
      )}

      {successSnackbar && (
        <SuccessSnackbar
          open={successSnackbar}
          handleClose={() => setSuccessSnackbar(false)}
          message="Запрошення успішно надіслано"
        />
      )}
    </>
  );
};
